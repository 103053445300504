import React, { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers";
import { useEffect } from "react";

interface IProps {
  title: string;
  id: string;
  content: ReactElement;
}

export const Drawer = (props: IProps) => {
  return (
    <div
      id={props.id + "_drawer"}
      className="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name={props.id}
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle={"#" + props.id}
      data-kt-drawer-close={"#" + props.id + "_drawer_close"}
    >
      {/* begin::Card */}
      <div className="card shadow-none rounded-0 w-100">
        {/* begin::Header */}
        <div className="card-header" id="kt_help_header">
          <h5 className="card-title fw-bold text-gray-600">{props.title}</h5>

          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-icon explore-btn-dismiss me-n5"
              id={props.id + "_drawer_close"}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-2"
              />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body" id={props.id + "_body"}>
          {props.content}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </div>
  );
};
