import { CTA1 } from "../../modules/common/cards/CTA1";

export const FlexibleAccessDrawdown = () => {
  return (
    <>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <h3>What is it?</h3>
        <div>
          A flexible access drawdown is a retirement income option that allows
          individuals to keep their pension funds invested while drawing an
          income as needed. This arrangement provides a high degree of control
          over retirement savings and the ability to tailor income levels
          according to changing circumstances or needs.
        </div>
      </div>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <h3>When might this be ideal?</h3>
        <div>
          Consider Emily, a 62-year-old woman who recently retired from her job
          as a marketing executive. She has a sizeable pension pot and wants the
          flexibility to adjust her income based on her lifestyle and financial
          needs during retirement. Emily also has plans to travel extensively in
          the first few years of her retirement and anticipates higher expenses
          during this period.
          <br />
          In Emily's situation, a flexible access drawdown may be the most
          suitable choice. By opting for this option, she can keep her pension
          funds invested and withdraw varying amounts as needed, providing her
          with the flexibility to accommodate her changing financial
          requirements. Emily can choose to draw a higher income during her
          travel years and then reduce her withdrawals when her expenses
          decrease. Additionally, she can adjust her income levels as her
          investment performance or personal circumstances change, giving her
          complete control over her retirement finances.
        </div>
      </div>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <CTA1
          title={<div className="mb-5 ms-20">Need help?</div>}
          backGroundImageSrc="media/illustrations/bw/png/question.png"
          button={[
            {
              to: "#",
              label: "Request A call back",
              className: "btn btn-primary fw-semibold ms-20 px-6 py-3",
              function: undefined,
              id: "booking",
            },
          ]}
        />
      </div>
    </>
  );
};
