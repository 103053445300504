import { toAbsoluteUrl } from "../../../_metronic/helpers";
import FileDecoder from "./FileDecoder";

interface IProps {
  documents: IDocument[];
}

interface IDocument {
  name: string;
  createdDate: string;
  data: string;
  type: string;
}

export const DocumentsCollection = (props: IProps) => {
  return (
    <>
      <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
        {props.documents.map((document, index) => (
          <div key={index} className="col-12 col-sm-12 col-xl">
            <div className="card btn h-100">
              <FileDecoder
                fileName={document.name}
                fileType={document.type}
                base64Data={document.data}
                children={
                  <div className="symbol symbol-75px mb-6">
                    {document.type === "pdf" ? (
                      <img
                        src={toAbsoluteUrl("/media/svg/files/pdf.svg")}
                        alt=""
                      />
                    ) : (
                      <img
                        src={toAbsoluteUrl("/media/svg/files/doc.svg")}
                        alt=""
                      />
                    )}
                  </div>
                }
              />
              <div className="fs-5 fw-bolder mb-2">{document.name}</div>
              <div className="fs-7 fw-bold text-gray-400 mt-auto">
                {document.createdDate}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
