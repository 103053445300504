import * as Yup from "yup";
import { propertySchema } from "../Shared/PropertySchema";

const header = "Property details";

const description =
  "Please provide details of any properties you own. This information can significantly impact the personalised financial advice we provide.";

const schema = Yup.object().shape({
  propertyDetails: Yup.array()
    .of(propertySchema)
    .label("")
    .meta({
      fieldArrayName: "properties",
      fieldArrayDescription: "",
      addSeparatorAbove: false,
      control: [
        {
          componentType: "InputBox",
          props: {
            name: "address",
            label: "What is the address?",
            required: true,
            description: "Please enter your house name/number and street",
            placeholder: "",
            inputType: "string",
            tooltip: "",
            prefix: null,
            addSeparatorBelow: false,
          },
        },
        {
          componentType: "InputBox",
          props: {
            name: "city",
            label: "What is the city?",
            required: true,
            description: "Please enter the city",
            placeholder: "",
            inputType: "string",
            tooltip: "",
            prefix: null,
            addSeparatorBelow: false,
          },
        },
        {
          componentType: "Postcode",
          props: {
            label: "What is the postcode?",
            description: "",
            name: "postcode",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "text",
            prefix: undefined,
            addressLine1FieldName: "",
            cityFieldName: "",
            includeFindAddress: false,
            validationType: "postcode",
          },
        },
        {
          componentType: "InputBox",
          props: {
            name: "estimatedValue",
            label: "What is the estimated value of your property?",
            required: true,
            description: "Please enter the estimated value of your property",
            placeholder: "",
            inputType: "number",
            tooltip: "",
            prefix: <div className="h3">£</div>,
            addSeparatorBelow: false,
            validationType: "number",
            minValue: 0,
            maxValue: 10000000
          },
        },
      ],
    }),
});

export { header, description, schema };
