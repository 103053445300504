import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers";

export const DataCard1 = (props: IProps) => {
  return (
    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <div className="card-header cursor-pointer">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">{props.title}</h3>
        </div>

        {props.headerButtons?.map((item, index) => {
          if (!item.function) {
            return (
              <Link
                id={item.id}
                key={item.label}
                to={item.to}
                className={
                  item.className
                    ? item.className
                    : "btn btn-primary align-self-center"
                }
              >
                {item.label}
              </Link>
            );
          } else {
            return (
              <a
                key={item.label}
                href="#"
                onClick={() => item.function()}
                className={
                  item.className
                    ? item.className
                    : "btn btn-primary align-self-center"
                }
              >
                {item.label}
              </a>
            );
          }
        })}
      </div>

      <div className="card-body p-9">
        {props.description && (
          <h5 className="mb-5 pb-5">{props.description}</h5>
        )}

        {props.cardData?.map((item, index) => {
          return (
            item && (
              <div key={item.name} className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                  {item.name}
                </label>

                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">{item.value}</span>
                </div>
              </div>
            )
          );
        })}

        {props.customContent && props.customContent}
      </div>
    </div>
  );
};

interface IProps {
  title: string;
  description: string | null;
  headerButtons: ICardHeaderButton[] | null;
  cardData?: ICardData[] | null;
  customContent: ReactNode | null;
}

interface ICardData {
  name: string | null;
  value: string | null;
}

interface ICardHeaderButton {
  label: string;
  to: string;
  function: () => void | null;
  className: string | null;
  id?: string;
}
