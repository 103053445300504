/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../config/firebase";
import { useUserAuth } from "../../../../context/AuthContext";
import { usePortal } from "../../../../context/PortalContext";

const userAvatarClass = "symbol-35px symbol-md-40px";

const HeaderUserMenu: FC = () => {
  const { logout }: any = useUserAuth();
  const { clearClientContext }: any = usePortal();
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  const handleLogout = async () => {
    await logout();
    await clearClientContext();
    window.location.href = "/";
  };

  return (
    <>
      {/* user bit TODO: needs to swap if loggedin */}
      {user ? (
        <>
          <div
            className={clsx("cursor-pointer symbol", userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
          >
            <img src={toAbsoluteUrl("/media/avatars/blank.png")} alt="" />
          </div>
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <div className="menu-content d-flex align-items-center px-3">
                <div className="symbol symbol-50px me-5">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/avatars/blank.png")}
                  />
                </div>

                <div className="d-flex flex-column">
                  <div className="fw-bolder d-flex align-items-center fs-5">
                    {user.displayName}
                    <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                      Logged in
                    </span>
                  </div>
                  <a
                    href="#"
                    className="fw-bold text-muted text-hover-primary fs-7"
                  >
                    {user.email}
                  </a>
                </div>
              </div>
            </div>

            <div className="separator my-2"></div>

            {/* <div className="menu-item px-5">
              <Link to={"/crafted/pages/profile"} className="menu-link px-5">
                My Profile
              </Link>
            </div>

            <div className="separator my-2"></div>

            <div className="menu-item px-5 my-1">
              <Link to="/crafted/account/settings" className="menu-link px-5">
                Account Settings
              </Link>
            </div> */}

            <div className="menu-item px-5">
              <a onClick={handleLogout} className="menu-link px-5">
                Sign Out
              </a>
            </div>
          </div>
        </>
      ) : (
        <div>
          <span className="svg-icon svg-icon-primary svg-icon-2x">
            <Link to="auth/login" className="btn btn-sm fw-bold btn-outline">
              Sign In{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    transform="translate(9.000000, 12.000000) rotate(-270.000000) translate(-9.000000, -12.000000) "
                    x="8"
                    y="6"
                    width="2"
                    height="12"
                    rx="1"
                  />
                  <path
                    d="M20,7.00607258 C19.4477153,7.00607258 19,6.55855153 19,6.00650634 C19,5.45446114 19.4477153,5.00694009 20,5.00694009 L21,5.00694009 C23.209139,5.00694009 25,6.7970243 25,9.00520507 L25,15.001735 C25,17.2099158 23.209139,19 21,19 L9,19 C6.790861,19 5,17.2099158 5,15.001735 L5,8.99826498 C5,6.7900842 6.790861,5 9,5 L10.0000048,5 C10.5522896,5 11.0000048,5.44752105 11.0000048,5.99956624 C11.0000048,6.55161144 10.5522896,6.99913249 10.0000048,6.99913249 L9,6.99913249 C7.8954305,6.99913249 7,7.89417459 7,8.99826498 L7,15.001735 C7,16.1058254 7.8954305,17.0008675 9,17.0008675 L21,17.0008675 C22.1045695,17.0008675 23,16.1058254 23,15.001735 L23,9.00520507 C23,7.90111468 22.1045695,7.00607258 21,7.00607258 L20,7.00607258 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                    opacity="0.3"
                    transform="translate(15.000000, 12.000000) rotate(-90.000000) translate(-15.000000, -12.000000) "
                  />
                  <path
                    d="M16.7928932,9.79289322 C17.1834175,9.40236893 17.8165825,9.40236893 18.2071068,9.79289322 C18.5976311,10.1834175 18.5976311,10.8165825 18.2071068,11.2071068 L15.2071068,14.2071068 C14.8165825,14.5976311 14.1834175,14.5976311 13.7928932,14.2071068 L10.7928932,11.2071068 C10.4023689,10.8165825 10.4023689,10.1834175 10.7928932,9.79289322 C11.1834175,9.40236893 11.8165825,9.40236893 12.2071068,9.79289322 L14.5,12.0857864 L16.7928932,9.79289322 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                    transform="translate(14.500000, 12.000000) rotate(-90.000000) translate(-14.500000, -12.000000) "
                  />
                </g>
              </svg>
            </Link>
          </span>
        </div>
      )}
    </>
  );
};

export { HeaderUserMenu };
