export const employmentTypeLookup = [
    { label: "", value: "0" },
    { label: "Current primary employment", value: "3663" },
    { label: "Current Secondary employment", value: "3664" },
    { label: "Previous employment", value: "3665" },
  ]

export const employmentStatusLookup = [
    { label: "", value: "0" },
    { label: "Full Time Employee", value: "1742" },
    { label: "Part Time Employee", value: "1743" },
    { label: "Self Employed", value: "1744" },
    { label: "Contract", value: "1745" },
    { label: "Retired", value: "1746" },
    { label: "Student", value: "1747" },
    { label: "Unemployed", value: "1748" },
    { label: "House Person", value: "1749" },
    { label: "Temporary Employment", value: "1750" },
    { label: "Director", value: "1751" },
    { label: "Other", value: "1752" },
  ]

export const pensionTypeLookup = [
  { label: "", value: "0" },
  { label: "Stakeholder Pension", value: "4093" },
  { label: "SIPPS", value: "4094" },
  { label: "Occupational Pension", value: "4095" },
  { label: "Annuity", value: "4098" },
  { label: "SERPS", value: "4102" },
  { label: "AVCs", value: "4103" },
  { label: "FSAVCs", value: "4104" },
  { label: "Personal Pension Plan", value: "100287" },
  { label: "Section 32", value: "100288" },
  { label: "RAC", value: "100289" },
  { label: "Group Personal Pension", value: "100290" },
  { label: "IPP", value: "100291" },
  { label: "S226", value: "100292" },
  { label: "SHP", value: "100293" },
  { label: "GPP", value: "100294" },
  { label: "GSHP", value: "100295" },
  { label: "GSIPP", value: "100296" },
  { label: "ID (USP)", value: "100297" },
  { label: "SSAS", value: "100298" },
  { label: "CIMP", value: "100299" },
  { label: "COMP", value: "100300" },
  { label: "EPP", value: "100301" },
  { label: "GAVC", value: "100302" },
  { label: "DAC/A2L", value: "100303" },
  { label: "Workplace Pension", value: "100304" },
  { label: "Final Salary", value: "100305" },
  { label: "FTA (Maturing)", value: "100694" },
]