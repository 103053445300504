import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: '8189099d-b806-48f2-ab86-7e1144daafd5',
    },
  });
  
appInsights.loadAppInsights();
appInsights.trackPageView(); // Optional: Track page views

export default appInsights;