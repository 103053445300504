import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Formik, Form, FormikValues, FormikProps } from "formik";
import {
  JSXElementConstructor,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import * as Yup from "yup";
import { KTSVG } from "../../../_metronic/helpers";
import { usePortal } from "../../../context/PortalContext";
import { IClientData } from "../../../models/ClientData";
import { useNavigate } from "react-router-dom";
import { ObjectShape } from "yup/lib/object";
import {
  GetFormFields,
  GetFormFieldsWithHeaderAndFields,
} from "../../forms/Helpers";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../config/firebase";
import { ScrollToError } from "./ScrollToError";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  schemas: FormSchema[];
  data?: Record<string, any>;
  handleSubmit: (data: any) => void;
  navigateAfterSubmitTo: string | undefined;
  TopContent?: ReactNode;
  FormTopContent?: ReactNode;
  FormFootercontent?: ReactNode;
  SaveAndExitButton?: boolean;
  initialStep?: number;
}

interface FormSchema {
  header: string;
  description: string;
  schema: Schema;
}

interface Schema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}

export const StepperForm = (props: IProps) => {
  const navigate = useNavigate();
  const { clientData, setClientData }: any = usePortal();
  const [shouldSaveAndExit, setShouldSaveAndExit] = useState(false);
  const [user] = useAuthState(auth);
  const isLoggedIn = user !== null;

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);

  const [showBottomErrors, setShowBottomErrors] = useState(false);

  const [disableButton, setDisableButton] = useState(false);

  const FormFields: JSX.Element[] = [];

  props.schemas.map((schema, index) =>
    FormFields.push(
      <div
        key={index}
        className={index === 0 ? "current" : ""}
        data-kt-stepper-element="content"
      >
        {GetFormFieldsWithHeaderAndFields(
          schema.schema,
          schema.header,
          schema.description
        )}
      </div>
    )
  );

  const [currentSchema, setCurrentSchema] = useState(props.schemas[0].schema);

  const initialValues = props.data || {};

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );

    if (stepper.current) {
      //by default the stepper control will look at the amount of elements in the stepper nav (in this case we have more than there are steps as we want to illustrate that the get full quotes is locked)
      //so here we are checking to see if it's
      stepper.current.totatStepsNumber = props.schemas.length;

      //setting an initial step will automatically try to skip to that step, if there are any validation errors on previous steps, it will stop at the step with the valiation errors.
      if (props.initialStep && props.initialStep > 0) {
        for (let i = 0; i < props.initialStep; i++) {
          const schemaForStep = props.schemas[i]
            .schema as Yup.ObjectSchema<any>;
          try {
            schemaForStep.validateSync(initialValues, { abortEarly: false });
          } catch (err) {
            // If there's a validation error, set the current step to this one and break the loop
            stepper.current.currentStepIndex = i;
            setCurrentSchema(props.schemas[i].schema);
            stepper.current.goNext();
            return;
          }
        }
        stepper.current.currentStepIndex = props.initialStep;
        setCurrentSchema(props.schemas[props.initialStep].schema);
        stepper.current.goNext();
      }
    }
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
    setCurrentSchema(
      props.schemas[stepper.current.currentStepIndex - 1].schema
    );

    //window.scrollTo(0, 0);
    document.getElementById("stepper")?.scrollIntoView();
  };

  const submitStep = (values: any, actions: FormikValues) => {
    console.log(values);
    if (!stepper.current) {
      return;
    }

    //go next or submit
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
      //window.scrollTo(0, 0);
      const yOffset = -100;
      const element = document.getElementById("stepper");
      if (element) {
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    } else {
      //submit
      submitBtnRef.current?.setAttribute("data-kt-indicator", "on");
      submitBtnRef.current?.setAttribute("disabled", "true");
      props.handleSubmit(values);
      props.navigateAfterSubmitTo && navigate(props.navigateAfterSubmitTo);
    }

    setCurrentSchema(
      props.schemas[stepper.current.currentStepIndex - 1].schema
    );
  };

  const saveAndExit = (formik: FormikProps<Record<string, any>>) => {
    formik.submitForm();
    //update context
    setClientData(formik.values);
    setShouldSaveAndExit(true);
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef]);

  useEffect(() => {
    if (shouldSaveAndExit) {
      navigate("/");
    }
  }, [shouldSaveAndExit]);

  return (
    <>
      {props.TopContent && props.TopContent}
      <div
        ref={stepperRef}
        className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
        id="stepper"
      >
        <div className="d-flex flex-row-fluid flex-center bg-body rounded me-md-9 mb-5">
          <Formik
            validationSchema={currentSchema}
            initialValues={initialValues}
            onSubmit={submitStep}
            validateOnBlur={true}
            validateOnChange={true}
            validateOnMount={false}
          >
            {(formik) => {
              const { isValid, submitForm } = formik;
              return (
                <Form className="py-20 w-100 px-9" noValidate id="stepperForm">
                  {props.FormTopContent && props.FormTopContent}
                  <div>{FormFields}</div>

                  {!isValid && (
                    <div className="text-danger mt-2 d-flex justify-content-end">
                      Please correct the above errors.
                    </div>
                  )}
                  <div className="d-flex flex-stack pt-10">
                    <div className="mr-2">
                      <button
                        onClick={prevStep}
                        type="button"
                        className="btn btn-lg btn-light-primary me-3"
                        data-kt-stepper-action="previous"
                      >
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr063.svg"
                          className="svg-icon-4 me-1"
                        />
                        Back
                      </button>
                    </div>

                    <div>
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        ref={submitBtnRef}
                        onClick={async () => {
                          submitForm();
                          // Add an onClick handler
                          await formik.validateForm(); // Trigger validation
                          if (!isValid) {
                            ScrollToError();
                          }
                        }}
                      >
                        <span className="indicator-label">
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! && "Continue"}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! && "Submit"}
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr064.svg"
                            className="svg-icon-3 ms-2 me-0"
                          />
                        </span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    </div>
                  </div>

                  {isLoggedIn && props.SaveAndExitButton == true && (
                    <div className="d-flex flex-stack">
                      <div></div>
                      <div className="ml-auto">
                        <div className="separator my-10"></div>
                        <button
                          onClick={() => saveAndExit(formik)}
                          type="submit"
                          className="btn btn-sm btn-light-primary"
                        >
                          <KTSVG
                            path="/media/icons/duotune/files/fil012.svg"
                            className="svg-icon-4 me-1"
                          />
                          Save & Exit
                        </button>
                      </div>
                    </div>
                  )}
                  {props.FormFootercontent && props.FormFootercontent}
                </Form>
              );
            }}
          </Formik>
        </div>

        {/* begin::Aside*/}
        <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px mb-5">
          {/* begin::Wrapper*/}
          <div className="card-body px-6 px-lg-10 px-xxl-15 py-20">
            {/* begin::Nav*/}
            <div className="stepper-nav">
              {props.schemas.map((schema, index) => {
                return (
                  <div
                    key={index}
                    className={
                      index === 0 ? "stepper-item current" : "stepper-item"
                    }
                    data-kt-stepper-element="nav"
                  >
                    <div className="stepper-wrapper">
                      {/* begin::Icon*/}
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">{index + 1}</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className="stepper-label">
                        <h3 className="stepper-title">{schema.header}</h3>

                        <div className="stepper-desc fw-semibold">
                          {schema.description}
                        </div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}
                    {/* begin::Line*/}
                    {index != props.schemas.length - 1 && (
                      <div className="stepper-line h-40px"></div>
                    )}

                    {/* end::Line*/}
                  </div>
                );
              })}
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}
      </div>
    </>
  );
};
