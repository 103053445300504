import { Link } from "react-router-dom";
import { NextSteps } from "../NextSteps";

export const FactfindCompleted = () => {
  return (
    <>
      <Link to="/overview" className="btn btn-primary mb-10">
        Back to overview
      </Link>
      <NextSteps />
    </>
  );
};
