import { CTA1 } from "../../modules/common/cards/CTA1";

export const QuoteError = () => {
  return (
    <>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <h3>Why couldn't we return all quotes?</h3>
        <div>
          There can be a number of reasons why we were unable to return all
          quotes, this can be due to your age, fund size or certain providers
          having technical issues. In some cases, we may be able to return
          Lifetime Annuity quotes, however not Fixed-Term Annuity quotes, or
          vice versa.
        </div>
      </div>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <h3>What should I do about it?</h3>
        <div>
          If this happens, the best thing to do is contact us, we can then
          review your details and confirm if there is any reason why you may not
          be eligible for certain products.
        </div>
      </div>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <CTA1
          title={<div className="mb-5 ms-20">Need help?</div>}
          backGroundImageSrc="media/illustrations/bw/png/question.png"
          button={[
            {
              id: "booking",
              to: "#",
              label: "Speak to us",
              className: "btn btn-primary fw-semibold ms-20 px-6 py-3",
              function: undefined,
            },
          ]}
        />
      </div>
    </>
  );
};
