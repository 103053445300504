import clsx from "clsx";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  ThemeModeSwitcher,
} from "../../../partials";
import { useLayout } from "../../core";

const itemClass = "ms-1 ms-lg-3";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "svg-icon-1";

const Navbar = () => {
  const { config } = useLayout();
  return (
    <div className="app-navbar flex-shrink-0">
      {/* <div className="d-flex align-items-center">
        <a className="app-navbar-item">
          <span className="text-primary my-1 me-3">
            Need help? Call us on:{" "}
          </span>
          <button className="btn btn-outline h1 text-dark fw-bolder my-1 fs-3 lh-1">
            01302 496 300
          </button>
        </a>
      </div> */}
      <div
        className={clsx("app-navbar-item align-items-stretch", itemClass)}
      ></div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className={btnIconClass}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
