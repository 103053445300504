import { useIntl } from "react-intl";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MegaMenu } from "./MegaMenu";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../../config/firebase";

export function MenuInner() {
  const [user, loading, error] = useAuthState(auth);
  return (
    <>
      <MenuItem title="Portal Home" to="/" />
      {/* <MenuItem title="Equity Release Portal" to="/equityRelease" /> */}
    </>
  );
}
