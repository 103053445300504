import * as Yup from "yup";

export const propertySchema = Yup.object().shape({
  address: Yup.string().label("Address"),
  city: Yup.string().label("City"),
  postcode: Yup.string()
    .matches(
      /^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) [0-9][ABD-HJLNP-UW-Z]{2})$/i,
      "Invalid UK Postcode"
    )
    .label("Postcode"),
  estimatedValue: Yup.string().label("Estimated Value"),
  ownership: Yup.string().label("Ownership"),
  residenceType: Yup.string().label("Residence Type"),
});
