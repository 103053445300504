import { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import { LayoutProvider, LayoutSplashScreen } from "./_metronic/layout/core";
import { MasterInit } from "./_metronic/layout/MasterInit";
import { ObjectEncodingOptions } from "fs";
import { Drawer } from "./modules/common/other/Drawer";
import { FixedTermAnnuity } from "./pages/Info_articles/FixedTermAnnuity";
import { LifetimeAnnuity } from "./pages/Info_articles/LifetimeAnnuity";
import { FlexibleAccessDrawdown } from "./pages/Info_articles/FlexibleAccessDrawdown";
import CalendlyWidget from "./modules/common/other/CalendlyWidget";
import { Booking } from "./pages/booking/Booking";
import { QuoteError } from "./pages/Info_articles/QuoteError";

const App = () => {
  const [clientData, setClientData] = useState({});
  const [annuityData, setAnnuityData] = useState({});

  return (
    <LayoutProvider>
      {/* add info drawers that can be used throughout app */}
      <Drawer
        id="whatIsALifeTimeAnnuity"
        title="What is a Lifetime Annuity?"
        content={<LifetimeAnnuity />}
      />
      <Drawer
        id="whatIsAFixedTermAnnuity"
        title="What is a Fixed Term Annuity?"
        content={<FixedTermAnnuity />}
      />
      <Drawer
        id="whatIsFlexibleAccessDrawdown"
        title="What is Flexible Access Drawdown?"
        content={<FlexibleAccessDrawdown />}
      />
      <Drawer id="booking" title="Book an appointment" content={<Booking />} />
      <Drawer
        id="quoteError"
        title="Why couldn't we get all quotes?"
        content={<QuoteError />}
      />
      <Outlet />
      <MasterInit />
    </LayoutProvider>
  );
};

export { App };
