import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

interface IProps {
  className?: string;
  header: React.ReactNode;
  image?: string;
  image2?: string;
  linkId?: string;
  linkTo: string;
  linkLabel: string;
  subLinkId?: string;
  subLinkTo: string;
  subLinkLabel: string;
}

export const CTA3 = (props: IProps) => {
  return (
    <div className={`card card-flush ${props.className}`}>
      <div className="card-body">
        <div className="d-flex justify-content-center row align-items-center ">
          <div className="col-md-6 col-sm-12 text-center py-10">
            {props.image && (
                <img
                className="mx-auto h-200px h-lg-200px"
                src={toAbsoluteUrl(props.image)}
                alt=""
              />
            )}

            <div className="fs-1 fw-bolder text-black mb-10 mt-10">
              {props.header}
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="mb-0">
              <div className="text-center">
              {props.image2 && (
                <img
                className="mx-auto h-200px h-lg-200px"
                src={toAbsoluteUrl(props.image2)}
                alt=""
              />
            )}
                <Link
                  className="btn btn-primary btn-lg"
                  to={props.linkTo}
                  id={props.linkId}
                >
                  {props.linkLabel}
                </Link>
              </div>
              <div className="text-center mt-5">
                <Link to={props.subLinkTo} id={props.subLinkId}>
                  {props.subLinkLabel}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
