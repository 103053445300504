import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { App } from "../App";
import { Home } from "../pages/Home";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import { AuthPage } from "../modules/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";
import DashboardWrapper from "../pages/portal_home/Dashboard";
import { UserAuthContextProvider } from "../context/AuthContext";
import { PortalContextProvider } from "../context/PortalContext";
import { QuickQuote } from "../pages/annuity_quote/quick_quote/QuickQuote";
import { AnnuityQuotes } from "../pages/annuity_quote/AnnuityQuotes";
import { GetStarted } from "../pages/GetStarted";
import { FullQuote } from "../pages/annuity_quote/full_quote/FullQuote";
import { Factfind } from "../pages/factfind/Factfind";
import { FormTestPage } from "../pages/FormTestPage";
import { BookingFull } from "../pages/booking/BookingFull";
import { FactfindCompleted } from "../pages/factfind/FactfindCompleted";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const [user, loading, error] = useAuthState(auth);
  const loggedInRoutes = (
    <>
      <Route path="/dashboard/*" element={<DashboardWrapper />} />
      <Route path="/portal/*" element={<DashboardWrapper />} />
      <Route path="/*" element={<DashboardWrapper />} />
      <Route path="/fullquote" element={<FullQuote />}></Route>
      <Route path="/factfind" element={<Factfind />}></Route>
      <Route path="/factfindcompleted" element={<FactfindCompleted />}></Route>
      <Route
        path="/annuityquotes"
        element={<AnnuityQuotes refreshOnLoad={false} />}
      ></Route>
      <Route
        path="/annuityquotes/refresh"
        element={<AnnuityQuotes refreshOnLoad={true} />}
      ></Route>

      <Route path="/FormTestPage" element={<FormTestPage />} />

      <Route
        path="/bookifacall"
        element={
          <BookingFull
            calendlyURL={
              "https://calendly.com/appointments-pense/30min?hide_event_type_details=1&hide_gdpr_banner=1"
            }
          />
        }
      />
    </>
  );
  const loggedOutRoutes = (
    <>
      <Route path="/" element={<Home />} />
      <Route path="auth/*" element={<AuthPage />} />
      <Route path="/*" element={<ErrorsPage />} />
    </>
  );
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <UserAuthContextProvider>
        <PortalContextProvider>
          <Routes>
            <Route element={<App />}>
              <Route element={<MasterLayout />}>
                {loading ? null : user ? loggedInRoutes : loggedOutRoutes}
                <Route path="/home" element={<Home />}></Route>
                <Route path="/quickquote" element={<QuickQuote />}></Route>
                <Route path="/getstarted" element={<GetStarted />}></Route>
                <Route
                  path="/quickquote/annuityquotes"
                  element={<AnnuityQuotes refreshOnLoad />}
                ></Route>
                <Route path="error/*" element={<ErrorsPage />} />
              </Route>
            </Route>
          </Routes>
        </PortalContextProvider>
      </UserAuthContextProvider>
    </BrowserRouter>
  );
};

export { AppRoutes };
