import { ErrorMessage, Field, useFormik } from "formik";
import { ReactNode } from "react";
import * as Yup from "yup";

interface IProps {
  label: string;
  description: string;
  tooltip?: ITooltip;
  name: string;
  className?: string;
  addSeparatorAbove?: boolean;
  addSeparatorBelow?: boolean;
  required: boolean;
  yesOrNoSelection?: string;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}

export const YesNoBoxes = (props: IProps) => {
  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <div className="mb-10 fv-row">
        <label className="d-flex align-items-center fs-3 fw-bold form-label">
          <span className={props.required ? "required" : ""}>
            {props.label}
          </span>
          {props.tooltip?.button && props.tooltip.button}
        </label>
        {props.tooltip?.notice && props.tooltip.notice}
        {props.description !== "" && (
          <div className="form-text">{props.description}</div>
        )}
        <div className="row mb-2 mt-5" data-kt-buttons="true">
          <div className="col">
            <Field
              type="radio"
              className="btn-check"
              name={props.name}
              value={"true"}
              id={props.name + "Yes"}
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
              htmlFor={props.name + "Yes"}
            >
              <span className="fs-6">Yes</span>
            </label>
          </div>
          <div className="col">
            <Field
              type="radio"
              className="btn-check"
              name={props.name}
              value={"false"}
              id={props.name + "No"}
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
              htmlFor={props.name + "No"}
            >
              <span className="fs-6">No</span>
            </label>
          </div>
        </div>

        <div className="text-danger mt-5 mb-5">
          <ErrorMessage name={props.name} />
        </div>
      </div>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};
