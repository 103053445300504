import { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import { usePortal } from "../../context/PortalContext";
import { Notice } from "../../modules/common/cards/Notice";
import { QuoteCollection } from "../../modules/common/cards/QuoteCollection";
import { TopFiguresCard } from "../../modules/common/cards/TopFiguresCard";
import { Link, useNavigate } from "react-router-dom";
import { RotatingLoader } from "../../modules/common/other/RotatingLoader/RotatingLoader";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import FadeIn from "react-fade-in";
import { IQuote } from "../../models/QuoteData";
import { ExampleQuotes } from "../../context/ExampleClientData";
import { NextSteps } from "../NextSteps";

export const AnnuityQuotes = (props: IProps) => {
  //run refreshOnLoad if set OR if no quotes in current context
  const [user, loading, error] = useAuthState(auth);
  const [showProviderSpinner, setShowProviderSpinner] = useState<boolean>(
    props.refreshOnLoad
  );

  const [showQuotes, setShowQuotes] = useState<boolean>(false);
  const [quotesList, setQuotesList] = useState<Array<IQuote>>([]);
  const [quotesListHighest, setQuotesListHighest] = useState<Array<IQuote>>([]);
  const [quoteAssumptions, setQuoteAssumptions] = useState("");
  const navigate = useNavigate();

  const {
    returnClientData,
    setClientData,
    clientData,
    getNewQuotes,
    getNewQuickQuotes,
    apiUpdateClientData,
  }: any = usePortal();

  useEffect(() => {
    if (props.refreshOnLoad) {
      refreshQuotes();
    } else {
      setShowProviderSpinner(false);
      setShowQuotes(true);
    }
  }, [props.refreshOnLoad]);

  useEffect(() => {
    setQuotesList(clientData.annuity?.quotes);
  }, [clientData]);

  useEffect(() => {
    if (quotesList) {
      const sortedQuotes = Object.values(
        quotesList.reduce((acc: { [key: string]: IQuote }, quote: IQuote) => {
          if (!quote.productName) {
            return acc;
          }
          if (
            !acc[quote.productName] ||
            acc[quote.productName].annuityAmountPA < quote.annuityAmountPA
          ) {
            acc[quote.productName] = quote;
          }
          return acc;
        }, {})
      );

      sortedQuotes.sort((a: IQuote, b: IQuote) => {
        if (a.productName.includes("life")) return -1;
        if (b.productName.includes("life")) return 1;
        if (a.productName === "fixed") return -1;
        if (b.productName === "fixed") return 1;
        return 0;
      });

      setQuotesListHighest(sortedQuotes);
    }
  }, [quotesList]);

  useEffect(() => {
    setQuoteAssumptions(getQuoteAssumptions());
  });

  const providers = [
    toAbsoluteUrl("/media/providers/Aviva.png"),
    toAbsoluteUrl("/media/providers/JUST.png"),
    toAbsoluteUrl("/media/providers/Legal & General.png"),
    toAbsoluteUrl("/media/providers/LV=.png"),
    toAbsoluteUrl("/media/providers/Canada Life.png"),
    toAbsoluteUrl("/media/providers/Scottish Widows.png"),
  ];

  const updatedDate = new Date();
  const isLoggedIn = user !== null;

  function getProviderImageURLs() {
    let imageLocations = [
      "/media/providers/Aviva.png",
      "/media/providers/JUST.png",
      "/media/providers/Legal and General.png",
      "/media/providers/lV=.png",
    ];

    let imagePaths = [""];
    imagePaths.pop();

    imageLocations.forEach((image) => {
      imagePaths.push(toAbsoluteUrl(image));
    });

    return imagePaths;
  }

  function getContentSections() {
    let contentSections = [];

    if (!isLoggedIn) {
      contentSections.push({
        title: "Customise your quote",
        content:
          "For a more personalised quote register for free and tell us a few more details for an enhanced quote. The more details you provide the better the quote.",
        button: {
          className: "btn-success",
          label: "Register & customise your quote",
          to: "../../auth/registration",
        },
      });
      contentSections.push({
        title: "Still have some questions?",
        content:
          "Speak to one of our specialise retirement technicians so they can find you the best annuity rates based on your personal circumstances.",
        button: {
          className: "btn btn-secondary",
          label: "Book an online video call",
          to: "#",
          id: "booking",
        },
      });
    } else {
      contentSections.push({
        title: "Still have some questions?",
        content:
          "Speak to one of our specialise retirement technicians so they can find you the best annuity rates based on your personal circumstances.",
        button: {
          className: "btn btn-secondary",
          label: "Book an online video call",
          to: "#",
          id: "booking",
        },
      });
      contentSections.push({
        title: "Ready to speak to an IFA?",
        content:
          "If you feel ready and want to start the process for your retirement, why not book in with one of our advisers?",
        button: {
          className: null,
          label: "Book Appointment",
          to: "#",
          id: "booking",
        },
      });
    }

    return contentSections;
  }

  function getQuoteNotice() {
    let quoteNotice = null;

    if (!isLoggedIn) {
      quoteNotice = {
        button: {
          to: "../../auth/registration",
          className: "btn btn-success",
          label: "Register & customise your quote",
        },
        content:
          "Get more by registering for free and telling us a few more details",
        header: null,
        icon: null,
        iconClasses: null,
        noticeClasses: null,
      };
    }

    return quoteNotice;
  }

  async function refreshQuotes() {
    setShowProviderSpinner(true);
    setShowQuotes(false);
    if (isLoggedIn) {
      await getNewQuotes(clientData);
    } else {
      await getNewQuickQuotes(clientData);
    }

    setShowProviderSpinner(false);
    setShowQuotes(true);
  }

  function getQuoteAssumptions() {
    let assumptions = "";
    let clientMedical = clientData?.medicalInformation;

    if (clientMedical) {
      if (clientMedical.regularDrinkerYesNo == "true") {
        assumptions +=
          "\n The average person in the UK consumes 18 units of alcohol per week.";
      }

      if (clientMedical.regularSmoker == "true") {
        assumptions +=
          "\n The average smoker in the UK smokes 15 cigarettes per day.";
      }

      if (clientMedical.weightIssuesYesNo == "true") {
        assumptions += "\n You may be slightly under or over weight.";
      }

      if (clientMedical.medicalConditionListQuickQuote) {
        assumptions += `\n You have the following medical conditions: ${clientData.medicalInformation.medicalConditionListQuickQuote.join(
          ", "
        )}.`;
      }
    }

    return assumptions;
  }

  //for scrolling into view
  const lifetimeQuotes = useRef(null);
  const fixedTermQuotes = useRef(null);
  const scrollToRef = (ref: any) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    //window.scrollBy(0, -50);
  };

  return (
    <>
      {showProviderSpinner && (
        <div className="m-5 p-5">
          <RotatingLoader
            images={providers}
            notice={{
              header: "Assumptions",
              contentTitle:
                "Based on the information you have provided we have made the following assumptions: ",
              content: quoteAssumptions,
            }}
          />
        </div>
      )}
      {showQuotes && quotesList && quotesListHighest && (
        <FadeIn transitionDuration={500}>
          <div>
            <div className="d-flex justify-content-between flex-row flex-wrap">
              <div className="d-flex flex-column flex-sm-fill">
                <div className="fs-1 fw-bolder">Your Top Annuity Quotes</div>
                <Notice
                  noticeClasses={
                    "notice d-flex bg-light-primary rounded border-primary border border-dashed mt-5 mb-5 p-6"
                  }
                  button={null}
                  header={"Did you know?"}
                  content={"We provide better rates than going direct!"}
                  icon={null}
                  iconClasses={null}
                />
              </div>
              <div className="d-flex align-items-md-end flex-column flex-sm-fill text-start pb-5">
                <div className="mt-auto">
                  {isLoggedIn && (
                    <Link to="/fullquote" className="mb-5 me-5 btn btn-primary">
                      Tailor Quotes
                    </Link>
                  )}

                  <button
                    onClick={() => refreshQuotes()}
                    className="mb-5 btn btn-secondary"
                  >
                    Refresh Quotes
                  </button>
                </div>
                <div>Updated as of {updatedDate.toString()}</div>
              </div>
            </div>

            <Notice
              noticeClasses={
                user
                  ? "bg-light-primary border-1 rounded border-primary mt-9 mb-9 p-10"
                  : "bg-light-danger border-2 rounded border-danger mt-9 mb-9 p-10"
              }
              icon={undefined}
              iconClasses={null}
              header={
                user ? (
                  <div className="text-black fs-4">Assumptions</div>
                ) : (
                  <div className="text-black fs-1">Get up to 40% more!</div>
                )
              }
              content={
                user ? (
                  "Although these quotes are more tailored, we have still made some assumptions to generate them, therefore values may change when you speak to your Independant Financial Adviser."
                ) : (
                  <div>
                    <div className="text-black fs-6">
                      <div className="fs-6 text-black pe-7">
                        <p>
                          Adding further health & lifestyle details could
                          increase these figures dramatically. Register for free
                          to add more details and get fully tailored quotes.
                        </p>
                      </div>
                    </div>
                    {quoteAssumptions.length > 0 && (
                      <div className="text-black fs-6">
                        <div className="fs-6 text-gray-600 pe-7">
                          <p>
                            Below are some assumptions that have been made to
                            generate these figures:
                          </p>
                          <ul>
                            {quoteAssumptions
                              .split("\n")
                              .slice(1)
                              .map((item, index) => (
                                <li key={index}>{item.trim()}</li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                )
              }
              button={null}
            />

            <TopFiguresCard
              headerSections={[
                ...quotesListHighest.map((quote) => ({
                  title: quote.productName.toLowerCase().includes("fixed")
                    ? "Fixed Term Annuity"
                    : quote.productName.toLowerCase().includes("life")
                    ? "Lifetime Annuity"
                    : "",
                  buttons: [
                    {
                      to: "",
                      label: quote.productName.toLowerCase().includes("fixed")
                        ? "What is a Fixed-term annuity?"
                        : quote.productName.toLowerCase().includes("life")
                        ? "what is a Lifetime annuity?"
                        : "",
                      id: quote.productName.toLowerCase().includes("fixed")
                        ? "whatIsAFixedTermAnnuity"
                        : quote.productName.toLowerCase().includes("life")
                        ? "whatIsALifeTimeAnnuity"
                        : "",
                      className: "btn btn-primary w-100 my-1",
                    },
                    {
                      to: "",
                      label: "View all",
                      id: "",
                      className: "btn btn-secondary w-100 my-1",
                      onClick: quote.productName.toLowerCase().includes("fixed")
                        ? () => scrollToRef(fixedTermQuotes)
                        : () => scrollToRef(lifetimeQuotes),
                    },
                  ],
                  value:
                    "£" +
                    parseFloat(quote.annuityAmountPA.toString()).toLocaleString(
                      "en-GB"
                    ),
                  logoSrc: `/media/providers/${quote.providerName}.png`,
                })),
                {
                  title: "Flexible Access Drawdown",
                  buttons: [
                    {
                      to: "",
                      label: "What is Flexible Access Drawdown?",
                      id: "whatIsFlexibleAccessDrawdown",
                      className: "btn btn-primary w-100",
                    },
                  ],
                  value: "Flexible",
                  logoSrc: "/media/providers/Aviva.png",
                },
              ]}
              notice={null}
              contentSections={getContentSections()}
            />

            <div className="mb-9 mt-9">
              {clientData && quotesListHighest.length < 1 ? (
                <NextSteps
                  buttonID="booking"
                  title="No quotes?"
                  text="If this happens, the best thing to do is contact us, we can then review your details and confirm if there is any reason why you may not be eligible for certain products."
                  buttonText="Get in touch"
                  buttonTo="#"
                />
              ) : (
                <NextSteps />
              )}
            </div>

            {quotesList.filter((quote) =>
              quote.productName.toLowerCase().includes("life")
            ).length > 0 && (
              <div ref={lifetimeQuotes}>
                <QuoteCollection
                  title={"Lifetime Annuity Quotes"}
                  titleLink={null}
                  topParagraph={
                    "Lifetime annuities offer lifelong income security, ensuring retirees have a steady cash flow to cover their needs and maintain a comfortable lifestyle throughout their retirement years."
                  }
                  bottomParagraph={
                    "Alternatively, book an online call with one of our specialist retirement technicians."
                  }
                  notice={getQuoteNotice()}
                  footer={
                    "Please note: the quotes are representative examples based on assumptions and the information provided. The figures shown are not guaranteed due to market fluctuations which may increase/decrease their value at the point of transaction."
                  }
                  quotes={quotesList
                    .filter((quote) =>
                      quote.productName.toLowerCase().includes("life")
                    )
                    .sort(
                      (quoteA, quoteB) =>
                        quoteB.annuityAmountPA - quoteA.annuityAmountPA
                    )
                    .map((quote) => ({
                      button: {
                        id: "whatIsALifeTimeAnnuity",
                        to: "",
                        className: null,
                        label: "More info",
                      },
                      logoLocation: `/media/providers/${quote.providerName}.png`,
                      quoteValues: [
                        {
                          title: "Provider",
                          value: quote.providerName,
                        },
                        {
                          title: "Total Annual Income",
                          value: new Intl.NumberFormat("en-gb", {
                            style: "currency",
                            currency: "GBP",
                          })
                            .format(quote.annuityAmountPA)
                            .toString(),
                        },
                        {
                          title: "Tax Free Cash",
                          value: new Intl.NumberFormat("en-gb", {
                            style: "currency",
                            currency: "GBP",
                          })
                            .format(quote.taxFreeCash)
                            .toString(),
                        },
                      ],
                    }))}
                />
              </div>
            )}
            {quotesList.filter((quote) =>
              quote.productName.toLowerCase().includes("fixed")
            ).length > 0 && (
              <div ref={fixedTermQuotes}>
                <QuoteCollection
                  title={"Fixed-Term Annuity Quotes"}
                  titleLink={null}
                  topParagraph={
                    "Fixed-term annuities provide a secure, predictable income for a set period during retirement, allowing retirees to effectively manage their expenses and enjoy a stress-free lifestyle for the term's duration."
                  }
                  bottomParagraph={
                    "Alternatively, book an online call with one of our specialist retirement technicians."
                  }
                  notice={getQuoteNotice()}
                  footer={
                    "Please note: the quotes are representative examples based on assumptions and the information provided. The figures shown are not guaranteed due to market fluctuations which may increase/decrease their value at the point of transaction."
                  }
                  quotes={quotesList
                    .filter((quote) =>
                      quote.productName.toLowerCase().includes("fixed")
                    )
                    .sort(
                      (quoteA, quoteB) =>
                        quoteB.annuityAmountPA - quoteA.annuityAmountPA
                    )
                    .map((quote) => ({
                      button: {
                        id: "whatIsAFixedTermAnnuity",
                        to: "",
                        className: null,
                        label: "More info",
                      },
                      footer: quote.productName.toLowerCase().includes("fixed")
                        ? "Based on a term of " +
                          quote.termYears +
                          " years and " +
                          quote.termMonths +
                          " months."
                        : "",
                      logoLocation: `/media/providers/${quote.providerName}.png`,
                      quoteValues: [
                        {
                          title: "Provider",
                          value: quote.providerName,
                        },
                        {
                          title: "Total Annual Income",
                          value: new Intl.NumberFormat("en-gb", {
                            style: "currency",
                            currency: "GBP",
                          })
                            .format(quote.annuityAmountPA)
                            .toString(),
                        },
                        {
                          title: "Tax Free Cash",
                          value: new Intl.NumberFormat("en-gb", {
                            style: "currency",
                            currency: "GBP",
                          })
                            .format(quote.taxFreeCash)
                            .toString(),
                        },
                        ...(quote.guaranteedMaturitySum
                          ? [
                              {
                                title: "Guaranteed Maturity Sum",
                                value: new Intl.NumberFormat("en-gb", {
                                  style: "currency",
                                  currency: "GBP",
                                })
                                  .format(quote.guaranteedMaturitySum)
                                  .toString(),
                              },
                            ]
                          : []),
                      ],
                    }))}
                />
              </div>
            )}
            <QuoteCollection
              title={"Flexible Access Drawdown"}
              titleLink={null}
              topParagraph={
                "Income drawdown offers retirees flexibility, as they can access their pension pot while it remains invested, providing the opportunity to adjust withdrawal amounts according to their financial needs and market conditions."
              }
              bottomParagraph={
                "Alternatively, book an online call with one of our specialist retirement technicians."
              }
              notice={getQuoteNotice()}
              footer={
                "Please note: the quotes are representative examples based on assumptions and the information provided. The figures shown are not guaranteed due to market fluctuations which may increase/decrease their value at the point of transaction."
              }
              quotes={[
                {
                  button: {
                    id: "whatIsFlexibleAccessDrawdown",
                    to: "",
                    className: null,
                    label: "More info",
                  },
                  logoLocation: "/media/providers/Aviva.png",
                  quoteValues: [
                    {
                      title: "Provider",
                      value: "Aviva",
                    },
                    {
                      title: "Total Annual Income",
                      value: "Flexible",
                    },
                    {
                      title: "Total Tax Free Cash",
                      value: new Intl.NumberFormat("en-gb", {
                        style: "currency",
                        currency: "GBP",
                      })
                        .format(quotesList.at(0)?.taxFreeCash ?? 0)
                        .toString(),
                    },
                  ],
                },
              ]}
            />
          </div>
        </FadeIn>
      )}
    </>
  );
};

interface IProps {
  refreshOnLoad: boolean;
}
