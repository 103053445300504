import React, { ReactElement } from "react";

interface IProps {
  title: string;
  content: ReactElement;
  onToggle: any;
  expanded: boolean;
}

export const Accordion = (props: IProps) => {
  return (
    <div className="card mt-5 mb-5 accordion">
      <div className={`card-header cursor-pointer accordion-button py-10 ${props.expanded ? "" : "collapsed"}`} onClick={props.onToggle}>
        <div className="card-title m-0">
          <div className="fw-bolder m-0">{props.title}</div>
        </div>
      </div>
      {props.expanded && (
        <div className="card-body">
          <div className="accordion-content">{props.content}</div>
        </div>
      )}
    </div>
  );
};

export default Accordion;
