import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

export const CTA1 = (props: IProps) => {
  const handleClick = (buttonFunction: (() => void) | undefined) => {
    if (buttonFunction) {
      buttonFunction();
    }
  };

  const cardContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  };

  const cardStyle = {
    backgroundImage: props.backGroundImageSrc
      ? `url('${toAbsoluteUrl(props.backGroundImageSrc)}')`
      : undefined,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    flexGrow: 1,
  };

  const cardBodyContainerStyle = {
    flexGrow: 1,
  };

  return (
    <div style={cardContainerStyle}>
      <div style={cardBodyContainerStyle}>
        <div
          className="card bgi-no-repeat bgi-size-contain card-xl-stretch mb-5 mb-xl-8"
          style={cardStyle}
        >
          <div className="card-body d-flex flex-column flex-md-row justify-content-around align-items-center ps-lg-20">
            <div className="d-flex flex-column ">
              <h1
                className={
                  props.titleClassName
                    ? props.titleClassName
                    : "text-dark fs-2qx fw-bold mb-3"
                }
              >
                {props.title}
              </h1>
              {props.content && <div className="mb-5">{props.content}</div>}
              {props.tooltip?.button && props.tooltip.button}
              {props.tooltip?.notice && props.tooltip.notice}
              {props.button && (
                <div className="m-0">
                  <div className="d-flex d-flex-row align-items-center">
                    {props.button.map((button, index) => {
                      return (
                        <div className="d-flex flex-column me-5" key={index}>
                          {button.to && (
                            <Link
                              id={button.id}
                              to={button.to}
                              className={
                                button.className
                                  ? button.className
                                  : "btn btn-primary fw-semibold px-6 py-3"
                              }
                            >
                              {button.label}
                            </Link>
                          )}
                          {!button.to && button.function && (
                            <a
                              href="#"
                              onClick={() => handleClick(button.function)}
                              className={
                                button.className
                                  ? button.className
                                  : "btn btn-primary fw-semibold px-6 py-3"
                              }
                            >
                              {button.label}
                            </a>
                          )}
                          {props.subLinkTo && (
                            <div className="text-center mt-5">
                              <Link to={props.subLinkTo} id={props.subLinkId}>
                                {props.subLinkLabel}
                              </Link>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex flex-column">
              {props.imageSrc && (
                <img
                  className="w-100 h-xl-300px"
                  src={toAbsoluteUrl(props.imageSrc)}
                  alt=""
                />
              )}
            </div>
          </div>
          {props.cardFooter && <div>{props.cardFooter}</div>}
        </div>
      </div>
    </div>
  );
};

interface IProps {
  title: ReactNode;
  titleClassName?: string;
  backGroundImageSrc?: string;
  imageSrc?: string;
  button?: IButton[];
  content?: ReactNode;
  subLinkTo?: string;
  subLinkLabel?: string;
  subLinkId?: string;
  cardFooter?: ReactNode;
  tooltip?: ITooltip;
}

interface IButton {
  label: string;
  to?: string | null;
  function?: () => void;
  className: string | null;
  id?: string;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}
