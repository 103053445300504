import { DocumentsCollection } from "../../modules/common/other/DocumentsCollection";
import { usePortal } from "../../context/PortalContext";
import { useEffect, useState } from "react";
import { IJournalDocument } from "../../models/ClientData";

export const MyDocuments = () => {
  const { clientData }: any = usePortal();
  const [portalDocs, setPortalDocuments] = useState(Array<IJournalDocument>());

  useEffect(() => {
    let allDocs = Array<IJournalDocument>();
    
    clientData.journalDocuments.forEach((clientDoc: IJournalDocument) => { allDocs.push(clientDoc) });
    clientData.annuity?.journalDocuments.forEach((annuityDoc: IJournalDocument) => { allDocs.push(annuityDoc) });

    setPortalDocuments(allDocs);
  }, []);

  return (
    <>
      <div className="d-flex flex-wrap flex-stack mb-6">
        <h3 className="fw-bolder my-2">My Documents</h3>
      </div>
      {portalDocs && portalDocs.length > 0 ? (
        <>
          <DocumentsCollection
            documents={[
              ...portalDocs.map((item: IJournalDocument, index) => ({
                name: item.name,
                createdDate: item.date,
                data: item.file,
                type: item.type,
              })),
            ]}
          />
        </>
      ) : (
        <div className="card h-100">
          <div className="card-body d-flex justify-content-center text-center flex-column p-8 fs-5 fw-bolder">
            <p>You don't have any documents here yet...</p>
          </div>
        </div>         
      )}
    </>
  );
};
