import { ErrorMessage, Field } from "formik";
import PropTypes from "prop-types";
import { ReactNode } from "react";

export const RadioBoxes = (props: IProps) => {
  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <div className="mb-10 fv-row">
        <label className="d-flex align-items-center fs-3 fw-bold form-label mb-2">
          <span className={props.required ? "required" : ""}>
            {props.label}
          </span>
          {props.tooltip?.button && props.tooltip.button}
        </label>
        {props.tooltip?.notice && props.tooltip.notice}
        {props.description != "" && (
          <small className="form-text">{props.description}</small>
        )}
        <div className="row mb-2 mt-4" data-kt-buttons="true">
          {props.items.map((item, index) => {
            return (
              <div key={item.value} className="col-md-4 col-lg-3 mb-4 me-2">
                <Field
                  type="radio"
                  className="btn-check"
                  name={props.name}
                  value={item.value}
                  id={props.name + "-" + index}
                />
                <label
                  className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                  htmlFor={props.name + "-" + index}
                >
                  <span className="fs-6">{item.label}</span>
                </label>
              </div>
            );
          })}
        </div>

        <div className="text-danger mt-5">
          <ErrorMessage name={props.name} />
        </div>
      </div>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};

interface IProps {
  label: string;
  description: string;
  tooltip?: ITooltip;
  items: IItem[];
  name: string;
  className?: string;
  addSeparatorBelow?: boolean;
  addSeparatorAbove?: boolean;
  required: boolean;
}

interface IItem {
  label: string;
  value: string;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}
