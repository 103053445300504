import { ErrorMessage, Field, useField } from "formik";
import PropTypes from "prop-types";
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import Client, { TypeaheadOptions } from "getaddress-api";
import { type } from "jquery";
import { LookupWithSearchAndGroups } from "./LookupWithSearchAndGroups";
import { fieldValidation } from '../../../models/FieldValidationConfig';

const Postcode = (props: IProps) => {
  const api = new Client("bf_V7XsVa0OlofYnzgL8rA33880");
  const [validationType, setValidationType] = useState(props.validationType);
  const [validationMin, setValidationMin] = useState(props.minValue);
  const [validationMax, setValidationMax] = useState(props.maxValue);

  const [field, meta, helpers] = useField(props.name);
  const [addressLine1Field, addressLine1FieldMeta, addressLine1FieldHelper] =
    useField(
      props.addressLine1FieldName
        ? props.addressLine1FieldName
        : "fielddoesnotexist"
    );
  const [cityField, cityFieldMeta, cityFieldHelper] = useField(
    props.cityFieldName ? props.cityFieldName : "fielddoesnotexist"
  );

  const [inputValue, setInputValue] = useState(props.initialValue);

  useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>("");

  async function setSuggestionsSelect() {
    const autocompleteResult = await api.autocomplete(inputValue);
    if (autocompleteResult.isSuccess) {
      var success = autocompleteResult.toSuccess();
      var suggestionArray: ISuggestion[] = [];
      console.log(success.suggestions);
      for (const suggestion of success.suggestions) {
        console.log(suggestion.address);
        suggestionArray.push(suggestion);
      }
      setSuggestions(suggestionArray);
    } else {
      const failed = autocompleteResult.toFailed();
      console.log(failed);
    }
  }

  interface TypeaheadSuccess {
    isSuccess: true;
    results: string[];
  }

  interface TypeaheadFailed {
    isSuccess: false;
    error: string;
  }

  const [postcodeResults, setPostcodeResults] = useState<string[]>([]);

  async function handleChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const value = event.target.value;
    setInputValue(value);

    const typeAheadOptions: TypeaheadOptions = {
      search: ["postcode"],
      top: 100,
    };

    api.typeahead(value.replace(/\s/g, ""), typeAheadOptions).then((vals) => {
      if (vals.isSuccess) {
        let successVals = vals as unknown as TypeaheadSuccess;
        setPostcodeResults(successVals.results);
        // successVals.results.forEach((result) => {
        //   console.log(result);
        //   setPostcodeResults
        // });
      } else {
        let failedVals = vals as unknown as TypeaheadFailed;
        console.error("Error:", failedVals.error);
      }
    });
  }

  async function handleAddressSelect(event: ChangeEvent<HTMLSelectElement>) {
    const selectedSuggestionId = event.target.value;
    const response = await api.get(selectedSuggestionId);
    if (response.isSuccess) {
      const successResponse: GetSuccess = response.toSuccess();

      if (props.addressLine1FieldName) {
        let address = successResponse.address.line_1;

        if (
          successResponse.address.line_2 &&
          successResponse.address.line_2 !== ""
        ) {
          address += ", " + successResponse.address.line_2;
        }

        if (
          successResponse.address.line_3 &&
          successResponse.address.line_3 !== ""
        ) {
          address += ", " + successResponse.address.line_3;
        }

        if (
          successResponse.address.locality &&
          successResponse.address.locality !== ""
        ) {
          address += ", " + successResponse.address.locality;
        }

        addressLine1FieldHelper.setValue(address);
      }

      if (props.cityFieldName) {
        cityFieldHelper.setValue(successResponse.address.town_or_city);
      }
    }
  }

  async function handleBlur() {
    helpers.setValue(inputValue);
  }

  function postcodeSelected(item: string) {
    setInputValue(item);
    helpers.setValue(item);
    setPostcodeResults([]);
  }

  function validator(value: any) {
    return fieldValidation(value, validationType, validationMin, validationMax, props.required);
  }

  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <div className="d-flex flex-column mb-7 fv-row">
        <div className="mb-2">
          <label className="d-flex align-items-center fs-3 fw-bold form-label">
            <span className={props.required ? "required" : ""}>
              {props.label}
            </span>
            {props.tooltip?.button && props.tooltip.button}
          </label>
          {props.tooltip?.notice && props.tooltip.notice}
          {props.description != "" && (
            <div className="form-text">{props.description}</div>
          )}
        </div>

        <div className="input-group input-group-solid mt-5">
          {props.prefix && (
            <div className="input-group-text">{props.prefix}</div>
          )}
          <Field
            type={props.inputType}
            className="form-control form-control-solid"
            placeholder={props.placeholder}
            name={props.name}
            value={inputValue}
            onChange={handleChange}
            onBlur={handleBlur} // Trigger re-render on blur
            id={props.name}
            autoComplete="off"
            validate={validator}
          />
          {props.includeFindAddress && (
            <button
              onClick={setSuggestionsSelect}
              className="btn btn-sm btn-primary"
              style={{
                borderTopRightRadius: "1.25rem",
                borderBottomRightRadius: "1.25rem",
              }}
              type="button"
            >
              Find address
            </button>
          )}
        </div>
        {postcodeResults.length > 0 && (
          <div className="form-control form-control-solid form-select-solid mt-5">
            <div className="mb-5 text-gray-500">Please select:</div>
            {postcodeResults.map((item, index) => (
              <a
                onClick={() => {
                  postcodeSelected(item);
                }}
                className="fs-4 btn btn-success btn-sm m-2"
                key={index}
              >
                {item}
              </a>
            ))}
          </div>
        )}
        {props.includeFindAddress && suggestions.length > 0 && (
          <div>
            {suggestions.length > 0 && (
              <select
                className="form-control form-control-solid form-select form-select-solid mt-10"
                onChange={handleAddressSelect}
              >
                {suggestions.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.address}
                  </option>
                ))}
              </select>
            )}
          </div>
        )}

        <div className="text-danger mt-2">
          <ErrorMessage name={props.name} />
        </div>
      </div>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};

interface IProps {
  label: string;
  description: string;
  name: string;
  initialValue: string;
  required: boolean;
  placeholder: string;
  tooltip?: ITooltip;
  inputType: string;
  prefix: ReactNode;
  className?: string;
  addSeparatorAbove?: boolean;
  addSeparatorBelow?: boolean;
  addressLine1FieldName?: string;
  cityFieldName?: string;
  includeFindAddress?: boolean;
  validationType?: string;
  minValue?: number;
  maxValue?: number;
}

interface ISuggestion {
  address: string;
  url: string;
  id: string;
}

interface IAddress {
  postcode: string;
  latitude: number;
  longitude: number;
  formatted_address: string[];
  thoroughfare: string;
  building_name: string;
  sub_building_name: string;
  sub_building_number: string;
  building_number: string;
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  locality: string;
  town_or_city: string;
  county: string;
  district: string;
  country: string;
  residential: boolean;
}

interface IApiResponse {
  isSuccess: boolean;
  address: IAddress;
}

interface GetSuccess {
  isSuccess: boolean;
  address: IAddress;
}

interface GetFailed {
  isSuccess: boolean;
  error: string;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}

export { Postcode };
export type { IProps };
