import { IGroupedOption } from "./LookupWithGroupsData";

//use below query to get data

// select '{value: "' + cast(System_AccountID as nvarchar) + '", label: "' + system_name + '",},'
// from service_account where system_organisationalunitId = 100104 and isnull(System_deleted,0)=0 and isnull(system_name, '') <> ''
// and isnull(System_Name,'') not like '%do not%'
// and isnull(System_Name,'') not like '%"%'

interface IEmployment {
  employmentType?: string;
  employmentStatus?: string;
  startDate?: string;
  endDate?: string;
}

export type { IEmployment }

const occupations = [
  {value: "8619", label: "Abattoir Inspector",},
  {value: "1753", label: "Abattoir Worker",},
  {value: "8564", label: "Abrasive Wheels Worker",},
  {value: "1755", label: "Account Executive",},
  {value: "1759", label: "Accountant",},
  {value: "8565", label: "Accounts Administrator/Assistant",},
  {value: "8566", label: "Acidifier Operator",},
  {value: "8567", label: "Acrobat",},
  {value: "8568", label: "Actor/Actress (no stunt work)",},
  {value: "1769", label: "Actuary",},
  {value: "1770", label: "Acupuncturist",},
  {value: "1775", label: "Administration Manager",},
  {value: "8618", label: "Administrator - office",},
  {value: "1784", label: "Advertising Executive",},
  {value: "1785", label: "Advertising Manager",},
  {value: "8569", label: "Aerial Erector (40' up)",},
  {value: "8570", label: "Aerial Erector (up to 40')",},
  {value: "8571", label: "Aerial Photography",},
  {value: "8572", label: "Aerobics Instructor",},
  {value: "8573", label: "Agent",},
  {value: "1797", label: "Agricultural Engineer",},
  {value: "1799", label: "Agricultural Worker",},
  {value: "1800", label: "Agronomist",},
  {value: "8574", label: "Air Compressor Operator",},
  {value: "8575", label: "Air Frame Service Fitter",},
  {value: "8576", label: "Air Pump Attendant -Coastal etc",},
  {value: "8577", label: "Air Pump Attendant -Deep Sea",},
  {value: "8578", label: "Air Traffic Control Assistant",},
  {value: "1805", label: "Air Traffic Controller",},
  {value: "8579", label: "Air Traffic Planner ",},
  {value: "8580", label: "Aircraft Electronics Service Fitter",},
  {value: "8581", label: "Aircraft Engine Service Fitter",},
  {value: "8582", label: "Aircraft Finisher",},
  {value: "8583", label: "Aircraft Inspector",},
  {value: "8584", label: "Aircraft Instrument Mechanic",},
  {value: "8585", label: "Aircraft Joiner",},
  {value: "8586", label: "Aircraft Maintenance Technician",},
  {value: "8587", label: "Aircraft Marshaller",},
  {value: "8588", label: "Aircraft Refueller",},
  {value: "8589", label: "Aircrew (including Flight Engineer)",},
  {value: "8590", label: "Airline Cabin Staff",},
  {value: "8591", label: "Airline Pilots",},
  {value: "1818", label: "Airport Manager",},
  {value: "8592", label: "Airport Superintendent",},
  {value: "8617", label: "Alternative Therapist/Complimentary Therapist",},
  {value: "8593", label: "Ambassador",},
  {value: "1823", label: "Ambulance Driver",},
  {value: "8594", label: "Ambulanceman (No driving)",},
  {value: "1824", label: "Amusement Arcade Worker",},
  {value: "8595", label: "Amusement Park Worker",},
  {value: "1825", label: "Anaesthetist",},
  {value: "8596", label: "Analyst - Business",},
  {value: "8597", label: "Analyst - City",},
  {value: "8598", label: "Analyst - Investment",},
  {value: "8599", label: "Analyst - Systems",},
  {value: "8600", label: "Analyst- Other",},
  {value: "1827", label: "Analytical Chemist",},
  {value: "8601", label: "Ancient Monuments Inspector",},
  {value: "8602", label: "Animal Nursing Auxiliary",},
  {value: "8603", label: "Animal Trainer/Keeper",},
  {value: "8604", label: "Animator",},
  {value: "8605", label: "Annealer",},
  {value: "8606", label: "Announcer - Radio & TV - Entertainment",},
  {value: "8607", label: "Announcer - Station Personnel - Railways",},
  {value: "8608", label: "Anodiser",},
  {value: "1831", label: "Antique Dealer",},
  {value: "8609", label: "Antique Restorer",},
  {value: "8610", label: "Arc Welder",},
  {value: "1838", label: "Archaeologist",},
  {value: "8611", label: "Archaeologist (other countries) ",},
  {value: "1839", label: "Architect",},
  {value: "8612", label: "Architect (office)",},
  {value: "1842", label: "Archivist",},
  {value: "8614", label: "Armed Forces - Army - aircrew",},
  {value: "8615", label: "Armed Forces - Army - bomb disposal",},
  {value: "8616", label: "Armed Forces - Army - no bomb disposal",},
  {value: "8613", label: "Armed Forces - Army - SAS",},
  {value: "8620", label: "Armed Forces - Full time reservist - no special duties",},
  {value: "8621", label: "Armed Forces - Full time reservist - special duties",},
  {value: "8623", label: "Armed Forces - Navy - aircrew",},
  {value: "8624", label: "Armed Forces - Navy - diving",},
  {value: "8625", label: "Armed Forces - Navy - no diving",},
  {value: "8622", label: "Armed Forces - Navy - SBS",},
  {value: "8626", label: "Armed Forces - RAF - aircrew",},
  {value: "8627", label: "Armed Forces - RAF - no flying",},
  {value: "1850", label: "Aromatherapist",},
  {value: "8628", label: "Arranger",},
  {value: "8629", label: "Art Director",},
  {value: "8630", label: "Art Gallery Attendant",},
  {value: "8631", label: "Art Gallery Curator",},
  {value: "8632", label: "Art Gallery Guide",},
  {value: "8633", label: "Art Gallery Manager - Commercial",},
  {value: "1855", label: "Artexer",},
  {value: "8634", label: "Artist - Freelance Painter",},
  {value: "8635", label: "Artist Commercial",},
  {value: "8636", label: "Artist's Model",},
  {value: "8637", label: "Asbestos Inspector",},
  {value: "8638", label: "Asbestos Worker",},
  {value: "1860", label: "Asphalter",},
  {value: "8639", label: "Assembler - Woodworking Industry",},
  {value: "8640", label: "Assembly Inspector",},
  {value: "8641", label: "Assessor (claims/insurance)",},
  {value: "8642", label: "Assistant Cameraman",},
  {value: "8643", label: "Assistant Director",},
  {value: "8644", label: "Assistant Editor",},
  {value: "8645", label: "Assistant Superintendent",},
  {value: "8646", label: "Assistant Tool Pusher",},
  {value: "8647", label: "Associate Producer",},
  {value: "8648", label: "Assumed Non-Hazardous (for quotation only)",},
  {value: "1872", label: "Astrologer",},
  {value: "1873", label: "Astronomer",},
  {value: "8649", label: "Atomic Energy Worker",},
  {value: "8650", label: "Attendant - Bingo - Entertainment",},
  {value: "8651", label: "Attendant - Fairground etc - Entertainment",},
  {value: "1874", label: "Au Pair",},
  {value: "1876", label: "Auctioneer",},
  {value: "8652", label: "Audiometrician",},
  {value: "1880", label: "Auditor",},
  {value: "1881", label: "Author",},
  {value: "8653", label: "Autoclave Operator",},
  {value: "8654", label: "Autolysis Man",},
  {value: "8655", label: "Automatic Train Attendant",},
  {value: "1883", label: "Auxiliary Nurse",},
  {value: "1886", label: "Baggage Handler",},
  {value: "8656", label: "Baggage Manager",},
  {value: "8657", label: "Baggage Master",},
  {value: "8658", label: "Baggage Porter",},
  {value: "1887", label: "Bailiff",},
  {value: "1888", label: "Baker",},
  {value: "8659", label: "Bakery Equipment Operator",},
  {value: "8660", label: "Bakery Shop Manager ",},
  {value: "8661", label: "Baler",},
  {value: "8662", label: "Band Leader",},
  {value: "8663", label: "Band Mill Sawyer",},
  {value: "1897", label: "Bank Staff",},
  {value: "8664", label: "Banksman",},
  {value: "8665", label: "Banksman's Assistant",},
  {value: "8666", label: "Bar Manager/Proprietor",},
  {value: "1902", label: "Bar Steward",},
  {value: "1903", label: "Barber",},
  {value: "8667", label: "Barber - Shop Manager/Proprietor",},
  {value: "8668", label: "Bargeman - Merchant Marine",},
  {value: "8669", label: "Bargeman - Quarrying",},
  {value: "8670", label: "Bargemaster",},
  {value: "8671", label: "Barley Roaster",},
  {value: "1904", label: "Barmaid",},
  {value: "1905", label: "Barman",},
  {value: "8672", label: "Barrelman",},
  {value: "1906", label: "Barrister",},
  {value: "8673", label: "Barrister, Advocate",},
  {value: "8674", label: "Batman",},
  {value: "8675", label: "Battery Assembler",},
  {value: "8676", label: "Battery Repairer",},
  {value: "1908", label: "Beautician",},
  {value: "8677", label: "Beautician Shop Manager/Proprietor",},
  {value: "8678", label: "Bed & Breakfast Proprietor",},
  {value: "8679", label: "Beekeeper, Apiarist",},
  {value: "8680", label: "Belt Maker",},
  {value: "8681", label: "Belt Patrol Man",},
  {value: "8682", label: "Bench Hand - Production Fitting - Metal Manufacture",},
  {value: "8683", label: "Bench Hand - Rubber Industry - Natural",},
  {value: "8684", label: "Berthing Superintendent",},
  {value: "8685", label: "Betting Shop Manager (on course)",},
  {value: "8686", label: "Betting Shop Manager (shop based) ",},
  {value: "8687", label: "Bill Poster/Sticker",},
  {value: "8688", label: "Bin Man",},
  {value: "8689", label: "Bindery Assistant",},
  {value: "8690", label: "Binding Machine Attendant",},
  {value: "8691", label: "Bingo Hall Manager",},
  {value: "1914", label: "Biochemist",},
  {value: "8692", label: "Biochemist - Lecturing and research",},
  {value: "8693", label: "Biological scientist",},
  {value: "8694", label: "Biologist (No travel/ underwater)",},
  {value: "8695", label: "Biologist (Overseas travel)",},
  {value: "8696", label: "Biologist (Underwater work)",},
  {value: "8697", label: "Biscuit Baker",},
  {value: "1917", label: "Blacksmith",},
  {value: "8698", label: "Blancher",},
  {value: "8699", label: "Blaster - quarry",},
  {value: "8700", label: "Bleacher - Paper & Board Manufacture",},
  {value: "8701", label: "Bleacher - Textile & Clothing Industry",},
  {value: "8702", label: "Blender",},
  {value: "8703", label: "Block Cutter",},
  {value: "8704", label: "Boarding School Matron",},
  {value: "1921", label: "Boat Builder",},
  {value: "8705", label: "Boatswain - Fishing Industry",},
  {value: "8706", label: "Boatswain - Merchant Marine",},
  {value: "8707", label: "Boatswain's Mate",},
  {value: "1923", label: "Bodyguard",},
  {value: "8708", label: "Boiler - Confectionery etc - Food & Drink",},
  {value: "8709", label: "Boiler - Fruit & Veg. - Food & Drink",},
  {value: "8710", label: "Boiler - Meat, Fish etc - Food & Drink",},
  {value: "8711", label: "Boiler Cleaner",},
  {value: "8712", label: "Boiler Operator - Electrical Supply",},
  {value: "8713", label: "Boiler Operator - Water Supply Industry",},
  {value: "8714", label: "Boiler Operator/Fireman",},
  {value: "8715", label: "Bomb Disposal - Elsewhere",},
  {value: "8716", label: "Bomb Disposal - Mainland Britain",},
  {value: "8717", label: "Book Illustrator",},
  {value: "1928", label: "Book Seller",},
  {value: "8718", label: "Bookbinder",},
  {value: "1932", label: "Book-Keeper",},
  {value: "8719", label: "Bookmaker - On course",},
  {value: "8720", label: "Bookmaker - Shop Manager",},
  {value: "8721", label: "Bookmaker - Shop Owner",},
  {value: "8722", label: "Bookmaker - Shop Staff",},
  {value: "8723", label: "Boom Operator",},
  {value: "8724", label: "Borer - Mining",},
  {value: "8725", label: "Borer - Tunnelling",},
  {value: "8726", label: "Borstal Matron",},
  {value: "8727", label: "Bosun (Third Hand)",},
  {value: "8728", label: "Botanist (No overseas field work)",},
  {value: "8729", label: "Bottle Washer (hand or machine)",},
  {value: "8730", label: "Bottling Machine Attendant",},
  {value: "1935", label: "Box Maker",},
  {value: "8731", label: "Box Office Cashier",},
  {value: "1936", label: "Box Office Clerk",},
  {value: "8732", label: "Box Office Manager",},
  {value: "8733", label: "Brakesman",},
  {value: "8734", label: "Brazer",},
  {value: "8735", label: "Bread Baker",},
  {value: "8736", label: "Bread Roundsman",},
  {value: "8737", label: "Breakdown Recovery Man",},
  {value: "1939", label: "Brewer",},
  {value: "1940", label: "Brewery Manager",},
  {value: "1942", label: "Bricklayer",},
  {value: "8738", label: "Bridge Man",},
  {value: "8739", label: "Briner",},
  {value: "8740", label: "Broker - Insurance IFA",},
  {value: "8741", label: "Broker - Insurance Non IFA",},
  {value: "8742", label: "Broker - Money/investments",},
  {value: "8743", label: "Broker - Oil",},
  {value: "8744", label: "Broker - Other",},
  {value: "8745", label: "Bronzer",},
  {value: "8746", label: "Broom/Brush Maker",},
  {value: "8747", label: "Buffet Car Attendant",},
  {value: "1947", label: "Builder",},
  {value: "1959", label: "Building Inspector",},
  {value: "8748", label: "Building Site Agent - Building and construction",},
  {value: "8749", label: "Building Society worker ",},
  {value: "1965", label: "Building Surveyor",},
  {value: "8750", label: "Bulldozer Driver",},
  {value: "8751", label: "Bunker Control man",},
  {value: "8752", label: "Burglar Alarm Fitter",},
  {value: "8753", label: "Bus Conductor (No driving)",},
  {value: "1970", label: "Bus Driver",},
  {value: "8754", label: "Bus Inspector",},
  {value: "1974", label: "Business Consultant",},
  {value: "1979", label: "Butcher",},
  {value: "8755", label: "Butcher Shop Proprietor ",},
  {value: "1981", label: "Butler",},
  {value: "8756", label: "Butter Blender",},
  {value: "8757", label: "Butter Maker",},
  {value: "8758", label: "Buyer - retail",},
  {value: "8759", label: "Buyer - stocks and shares",},
  {value: "8762", label: "Cabin Boy",},
  {value: "1984", label: "Cabinet Maker",},
  {value: "8763", label: "Cable Former",},
  {value: "8764", label: "Cable Hand",},
  {value: "1986", label: "Cable Jointer",},
  {value: "8765", label: "Cable Laying Diver",},
  {value: "8766", label: "Cable Tester",},
  {value: "8771", label: "Caf? Worker",},
  {value: "8767", label: "Cafe Cashier",},
  {value: "8768", label: "Cafe Manager",},
  {value: "8769", label: "Cafe Proprietor (Licensed)",},
  {value: "8770", label: "Cafe Proprietor (Unlicensed)",},
  {value: "8772", label: "Calibrator",},
  {value: "1992", label: "Call Centre Manager",},
  {value: "8773", label: "Call Centre Worker",},
  {value: "8774", label: "Caller",},
  {value: "8775", label: "Calligrapher",},
  {value: "8776", label: "Camera Repair Technician",},
  {value: "8777", label: "Cameraman Outside Work",},
  {value: "8778", label: "Cameraman Studio",},
  {value: "8779", label: "Cameraman War or Disaster reporting",},
  {value: "8780", label: "Candle Maker",},
  {value: "8781", label: "Canine Beautician",},
  {value: "8782", label: "Canine Behaviourist ",},
  {value: "8783", label: "Canning Machine Attendant",},
  {value: "8784", label: "Canteen Assistant",},
  {value: "8785", label: "Canteen Manager",},
  {value: "8786", label: "Canvasser",},
  {value: "8787", label: "Captain - Merchant Marine",},
  {value: "8788", label: "Captain - Oil & Natural Gas Industries ",},
  {value: "2001", label: "Car Delivery Driver",},
  {value: "8789", label: "Car Hire Company Proprietor (admin. and driving)",},
  {value: "8790", label: "Car Lasher",},
  {value: "2003", label: "Car Park Attendant",},
  {value: "8791", label: "Car Rental Company Manager",},
  {value: "8792", label: "Car Rental Company Worker",},
  {value: "8793", label: "Car Salesman (S/E or commission)",},
  {value: "8794", label: "Car Salesman (Salaried)",},
  {value: "8795", label: "Car Valeter",},
  {value: "2007", label: "Car Wash Attendant",},
  {value: "8796", label: "Caravan Site Manager",},
  {value: "8797", label: "Caravan Site Staff",},
  {value: "8798", label: "Carbon Printer",},
  {value: "8799", label: "Carbonation Man",},
  {value: "8800", label: "Carboniser",},
  {value: "2008", label: "Care Assistant",},
  {value: "8801", label: "Care Worker - Residential ",},
  {value: "2011", label: "Careers Advisor",},
  {value: "8802", label: "Caretaker, Janitor",},
  {value: "8803", label: "Cargo Clerk",},
  {value: "8804", label: "Cargo Superintendent",},
  {value: "8806", label: "Carpenter - Construction Industry",},
  {value: "8807", label: "Carpenter - Film Industry - Entertainment",},
  {value: "8805", label: "Carpenter & Joiner",},
  {value: "2020", label: "Carpet Cleaner",},
  {value: "8808", label: "Carpet Company director (office based admin. only)",},
  {value: "8809", label: "Carpet Designer ",},
  {value: "2021", label: "Carpet Fitter",},
  {value: "8810", label: "Carpet Salesman ",},
  {value: "8811", label: "Carpet Shop Assistant",},
  {value: "8812", label: "Carpet Shop Manager (admin.) ",},
  {value: "8813", label: "Carpet Shop Owner (no manual duties) ",},
  {value: "8814", label: "Carriage Cleaner",},
  {value: "8815", label: "Carriage Examiner",},
  {value: "2024", label: "Cartographer",},
  {value: "2025", label: "Cartoonist",},
  {value: "8816", label: "Cartridge Filler",},
  {value: "8817", label: "Cashier - Bank, Building Society",},
  {value: "8818", label: "Cashier - Shop, cafe, supermarket, bingo",},
  {value: "8819", label: "Casino Cashier",},
  {value: "8820", label: "Caster",},
  {value: "8821", label: "Casting Director",},
  {value: "8822", label: "Casting Machine Operator",},
  {value: "8823", label: "Caterer - offshore/at sea",},
  {value: "2031", label: "Catering Assistant",},
  {value: "2033", label: "Catering Manager",},
  {value: "8824", label: "Cathead Man",},
  {value: "2035", label: "Caulker",},
  {value: "8760", label: "CC TV Installer/Maintenance - 40 ft and over",},
  {value: "8761", label: "CC TV Installer/Maintenance - under 40 ft",},
  {value: "2037", label: "Ceiling Fixer",},
  {value: "8825", label: "Cell Tester",},
  {value: "8826", label: "Cementer",},
  {value: "8827", label: "Cemetery Worker",},
  {value: "8828", label: "Ceramicist",},
  {value: "8829", label: "Chain Maker",},
  {value: "8830", label: "Chair Maker",},
  {value: "8831", label: "Chambermaid - Housekeeper",},
  {value: "8832", label: "Charge Nurse",},
  {value: "8833", label: "Charity Worker - Admin Only",},
  {value: "8835", label: "Charity Worker - Overseas Work",},
  {value: "8834", label: "Charity Worker - UK Work",},
  {value: "8836", label: "Chartered Engineer (some site duties)",},
  {value: "8837", label: "Chartered Engineered (admin. only)",},
  {value: "8838", label: "Chartered Surveyor (admin only) ",},
  {value: "8839", label: "Chartered Surveyor (some site duties)",},
  {value: "8840", label: "Chassis Builder",},
  {value: "2051", label: "Chauffeur",},
  {value: "2053", label: "Chef",},
  {value: "8844", label: "Chemical engineer - offshore",},
  {value: "8843", label: "Chemical engineer - UK",},
  {value: "8841", label: "Chemical Plumber",},
  {value: "8842", label: "Chemical Plumber's Mate",},
  {value: "8845", label: "Chemist - industrial",},
  {value: "8846", label: "Chemist - retail",},
  {value: "8847", label: "Child Protection Co-ordinator",},
  {value: "8848", label: "Child Support Agency (CSA) worker",},
  {value: "8849", label: "Child Welfare Officer",},
  {value: "8850", label: "Childminder",},
  {value: "8851", label: "Children's Inspector",},
  {value: "8852", label: "Children's Matron",},
  {value: "8853", label: "Children's Nursery Proprietor",},
  {value: "8854", label: "Children's Play-group Leader",},
  {value: "2063", label: "Chimney Sweep",},
  {value: "8855", label: "Chip Shop Owner ",},
  {value: "8856", label: "Chip Shop Worker",},
  {value: "8857", label: "Chip/Money Changer",},
  {value: "8858", label: "Chipper & Painter",},
  {value: "8859", label: "Chipper (hand)",},
  {value: "8860", label: "Chipping Driver",},
  {value: "2065", label: "Chiropodist",},
  {value: "8861", label: "Chiropracter",},
  {value: "8862", label: "Church Organist ",},
  {value: "8863", label: "Cinema Projectionist",},
  {value: "8864", label: "Circus Hand",},
  {value: "8865", label: "Circus Manager",},
  {value: "2074", label: "Civil Engineer",},
  {value: "2075", label: "Civil Servant",},
  {value: "8866", label: "Claims Adjuster",},
  {value: "2077", label: "Claims Assessor",},
  {value: "8867", label: "Cleaner - commercial premises",},
  {value: "8868", label: "Cleaner - domestic premises",},
  {value: "8869", label: "Cleaner - industrial ",},
  {value: "8870", label: "Clergy",},
  {value: "8871", label: "Clerical Staff",},
  {value: "8872", label: "Clerical Worker",},
  {value: "2092", label: "Clerk Of Works",},
  {value: "8873", label: "Cloakroom Attendant - Club/Nightclub - Entertainment",},
  {value: "8874", label: "Cloakroom Attendant - Theatre, Ballet etc - Entertainment",},
  {value: "8875", label: "Clock & Watch Assembler",},
  {value: "8876", label: "Clock/Watch Maker",},
  {value: "8877", label: "Clock/Watch Repairer",},
  {value: "8878", label: "Cloth Cutter",},
  {value: "8879", label: "Clothing Designer",},
  {value: "8880", label: "Clown",},
  {value: "8881", label: "Club Manager",},
  {value: "8882", label: "Club Proprietor",},
  {value: "8883", label: "Club Steward",},
  {value: "8884", label: "Coach - Sports",},
  {value: "2097", label: "Coach Driver",},
  {value: "8885", label: "Coach Painter",},
  {value: "8886", label: "Coal Cutter Mover",},
  {value: "8887", label: "Coal Cutterman",},
  {value: "8888", label: "Coal Dry Cleaning Plant Operator",},
  {value: "8889", label: "Coal Face Workers",},
  {value: "8890", label: "Coal Melter",},
  {value: "8891", label: "Coal Merchant - admin only",},
  {value: "8892", label: "Coal Merchant - some delivery",},
  {value: "8893", label: "Coal Trimmer",},
  {value: "8894", label: "Coal Washery Operator",},
  {value: "8895", label: "Coal Yard Foreman",},
  {value: "8896", label: "Coal Yard Man",},
  {value: "8897", label: "Coastguard (Office based)",},
  {value: "8898", label: "Coastguard (Otherwise)",},
  {value: "8899", label: "Coffin Maker",},
  {value: "8900", label: "Coil Former",},
  {value: "8901", label: "Coil Winder",},
  {value: "2105", label: "College Lecturer",},
  {value: "8902", label: "Colour Calculator",},
  {value: "8903", label: "Colour Matcher",},
  {value: "8904", label: "Colour Mixer",},
  {value: "8905", label: "Columnist",},
  {value: "8906", label: "Comedian",},
  {value: "8907", label: "Commentator - no overseas travel etc",},
  {value: "8908", label: "Commentator - otherwise",},
  {value: "8909", label: "Commercial Diving",},
  {value: "8910", label: "Commercial Manager (office sales)",},
  {value: "8911", label: "Commercial Pilots",},
  {value: "2109", label: "Commercial Traveller",},
  {value: "2111", label: "Commissionaire",},
  {value: "8912", label: "Community Development Worker",},
  {value: "2120", label: "Community Nurse",},
  {value: "8913", label: "Company Director (admin. duties only)",},
  {value: "2125", label: "Company Secretary",},
  {value: "8914", label: "Compass Adjuster",},
  {value: "8915", label: "Compliance Manager",},
  {value: "2127", label: "Composer",},
  {value: "2128", label: "Compositor",},
  {value: "8916", label: "Compounder",},
  {value: "2130", label: "Computer Analyst",},
  {value: "8917", label: "Computer Company Technical Support Manager",},
  {value: "2134", label: "Computer Operator",},
  {value: "2135", label: "Computer Programmer",},
  {value: "8918", label: "Computer Programmer/Analyst",},
  {value: "8919", label: "Computer Salesman (office based) ",},
  {value: "8920", label: "Computer Software Manager",},
  {value: "8921", label: "Computer Software Salesman (includes travelling) ",},
  {value: "8922", label: "Computer Systems Installer",},
  {value: "8923", label: "Computer Wirer",},
  {value: "8924", label: "Computer Workshop Technical Engineer",},
  {value: "8925", label: "Concert Promoter",},
  {value: "8926", label: "Concrete Erector - 40' up",},
  {value: "8927", label: "Concrete Erector - up to 40'",},
  {value: "8928", label: "Concrete Finisher",},
  {value: "8929", label: "Concrete Paving Driver",},
  {value: "8930", label: "Concrete Shutterer",},
  {value: "8931", label: "Concreter",},
  {value: "8932", label: "Conductor - Music Industry - Entertainment",},
  {value: "8933", label: "Conductor - Train Crew - Railways",},
  {value: "2138", label: "Confectioner",},
  {value: "8934", label: "Conference Organising Assistant ",},
  {value: "8935", label: "Conference Organising Manager",},
  {value: "8936", label: "Conjurer",},
  {value: "8937", label: "Construction Work",},
  {value: "8938", label: "Contact Lens Technician",},
  {value: "8939", label: "Continuity Clerk",},
  {value: "8940", label: "Control Engineer",},
  {value: "8941", label: "Control Room Operator",},
  {value: "2158", label: "Conveyancer",},
  {value: "8942", label: "Conveyer Operator",},
  {value: "2159", label: "Cook",},
  {value: "2160", label: "Cooper",},
  {value: "2163", label: "Coppersmith",},
  {value: "8943", label: "Copyholder",},
  {value: "8944", label: "Copyholder (Newspapers etc)",},
  {value: "8945", label: "Copytaster",},
  {value: "2164", label: "Copywriter",},
  {value: "8946", label: "Core Borer",},
  {value: "8947", label: "Core Builder",},
  {value: "8948", label: "Coremaker",},
  {value: "2165", label: "Coroner",},
  {value: "8949", label: "Correspondent - no overseas travel etc - Journalism",},
  {value: "8950", label: "Correspondent - no overseas travel etc - Radio & TV - Entertainment",},
  {value: "8951", label: "Correspondent - otherwise - Journalism",},
  {value: "8952", label: "Correspondent - otherwise - Radio & TV - Entertainment",},
  {value: "8953", label: "Costermonger",},
  {value: "2169", label: "Costume Designer",},
  {value: "2171", label: "Costumier",},
  {value: "2173", label: "Counsellor",},
  {value: "8954", label: "Counter Staff - Dry Cleaning",},
  {value: "8955", label: "Counter Staff - Laundry",},
  {value: "8956", label: "Counter Staff - Post Office/Telecommunications",},
  {value: "8957", label: "Couriers",},
  {value: "8958", label: "Court Bailiff",},
  {value: "8959", label: "Court Usher",},
  {value: "8960", label: "Crab Fisherman",},
  {value: "2186", label: "Crane Erector",},
  {value: "2187", label: "Crane Operator",},
  {value: "8961", label: "Crane Slinger",},
  {value: "8962", label: "Credit Agent",},
  {value: "2191", label: "Credit Controller",},
  {value: "8963", label: "Critic",},
  {value: "8964", label: "Crop Sprayer - on ground",},
  {value: "8965", label: "Crop Sprayer - pilot",},
  {value: "8966", label: "Crossing Keeper",},
  {value: "2198", label: "Croupier",},
  {value: "8967", label: "Crushing Worker",},
  {value: "8969", label: "Curator - Zoo",},
  {value: "8968", label: "Curator (museum) ",},
  {value: "8970", label: "Curer",},
  {value: "8971", label: "Customer Care Officer",},
  {value: "8972", label: "Customer Service Staff",},
  {value: "8973", label: "Customs and Excise",},
  {value: "2212", label: "Cutter",},
  {value: "8974", label: "Cutting Machine Operator",},
  {value: "8975", label: "Dairyman",},
  {value: "2217", label: "Dancer",},
  {value: "8976", label: "Dancing Teacher ",},
  {value: "8977", label: "Data Controller",},
  {value: "8978", label: "Data Inputter",},
  {value: "8979", label: "Dealer - money/shares/investment",},
  {value: "2226", label: "Debt Collector",},
  {value: "8980", label: "Deck Chair Attendant",},
  {value: "8981", label: "Deck Hand",},
  {value: "8982", label: "Deck Officer",},
  {value: "2228", label: "Decorator",},
  {value: "2231", label: "Delivery Driver",},
  {value: "8983", label: "Demolition Worker-no explosives",},
  {value: "8984", label: "Demolition Worker-using explosives",},
  {value: "2234", label: "Demonstrator",},
  {value: "2236", label: "Dental Assistant",},
  {value: "8985", label: "Dental Auxiliary",},
  {value: "8986", label: "Dental Consultant",},
  {value: "2237", label: "Dental Hygienist",},
  {value: "2238", label: "Dental Nurse",},
  {value: "8987", label: "Dental Practitioner",},
  {value: "8988", label: "Dental Receptionist",},
  {value: "2240", label: "Dental Technician",},
  {value: "2241", label: "Dental Therapist",},
  {value: "2242", label: "Dentist",},
  {value: "8989", label: "Department Store Manager ",},
  {value: "8990", label: "Department Store worker",},
  {value: "2246", label: "Dermatologist",},
  {value: "8991", label: "Design cutter",},
  {value: "2250", label: "Designer",},
  {value: "8992", label: "Detention Centre Warden",},
  {value: "8993", label: "Diamond workers",},
  {value: "8994", label: "Die Cutter",},
  {value: "8995", label: "Die Setter",},
  {value: "8996", label: "Diesel Locomotive Fitter",},
  {value: "2259", label: "Dietician",},
  {value: "8997", label: "Dining Car Attendant",},
  {value: "2260", label: "Dinner Lady",},
  {value: "8998", label: "Director - Company - admin only",},
  {value: "8999", label: "Director - Company - other",},
  {value: "9000", label: "Director - Managing - admin only",},
  {value: "9001", label: "Director - Managing - other",},
  {value: "9002", label: "Director - Musical",},
  {value: "9003", label: "Director - Other",},
  {value: "9004", label: "Director - Sales - management only",},
  {value: "9005", label: "Director - Sales - some selling",},
  {value: "9006", label: "Director - TV and film",},
  {value: "9007", label: "Director & Medical Consultant",},
  {value: "2268", label: "Disc Jockey",},
  {value: "9008", label: "Disinfecting Officer",},
  {value: "9009", label: "Disinfestor",},
  {value: "9010", label: "Dispatch Rider",},
  {value: "9011", label: "Dispatcher",},
  {value: "9012", label: "Distiller",},
  {value: "9013", label: "Distillery Manager",},
  {value: "2272", label: "District Nurse",},
  {value: "2274", label: "Diver",},
  {value: "9014", label: "Diver (North Sea)",},
  {value: "9015", label: "Diver's Linesman -Coastal etc",},
  {value: "9016", label: "Diver's Linesman -Deep Sea",},
  {value: "9017", label: "Dock Foreman",},
  {value: "9018", label: "Dock Master",},
  {value: "9019", label: "Dock Superintendent",},
  {value: "2275", label: "Docker",},
  {value: "9020", label: "Doctor - Health",},
  {value: "9021", label: "Doctor - Merchant Marine",},
  {value: "9022", label: "Dog Catcher ",},
  {value: "9023", label: "Dogger",},
  {value: "9024", label: "Domestic Electrician",},
  {value: "9025", label: "Domestic Premises Cleaner",},
  {value: "9026", label: "Domestic Supervisor (Hospital)",},
  {value: "9027", label: "Domestic Tiler",},
  {value: "9028", label: "Donkeyman",},
  {value: "9029", label: "Door to Door Salesman",},
  {value: "2291", label: "Doorman",},
  {value: "9030", label: "Double Glazing - Installer/fitter ",},
  {value: "9031", label: "Double Glazing Surveyor ",},
  {value: "9032", label: "Drainage Layer/Clearer",},
  {value: "9033", label: "Draper",},
  {value: "2294", label: "Draughtsman",},
  {value: "9034", label: "Drawer (Bar, Plate, Rod, etc)",},
  {value: "2296", label: "Drayman",},
  {value: "9035", label: "Dredger Driver",},
  {value: "9036", label: "Dredgerman",},
  {value: "9037", label: "Dresser",},
  {value: "2297", label: "Dressmaker",},
  {value: "9038", label: "Drier",},
  {value: "9039", label: "Driller - offshore",},
  {value: "9040", label: "Driller - onshore",},
  {value: "9042", label: "Driver - construction",},
  {value: "9043", label: "Driver - delivery",},
  {value: "9041", label: "Driver - HGV",},
  {value: "9044", label: "Driver - industrial plant",},
  {value: "2302", label: "Driver - Psv",},
  {value: "9045", label: "Driver - refuse",},
  {value: "9046", label: "Driver - tractor",},
  {value: "2304", label: "Driving Examiner",},
  {value: "2305", label: "Driving Instructor",},
  {value: "9047", label: "Drop Ball Operator",},
  {value: "9048", label: "Dry Cleaning Machine Operator",},
  {value: "9049", label: "Dry Salter",},
  {value: "9050", label: "Dustman/Refuse Collector",},
  {value: "2313", label: "Dyer",},
  {value: "9051", label: "Ecological Consultant Outside UK",},
  {value: "9052", label: "Ecological Consultant UK",},
  {value: "2316", label: "Economist",},
  {value: "2317", label: "Editor",},
  {value: "9053", label: "Editorial Assistant",},
  {value: "9054", label: "Education Assistant",},
  {value: "9055", label: "Educational Advisor ",},
  {value: "9056", label: "Effluent Inspector",},
  {value: "9057", label: "Electric Logger",},
  {value: "2325", label: "Electrical Contractor",},
  {value: "2326", label: "Electrical Engineer",},
  {value: "2327", label: "Electrical Fitter",},
  {value: "9058", label: "Electrical Wireman",},
  {value: "9059", label: "Electrician - offshore",},
  {value: "9060", label: "Electrician UK based - domestic",},
  {value: "9061", label: "Electrician UK based - industrial",},
  {value: "9062", label: "Electronics Fitter",},
  {value: "9063", label: "Electronics Installer",},
  {value: "9064", label: "Electronics Mechanic",},
  {value: "9065", label: "Electronics Repairer",},
  {value: "9066", label: "Electronics Service Mechanic",},
  {value: "9067", label: "Electronics Wireman",},
  {value: "9068", label: "Electroplater",},
  {value: "9069", label: "Electrotyper",},
  {value: "2334", label: "Embalmer",},
  {value: "9070", label: "Embassy Employee",},
  {value: "2336", label: "Embroiderer",},
  {value: "9071", label: "Employment Agency Owner (admin. only)",},
  {value: "9072", label: "Employment Agency worker",},
  {value: "9073", label: "Enameller",},
  {value: "9074", label: "Engine Driver",},
  {value: "9075", label: "Engine Tester",},
  {value: "9076", label: "Engineer - admin and site visits only",},
  {value: "9077", label: "Engineer - admin only",},
  {value: "9078", label: "Engineer - heavy manual",},
  {value: "9079", label: "Engineer - light manual ",},
  {value: "9080", label: "Engineer - offshore",},
  {value: "9081", label: "Engineer - sales",},
  {value: "9082", label: "Engineer - works at heights over 40 ft",},
  {value: "9083", label: "Engineering Fitter",},
  {value: "9084", label: "Engineering Technician",},
  {value: "2342", label: "Engraver",},
  {value: "9085", label: "Enrolled Nurse",},
  {value: "9086", label: "Entertainer - Entertainment industry",},
  {value: "9087", label: "Entertainment Agent - Entertainment industry",},
  {value: "9088", label: "Entertainment Manager - Entertainment industry",},
  {value: "9089", label: "Entertainments Officer",},
  {value: "2347", label: "Environmental Health Officer",},
  {value: "9090", label: "Equestrian Artiste",},
  {value: "9091", label: "Equestrianism - Riding Instructor",},
  {value: "9092", label: "Equestrianism - Show Jumping",},
  {value: "9093", label: "Equipment Cleaner",},
  {value: "9094", label: "Erector - Aircraft/Aerospace",},
  {value: "9095", label: "Erector - Production Fitting - Metal Manufacture",},
  {value: "9096", label: "Escapologist",},
  {value: "2350", label: "Estate Agent",},
  {value: "9097", label: "Estate Manager - all aspects (no manual work)",},
  {value: "9098", label: "Estate Ranger",},
  {value: "2352", label: "Estimator",},
  {value: "9099", label: "Estimator (mainly office duties) ",},
  {value: "9101", label: "Etcher - Pottery Industry",},
  {value: "9102", label: "Etcher - Precious Metals, Engraving etc - Metal Manufacture",},
  {value: "9103", label: "Etcher - Printing Industry",},
  {value: "9100", label: "Etcher (creative)",},
  {value: "9104", label: "Examiner - process",},
  {value: "9105", label: "Excavator Driver",},
  {value: "2358", label: "Exhaust Fitter",},
  {value: "9106", label: "Exhausterman",},
  {value: "9107", label: "Exhibition Foreman",},
  {value: "9108", label: "Exhibition Space Sales Manager",},
  {value: "9109", label: "Exhibition Stand Fitter",},
  {value: "9110", label: "Explosives Inspector",},
  {value: "9111", label: "Export Agent",},
  {value: "9112", label: "Extruder",},
  {value: "9113", label: "Fabric Designer",},
  {value: "9114", label: "Fabricator - welder/fitter",},
  {value: "9115", label: "Facilities Assistant",},
  {value: "9116", label: "Facilities Procurement Officer",},
  {value: "9117", label: "Factory (worker)",},
  {value: "2369", label: "Factory Inspector",},
  {value: "9118", label: "Factory Manager (mainly admin.)",},
  {value: "2372", label: "Fairground Worker",},
  {value: "9119", label: "Farm Manager (manual duties)",},
  {value: "9120", label: "Farm Manager (no manual duties) ",},
  {value: "9121", label: "Farm Owner (manual duties)",},
  {value: "9122", label: "Farm Owner (no manual duties)",},
  {value: "9123", label: "Farm Worker/Labourer",},
  {value: "2377", label: "Farrier",},
  {value: "9124", label: "Fashion Model",},
  {value: "2379", label: "Fashion Photographer",},
  {value: "9125", label: "Fashion Stylist",},
  {value: "9126", label: "Fast Food Restaurant Assistant",},
  {value: "9127", label: "Fast Food Restaurant Manager (admin. only)",},
  {value: "9128", label: "Fat Extractor Man",},
  {value: "9129", label: "Fencing Contractor",},
  {value: "9130", label: "Ferryman",},
  {value: "9131", label: "Fight Arranger",},
  {value: "9132", label: "Film Developer",},
  {value: "9133", label: "Film Joiner",},
  {value: "9134", label: "Film Processor",},
  {value: "9135", label: "Filmsetting Machine Operator",},
  {value: "9136", label: "Financial Adviser",},
  {value: "9137", label: "Financial Planner/Paraplanner",},
  {value: "9138", label: "Finisher - toys and textiles",},
  {value: "9139", label: "Fire Eater",},
  {value: "2402", label: "Fire Prevention Officer",},
  {value: "9140", label: "Firefighter - Fire Service",},
  {value: "9143", label: "Fish and chip shop owner",},
  {value: "9144", label: "Fish and chip shop worker",},
  {value: "9141", label: "Fish Farmer",},
  {value: "9142", label: "Fish Preparer",},
  {value: "2415", label: "Fisherman",},
  {value: "9145", label: "Fishery Officer/Warden",},
  {value: "2417", label: "Fishmonger",},
  {value: "2419", label: "Fitness Instructor",},
  {value: "9146", label: "Fitter - Motor Vehicle & Cycle Industry",},
  {value: "9147", label: "Fitter-Assembler",},
  {value: "9148", label: "Fixer Mason",},
  {value: "9149", label: "Flame cutter - 40 ft +",},
  {value: "9150", label: "Flame cutter - under 40 ft",},
  {value: "9151", label: "Flight Attendant",},
  {value: "9152", label: "Flight Dispatcher",},
  {value: "9153", label: "Flight Operations Manager",},
  {value: "9154", label: "Flight Planner",},
  {value: "2424", label: "Floor Layer",},
  {value: "2425", label: "Floor Manager",},
  {value: "9155", label: "Floor Tiler",},
  {value: "9156", label: "Floorman",},
  {value: "9157", label: "Floorman - Oil Rig Industry ",},
  {value: "2426", label: "Florist",},
  {value: "9158", label: "Flour Confectioner",},
  {value: "9159", label: "Food Technologist",},
  {value: "9160", label: "Football Manager - Professional players",},
  {value: "9161", label: "Forecourt Attendant ",},
  {value: "9162", label: "Foreman - heavy manual",},
  {value: "9163", label: "Foreman - light manual",},
  {value: "9164", label: "Foreman - no manual",},
  {value: "9165", label: "Foreman - offshore",},
  {value: "9166", label: "Foreman - other",},
  {value: "2441", label: "Forest Ranger",},
  {value: "9167", label: "Forest Worker",},
  {value: "9168", label: "Forestry Officer",},
  {value: "9169", label: "Forge Hammerman",},
  {value: "9170", label: "Forge Pressman",},
  {value: "9171", label: "Forger",},
  {value: "2443", label: "Fork Lift Truck Driver",},
  {value: "9172", label: "Fortune Teller",},
  {value: "2445", label: "Foster Parent",},
  {value: "9173", label: "Frame Finisher",},
  {value: "9174", label: "Freezer Operator",},
  {value: "9175", label: "Freight Clerk",},
  {value: "9176", label: "Freight Manager - Airport",},
  {value: "9177", label: "Freight Manager - Docks",},
  {value: "2448", label: "French Polisher",},
  {value: "9178", label: "Fruitier",},
  {value: "9179", label: "Fuel Technologist",},
  {value: "2452", label: "Funeral Director",},
  {value: "9180", label: "Funeral Director's Assistant",},
  {value: "9181", label: "Furnace Control Room Operator",},
  {value: "9182", label: "Furnace Operator - Cemetery, Crematorium",},
  {value: "9183", label: "Furnace Operator - Other",},
  {value: "9184", label: "Furniture Designer",},
  {value: "9185", label: "Furniture Maker ",},
  {value: "2456", label: "Furniture Remover",},
  {value: "2457", label: "Furniture Restorer",},
  {value: "9186", label: "Furniture Retailer",},
  {value: "9188", label: "Gallery Assistant",},
  {value: "9189", label: "Gallery Owner (admin. only) ",},
  {value: "9190", label: "Gallery Owner (including manual work)",},
  {value: "9191", label: "Galley Hand",},
  {value: "9192", label: "Galvaniser",},
  {value: "2462", label: "Gamekeeper",},
  {value: "9193", label: "Ganger",},
  {value: "9194", label: "Gantry Crane Driver",},
  {value: "9195", label: "Garage - Mechanic",},
  {value: "9196", label: "Garage - Petrol Pump Attendant",},
  {value: "9197", label: "Garage Proprietor - admin only",},
  {value: "9198", label: "Garage Repair Shop Supervisor (including manual duties)",},
  {value: "2472", label: "Gardener",},
  {value: "9199", label: "Gas Appliance Mechanic",},
  {value: "9200", label: "Gas Compressor Operator",},
  {value: "2473", label: "Gas Fitter",},
  {value: "9201", label: "Gas Meter Tester",},
  {value: "9202", label: "Gem Cutter",},
  {value: "9203", label: "Gem Polisher",},
  {value: "9204", label: "Gem Setter",},
  {value: "9205", label: "Geologist - Mining",},
  {value: "9207", label: "Geologist - no aerial/offshore etc",},
  {value: "9206", label: "Geologist - Oil & Natural Gas Industries ",},
  {value: "9208", label: "Geophysicist - Mining",},
  {value: "9209", label: "Geophysicist - Oil & Natural Gas Industries ",},
  {value: "9210", label: "Glamour Model",},
  {value: "9211", label: "Glass Blower",},
  {value: "2484", label: "Glass Worker",},
  {value: "9212", label: "Glazer",},
  {value: "2485", label: "Glazier",},
  {value: "9213", label: "Gold Beater",},
  {value: "2486", label: "Goldsmith",},
  {value: "9214", label: "Golf - Caddie",},
  {value: "2492", label: "Governor",},
  {value: "9187", label: "GP - general practitioner - Doctor",},
  {value: "9215", label: "Grader",},
  {value: "9216", label: "Grain Merchant (office based)",},
  {value: "2494", label: "Graphic Designer",},
  {value: "2496", label: "Grave Digger",},
  {value: "9217", label: "Greaser",},
  {value: "9218", label: "Greenkeeper",},
  {value: "9219", label: "Grinder",},
  {value: "2500", label: "Grocer",},
  {value: "2501", label: "Groom",},
  {value: "9220", label: "Ground Equipment Service Mechanic",},
  {value: "9221", label: "Ground Hostess/Steward",},
  {value: "9222", label: "Ground Movement Controller",},
  {value: "2503", label: "Groundsman",},
  {value: "9223", label: "Groundworker",},
  {value: "9224", label: "Guard - railway",},
  {value: "9225", label: "Guard - security",},
  {value: "2506", label: "Guest House Proprietor",},
  {value: "9226", label: "Guest House Proprietor (admin. only) ",},
  {value: "9227", label: "Guillotine Operator",},
  {value: "9228", label: "Gummer",},
  {value: "9229", label: "Gunsmith",},
  {value: "9230", label: "Haberdasher ",},
  {value: "2511", label: "Hairdresser - Mobile",},
  {value: "9231", label: "Hairdresser - Salon",},
  {value: "9232", label: "Hairdresser Shop Manager - admin only",},
  {value: "9233", label: "Hairdresser Shop Proprietor",},
  {value: "9234", label: "Hammerman",},
  {value: "2512", label: "Handyman",},
  {value: "2513", label: "Harbour Master",},
  {value: "9235", label: "Harbour Pilot",},
  {value: "9236", label: "Hardware Shop Retailer",},
  {value: "9237", label: "Harness Maker",},
  {value: "9238", label: "Hat Maker",},
  {value: "9239", label: "Hatchery Worker",},
  {value: "2516", label: "Haulage Contractor",},
  {value: "9240", label: "Haulier (no driving)",},
  {value: "9241", label: "Head Gardener",},
  {value: "9242", label: "Head Groundsman",},
  {value: "9243", label: "Head Keeper - Zoo",},
  {value: "9244", label: "Head Roustabout",},
  {value: "9245", label: "Headteacher",},
  {value: "2523", label: "Health & Safety Officer",},
  {value: "9248", label: "Health and Fitness Club Manager (admin. only)",},
  {value: "9249", label: "Health and Fitness Club Trainer ",},
  {value: "9246", label: "Health Counsellor",},
  {value: "9247", label: "Health Radiation Monitor",},
  {value: "2529", label: "Health Visitor",},
  {value: "9250", label: "Heating and Ventilating Fitter",},
  {value: "9251", label: "Heavy Goods Driver (no loading) UK only ",},
  {value: "9252", label: "Heavy Goods Vehicle Driver",},
  {value: "9253", label: "Helicopter Engineer ",},
  {value: "9254", label: "Helicopter Pilot - Oil Rig Industry ",},
  {value: "9255", label: "Helicopter Pilot - Onshore",},
  {value: "9256", label: "Historic Building Guide",},
  {value: "9257", label: "Hod Carrier - construction",},
  {value: "9258", label: "Hoist Driver",},
  {value: "9259", label: "Hoist Operator",},
  {value: "9260", label: "Holiday Representative",},
  {value: "2548", label: "Home Help",},
  {value: "9261", label: "Homeless Centre Manager (admin. only) ",},
  {value: "2550", label: "Homeopath",},
  {value: "2553", label: "Horse Breeder",},
  {value: "9262", label: "Horse Racing - Flat Jockey",},
  {value: "9263", label: "Horse Racing - National Hunt",},
  {value: "9264", label: "Horticulturist",},
  {value: "9265", label: "Hospital Matron",},
  {value: "9266", label: "Hospital Porter - Health",},
  {value: "9267", label: "Hospital Storeman",},
  {value: "9268", label: "Hospital Ward Orderly",},
  {value: "9269", label: "Hostel Matron",},
  {value: "9270", label: "Hostel Warden",},
  {value: "2570", label: "Hostess",},
  {value: "9271", label: "Hotel Concierge",},
  {value: "9272", label: "Hotel Detective",},
  {value: "9273", label: "Hotel Doorman",},
  {value: "9274", label: "Hotel Maid",},
  {value: "9275", label: "Hotel Manager (office based) ",},
  {value: "9276", label: "Hotel Porter",},
  {value: "9277", label: "Hotel Proprietor",},
  {value: "9278", label: "Hotel Receptionist",},
  {value: "9279", label: "House Maid",},
  {value: "2579", label: "Housekeeper",},
  {value: "9280", label: "Housewife/House-Husband",},
  {value: "9281", label: "Housing Association Development Manager (inc. site visits)",},
  {value: "9282", label: "Housing Inspector",},
  {value: "9283", label: "Housing Manager ",},
  {value: "9284", label: "Human Resources Advisor",},
  {value: "9285", label: "Human Resources Analyst",},
  {value: "9286", label: "Human Resources Assistant",},
  {value: "9287", label: "Human Resources Consultant",},
  {value: "9288", label: "Human Resources Officer",},
  {value: "9289", label: "Hydro-Extractor Operator",},
  {value: "9290", label: "Hydrographic Engineer/Surveyor",},
  {value: "2589", label: "Hygienist",},
  {value: "2590", label: "Hypnotherapist",},
  {value: "2591", label: "Hypnotist",},
  {value: "9295", label: "Ice Cream Van Driver",},
  {value: "9296", label: "Illusionist",},
  {value: "2593", label: "Illustrator",},
  {value: "9297", label: "Immigration Officer - admin only",},
  {value: "9298", label: "Immigration Officer - otherwise",},
  {value: "9299", label: "Impersonator",},
  {value: "2596", label: "Importer",},
  {value: "9300", label: "Incinerator Operator",},
  {value: "9301", label: "Independent Financial Adviser - IFA",},
  {value: "2599", label: "Industrial Chemist",},
  {value: "2601", label: "Industrial Designer",},
  {value: "9302", label: "Industrial Relations Officer",},
  {value: "9303", label: "Industrial Trainer",},
  {value: "9304", label: "Inseminator",},
  {value: "9305", label: "Inspector (not police)",},
  {value: "9306", label: "Instructor - aviation, diving, etc",},
  {value: "9307", label: "Instructor - no special risks",},
  {value: "2608", label: "Instrument Maker",},
  {value: "9308", label: "Instrument Repairer ",},
  {value: "9309", label: "Insulator - asbestos work inc",},
  {value: "9310", label: "Insulator - no asbestos work",},
  {value: "2612", label: "Insurance Agent",},
  {value: "2613", label: "Insurance Assessor",},
  {value: "2614", label: "Insurance Broker",},
  {value: "2616", label: "Insurance Inspector",},
  {value: "2620", label: "Interior Designer",},
  {value: "9311", label: "Internal Auditor",},
  {value: "2622", label: "Interpreter",},
  {value: "9312", label: "Investment Analyst",},
  {value: "9313", label: "Ironer",},
  {value: "9291", label: "IT Analyst",},
  {value: "9292", label: "IT Manager - admin only",},
  {value: "9293", label: "IT Programmer",},
  {value: "9294", label: "IT Technician",},
  {value: "2635", label: "Janitor",},
  {value: "9314", label: "Jetty Hand",},
  {value: "2636", label: "Jeweller",},
  {value: "9315", label: "Jewellery Enameller",},
  {value: "9316", label: "Jewellery Making & Repair",},
  {value: "9317", label: "Jewellery Mounter",},
  {value: "9318", label: "Joiner - Construction Industry",},
  {value: "9319", label: "Joiner - Ship Building, Ship Repair & Marine Engineering",},
  {value: "9320", label: "Jointer",},
  {value: "9321", label: "Journalist - no overseas travel etc",},
  {value: "9322", label: "Journalist - otherwise",},
  {value: "2644", label: "Judge",},
  {value: "9323", label: "Judge's Clerk",},
  {value: "9324", label: "Juggler",},
  {value: "2646", label: "Justice Of The Peace",},
  {value: "9325", label: "Kebab Van Vendor",},
  {value: "9326", label: "Keeper - Zoo",},
  {value: "2648", label: "Kennel Hand",},
  {value: "9327", label: "Kerb Layer",},
  {value: "9328", label: "Key Cutter",},
  {value: "9329", label: "Keyboard Operator (type setting)",},
  {value: "9330", label: "Kiln Attendant",},
  {value: "9331", label: "Kiln Operator",},
  {value: "9332", label: "Kitchen Porter",},
  {value: "9333", label: "Kitchen Staff",},
  {value: "9334", label: "Knife Thrower",},
  {value: "2655", label: "Knitter",},
  {value: "9335", label: "Labeller",},
  {value: "9336", label: "Laboratory Manager (supervisory and some testing)",},
  {value: "2663", label: "Laboratory Technician",},
  {value: "2664", label: "Labourer",},
  {value: "9337", label: "Lagger",},
  {value: "2665", label: "Laminator",},
  {value: "2667", label: "Land Agent",},
  {value: "9338", label: "Land Drainage Worker",},
  {value: "2668", label: "Land Surveyor",},
  {value: "9339", label: "Landlord (Property -inc manual work)",},
  {value: "9340", label: "Landlord (Property -no manual work )",},
  {value: "2673", label: "Landscape Gardener",},
  {value: "9341", label: "Landscape Painter",},
  {value: "2676", label: "Lathe Operator",},
  {value: "9342", label: "Launderette Assistant",},
  {value: "9343", label: "Laundryman",},
  {value: "2679", label: "Lavatory Attendant",},
  {value: "2682", label: "Lawyer",},
  {value: "9344", label: "Leading Fireman",},
  {value: "9345", label: "Leather Technologist",},
  {value: "2684", label: "Leather Worker",},
  {value: "2685", label: "Lecturer",},
  {value: "9346", label: "Left Luggage Attendant",},
  {value: "9347", label: "Legal Adviser",},
  {value: "2689", label: "Legal Executive",},
  {value: "9348", label: "Legal Practice Manager",},
  {value: "2690", label: "Legal Secretary",},
  {value: "9349", label: "Letting Agent - Holiday homes",},
  {value: "2696", label: "Librarian",},
  {value: "2697", label: "Library Assistant",},
  {value: "9350", label: "Life Coach",},
  {value: "9352", label: "Lifeboatman - Crew ",},
  {value: "9351", label: "Lifeboatman (enrolled crew)",},
  {value: "2701", label: "Lifeguard",},
  {value: "2702", label: "Lift Attendant",},
  {value: "2703", label: "Lift Engineer",},
  {value: "9353", label: "Lift Erector",},
  {value: "9354", label: "Light Goods Vehicle Driver",},
  {value: "2704", label: "Lighterman",},
  {value: "9355", label: "Lighting Manager",},
  {value: "2707", label: "Lighting Technician",},
  {value: "9356", label: "Linesman",},
  {value: "9357", label: "Linesman's Mate",},
  {value: "9358", label: "Liquidator",},
  {value: "2712", label: "Literary Agent",},
  {value: "2714", label: "Lithographer",},
  {value: "9359", label: "Lithographic Assistant",},
  {value: "9360", label: "Lithographic Plate Grainer",},
  {value: "9361", label: "Lithographic Plate Preparer",},
  {value: "2716", label: "Loader",},
  {value: "9362", label: "Loader Operator",},
  {value: "9363", label: "Lobster Fisherman",},
  {value: "2719", label: "Local Government Officer",},
  {value: "9364", label: "Local Newspaper Editor",},
  {value: "2720", label: "Lock Keeper",},
  {value: "2721", label: "Locksmith",},
  {value: "9365", label: "Locomotive Driver",},
  {value: "9366", label: "Locomotive Guard",},
  {value: "9367", label: "Lollipop Man/Lady",},
  {value: "9368", label: "Loss Adjuster",},
  {value: "2729", label: "Lumberjack",},
  {value: "9369", label: "Machine Attendant",},
  {value: "9370", label: "Machine Maintenance Worker",},
  {value: "9371", label: "Machine Operator - processing",},
  {value: "9372", label: "Machine Tool Setter-Operator",},
  {value: "9373", label: "Machinery Electrician",},
  {value: "2740", label: "Machinist",},
  {value: "9374", label: "Magazine Editor",},
  {value: "9375", label: "Magazine Illustrator ",},
  {value: "9376", label: "Magazine Writer",},
  {value: "2741", label: "Magician",},
  {value: "9377", label: "Magistrate - Stipendiary",},
  {value: "9378", label: "Mail Sorter ",},
  {value: "2746", label: "Maintenance Fitter",},
  {value: "2748", label: "Maintenance Manager",},
  {value: "9379", label: "Maintenance Repairer",},
  {value: "9380", label: "Maintenance Technician",},
  {value: "9381", label: "Maitre d'Hotel",},
  {value: "9382", label: "Make-up Artist",},
  {value: "9383", label: "Malt Roaster",},
  {value: "9384", label: "Maltster",},
  {value: "9385", label: "Management Consultant - Usually",},
  {value: "9389", label: "Manager - admin only",},
  {value: "9390", label: "Manager - heavy manual work",},
  {value: "9391", label: "Manager - light manual work",},
  {value: "9386", label: "Manager - Offshore",},
  {value: "9392", label: "Manager - other",},
  {value: "9387", label: "Manager - Retail",},
  {value: "9388", label: "Manager - Sales",},
  {value: "9393", label: "Manager (off site)",},
  {value: "9398", label: "Managing Director - admin/office based only",},
  {value: "9399", label: "Managing Director - heavy manual duties",},
  {value: "9400", label: "Managing Director - light manual duties",},
  {value: "9394", label: "Managing Director - Other",},
  {value: "9395", label: "Managing Director - Retail",},
  {value: "9396", label: "Managing Director - Sales Management",},
  {value: "9397", label: "Managing Director - Selling",},
  {value: "9401", label: "Manhole Maker",},
  {value: "2763", label: "Manicurist",},
  {value: "9402", label: "Marine Biologist",},
  {value: "2775", label: "Marine Engineer",},
  {value: "9403", label: "Marine Installation Fitter",},
  {value: "2778", label: "Marine Surveyor",},
  {value: "2779", label: "Market Gardener",},
  {value: "9409", label: "Market or Street Trader",},
  {value: "9410", label: "Market or Street Traders Assistant",},
  {value: "9404", label: "Market Porter - Usually",},
  {value: "9405", label: "Market Research Analyst",},
  {value: "9406", label: "Market Research Interviewer",},
  {value: "9408", label: "Market Researcher - Street research",},
  {value: "9407", label: "Market Researcher (office based) ",},
  {value: "9411", label: "Marketing Consultant - International ",},
  {value: "2794", label: "Marketing Manager",},
  {value: "9412", label: "Marketing Research Manager (office based)",},
  {value: "9413", label: "Marriage Guidance Counsellor",},
  {value: "9414", label: "Martial Arts Instructor",},
  {value: "9415", label: "Mason",},
  {value: "9416", label: "Mason Bricklayer",},
  {value: "9417", label: "Mason's Labourer",},
  {value: "2797", label: "Masseur",},
  {value: "2798", label: "Masseuse",},
  {value: "9418", label: "Mate, Second Hand",},
  {value: "2803", label: "Mathematician",},
  {value: "2805", label: "Mattress Maker",},
  {value: "9419", label: "Meat Cutter",},
  {value: "2808", label: "Meat Inspector",},
  {value: "9420", label: "Meat Trimmer",},
  {value: "2810", label: "Mechanic",},
  {value: "9421", label: "Mechanic - Oil Rig",},
  {value: "2813", label: "Mechanical Engineer",},
  {value: "2823", label: "Medical Practitioner",},
  {value: "9422", label: "Medical Receptionist",},
  {value: "2825", label: "Medical Secretary",},
  {value: "9423", label: "Medium",},
  {value: "9424", label: "Member of Parliament, Politician",},
  {value: "9425", label: "Messenger - Motorcycle",},
  {value: "9426", label: "Messenger - Not motorcycle",},
  {value: "9427", label: "Metallographer",},
  {value: "2840", label: "Metallurgist",},
  {value: "2842", label: "Meteorologist",},
  {value: "9428", label: "Meter Collector",},
  {value: "9429", label: "Meter Fixer/Tester",},
  {value: "2843", label: "Meter Reader",},
  {value: "2845", label: "Microfilm Operator",},
  {value: "2846", label: "Midwife",},
  {value: "9430", label: "Milk Roundsman",},
  {value: "9431", label: "Milker",},
  {value: "2851", label: "Miller",},
  {value: "2852", label: "Milliner",},
  {value: "2854", label: "Miner",},
  {value: "9432", label: "Mineralogist",},
  {value: "9433", label: "Mini Cab Driver ",},
  {value: "2859", label: "Mining Engineer",},
  {value: "9434", label: "Mining Officer",},
  {value: "9435", label: "Mining Surveyor",},
  {value: "2860", label: "Minister Of Religion",},
  {value: "2861", label: "Missionary",},
  {value: "9436", label: "Mixer - processing",},
  {value: "9437", label: "Mobile Crane Driver",},
  {value: "2869", label: "Model Maker",},
  {value: "2870", label: "Money Broker",},
  {value: "9438", label: "Mortuary Attendant",},
  {value: "9439", label: "Motor Bike Instructor",},
  {value: "9440", label: "Motor Cycle Courier",},
  {value: "9441", label: "Motor Cycle Messenger",},
  {value: "9442", label: "Motor Fleet Manager ",},
  {value: "9443", label: "Motor Service Manager - admin only",},
  {value: "9444", label: "Motorman",},
  {value: "9445", label: "Mould Maker",},
  {value: "9446", label: "Moulder",},
  {value: "9447", label: "Mud Engineer",},
  {value: "9448", label: "Mud Logger",},
  {value: "9449", label: "Mud Man",},
  {value: "2887", label: "Museum Attendant",},
  {value: "9450", label: "Museum Curator",},
  {value: "9451", label: "Museum Guide",},
  {value: "9452", label: "Music Teacher (Private)",},
  {value: "9453", label: "Musical Instrument Maker",},
  {value: "9454", label: "Musical Instrument Repairer",},
  {value: "9455", label: "Musician - Professional",},
  {value: "9457", label: "Nail Technician/Beautician",},
  {value: "2898", label: "Nanny",},
  {value: "9458", label: "Nature Reserve Worker/Warden",},
  {value: "2900", label: "Navigator",},
  {value: "9459", label: "News Photographer - no overseas travel etc",},
  {value: "9460", label: "News Photographer - otherwise",},
  {value: "9461", label: "Newsagent (not delivering papers)",},
  {value: "9462", label: "Newspaper Reporter - Freelance",},
  {value: "9463", label: "Newsreader",},
  {value: "9464", label: "Newsvendor",},
  {value: "9456", label: "NHS Manager",},
  {value: "2911", label: "Night Watchman",},
  {value: "9465", label: "Nuclear Engineer",},
  {value: "9466", label: "Nuclear Plant Attendant",},
  {value: "2916", label: "Nuclear Scientist",},
  {value: "2918", label: "Nurse",},
  {value: "9467", label: "Nurse - Midwife",},
  {value: "9468", label: "Nurse - Sister",},
  {value: "9469", label: "Nurse - Teaching duties only",},
  {value: "9470", label: "Nursery School Assistant",},
  {value: "2923", label: "Nurseryman",},
  {value: "2925", label: "Nursing Auxiliary",},
  {value: "9471", label: "Nursing Home Proprietor (admin. only)",},
  {value: "9472", label: "Obstetrician",},
  {value: "2932", label: "Occupational Therapist",},
  {value: "9473", label: "Oceanographer",},
  {value: "2936", label: "Office Clerk",},
  {value: "9476", label: "Office Fitter",},
  {value: "9477", label: "Office Messenger",},
  {value: "9478", label: "Office Receptionist",},
  {value: "9474", label: "Off-Licence Employee",},
  {value: "9475", label: "Off-Licence Manager",},
  {value: "9479", label: "Oiler and Greaser",},
  {value: "9480", label: "Old People's Home Matron",},
  {value: "9481", label: "Old People's Home Warden",},
  {value: "9482", label: "Operations Manager - Light engineering company",},
  {value: "9483", label: "Operations Officer",},
  {value: "9484", label: "Optical Instrument Fitter",},
  {value: "9485", label: "Optical Instrument Maker",},
  {value: "9486", label: "Optical Instrument Mechanic",},
  {value: "9487", label: "Optical Instrument Repairer",},
  {value: "9488", label: "Optical Printer",},
  {value: "2952", label: "Optician",},
  {value: "9489", label: "Opticians Assistant ",},
  {value: "9490", label: "Orchestrator",},
  {value: "9491", label: "Orthodontic Technician",},
  {value: "9492", label: "Orthodontist",},
  {value: "9493", label: "Orthopaedic Surgeon ",},
  {value: "2960", label: "Orthoptist",},
  {value: "2961", label: "Osteopath",},
  {value: "9494", label: "Other - Occupation not listed",},
  {value: "9495", label: "Outdoor Pursuits Centre Instructor",},
  {value: "9496", label: "Ovensman",},
  {value: "9497", label: "Overhead Crane Driver",},
  {value: "9498", label: "Overhead Linesman",},
  {value: "9499", label: "Overhead Linesman's Mate",},
  {value: "9500", label: "Oyster Fisherman",},
  {value: "9501", label: "P E Teacher",},
  {value: "9503", label: "Packaging Machine Attendant",},
  {value: "2973", label: "Packer",},
  {value: "2980", label: "Painter",},
  {value: "9507", label: "Painter - Woodworking Industry",},
  {value: "9504", label: "Painter & Decorator (Interior)",},
  {value: "9505", label: "Painter (Exterior) - 40' up",},
  {value: "9506", label: "Painter (Exterior) - up to 40'",},
  {value: "2985", label: "Panel Beater",},
  {value: "9508", label: "Paper Maker (hand)",},
  {value: "9509", label: "Paper Merchant",},
  {value: "9510", label: "Paramedic (Driver)",},
  {value: "9511", label: "Paramedic (No driving)",},
  {value: "2990", label: "Park Keeper",},
  {value: "2991", label: "Park Ranger",},
  {value: "9512", label: "Parks Superintendent",},
  {value: "9513", label: "Parliamentary Agent",},
  {value: "9514", label: "Party Organiser ",},
  {value: "9515", label: "Passenger Officer",},
  {value: "2998", label: "Pasteuriser",},
  {value: "3000", label: "Patent Agent",},
  {value: "3002", label: "Pathologist",},
  {value: "9516", label: "Pattern Maker - Metal, Plastic etc",},
  {value: "9517", label: "Pavior",},
  {value: "3009", label: "Pawnbroker",},
  {value: "9518", label: "Pedicurist",},
  {value: "9519", label: "Personal Assistant (PA)",},
  {value: "9520", label: "Pest Control Manager",},
  {value: "9521", label: "Pest Control Operator",},
  {value: "9522", label: "Petrol Pump Attendant",},
  {value: "3029", label: "Pharmacist",},
  {value: "9523", label: "Pharmacologist",},
  {value: "9524", label: "Pharmacy Assistant",},
  {value: "3033", label: "Phlebotomist",},
  {value: "9525", label: "Photocopier Engineer",},
  {value: "9526", label: "Photocopying Machine Operator",},
  {value: "3039", label: "Photographer",},
  {value: "9527", label: "Photographer - Aerial",},
  {value: "9528", label: "Photographic Finisher",},
  {value: "9529", label: "Photographic Model",},
  {value: "9530", label: "Photographic Mounter",},
  {value: "3043", label: "Physician",},
  {value: "3044", label: "Physicist",},
  {value: "3046", label: "Physiotherapist",},
  {value: "9531", label: "Piano/Organ Tuner",},
  {value: "9532", label: "Pickler",},
  {value: "3051", label: "Picture Framer",},
  {value: "9533", label: "Pier Master",},
  {value: "9534", label: "Pile Driver",},
  {value: "3056", label: "Pilot",},
  {value: "3057", label: "Pipe Fitter",},
  {value: "9535", label: "Pipe Jointer",},
  {value: "3060", label: "Pipe Layer",},
  {value: "9536", label: "Pipe/Powerline Survey Work",},
  {value: "9537", label: "Pitch Melter",},
  {value: "3062", label: "Planning Engineer",},
  {value: "9538", label: "Planning Inspector",},
  {value: "3066", label: "Plant Attendant",},
  {value: "9539", label: "Plant Hire Manager (some manual work)",},
  {value: "9540", label: "Plant Hire Owner (some manual work) ",},
  {value: "9541", label: "Plant Hire Proprietor (admin. only) ",},
  {value: "3071", label: "Plant Operator",},
  {value: "3072", label: "Plasterer",},
  {value: "9542", label: "Plastic Coating Operator",},
  {value: "9543", label: "Plate Cutter",},
  {value: "9544", label: "Plate Moulder",},
  {value: "9545", label: "Plate Separator",},
  {value: "9546", label: "Platelayer",},
  {value: "9547", label: "Plateman",},
  {value: "9549", label: "Plater - Aircraft/Aerospace",},
  {value: "9550", label: "Plater - Motor Vehicle & Cycle Industry",},
  {value: "9551", label: "Plater - Ship Building, Ship Repair & Marine Engineering",},
  {value: "9548", label: "Plater (including Boiler)",},
  {value: "9552", label: "Playschool/Group Worker/Leader",},
  {value: "9553", label: "Playwright",},
  {value: "9554", label: "Plumber - Construction/Industrial",},
  {value: "9555", label: "Plumber - Domestic",},
  {value: "9556", label: "Pneumatic Drill Operator",},
  {value: "9557", label: "Poet",},
  {value: "9558", label: "Pointsman",},
  {value: "3082", label: "Police",},
  {value: "9559", label: "Police Frogman",},
  {value: "9560", label: "Politician ",},
  {value: "9561", label: "Pollution Inspector",},
  {value: "9562", label: "Pop Musician",},
  {value: "9563", label: "Pop Singer",},
  {value: "9564", label: "Port Control Signalman",},
  {value: "9565", label: "Port Health Inspector",},
  {value: "9566", label: "Porter - Meat, Fish etc - Food & Drink",},
  {value: "9567", label: "Porter - Station Personnel - Railways",},
  {value: "9568", label: "Portrait Painter",},
  {value: "9569", label: "Portrait Photographer",},
  {value: "9570", label: "Postal Courier Driver",},
  {value: "9571", label: "Postal Courier Manager ",},
  {value: "3097", label: "Postman",},
  {value: "9572", label: "Postman (no driving)",},
  {value: "9573", label: "Pot Fisherman",},
  {value: "9574", label: "Potman",},
  {value: "3102", label: "Potter",},
  {value: "9575", label: "Poultry Dresser",},
  {value: "9576", label: "Poultry Plucker",},
  {value: "9577", label: "Poultryman",},
  {value: "9578", label: "Power Loader Man",},
  {value: "9579", label: "Power Loader Operator",},
  {value: "9580", label: "Power Station Charge Engineer",},
  {value: "9581", label: "Power Station Manager",},
  {value: "9582", label: "Power Station Superintendent",},
  {value: "9502", label: "PR Executive",},
  {value: "3103", label: "Practice Manager",},
  {value: "9583", label: "Precision Instrument Fitter",},
  {value: "9584", label: "Precision Instrument Maker",},
  {value: "9585", label: "Precision Instrument Repairer",},
  {value: "9586", label: "Preparer",},
  {value: "9587", label: "Press Cutter",},
  {value: "3108", label: "Press Officer",},
  {value: "9588", label: "Press Operator - processing",},
  {value: "9589", label: "Press Tool Setter",},
  {value: "9590", label: "Presser - Fruit & Veg. - Food & Drink",},
  {value: "9591", label: "Presser - Laundry",},
  {value: "3112", label: "Priest",},
  {value: "3114", label: "Printer",},
  {value: "9592", label: "Printing Director (purely admin.)",},
  {value: "3116", label: "Prison Officer",},
  {value: "9593", label: "Private Detective",},
  {value: "3119", label: "Probation Officer",},
  {value: "3123", label: "Process Worker",},
  {value: "9594", label: "Producer - TV/film/theatre",},
  {value: "3133", label: "Production Manager",},
  {value: "3139", label: "Professional Sportsperson",},
  {value: "9595", label: "Project Co-ordinator",},
  {value: "9596", label: "Project Manager/Programme Manager",},
  {value: "3151", label: "Projectionist",},
  {value: "9597", label: "Prompter",},
  {value: "9598", label: "Proofer",},
  {value: "9599", label: "Property & Estate Manager",},
  {value: "9600", label: "Property Developer(no manual work)",},
  {value: "3158", label: "Property Manager",},
  {value: "3161", label: "Proprietor",},
  {value: "3163", label: "Psychiatrist",},
  {value: "3166", label: "Psychologist",},
  {value: "3167", label: "Psychotherapist",},
  {value: "9601", label: "Public Hall Bookings Office Manager ",},
  {value: "9602", label: "Public Health Inspector",},
  {value: "9603", label: "Public House Manager (salaried)",},
  {value: "9604", label: "Public Lighting Fitter-Erector",},
  {value: "3170", label: "Public Relations Officer",},
  {value: "3172", label: "Publican",},
  {value: "3174", label: "Publisher",},
  {value: "9605", label: "Pumpman",},
  {value: "9606", label: "Puppeteer",},
  {value: "9607", label: "Purchasing Officer/Manager (not retail)",},
  {value: "9608", label: "Purifier Man",},
  {value: "3180", label: "Purser",},
  {value: "9609", label: "Pusherman",},
  {value: "9610", label: "Quality Control Engineer",},
  {value: "9611", label: "Quality Control Inspector",},
  {value: "3186", label: "Quantity Surveyor",},
  {value: "9612", label: "Quarry Manager",},
  {value: "9613", label: "Quarryman",},
  {value: "9614", label: "Quartermaster",},
  {value: "9615", label: "Queen?s Counsel",},
  {value: "3190", label: "Rabbi",},
  {value: "9617", label: "Racetrack Steward",},
  {value: "9618", label: "Radar Controller/Operator",},
  {value: "9619", label: "Radar Observer",},
  {value: "9621", label: "Radio and TV Repairer",},
  {value: "9620", label: "Radio Station Manager",},
  {value: "9622", label: "Radio/Radar Operator",},
  {value: "9623", label: "Radio/TV Announcer/Presenter",},
  {value: "9624", label: "Radio/TV Director/Producer",},
  {value: "9625", label: "Radiographer - Health",},
  {value: "3199", label: "Radiologist",},
  {value: "9626", label: "Radiotherapist",},
  {value: "9627", label: "Rag & Bone Dealer",},
  {value: "3203", label: "Receptionist",},
  {value: "9628", label: "Record Producer - Entertainment Industry",},
  {value: "9629", label: "Recording Engineer",},
  {value: "3207", label: "Recruitment Consultant",},
  {value: "3209", label: "Rector",},
  {value: "3213", label: "Reflexologist",},
  {value: "3216", label: "Refuse Collector",},
  {value: "3217", label: "Registrar",},
  {value: "9630", label: "Reinsurance Broker",},
  {value: "9631", label: "Relationship Manager",},
  {value: "3221", label: "Rent Collector",},
  {value: "9632", label: "Reporter/Writer - no overseas travel etc",},
  {value: "9633", label: "Reporter/Writer - otherwise",},
  {value: "9634", label: "Rescue Diver",},
  {value: "9635", label: "Research Chemist (Managerial)",},
  {value: "9636", label: "Research Information Officer (Office based) ",},
  {value: "9637", label: "Research Projects Manager (Deals with hazardous substances) ",},
  {value: "9638", label: "Research Survey Clerk",},
  {value: "9639", label: "Researcher - Journalism",},
  {value: "9640", label: "Researcher - Radio & TV - Entertainment",},
  {value: "9641", label: "Reservations Clerk",},
  {value: "9642", label: "Reservoir Attendant",},
  {value: "9643", label: "Residential Care Worker",},
  {value: "9644", label: "Residential Home Proprietor (Admin. only)",},
  {value: "9645", label: "Residential Home Proprietor (full involvement in caring)",},
  {value: "9646", label: "Restaurant Proprietor - no cooking",},
  {value: "9647", label: "Restorer (Paintings)",},
  {value: "9648", label: "Restorer (Stone, Brickwork)",},
  {value: "9649", label: "Retail Shop Manager ",},
  {value: "9650", label: "Retail Shop Manager - admin only",},
  {value: "3243", label: "Retired",},
  {value: "3246", label: "Riding Instructor",},
  {value: "9651", label: "Rig Electrician",},
  {value: "9652", label: "Rig Maintenance Diver",},
  {value: "9653", label: "Rig Mechanic",},
  {value: "9654", label: "Rig Medic",},
  {value: "9655", label: "Rigger - Docks",},
  {value: "9656", label: "Rigger - Film Industry - Entertainment",},
  {value: "9657", label: "Rigger - Gas Supply Industry",},
  {value: "9658", label: "Rigger - Industrial/Plant Machinery",},
  {value: "9659", label: "Rigger - Oil & Natural Gas Industries ",},
  {value: "9660", label: "Rigger - Ship Building, Ship Repair & Marine Engineering",},
  {value: "9661", label: "Ripper",},
  {value: "9662", label: "River Inspector",},
  {value: "3250", label: "Riveter",},
  {value: "9663", label: "Road Crew Member - 'Roadie'",},
  {value: "9664", label: "Road Manager - Rock band",},
  {value: "9665", label: "Road Marker",},
  {value: "9666", label: "Road Patrolman ",},
  {value: "3251", label: "Road Safety Officer",},
  {value: "9668", label: "Road Sweeper - Mechanical",},
  {value: "9667", label: "Road Sweeper (hand)",},
  {value: "9669", label: "Road Tester - Garage Trade",},
  {value: "9670", label: "Road Tester - Motor Vehicle & Cycle Industry",},
  {value: "9671", label: "Road Worker/Labourer",},
  {value: "9672", label: "Roaster",},
  {value: "9673", label: "Rodent Destroyer",},
  {value: "9674", label: "Roller Blind Maker",},
  {value: "9675", label: "Rollerman ",},
  {value: "9676", label: "Roofer - 40' up",},
  {value: "9677", label: "Roofer - up to 40'",},
  {value: "9678", label: "Roofing Inspector (Mostly office based - some estimating)",},
  {value: "9679", label: "Rope Maker",},
  {value: "9680", label: "Roughneck",},
  {value: "9681", label: "Roustabout",},
  {value: "9682", label: "Roustabout Pusher - Oil Rig Industry",},
  {value: "9616", label: "RSPCA Inspector",},
  {value: "9683", label: "Rubber & Plastics Worker",},
  {value: "9684", label: "Rubber Technologist",},
  {value: "3262", label: "Saddler",},
  {value: "9685", label: "Safety Inspector",},
  {value: "3263", label: "Safety Officer",},
  {value: "9686", label: "Safety Officer - Oil Rig Industry",},
  {value: "9687", label: "Sailing Instructor",},
  {value: "9688", label: "Salary Administrator",},
  {value: "9689", label: "Sales & Marketing Manager",},
  {value: "9690", label: "Sales Assistant - retail",},
  {value: "3275", label: "Sales Executive",},
  {value: "3276", label: "Sales Manager",},
  {value: "9691", label: "Sales Support Administrator",},
  {value: "9692", label: "Salter",},
  {value: "9693", label: "Salvage Diver",},
  {value: "9694", label: "Salvage Man",},
  {value: "9695", label: "Sandblaster",},
  {value: "9696", label: "Satellite Aerial Fixer (domestic only)",},
  {value: "9697", label: "Saturation Tank Attendant",},
  {value: "9698", label: "Saw Doctor or Repairer or Sharpener",},
  {value: "3284", label: "Scaffolder",},
  {value: "9699", label: "Scaffolder Offshore Oil or Gas",},
  {value: "9700", label: "Scene Shifter",},
  {value: "9701", label: "Scenery Painter",},
  {value: "9702", label: "School Assistant",},
  {value: "9703", label: "School Bursar",},
  {value: "3286", label: "School Inspector",},
  {value: "9704", label: "Scrap Breaker",},
  {value: "3290", label: "Scrap Dealer",},
  {value: "3291", label: "Screen Printer",},
  {value: "9705", label: "Screener - Quarrying",},
  {value: "9706", label: "Screenmaker",},
  {value: "9707", label: "Screwman",},
  {value: "3293", label: "Script Writer",},
  {value: "3294", label: "Sculptor",},
  {value: "3295", label: "Seaman",},
  {value: "3298", label: "Secretary",},
  {value: "3301", label: "Security Consultant",},
  {value: "3303", label: "Security Guard",},
  {value: "9708", label: "Security Manager",},
  {value: "9709", label: "Seismologist",},
  {value: "9710", label: "Set Designer",},
  {value: "9711", label: "Sewage Works Attendant",},
  {value: "9712", label: "Sewage Works Manager",},
  {value: "9713", label: "Sewerman",},
  {value: "9714", label: "Sewing Machine Mechanic ",},
  {value: "9715", label: "Sewing Machinist",},
  {value: "9716", label: "Shaftsman",},
  {value: "9717", label: "Sheep Shearer",},
  {value: "9718", label: "Sheet Fixer",},
  {value: "3315", label: "Sheet Metal Worker",},
  {value: "3316", label: "Shelf Filler",},
  {value: "3318", label: "Shepherd",},
  {value: "3326", label: "Shipping Clerk",},
  {value: "9719", label: "Ship's Broker",},
  {value: "3330", label: "Shoe Maker",},
  {value: "3331", label: "Shoe Repairer",},
  {value: "3334", label: "Shop Assistant",},
  {value: "3335", label: "Shop Fitter",},
  {value: "9720", label: "Shotblaster",},
  {value: "9721", label: "Shotfirer",},
  {value: "9722", label: "Shredding Machine Operator",},
  {value: "9723", label: "Shunter - Marshalling/Goods Yard - Railways",},
  {value: "9724", label: "Shunter - Mining",},
  {value: "9725", label: "Sieve Operator - food",},
  {value: "9726", label: "Sieve Operator - quarry",},
  {value: "9727", label: "Sifter - food",},
  {value: "9728", label: "Sifter - quarry",},
  {value: "9729", label: "Sign Writer (40' up)",},
  {value: "9730", label: "Sign Writer (no work at heights)",},
  {value: "3346", label: "Signalman",},
  {value: "9731", label: "Siloman",},
  {value: "9732", label: "Siloman - docks",},
  {value: "9733", label: "Siloman - quarry",},
  {value: "9734", label: "Silversmith ",},
  {value: "9735", label: "Sister (Hospital)",},
  {value: "3349", label: "Site Agent",},
  {value: "9736", label: "Skiing - Snow - Prof Instructor",},
  {value: "3352", label: "Skipper",},
  {value: "9737", label: "Slate Cutter",},
  {value: "9738", label: "Slate Dresser",},
  {value: "9739", label: "Slate Splitter",},
  {value: "9740", label: "Slater - 40' up",},
  {value: "9741", label: "Slater - up to 40'",},
  {value: "9742", label: "Slaughterer",},
  {value: "9743", label: "Slaughterhouse Manager",},
  {value: "9744", label: "Sleeping Car Attendant",},
  {value: "9745", label: "Sleeve Designer",},
  {value: "9746", label: "Smeller",},
  {value: "9747", label: "Smith - Gold, Silver etc",},
  {value: "9748", label: "Smoker",},
  {value: "3357", label: "Social Worker",},
  {value: "9749", label: "Sociologist",},
  {value: "9750", label: "Solderer",},
  {value: "3363", label: "Solicitor",},
  {value: "3364", label: "Song Writer",},
  {value: "9752", label: "Sorter - Dry Cleaning",},
  {value: "9753", label: "Sorter - Laundry",},
  {value: "9754", label: "Sorter - Post Office/Telecommunications",},
  {value: "9751", label: "Sorter (scrap metal)",},
  {value: "9755", label: "Sound Balancer",},
  {value: "9756", label: "Sound Mixer",},
  {value: "9757", label: "Sound Recordist",},
  {value: "3367", label: "Sound Technician",},
  {value: "9758", label: "Special Air Service (SAS)",},
  {value: "9759", label: "Special Boat Service (SBS)",},
  {value: "9760", label: "Special Effects Technician",},
  {value: "3370", label: "Speech Therapist",},
  {value: "9761", label: "Spiderman",},
  {value: "9762", label: "Spinner",},
  {value: "9763", label: "Sports Equipment Maker",},
  {value: "9764", label: "Sports Equipment Repairer",},
  {value: "9765", label: "Spot-Welder",},
  {value: "9766", label: "Spray Painter",},
  {value: "9767", label: "Stablehand",},
  {value: "3379", label: "Staff Nurse",},
  {value: "9768", label: "Stage Doorkeeper",},
  {value: "3381", label: "Stage Hand",},
  {value: "3382", label: "Stage Manager",},
  {value: "9769", label: "Stage Technician",},
  {value: "9770", label: "Stamper (identification markings)",},
  {value: "3384", label: "Station Manager",},
  {value: "9771", label: "Station Officer - Fire",},
  {value: "3385", label: "Stationer",},
  {value: "3386", label: "Statistician",},
  {value: "9772", label: "Steel Erector - 40' up",},
  {value: "9773", label: "Steel Erector - up to 40'",},
  {value: "3389", label: "Steeplejack",},
  {value: "3390", label: "Stenographer",},
  {value: "9774", label: "Steriliser",},
  {value: "3391", label: "Stevedore",},
  {value: "9775", label: "Stitcher",},
  {value: "3396", label: "Stockbroker",},
  {value: "9776", label: "Stockroom Storeman",},
  {value: "3398", label: "Stocktaker",},
  {value: "9777", label: "Stone Breaker",},
  {value: "9778", label: "Stone/Slate Polisher",},
  {value: "3401", label: "Stonemason",},
  {value: "3402", label: "Store Detective",},
  {value: "9779", label: "Storekeeper",},
  {value: "3403", label: "Storeman",},
  {value: "3408", label: "Structural Engineer",},
  {value: "3410", label: "Student",},
  {value: "9780", label: "Stunt Man",},
  {value: "9781", label: "Submariner",},
  {value: "9782", label: "Sugar Beet Cutter/Slicer",},
  {value: "9783", label: "Sugar Boiler",},
  {value: "9784", label: "Supermarket Cashier",},
  {value: "9785", label: "Supermarket Manager",},
  {value: "3423", label: "Surgeon",},
  {value: "9786", label: "Surgery Nurse",},
  {value: "9787", label: "Surgery Receptionist",},
  {value: "9788", label: "Surgical Appliance Maker",},
  {value: "9789", label: "Surgical Shoe Maker ",},
  {value: "9790", label: "Surveyor - Oil & Natural Gas Industries (Exploration & Production)",},
  {value: "9791", label: "Surveyor - Ship Building, Ship Repair & Marine Engineering",},
  {value: "9792", label: "Swimming Instructor ",},
  {value: "9793", label: "Swimming Pool Attendant",},
  {value: "9794", label: "Switchboard Operator",},
  {value: "9795", label: "Sword Swallower",},
  {value: "9796", label: "Systems Programmer",},
  {value: "3430", label: "Tailor",},
  {value: "9797", label: "Takeaway Food Shop Assistant",},
  {value: "9798", label: "Takeaway Food Shop Manager - no serving",},
  {value: "9799", label: "Tamperman",},
  {value: "9800", label: "Tank Room Attendant",},
  {value: "3432", label: "Tanker Driver",},
  {value: "9801", label: "Tanker Filler",},
  {value: "3433", label: "Tanner",},
  {value: "9802", label: "Tarmac Layer - Construction Industry",},
  {value: "9803", label: "Tarmac Layer - Road Maintenance & Construction",},
  {value: "9804", label: "Tattoo Artist",},
  {value: "3440", label: "Tax Consultant",},
  {value: "3441", label: "Tax Inspector",},
  {value: "9805", label: "Taxi Business Administrator",},
  {value: "9806", label: "Taxi Business Proprietor (no driving)",},
  {value: "3445", label: "Taxi Driver",},
  {value: "3446", label: "Taxidermist",},
  {value: "3449", label: "Teacher",},
  {value: "3451", label: "Teaching Assistant",},
  {value: "9807", label: "Technician - admin and site visits",},
  {value: "9808", label: "Technician - admin only",},
  {value: "9809", label: "Technician - heavy manual",},
  {value: "9810", label: "Technician - light manual ",},
  {value: "9811", label: "Technician - other",},
  {value: "9812", label: "Telecommunication Technical Officer",},
  {value: "3475", label: "Telegraphist",},
  {value: "9813", label: "Telephone Customer Advisor",},
  {value: "9814", label: "Telephone Exchange Superintendent",},
  {value: "9815", label: "Telephone Fitter",},
  {value: "9816", label: "Telephone Operator",},
  {value: "9817", label: "Telephone Repairer",},
  {value: "9818", label: "Telephone Supervisor",},
  {value: "9819", label: "Telephone Systems Sales Director",},
  {value: "3478", label: "Telephonist",},
  {value: "9820", label: "Telesales Caller",},
  {value: "9821", label: "Telesales Manager",},
  {value: "3482", label: "Television Engineer",},
  {value: "9822", label: "Test Engineer",},
  {value: "9823", label: "Test Pilots",},
  {value: "9824", label: "Tester",},
  {value: "3491", label: "Textile Worker",},
  {value: "3492", label: "Thatcher",},
  {value: "9825", label: "Theatre Sound Engineer",},
  {value: "3500", label: "Ticket Inspector",},
  {value: "9826", label: "Tiler - 40' up",},
  {value: "9827", label: "Tiler - up to 40'",},
  {value: "9828", label: "Timber Merchant (no manual work)",},
  {value: "9829", label: "Timberman",},
  {value: "3505", label: "Tobacconist",},
  {value: "9830", label: "Tool Fitter",},
  {value: "3507", label: "Tool Maker",},
  {value: "9831", label: "Tool Pusher",},
  {value: "3510", label: "Tour Guide",},
  {value: "9832", label: "Tour Manager",},
  {value: "9833", label: "Tower Crane Driver",},
  {value: "3512", label: "Town Planner",},
  {value: "9834", label: "Toxicologist",},
  {value: "9835", label: "Trackman",},
  {value: "9836", label: "Trade Union Official (full time)",},
  {value: "3527", label: "Traffic Warden",},
  {value: "9837", label: "Train Crew Inspector",},
  {value: "9838", label: "Train Crew Supervisor",},
  {value: "3528", label: "Train Driver",},
  {value: "9839", label: "Trainer - education/office based",},
  {value: "9840", label: "Tram Driver/Conductor",},
  {value: "3543", label: "Translator",},
  {value: "9841", label: "Transport Company Operations Manager (Office based) ",},
  {value: "3549", label: "Transport Manager",},
  {value: "9842", label: "Travel Agent (office based) ",},
  {value: "3555", label: "Travel Courier",},
  {value: "9843", label: "Trawlerman",},
  {value: "3560", label: "Tree Feller",},
  {value: "3561", label: "Tree Surgeon",},
  {value: "9844", label: "Trenchman",},
  {value: "9845", label: "Tugman",},
  {value: "9846", label: "Tunneller - no explosives etc",},
  {value: "9847", label: "Tunneller - using explosives etc",},
  {value: "9848", label: "Turf Accountant (on course) ",},
  {value: "9849", label: "Turf Accountant (shop)",},
  {value: "9850", label: "Turf Cutter/Layer",},
  {value: "9851", label: "Turner - Machining, Shaping etc - Metal Manufacture",},
  {value: "9852", label: "Turner - Pottery Industry",},
  {value: "9853", label: "Turnstile Operator",},
  {value: "9854", label: "Tutor (salaried)",},
  {value: "9855", label: "Tutor (self-employed)",},
  {value: "9856", label: "Type Caster",},
  {value: "3575", label: "Typesetter",},
  {value: "9857", label: "Typograph Operator",},
  {value: "9858", label: "Typographical Designer",},
  {value: "9859", label: "Tyre/Exhaust Fitter",},
  {value: "3582", label: "Umpire",},
  {value: "9860", label: "Under Secretary",},
  {value: "3583", label: "Undertaker",},
  {value: "9861", label: "Undertaker's Director?s Assistant",},
  {value: "3584", label: "Underwriter",},
  {value: "3585", label: "Unemployed",},
  {value: "3586", label: "University Lecturer",},
  {value: "9862", label: "Unknown",},
  {value: "3587", label: "Upholsterer",},
  {value: "9863", label: "Usher/Usherette",},
  {value: "9864", label: "Vacuum Plant Operator",},
  {value: "9865", label: "Valet/Valeter",},
  {value: "3590", label: "Valuer",},
  {value: "9866", label: "Valveman",},
  {value: "3592", label: "Van Driver",},
  {value: "9867", label: "Varnisher",},
  {value: "9868", label: "Vatman",},
  {value: "9869", label: "Vehicle Body Builder",},
  {value: "9870", label: "Vehicle Body Fitter",},
  {value: "9871", label: "Vending Machine Engineer",},
  {value: "9872", label: "Venetian Blind Maker",},
  {value: "3601", label: "Ventriloquist",},
  {value: "9873", label: "Venture Capitalist",},
  {value: "3602", label: "Verger",},
  {value: "9874", label: "Veterinarian",},
  {value: "3603", label: "Veterinary Assistant",},
  {value: "3606", label: "Vicar",},
  {value: "9875", label: "Video Conference Engineer",},
  {value: "9876", label: "Video Recorder Operator",},
  {value: "9877", label: "Vintner",},
  {value: "9878", label: "Vision Mixer",},
  {value: "9879", label: "Vocational Training Instructor",},
  {value: "3609", label: "Wages Clerk",},
  {value: "9880", label: "Wages Inspector",},
  {value: "9881", label: "Waiter, Waitress",},
  {value: "9882", label: "Wallpaper Printer",},
  {value: "9883", label: "Warden",},
  {value: "9884", label: "Wardrobe Mistress",},
  {value: "3615", label: "Warehouse Manager",},
  {value: "9885", label: "Warehouseman",},
  {value: "9886", label: "Washer",},
  {value: "9887", label: "Waste Disposal/Recycling Operative",},
  {value: "9888", label: "Wasteman, Salvage Man",},
  {value: "9889", label: "Watch & Clock Maker ",},
  {value: "9890", label: "Watch & Clock Repairer",},
  {value: "9891", label: "Watchman (inland waterways)",},
  {value: "9892", label: "Watchstander",},
  {value: "9893", label: "Water Bailiff",},
  {value: "9894", label: "Water Infusion Man",},
  {value: "9895", label: "Water Meter Reader/Fitter/Tester",},
  {value: "9896", label: "Water Skiing - Prof. Instructor",},
  {value: "9897", label: "Water Treatment Plant Operator",},
  {value: "9898", label: "Waterworks Manager",},
  {value: "3623", label: "Weaver",},
  {value: "9899", label: "Website/Webpage Designer",},
  {value: "9900", label: "Wedding Photographer",},
  {value: "3625", label: "Weighbridge Clerk",},
  {value: "9901", label: "Weighbridgeman ",},
  {value: "9902", label: "Weights & Measures Inspector",},
  {value: "9903", label: "Welder - 40' up",},
  {value: "9904", label: "Welder - up to 40'",},
  {value: "3629", label: "Welfare Officer",},
  {value: "9905", label: "Welfare Worker",},
  {value: "9906", label: "Wicker Worker",},
  {value: "9907", label: "Wig Maker",},
  {value: "9908", label: "Winch Operator",},
  {value: "9909", label: "Winchman",},
  {value: "9910", label: "Window Cleaner (40' up)",},
  {value: "9911", label: "Window Cleaner (up to 40')",},
  {value: "3636", label: "Window Dresser",},
  {value: "9912", label: "Window/Conservatory Fitter",},
  {value: "9913", label: "Windscreen Fitter/Repairer",},
  {value: "9914", label: "Wire Winder",},
  {value: "3642", label: "Wood Carver",},
  {value: "3644", label: "Wood Worker",},
  {value: "9915", label: "Woodcutter",},
  {value: "9916", label: "Woodman",},
  {value: "9917", label: "Working Partner",},
  {value: "9918", label: "Wrapping Machine Attendant",},
  {value: "3647", label: "Writer",},
  {value: "9919", label: "X-ray Technician - Radiologist",},
  {value: "9920", label: "Yacht & Boat Builder",},
  {value: "9921", label: "Yard Assistant",},
  {value: "9922", label: "Yard Cleaner",},
  {value: "9923", label: "Yarn Worker",},
  {value: "3650", label: "Yoga Teacher",},
  {value: "9924", label: "Youth Hostel Assistant and Cook ",},
  {value: "9925", label: "Youth Hostel Manager (some manual work) ",},
  {value: "9926", label: "Youth Leader (Full time)",},
  {value: "9927", label: "Youth Worker (full time)",},
  {value: "9928", label: "Zoo Curator",},
  {value: "9929", label: "Zoo Director",},
  {value: "3653", label: "Zoo Keeper",},
  {value: "9930", label: "Zoo Keeper - large zoos",},
  {value: "9931", label: "Zoo Keeper - small zoos",},
  {value: "9932", label: "Zoological Research Associate",},
  {value: "9933", label: "Zoological Researcher",},
  {value: "3655", label: "Zoologist",},
  {value: "9934", label: "Zoologist (no overseas field work)",},
];

export const groupedOptions: IGroupedOption[] = [
  {
    label: "Occupations",
    groupValue: "occupations",
    options: occupations,
  },
];
