import React, { useEffect, useState } from "react";
import { PageTitle } from "../../_metronic/layout/core";
import { useNavigate } from "react-router-dom";
import { usePortal } from "../../context/PortalContext";
import { StepperForm } from "../../modules/common/other/StepperForm";
import { ObjectShape } from "yup/lib/object";
import {
  schema as page1Schema,
  header as page1Header,
  description as page1Desctiption,
} from "../../modules/forms/form_schemas/AnnuityFactfind/PersonalDetails";
import {
  schema as page2Schema,
  header as page2Header,
  description as page2Desctiption,
} from "../../modules/forms/form_schemas/AnnuityFactfind/PensionDetails";

import {
  schema as page3Schema,
  header as page3Header,
  description as page3Desctiption,
} from "../../modules/forms/form_schemas/AnnuityFactfind/MedicalDetails";

import {
  schema as page4Schema,
  header as page4Header,
  description as page4Desctiption,
} from "../../modules/forms/form_schemas/AnnuityFactfind/EmploymentDetails";

import {
  schema as page5Schema,
  header as page5Header,
  description as page5Desctiption,
} from "../../modules/forms/form_schemas/AnnuityFactfind/PropertyDetails";

import { Loader } from "../../modules/common/other/Loader";
import { Notice } from "../../modules/common/cards/Notice";
import { KTSVG } from "../../_metronic/helpers";
import { CTA1 } from "../../modules/common/cards/CTA1";
import appInsights from "../../azureAppInsights";

interface FormSchema {
  header: string;
  description: string;
  schema: Schema;
}

interface Schema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}

export const Factfind = () => {
  <PageTitle>Retirement Fact Find</PageTitle>;

  const {
    returnClientData,
    setClientData,
    apiUpdateClientData,
    clientData,
  }: any = usePortal();

  const [formData, setFormData] = useState(undefined);

  useEffect(() => {
    console.log(clientData);
    if (!clientData) {
      returnClientData().then((data: any) => setClientData(data));
    } else {
      setFormData({
        ...clientData,
        medicalInformation: {
          ...(clientData.medicalInformation ?? {}),
          medicalConditionsYesNo:
            clientData.medicalInformation?.medicalConditionList?.length > 0
              ? "true"
              : "false",
          medicationsYesNo:
            clientData.medicalInformation?.medicationList?.length > 0
              ? "true"
              : "false",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (clientData) {
      setFormData({
        ...clientData,
        medicalInformation: {
          ...(clientData.medicalInformation ?? {}),
          medicalConditionsYesNo:
            clientData.medicalInformation?.medicalConditionList?.length > 0
              ? "true"
              : "false",
          medicationsYesNo:
            clientData.medicalInformation?.medicationList?.length > 0
              ? "true"
              : "false",
        },
      });
    }

    console.log(clientData);
  }, [clientData]);

  const navigate = useNavigate();
  const formSchemas: FormSchema[] = [];

  formSchemas.push({
    header: page1Header,
    description: page1Desctiption,
    schema: page1Schema,
  });

  formSchemas.push({
    header: page2Header,
    description: page2Desctiption,
    schema: page2Schema,
  });

  formSchemas.push({
    header: page3Header,
    description: page3Desctiption,
    schema: page3Schema,
  });

  formSchemas.push({
    header: page4Header,
    description: page4Desctiption,
    schema: page4Schema,
  });

  formSchemas.push({
    header: page5Header,
    description: page5Desctiption,
    schema: page5Schema,
  });

  async function submit(values: any) {
    let updatedValues = {
      ...values,
      annuity: {
        ...values.annuity,
        factfindCompleted: "true",
      },
    };

    try {
      console.log("updating please wait.");
      const responseData: any = await apiUpdateClientData(updatedValues);
      setClientData(responseData);
      navigate("/factfindcompleted");
    } catch (error) {
      console.error(error);

      let appInsightsError: Error = {
        name: "FactFind - apiUpdateClientData Error",
        message: String(error),
      };
      appInsights.trackException({ exception: appInsightsError });
    }
  }

  return (
    <>
      {clientData ? (
        <StepperForm
          data={clientData}
          schemas={formSchemas}
          handleSubmit={submit}
          navigateAfterSubmitTo={undefined}
          SaveAndExitButton={false}
          TopContent={
            <CTA1
              title={<div className="mb-5">Your fact find</div>}
              content={
                <>
                  <div className="fs-4 text-gray-800">
                    Before you can speak with one of our advisers,
                    <br />
                    we need you to provide further information about yourself.
                  </div>
                </>
              }
              tooltip={{
                button: (
                  <a
                    className="ms-10 btn btn-small btn-outline form-text"
                    style={{ maxWidth: "500px" }}
                    data-bs-toggle="collapse"
                    data-bs-target="#factfindTooltip"
                    aria-expanded="false"
                    aria-controls="factfindTooltip"
                  >
                    Why do you need more information from me?
                  </a>
                ),
                notice: (
                  <div className="collapse pb-5 pt-3" id="factfindTooltip">
                    <Notice
                      noticeClasses={"bg-light-warning"}
                      icon={undefined}
                      iconClasses={null}
                      header={undefined}
                      content={
                        <div style={{ maxWidth: "500px" }}>
                          The more we know about your current situation,
                          retirement objectives, and goals, the better we can
                          advise you on suitable products and solutions. As a
                          Trustpilot 5 star rated company, you can be sure that
                          your financial well-being is our top priority.
                        </div>
                      }
                      button={null}
                    />
                  </div>
                ),
              }}
              imageSrc="media/illustrations/bw/png/file-manager.png"
            />
          }
          FormFootercontent={
            clientData?.annuity?.status != "Portal" && (
              <>
                <Notice
                  noticeClasses={"mt-10 bg-light-warning border border-warning"}
                  icon={
                    <KTSVG
                      path="/media/icons/duotune/general/gen047.svg"
                      className="svg-icon svg-icon-3x svg-icon-dark"
                    />
                  }
                  iconClasses={""}
                  header={"Record locked"}
                  content={
                    <>
                      <div className="mt-5">
                        Changes will not be saved, if you need to make any
                        changes to your details, please contact us on{" "}
                        <span className="text-dark text-bolder">
                          {" "}
                          0808 281 2558.
                        </span>
                      </div>
                    </>
                  }
                  button={null}
                />
              </>
            )
          }
        />
      ) : (
        <Loader text="Loading your data" />
      )}
    </>
  );
};
