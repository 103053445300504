import {
  useContext,
  ReactNode,
  createContext,
  useState,
  useEffect,
} from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  onAuthStateChanged
} from "firebase/auth";
import { auth, googleAuthProvider, facebookAuthProvider } from "../config/firebase";
import appInsights from '../azureAppInsights';
import { useNavigate } from 'react-router-dom';

const UserAuthContext = createContext({});

export function UserAuthContextProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function register(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  async function loginWithGoogle() {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      console.log(result);
      navigate('/dashboard');
    } catch (error) {
      logAppInsightsError(error);
    }
  }

  async function loginWithFacebook() {
    try {
      const result = await signInWithPopup(auth, facebookAuthProvider);
      console.log(result);
      navigate('/dashboard');
    } catch (error) {
      logAppInsightsError(error);
    }
  } 

  function logout() {
    let logout;

    signOut(auth)
      .then(() => {
        logout = true;
      })
      .catch((error: Error) => {
        logout = false;
        appInsights.trackException({ exception: error });
      });

    return logout;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser: any) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserAuthContext.Provider
      value={{ login, logAppInsightsError, loginWithGoogle, loginWithFacebook, logout, register }}
    >
      {children}
    </UserAuthContext.Provider>
  );
}

export const logAppInsightsError = (error: any) => {
  let appInsightsError: Error = {
    name: "Login - onSubmit Error",
    message: String(error),
  };
  
  appInsights.trackException({ exception: appInsightsError });
}

export function useUserAuth() {
  return useContext(UserAuthContext);
}
