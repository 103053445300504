import {
  ErrorMessage,
  Field,
  useField,
  Form,
  Formik,
  useFormikContext,
} from "formik";
import PropTypes from "prop-types";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { fieldValidation } from '../../../models/FieldValidationConfig';

const InputBox = (props: IProps) => {
  const [field, meta, helpers] = useField(props.name);
  const [inputValue, setInputValue] = useState(props.initialValue);
  const { validateField, validateForm } = useFormikContext();
  const [validationType, setValidationType] = useState(props.validationType);
  const [validationMin, setValidationMin] = useState(props.minValue);
  const [validationMax, setValidationMax] = useState(props.maxValue);

  useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
  };

  const handleBlur = () => {
    helpers.setValue(inputValue);
  };

  function validator(value: any) {
    return fieldValidation(value, validationType, validationMin, validationMax, props.required);
  }

  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <div className="d-flex flex-column mb-7 fv-row">
        <div className="mb-2">
          <label className="d-flex align-items-center fs-3 fw-bold form-label">
            <span className={props.required ? "required" : ""}>
              {props.label}
            </span>
            {props.tooltip?.button && props.tooltip.button}
          </label>
          {props.tooltip?.notice && props.tooltip.notice}
          {props.description != "" && (
            <div className="form-text">{props.description}</div>
          )}
        </div>

        <div className="input-group input-group-solid mt-5">
          {props.prefix && (
            <div className="input-group-text">{props.prefix}</div>
          )}

          <Field
            type={props.inputType}
            className="form-control form-control-solid"
            placeholder={props.placeholder}
            name={props.name}
            value={inputValue}
            onChange={handleChange}
            onBlur={handleBlur} // Trigger re-render on blur
            autoComplete="off"
            key={props.name}
            validate={validator}
          />
        </div>

        <div className="text-danger mt-2 field-error">
          <ErrorMessage name={props.name} />
        </div>
      </div>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};

interface IProps {
  label: string;
  description: string;
  name: string;
  initialValue: string;
  required: boolean;
  placeholder: string;
  tooltip?: ITooltip;
  inputType: string;
  prefix: ReactNode;
  className?: string;
  addSeparatorAbove?: boolean;
  addSeparatorBelow?: boolean;
  validationType?: string;
  minValue?: number;
  maxValue?: number;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}

export { InputBox };
export type { IProps };
