import { Oval } from "react-loader-spinner";

interface IProps {
  text?: string;
}

export const Loader = (props: IProps) => {
  return (
    <div className="position-fixed top-50 start-50 translate-middle">
      <div className="card p-20 m-20">
        <div className="card-body d-flex flex-column align-items-center justify-content-center">
          <Oval
            height={100}
            width={100}
            color="#0051d2"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#d9e5f8"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
          {props.text ? (
            <div className="fs-1 fw-bold text-dark mt-20">{props.text}</div>
          ) : (
            <div className="fs-1 fw-bold text-dark mt-20"> Please wait...</div>
          )}
        </div>
      </div>
    </div>
  );
};
