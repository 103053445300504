import { Notice } from "../../cards/Notice";
import styles from "./RotatingLoader.module.css";
import { useState, useEffect, useRef } from "react";

interface IProps {
  images: string[];
  notice: {
    header: string | undefined;
    contentTitle: string | undefined;
    content: string | undefined;
  };
}

export const RotatingLoader = (props: IProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const assumptionsRef = useRef<HTMLDivElement>(null);
  const [cardHeight, setCardHeight] = useState<number | undefined>(undefined);
  const [assumptionsHeight, setAssumptionsHeight] = useState<
    number | undefined
  >(undefined);
  const [shouldShowAssumptions, setShouldShowAssumptions] = useState(false);

  useEffect(() => {
    const adjustHeight = () => {
      const assumptionsLength = props.notice.content
        ? props.notice.content.split("\n").length
        : 0;
      const cardElement = cardRef.current;
      const assumptionsElement = assumptionsRef.current;

      if (assumptionsLength > 0) {
        setShouldShowAssumptions(true);
      }

      if (cardElement && assumptionsElement) {
        const assumptionsHeight = assumptionsElement.offsetHeight;
        const newCardHeight =
          cardElement.offsetHeight + assumptionsHeight + 100;
        setCardHeight(newCardHeight);
        setAssumptionsHeight(assumptionsHeight);
      }
    };

    adjustHeight();
  }, [props.notice.content]);

  return (
    <div
      id="quotesLoaderParent"
      className={`position-fixed d-flex align-items-center justify-content-center ${styles.overlay}`}
      style={{ zIndex: 9999 }}
    >
      <div
        ref={cardRef}
        id="quotesLoader"
        className={`ms-10 me-10 card border-0 ${styles.card}`}
        style={{ height: cardHeight }}
      >
        <div className="card-body d-flex flex-column align-items-center text-center">
          <div className="mb-10">
            <div className="fs-1 fw-bolder pt-5">Searching the market...</div>
            <h3 className={`text-muted pt-2 pb-5${styles.textCenter}`}>
              This may take a few minutes
            </h3>
          </div>
          <div
            className={`d-flex align-items-center justify-content-center py-3 ${styles.carouselWrapper}`}
          >
            {props.images.map((image, index) => (
              <div
                key={index}
                className={
                  "d-flex align-items-center justify-content-center " +
                  styles.carousel__item
                }
              >
                <div
                  className={`${styles.carousel__itembody} ${styles.quotes__lodaer__provider}`}
                >
                  <img
                    style={{
                      minWidth: "90px",
                      minHeight: "90px",
                      maxWidth: "90px",
                      maxHeight: "90px",
                      objectFit: "contain",
                    }}
                    src={image}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {shouldShowAssumptions
          ? props.notice.content && (
              <Notice
                noticeClasses={
                  "bg-light-primary border-1 rounded border-primary mt-5 mb-15 p-10 mx-10"
                }
                icon={undefined}
                iconClasses={null}
                header={props?.notice?.header ? props.notice.header : ""}
                content={
                  <div className="text-black fs-6">
                    <div className="fs-6 text-gray-600 pe-7">
                      <p>{props.notice.contentTitle}</p>
                      <ul>
                        {props.notice.content
                          .split("\n")
                          .slice(1)
                          .map((item, index) => (
                            <li key={index}>{item.trim()}</li>
                          ))}
                      </ul>
                    </div>
                  </div>
                }
                button={null}
              />
            )
          : ""}
      </div>
    </div>
  );
};
