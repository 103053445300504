import * as Yup from "yup";

const header = "Your Pension Fund";

const description = "Provide your pension fund details.";

const stepperDescription = "Provide your pension fund details.";

const schema = Yup.object().shape({
  annuity: Yup.object().shape({
    pensionFund: Yup.number()
      .required()
      .label("Pension Fund")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Pension Fund",
            description:
              "If you have multiple pensions, please combine them and enter that amount",
            name: "annuity.pensionFund",
            required: true,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="h3">£</div>,
            addSeparatorBelow: true,
            validationType: "number",
            minValue: 0,
            maxValue: 10000000
          },
        },
      }),
    plannedRetirementDate: Yup.string()
      .required()
      .label("Planned Retirement Date")
      .meta({
        control: {
          componentType: "DateDDMMYYYY",
          props: {
            name: "annuity.plannedRetirementDate",
            label: "Planned retirement date",
            required: true,
            description: "",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: new Date().getFullYear(),
            endYear: new Date().getFullYear() + 10,
          },
        },
      }),
  }),
});

export { header, description, schema, stepperDescription };
