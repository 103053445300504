import { ReactNode } from "react";
import { Link } from "react-router-dom";

export const Notice = (props: IProps) => {
  return (
    <>
      <div
        className={
          `notice d-flex rounded border border-dashed p-6 ` +
          (props.noticeClasses ? props.noticeClasses : " border-gray-600")
        }
      >
        {props.icon && (
          <span
            className={
              `svg-icon svg-icon-2tx me-4 ` +
              (props.iconClasses && props.iconClasses)
            }
          >
            {props.icon}
          </span>
        )}

        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
          <div className="mb-3 mb-md-0 fw-semibold">
            {props.header && (
              <h4 className="text-gray-800 fw-bold">{props.header}</h4>
            )}
            {props.content && (
              <div className="fs-6 text-gray-600 pe-7">{props.content}</div>
            )}
          </div>
          {props.button && (
            <Link
              to={props.button.to}
              className={
                props.button.className
                  ? props.button.className
                  : "btn btn-primary px-6 align-self-center text-nowrap"
              }
            >
              {props.button.label}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

interface IProps {
  noticeClasses: string | null;
  icon: ReactNode | null;
  iconClasses: string | null;
  header: ReactNode | null;
  content: ReactNode | null;
  button: IButton | null;
}

interface IButton {
  label: string;
  to: string;
  className: string | null;
}
