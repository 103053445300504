import * as Yup from "yup";
import { groupedOptions as groupedMedicalConditions } from "../../../../models/MedicalConditionsData";
import { groupedOptions as groupedMedications } from "../../../../models/MedicationData";
import { medicalConditionSchema } from "../Shared/MedicalConditionSchema";
import { medicationSchema } from "../Shared/MedicationSchema";

const header = "Medical details";

const description =
  "Tell us about your lifestyle, medical conditions and any medications";

function medicationForConditions(formData: any) {
  let values: { label: string; value: string }[] = [{ label: "", value: "0" }];

  const conditions = formData.medicalInformation.medicalConditionList;
  conditions.forEach((condition: any) => {
    let itemToAdd;

    switch (condition.conditionType) {
      case "212":
        itemToAdd = { label: "Cancer/Tumour", value: "4161" };
        break;
      case "208":
        itemToAdd = { label: "Other", value: "4163" };
        break;
      case "210":
        itemToAdd = { label: "Heart", value: "4165" };
        break;
      case "211":
        itemToAdd = { label: "Diabetes", value: "4167" };
        break;
      case "214":
        itemToAdd = { label: "Respiratory", value: "4169" };
        break;
      case "216":
        itemToAdd = { label: "Neurological", value: "4170" };
        break;
      case "213":
        itemToAdd = { label: "Stroke", value: "4172" };
        break;
      case "218":
        itemToAdd = { label: "High Blood Pressure", value: "5873" };
        break;
      case "219":
        itemToAdd = { label: "High Cholesterol", value: "5874" };
        break;
      case "215":
        itemToAdd = { label: "Multiple Sclerosis", value: "5983" };
        break;
    }
    if (itemToAdd) {
      values.push(itemToAdd);
    }
  });

  return values;
}

const schema = Yup.object().shape({
  medicalInformation: Yup.object().shape({
    heightFeet: Yup.number()
      .nullable()
      .label("Your Height")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Enter Your Height",
            description:
              "Your height and weight are used in health assessments, which can affect pension rates.",
            name: "medicalInformation.heightFeet",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="small">Feet:</div>,
            className: "col-md-6 col-sm-12",
            validationType: "number",
            minValue: 2,
            maxValue: 8
          },
        },
      }),

    heightInches: Yup.number()
      .nullable()
      .label("Your Height")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "",
            description: "",
            name: "medicalInformation.heightInches",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="small">Inches:</div>,
            className: "col-md-6 col-sm-12",
            validationType: "number",
            minValue: 0,
            maxValue: 11
          },
        },
      }),

    weightStone: Yup.number()
      .nullable()
      .label("Your Weight")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Enter Your Weight",
            description: "",
            name: "medicalInformation.weightStone",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="small">Stone:</div>,
            className: "col-md-6 col-sm-12",
            addSeparatorBelow: false,
            validationType: "number",
            minValue: 0,
            maxValue: 50
          },
        },
      }),

    weightPounds: Yup.number()
      .nullable()
      .label("Your Weight")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "",
            description: "",
            name: "medicalInformation.weightPounds",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="small">Pounds:</div>,
            className: "col-md-6 col-sm-12",
            addSeparatorBelow: false,
            validationType: "number",
            minValue: 0,
            maxValue: 13
          },
        },
      }),

    medicalConditionsYesNo: Yup.string()
      .required()
      .label(
        "Do you currently have or have you ever had any medical conditions?"
      )
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label:
              "Do you currently have or have you ever had any medical conditions?",
            description:
              "Medical conditions can dramatically increase the amount of money you could receive.",
            name: "medicalInformation.medicalConditionsYesNo",
            tooltip: "",
            addSeparatorAbove: true,
          },
        },
      }),

    medicalConditionList: Yup.array()
      .of(medicalConditionSchema)
      .when("medicalInformation.medicalConditionsYesNo", {
        is: (val: string) => val === "true",
        then: Yup.array().required(),
        otherwise: Yup.array().optional(),
      })
      .label("Medical Conditions")
      .meta({
        fieldArrayName: "medicalInformation.medicalConditionList",
        fieldArrayDescription:
          "If you have any medical conditions, these can potentially increase the amount you recieve. Use the form below to add them.",
        addSeparatorAbove: false,
        control: [
          {
            componentType: "Lookup",
            props: {
              name: "conditionType",
              label: "Condition Type",
              required: true,
              description: "",
              placeholder: "Please Select",
              tooltip: "",
              prefix: undefined,
              values: [
                { label: "", value: "0" },
                { label: "High Blood Pressure", value: "218" },
                { label: "High Cholesterol", value: "219" },
                { label: "Diabetes", value: "211" },
                { label: "Cancer", value: "212" },
                { label: "Other", value: "208" },
                { label: "Heart", value: "210" },
                { label: "Respiratory", value: "214" },
                { label: "Neurological", value: "216" },
                { label: "Stroke", value: "213" },
                { label: "Multiple Sclerosis", value: "215" },
              ],
            },
          },

          //Respiratory
          {
            componentType: "Lookup",
            props: {
              label: "What sort of respiratory condition is it?",
              description: "",
              name: "respiratoryType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Chronic Obstructive Airways", value: "6020" },
                { label: "Pulmonary Disease", value: "6021" },
                { label: "Emphysema", value: "6022" },
                { label: "Bronchiectasis", value: "6023" },
                { label: "Pneumoconiosis", value: "6024" },
                { label: "Asbestosis", value: "6025" },
                { label: "Asthma", value: "6026" },
                { label: "Pleural Plaques", value: "6027" },
                { label: "Other", value: "6029" },
              ],
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["214"],
              },
            ],
          },

          // Heart
          {
            componentType: "Lookup",
            props: {
              name: "heartConditionType",
              label: "What type of heart condition do you have?",
              required: false,
              description: "",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Heart Attack", value: "5997" },
                { label: "Angina", value: "5998" },
                { label: "Heart Failure", value: "5999" },
                { label: "Aortic Aneurysm", value: "6000" },
                { label: "Cardiomyopathy", value: "6001" },
                { label: "Heart Valve Disorders", value: "6002" },
                { label: "Atrial Fibrillation (AF)", value: "6003" },
                { label: "Other", value: "6004" },
              ],
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["210"],
              },
            ],
          },
          {
            componentType: "LookupWithSearchAndGroups",
            props: {
              name: "conditionName",
              label: "Condition Name",
              required: false,
              description: "Search and select a condition",
              placeholder: "Please Select",
              tooltip: "",
              prefix: undefined,
              values: groupedMedicalConditions,
              groupFilterField: "conditionType",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["210", "208", "212", "214", "216", "213"],
              },
            ],
          },
          //shared
          {
            componentType: "DateMMYYYY",
            props: {
              name: "dateDiagnosed",
              label: "When were you diagnosed?",
              required: true,
              description: "A rough estimate will be fine.",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: new Date().getFullYear() - 100,
              endYear: new Date().getFullYear(),
              addSeparatorBelow: false,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: [
                  "210",
                  "208",
                  "212",
                  "214",
                  "216",
                  "213",
                  "218",
                  "219",
                  "211",
                  "215",
                ],
              },
            ],
          },

          // High Blood Pressure
          {
            componentType: "InputBox",
            props: {
              name: "lastSystolicReading",
              label: "What was your last Systolic Reading?",
              required: true,
              description: "This is the higher reading",
              placeholder: "",
              inputType: "number",
              tooltip: "",
              prefix: null,
              addSeparatorBelow: false,
              validationType: "number",
              minValue: 50,
              maxValue: 200
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["218"],
              },
            ],
          },
          {
            componentType: "InputBox",
            props: {
              name: "lastDiastolicReading",
              label: "What was your last Diastolic Reading?",
              required: true,
              description: "This is the lower reading",
              placeholder: "",
              inputType: "number",
              tooltip: "",
              prefix: null,
              addSeparatorBelow: false,
              validationType: "number",
              minValue: 50,
              maxValue: 150
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["218"],
              },
            ],
          },
          {
            componentType: "DateMMYYYY",
            props: {
              name: "lastReadingDate",
              label: "When were these readings taken?",
              required: true,
              description: "A rough estimate will be fine.",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: new Date().getFullYear() - 100,
              endYear: new Date().getFullYear(),
              addSeparatorBelow: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["218"],
              },
            ],
          },

          // Other
          {
            componentType: "DateMMYYYY",
            props: {
              name: "lastSufferedSymptoms",
              label: "When did you last suffer symptoms?",
              required: true,
              description: "A rough estimate will be fine.",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: new Date().getFullYear() - 100,
              endYear: new Date().getFullYear(),
              addSeparatorBelow: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["208"],
              },
            ],
          },

          // Diabetus
          {
            componentType: "Lookup",
            props: {
              label: "What type of diabetes do you have?",
              description: "",
              name: "diabetesType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Type 1", value: "5820" },
                { label: "Type 2", value: "5821" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["211"],
              },
            ],
          },
          {
            componentType: "Lookup",
            props: {
              label: "How do you control your diabetes?",
              description: "",
              name: "diabetesControlMethod",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Diet", value: "5822" },
                { label: "Insulin", value: "5823" },
                { label: "Tablet", value: "5824" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["211"],
              },
            ],
          },

          // Cancer
          {
            componentType: "Lookup",
            props: {
              label: "What type of tumour did/do you have?",
              description: "",
              name: "tumourType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Benign", value: "5860" },
                { label: "Pre-Cancerous", value: "5861" },
                { label: "Malignant", value: "5862" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["212"],
              },
            ],
          },
          {
            componentType: "Lookup",
            props: {
              label: "Where did/do you have the tumour?",
              description: "",
              name: "tumourLocation",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Bone", value: "5939" },
                { label: "Brain and Central Nervous System", value: "5940" },
                { label: "Breast", value: "5941" },
                { label: "Circulatory system", value: "5942" },
                { label: "Connective and other soft tissue", value: "5943" },
                { label: "Digestive System", value: "5944" },
                { label: "Liver", value: "5945" },
                { label: "Lymph Nodes", value: "5946" },
                { label: "Oral", value: "5947" },
                { label: "Reproductive system", value: "5948" },
                { label: "Respiratory system", value: "5949" },
                { label: "Skin", value: "5950" },
                { label: "Urinary system", value: "5951" },
                { label: "Other", value: "5952" },
                { label: "Not specified", value: "5953" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["212"],
              },
            ],
          },
          {
            componentType: "Lookup",
            props: {
              label: "What grade of cancer did/do you have?",
              description: "",
              name: "generalGrade",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Grade 1 (Low Grade)", value: "5954" },
                { label: "Grade 2 (Intermediate Grade)", value: "5955" },
                { label: "Grade 3 (High Grade)", value: "5956" },
                { label: "Unknown", value: "5966" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["212"],
              },
            ],
          },
          // classification, multiple classifcation types AnnArborClassification MAC FIGO Dukes
          {
            componentType: "Lookup",
            props: {
              label: "What grade of cancer did/do you have?",
              description: "",
              name: "classificationType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Grade 1 (Low Grade)", value: "5954" },
                { label: "Grade 2 (Intermediate Grade)", value: "5955" },
                { label: "Grade 3 (High Grade)", value: "5956" },
                { label: "Unknown", value: "5966" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["212"],
              },
            ],
          },

          // Stroke
          {
            componentType: "Lookup",
            props: {
              label: "What type of stroke did you have?",
              description: "",
              name: "strokeType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                {
                  label: "CVA (Cerebrovascular Accident - Major Stroke)",
                  value: "6043",
                },
                { label: "Subarachnoid Haemorrhage (SAH)", value: "6044" },
                { label: "Cerebral Haemorrhage/Bleed", value: "6045" },
                {
                  label: "TIA (Transient Ischaemic Attack - Mini-Stroke)",
                  value: "6046",
                },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["213"],
              },
            ],
          },
          {
            componentType: "Lookup",
            props: {
              label: "Which area has been the most affected by the stroke?",
              description: "",
              name: "bodyPartAffected",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Speech", value: "6038" },
                { label: "Vision", value: "6039" },
                { label: "Arm", value: "6040" },
                { label: "Leg", value: "6041" },
                { label: "Memory Loss", value: "6042" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["213"],
              },
            ],
          },

          // Neurological
          {
            componentType: "Lookup",
            props: {
              label: "What is the neurological condition type?",
              description: "",
              name: "neurologicalType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Alzheimer's Disease", value: "6016" },
                { label: "Parkinson's Disease", value: "6017" },
                { label: "Other", value: "6019" },
                { label: "Senile Dementia", value: "100995" },
                { label: "Vascular Dementia", value: "100996" },
                { label: "Motor Neurone Disease", value: "100997" },
              ],
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "YesNoBoxes",
            props: {
              label: "Do you know your MMSE score?",
              description: "",
              name: "mmseScoreKnown",
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "InputBox",
            props: {
              name: "mmseScore",
              label: "What is your MMSE score?",
              required: true,
              description: "It will be a number no higher than 30",
              placeholder: "",
              inputType: "number",
              tooltip: "",
              prefix: null,
              addSeparatorBelow: false,
              validationType: "number",
              minValue: 0,
              maxValue: 30
            },
            dependsOnFields: [
              {
                fieldName: "mmseScoreKnown",
                fieldValue: "true",
              },
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "YesNoBoxes",
            props: {
              label: "Have you had any falls?",
              description: "",
              name: "falls",
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: "mmseScoreKnown",
                fieldValue: "true",
              },
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "YesNoBoxes",
            props: {
              label: "Have you had any pressure sores?",
              description: "",
              name: "pressureSores",
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "YesNoBoxes",
            props: {
              label: "Have you had any seizures?",
              description: "",
              name: "seizures",
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "YesNoBoxes",
            props: {
              label: "Have you had any tremors?",
              description: "",
              name: "tremors",
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
        ],

        dependsOnFields: [
          {
            fieldName: "medicalInformation.medicalConditionsYesNo",
            fieldValue: "true",
          },
        ],
      }),

    medicationsYesNo: Yup.string()
      .label("Do you take any medications?")
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label: "Do you take any medications?",
            description:
              "Medications you take can dramatically increase the amount of money you could receive.",
            name: "medicalInformation.medicationsYesNo",
            tooltip: "",
            addSeparatorAbove: true,
          },
        },
      }),

    medicationList: Yup.array()
      .of(Yup.object().shape({ medicationSchema }))
      .label("Add current or previous medications")
      .meta({
        fieldArrayName: "medicalInformation.medicationList",
        fieldArrayDescription:
          "If you take any medications, these can potentially increase the amount you recieve. Use the below to add them",
        addSeparatorAbove: false,
        control: [
          {
            componentType: "LookupWithSearchAndGroups",
            props: {
              name: "medicationName",
              label: "What is the name of the medication?",
              required: false,
              description: "Search and select a medication",
              placeholder: "Please Select",
              tooltip: "",
              prefix: undefined,
              className: "",
              values: groupedMedications,
            },
          },
          {
            componentType: "Lookup",
            props: {
              name: "forConditionType",
              label: "Which of your condition types is this medication for?",
              required: true,
              description: "",
              placeholder: "Please Select",
              tooltip: "",
              prefix: undefined,
              valuesFunction: medicationForConditions,
            },
          },
          {
            componentType: "Lookup",
            props: {
              label: "How often do you take the medication?",
              description: "",
              name: "frequency",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { value: "5833", label: "As Required" },
                { value: "5984", label: "Daily" },
                { value: "5985", label: "Weekly" },
                { value: "5986", label: "Fortnightly" },
                { value: "5987", label: "Every Four Weeks" },
                { value: "5988", label: "Monthly" },
                { value: "5989", label: "Quarterly" },
                { value: "5990", label: "Half Yearly" },
                { value: "5991", label: "Yearly" },
              ],
            },
          },

          {
            componentType: "YesNoBoxes",
            props: {
              label: "Do you know the exact dosage for this medication?",
              description: "",
              name: "dosageKnown",
              tooltip: "",
            },
          },

          {
            componentType: "InputBox",
            props: {
              label: "What dosage do you take?",
              description: "",
              name: "dosage",
              required: false,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: null,
              addSeparatorBelow: false,
              validationType: "number",
              minValue: 0,
              maxValue: 1000
            },
            dependsOnFields: [
              {
                fieldName: "dosageKnown",
                fieldValue: "true",
              },
            ],
          },
          {
            componentType: "Lookup",
            props: {
              label: "Unit",
              description: "",
              name: "dosageUnit",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { value: "5992", label: "Gram" },
                { value: "5993", label: "Milligram" },
                { value: "5994", label: "Microgram" },
                { value: "5995", label: "Millilitre" },
                { value: "5996", label: "Unit Of Insulin" },
              ],
            },
            dependsOnFields: [
              {
                fieldName: "dosageKnown",
                fieldValue: "true",
              },
            ],
          },
          {
            componentType: "InputBox",
            props: {
              label: "How many do you take?",
              description: "",
              name: "numberTaken",
              required: false,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: null,
              addSeparatorBelow: false,
              validationType: "number",
              minValue: 0,
              maxValue: 50
            },
            dependsOnFields: [
              {
                fieldName: "dosageKnown",
                fieldValue: "true",
              },
            ],
          },

          {
            componentType: "DateDDMMYYYY",
            props: {
              name: "startDate",
              label: "When did you start taking the medication?",
              required: false,
              description: "",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: 1920,
              endYear: new Date().getFullYear(),
            },
          },

          {
            componentType: "YesNoBoxes",
            props: {
              label: "Do you still take this medication?",
              description: "",
              name: "stillTakeMedication",
              tooltip: "",
            },
          },

          {
            componentType: "DateDDMMYYYY",
            props: {
              name: "endDate",
              label: "When did you stop taking the medication?",
              required: false,
              description: "",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: 1920,
              endYear: new Date().getFullYear(),
            },
            dependsOnFields: [
              {
                fieldName: "stillTakeMedication",
                fieldValue: "false",
              },
            ],
          },
        ],
        dependsOnFields: [
          {
            fieldName: "medicalInformation.medicationsYesNo",
            fieldValue: "true",
          },
          {
            fieldName: "medicalInformation.medicalConditionsYesNo",
            fieldValue: "true",
          },
        ],
      }),

    alcoholUnitsPerWeek: Yup.string()
      .nullable()
      .label("Do you drink alcohol?")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "How many units of alcohol do you drink per week?",
            description:
              "1 pint of beer is 3 units, a single shot of spirit is 1 unit and a glass of wine is 2 units.",
            name: "medicalInformation.alcoholUnitsPerWeek",
            tooltip: "",
            inputType: "number",
            addSeparatorAbove: true,
            validationType: "number",
            minValue: 0,
            maxValue: 50
          },
        },
      }),

    previousOrCurrentSmokerYesNo: Yup.string()
      .required()
      .label("Have you ever been a smoker?")
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label: "Have you ever been a smoker?",
            description:
              "If you are a smoker or have been previously, this can increase the amount you receive.",
            name: "medicalInformation.previousOrCurrentSmokerYesNo",
            tooltip: "",
            required: true,
            addSeparatorAbove: true,
          },
        },
      }),

    previousSmokerYesNo: Yup.string()
      .label("Have you now stopped smoking?")
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label: "Have you now stopped smoking?",
            description: "",
            name: "medicalInformation.previousSmokerYesNo",
            tooltip: "",
            addSeparatorAbove: false,
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmokerYesNo",
              fieldValue: "true",
            },
          ],
        },
      }),

    yearMonthStartedSmoking: Yup.string()
      .nullable()
      .label("When did you start smoking?")
      .meta({
        control: {
          componentType: "DateMMYYYY",
          props: {
            name: "medicalInformation.yearMonthStartedSmoking",
            label: "When did you start smoking?",
            required: false,
            description: "A rough estimate will be fine.",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: new Date().getFullYear() - 100,
            endYear: new Date().getFullYear(),
            addSeparatorBelow: false,
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmokerYesNo",
              fieldValue: "true",
            },
          ],
        },
      }),

    yearMonthStoppedSmoking: Yup.string()
      .nullable()
      .label("When did you stop smoking?")
      .meta({
        control: {
          componentType: "DateMMYYYY",
          props: {
            name: "medicalInformation.yearMonthStoppedSmoking",
            label: "When did you stop smoking?",
            required: false,
            description: "A rough estimate will be fine.",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: new Date().getFullYear() - 100,
            endYear: new Date().getFullYear(),
            addSeparatorBelow: false,
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmokerYesNo",
              fieldValue: "true",
            },
            {
              fieldName: "medicalInformation.previousSmokerYesNo",
              fieldValue: "true",
            },
          ],
        },
      }),

    cigarettesPerDay: Yup.string()
      .label("Cigarettes Per Day")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Cigarettes Per Day",
            description: "",
            name: "medicalInformation.cigarettesPerDay",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: undefined,
            className: null,
            addSeparatorBelow: false,
            validationType: "number",
            minValue: 0,
            maxValue: 200
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmokerYesNo",
              fieldValue: "true",
            },
          ],
        },
      }),

    cigarsPerday: Yup.string()
      .label("Cigars Per Day")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Cigars Per Day",
            description: "",
            name: "medicalInformation.cigarsPerday",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: undefined,
            className: null,
            addSeparatorBelow: false,
            validationType: "number",
            minValue: 0,
            maxValue: 200
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmokerYesNo",
              fieldValue: "true",
            },
          ],
        },
      }),

    gramsOfTobaccoPerDay: Yup.string()
      .label("Grams Of Tobacco Per Day")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Grams Of Tobacco Per Day",
            description: "",
            name: "medicalInformation.gramsOfTobaccoPerDay",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: undefined,
            className: null,
            addSeparatorBelow: false,
            validationType: "number",
            minValue: 0,
            maxValue: 1000
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmokerYesNo",
              fieldValue: "true",
            },
          ],
        },
      }),

    gramsOfPipeTobaccoPerDay: Yup.string()
      .label("Grams Of Tobacco Per Day")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Grams Of Pipe Tobacco Per Day",
            description: "",
            name: "medicalInformation.gramsOfPipeTobaccoPerDay",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: undefined,
            className: null,
            addSeparatorBelow: false,
            validationType: "number",
            minValue: 0,
            maxValue: 1000
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmokerYesNo",
              fieldValue: "true",
            },
          ],
        },
      }),
  }),
});

export { header, description, schema };
