const name = /^[a-zA-Z][a-z]*$/;
const number = /^[0-9]*$/;
const postcode = /^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) [0-9][ABD-HJLNP-UW-Z]{2})$/i;
const niNumber = /^[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\d{6}[A-D]$/;

export function fieldValidation(value: any, type: string|undefined, min?: number, max?: number, required?: boolean) {
    if(required === null || required === undefined || !required) {
        return undefined;
    }
    
    switch(type) {
        case 'name':          
            if(!name.test(value)) {
                return 'Value must contain only a-z or A-Z characters';
            }

            if(min !== undefined && value && value.length < min) {
                return `Value must be greater than or equal to ${min} characters in length`;
            }
                
            if(max !== undefined && value && value.length > max) {
                return `Value must be less than or equal to ${max} characters in length`;
            }

            return undefined;
        case 'number':
            if(!number.test(value)) {
                return 'Value can only contain numbers';
            }
            
            if(min !== undefined && value && parseInt(value) < min) {
                return `Value must be greater than or equal to ${min}`;
            } 
            
            if(max !== undefined && value && parseInt(value) > max) {
                return `Value must be less than or equal to ${max}`;
            }

            return undefined;
        case 'postcode':
            return value && postcode.test(value) ? undefined : `Value must be a valid UK Postcode`;
        case 'niNumber':
            return value && niNumber.test(value) ? undefined: 'Value must be a valid UK National Insurance number'; 
    }
}