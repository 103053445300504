import * as Yup from "yup";
export const medicationSchema = Yup.object().shape({
  medicationName: Yup.string().label("Medication Name"),
  numberTaken: Yup.string().label("Number Taken"),
  Frequency: Yup.string().label("Frequency Taken"),
  dosageKnown: Yup.string().label("Dosage Known"),
  dosage: Yup.string().label("Dosage"),
  dosageUnit: Yup.string().label("Dosage Unit"),
  forConditionType: Yup.string().label("Taken for condition type"),
  startDate: Yup.string().label("When did you start taking the medication?"),
  stillTakeMedication: Yup.string().label(
    "Are you still taking the medication?"
  ),
  endDate: Yup.string().label("When did you stop taking the medication?"),
});
