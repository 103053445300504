import { PageTitle } from "../../../_metronic/layout/core";
import { useEffect, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { usePortal } from "../../../context/PortalContext";
import { useNavigate } from "react-router-dom";
import { ObjectShape } from "yup/lib/object";
import { StepperForm } from "../../../modules/common/other/StepperForm";

import {
  schema as page1Schema,
  header as page1Header,
  description as page1Desctiption,
} from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuotePage1";

import {
  schema as page2Schema,
  header as page2Header,
  description as page2Desctiption,
} from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuotePage2";

import {
  schema as page3Schema,
  header as page3Header,
  description as page3Desctiption,
} from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuotePage3";

import {
  schema as page4Schema,
  header as page4Header,
  description as page4Desctiption,
} from "../../../modules/forms/form_schemas/AnnuityQuote/AnnuityQuotePage4";

import { Loader } from "../../../modules/common/other/Loader";
import { Notice } from "../../../modules/common/cards/Notice";
import { CTA1 } from "../../../modules/common/cards/CTA1";
import appInsights from "../../../azureAppInsights";

export const FullQuote = () => {
  <PageTitle>Retirement Quote</PageTitle>;

  const {
    returnClientData,
    setClientData,
    clientData,
    apiUpdateClientData,
  }: any = usePortal();

  const [formData, setFormData] = useState(undefined);

  useEffect(() => {
    console.log(clientData);
    if (!clientData) {
      returnClientData().then((data: any) => setClientData(data));
    } else {
      setFormData({
        ...clientData,
        medicalInformation: {
          ...(clientData.medicalInformation ?? {}),
          medicalConditionsYesNo:
            clientData.medicalInformation?.medicalConditionList?.length > 0
              ? "true"
              : "false",
          medicationsYesNo:
            clientData.medicalInformation?.medicationList?.length > 0
              ? "true"
              : "false",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (clientData) {
      setFormData({
        ...clientData,
        medicalInformation: {
          ...(clientData.medicalInformation ?? {}),
          medicalConditionsYesNo:
            clientData.medicalInformation?.medicalConditionList?.length > 0
              ? "true"
              : "false",
          medicationsYesNo:
            clientData.medicalInformation?.medicationList?.length > 0
              ? "true"
              : "false",
        },
      });
    }

    console.log(clientData);
  }, [clientData]);

  const navigate = useNavigate();
  const formSchemas: FormSchema[] = [];

  formSchemas.push({
    header: page1Header,
    description: page1Desctiption,
    schema: page1Schema,
  });

  formSchemas.push({
    header: page2Header,
    description: page2Desctiption,
    schema: page2Schema,
  });

  formSchemas.push({
    header: page3Header,
    description: page3Desctiption,
    schema: page3Schema,
  });

  formSchemas.push({
    header: page4Header,
    description: page4Desctiption,
    schema: page4Schema,
  });

  async function submit(values: any) {
    console.log(values);
    let updatedValues = {
      ...values,
      annuity: {
        ...values.annuity,
        fullQuoteCompleted: "true",
      },
    };

    try {
      const responseData: any = await apiUpdateClientData(updatedValues);
      setClientData(responseData);
      navigate("/annuityquotes/refresh");
    } catch (error) {
      console.error(error);

      let appInsightsError: Error = {
        name: "FullQuote - apiUpdateClientData Error",
        message: String(error),
      };
      appInsights.trackException({ exception: appInsightsError });
    }
  }

  return (
    <>
      {formData ? (
        <StepperForm
          initialStep={2}
          data={formData}
          schemas={formSchemas}
          handleSubmit={submit}
          navigateAfterSubmitTo={undefined}
          SaveAndExitButton={false}
          TopContent={
            <CTA1
              title={<div className="mb-5">Tailored quote</div>}
              content={
                <>
                  <div className="fs-4 text-gray-800">
                    Add further details on your health & lifestyle to
                    potentially
                    <br />
                    increase the amount you could recieve.
                  </div>
                </>
              }
              imageSrc="media/illustrations/bw/png/profit.png"
            />
          }
          FormFootercontent={
            clientData?.annuity?.status != "Portal" && (
              <>
                <Notice
                  noticeClasses={"mt-10 bg-light-warning border border-warning"}
                  icon={
                    <KTSVG
                      path="/media/icons/duotune/general/gen047.svg"
                      className="svg-icon svg-icon-3x svg-icon-dark"
                    />
                  }
                  iconClasses={""}
                  header={"Record locked"}
                  content={
                    <>
                      <div className="mt-5">
                        Changes will not be saved, if you need to make any
                        changes to your details, please contact us on{" "}
                        <span className="text-dark text-bolder">
                          {" "}
                          0808 281 2558.
                        </span>
                      </div>
                    </>
                  }
                  button={null}
                />
              </>
            )
          }
        />
      ) : (
        <Loader text="Loading your data" />
      )}
    </>
  );
};

interface FormSchema {
  header: string;
  description: string;
  schema: Schema;
}

interface Schema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}
