import { KTSVG } from "../../../_metronic/helpers";

interface IProps {
  className?: string;
  milestones: IMilestone[];
  title: string;
}
interface IMilestone {
  name: string;
  completed: boolean;
  completedDate?: string;
  dueDate?: string;
}

export const Milestones = (props: IProps) => {
  return (
    <div className={`card ${props.className}`}>
      {/* begin::Header */}
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-dark">{props.title}</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pt-2">
        {props.milestones.map((milestone, index) => {
          return (
            <div key={index} className="d-flex align-items-center mb-8">
              {/* begin::Bullet */}

              <span
                className={
                  milestone.completed
                    ? "bullet bullet-vertical h-40px bg-success"
                    : "bullet bullet-vertical h-40px bg-primary"
                }
              ></span>
              {/* end::Bullet */}
              {/* begin::Checkbox */}
              <div className="form-check form-check-custom form-check-solid mx-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  defaultChecked={milestone.completed ? true : false}
                />
              </div>
              {/* end::Checkbox */}
              {/* begin::Description */}
              <div className="flex-grow-1">
                <a
                  href="#"
                  className="text-gray-800 text-hover-primary fw-bold fs-6"
                >
                  {milestone.name}
                </a>
                <span className="text-muted fw-semibold d-block">
                  {milestone.completed
                    ? "Completed on " + milestone.completedDate
                    : //"Due on or before " + milestone.dueDate
                      ""}
                </span>
              </div>
              {/* end::Description */}
              {milestone.completed && (
                <span className="badge badge-light-success fs-8 fw-bold">
                  Completed
                </span>
              )}
            </div>
          );
        })}
      </div>
      {/* end::Body */}
    </div>
  );
};
