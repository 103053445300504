import React, { EventHandler } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
export const RadioImageSelect = (props: IRadioImageSelectProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueAsNumber = parseInt(event.target.value, 10);
    props.onSelect(valueAsNumber);
  };

  return (
    <div className="row mb-2" data-kt-buttons="true">
      {props.item.map((item, index) => {
        return (
          <div className="col mb-5" key={index}>
            <input
              type="radio"
              className="btn-check"
              value={index}
              id={item.label}
              name={props.radioName}
              onChange={handleChange}
            ></input>
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default h-100 w-100 p-4 bg-white"
              htmlFor={item.label}
            >
              <div className="d-flex flex-column justify-content-between pb-10">
                {item.imageSrc && (
                  <img
                    className="mx-auto h-175px h-lg-175px theme-light-show"
                    src={toAbsoluteUrl(item.imageSrc)}
                    alt=""
                  />
                )}
              </div>
              <h1 className="fw-bolder fs-1">{item.label}</h1>
            </label>
          </div>
        );
      })}
    </div>
  );
};

interface IRadioImageSelectProps {
  item: IItem[];
  radioName: string;
  onSelect: (value: number) => void;
}

interface IItem {
  label: string | undefined;
  imageSrc: string | undefined;
}
