import { AllData } from "../modules/forms/form_schemas/Shared/AllData";
import { usePortal } from "../context/PortalContext";
import {
  Form,
  Formik,
  FormikValues,
  useFormik,
  useFormikContext,
} from "formik";
import { useEffect, useRef, useState } from "react";
import {
  GetFormFields,
  GetFormFieldsWithHeaderAndFields,
} from "../modules/forms/Helpers";
import { Loader } from "../modules/common/other/Loader";
import { IClientData } from "../models/ClientData";

export const FormTestPage = () => {
  const {
    returnClientData,
    setClientData,
    clientData,
    apiUpdateClientData,
  }: any = usePortal();

  useEffect(() => {
    if (!clientData) {
      returnClientData().then((data: any) => setClientData(data));
      console.log(clientData);
    } else {
      console.log(clientData);
    }
  }, []);

  useEffect(() => {
    console.log(clientData);
  }, [clientData]);

  // const updateClient = (data: any) => {
  //   setClientData(data);
  //   APIUpdateClientData(data);
  //   console.log("below is the updated client Data");
  //   console.log(data);


  // };

  async function updateClient(data:any){
    try {
      console.log("updating please wait.");
      const responseData: any = await apiUpdateClientData(data);
      setClientData(responseData);
      console.log("below is the updated client Data");
      console.log(responseData);
    } catch (error) {
      console.error(error);
    }
  }

  const formikRef = useRef<FormikValues>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const formFields = GetFormFields(AllData);

  return (
    <>
      {clientData ? (
        <Formik
          validationSchema={AllData}
          initialValues={clientData}
          onSubmit={updateClient}
          forwardRef={formikRef}
        >
          {(formik) => {
            const { isValid } = formik;
            return (
              <>
                <div className="modal-body py-lg-10 px-lg-10">
                  <div className="flex-row-fluid py-lg-5 px-lg-15">
                    <Form
                      className="py-5 w-100 px-5 container-xl"
                      noValidate
                      id="ModalForm"
                      ref={formRef}
                    >
                      {formFields}
                    </Form>
                  </div>
                </div>
                <div className="modal-footer">
                  {!isValid && (
                    <div className="text-danger mt-2 d-flex justify-content-end">
                      Please correct the above errors.
                    </div>
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => formRef.current?.requestSubmit()}
                  >
                    Save
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      ) : (
        <Loader text="Loading your data" />
      )}
    </>
  );
};
