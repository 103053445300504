import { IGroupedOption } from "./LookupWithGroupsData";

//use below query to get data

// select '{value: "' + cast(System_AccountID as nvarchar) + '", label: "' + system_name + '",},'
// from service_account where system_organisationalunitId = 100104 and isnull(System_deleted,0)=0 and isnull(system_name, '') <> ''
// and isnull(System_Name,'') not like '%do not%'
// and isnull(System_Name,'') not like '%"%'

const providers = [
  { value: "100016", label: "Aviva " },
  { value: "100048", label: "Exchange Life 1" },
  { value: "100049", label: "Friends Life (Friends Provident)" },
  { value: "100050", label: "Abbey Life" },
  { value: "100051", label: "Aegon" },
  { value: "100052", label: "AXA" },
  { value: "100053", label: "Cananda Life" },
  { value: "100054", label: "The Co-Operative Financial Services" },
  { value: "100055", label: "Guardian Financial Services" },
  { value: "100056", label: "Hodge Lifetime" },
  { value: "100057", label: "Legal & General" },
  { value: "100058", label: "London Life" },
  { value: "100059", label: "Met Life" },
  { value: "100060", label: "MGM Advantage" },
  { value: "100061", label: "NFU Mutual" },
  { value: "100062", label: "Nucleus" },
  { value: "100063", label: "NPI" },
  { value: "100064", label: "Partnership" },
  { value: "100065", label: "Pearl" },
  { value: "100067", label: "Scottish Life" },
  { value: "100068", label: "Scottish Widows" },
  { value: "100069", label: "Skandia" },
  { value: "100070", label: "Standard Life" },
  { value: "100071", label: "St James's Place" },
  { value: "100072", label: "Wesleyan" },
  { value: "100073", label: "Pearson Group Pension Plan" },
  { value: "100074", label: "Clerical Medical" },
  { value: "100075", label: "Barclays (Towers Watson)" },
  { value: "100077", label: "Zurich" },
  { value: "100078", label: "Fidelity" },
  { value: "100079", label: "Corus" },
  { value: "100080", label: "Siemens Pension Contact Centre" },
  { value: "100081", label: "Royal London" },
  { value: "100083", label: "P.O.I.S" },
  { value: "100084", label: "Towers Watson SlumBerger UK Pension Scheme" },
  { value: "100085", label: "Pension Protection Fund" },
  { value: "100086", label: "Infast Money Purchase Scheme" },
  { value: "100087", label: "Family Investment " },
  { value: "100088", label: "EDS" },
  { value: "100089", label: "country wide assurance" },
  { value: "100090", label: "Reassure (Was Windsor Life)" },
  { value: "100091", label: "SIPP Centre" },
  { value: "100092", label: "Bridge Trustees" },
  { value: "100093", label: "HSBC" },
  { value: "100094", label: "Reassure - Tomorrow" },
  { value: "100095", label: "EDS Pension Administration" },
  { value: "100096", label: "Sun Life Financial Of Canada-target life" },
  { value: "100097", label: "AJ Bell Platinum SIPP " },
  { value: "100098", label: "AXA Life Europe " },
  { value: "100099", label: "AXA Wealth " },
  { value: "100100", label: "Barclays Stockbroker SIPP " },
  { value: "100101", label: "Cofunds " },
  { value: "100102", label: "Curtis Banks " },
  { value: "100103", label: "Halifax Share Dealing SIPP " },
  { value: "100104", label: "Hartley SAS " },
  { value: "100105", label: "Just Retirement " },
  { value: "100106", label: "Liberty SIPP " },
  { value: "100107", label: "SIPP Deal PART OF AJ BELL" },
  { value: "100108", label: "SIPP Deal Xtra" },
  { value: "100109", label: "Skandia SIPP " },
  { value: "100110", label: "Suffolk Life" },
  { value: "100111", label: "Sun Life Financial of Canada " },
  { value: "100112", label: "TD SIPP " },
  { value: "100113", label: "Barclays" },
  { value: "100114", label: "cargill company pension scheme" },
  { value: "100115", label: "hagreaves lansdown" },
  { value: "100116", label: "formica " },
  { value: "100117", label: "Barclays (Reassure)" },
  { value: "100118", label: "CIS Insurance" },
  { value: "100119", label: "B & CE" },
  { value: "100120", label: "Halifax Financial Services" },
  { value: "100121", label: "Royal London " },
  { value: "100122", label: "Reassure" },
  { value: "100123", label: "Cable and Wireless Communications UK pensions" },
  { value: "100124", label: "Countrywide Assured" },
  { value: "100125", label: "DHL Pension Department" },
  { value: "100126", label: "Equinti" },
  { value: "100127", label: "Friends Life Free Standing AVC" },
  { value: "100128", label: "Friends Life Personal Pension" },
  { value: "100129", label: "Friends Provident (Friends Life)" },
  { value: "100130", label: "Hargreaves Lansdown" },
  { value: "100131", label: "J.P Morgan" },
  { value: "100132", label: "James Hay" },
  { value: "100133", label: "Jessops" },
  { value: "100134", label: "Lloyds Banking Group Your Tomorrow" },
  { value: "100135", label: "Mercer" },
  { value: "100136", label: "Natwest" },
  { value: "100137", label: "Phoenix (Liverpool)" },
  { value: "100138", label: "Phoenix (Romford)" },
  { value: "100139", label: "Phoenix (St Vincent)" },
  { value: "100140", label: "Phoenix (former Scotish Mutual plans)" },
  { value: "100141", label: "BlackRock - McIntyre DC Scheme" },
  { value: "100142", label: "Co- opertative Investments " },
  { value: "100144", label: "Phoenix (Peterborough)" },
  { value: "100145", label: "Virgin Money" },
  { value: "100146", label: "Capita" },
  { value: "100147", label: "London Pension Fund Authority (LPFA)" },
  { value: "100148", label: "Polestar Mercer " },
  { value: "100149", label: "Police Mutual Assurance Society Limited" },
  { value: "100150", label: "Primetime Retirement " },
  { value: "100151", label: "Prudential" },
  { value: "100152", label: "Prudential (teachers AVC)" },
  { value: "100153", label: "Reliance Mutual " },
  { value: "100154", label: "Rentokil Initial" },
  { value: "100155", label: "Resolutions " },
  { value: "100156", label: "Rolls Royce" },
  { value: "100157", label: "Royal Liver Assurance (part Of Royal London)" },
  { value: "100158", label: "Royal London Plus" },
  { value: "100159", label: "Schlumberger (Towers Watson)" },
  { value: "100160", label: "Scottish Amicable[Prudential Now]" },
  { value: "100161", label: "Scottish Life (M.Mercado Ltd)" },
  { value: "100162", label: "Sky Pensions" },
  { value: "100163", label: "Standard Life [Executive Personal Pension]" },
  { value: "100164", label: "Aviva Credit Control" },
  { value: "100165", label: "Alico Living Time" },
  { value: "100166", label: "Aon Consulting Ltd" },
  { value: "100167", label: "BAE Systems DB Retirement Plan" },
  { value: "100168", label: "TDG PENSIONS SCHEME" },
  { value: "100169", label: "Co operative " },
  { value: "100170", label: "Teachers Assurance" },
  { value: "100171", label: "The Pensions Trust" },
  { value: "100172", label: "Threadneeddle Pensions LTD" },
  { value: "100173", label: "Abbey Life " },
  {
    value: "100174",
    label:
      "AEON Consulting Ramsey Heealth Care UK Operations Ltd Pension Schem",
  },
  { value: "100175", label: "Nat West" },
  { value: "100176", label: "Santander (Royal London)" },
  { value: "100177", label: "Investment Solutions" },
  { value: "100178", label: "European Pensions Management Ltd EPML" },
  { value: "100179", label: "Huntings Pension Trust Ltd" },
  { value: "100180", label: "M P I" },
  { value: "100181", label: "Hargreave's Lansdowne" },
  { value: "100182", label: "British Airways" },
  { value: "100183", label: "USS (Universities Superannuation Scheme) " },
  { value: "100184", label: "Co-Operative Insurance Society" },
  { value: "100185", label: "MNPA" },
  { value: "100186", label: "Virgin" },
  { value: "100187", label: "Towry" },
  { value: "100188", label: "Bradshaw Homes Pension Fund" },
  { value: "100189", label: "clear channel" },
  { value: "100190", label: "J C McColl" },
  { value: "100191", label: "Lafarge" },
  { value: "100192", label: "IBM" },
  { value: "100193", label: "Scottish Provident" },
  { value: "100194", label: "Friends Life Winterthur Plans beg with a G " },
  { value: "100195", label: "Peel Holdings " },
  { value: "100196", label: "Scottish Provident " },
  { value: "100197", label: "Xafinity Paymaster EDS Pensions" },
  { value: "100198", label: "Combined Nuclear Pension Plan" },
  { value: "100199", label: "Axa Wealth" },
  { value: "100200", label: "Sterling/Zurich" },
  { value: "100201", label: "Lawdac" },
  { value: "100202", label: "Prudential Group Personal Pension" },
  { value: "100203", label: "Hornbuckle Mitchell" },
  { value: "100204", label: "Arco " },
  { value: "100205", label: "ARCO" },
  { value: "100206", label: "Arco Pension Scheme " },
  { value: "100207", label: "The Co-operative Pension Scheme (Pace) " },
  { value: "100208", label: "Legal & General (Wates Group Pension Plan)" },
  { value: "100209", label: "Higgins Construction" },
  { value: "100210", label: "AMEX" },
  { value: "100211", label: "ICI" },
  { value: "100212", label: "Foster Wheeler (Towers Watson)" },
  { value: "100213", label: "Bechtel" },
  { value: "100214", label: "L&G / M W Kellogg" },
  { value: "100215", label: "Aker Kverner" },
  { value: "100216", label: "Foresters" },
  {
    value: "100217",
    label: "Royal London - Ex  Co-Operative Financial Services",
  },
  {
    value: "100218",
    label: "POIS  This is not Post Office Insurance See Below",
  },
  { value: "100219", label: "Blue Sky Pension" },
  { value: "100220", label: "Invensys" },
  { value: "100221", label: "JSS trustees" },
  { value: "100222", label: "Allianz" },
  { value: "100223", label: "TMD" },
  { value: "100224", label: "D S Smith Saver Scheme" },
  { value: "100225", label: "Capita (Hewlett Packard)" },
  { value: "100226", label: "Equinity (Nolson Coors UK Pension Plan)" },
  { value: "100227", label: "National Grid UK Pensions" },
  { value: "100228", label: "Capita Hartshead ~Sheffield " },
  { value: "100229", label: "Transact - Sipp" },
  {
    value: "100230",
    label: "Jaguar JLT Benefit Solutions - Jaguar Benefits Admin Team",
  },
  { value: "100231", label: "Aviva Portfolio plan" },
  { value: "100232", label: "Nat Grid " },
  { value: "100233", label: "Marks and Spencer" },
  { value: "100234", label: "Halifax" },
  { value: "100235", label: "American Express U K Pensions " },
  { value: "100236", label: "Timex GPP " },
  { value: "100238", label: "JLT Benefit Solutions LTD" },
  { value: "100239", label: "Macdermid Canning Pensions Ltd" },
  { value: "100240", label: "Xafinity Paymaster Leeds" },
  { value: "100241", label: "Telent Pensions " },
  { value: "100242", label: "Allianz ( jlt)" },
  { value: "100243", label: "Molson Coors (UK) Pension Plan" },
  { value: "100244", label: "Marks & Spencer" },
  { value: "100245", label: "RBS" },
  { value: "100246", label: "Towers Watson" },
  {
    value: "100247",
    label: "Greenalls Group Pension Scheme ( Towers Watson )",
  },
  { value: "100248", label: "Friends Life (Winterthur)" },
  { value: "100249", label: "Axa Equity & Law Life Assurance Society" },
  { value: "100250", label: "Standard Life (Bond Air Services Ltd)" },
  { value: "100251", label: "Commercial Union" },
  { value: "100252", label: "Organon" },
  { value: "100253", label: "Financial Assistance Scheme" },
  { value: "100254", label: "First Direct" },
  { value: "100255", label: "Friends Life plans beg with BK" },
  {
    value: "100256",
    label: "Friends Life Bristol ( policies that start with 100)",
  },
  { value: "100257", label: "Tenant" },
  { value: "100258", label: "JLT NSPCC pensions" },
  { value: "100259", label: "Ensign" },
  { value: "100260", label: "Friends Life beg with 7" },
  { value: "100261", label: "Friends Life Policy begin with DK" },
  { value: "100262", label: "Alliance Trust" },
  { value: "100263", label: "Abbey for Intermediaries Scottish Mutual" },
  { value: "100264", label: "A J Bell Trustees" },
  { value: "100265", label: "AJ Bell Trustees Ltd" },
  { value: "100266", label: "Tesco Trustees Ltd" },
  { value: "100267", label: "Best Invest" },
  { value: "100268", label: "British Telecom" },
  { value: "100269", label: "Sanlam" },
  { value: "100270", label: "Canada Life" },
  {
    value: "100271",
    label: "Capita Hartshead - Powell Duffryn Employee Pension Scheme",
  },
  {
    value: "100272",
    label: "Standard Life (SSA Global Technology Pension Scheme)",
  },
  {
    value: "100273",
    label: "Standard Life: Pharmaceutical Packaging (Leeds) Ltd",
  },
  { value: "100274", label: "Equiniti" },
  { value: "100275", label: "Xafinity" },
  { value: "100276", label: "Curtis Banks" },
  { value: "100277", label: "The Hill Samuel Group" },
  { value: "100278", label: "Scottish Friendly" },
  { value: "100279", label: "Punter Southall Consulting Actuaries" },
  { value: "100280", label: "Scandia" },
  { value: "100281", label: "National Coal Board" },
  { value: "100282", label: "Glencore UK" },
  { value: "100283", label: "David Broughton/CO Shirebrook Investments Ltd" },
  { value: "100284", label: "KPMG LLP Pensions" },
  { value: "100285", label: "Aon Hewitt " },
  {
    value: "100286",
    label: "Sun Life Financial of Canada (ex Lincoln Finance)",
  },
  { value: "100287", label: "Builder & Civil Engineering Benefit Scheme" },
  { value: "100288", label: "Kingfisher" },
  { value: "100289", label: "PFP Benefit Solutions" },
  { value: "100290", label: "Magna UK Pension Scheme" },
  { value: "100291", label: "KPMG Bibby Line Group Pension Scheme" },
  { value: "100292", label: "Mercer-Saint Gobain" },
  { value: "100293", label: "Friends Life company pension" },
  { value: "100294", label: "SmithKline Beecham" },
  { value: "100295", label: "Railway Pensions Scheme" },
  { value: "100296", label: "Capita Hartshead - William Hill Retirement Plan" },
  {
    value: "100297",
    label: "JLT Benefit Solutions Ltd  - The Elementis Group Pension Scheme",
  },
  { value: "100298", label: "Capita Hartshead - Delta DC Scheme" },
  { value: "100299", label: "Virgin Direct" },
  {
    value: "100300",
    label: "Standard Life (Fenchurch Environmental Group Staff Pension Scheme)",
  },
  { value: "100301", label: "Barnett Waddingham LLP" },
  { value: "100302", label: "Aon" },
  { value: "100303", label: "Scottish Mutual" },
  {
    value: "100304",
    label: "Equiniti - Llyods Bank Pension SAcheme Number 1 (C&G Section)",
  },
  {
    value: "100305",
    label: "Mercer - British Midland Airways Pension DC Top up section.",
  },
  { value: "100306", label: "Co-Operative Group Pension Dept" },
  { value: "100307", label: "ITW" },
  {
    value: "100308",
    label:
      "JLT  _  Phoenix Life - Exeter and Devon Airport Ltd 1991 Pension Scheme.",
  },
  { value: "100309", label: "Bower Retirement Services" },
  {
    value: "100310",
    label: "Accenture Retirement Savings Plan (Towers Watson)",
  },
  { value: "100311", label: "BBC" },
  {
    value: "100312",
    label: "Wiltshire Pension Fund (Local Government Pension Scheme) ",
  },
  { value: "100313", label: "Royal Bank Of Scotland" },
  { value: "100314", label: "Capita Black and Decker" },
  { value: "100315", label: "John Lewis Partnership Pensions Trust" },
  { value: "100316", label: "KPMG LLP Pensions / NMB Pensions" },
  { value: "100317", label: "First Actuarial" },
  { value: "100318", label: "Somerfield Pension Scheme" },
  { value: "100319", label: "Aon Hewitt - NHBC Pension Scheme" },
  { value: "100320", label: "G4S Pension Scheme (Securicor Section)" },
  { value: "100321", label: "KNORR-BREMSE" },
  { value: "100322", label: "Teachers Pension Agency" },
  { value: "100323", label: "Scottish Life Group Pensions" },
  { value: "100324", label: "Aviva" },
  { value: "100325", label: "prudential ford motor company " },
  { value: "100326", label: "nationwide" },
  { value: "100327", label: "Lloyds TSB Employee Pension Benefits" },
  { value: "100328", label: "Premier Pensions Management" },
  { value: "100329", label: "NPI " },
  {
    value: "100330",
    label: "Lloyds Bank pension scheme number 2 - C/O Equiniti Ltd",
  },
  { value: "100331", label: "Flemings" },
  {
    value: "100332",
    label: "Amcor Packaging Pension Plan - UK (Lawson Mardon Pension Scheme)",
  },
  { value: "100333", label: "Atkins" },
  { value: "100334", label: "Standex" },
  { value: "100335", label: "Royal London (CIS)" },
  { value: "100336", label: "Balfour Beatty" },
  { value: "100337", label: "Capit (previously Bluefin)" },
  { value: "100338", label: "Pensions Trust" },
  { value: "100339", label: "Pension Trust" },
  { value: "100340", label: "Baptist Union" },
  { value: "100341", label: "Brush Group (2013) Pension Scheme" },
  { value: "100342", label: "Save and Prosper" },
  { value: "100343", label: "Philips" },
  { value: "100344", label: "West Midlands Pension Fund" },
  { value: "100345", label: "Ladbrokes" },
  { value: "100346", label: "Resource" },
  { value: "100347", label: "NCR" },
  { value: "100348", label: "Xafinity Consulting" },
  { value: "100349", label: "Scottish Life (Crest Secure)" },
  { value: "100350", label: "Land Instruments International Ltd" },
  { value: "100351", label: "Friends Life Corporate pensions" },
  { value: "100352", label: "Centrica Pension Scheme" },
  { value: "100353", label: "UTC Pension Trust Limited" },
  { value: "100354", label: "Provident Financial Group" },
  { value: "100355", label: "Tower Hamlets Pension Scheme" },
  { value: "100356", label: "Axa Elevate" },
  { value: "100357", label: "Capita (Proctor & Gamble)" },
  { value: "100358", label: "Dixons " },
  { value: "100359", label: "Alliance and Leicester" },
  { value: "100360", label: "Central - Tax & Trustee Planning" },
  { value: "100361", label: "Legal & General Corporate Services" },
  { value: "100362", label: "RWE npower group of the ESPS-Main Section" },
  { value: "100363", label: "Morgan Lloyd" },
  { value: "100364", label: "Northern Foods Pension Scheme" },
  { value: "100365", label: "The Hartford" },
  {
    value: "100367",
    label: "Guardian Financial Services (Formerly Guardian Royal Exchange)",
  },
  { value: "100368", label: "Towers Watson - Balfour Beatty" },
  { value: "100369", label: "Family Investment POI" },
  { value: "100370", label: "Police Staff Pension" },
  { value: "100371", label: "Friends Life Corporate Services" },
  { value: "100372", label: "Fidelity - ICL Group Pension Scheme" },
  { value: "100373", label: "Capita Hartshead - Dow Services UK Pension Plan" },
  { value: "100374", label: "JLT GKN Pension Scheme" },
  { value: "100375", label: "Metlife" },
  { value: "100376", label: "Avon Pension Fund" },
  { value: "100377", label: "Durham County Council Superannuation Scheme" },
  { value: "100378", label: "Friends Mutual" },
  { value: "100379", label: "Jardine Lloyd Thompson" },
  { value: "100380", label: "Charles Stanley" },
  { value: "100381", label: "Pension Insurance Corporation" },
  { value: "100382", label: "Marshall Airspace" },
  { value: "100383", label: "woolwich" },
  { value: "100384", label: "The Chviot Trust" },
  { value: "100385", label: "mercedes finance" },
  { value: "100386", label: "valkeswagen finance" },
  { value: "100387", label: "State Pensions " },
  { value: "100388", label: "WHSmith" },
  { value: "100389", label: "Vodafone Pension (Towers Watson)" },
  { value: "100390", label: "Thomas Cook" },
  { value: "100391", label: "Xafinity Paymaster" },
  { value: "100392", label: "Peninsula Pensions" },
  { value: "100393", label: "Willis Group Services Ltd" },
  { value: "100394", label: "Equiniti - Llyods Number 1 and 2 scheme" },
  { value: "100395", label: "Aegon National Veterinary Services" },
  { value: "100396", label: "Punter Southall (Ryder Pension Scheme)" },
  { value: "100397", label: "TD Waterhouse" },
  {
    value: "100398",
    label: "Capita - The Guinness Mahon Group Pension Scheme.",
  },
  { value: "100399", label: "Mobius Life" },
  { value: "100400", label: "Mortgage Express" },
  { value: "100401", label: "Foreign & Colonial" },
  { value: "100402", label: "TRW Automotive Pension Scheme" },
  { value: "100403", label: "Prudential (ASDA Pension Scheme)" },
  { value: "100404", label: "ASDA Pensions Team" },
  { value: "100405", label: "Cassidian" },
  { value: "100406", label: "RowanMoor - RSH (Chesterfield) Ltd EPP Scheme" },
  { value: "100407", label: "whittingham" },
  {
    value: "100408",
    label: "Jardine Lloyd Thompson - Redfunnell - Zurich AVC",
  },
  { value: "100409", label: "Adco" },
  { value: "100410", label: "Merchant Navy Penssions" },
  { value: "100411", label: "Trafalgar House Pension Scheme" },
  { value: "100412", label: "Brewin Dolphin" },
  { value: "100413", label: "Scottish Equity" },
  { value: "100414", label: "Prudential AVC" },
  { value: "100415", label: "British Coal Superannuation Scheme" },
  {
    value: "100416",
    label: "Towers Watson - Ex Royal London Staff pension Fund",
  },
  { value: "100417", label: "Friends Life" },
  { value: "100418", label: "Hymans Robertson" },
  { value: "100419", label: "Investco" },
  { value: "100420", label: "Bank of Ireland Staff Pensions Fund (BSPF UK)" },
  { value: "100421", label: "123 Solutions (Debt Management)" },
  { value: "100422", label: "Assured Packard" },
  { value: "100423", label: "Investec" },
  { value: "100424", label: "Skipton" },
  { value: "100425", label: "MBNA" },
  { value: "100426", label: "Old Mutual Wealth" },
  { value: "100427", label: "Arcadia Group" },
  { value: "100428", label: "Hawthorn Life" },
  { value: "100429", label: "Friends LIfe ( Policies beginning with BD)" },
  { value: "100430", label: "Care Group" },
  { value: "100431", label: "Howdens Joinery/ Towers Watson" },
  { value: "100432", label: "British Gas" },
  { value: "100433", label: "Bupa" },
  { value: "100434", label: "AON -Getronics UK Pension Plan" },
  { value: "100435", label: "Skanska" },
  {
    value: "100436",
    label: "Associated British Foods Pension Trustees Limited",
  },
  { value: "100437", label: "Fisher Investments Ltd " },
  { value: "100438", label: "Global Partners Ltd" },
  { value: "100439", label: "honeywell" },
  { value: "100440", label: "Babcock International Group Pension Scheme" },
  { value: "100441", label: "Towers Watson Honeywell Uk Pension Scheme" },
  { value: "100442", label: "Deloitte, total rewards benefits" },
  { value: "100443", label: "The Pensions Trust - JLT Benefit Solutions" },
  {
    value: "100444",
    label: "JLT Benefit Solutions - Lucas CAV Pension Scheme",
  },
  { value: "100445", label: "Capita - Lucas CAV Pension Scheme" },
  { value: "100446", label: "Civil Aviation Authority" },
  { value: "100447", label: "M&G" },
  { value: "100448", label: "Pointon York" },
  { value: "100449", label: "Capita (Thames Water Pension Scheme)" },
  { value: "100450", label: "Rothesay" },
  { value: "100451", label: "Royal Sun Alliance" },
  { value: "100452", label: "Kraft Foods" },
  { value: "100453", label: "Orange " },
  { value: "100454", label: "EE" },
  { value: "100455", label: "Marsh Uk" },
  { value: "100456", label: "Bedford County Council" },
  { value: "100457", label: "Birmingham Midshires" },
  { value: "100458", label: "Massey Ferguson" },
  { value: "100459", label: "University of Bristol" },
  { value: "100460", label: "Mitchell Consulting" },
  { value: "100461", label: "Wellplan" },
  { value: "100462", label: "600 Group PLC Pension  Scheme" },
  { value: "100463", label: "Halma" },
  { value: "100464", label: "Yorkshire Building Society" },
  { value: "100465", label: "Lloyds TSB Staff Pension Scheme" },
  { value: "100466", label: "Go Ahead" },
  { value: "100467", label: "Halliburton" },
  { value: "100468", label: "Rexam" },
  { value: "100469", label: "Wincanton" },
  { value: "100470", label: "Leicester County Council" },
  { value: "100471", label: "Leicester City Council" },
  { value: "100472", label: "Allied Dunbar" },
  { value: "100473", label: "Gala Coral Pension Plan" },
  { value: "100474", label: "Seat" },
  { value: "100475", label: "British Aerospace Final Salary Scheme" },
  { value: "100476", label: "Intelligent Money Ltd" },
  { value: "100477", label: "MW Pensions" },
  { value: "100478", label: "Fiat" },
  { value: "100479", label: "GlaxoSmithKline (GSK)" },
  { value: "100480", label: "FSA" },
  { value: "100481", label: "Rowan Dartington" },
  { value: "100482", label: "Royal Mail Pension Plan" },
  { value: "100483", label: "Lookers LTD" },
  { value: "100484", label: "volkswagen finance" },
  { value: "100485", label: "L&G" },
  { value: "100486", label: "Aon Hewitt - AA Pension Scheme - " },
  { value: "100487", label: "Sodexo" },
  { value: "100488", label: "Lancashire County Council" },
  { value: "100489", label: "NORTHUMBRIAN WATER PENSION TRUSTEES LIMITED" },
  { value: "100490", label: "Hartlink" },
  { value: "100491", label: "PZ Cussons" },
  { value: "100492", label: "Pendragon " },
  { value: "100493", label: "Veterans UK - Armed Forces Pension Scheme" },
  { value: "100494", label: "Nord Anglia Education" },
  { value: "100495", label: "JLT  Bracknell" },
  { value: "100496", label: "Aon Hewitt - Durham University Pension Scheme" },
  { value: "100497", label: "Sea Containers Ltd" },
  { value: "100498", label: "BMP Paris" },
  { value: "100499", label: "Compass Group Pension Plan" },
  { value: "100500", label: "Scottish Life / Royal London" },
  { value: "100501", label: "Marley Pension Department " },
  { value: "100502", label: "Privilege" },
  { value: "100503", label: "EEA" },
  { value: "100504", label: "EEA Fund Manager" },
  { value: "100505", label: "JP Morgan Chase (Towers Watson)" },
  { value: "100506", label: "My Wealth at Work" },
  { value: "100507", label: "Ascot Lloyd" },
  { value: "100508", label: "NHS" },
  { value: "100509", label: "Capita - Morrisons Retirement Saver Plan" },
  { value: "100510", label: "Airbus" },
  { value: "100511", label: "Amey Pension Scheme - Money Purchase Section" },
  { value: "100512", label: "Zurich/Eagle Star" },
  { value: "100513", label: "Joseph Black" },
  { value: "100514", label: "Norwich Union" },
  { value: "100515", label: "Glasgow City Council" },
  { value: "100516", label: "Equitable Life" },
  { value: "100517", label: "TKM Group Pension Scheme" },
  { value: "100518", label: "Unilever" },
  { value: "100519", label: "Engage Mutual Assurance" },
  { value: "100520", label: "Western Power" },
  { value: "100521", label: "Crest Homes" },
  { value: "100522", label: "Lighthouse Pension Services" },
  { value: "100523", label: "Nuffield Health Group" },
  { value: "100524", label: "Nest" },
  { value: "100525", label: "Debenhams" },
  { value: "100526", label: "SR Technics UK Ltd Pension Scheme " },
  { value: "100527", label: "L F Beauty" },
  { value: "100528", label: "Mercer - ITT GroupmPension Scheme" },
  { value: "100529", label: "Rothschild" },
  { value: "100530", label: "Hewitt Associates" },
  {
    value: "100531",
    label: "British Alcan Retirement Income and Assurance Plan",
  },
  { value: "100532", label: "BBS Consultants and Actuaries Ltd" },
  {
    value: "100533",
    label:
      "Aon Hewitt - The Towers Russell Pension Scheme  Money Purchase Section",
  },
  { value: "100534", label: "Pentagon House Pension Services" },
  { value: "100535", label: "Gemmells" },
  { value: "100536", label: "Reed Elsevier" },
  { value: "100537", label: "Kent County Council" },
  { value: "100538", label: "Barlow World Handling" },
  { value: "100539", label: "Barloworld Handling" },
  { value: "100540", label: "MILK" },
  { value: "100541", label: "Rentokill Initial " },
  { value: "100542", label: "Samworth Brothers" },
  { value: "100543", label: "Buck Consultations" },
  { value: "100544", label: "Trigon" },
  { value: "100545", label: "Michelin" },
  { value: "100547", label: "FCA" },
  { value: "100548", label: "Mitchelin" },
  { value: "100549", label: "WYKO" },
  {
    value: "100550",
    label: "Xerox Benefit Administration Services (Manchester)",
  },
  { value: "100551", label: "David Brown Union" },
  { value: "100552", label: "BAXI" },
  { value: "100553", label: "MPI" },
  { value: "100554", label: "Northern Ireland Electricity Pension Scheme" },
  {
    value: "100555",
    label:
      "JLT Benefit Solutions C&G UK PENSION SCHEME(ADMIRAL SECTION) STANDARD LIFE AVC FUND",
  },
  {
    value: "100557",
    label:
      "Hyde Housing Association Ltd Pension and Life Assurance Scheme (The Scheme) Hymans Robertson LLP Pensions Administration",
  },
  { value: "100558", label: "Tyco Electronic Pension Plan" },
  {
    value: "100559",
    label: "Capita - Yorkshire Water Final Salary Pension Scheme",
  },
  {
    value: "100560",
    label:
      "JLT Benefit Solutions LTD - Lincoln Electrics retirement and death benefits scheme",
  },
  { value: "100561", label: "Capita - Procter and Gamble -Wella Section " },
  { value: "100562", label: "Sony UK Pension Scheme- Towers Watson - " },
  { value: "100563", label: "Spirit Group" },
  { value: "100564", label: "C&J Clarkes Arvells" },
  { value: "100565", label: "C&J Clarkes" },
  { value: "100566", label: "Arkells" },
  { value: "100567", label: "Owen Owen" },
  { value: "100568", label: "Hughes Price Walker" },
  { value: "100569", label: "Broadstone" },
  {
    value: "100570",
    label: "Aon Hewitt - Spirit Group Retail Pension Scheme.",
  },
  { value: "100571", label: "IBC Vehicles Limited" },
  { value: "100572", label: "B&CE" },
  { value: "100573", label: "Sanderson Law Pensions Management" },
  { value: "100574", label: "Pilkington Superannuation Scheme" },
  {
    value: "100575",
    label: "Towers Watson Ltd In Bev UK Group Pension Scheme",
  },
  { value: "100576", label: "Tata Steel Works" },
  { value: "100577", label: "Amber Pension Trust" },
  { value: "100578", label: "Misys" },
  { value: "100579", label: "BAE Systems/ 2000 Pension Plan" },
  { value: "100580", label: "Corpad" },
  { value: "100581", label: "Cemex Pension Administrators" },
  { value: "100582", label: "Black Rock " },
  { value: "100583", label: "Shipbuilding Industries Pension Scheme. SIPS. " },
  { value: "100584", label: "Rio Tinto Group" },
  { value: "100585", label: "PSA Peugeot Citroen" },
  { value: "100586", label: "Northern Power Grid" },
  { value: "100587", label: "Smith & Nephew" },
  { value: "100588", label: "Cobham Engineering" },
  { value: "100589", label: "Cheshire Pension Fund" },
  { value: "100590", label: "Thomson-Reuters" },
  { value: "100591", label: "Erskine House" },
  { value: "100592", label: "C/O Ricoh UK Ltd" },
  { value: "100593", label: "Pitney Bowes" },
  { value: "100594", label: "Alcatel-Lucent" },
  { value: "100595", label: "Berkeley Homes" },
  { value: "100596", label: "Short Brothers" },
  { value: "100597", label: "ESPS Eon Group (RPMI EPAL - Administrator)" },
  { value: "100598", label: "Building & Civil Engineers" },
  { value: "100599", label: "National Trust Staff Pension Scheme" },
  { value: "100600", label: "Bunzl Pension Scheme" },
  {
    value: "100601",
    label:
      "Capita Hartshead - EE Group Final Sal Scheme - AVC Benefit from Fidelity",
  },
  { value: "100602", label: "Harbour Pensions" },
  { value: "100603", label: "Meritor" },
  { value: "100604", label: "Novia Financial PLC" },
  { value: "100605", label: "Falcon International Estates" },
  { value: "100606", label: "Finnie UK Limited" },
  { value: "100607", label: "St. Pauls Pension Scheme" },
  { value: "100608", label: "Legrand UK Ltd" },
  { value: "100609", label: "Northern Gas Networks" },
  { value: "100610", label: "Berkeley Burke (Carter Allen)" },
  { value: "100611", label: "Bernard Bassington" },
  { value: "100612", label: "British Steel" },
  { value: "100613", label: "BMPS UK Pension Fund" },
  { value: "100614", label: "BNP Paribas" },
  { value: "100615", label: "ITV" },
  { value: "100616", label: "Swiss Reassure" },
  { value: "100617", label: "JLT Benefits Solution (Sun Alliance London)" },
  { value: "100618", label: "CSI" },
  { value: "100619", label: "Astra Zeneca (formally ICI)" },
  { value: "100620", label: "Dyfed Local Government Scheme COMPS. " },
  { value: "100621", label: "Marshalls of Cambridge" },
  { value: "100622", label: "FKI" },
  { value: "100623", label: "Bank of England" },
  { value: "100624", label: "Bombardier" },
  { value: "100625", label: "Essentre" },
  { value: "100626", label: "Provident Mutual" },
  { value: "100627", label: "Murray Group" },
  { value: "100628", label: "Towers Watson (EY Pension Scheme)" },
  { value: "100629", label: "Kimberley-Clark" },
  { value: "100630", label: "Interactive Investor" },
  { value: "100631", label: "Mattioli woods" },
  { value: "100632", label: "London & Colonial" },
  { value: "100633", label: "UNIQ" },
  { value: "100634", label: "Merseyside Pension Fund" },
  { value: "100635", label: "Diageo" },
  { value: "100636", label: "Illinois Tool Works" },
  { value: "100637", label: "Amicus Wealth" },
  { value: "100638", label: "BT Pension Scheme" },
  { value: "100639", label: "DAL" },
  { value: "100640", label: "UK Power Station Scheme" },
  { value: "100641", label: "Donnington MC Pension Scheme" },
  { value: "100642", label: "Attivo Group" },
  { value: "100643", label: "Paragon" },
  {
    value: "100644",
    label:
      "Baker Tilly - Ref Friends Provident International Pension Scheme - ",
  },
  { value: "100645", label: "Succession Wealth Group" },
  { value: "100646", label: "Midscot Training - Trustees" },
  { value: "100647", label: "Midscot Training " },
  { value: "100648", label: "AVF" },
  { value: "100649", label: "Talbot Muir" },
  { value: "100650", label: "Wilson Sporting Goods" },
  { value: "100651", label: "BA Systems" },
  { value: "100652", label: "Telefonica" },
  {
    value: "100653",
    label:
      "Barnett Waddingham Fergusson Wild Group Pension & Assurance Scheme ",
  },
  { value: "100654", label: "Sendia" },
  { value: "100655", label: "CAREY CORPORATE PENSIONS UK " },
  { value: "100656", label: "Punter Southall Group (Sumitomo Pension Scheme)" },
  { value: "100657", label: "Coventry" },
  { value: "100658", label: "Crown Servants (Grosvenor) Pension Scheme" },
  { value: "100659", label: "St James Investment Company" },
  { value: "100660", label: "Easy Build" },
  { value: "100661", label: "MyCSP" },
  { value: "100662", label: "Domecque" },
  { value: "100663", label: "Delmonte" },
  { value: "100664", label: "The Peoples Pension" },
  { value: "100665", label: "Carey Group" },
  { value: "100666", label: "AB World Foods" },
  { value: "100667", label: "PPD" },
  { value: "100668", label: "Xerox" },
  { value: "100669", label: "Amec Foster Wheeler" },
  { value: "100670", label: "Hilton Group" },
  { value: "100671", label: "Pharmaceutical Product Development" },
  { value: "100672", label: "John Heathcoat" },
  { value: "100673", label: "Delphi Automotive Systems" },
  { value: "100674", label: "Drax Power" },
  { value: "100675", label: "ITB Pension Funds" },
  { value: "100676", label: "Highmans" },
  { value: "100677", label: "Scottish Equitable" },
  { value: "100678", label: "FT Construction Group " },
  { value: "100679", label: "British Oxygen Party Pension Scheme" },
  { value: "100680", label: "Daily Telergraph" },
  { value: "100681", label: "Stagecoach Group Pension scheme" },
  { value: "100682", label: "GRSL" },
  { value: "100683", label: "Baker Hughes" },
  { value: "100684", label: "Pension Practitioner" },
  { value: "100685", label: "Fujitsu" },
  { value: "100686", label: "Roche Products Pension Trust Limited" },
  { value: "100687", label: "Worcestershire County Council" },
  { value: "100688", label: "Vauxhall Motors" },
  { value: "100689", label: "Joy Global" },
  { value: "100690", label: "UK Power Network" },
  { value: "100691", label: "Pennon Group" },
  { value: "100692", label: "Aon Hewitt - C/O  The RHM (DB Scheme)" },
  { value: "100693", label: "One Family" },
  { value: "100694", label: "Towers Watson/Thomas Cook" },
  { value: "100695", label: "EON" },
  { value: "100696", label: "The Cheviot Scheme" },
  { value: "100697", label: "ABF" },
  { value: "100698", label: "C BREWER &SONS LTD RETIREMENT BENEFITS SCHEME  " },
  { value: "100699", label: "Tullet Prebon-Final Salary" },
  { value: "100700", label: "Wrexham" },
  { value: "100701", label: "Hawthorne Platinum" },
  { value: "100702", label: "Samlam" },
  { value: "100703", label: "Goddard Perry Consulting" },
  { value: "100704", label: "Mondelez" },
  { value: "100705", label: "Royal Hospital" },
  { value: "100706", label: "Lifetime Sipp" },
  { value: "100707", label: "Local Government Pension Scheme" },
  { value: "100708", label: "Frank Robert and Son's Limited Pensions " },
  { value: "100709", label: "Anglo American PLC" },
  { value: "100710", label: "Punters Southall Pensions Administration" },
  { value: "100711", label: "SPECTRIS PLC" },
  { value: "100712", label: "Canadian Pacific" },
  { value: "100713", label: "West Yorkshire Pension Fund" },
  { value: "100714", label: "Guide Dogs For The Blind Pension Scheme" },
  { value: "100715", label: "SGN Pension Scheme" },
  { value: "100716", label: "MOTOROLA SOLUTIONS PENSION TRUSTEES LIMITED" },
  { value: "100717", label: "AIG Pension Plan" },
  { value: "100718", label: "Barnados" },
  { value: "100719", label: "Aga Rangemaster" },
  { value: "100720", label: "Carillion Pension Plan " },
  { value: "100721", label: "Social Housing Pension Scheme " },
  { value: "100722", label: "Capita NWL" },
  { value: "100724", label: "Houghton Plc Group Pension Scheme" },
  { value: "100725", label: "Punta and Southern " },
  { value: "100726", label: "Ratheon Pensions " },
  { value: "100727", label: "LIC of India " },
  { value: "100728", label: "Quattro Pensions" },
  { value: "100729", label: "Multiplex" },
  { value: "100730", label: "Kier Business Services" },
  { value: "100731", label: "Inchcape Pension Scheme" },
  {
    value: "100732",
    label:
      "CPRM Actuaries and Consultants -C/O  Laura Ashley Retirement Benefits Scheme for Employees",
  },
  { value: "100733", label: "Forth Ports LTD" },
  { value: "100735", label: "EMCOR Group Pension Scheme" },
  { value: "100736", label: "Affinity Pension Plan" },
  { value: "100737", label: "Bradford & Bingley Staff Pension Scheme" },
  { value: "100738", label: "Achieva Pension Plan" },
  { value: "100739", label: "JWP Life & Pensions" },
  { value: "100740", label: "Mercer - Sedgwick Noble Lowndes" },
  { value: "100741", label: "Bank Of Ireland" },
  { value: "100742", label: "Group Lotus " },
  { value: "100743", label: "T Systems" },
  { value: "100744", label: "Liverpool Victoria" },
  { value: "100745", label: "KPMG" },
  { value: "100746", label: "Scapa Group Pension Scheme" },
  { value: "100747", label: "Scottish Pension Trustees" },
  { value: "100748", label: "Retirement Advantage" },
  {
    value: "100749",
    label: "Willis Towers Watson - DSM UK Pension Scheme - AVC Fund ",
  },
  { value: "100750", label: "Ford Pension Trustees Ltd" },
  { value: "100751", label: "JFM" },
  { value: "100752", label: "BCF Pension Trust" },
  { value: "100753", label: "Edrington Pension Fund" },
  {
    value: "100754",
    label: "Lloyds Bank pension scheme number 1 - C/O Towers Watson",
  },
  { value: "100755", label: "Teachers Pensions" },
  { value: "100756", label: "GEC Marconi  " },
  { value: "100757", label: "Admenta Pension Scheme" },
  { value: "100758", label: "Total UK Pension Plan" },
  {
    value: "100759",
    label: "Santander (UK) Group Pension Scheme - JLT Benefit Solutions Ltd",
  },
  {
    value: "100760",
    label:
      "BAE 100 Plus DC SCheme - Mixed Befit Scheme-Part final salary and part DC.",
  },
  { value: "100761", label: "Freemantle Media Pension group" },
  { value: "100762", label: "Quantum Advisory" },
  { value: "100763", label: "SPX Pension trust Company limited" },
  { value: "100764", label: "PA Consultancy " },
  { value: "100765", label: "Church of England Pension" },
  { value: "100766", label: "Esab Pensions Limited" },
  { value: "100767", label: "Houghton Vaughan " },
  { value: "100768", label: "Boots Pensions" },
  { value: "100769", label: "Great Western Railway Pensions" },
  { value: "100770", label: "Merpro Ltd" },
  { value: "100771", label: "Metal Box Pension Scheme" },
  { value: "100772", label: "The Yorkshire and Clydesdale Bank Scheme" },
  { value: "100773", label: "Saga Investments " },
  {
    value: "100774",
    label: "HSBC (UK) Pension Scheme-HSBC Administration Team - Towers Watson",
  },
  { value: "100775", label: "Sainsburys Pension Scheme" },
  { value: "100776", label: "TMG Telegraph Media Group" },
  { value: "100777", label: "Horn Buckle " },
  {
    value: "100778",
    label: "Plumbing & Mechanical Services UK Pension Scheme",
  },
  { value: "100779", label: "Braemar Seascope Management Ltd" },
  { value: "100780", label: "BSI Retirement Benefits Plan " },
  { value: "100781", label: "Ingersol Rand Final Salary Scheme" },
  {
    value: "100782",
    label: "Capita - Keir Construction DC Pension Benefits Scheme",
  },
  { value: "100783", label: "Universal Pensions Limited" },
  { value: "100784", label: "Vaillant Group Pension Scheme" },
  { value: "100785", label: "Brunel Holdings Pension Scheme" },
  { value: "100786", label: "Beckford James " },
  { value: "100787", label: "Kappa Packaging UK Pension Schemes Limited" },
  { value: "100788", label: "ATOS Pension Scheme" },
  { value: "100789", label: "Ibstock Pension Scheme" },
  {
    value: "100790",
    label: "British Petroleum (BP) Final Salary Pension Scheme",
  },
  { value: "100791", label: "Brambles Group" },
  { value: "100792", label: "Aegon Retireready Pension " },
  { value: "100793", label: "Source Pensions" },
  { value: "100794", label: "Reed Pensions" },
  { value: "100795", label: "Onesubsea" },
  { value: "100796", label: "Syngenta UK Ltd - Towers Watson" },
  { value: "100797", label: "Stora Enso" },
  { value: "100798", label: "IPM" },
  { value: "100799", label: "Gdynia America Shipping Lines (London) Limited" },
  { value: "100801", label: "Britannia Pension" },
  { value: "100802", label: "Texas Instruments Pension" },
  { value: "100803", label: "BMW Operations Pensions" },
  { value: "100804", label: "British Railways Pension" },
  { value: "100840", label: "Riccardo" },
  { value: "100841", label: "NOW: Pensions" },
  { value: "100842", label: "Tyco Holdings (UK)" },
  { value: "100843", label: "Tyco UK" },
  { value: "100844", label: "The Berkshire Pension Fund" },
  { value: "100847", label: "Beaufort Share Dealing" },
  {
    value: "100848",
    label:
      "JLT Benefits Solutions LTD - Jacobs Pension Scheme - Jacobs Engineering",
  },
  { value: "100849", label: "H S Admin" },
  { value: "100852", label: "Kode International" },
  { value: "100854", label: "Broad Gate Financial Management" },
  { value: "100860", label: "Daily Mail & General Trust" },
  { value: "100875", label: "Travelers Pensions" },
  { value: "100882", label: "Tube Lines Pension Scheme" },
  { value: "100883", label: "McCain Retirement Savings Plan" },
  { value: "100889", label: "True Potential" },
  { value: "100894", label: "BP UK Pensions and Benefits (DC Scheme)" },
  { value: "100899", label: "Hilger Analytical Limited" },
  { value: "100907", label: "Caterpillar Defined Contribution Pension Plan" },
  { value: "100913", label: "Aker Solutions DC Pension Scheme" },
  { value: "100930", label: "Heritage Pensions" },
  { value: "100932", label: "Penguin" },
  { value: "100950", label: "Lothain Pension Fund" },
  { value: "100964", label: "United Biscuits" },
  { value: "100978", label: "Harsco" },
  { value: "100986", label: "Nutmeg Pensions" },
  { value: "100987", label: "Parmenion" },
  { value: "101001", label: "Link Asset Services" },
  { value: "101005", label: "Nortel Pensions UK" },
  { value: "101019", label: "Really Useful Theatres Pension Scheme" },
  { value: "101025", label: "Allen & Overy" },
  { value: "101026", label: "Link Solutions Ltd" },
  { value: "101029", label: "Yorsipp pension" },
  { value: "101032", label: "Lane Clark & Peacock LLP" },
  { value: "101043", label: "BASF" },
  { value: "101044", label: "The Bank of New York Mellon" },
  { value: "101048", label: "Janus Henderson Investors" },
  { value: "101053", label: "DA Phillips" },
  { value: "101054", label: "Rexel" },
  { value: "101066", label: "Ageas" },
  { value: "101067", label: "Council" },
  { value: "101070", label: "Portafina" },
  { value: "101074", label: "Edward Bond Wealth" },
  { value: "101076", label: "Bank of America" },
  { value: "101080", label: "Killik and Co" },
  { value: "101086", label: "XPS Administration" },
  { value: "101087", label: "Generali - Trustees Hornbuckle Mitchell" },
  { value: "101088", label: "DAS Legal Services" },
  { value: "101097", label: "Phoenix Wealth" },
  { value: "101099", label: "Phoenix (Manchester)" },
  { value: "101103", label: "Fil Life Insurance Robert McAlpine" },
  { value: "101112", label: "Bartlett Wealth Management" },
  { value: "101113", label: "Forthplus Pensions" },
  { value: "101120", label: "Case New Holland" },
];

export const groupedOptions: IGroupedOption[] = [
  {
    label: "Pension Providers",
    groupValue: "pension providers",
    options: providers,
  },
];
