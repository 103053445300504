import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Notice } from "./Notice";
import { NextSteps } from "../../../pages/NextSteps";

export const TopFiguresCard = (props: IProps) => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 mb-9">
        {props.headerSections.length < 3 && (
          <div className="col">
            <div
              className={
                "card flex-grow-1 flex-sm-fill flex-basis-0 flex-sm-grow-1 mb-5 h-100"
              }
            >
              <div className="card-body row">
                <div className="d-flex">
                  <div className="fw-bolder fs-2hx col-10 text-darker d-flex align-items-center">
                    We couldn't return all quotes
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="w-100">
                  <a id="booking" className="btn btn-primary w-100 mb-5">
                    Book an appointment
                  </a>
                  <a id="quoteError" className="btn btn-secondary w-100">
                    Find out more
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.headerSections?.map((item, index) => {
          return (
            <div className="col" key={index}>
              <div
                className={
                  "card flex-grow-1 flex-sm-fill flex-basis-0 flex-sm-grow-1 mb-5 h-100"
                }
              >
                <div key={item.title} className="card-body row">
                  <div className="col-12 pb-2 fs-1 fw-bold d-flex justify-content-center">
                    {item.title}
                  </div>
                  <div className="d-flex">
                    <div className="fw-bolder fs-2hx col-10 text-darker d-flex align-items-center">
                      {item.logoSrc && (
                        <div>
                          <img
                            src={item.logoSrc}
                            alt="logo"
                            className="w-75 h-auto p-5"
                          />
                        </div>
                      )}
                      <div className="d-flex flex-column">
                        {" "}
                        {/* Changed from flex-row to flex-column */}
                        <div>{item.value}</div>
                        {item?.value != "Flexible" ? (
                          <div className="fs-4 text-gray-700">Per year</div>
                        ) : (
                          <div className="fs-4 text-gray-700">As required</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="w-100">
                    {item.buttons?.map((button, buttonIndex) => {
                      return (
                        <Link
                          id={button.id}
                          className={
                            button.className
                              ? button.className
                              : "btn btn-secondary w-100"
                          }
                          to={button.to}
                          onClick={(e) => {
                            e.preventDefault();
                            button.onClick && button.onClick();
                          }}
                        >
                          {button.label}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

interface IProps {
  headerSections: IHeaderSection[];
  notice: INotice | null;
  contentSections: IContentSection[];
}

interface IHeaderSection {
  title: string | null;
  value: string | null;
  buttons?: IButton[];
  logoSrc?: string;
}

interface IContentSection {
  title: string | null;
  content: string | null;
  button: IButton | null;
}

interface INotice {
  noticeClasses: string | null;
  icon: ReactNode | null;
  iconClasses: string | null;
  header: string | null;
  content: ReactNode | null;
  button: IButton | null;
}

interface IButton {
  label: string;
  to: string;
  className?: string | null;
  id?: string;
  onClick?: () => void;
}
