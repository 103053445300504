import { CTA1 } from "../../modules/common/cards/CTA1";

export const FixedTermAnnuity = () => {
  return (
    <>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <h3>What is it?</h3>
        <div>
          A fixed-term annuity is a financial product designed to provide a
          consistent income stream for a predetermined period, typically during
          an individual's retirement years. By investing a lump sum, the
          purchaser secures regular payouts for the specified term. This
          arrangement offers a level of financial flexibility, as it allows the
          individual to access their savings for a set time and reassess their
          financial needs at the end of the term.
        </div>
      </div>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <h3>When might this be ideal?</h3>
        <div>
          Let's consider John, a 60-year-old man who recently retired from his
          job as a financial adviser. John plans to sell his business at age 70,
          which will provide him with a significant lump sum, but he needs a
          stable income source to cover his living expenses until then. In this
          scenario, a fixed-term annuity might be the most suitable option for
          John. By choosing a 10-year fixed-term annuity, he can guarantee a
          regular income to maintain his standard of living while preserving his
          savings for future needs. Once the annuity term ends and John sells
          his business, he can reevaluate his financial situation and make
          informed decisions about his retirement income strategy based on his
          updated circumstances.
        </div>
      </div>
      <div className="rounded border border-dashed border-primary p-6 p-lg-8 mb-10">
        <CTA1
          title={<div className="mb-5 ms-20">Need help?</div>}
          backGroundImageSrc="media/illustrations/bw/png/question.png"
          button={[
            {
              to: "#",
              label: "Request A call back",
              className: "btn btn-primary fw-semibold ms-20 px-6 py-3",
              function: undefined,
              id: "booking",
            },
          ]}
        />
      </div>
    </>
  );
};
