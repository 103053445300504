import { ReactNode, useEffect, useRef } from "react";
import { Modal, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { boolean } from "yup";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";

export const CarouselModal = (props: ModalProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const carouselEl = carouselRef.current;
    if (!carouselEl) return;

    const handleSlidEvent = () => {};

    carouselEl.addEventListener("slid.bs.carousel", handleSlidEvent);

    return () => {
      carouselEl.removeEventListener("slid.bs.carousel", handleSlidEvent);
    };
  }, []);

  return (
    <Modal
      id="editDataModal"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px"
      show={props.show}
      onHide={props.handleClose}
      backdrop={true}
    >
      <div className="modal-header">
        {/* begin::Close */}
        <h2></h2>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={props.handleClose}
        >
          <KTSVG
            className="svg-icon-1"
            path="/media/icons/duotune/arrows/arr061.svg"
          />
        </div>
        {/* end::Close */}
      </div>
      <div className="modal-body">
        <div className="flex-row-fluid">
          <div className="d-flex flex-column justify-content-between">
            {props.imageSrc && (
              <img
                className="mx-auto h-150px h-lg-300px theme-light-show"
                src={toAbsoluteUrl(props.imageSrc)}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      <div></div>
      <div className="modal-body bg-custom-1">
        <div
          id="carousel"
          className="carousel carousel-custom slide"
          data-bs-ride={false}
          data-bs-interval={false}
          ref={carouselRef}
        >
          <div className="carousel-inner pt-8">
            {props.items?.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className={
                      index === 0 ? "carousel-item active" : "carousel-item"
                    }
                  >
                    <div className="px-20">
                      <div className="h2 fw-bolder">{item.title}</div>
                      <div className="text-black fs-6">{item.content}</div>
                      <div className="d-flex align-items-right justify-content-between flex-wrap mt-20">
                        {/* if button 1 has no function set but does have a to set*/}
                        {item.button1Override?.to && (
                          <Link
                            to={item.button1Override?.to}
                            className={
                              item.button1Override.className
                                ? item.button1Override.className
                                : "btn btn-primary align-self-center ms-2 mb-5"
                            }
                          >
                            {item.button1Override.label}
                          </Link>
                        )}
                        {/* if button 1 has a function set */}
                        {!item.button1Override?.to &&
                          item.button1Override?.function && (
                            <a
                              href="#"
                              onClick={() => item.button1Override?.function()}
                              className={
                                item.button1Override?.className
                                  ? item.button1Override?.className
                                  : "btn btn-primary align-self-center ms-2 mb-5"
                              }
                            >
                              {item.button1Override?.label}
                            </a>
                          )}

                        {/* add nextprevbuttons if overrides aren't set  */}
                        {item.addNextPrevButtons && !item.button1Override && (
                          <button
                            className="btn btn-secondary btn-active-light-primary ms-2 mb-5"
                            type="button"
                            data-bs-target="#carousel"
                            data-bs-slide="prev"
                          >
                            <i className="bi bi-chevron-left"></i> Back
                          </button>
                        )}

                        {/* if button 2 has no function set but does have a to set*/}
                        {item.button2Override?.to && (
                          <Link
                            to={item.button2Override?.to}
                            className={
                              item.button2Override.className
                                ? item.button2Override.className
                                : "btn btn-primary align-self-center ms-2 mb-5"
                            }
                          >
                            {item.button2Override.label}
                          </Link>
                        )}
                        {/* if button 2 has a function set */}
                        {!item.button2Override?.to &&
                          item.button2Override?.function && (
                            <a
                              href="#"
                              onClick={() => item.button2Override?.function()}
                              className={
                                item.button2Override?.className
                                  ? item.button2Override?.className
                                  : "btn btn-primary align-self-center ms-2 mb-5"
                              }
                            >
                              {item.button2Override?.label}
                            </a>
                          )}

                        {item.addNextPrevButtons && !item.button2Override && (
                          <button
                            className="btn btn-primary btn-lg btn-active-light-primary ms-2 mb-5"
                            type="button"
                            data-bs-target="#carousel"
                            data-bs-slide="next"
                          >
                            Continue <i className="bi bi-chevron-right"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Next and Back buttons */}

          <div className="d-flex align-items-center justify-content-center flex-wrap mt-10">
            <ol className="p-0 m-0 carousel-indicators carousel-indicators-bullet carousel-indicators-active-dark">
              {props.items?.length > 1 &&
                props.items?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      data-bs-target="#carousel"
                      data-bs-slide-to={index}
                      className={index === 0 ? "ms-1 active" : "ms-1"}
                    ></li>
                  );
                })}
            </ol>
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  handleClose: () => void;
  show: boolean;
  modalHeader: string;
  items: CarouselItem[];
  imageSrc: string | undefined;
}

interface CarouselItem {
  title: string | undefined;
  content: ReactNode | undefined;
  addNextPrevButtons: boolean;
  button1Override: IButton | undefined;
  button2Override: IButton | undefined;
}

interface IButton {
  label: string;
  to: string;
  function: () => void | null;
  className: string | null;
  nextOrprev: string | null;
}
