import React, { useEffect, useState } from "react";
import { usePortal } from "../../context/PortalContext";
import { IMedicalCondition } from "../../models/MedicalConditionsData";
import { IMedication } from "../../models/MedicationData";
import { DataCard1 } from "../../modules/common/cards/DataCard1";
import { MultiDataCard1 } from "../../modules/common/cards/MultiDataCard1";
import { EditDataModal } from "../../modules/common/modals/EditDataModal";
import { YourProfile } from "../../modules/forms/form_schemas/AnnuityDashboard/YourProfile";
import Table from "../../modules/common/other/Table";
import {
  employmentStatusLookup,
  employmentTypeLookup,
  pensionTypeLookup,
} from "../../modules/forms/Lookups";
import { groupedOptions } from "../../models/PensionProviders";

import {
  schema as personalDetailsSchema,
  header as personalDetailsHeader,
  description as personalDetailsDescription,
} from "../../modules/forms/form_schemas/AnnuityFactfind/PersonalDetails";

import {
  schema as pensionSchema,
  header as pensionHeader,
  description as pensionDescription,
} from "../../modules/forms/form_schemas/AnnuityFactfind/PensionDetails";

import {
  schema as medicalSchema,
  header as medicalHeader,
  description as medicalDesctiption,
} from "../../modules/forms/form_schemas/AnnuityFactfind/MedicalDetails";

import {
  schema as employmentSchema,
  header as employmentHeader,
  description as employmentDesctiption,
} from "../../modules/forms/form_schemas/AnnuityFactfind/EmploymentDetails";

import {
  schema as propertySchema,
  header as propertyHeader,
  description as propertyDesctiption,
} from "../../modules/forms/form_schemas/AnnuityFactfind/PropertyDetails";

import { CTA1 } from "../../modules/common/cards/CTA1";
import { Loader } from "../../modules/common/other/Loader";
import { Accordion } from "../../modules/common/other/Accordion";
import { IPension } from "../../models/ClientData";
import appInsights from "../../azureAppInsights";
import { boolean } from "yup";

interface IProperty {
  address?: string;
  city?: string;
  postcode?: string;
  estimatedValue?: string;
  propertyID?: string;
}

interface IEmployment {
  employmentType?: string;
  employmentStatus?: string;
  dateStarted?: string;
  dateEnded?: string;
}

interface IRow {
  data: string[];
}

export const MyDetails = () => {
  const {
    returnClientData,
    setClientData,
    clientData,
    apiUpdateClientData,
  }: any = usePortal();

  const [showYourProfileModal, setShowYourProfileModal] = useState(false);
  const [showYourAccountModal, setShowYourAccountModal] = useState(false);
  const [showMedicalAndLifestyleModal, setShowMedicalAndLifestyleModal] =
    useState(false);
  const [showEmploymentModal, setShowEmploymentModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [showPensionModal, setShowPensionModal] = useState(false);

  async function updateClient(data: any) {
    try {
      console.log("updating please wait.");
      const responseData: any = await apiUpdateClientData(data);
      setClientData(responseData);
    } catch (error) {
      console.error(error);

      let appInsightsError: Error = {
        name: "MyDetails - updateClient Error",
        message: String(error),
      };
      appInsights.trackException({ exception: appInsightsError });
    }
  }

  const [personalDetailsExpanded, setPersonalDetailsExpanded] = useState(true);
  const [medicalDetailsExpanded, setMedicalDetailsExpanded] = useState(false);
  const [propertyDetailsExpanded, setPropertyDetailsExpanded] = useState(false);
  const [pensionDetailsExpanded, setPensionDetailsExpanded] = useState(false);
  const [employmentDetailsExpanded, setEmploymentDetailsExpanded] =
    useState(false);

  let medicalConditionList: Array<IMedicalCondition> =
    clientData?.medicalConditionList;

  let medicationList: Array<IMedication> = clientData?.medicationList;

  const [formData, setFormData] = useState(undefined);
  const [recordLocked, setRecordLocked] = useState(false);

  useEffect(() => {
    console.log(clientData);
    medicalConditionList = clientData?.medicalConditionList;
    medicationList = clientData?.medicationList;
    if (!clientData) {
      returnClientData().then((data: any) => setClientData(data));
    } else {
      setFormData({
        ...clientData,
        medicalInformation: {
          ...(clientData.medicalInformation ?? {}),
          medicalConditionsYesNo:
            clientData.medicalInformation?.medicalConditionList?.length > 0
              ? "true"
              : "false",
          medicationsYesNo:
            clientData.medicalInformation?.medicationList?.length > 0
              ? "true"
              : "false",
        },
      });
      clientData?.annuity?.status != "Portal" && setRecordLocked(true);
    }
  }, []);

  useEffect(() => {
    medicalConditionList = clientData?.medicalConditionList;
    medicationList = clientData?.medicationList;
    if (clientData) {
      setFormData({
        ...clientData,
        medicalInformation: {
          ...(clientData.medicalInformation ?? {}),
          medicalConditionsYesNo:
            clientData.medicalInformation?.medicalConditionList?.length > 0
              ? "true"
              : "false",
          medicationsYesNo:
            clientData.medicalInformation?.medicationList?.length > 0
              ? "true"
              : "false",
        },
      });
    }
    clientData?.annuity?.status != "Portal" && setRecordLocked(true);
    console.log(clientData);
  }, [clientData]);

  function closeAllAccordians() {
    setPensionDetailsExpanded(false);
    setEmploymentDetailsExpanded(false);
    setPropertyDetailsExpanded(false);
    setMedicalDetailsExpanded(false);
    setPersonalDetailsExpanded(false);
  }

  const togglePersonalDetails = () => {
    closeAllAccordians();
    setPersonalDetailsExpanded(!personalDetailsExpanded);
  };

  const toggleMedicalDetails = () => {
    closeAllAccordians();
    setMedicalDetailsExpanded(!medicalDetailsExpanded);
  };

  const togglePropertyDetails = () => {
    closeAllAccordians();
    setPropertyDetailsExpanded(!propertyDetailsExpanded);
  };

  const toggleEmploymentDetails = () => {
    closeAllAccordians();
    setEmploymentDetailsExpanded(!employmentDetailsExpanded);
  };

  const togglePensionDetails = () => {
    closeAllAccordians();
    setPensionDetailsExpanded(!pensionDetailsExpanded);
  };

  return (
    <>
      {formData ? (
        <>
          <CTA1
            title={<div className="mb-5">Ready to complete a factfind?</div>}
            imageSrc="media/illustrations/bw/png/feedback.png"
            button={[
              {
                to: "/factfind",
                label: "GET STARTED",
                className: "btn btn-primary fw-semibold px-6 py-3",
                function: undefined,
              },
            ]}
          />
          <EditDataModal
            data={formData}
            schema={personalDetailsSchema}
            handleClose={() => setShowYourProfileModal(false)}
            show={showYourProfileModal}
            handleSubmit={updateClient}
            modalHeader="Your details"
            fullscreen={true}
            recordLocked={recordLocked}
          />
          <EditDataModal
            data={formData}
            schema={pensionSchema}
            handleClose={() => setShowPensionModal(false)}
            show={showPensionModal}
            handleSubmit={updateClient}
            modalHeader="Pension details"
            fullscreen={true}
            recordLocked={recordLocked}
          />
          <EditDataModal
            data={formData}
            schema={medicalSchema}
            handleClose={() => setShowMedicalAndLifestyleModal(false)}
            show={showMedicalAndLifestyleModal}
            handleSubmit={updateClient}
            modalHeader="Medical details"
            fullscreen={true}
            recordLocked={recordLocked}
          />
          <EditDataModal
            data={formData}
            schema={employmentSchema}
            handleClose={() => setShowEmploymentModal(false)}
            show={showEmploymentModal}
            handleSubmit={updateClient}
            modalHeader="Employment details"
            fullscreen={true}
            recordLocked={recordLocked}
          />

          <EditDataModal
            data={formData}
            schema={propertySchema}
            handleClose={() => setShowPropertyModal(false)}
            show={showPropertyModal}
            handleSubmit={updateClient}
            modalHeader="Property details"
            fullscreen={true}
            recordLocked={recordLocked}
          />

          <Accordion
            title={"Personal Details"}
            expanded={personalDetailsExpanded}
            content={
              <DataCard1
                title={"Your Profile"}
                description={
                  "Making sure your personal details are up to date prior to any IFA appointments will help speed up the appointment."
                }
                headerButtons={[
                  {
                    label: "Edit",
                    to: "",
                    className: null,
                    function: () => setShowYourProfileModal(true),
                  },
                ]}
                cardData={[
                  {
                    name: "Name",
                    value:
                      (clientData?.firstName && clientData?.firstName) +
                      " " +
                      (clientData?.lastName && clientData?.lastName),
                  },
                  {
                    name: "Pension Fund",
                    value: `£${parseFloat(
                      clientData?.annuity?.pensionFund
                    ).toFixed(2)}`,
                  },
                  {
                    name: "Date of Birth",
                    value: clientData?.dateOfBirth,
                  },
                ]}
                customContent={undefined}
              />
            }
            onToggle={togglePersonalDetails}
          />

          <Accordion
            title={"Pension Details"}
            expanded={pensionDetailsExpanded}
            content={
              <DataCard1
                title={"Pension Details"}
                description={""}
                headerButtons={[
                  {
                    label: "Edit",
                    to: "",
                    className: null,
                    function: () => setShowPensionModal(true),
                  },
                ]}
                cardData={[
                  {
                    name: "Number of pensions added",
                    value: clientData?.pensions?.length,
                  },
                ]}
                customContent={
                  clientData?.pensions?.length > 0 ? (
                    <Table
                      title="Your Pensions"
                      columns={[
                        { name: "Type", dataType: "text" },
                        { name: "Provider", dataType: "text" },
                        { name: "Valuation", dataType: "currency" },
                        { name: "Valuation Date", dataType: "date" },
                      ]}
                      rows={clientData?.pensions?.map((pension: IPension) => {
                        let pensionType = pensionTypeLookup.find(
                          (item) => item.value === pension.pensionType
                        );

                        const getProviderLabel = (
                          value: string
                        ): string | undefined => {
                          for (const group of groupedOptions) {
                            for (const option of group.options) {
                              if (option.value === value) {
                                return option.label;
                              }
                            }
                          }

                          return undefined; // Provider with the specified value not found
                        };

                        const providerLabel = getProviderLabel(
                          pension.cedingScheme
                        );

                        console.log(pension.valuationDate);

                        let pensionData: IRow = {
                          data: [
                            pensionType ? pensionType.label : "",
                            providerLabel ? providerLabel : "",
                            pension.valuation
                              ? pension.valuation.toString()
                              : "",
                            pension.valuationDate ? pension.valuationDate : "",
                          ],
                        };

                        return pensionData;
                      })}
                    />
                  ) : null
                }
              />
            }
            onToggle={togglePensionDetails}
          />

          <Accordion
            title={"Medical Details"}
            expanded={medicalDetailsExpanded}
            content={
              <DataCard1
                title={"Medical & Lifestyle"}
                description={
                  "Completing the below can potentially increase the amount you could receive and entitle you to enhanced annuities."
                }
                headerButtons={[
                  {
                    label: "Edit",
                    to: "",
                    className: null,
                    function: () => setShowMedicalAndLifestyleModal(true),
                  },
                ]}
                cardData={[
                  {
                    name: "Height",
                    value:
                      (clientData?.medicalInformation?.heightFeet &&
                        clientData?.medicalInformation?.heightFeet) +
                      " ' " +
                      (clientData?.medicalInformation?.heightInches &&
                        clientData?.medicalInformation?.heightInches),
                  },
                  {
                    name: "Weight",
                    value:
                      clientData?.medicalInformation?.weightStone &&
                      clientData?.medicalInformation?.weightStone +
                        "st " +
                        clientData?.medicalInformation?.weightPounds &&
                      clientData?.medicalInformation?.weightPounds + "lb",
                  },
                  ...clientData?.medicalInformation?.medicalConditionList.map(
                    (item: any, index: number) => ({
                      name: "Medical condition " + (index + 1),
                      value: item.conditionName,
                    })
                  ),
                  {
                    name: "Number of medications taken",
                    value:
                      clientData?.medicalInformation?.medicationList.length,
                  },
                ]}
                customContent={undefined}
              />
            }
            onToggle={toggleMedicalDetails}
          />

          <Accordion
            title={"Property Details"}
            expanded={propertyDetailsExpanded}
            content={
              <DataCard1
                title={"Add details about any properties you own"}
                description={""}
                headerButtons={[
                  {
                    label: "Edit",
                    to: "",
                    className: null,
                    function: () => setShowPropertyModal(true),
                  },
                ]}
                cardData={[
                  {
                    name: "Number of properties added",
                    value: clientData?.properties?.length,
                  },
                ]}
                customContent={
                  <Table
                    title="Your Properties"
                    columns={[
                      { name: "Name", dataType: "text" },
                      { name: "Address", dataType: "text" },
                      { name: "PostCode", dataType: "text" },
                      { name: "Est. Value", dataType: "currency" },
                    ]}
                    rows={clientData?.properties.map(
                      (property: IProperty, index: number) => ({
                        data: [
                          "Property " + (index + 1),
                          property.address,
                          property.postcode,
                          property.estimatedValue,
                        ],
                      })
                    )}
                  />
                }
              />
            }
            onToggle={togglePropertyDetails}
          />

          <Accordion
            title={"Employment Details"}
            expanded={employmentDetailsExpanded}
            content={
              <DataCard1
                title={"Add details of current and past employments"}
                description={""}
                headerButtons={[
                  {
                    label: "Edit",
                    to: "",
                    className: null,
                    function: () => setShowEmploymentModal(true),
                  },
                ]}
                cardData={[
                  {
                    name: "Number of employments added",
                    value: clientData?.employments?.length,
                  },
                ]}
                customContent={
                  <Table
                    title="Your Employment History"
                    columns={[
                      { name: "Type", dataType: "text" },
                      { name: "Status", dataType: "text" },
                      { name: "Start Date", dataType: "date" },
                      { name: "End Date", dataType: "date" },
                    ]}
                    rows={clientData?.employments?.map(
                      (employment: IEmployment) => {
                        let employmentType = employmentTypeLookup.find(
                          (item) => item.value === employment.employmentType
                        );
                        let employmentStatus = employmentStatusLookup.find(
                          (item) => item.value === employment.employmentStatus
                        );

                        let employmentData: IRow = {
                          data: [
                            employmentType ? employmentType.label : "",
                            employmentStatus ? employmentStatus.label : "",
                            employment.dateStarted
                              ? employment.dateStarted
                              : "",
                            employment.dateEnded ? employment.dateEnded : "",
                          ],
                        };

                        return employmentData;
                      }
                    )}
                  />
                }
              />
            }
            onToggle={toggleEmploymentDetails}
          />
        </>
      ) : (
        <Loader text="Loading your data" />
      )}
    </>
  );
};
