import React from "react";
import { useFormikContext } from "formik";

interface IProps {
  dependsOnFields: Array<IDependsOnField>;
}

interface IDependsOnField {
  fieldName: string;
  fieldValue: string;
}

const getNestedValue = (obj: any, path: string) => {
  const pathParts = path.split(".");
  return pathParts.reduce((accumulator, current) => {
    return accumulator && accumulator[current];
  }, obj);
};

const WithVisibility = <T extends {}>(
  WrappedComponent: React.ComponentType<T>
) => {
  const ComponentWithVisibility: React.FC<T & IProps> = (props) => {
    let hide = false;
    const { values } = useFormikContext<any>();
    if (props.dependsOnFields?.length > 0) {
      props.dependsOnFields.map((field) => {
        const fieldValue = getNestedValue(values, field.fieldName);
        //if fieldValue is a string then just check to see if it doesn't match and hide if not
        if (!Array.isArray(field.fieldValue)) {
          if (fieldValue !== field.fieldValue) {
            hide = true;
          }
        }
        //if fieldValue is an array of strings, then check to see if any of them match and hide if none match
        else {
          let matches = 0;
          field.fieldValue.map((value: any) => {
            if (value == fieldValue) {
              matches++;
            }
          });
          if (matches == 0) {
            hide = true;
          }
        }
      });
    }

    if (hide) {
      return null;
    } else {
      return <WrappedComponent {...props} />;
    }
  };

  return ComponentWithVisibility;
};

export default WithVisibility;
