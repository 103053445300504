import { useFormikContext } from "formik";
import { useEffect } from "react";

export function ScrollToError() {
  //used timeout to wait for elem%ents to be rerendered
  setTimeout(RunScroll, 200);
}

function RunScroll() {
  const allElements = document.getElementsByClassName("field-error");
  for (let i = 0; i < allElements.length; i++) {
    const element = allElements[i];

    if (element.textContent != "") {
      const parentElement = element.parentElement;

      if (parentElement) {
        // const rect = parentElement.getBoundingClientRect();
        // const y = rect.top + window.pageYOffset - 100; // 100 pixels above the parent element

        // window.scrollTo({ top: y, behavior: "smooth" });

        parentElement.scrollIntoView({ behavior: "smooth", block: "center" });
        break;
      }
    }
  }
}
