import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { usePortal } from "../context/PortalContext";
import { useEffect, useState, ReactNode } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}

interface IProps {
  text?: string;
  title?: string;
  buttonTo?: string;
  buttonID?: string;
  buttonText?: string;
}

export const NextSteps = (props: IProps) => {
  const [user, error] = useAuthState(auth);
  const { returnClientData, setClientData, clientData }: any = usePortal();

  const [text, setText] = useState(props?.text ? props?.text : "");
  const [title, setTitle] = useState(props?.title ? props?.title : "");
  const [buttonText, setButtonText] = useState(
    props?.buttonText ? props?.buttonText : ""
  );
  const [buttonTo, setButtonTo] = useState(
    props?.buttonTo ? props?.buttonTo : ""
  );
  const [buttonID, setButtonID] = useState(
    props?.buttonID ? props?.buttonID : ""
  );
  const [tooltip, setTooltip] = useState<ITooltip>({
    button: null,
    notice: null,
  });

  useEffect(() => {
    // if (props?.text) {
    //   if (props?.text) {
    //     setText(props.text);
    //   }
    //   if (props?.title) {
    //     setTitle(props.title);
    //   }
    //   if (props?.buttonTo) {
    //     setButtonTo(props.buttonTo);
    //   }
    //   if (props?.buttonID) {
    //     setButtonID(props.buttonID);
    //   }
    //   if (props?.buttonText) {
    //     setButtonText(props.buttonText);
    //   }
    // } else {
    if (clientData) {
      if (
        clientData.annuity?.fullQuoteCompleted === "false" ||
        clientData.annuity?.fullQuoteCompleted === null ||
        clientData.annuity?.fullQuoteCompleted === ""
      ) {
        setButtonTo("/fullquote");
        setButtonText("Continue to next step");
        setTitle("First, let's get some tailored quotes");
        setText(
          "Welcome to your portal and the start to your retirement! To get started, simply click the 'Continue to next step' button and provide the necessary information. Once we have a better understanding of your situation, we'll be able to offer you personalised and valuable advice, tailored to your needs."
        );
        setTooltip({
          button: (
            <a
              className="ms-10 btn btn-small btn-outline form-text"
              href="https://www.pense.co.uk/importance-of-financial-advice"
              target="_blank"
              rel="noopener"
            >
              Why do I need to speak with a Financial Adviser?
            </a>
          ),
          notice: null,
        });
      } else if (
        clientData.annuity?.fullQuoteCompleted === "true" &&
        (clientData.annuity?.factfindCompleted === "false" ||
          !clientData.annuity?.factfindCompleted)
      ) {
        setButtonTo("/factfind");
        setButtonText("Continue to next step");
        setTitle("Now I have quotes, what's next?");
        setText(
          "Before you can obtain an appointment with one of our independent financial advisers, we kindly request a few more details about your circumstances. Don't worry, it's a quick and straightforward process."
        );
      } else if (
        clientData.annuity?.fullQuoteCompleted === "true" &&
        clientData.annuity?.factfindCompleted === "true" &&
        (clientData.annuity?.bookIFAAppointment === "false" ||
          clientData.annuity?.bookIFAAppointment == null ||
          clientData.annuity?.bookIFAAppointment === "")
      ) {
        setButtonTo("/bookifacall");
        setButtonText("Continue to next step");
        setTitle("You're now ready to speak with an IFA!");
        setText(
          "Thank you for completing your fact-find. You're now all set to schedule a phone call or a video call with one of our Independent Financial Advisers, who will provide you with comprehensive advice and recommendations tailored to your needs. There is no cost for the appointment and we can provide an appointment time to suit you. Simply click the button below to get started."
        );
      } else if (
        clientData.annuity?.fullQuoteCompleted === "true" &&
        clientData.annuity?.bookIFAAppointment === "true"
      ) {
        setButtonTo("");
        setTitle("You're all done!");
        setText(
          "There's nothing left for you to do right now. If you need any information relating to your IFA call or anything else, don't hesitate to reach out to us."
        );
      }
    } else {
      returnClientData(clientData);
    }
    // }
  }, [clientData]);

  return (
    <>
      {user ? (
        <>
          {clientData ? (
            <div className="card">
              <div className="card-body ps-lg-12 mx-10 my-10">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h1 className="text-dark fs-2qx fw-bold mb-3 ms-5">
                      {title}
                    </h1>
                    <div
                      className="p-5 rounded bg-light-info text-dark fw-semibold mb-5 mt-5 d-flex align-items-center justify-content-center"
                      data-kt-element="message-text"
                    >
                      <Typewriter
                        options={{
                          strings: [`${text}`],
                          autoStart: true,
                          loop: false,
                          delay: 30,
                          deleteSpeed: 100000000000,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5 mt-5">
                    {!props?.buttonTo &&
                    props?.buttonTo !== "" &&
                    !buttonTo &&
                    buttonTo !== "" ? (
                      <div className="ms-0 card border rounded border-dark p-10 mb-5">
                        <h1 className="fs-1">Want to get in touch?</h1>
                        <p>Feel free to give us a call:</p>
                        <h1>0808 281 2558</h1>
                        <p>
                          Mon to Fri - 9am - 5pm <br />
                          Sat - 9am - 1pm
                        </p>
                      </div>
                    ) : (
                      <Link
                        to={props?.buttonTo ? props.buttonTo : buttonTo}
                        className="btn btn-lg btn-success fs-1"
                        id={props?.buttonID ? props.buttonID : buttonID}
                      >
                        {buttonText ? buttonText : "Continue to next step"}
                      </Link>
                    )}
                  </div>
                </div>
                {tooltip?.button && tooltip.button}
                {tooltip?.notice && tooltip.notice}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className="card">
            <div className="card-body ps-lg-12 mx-10 my-10">
              <div className="row">
                <div className="col-12 col-md-6">
                  <h1 className="text-dark fs-2qx fw-bold mb-3 ms-5">
                    {props?.title ? props.title : "What's next?"}
                  </h1>
                  <div
                    className="p-5 rounded bg-light-info text-dark fw-semibold mb-10 d-flex align-items-center justify-content-start"
                    data-kt-element="message-text"
                  >
                    <Typewriter
                      options={{
                        strings: [
                          props?.text
                            ? props.text
                            : 'Register for Free and Boost Your Quotes by Up to 40%! If you have questions and would rather speak to somebody, please call 0808 281 2558 or click <a href="https://outlook.office365.com/owa/calendar/FreePensionConsultation@pense.co.uk/bookings/" target="_blank" rel="noopener noreferrer"><b>here</b></a> to book an appointment at a time to suit you with a retirement expert and we will call you.',
                        ],
                        autoStart: true,
                        loop: false,
                        delay: 30,
                        deleteSpeed: 100000000000,
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                  <Link
                    to={props?.buttonTo ? props.buttonTo : "/auth/registration"}
                    className="btn btn-lg btn-success fs-1"
                    id={props?.buttonID && props.buttonID}
                  >
                    {props.buttonText
                      ? props.buttonText
                      : "Register & get started"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
