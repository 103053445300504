import { IGroupedOption, IOption } from "./LookupWithGroupsData";

interface IMedicalCondition {
  conditionType: string;
  conditionName: string;
}

export type { IMedicalCondition };

const allConditions = {
  CancerConditions: [
    {
      value: "Abdomen Cancer",
      label: "Abdomen Cancer",
    },
    {
      value: "Abdominal Cancer",
      label: "Abdominal Cancer",
    },
    {
      value: "Accessory Sinuses Malignant Neoplasm",
      label: "Accessory Sinuses Malignant Neoplasm",
    },
    {
      value: "Acoustic Neuroma",
      label: "Acoustic Neuroma",
    },
    {
      value: "Acusticus Neurinoma",
      label: "Acusticus Neurinoma",
    },
    {
      value: "Acute Lymphoblastic Leukaemia",
      label: "Acute Lymphoblastic Leukaemia",
    },
    {
      value: "Acute Lymphocytic Leukaemia",
      label: "Acute Lymphocytic Leukaemia",
    },
    {
      value: "Acute Lymphoid Leukaemia",
      label: "Acute Lymphoid Leukaemia",
    },
    {
      value: "Acute Megakaryoblastic Leukaemia",
      label: "Acute Megakaryoblastic Leukaemia",
    },
    {
      value: "Acute Monocytic Leukaemia",
      label: "Acute Monocytic Leukaemia",
    },
    {
      value: "Acute Myelocytic Leukaemia",
      label: "Acute Myelocytic Leukaemia",
    },
    {
      value: "Acute Myelofibrosis",
      label: "Acute Myelofibrosis",
    },
    {
      value: "Acute Myeloid Leukaemia",
      label: "Acute Myeloid Leukaemia",
    },
    {
      value: "Acute Myelomonocytic Leukaemia",
      label: "Acute Myelomonocytic Leukaemia",
    },
    {
      value: "Acute Panmyelosis",
      label: "Acute Panmyelosis",
    },
    {
      value: "Acute Promyelocytic Leukaemia",
      label: "Acute Promyelocytic Leukaemia",
    },
    {
      value: "Adeno CA Prostate",
      label: "Adeno CA Prostate",
    },
    {
      value: "Adeno Carcinoma Prostate",
      label: "Adeno Carcinoma Prostate",
    },
    {
      value: "Adenocarcinoma Prostate",
      label: "Adenocarcinoma Prostate",
    },
    {
      value: "Adenoid Cancer",
      label: "Adenoid Cancer",
    },
    {
      value: "Adenomatous Polyposis",
      label: "Adenomatous Polyposis",
    },
    {
      value: "Adenomatous Polyposis Coli",
      label: "Adenomatous Polyposis Coli",
    },
    {
      value: "Adrenal Cancer",
      label: "Adrenal Cancer",
    },
    {
      value: "Adrenal Gland Cancer",
      label: "Adrenal Gland Cancer",
    },
    {
      value: "Adrenal Gland Malignancy",
      label: "Adrenal Gland Malignancy",
    },
    {
      value: "Adrenal Gland Neoplasm",
      label: "Adrenal Gland Neoplasm",
    },
    {
      value: "Adrenal Gland Tumour",
      label: "Adrenal Gland Tumour",
    },
    {
      value: "Adult T-Cell Leukaemia",
      label: "Adult T-Cell Leukaemia",
    },
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "AML",
      label: "AML",
    },
    {
      value: "Anal Cancer",
      label: "Anal Cancer",
    },
    {
      value: "Anal Tumour",
      label: "Anal Tumour",
    },
    {
      value: "Anus And Anal Canal Malignant Neoplasm",
      label: "Anus And Anal Canal Malignant Neoplasm",
    },
    {
      value: "Anus Neoplasm",
      label: "Anus Neoplasm",
    },
    {
      value: "Arm Cancer",
      label: "Arm Cancer",
    },
    {
      value: "Arm Carcinoma",
      label: "Arm Carcinoma",
    },
    {
      value: "Arm Neoplasm Malignant",
      label: "Arm Neoplasm Malignant",
    },
    {
      value: "Astrocytoma",
      label: "Astrocytoma",
    },
    {
      value: "Autonomic Nervous System Neoplasm",
      label: "Autonomic Nervous System Neoplasm",
    },
    {
      value: "Axilla Cancer",
      label: "Axilla Cancer",
    },
    {
      value: "Axilla Neoplasm Malignant",
      label: "Axilla Neoplasm Malignant",
    },
    {
      value: "B-Cell Lymphoma",
      label: "B-Cell Lymphoma",
    },
    {
      value: "Back Cancer",
      label: "Back Cancer",
    },
    {
      value: "Back Neoplasm Malignant",
      label: "Back Neoplasm Malignant",
    },
    {
      value: "Basal Cell Carcinoma",
      label: "Basal Cell Carcinoma",
    },
    {
      value: "BCC",
      label: "BCC",
    },
    {
      value: "Benign Bladder Tumour",
      label: "Benign Bladder Tumour",
    },
    {
      value: "Benign Brain Tumour",
      label: "Benign Brain Tumour",
    },
    {
      value: "Benign Breast Lump",
      label: "Benign Breast Lump",
    },
    {
      value: "Benign Breast Tumour",
      label: "Benign Breast Tumour",
    },
    {
      value: "Benign Colon Tumour",
      label: "Benign Colon Tumour",
    },
    {
      value: "Benign Ear Neoplasm",
      label: "Benign Ear Neoplasm",
    },
    {
      value: "Benign Ear Tumour",
      label: "Benign Ear Tumour",
    },
    {
      value: "Benign Liver Tumour",
      label: "Benign Liver Tumour",
    },
    {
      value: "Benign Lung Tumour",
      label: "Benign Lung Tumour",
    },
    {
      value: "Benign Neoplasm",
      label: "Benign Neoplasm",
    },
    {
      value: "Benign Neoplasm Bladder",
      label: "Benign Neoplasm Bladder",
    },
    {
      value: "Benign Neoplasm Bone",
      label: "Benign Neoplasm Bone",
    },
    {
      value: "Benign Neoplasm Colon",
      label: "Benign Neoplasm Colon",
    },
    {
      value: "Benign Neoplasm Larynx",
      label: "Benign Neoplasm Larynx",
    },
    {
      value: "Benign Neoplasm Lung",
      label: "Benign Neoplasm Lung",
    },
    {
      value: "Benign Neoplasm Nose",
      label: "Benign Neoplasm Nose",
    },
    {
      value: "Benign Neoplasm Of Bladder",
      label: "Benign Neoplasm Of Bladder",
    },
    {
      value: "Benign Neoplasm Of Brain",
      label: "Benign Neoplasm Of Brain",
    },
    {
      value:
        "Benign Neoplasm Of Brain And Other Parts Of Central Nervous System",
      label:
        "Benign Neoplasm Of Brain And Other Parts Of Central Nervous System",
    },
    {
      value: "Benign Neoplasm Of Breast",
      label: "Benign Neoplasm Of Breast",
    },
    {
      value: "Benign Neoplasm Of Descending Colon",
      label: "Benign Neoplasm Of Descending Colon",
    },
    {
      value: "Benign Neoplasm Of Ear",
      label: "Benign Neoplasm Of Ear",
    },
    {
      value: "Benign Neoplasm Of Endocrine Gland",
      label: "Benign Neoplasm Of Endocrine Gland",
    },
    {
      value: "Benign Neoplasm Of Joints",
      label: "Benign Neoplasm Of Joints",
    },
    {
      value: "Benign Neoplasm Of Lung",
      label: "Benign Neoplasm Of Lung",
    },
    {
      value: "Benign Neoplasm Of Lymph Nodes",
      label: "Benign Neoplasm Of Lymph Nodes",
    },
    {
      value: "Benign Neoplasm Of Mammary Gland",
      label: "Benign Neoplasm Of Mammary Gland",
    },
    {
      value: "Benign Neoplasm Of Middle Ear",
      label: "Benign Neoplasm Of Middle Ear",
    },
    {
      value: "Benign Neoplasm Of Nasal Cavity",
      label: "Benign Neoplasm Of Nasal Cavity",
    },
    {
      value: "Benign Neoplasm Of Neck",
      label: "Benign Neoplasm Of Neck",
    },
    {
      value: "Benign Neoplasm Of Nose",
      label: "Benign Neoplasm Of Nose",
    },
    {
      value: "Benign Neoplasm Of Pancreas",
      label: "Benign Neoplasm Of Pancreas",
    },
    {
      value: "Benign Neoplasm Of Parotid Gland",
      label: "Benign Neoplasm Of Parotid Gland",
    },
    {
      value: "Benign Neoplasm Of Peripheral Nerves",
      label: "Benign Neoplasm Of Peripheral Nerves",
    },
    {
      value: "Benign Neoplasm Of Pituitary Gland",
      label: "Benign Neoplasm Of Pituitary Gland",
    },
    {
      value: "Benign Neoplasm Of Pleura",
      label: "Benign Neoplasm Of Pleura",
    },
    {
      value: "Benign Neoplasm Of Prostate",
      label: "Benign Neoplasm Of Prostate",
    },
    {
      value: "Benign Neoplasm Of Rectum",
      label: "Benign Neoplasm Of Rectum",
    },
    {
      value: "Benign Neoplasm Of Shoulder",
      label: "Benign Neoplasm Of Shoulder",
    },
    {
      value: "Benign Neoplasm Of Sigmoid Colon",
      label: "Benign Neoplasm Of Sigmoid Colon",
    },
    {
      value: "Benign Neoplasm Of Skin",
      label: "Benign Neoplasm Of Skin",
    },
    {
      value: "Benign Neoplasm Of Skin Of Arm",
      label: "Benign Neoplasm Of Skin Of Arm",
    },
    {
      value: "Benign Neoplasm Of Skin Of Upper Limb",
      label: "Benign Neoplasm Of Skin Of Upper Limb",
    },
    {
      value: "Benign Neoplasm Of Stomach",
      label: "Benign Neoplasm Of Stomach",
    },
    {
      value: "Benign Neoplasm Of The Bladder",
      label: "Benign Neoplasm Of The Bladder",
    },
    {
      value: "Benign Neoplasm Of The Pituitary Gland",
      label: "Benign Neoplasm Of The Pituitary Gland",
    },
    {
      value: "Benign Neoplasm Of The Stomach",
      label: "Benign Neoplasm Of The Stomach",
    },
    {
      value: "Benign Neoplasm Of Thyroid Gland",
      label: "Benign Neoplasm Of Thyroid Gland",
    },
    {
      value: "Benign Neoplasm Of Uterus",
      label: "Benign Neoplasm Of Uterus",
    },
    {
      value: "Benign Neoplasm On Back",
      label: "Benign Neoplasm On Back",
    },
    {
      value: "Benign Neoplasm Parathyroid",
      label: "Benign Neoplasm Parathyroid",
    },
    {
      value: "Benign Neoplasm Shoulder",
      label: "Benign Neoplasm Shoulder",
    },
    {
      value: "Benign Neoplasm Skin",
      label: "Benign Neoplasm Skin",
    },
    {
      value: "Benign Neoplasm Stomach",
      label: "Benign Neoplasm Stomach",
    },
    {
      value: "Benign Pituitary Gland Tumour",
      label: "Benign Pituitary Gland Tumour",
    },
    {
      value: "Benign Prostate Tumour",
      label: "Benign Prostate Tumour",
    },
    {
      value: "Benign Scrotum Tumour",
      label: "Benign Scrotum Tumour",
    },
    {
      value: "Benign Stomach Tumour",
      label: "Benign Stomach Tumour",
    },
    {
      value: "Benign Tumor Of Bladder",
      label: "Benign Tumor Of Bladder",
    },
    {
      value: "Benign Tumor Of Middle Ear",
      label: "Benign Tumor Of Middle Ear",
    },
    {
      value: "Benign Tumor Of Parotid Gland",
      label: "Benign Tumor Of Parotid Gland",
    },
    {
      value: "Benign Tumor Of Pituitary Gland",
      label: "Benign Tumor Of Pituitary Gland",
    },
    {
      value: "Benign Tumor Of Prostate",
      label: "Benign Tumor Of Prostate",
    },
    {
      value: "Benign Tumor Of The Ovary",
      label: "Benign Tumor Of The Ovary",
    },
    {
      value: "Benign Tumour",
      label: "Benign Tumour",
    },
    {
      value: "Benign Tumour Eye",
      label: "Benign Tumour Eye",
    },
    {
      value: "Benign Tumour In Ear",
      label: "Benign Tumour In Ear",
    },
    {
      value: "Benign Tumour Liver",
      label: "Benign Tumour Liver",
    },
    {
      value: "Benign Tumour Neck",
      label: "Benign Tumour Neck",
    },
    {
      value: "Benign Tumour Of Adrenal Gland",
      label: "Benign Tumour Of Adrenal Gland",
    },
    {
      value: "Benign Tumour Of Back",
      label: "Benign Tumour Of Back",
    },
    {
      value: "Benign Tumour Of Bladder",
      label: "Benign Tumour Of Bladder",
    },
    {
      value: "Benign Tumour Of Bone",
      label: "Benign Tumour Of Bone",
    },
    {
      value: "Benign Tumour Of Brain",
      label: "Benign Tumour Of Brain",
    },
    {
      value: "Benign Tumour Of Colon",
      label: "Benign Tumour Of Colon",
    },
    {
      value: "Benign Tumour Of Neck",
      label: "Benign Tumour Of Neck",
    },
    {
      value: "Benign Tumour Of Ovarian",
      label: "Benign Tumour Of Ovarian",
    },
    {
      value: "Benign Tumour Of Pancreas",
      label: "Benign Tumour Of Pancreas",
    },
    {
      value: "Benign Tumour Of Pelvis",
      label: "Benign Tumour Of Pelvis",
    },
    {
      value: "Benign Tumour Of Pituitary Gland",
      label: "Benign Tumour Of Pituitary Gland",
    },
    {
      value: "Benign Tumour Of Skull",
      label: "Benign Tumour Of Skull",
    },
    {
      value: "Benign Tumour Of Small Intestine",
      label: "Benign Tumour Of Small Intestine",
    },
    {
      value: "Benign Tumour Of Spinal Cord",
      label: "Benign Tumour Of Spinal Cord",
    },
    {
      value: "Benign Tumour Of Stomach",
      label: "Benign Tumour Of Stomach",
    },
    {
      value: "Benign Tumour Of The Brain",
      label: "Benign Tumour Of The Brain",
    },
    {
      value: "Benign Tumour Of The Colon",
      label: "Benign Tumour Of The Colon",
    },
    {
      value: "Benign Tumour Of Thyroid Gland",
      label: "Benign Tumour Of Thyroid Gland",
    },
    {
      value: "Benign Uterus Neoplasm",
      label: "Benign Uterus Neoplasm",
    },
    {
      value: "Biliary Tract Cancer",
      label: "Biliary Tract Cancer",
    },
    {
      value: "Biliary Tract Malignant Neoplasm",
      label: "Biliary Tract Malignant Neoplasm",
    },
    {
      value: "Bladder Cancer",
      label: "Bladder Cancer",
    },
    {
      value: "Bladder Malignant Neoplasm",
      label: "Bladder Malignant Neoplasm",
    },
    {
      value: "Bladder Neoplasm Malignant",
      label: "Bladder Neoplasm Malignant",
    },
    {
      value: "Blast Cell Leukaemia",
      label: "Blast Cell Leukaemia",
    },
    {
      value: "Blood Vessel Cancer",
      label: "Blood Vessel Cancer",
    },
    {
      value: "Bloodvessel Cancer",
      label: "Bloodvessel Cancer",
    },
    {
      value: "Bloodvessel Neoplasm Malignant",
      label: "Bloodvessel Neoplasm Malignant",
    },
    {
      value: "Bone Cancer",
      label: "Bone Cancer",
    },
    {
      value: "Bone Neoplasm Malignant",
      label: "Bone Neoplasm Malignant",
    },
    {
      value: "Bone Tumour Malignant",
      label: "Bone Tumour Malignant",
    },
    {
      value: "Bones Of Skull And Face Malignant Neoplasm",
      label: "Bones Of Skull And Face Malignant Neoplasm",
    },
    {
      value: "Bowel Cancer",
      label: "Bowel Cancer",
    },
    {
      value: "Bowel Tumour",
      label: "Bowel Tumour",
    },
    {
      value: "Brain Cancer",
      label: "Brain Cancer",
    },
    {
      value: "Brain Malignant Neoplasm",
      label: "Brain Malignant Neoplasm",
    },
    {
      value: "Brain Neoplasm Malignant",
      label: "Brain Neoplasm Malignant",
    },
    {
      value: "Brain Stem Cancer",
      label: "Brain Stem Cancer",
    },
    {
      value: "Brain Tumour",
      label: "Brain Tumour",
    },
    {
      value: "Breast Cancer",
      label: "Breast Cancer",
    },
    {
      value: "Breast Carcinoma",
      label: "Breast Carcinoma",
    },
    {
      value: "Breast Carcinoma In Situ",
      label: "Breast Carcinoma In Situ",
    },
    {
      value: "Breast Malignant Neoplasm",
      label: "Breast Malignant Neoplasm",
    },
    {
      value: "Breast Neoplasm Benign",
      label: "Breast Neoplasm Benign",
    },
    {
      value: "Breast Neoplasm Malignant",
      label: "Breast Neoplasm Malignant",
    },
    {
      value: "Breast Tumour",
      label: "Breast Tumour",
    },
    {
      value: "Bronchial Cancer",
      label: "Bronchial Cancer",
    },
    {
      value: "Bronchus And Lung Malignant Neoplasm",
      label: "Bronchus And Lung Malignant Neoplasm",
    },
    {
      value: "Buccal Cancer",
      label: "Buccal Cancer",
    },
    {
      value: "Burkitts Lymphoma",
      label: "Burkitts Lymphoma",
    },
    {
      value: "Burkitts Tumour",
      label: "Burkitts Tumour",
    },
    {
      value: "Buttocks Cancer",
      label: "Buttocks Cancer",
    },
    {
      value: "Buttocks Neoplasm Malignant",
      label: "Buttocks Neoplasm Malignant",
    },
    {
      value: "Ca Adrenal Gland",
      label: "Ca Adrenal Gland",
    },
    {
      value: "Caecum Cancer",
      label: "Caecum Cancer",
    },
    {
      value: "Cancer Bladder",
      label: "Cancer Bladder",
    },
    {
      value: "Cancer Cervix-Uteri",
      label: "Cancer Cervix-Uteri",
    },
    {
      value: "Cancer Colon",
      label: "Cancer Colon",
    },
    {
      value: "Cancer Kidney",
      label: "Cancer Kidney",
    },
    {
      value: "Cancer Liver",
      label: "Cancer Liver",
    },
    {
      value: "Cancer Lymph Nodes",
      label: "Cancer Lymph Nodes",
    },
    {
      value: "Cancer Mandible",
      label: "Cancer Mandible",
    },
    {
      value: "Cancer Of Bladder",
      label: "Cancer Of Bladder",
    },
    {
      value: "Cancer Of Colon",
      label: "Cancer Of Colon",
    },
    {
      value: "Cancer Of Connective And Soft Tissue",
      label: "Cancer Of Connective And Soft Tissue",
    },
    {
      value: "Cancer Of Esophagus",
      label: "Cancer Of Esophagus",
    },
    {
      value: "Cancer Of Face",
      label: "Cancer Of Face",
    },
    {
      value: "Cancer Of Kidney",
      label: "Cancer Of Kidney",
    },
    {
      value: "Cancer Of Kidneys",
      label: "Cancer Of Kidneys",
    },
    {
      value: "Cancer Of Lesser Stomach Curvature",
      label: "Cancer Of Lesser Stomach Curvature",
    },
    {
      value: "Cancer Of Lung",
      label: "Cancer Of Lung",
    },
    {
      value: "Cancer Of Meninges",
      label: "Cancer Of Meninges",
    },
    {
      value: "Cancer Of Nasopharynx",
      label: "Cancer Of Nasopharynx",
    },
    {
      value: "Cancer Of Oesophagus",
      label: "Cancer Of Oesophagus",
    },
    {
      value: "Cancer Of Ovaries",
      label: "Cancer Of Ovaries",
    },
    {
      value: "Cancer Of Ovary",
      label: "Cancer Of Ovary",
    },
    {
      value: "Cancer Of Pancreas",
      label: "Cancer Of Pancreas",
    },
    {
      value: "Cancer Of Pharynx",
      label: "Cancer Of Pharynx",
    },
    {
      value: "Cancer Of Prostate",
      label: "Cancer Of Prostate",
    },
    {
      value: "Cancer Of Rectum",
      label: "Cancer Of Rectum",
    },
    {
      value: "Cancer Of Scrotum",
      label: "Cancer Of Scrotum",
    },
    {
      value: "Cancer Of Shoulder Skin",
      label: "Cancer Of Shoulder Skin",
    },
    {
      value: "Cancer Of Skin Of Ear",
      label: "Cancer Of Skin Of Ear",
    },
    {
      value: "Cancer Of Sternum",
      label: "Cancer Of Sternum",
    },
    {
      value: "Cancer Of The Bladder",
      label: "Cancer Of The Bladder",
    },
    {
      value: "Cancer Of The Colon",
      label: "Cancer Of The Colon",
    },
    {
      value: "Cancer Of The Eye",
      label: "Cancer Of The Eye",
    },
    {
      value: "Cancer Of The Larynx",
      label: "Cancer Of The Larynx",
    },
    {
      value: "Cancer Of The Oesophagus",
      label: "Cancer Of The Oesophagus",
    },
    {
      value: "Cancer Of The Scrotum",
      label: "Cancer Of The Scrotum",
    },
    {
      value: "Cancer Of The Uterus",
      label: "Cancer Of The Uterus",
    },
    {
      value: "Cancer Of The Womb",
      label: "Cancer Of The Womb",
    },
    {
      value: "Cancer Of Uterus",
      label: "Cancer Of Uterus",
    },
    {
      value: "Cancer Of Womb",
      label: "Cancer Of Womb",
    },
    {
      value: "Cancer Scrotum",
      label: "Cancer Scrotum",
    },
    {
      value: "Cancer Secondary",
      label: "Cancer Secondary",
    },
    {
      value: "Cancer Splenic Flexure",
      label: "Cancer Splenic Flexure",
    },
    {
      value: "Cancer Unspecified",
      label: "Cancer Unspecified",
    },
    {
      value: "Cancer Womb",
      label: "Cancer Womb",
    },
    {
      value: "Carcinoid Syndrome",
      label: "Carcinoid Syndrome",
    },
    {
      value: "Carcinoma Bladder",
      label: "Carcinoma Bladder",
    },
    {
      value: "Carcinoma Breast",
      label: "Carcinoma Breast",
    },
    {
      value: "Carcinoma In Situ Of Anus",
      label: "Carcinoma In Situ Of Anus",
    },
    {
      value: "Carcinoma In Situ Of Bile Ducts",
      label: "Carcinoma In Situ Of Bile Ducts",
    },
    {
      value: "Carcinoma In Situ Of Bladder",
      label: "Carcinoma In Situ Of Bladder",
    },
    {
      value: "Carcinoma In Situ Of Breast",
      label: "Carcinoma In Situ Of Breast",
    },
    {
      value: "Carcinoma In Situ Of Cervix",
      label: "Carcinoma In Situ Of Cervix",
    },
    {
      value: "Carcinoma In Situ Of Cervix Uteri",
      label: "Carcinoma In Situ Of Cervix Uteri",
    },
    {
      value: "Carcinoma In Situ Of Colon",
      label: "Carcinoma In Situ Of Colon",
    },
    {
      value: "Carcinoma In Situ Of Prostate",
      label: "Carcinoma In Situ Of Prostate",
    },
    {
      value: "Carcinoma In Situ Of Rectum",
      label: "Carcinoma In Situ Of Rectum",
    },
    {
      value: "Carcinoma In Situ Of Skin Of Lip",
      label: "Carcinoma In Situ Of Skin Of Lip",
    },
    {
      value: "Carcinoma In Situ Of Thyroid Gland",
      label: "Carcinoma In Situ Of Thyroid Gland",
    },
    {
      value: "Carcinoma Of Bladder",
      label: "Carcinoma Of Bladder",
    },
    {
      value: "Carcinoma Of Colon",
      label: "Carcinoma Of Colon",
    },
    {
      value: "Carcinoma Of Prostate",
      label: "Carcinoma Of Prostate",
    },
    {
      value: "Carcinoma Of Rectum",
      label: "Carcinoma Of Rectum",
    },
    {
      value: "Carcinoma Of Skin",
      label: "Carcinoma Of Skin",
    },
    {
      value: "Carcinoma Prostate",
      label: "Carcinoma Prostate",
    },
    {
      value: "Carcinoma Rectum",
      label: "Carcinoma Rectum",
    },
    {
      value: "Carcinosis",
      label: "Carcinosis",
    },
    {
      value: "Cerebellar Cancer",
      label: "Cerebellar Cancer",
    },
    {
      value: "Cerebral Tumour",
      label: "Cerebral Tumour",
    },
    {
      value: "Cervical Cancer",
      label: "Cervical Cancer",
    },
    {
      value: "Cervix Cancer",
      label: "Cervix Cancer",
    },
    {
      value: "Cervix Uteri Cancer",
      label: "Cervix Uteri Cancer",
    },
    {
      value: "Cheek Cancer",
      label: "Cheek Cancer",
    },
    {
      value: "Cheek Neoplasm Malignant",
      label: "Cheek Neoplasm Malignant",
    },
    {
      value: "Chondrosarcoma",
      label: "Chondrosarcoma",
    },
    {
      value: "Choroid Neoplasm",
      label: "Choroid Neoplasm",
    },
    {
      value: "Chronic Lymphocytic Leukaemia",
      label: "Chronic Lymphocytic Leukaemia",
    },
    {
      value: "Chronic Lymphoid Leukaemia",
      label: "Chronic Lymphoid Leukaemia",
    },
    {
      value: "Chronic Monocytic Leukaemia",
      label: "Chronic Monocytic Leukaemia",
    },
    {
      value: "Chronic Myelocytic Leukaemia",
      label: "Chronic Myelocytic Leukaemia",
    },
    {
      value: "Chronic Myelogenous",
      label: "Chronic Myelogenous",
    },
    {
      value: "Chronic Myeloid Leukaemia",
      label: "Chronic Myeloid Leukaemia",
    },
    {
      value: "Chronic Myelomonocytic Leukaemia",
      label: "Chronic Myelomonocytic Leukaemia",
    },
    {
      value: "Clavicle Cancer",
      label: "Clavicle Cancer",
    },
    {
      value: "Clavicle Neoplasm Malignant",
      label: "Clavicle Neoplasm Malignant",
    },
    {
      value: "Clitoris Cancer",
      label: "Clitoris Cancer",
    },
    {
      value: "CLL",
      label: "CLL",
    },
    {
      value: "CML",
      label: "CML",
    },
    {
      value: "CNS Cancer",
      label: "CNS Cancer",
    },
    {
      value: "Coccyx Cancer",
      label: "Coccyx Cancer",
    },
    {
      value: "Coccyx Neoplasm",
      label: "Coccyx Neoplasm",
    },
    {
      value: "Coccyx Neoplasm Malignant",
      label: "Coccyx Neoplasm Malignant",
    },
    {
      value: "Colon Cancer",
      label: "Colon Cancer",
    },
    {
      value: "Colon Malignant Neoplasm",
      label: "Colon Malignant Neoplasm",
    },
    {
      value: "Colorectal Cancer",
      label: "Colorectal Cancer",
    },
    {
      value: "Common Bile Duct Malignant Neoplasm",
      label: "Common Bile Duct Malignant Neoplasm",
    },
    {
      value: "Connective Tissue Cancer",
      label: "Connective Tissue Cancer",
    },
    {
      value: "Connective Tissue Neoplasm Malignant",
      label: "Connective Tissue Neoplasm Malignant",
    },
    {
      value: "Corpus Uteri, Unspecified",
      label: "Corpus Uteri, Unspecified",
    },
    {
      value: "Coxa Cancer",
      label: "Coxa Cancer",
    },
    {
      value: "Coxa Neoplasm Malignant",
      label: "Coxa Neoplasm Malignant",
    },
    {
      value: "Di Guglielmos Disease",
      label: "Di Guglielmos Disease",
    },
    {
      value: "Diaphragm Cancer",
      label: "Diaphragm Cancer",
    },
    {
      value: "Diaphragm Neoplasm Malignant",
      label: "Diaphragm Neoplasm Malignant",
    },
    {
      value: "Diffuse Non-Hodgkins Lymphoma",
      label: "Diffuse Non-Hodgkins Lymphoma",
    },
    {
      value: "Disseminated Malignant Neoplasm",
      label: "Disseminated Malignant Neoplasm",
    },
    {
      value: "Endocervical Cancer",
      label: "Endocervical Cancer",
    },
    {
      value: "Essential Thrombocythemia",
      label: "Essential Thrombocythemia",
    },
    {
      value: "Extramedullary Plasmacytoma",
      label: "Extramedullary Plasmacytoma",
    },
    {
      value: "Eye Cancer",
      label: "Eye Cancer",
    },
    {
      value: "Eye Neoplasm Malignant",
      label: "Eye Neoplasm Malignant",
    },
    {
      value: "Eyeball Cancer",
      label: "Eyeball Cancer",
    },
    {
      value: "Eyesocket Cancer",
      label: "Eyesocket Cancer",
    },
    {
      value: "Eyesocket Neoplasm Malignant",
      label: "Eyesocket Neoplasm Malignant",
    },
    {
      value: "Face Cancer",
      label: "Face Cancer",
    },
    {
      value: "Face Neoplasm Malignant",
      label: "Face Neoplasm Malignant",
    },
    {
      value: "Fallopian Tube Cancer",
      label: "Fallopian Tube Cancer",
    },
    {
      value: "Familial Adenomatous Polyposis (FAP)",
      label: "Familial Adenomatous Polyposis (FAP)",
    },
    {
      value: "FAP",
      label: "FAP",
    },
    {
      value: "Female Genital Cancer",
      label: "Female Genital Cancer",
    },
    {
      value: "Follicular Cell Ca Thyroid",
      label: "Follicular Cell Ca Thyroid",
    },
    {
      value: "Follicular Cell Thyroid Cancer",
      label: "Follicular Cell Thyroid Cancer",
    },
    {
      value: "Follicular Non-Hodgkins Lymphoma",
      label: "Follicular Non-Hodgkins Lymphoma",
    },
    {
      value: "Foot Cancer",
      label: "Foot Cancer",
    },
    {
      value: "Foot Neoplasm Malignant",
      label: "Foot Neoplasm Malignant",
    },
    {
      value: "Frontal Lobe Cancer",
      label: "Frontal Lobe Cancer",
    },
    {
      value: "Gall Bladder Cancer",
      label: "Gall Bladder Cancer",
    },
    {
      value: "Gallbladder Cancer",
      label: "Gallbladder Cancer",
    },
    {
      value: "Gallbladder Malignant Neoplasm",
      label: "Gallbladder Malignant Neoplasm",
    },
    {
      value: "Genital Cancer Male",
      label: "Genital Cancer Male",
    },
    {
      value: "Gland Endocrine Cancer",
      label: "Gland Endocrine Cancer",
    },
    {
      value: "Granulocytic Sarcoma",
      label: "Granulocytic Sarcoma",
    },
    {
      value: "Gum Cancer",
      label: "Gum Cancer",
    },
    {
      value: "Gum Malignant Neoplasm",
      label: "Gum Malignant Neoplasm",
    },
    {
      value: "Hairy-Cell Leukaemia",
      label: "Hairy-Cell Leukaemia",
    },
    {
      value: "Hand Cancer",
      label: "Hand Cancer",
    },
    {
      value: "Hand Neoplasm Malignant",
      label: "Hand Neoplasm Malignant",
    },
    {
      value: "Head Cancer",
      label: "Head Cancer",
    },
    {
      value: "Head Neoplasm Malignant",
      label: "Head Neoplasm Malignant",
    },
    {
      value: "Heart Cancer",
      label: "Heart Cancer",
    },
    {
      value: "Hepatic Cancer",
      label: "Hepatic Cancer",
    },
    {
      value: "Hodgkins Disease",
      label: "Hodgkins Disease",
    },
    {
      value: "Hodgkins Lymphoma",
      label: "Hodgkins Lymphoma",
    },
    {
      value: "Hypopharynx Cancer",
      label: "Hypopharynx Cancer",
    },
    {
      value: "Hypopharynx Malignant Neoplasm",
      label: "Hypopharynx Malignant Neoplasm",
    },
    {
      value: "Idiopathic Myelofibrosis",
      label: "Idiopathic Myelofibrosis",
    },
    {
      value: "Infiltrating Ductal Adenocarcinoma",
      label: "Infiltrating Ductal Adenocarcinoma",
    },
    {
      value: "Intestinal Cancer",
      label: "Intestinal Cancer",
    },
    {
      value: "Intestine Cancer",
      label: "Intestine Cancer",
    },
    {
      value: "Invasive Lobular Carcinoma",
      label: "Invasive Lobular Carcinoma",
    },
    {
      value: "Jaw Cancer",
      label: "Jaw Cancer",
    },
    {
      value: "Jaw Neoplasm Malignant",
      label: "Jaw Neoplasm Malignant",
    },
    {
      value: "Joint Cancer",
      label: "Joint Cancer",
    },
    {
      value: "Joint Innerskin Cancer",
      label: "Joint Innerskin Cancer",
    },
    {
      value: "Joint Neoplasm Malignant",
      label: "Joint Neoplasm Malignant",
    },
    {
      value: "Kahlers Disease",
      label: "Kahlers Disease",
    },
    {
      value: "Kaposi Sarcoma",
      label: "Kaposi Sarcoma",
    },
    {
      value: "Kaposis Sarcoma",
      label: "Kaposis Sarcoma",
    },
    {
      value: "Kidney Cancer",
      label: "Kidney Cancer",
    },
    {
      value: "Kidney Neoplasm Malignant",
      label: "Kidney Neoplasm Malignant",
    },
    {
      value: "Knee Cancer",
      label: "Knee Cancer",
    },
    {
      value: "Knee Neoplasm Malignant",
      label: "Knee Neoplasm Malignant",
    },
    {
      value: "Larynx Cancer",
      label: "Larynx Cancer",
    },
    {
      value: "Larynx Malignant Neoplasm",
      label: "Larynx Malignant Neoplasm",
    },
    {
      value: "Leg Cancer",
      label: "Leg Cancer",
    },
    {
      value: "Leg Neoplasm Malignant",
      label: "Leg Neoplasm Malignant",
    },
    {
      value: "Lennerts Lymphoma",
      label: "Lennerts Lymphoma",
    },
    {
      value: "Letterersiwe Disease",
      label: "Letterersiwe Disease",
    },
    {
      value: "Leukaemia",
      label: "Leukaemia",
    },
    {
      value: "Leukaemic Reticuloendotheliosis",
      label: "Leukaemic Reticuloendotheliosis",
    },
    {
      value: "Leukemic Reticuloendotheliosis",
      label: "Leukemic Reticuloendotheliosis",
    },
    {
      value: "Ligament Cancer",
      label: "Ligament Cancer",
    },
    {
      value: "Lip Cancer",
      label: "Lip Cancer",
    },
    {
      value: "Liver And Intrahepatic Bile Ducts Malignant Neoplasm",
      label: "Liver And Intrahepatic Bile Ducts Malignant Neoplasm",
    },
    {
      value: "Liver Cancer",
      label: "Liver Cancer",
    },
    {
      value: "Loin Cancer",
      label: "Loin Cancer",
    },
    {
      value: "Lung Cancer",
      label: "Lung Cancer",
    },
    {
      value: "Lymph Nodes Cancer",
      label: "Lymph Nodes Cancer",
    },
    {
      value: "Lymph Nodes Neoplasm Malignant",
      label: "Lymph Nodes Neoplasm Malignant",
    },
    {
      value: "Lymphocytic Depletion",
      label: "Lymphocytic Depletion",
    },
    {
      value: "Lymphocytic Predominance",
      label: "Lymphocytic Predominance",
    },
    {
      value: "Lymphocytic-Histiocytic Predominance",
      label: "Lymphocytic-Histiocytic Predominance",
    },
    {
      value: "Lymphoepithelioid Lymphoma",
      label: "Lymphoepithelioid Lymphoma",
    },
    {
      value: "Lymphoid Leukaemia",
      label: "Lymphoid Leukaemia",
    },
    {
      value: "Lymphoma",
      label: "Lymphoma",
    },
    {
      value: "Lymphosarcoma",
      label: "Lymphosarcoma",
    },
    {
      value: "Lymphosarcoma Cell Leukaemia",
      label: "Lymphosarcoma Cell Leukaemia",
    },
    {
      value: "Macroglobulinemia",
      label: "Macroglobulinemia",
    },
    {
      value: "Maligant Neoplasm Of Oropharynx",
      label: "Maligant Neoplasm Of Oropharynx",
    },
    {
      value: "Maligant Neoplasm Of Spleen",
      label: "Maligant Neoplasm Of Spleen",
    },
    {
      value: "Malignant Astrocytoma",
      label: "Malignant Astrocytoma",
    },
    {
      value: "Malignant Brain Tumour",
      label: "Malignant Brain Tumour",
    },
    {
      value: "Malignant Breast Neoplasm",
      label: "Malignant Breast Neoplasm",
    },
    {
      value: "Malignant Histiocytosis",
      label: "Malignant Histiocytosis",
    },
    {
      value: "Malignant Immunoproliferative Disease",
      label: "Malignant Immunoproliferative Disease",
    },
    {
      value: "Malignant Lymphoma",
      label: "Malignant Lymphoma",
    },
    {
      value: "Malignant Mast Cell Tumors",
      label: "Malignant Mast Cell Tumors",
    },
    {
      value: "Malignant Mast Cell Tumour",
      label: "Malignant Mast Cell Tumour",
    },
    {
      value: "Malignant Mastocytoma",
      label: "Malignant Mastocytoma",
    },
    {
      value: "Malignant Mastocytosis",
      label: "Malignant Mastocytosis",
    },
    {
      value: "Malignant Melanoma",
      label: "Malignant Melanoma",
    },
    {
      value: "Malignant Melanoma Neck",
      label: "Malignant Melanoma Neck",
    },
    {
      value: "Malignant Melanoma Of Arm Or Shoulder",
      label: "Malignant Melanoma Of Arm Or Shoulder",
    },
    {
      value: "Malignant Melanoma Of Eyelid",
      label: "Malignant Melanoma Of Eyelid",
    },
    {
      value: "Malignant Melanoma Of Leg",
      label: "Malignant Melanoma Of Leg",
    },
    {
      value: "Malignant Melanoma Of Lip",
      label: "Malignant Melanoma Of Lip",
    },
    {
      value: "Malignant Melanoma Of Lower Limb",
      label: "Malignant Melanoma Of Lower Limb",
    },
    {
      value: "Malignant Melanoma Of Nose",
      label: "Malignant Melanoma Of Nose",
    },
    {
      value: "Malignant Melanoma Of Skin",
      label: "Malignant Melanoma Of Skin",
    },
    {
      value: "Malignant Melanoma Of Upper Limb Including Shoulder",
      label: "Malignant Melanoma Of Upper Limb Including Shoulder",
    },
    {
      value: "Malignant Melanoma On Ear",
      label: "Malignant Melanoma On Ear",
    },
    {
      value: "Malignant Melanoma On Eyelid",
      label: "Malignant Melanoma On Eyelid",
    },
    {
      value: "Malignant Melanoma On Face",
      label: "Malignant Melanoma On Face",
    },
    {
      value: "Malignant Melanoma On Scalp And Face",
      label: "Malignant Melanoma On Scalp And Face",
    },
    {
      value: "Malignant Melanoma Skin",
      label: "Malignant Melanoma Skin",
    },
    {
      value: "Malignant Melanoma Skin Ear External Auditory Canal",
      label: "Malignant Melanoma Skin Ear External Auditory Canal",
    },
    {
      value: "Malignant Melanoma Skin Eyelid Including Canthus",
      label: "Malignant Melanoma Skin Eyelid Including Canthus",
    },
    {
      value: "Malignant Melanoma Skin Leg Or Hip",
      label: "Malignant Melanoma Skin Leg Or Hip",
    },
    {
      value: "Malignant Melanoma Skin Lip",
      label: "Malignant Melanoma Skin Lip",
    },
    {
      value: "Malignant Melanoma Skin Lower Limb Including Hip",
      label: "Malignant Melanoma Skin Lower Limb Including Hip",
    },
    {
      value: "Malignant Melanoma Skin Of Face",
      label: "Malignant Melanoma Skin Of Face",
    },
    {
      value: "Malignant Melanoma Skin Other Unspecified Parts Face",
      label: "Malignant Melanoma Skin Other Unspecified Parts Face",
    },
    {
      value: "Malignant Melanoma Skin Scalp Neck",
      label: "Malignant Melanoma Skin Scalp Neck",
    },
    {
      value: "Malignant Melanoma Skin Scalp Or Neck",
      label: "Malignant Melanoma Skin Scalp Or Neck",
    },
    {
      value: "Malignant Melanoma Skin Trunk Except Scrotum",
      label: "Malignant Melanoma Skin Trunk Except Scrotum",
    },
    {
      value: "Malignant Melanoma Skin Upper Limb Including Shoulder",
      label: "Malignant Melanoma Skin Upper Limb Including Shoulder",
    },
    {
      value: "Malignant Mole",
      label: "Malignant Mole",
    },
    {
      value: "Malignant Neeoplasm Of Sacrum",
      label: "Malignant Neeoplasm Of Sacrum",
    },
    {
      value: "Malignant Neoplams Of Pleura",
      label: "Malignant Neoplams Of Pleura",
    },
    {
      value: "Malignant Neoplasm Abdomen",
      label: "Malignant Neoplasm Abdomen",
    },
    {
      value: "Malignant Neoplasm Accessory Sinus Unspecified",
      label: "Malignant Neoplasm Accessory Sinus Unspecified",
    },
    {
      value: "Malignant Neoplasm Adrenal Gland",
      label: "Malignant Neoplasm Adrenal Gland",
    },
    {
      value: "Malignant Neoplasm Ampulla Vater",
      label: "Malignant Neoplasm Ampulla Vater",
    },
    {
      value: "Malignant Neoplasm Anal Canal",
      label: "Malignant Neoplasm Anal Canal",
    },
    {
      value: "Malignant Neoplasm Anterior Aspect Epiglottis",
      label: "Malignant Neoplasm Anterior Aspect Epiglottis",
    },
    {
      value: "Malignant Neoplasm Anterior Mediastinum",
      label: "Malignant Neoplasm Anterior Mediastinum",
    },
    {
      value: "Malignant Neoplasm Anterior Portion Floor Mout",
      label: "Malignant Neoplasm Anterior Portion Floor Mout",
    },
    {
      value: "Malignant Neoplasm Anterior Wall Nasopharynx",
      label: "Malignant Neoplasm Anterior Wall Nasopharynx",
    },
    {
      value: "Malignant Neoplasm Anterior Wall Urinary Bladder",
      label: "Malignant Neoplasm Anterior Wall Urinary Bladder",
    },
    {
      value: "Malignant Neoplasm Anus",
      label: "Malignant Neoplasm Anus",
    },
    {
      value: "Malignant Neoplasm Anus Unspecified",
      label: "Malignant Neoplasm Anus Unspecified",
    },
    {
      value: "Malignant Neoplasm Appendix Vermiformis",
      label: "Malignant Neoplasm Appendix Vermiformis",
    },
    {
      value: "Malignant Neoplasm Ascending Colon",
      label: "Malignant Neoplasm Ascending Colon",
    },
    {
      value: "Malignant Neoplasm Auditory Tube Middle Ear Mastoid Air Cells",
      label: "Malignant Neoplasm Auditory Tube Middle Ear Mastoid Air Cells",
    },
    {
      value: "Malignant Neoplasm Axillary Tail Female Breast",
      label: "Malignant Neoplasm Axillary Tail Female Breast",
    },
    {
      value: "Malignant Neoplasm Back",
      label: "Malignant Neoplasm Back",
    },
    {
      value: "Malignant Neoplasm Base Tongue",
      label: "Malignant Neoplasm Base Tongue",
    },
    {
      value: "Malignant Neoplasm Biliary Tract Part Unspecified",
      label: "Malignant Neoplasm Biliary Tract Part Unspecified",
    },
    {
      value: "Malignant Neoplasm Bladder",
      label: "Malignant Neoplasm Bladder",
    },
    {
      value: "Malignant Neoplasm Bladder Neck",
      label: "Malignant Neoplasm Bladder Neck",
    },
    {
      value: "Malignant Neoplasm Bladder Part Unspecified",
      label: "Malignant Neoplasm Bladder Part Unspecified",
    },
    {
      value: "Malignant Neoplasm Body Pancreas",
      label: "Malignant Neoplasm Body Pancreas",
    },
    {
      value: "Malignant Neoplasm Body Penis",
      label: "Malignant Neoplasm Body Penis",
    },
    {
      value: "Malignant Neoplasm Body Stomach",
      label: "Malignant Neoplasm Body Stomach",
    },
    {
      value: "Malignant Neoplasm Body Uterus",
      label: "Malignant Neoplasm Body Uterus",
    },
    {
      value: "Malignant Neoplasm Bone Articular Cartilage",
      label: "Malignant Neoplasm Bone Articular Cartilage",
    },
    {
      value: "Malignant Neoplasm Bone Articular Cartilage Site Unspecified",
      label: "Malignant Neoplasm Bone Articular Cartilage Site Unspecified",
    },
    {
      value: "Malignant Neoplasm Bones Skull Face Except Mandible",
      label: "Malignant Neoplasm Bones Skull Face Except Mandible",
    },
    {
      value: "Malignant Neoplasm Brain Stem",
      label: "Malignant Neoplasm Brain Stem",
    },
    {
      value: "Malignant Neoplasm Brain Unspecified",
      label: "Malignant Neoplasm Brain Unspecified",
    },
    {
      value: "Malignant Neoplasm Breast",
      label: "Malignant Neoplasm Breast",
    },
    {
      value: "Malignant Neoplasm Breast Female Unspecified",
      label: "Malignant Neoplasm Breast Female Unspecified",
    },
    {
      value: "Malignant Neoplasm Broad Ligament Uterus",
      label: "Malignant Neoplasm Broad Ligament Uterus",
    },
    {
      value: "Malignant Neoplasm Bronchus Lung Unspecified",
      label: "Malignant Neoplasm Bronchus Lung Unspecified",
    },
    {
      value: "Malignant Neoplasm Cardia",
      label: "Malignant Neoplasm Cardia",
    },
    {
      value: "Malignant Neoplasm Carotid Body",
      label: "Malignant Neoplasm Carotid Body",
    },
    {
      value: "Malignant Neoplasm Cecum",
      label: "Malignant Neoplasm Cecum",
    },
    {
      value: "Malignant Neoplasm Central Portion Female Breast",
      label: "Malignant Neoplasm Central Portion Female Breast",
    },
    {
      value: "Malignant Neoplasm Cerebellum Nos",
      label: "Malignant Neoplasm Cerebellum Nos",
    },
    {
      value: "Malignant Neoplasm Cerebral Meninges",
      label: "Malignant Neoplasm Cerebral Meninges",
    },
    {
      value: "Malignant Neoplasm Cerebrum Except Lobes Ventricles",
      label: "Malignant Neoplasm Cerebrum Except Lobes Ventricles",
    },
    {
      value: "Malignant Neoplasm Cervix Uteri",
      label: "Malignant Neoplasm Cervix Uteri",
    },
    {
      value: "Malignant Neoplasm Cervix Uteri Unspecified",
      label: "Malignant Neoplasm Cervix Uteri Unspecified",
    },
    {
      value: "Malignant Neoplasm Cheek Mucosa",
      label: "Malignant Neoplasm Cheek Mucosa",
    },
    {
      value: "Malignant Neoplasm Clitoris",
      label: "Malignant Neoplasm Clitoris",
    },
    {
      value: "Malignant Neoplasm Colon",
      label: "Malignant Neoplasm Colon",
    },
    {
      value: "Malignant Neoplasm Colon Unspecified",
      label: "Malignant Neoplasm Colon Unspecified",
    },
    {
      value: "Malignant Neoplasm Commissure Lip",
      label: "Malignant Neoplasm Commissure Lip",
    },
    {
      value: "Malignant Neoplasm Commissure Of Lip",
      label: "Malignant Neoplasm Commissure Of Lip",
    },
    {
      value: "Malignant Neoplasm Common Bile Duct",
      label: "Malignant Neoplasm Common Bile Duct",
    },
    {
      value: "Malignant Neoplasm Connective Other Soft Tissue",
      label: "Malignant Neoplasm Connective Other Soft Tissue",
    },
    {
      value: "Malignant Neoplasm Corpus Uteri Except Isthmus",
      label: "Malignant Neoplasm Corpus Uteri Except Isthmus",
    },
    {
      value: "Malignant Neoplasm Cranial Nerves",
      label: "Malignant Neoplasm Cranial Nerves",
    },
    {
      value: "Malignant Neoplasm Descending Colon",
      label: "Malignant Neoplasm Descending Colon",
    },
    {
      value: "Malignant Neoplasm Dome Urinary Bladder",
      label: "Malignant Neoplasm Dome Urinary Bladder",
    },
    {
      value: "Malignant Neoplasm Dorsal Surface Tongue",
      label: "Malignant Neoplasm Dorsal Surface Tongue",
    },
    {
      value: "Malignant Neoplasm Duodenum",
      label: "Malignant Neoplasm Duodenum",
    },
    {
      value: "Malignant Neoplasm Endocervix",
      label: "Malignant Neoplasm Endocervix",
    },
    {
      value: "Malignant Neoplasm Endocrine Gland",
      label: "Malignant Neoplasm Endocrine Gland",
    },
    {
      value: "Malignant Neoplasm Endocrine Gland Site Unspecified",
      label: "Malignant Neoplasm Endocrine Gland Site Unspecified",
    },
    {
      value: "Malignant Neoplasm Epididymis",
      label: "Malignant Neoplasm Epididymis",
    },
    {
      value: "Malignant Neoplasm Ethmoidal Sinus",
      label: "Malignant Neoplasm Ethmoidal Sinus",
    },
    {
      value: "Malignant Neoplasm Exocervix",
      label: "Malignant Neoplasm Exocervix",
    },
    {
      value: "Malignant Neoplasm Extrahepatic Bile Ducts",
      label: "Malignant Neoplasm Extrahepatic Bile Ducts",
    },
    {
      value: "Malignant Neoplasm Eye",
      label: "Malignant Neoplasm Eye",
    },
    {
      value: "Malignant Neoplasm Face",
      label: "Malignant Neoplasm Face",
    },
    {
      value: "Malignant Neoplasm Fallopian Tube",
      label: "Malignant Neoplasm Fallopian Tube",
    },
    {
      value: "Malignant Neoplasm Female Breast",
      label: "Malignant Neoplasm Female Breast",
    },
    {
      value: "Malignant Neoplasm Floor Mouth",
      label: "Malignant Neoplasm Floor Mouth",
    },
    {
      value: "Malignant Neoplasm Floor Mouth Part Unspecified",
      label: "Malignant Neoplasm Floor Mouth Part Unspecified",
    },
    {
      value: "Malignant Neoplasm Frontal Lobe",
      label: "Malignant Neoplasm Frontal Lobe",
    },
    {
      value: "Malignant Neoplasm Frontal Sinus",
      label: "Malignant Neoplasm Frontal Sinus",
    },
    {
      value: "Malignant Neoplasm Fundus Stomach",
      label: "Malignant Neoplasm Fundus Stomach",
    },
    {
      value: "Malignant Neoplasm Gallbladder",
      label: "Malignant Neoplasm Gallbladder",
    },
    {
      value: "Malignant Neoplasm Gallbladder Extrahepatic Bile Ducts",
      label: "Malignant Neoplasm Gallbladder Extrahepatic Bile Ducts",
    },
    {
      value: "Malignant Neoplasm Glans Penis",
      label: "Malignant Neoplasm Glans Penis",
    },
    {
      value: "Malignant Neoplasm Glottis",
      label: "Malignant Neoplasm Glottis",
    },
    {
      value: "Malignant Neoplasm Greater Curvature Stomach Unspecified",
      label: "Malignant Neoplasm Greater Curvature Stomach Unspecified",
    },
    {
      value: "Malignant Neoplasm Gum",
      label: "Malignant Neoplasm Gum",
    },
    {
      value: "Malignant Neoplasm Gum Unspecified",
      label: "Malignant Neoplasm Gum Unspecified",
    },
    {
      value: "Malignant Neoplasm Hard Palate",
      label: "Malignant Neoplasm Hard Palate",
    },
    {
      value: "Malignant Neoplasm Head Face Neck",
      label: "Malignant Neoplasm Head Face Neck",
    },
    {
      value: "Malignant Neoplasm Head Pancreas",
      label: "Malignant Neoplasm Head Pancreas",
    },
    {
      value: "Malignant Neoplasm Heart",
      label: "Malignant Neoplasm Heart",
    },
    {
      value: "Malignant Neoplasm Hepatic Flexure",
      label: "Malignant Neoplasm Hepatic Flexure",
    },
    {
      value: "Malignant Neoplasm Hypopharynx",
      label: "Malignant Neoplasm Hypopharynx",
    },
    {
      value: "Malignant Neoplasm Hypopharynx Unspecified",
      label: "Malignant Neoplasm Hypopharynx Unspecified",
    },
    {
      value: "Malignant Neoplasm Ileum",
      label: "Malignant Neoplasm Ileum",
    },
    {
      value:
        "Malignant Neoplasm Illdefined Sites Within Digestive Organs Peritoneum",
      label:
        "Malignant Neoplasm Illdefined Sites Within Digestive Organs Peritoneum",
    },
    {
      value: "Malignant Neoplasm Illdefined Sites Within Lip Oral Cavity",
      label: "Malignant Neoplasm Illdefined Sites Within Lip Oral Cavity",
    },
    {
      value: "Malignant Neoplasm Illdefined Sites Within Respiratory System",
      label: "Malignant Neoplasm Illdefined Sites Within Respiratory System",
    },
    {
      value: "Malignant Neoplasm Intestinal Tract Part Unspecifie",
      label: "Malignant Neoplasm Intestinal Tract Part Unspecifie",
    },
    {
      value: "Malignant Neoplasm Intestine",
      label: "Malignant Neoplasm Intestine",
    },
    {
      value: "Malignant Neoplasm Intestine Unspecified",
      label: "Malignant Neoplasm Intestine Unspecified",
    },
    {
      value: "Malignant Neoplasm Intrahepatic Bile Ducts",
      label: "Malignant Neoplasm Intrahepatic Bile Ducts",
    },
    {
      value: "Malignant Neoplasm Islets Langerhans",
      label: "Malignant Neoplasm Islets Langerhans",
    },
    {
      value: "Malignant Neoplasm Jaw",
      label: "Malignant Neoplasm Jaw",
    },
    {
      value: "Malignant Neoplasm Jejunum",
      label: "Malignant Neoplasm Jejunum",
    },
    {
      value: "Malignant Neoplasm Junctional Region Oropharynx",
      label: "Malignant Neoplasm Junctional Region Oropharynx",
    },
    {
      value: "Malignant Neoplasm Junctional Zone Tongue",
      label: "Malignant Neoplasm Junctional Zone Tongue",
    },
    {
      value: "Malignant Neoplasm Labia Majora",
      label: "Malignant Neoplasm Labia Majora",
    },
    {
      value: "Malignant Neoplasm Labia Minora",
      label: "Malignant Neoplasm Labia Minora",
    },
    {
      value: "Malignant Neoplasm Laryngeal Cartilages",
      label: "Malignant Neoplasm Laryngeal Cartilages",
    },
    {
      value: "Malignant Neoplasm Larynx",
      label: "Malignant Neoplasm Larynx",
    },
    {
      value: "Malignant Neoplasm Larynx Unspecified",
      label: "Malignant Neoplasm Larynx Unspecified",
    },
    {
      value: "Malignant Neoplasm Lateral Portion Floor Mouth",
      label: "Malignant Neoplasm Lateral Portion Floor Mouth",
    },
    {
      value: "Malignant Neoplasm Lateral Wall Nasopharynx",
      label: "Malignant Neoplasm Lateral Wall Nasopharynx",
    },
    {
      value: "Malignant Neoplasm Lateral Wall Oropharynx",
      label: "Malignant Neoplasm Lateral Wall Oropharynx",
    },
    {
      value: "Malignant Neoplasm Lateral Wall Urinary Bladder",
      label: "Malignant Neoplasm Lateral Wall Urinary Bladder",
    },
    {
      value: "Malignant Neoplasm Leg",
      label: "Malignant Neoplasm Leg",
    },
    {
      value: "Malignant Neoplasm Lesser Curvature Stomach Unspecified",
      label: "Malignant Neoplasm Lesser Curvature Stomach Unspecified",
    },
    {
      value: "Malignant Neoplasm Lingual Tonsil",
      label: "Malignant Neoplasm Lingual Tonsil",
    },
    {
      value: "Malignant Neoplasm Lip",
      label: "Malignant Neoplasm Lip",
    },
    {
      value: "Malignant Neoplasm Lip Unspecified Inner Aspect",
      label: "Malignant Neoplasm Lip Unspecified Inner Aspect",
    },
    {
      value: "Malignant Neoplasm Lip Unspecified Vermilion Borde",
      label: "Malignant Neoplasm Lip Unspecified Vermilion Borde",
    },
    {
      value: "Malignant Neoplasm Liver Intrahepatic Bile Ducts",
      label: "Malignant Neoplasm Liver Intrahepatic Bile Ducts",
    },
    {
      value: "Malignant Neoplasm Liver Not Specified As Primary Secondary",
      label: "Malignant Neoplasm Liver Not Specified As Primary Secondary",
    },
    {
      value: "Malignant Neoplasm Liver Primary",
      label: "Malignant Neoplasm Liver Primary",
    },
    {
      value: "Malignant Neoplasm Liver Specified As Secondary",
      label: "Malignant Neoplasm Liver Specified As Secondary",
    },
    {
      value: "Malignant Neoplasm Long Bones Leg",
      label: "Malignant Neoplasm Long Bones Leg",
    },
    {
      value: "Malignant Neoplasm Long Bones Lower Limb",
      label: "Malignant Neoplasm Long Bones Lower Limb",
    },
    {
      value: "Malignant Neoplasm Lowe Limb",
      label: "Malignant Neoplasm Lowe Limb",
    },
    {
      value: "Malignant Neoplasm Lower Gum",
      label: "Malignant Neoplasm Lower Gum",
    },
    {
      value: "Malignant Neoplasm Lower Limb",
      label: "Malignant Neoplasm Lower Limb",
    },
    {
      value: "Malignant Neoplasm Lower Lip Inner Aspect",
      label: "Malignant Neoplasm Lower Lip Inner Aspect",
    },
    {
      value: "Malignant Neoplasm Lower Lip Vermilion Border",
      label: "Malignant Neoplasm Lower Lip Vermilion Border",
    },
    {
      value: "Malignant Neoplasm Lower Lobe Bronchus Lung",
      label: "Malignant Neoplasm Lower Lobe Bronchus Lung",
    },
    {
      value: "Malignant Neoplasm Lowerinner Quadrant Female Breast",
      label: "Malignant Neoplasm Lowerinner Quadrant Female Breast",
    },
    {
      value: "Malignant Neoplasm Lowerouter Quadrant Female Breast",
      label: "Malignant Neoplasm Lowerouter Quadrant Female Breast",
    },
    {
      value: "Malignant Neoplasm Lung",
      label: "Malignant Neoplasm Lung",
    },
    {
      value: "Malignant Neoplasm Lymph Node",
      label: "Malignant Neoplasm Lymph Node",
    },
    {
      value: "Malignant Neoplasm Main Bronchus",
      label: "Malignant Neoplasm Main Bronchus",
    },
    {
      value: "Malignant Neoplasm Major Salivary Glands",
      label: "Malignant Neoplasm Major Salivary Glands",
    },
    {
      value: "Malignant Neoplasm Male Genital Organ Site Unspecified",
      label: "Malignant Neoplasm Male Genital Organ Site Unspecified",
    },
    {
      value: "Malignant Neoplasm Mandible",
      label: "Malignant Neoplasm Mandible",
    },
    {
      value: "Malignant Neoplasm Maxillary Sinus",
      label: "Malignant Neoplasm Maxillary Sinus",
    },
    {
      value: "Malignant Neoplasm Meckels Diverticulum",
      label: "Malignant Neoplasm Meckels Diverticulum",
    },
    {
      value: "Malignant Neoplasm Mediastinum Part Unspecified",
      label: "Malignant Neoplasm Mediastinum Part Unspecified",
    },
    {
      value: "Malignant Neoplasm Meninges",
      label: "Malignant Neoplasm Meninges",
    },
    {
      value: "Malignant Neoplasm Middle Lobe Bronchus Lung",
      label: "Malignant Neoplasm Middle Lobe Bronchus Lung",
    },
    {
      value: "Malignant Neoplasm Mouth",
      label: "Malignant Neoplasm Mouth",
    },
    {
      value: "Malignant Neoplasm Mouth Unspecified",
      label: "Malignant Neoplasm Mouth Unspecified",
    },
    {
      value: "Malignant Neoplasm Nasal Cavities",
      label: "Malignant Neoplasm Nasal Cavities",
    },
    {
      value: "Malignant Neoplasm Nasal Cavities Middle Ear Accessory Sinuses",
      label: "Malignant Neoplasm Nasal Cavities Middle Ear Accessory Sinuses",
    },
    {
      value: "Malignant Neoplasm Nasopharynx",
      label: "Malignant Neoplasm Nasopharynx",
    },
    {
      value: "Malignant Neoplasm Nasopharynx Unspecified",
      label: "Malignant Neoplasm Nasopharynx Unspecified",
    },
    {
      value: "Malignant Neoplasm Nervous System Part Unspecified",
      label: "Malignant Neoplasm Nervous System Part Unspecified",
    },
    {
      value: "Malignant Neoplasm Nipple Areola Female Breast",
      label: "Malignant Neoplasm Nipple Areola Female Breast",
    },
    {
      value: "Malignant Neoplasm Occipital Lobe",
      label: "Malignant Neoplasm Occipital Lobe",
    },
    {
      value: "Malignant Neoplasm Oesophagus",
      label: "Malignant Neoplasm Oesophagus",
    },
    {
      value: "Malignant Neoplasm Of Accessory Sinus",
      label: "Malignant Neoplasm Of Accessory Sinus",
    },
    {
      value: "Malignant Neoplasm Of Accessory Sinuses",
      label: "Malignant Neoplasm Of Accessory Sinuses",
    },
    {
      value: "Malignant Neoplasm Of Adrenal Gland",
      label: "Malignant Neoplasm Of Adrenal Gland",
    },
    {
      value: "Malignant Neoplasm Of Anal Canal",
      label: "Malignant Neoplasm Of Anal Canal",
    },
    {
      value: "Malignant Neoplasm Of Anus And Anal Canal",
      label: "Malignant Neoplasm Of Anus And Anal Canal",
    },
    {
      value: "Malignant Neoplasm Of Back",
      label: "Malignant Neoplasm Of Back",
    },
    {
      value: "Malignant Neoplasm Of Base Of Tongue",
      label: "Malignant Neoplasm Of Base Of Tongue",
    },
    {
      value: "Malignant Neoplasm Of Biliary Tract",
      label: "Malignant Neoplasm Of Biliary Tract",
    },
    {
      value: "Malignant Neoplasm Of Bladder",
      label: "Malignant Neoplasm Of Bladder",
    },
    {
      value: "Malignant Neoplasm Of Bone",
      label: "Malignant Neoplasm Of Bone",
    },
    {
      value: "Malignant Neoplasm Of Bone And Articular Cartilage Of Limbs",
      label: "Malignant Neoplasm Of Bone And Articular Cartilage Of Limbs",
    },
    {
      value: "Malignant Neoplasm Of Bones Of Skull And Face",
      label: "Malignant Neoplasm Of Bones Of Skull And Face",
    },
    {
      value: "Malignant Neoplasm Of Brain",
      label: "Malignant Neoplasm Of Brain",
    },
    {
      value: "Malignant Neoplasm Of Breast",
      label: "Malignant Neoplasm Of Breast",
    },
    {
      value: "Malignant Neoplasm Of Bronchus And Lung",
      label: "Malignant Neoplasm Of Bronchus And Lung",
    },
    {
      value: "Malignant Neoplasm Of Caecum",
      label: "Malignant Neoplasm Of Caecum",
    },
    {
      value: "Malignant Neoplasm Of Cauda Equina",
      label: "Malignant Neoplasm Of Cauda Equina",
    },
    {
      value: "Malignant Neoplasm Of Central Nervous System",
      label: "Malignant Neoplasm Of Central Nervous System",
    },
    {
      value: "Malignant Neoplasm Of Cervical Part Of Oesophagus",
      label: "Malignant Neoplasm Of Cervical Part Of Oesophagus",
    },
    {
      value: "Malignant Neoplasm Of Cervix Uteri",
      label: "Malignant Neoplasm Of Cervix Uteri",
    },
    {
      value: "Malignant Neoplasm Of Choroid",
      label: "Malignant Neoplasm Of Choroid",
    },
    {
      value: "Malignant Neoplasm Of Coccyx",
      label: "Malignant Neoplasm Of Coccyx",
    },
    {
      value: "Malignant Neoplasm Of Colon",
      label: "Malignant Neoplasm Of Colon",
    },
    {
      value: "Malignant Neoplasm Of Connective And Soft Tissue",
      label: "Malignant Neoplasm Of Connective And Soft Tissue",
    },
    {
      value: "Malignant Neoplasm Of Connective Soft Tissue",
      label: "Malignant Neoplasm Of Connective Soft Tissue",
    },
    {
      value: "Malignant Neoplasm Of Endocrine Gland",
      label: "Malignant Neoplasm Of Endocrine Gland",
    },
    {
      value: "Malignant Neoplasm Of Endocrine Pancreas",
      label: "Malignant Neoplasm Of Endocrine Pancreas",
    },
    {
      value: "Malignant Neoplasm Of Endometrium",
      label: "Malignant Neoplasm Of Endometrium",
    },
    {
      value: "Malignant Neoplasm Of Eye",
      label: "Malignant Neoplasm Of Eye",
    },
    {
      value: "Malignant Neoplasm Of Floor Of Mouth",
      label: "Malignant Neoplasm Of Floor Of Mouth",
    },
    {
      value: "Malignant Neoplasm Of Gallbladder",
      label: "Malignant Neoplasm Of Gallbladder",
    },
    {
      value: "Malignant Neoplasm Of Gum",
      label: "Malignant Neoplasm Of Gum",
    },
    {
      value: "Malignant Neoplasm Of Heart",
      label: "Malignant Neoplasm Of Heart",
    },
    {
      value: "Malignant Neoplasm Of Heart, Mediastinum And Pleura",
      label: "Malignant Neoplasm Of Heart, Mediastinum And Pleura",
    },
    {
      value: "Malignant Neoplasm Of Hypopharynx",
      label: "Malignant Neoplasm Of Hypopharynx",
    },
    {
      value: "Malignant Neoplasm Of Intestine",
      label: "Malignant Neoplasm Of Intestine",
    },
    {
      value: "Malignant Neoplasm Of Jaw",
      label: "Malignant Neoplasm Of Jaw",
    },
    {
      value: "Malignant Neoplasm Of Kidney",
      label: "Malignant Neoplasm Of Kidney",
    },
    {
      value: "Malignant Neoplasm Of Larynx",
      label: "Malignant Neoplasm Of Larynx",
    },
    {
      value: "Malignant Neoplasm Of Leg",
      label: "Malignant Neoplasm Of Leg",
    },
    {
      value: "Malignant Neoplasm Of Lip",
      label: "Malignant Neoplasm Of Lip",
    },
    {
      value: "Malignant Neoplasm Of Liver And Intrahepatic Bile Ducts",
      label: "Malignant Neoplasm Of Liver And Intrahepatic Bile Ducts",
    },
    {
      value: "Malignant Neoplasm Of Lower Limb",
      label: "Malignant Neoplasm Of Lower Limb",
    },
    {
      value: "Malignant Neoplasm Of Lower Third Of Oesophagus",
      label: "Malignant Neoplasm Of Lower Third Of Oesophagus",
    },
    {
      value: "Malignant Neoplasm Of Lung",
      label: "Malignant Neoplasm Of Lung",
    },
    {
      value: "Malignant Neoplasm Of Lymph Nodes",
      label: "Malignant Neoplasm Of Lymph Nodes",
    },
    {
      value: "Malignant Neoplasm Of Male Genital Organ",
      label: "Malignant Neoplasm Of Male Genital Organ",
    },
    {
      value: "Malignant Neoplasm Of Mediastinum",
      label: "Malignant Neoplasm Of Mediastinum",
    },
    {
      value: "Malignant Neoplasm Of Meninges",
      label: "Malignant Neoplasm Of Meninges",
    },
    {
      value: "Malignant Neoplasm Of Mouth",
      label: "Malignant Neoplasm Of Mouth",
    },
    {
      value: "Malignant Neoplasm Of Nasal Cavity",
      label: "Malignant Neoplasm Of Nasal Cavity",
    },
    {
      value: "Malignant Neoplasm Of Nasal Cavity And Middle Ear",
      label: "Malignant Neoplasm Of Nasal Cavity And Middle Ear",
    },
    {
      value: "Malignant Neoplasm Of Nasopharynx",
      label: "Malignant Neoplasm Of Nasopharynx",
    },
    {
      value: "Malignant Neoplasm Of Neck",
      label: "Malignant Neoplasm Of Neck",
    },
    {
      value: "Malignant Neoplasm Of Oesophagus",
      label: "Malignant Neoplasm Of Oesophagus",
    },
    {
      value: "Malignant Neoplasm Of Olfactory Nerve",
      label: "Malignant Neoplasm Of Olfactory Nerve",
    },
    {
      value: "Malignant Neoplasm Of Optic Nerve",
      label: "Malignant Neoplasm Of Optic Nerve",
    },
    {
      value: "Malignant Neoplasm Of Other And Ill-Defined Digestive Organs",
      label: "Malignant Neoplasm Of Other And Ill-Defined Digestive Organs",
    },
    {
      value:
        "Malignant Neoplasm Of Other And Ill-Defined Sites In The Respiratory System And Intrathoracic Organs",
      label:
        "Malignant Neoplasm Of Other And Ill-Defined Sites In The Respiratory System And Intrathoracic Organs",
    },
    {
      value: "Malignant Neoplasm Of Other And Unspecified Parts Of Mouth",
      label: "Malignant Neoplasm Of Other And Unspecified Parts Of Mouth",
    },
    {
      value: "Malignant Neoplasm Of Other Connective And Soft Tissue",
      label: "Malignant Neoplasm Of Other Connective And Soft Tissue",
    },
    {
      value:
        "Malignant Neoplasm Of Other Endocrine Glands And Related Structures",
      label:
        "Malignant Neoplasm Of Other Endocrine Glands And Related Structures",
    },
    {
      value: "Malignant Neoplasm Of Ovary",
      label: "Malignant Neoplasm Of Ovary",
    },
    {
      value: "Malignant Neoplasm Of Palate",
      label: "Malignant Neoplasm Of Palate",
    },
    {
      value: "Malignant Neoplasm Of Pancreas",
      label: "Malignant Neoplasm Of Pancreas",
    },
    {
      value: "Malignant Neoplasm Of Pancreas Head",
      label: "Malignant Neoplasm Of Pancreas Head",
    },
    {
      value: "Malignant Neoplasm Of Parotid Gland",
      label: "Malignant Neoplasm Of Parotid Gland",
    },
    {
      value: "Malignant Neoplasm Of Pelvic Bones",
      label: "Malignant Neoplasm Of Pelvic Bones",
    },
    {
      value: "Malignant Neoplasm Of Piriform Sinus",
      label: "Malignant Neoplasm Of Piriform Sinus",
    },
    {
      value: "Malignant Neoplasm Of Placenta",
      label: "Malignant Neoplasm Of Placenta",
    },
    {
      value: "Malignant Neoplasm Of Prostate",
      label: "Malignant Neoplasm Of Prostate",
    },
    {
      value: "Malignant Neoplasm Of Rectosigmoid Junction",
      label: "Malignant Neoplasm Of Rectosigmoid Junction",
    },
    {
      value: "Malignant Neoplasm Of Rectum",
      label: "Malignant Neoplasm Of Rectum",
    },
    {
      value: "Malignant Neoplasm Of Retroperitoneum And Peritoneum",
      label: "Malignant Neoplasm Of Retroperitoneum And Peritoneum",
    },
    {
      value: "Malignant Neoplasm Of Rib",
      label: "Malignant Neoplasm Of Rib",
    },
    {
      value: "Malignant Neoplasm Of Salivary Gland",
      label: "Malignant Neoplasm Of Salivary Gland",
    },
    {
      value: "Malignant Neoplasm Of Scrotum",
      label: "Malignant Neoplasm Of Scrotum",
    },
    {
      value: "Malignant Neoplasm Of Skin",
      label: "Malignant Neoplasm Of Skin",
    },
    {
      value: "Malignant Neoplasm Of Skin Of Ear",
      label: "Malignant Neoplasm Of Skin Of Ear",
    },
    {
      value: "Malignant Neoplasm Of Skin Of Eyelid",
      label: "Malignant Neoplasm Of Skin Of Eyelid",
    },
    {
      value: "Malignant Neoplasm Of Small Intestine",
      label: "Malignant Neoplasm Of Small Intestine",
    },
    {
      value: "Malignant Neoplasm Of Soft Connective Tissue",
      label: "Malignant Neoplasm Of Soft Connective Tissue",
    },
    {
      value: "Malignant Neoplasm Of Sphenoidal Sinus",
      label: "Malignant Neoplasm Of Sphenoidal Sinus",
    },
    {
      value: "Malignant Neoplasm Of Spinal Column",
      label: "Malignant Neoplasm Of Spinal Column",
    },
    {
      value:
        "Malignant Neoplasm Of Spinal Cord, Cranial Nerves And Other Parts Of Central Nervous System",
      label:
        "Malignant Neoplasm Of Spinal Cord, Cranial Nerves And Other Parts Of Central Nervous System",
    },
    {
      value: "Malignant Neoplasm Of Stomach",
      label: "Malignant Neoplasm Of Stomach",
    },
    {
      value: "Malignant Neoplasm Of Testis",
      label: "Malignant Neoplasm Of Testis",
    },
    {
      value: "Malignant Neoplasm Of The Bladder",
      label: "Malignant Neoplasm Of The Bladder",
    },
    {
      value: "Malignant Neoplasm Of The Colon",
      label: "Malignant Neoplasm Of The Colon",
    },
    {
      value: "Malignant Neoplasm Of The Larynx",
      label: "Malignant Neoplasm Of The Larynx",
    },
    {
      value: "Malignant Neoplasm Of The Rectum",
      label: "Malignant Neoplasm Of The Rectum",
    },
    {
      value: "Malignant Neoplasm Of The Scrotum",
      label: "Malignant Neoplasm Of The Scrotum",
    },
    {
      value: "Malignant Neoplasm Of Thymus",
      label: "Malignant Neoplasm Of Thymus",
    },
    {
      value: "Malignant Neoplasm Of Thyroid Gland",
      label: "Malignant Neoplasm Of Thyroid Gland",
    },
    {
      value: "Malignant Neoplasm Of Tonsil",
      label: "Malignant Neoplasm Of Tonsil",
    },
    {
      value: "Malignant Neoplasm Of Tonsils",
      label: "Malignant Neoplasm Of Tonsils",
    },
    {
      value: "Malignant Neoplasm Of Trachea",
      label: "Malignant Neoplasm Of Trachea",
    },
    {
      value: "Malignant Neoplasm Of Ureter",
      label: "Malignant Neoplasm Of Ureter",
    },
    {
      value: "Malignant Neoplasm Of Urinary Organ",
      label: "Malignant Neoplasm Of Urinary Organ",
    },
    {
      value: "Malignant Neoplasm Of Uterus",
      label: "Malignant Neoplasm Of Uterus",
    },
    {
      value: "Malignant Neoplasm Of Vagina",
      label: "Malignant Neoplasm Of Vagina",
    },
    {
      value: "Malignant Neoplasm Of Vulva",
      label: "Malignant Neoplasm Of Vulva",
    },
    {
      value: "Malignant Neoplasm Oropharynx",
      label: "Malignant Neoplasm Oropharynx",
    },
    {
      value: "Malignant Neoplasm Oropharynx Unspecified",
      label: "Malignant Neoplasm Oropharynx Unspecified",
    },
    {
      value: "Malignant Neoplasm Other Accessory Sinuses",
      label: "Malignant Neoplasm Other Accessory Sinuses",
    },
    {
      value:
        "Malignant Neoplasm Other Illdefined Sites Within Digestive Organs Peritoneum",
      label:
        "Malignant Neoplasm Other Illdefined Sites Within Digestive Organs Peritoneum",
    },
    {
      value:
        "Malignant Neoplasm Other Illdefined Sites Within Respiratory System Intrathoracic Organs",
      label:
        "Malignant Neoplasm Other Illdefined Sites Within Respiratory System Intrathoracic Organs",
    },
    {
      value: "Malignant Neoplasm Other Major Salivary Glands",
      label: "Malignant Neoplasm Other Major Salivary Glands",
    },
    {
      value: "Malignant Neoplasm Other Parts Brain",
      label: "Malignant Neoplasm Other Parts Brain",
    },
    {
      value: "Malignant Neoplasm Other Parts Bronchus Lung",
      label: "Malignant Neoplasm Other Parts Bronchus Lung",
    },
    {
      value:
        "Malignant Neoplasm Other Sites Digestive System Intraabdominal Organs",
      label:
        "Malignant Neoplasm Other Sites Digestive System Intraabdominal Organs",
    },
    {
      value: "Malignant Neoplasm Other Sites Floor Mouth",
      label: "Malignant Neoplasm Other Sites Floor Mouth",
    },
    {
      value: "Malignant Neoplasm Other Sites Gum",
      label: "Malignant Neoplasm Other Sites Gum",
    },
    {
      value: "Malignant Neoplasm Other Sites Lip",
      label: "Malignant Neoplasm Other Sites Lip",
    },
    {
      value: "Malignant Neoplasm Other Sites Rectum Rectosigmoid Junction Anus",
      label: "Malignant Neoplasm Other Sites Rectum Rectosigmoid Junction Anus",
    },
    {
      value: "Malignant Neoplasm Other Sites Tongue",
      label: "Malignant Neoplasm Other Sites Tongue",
    },
    {
      value: "Malignant Neoplasm Other Sites Within Lip Oral Cavity",
      label: "Malignant Neoplasm Other Sites Within Lip Oral Cavity",
    },
    {
      value:
        "Malignant Neoplasm Other Sites Within Respirator System Intrathoracic Organs",
      label:
        "Malignant Neoplasm Other Sites Within Respirator System Intrathoracic Organs",
    },
    {
      value: "Malignant Neoplasm Other Specified Parts Mouth",
      label: "Malignant Neoplasm Other Specified Parts Mouth",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Bladder",
      label: "Malignant Neoplasm Other Specified Sites Bladder",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Body Uterus",
      label: "Malignant Neoplasm Other Specified Sites Body Uterus",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Cervix",
      label: "Malignant Neoplasm Other Specified Sites Cervix",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Female Breast",
      label: "Malignant Neoplasm Other Specified Sites Female Breast",
    },
    {
      value:
        "Malignant Neoplasm Other Specified Sites Gallbladder Extrahepatic Bile Ducts",
      label:
        "Malignant Neoplasm Other Specified Sites Gallbladder Extrahepatic Bile Ducts",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Hypopharynx",
      label: "Malignant Neoplasm Other Specified Sites Hypopharynx",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Large Intestine",
      label: "Malignant Neoplasm Other Specified Sites Large Intestine",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Larynx",
      label: "Malignant Neoplasm Other Specified Sites Larynx",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Male Genital Organs",
      label: "Malignant Neoplasm Other Specified Sites Male Genital Organs",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Nasopharynx",
      label: "Malignant Neoplasm Other Specified Sites Nasopharynx",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Oropharynx",
      label: "Malignant Neoplasm Other Specified Sites Oropharynx",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Pancreas",
      label: "Malignant Neoplasm Other Specified Sites Pancreas",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Small Intestine",
      label: "Malignant Neoplasm Other Specified Sites Small Intestine",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Stomach",
      label: "Malignant Neoplasm Other Specified Sites Stomach",
    },
    {
      value: "Malignant Neoplasm Other Specified Sites Urinary Organs",
      label: "Malignant Neoplasm Other Specified Sites Urinary Organs",
    },
    {
      value: "Malignant Neoplasm Other Unspecified Female Genital Organs",
      label: "Malignant Neoplasm Other Unspecified Female Genital Organs",
    },
    {
      value: "Malignant Neoplasm Other Unspecified Parts Mouth",
      label: "Malignant Neoplasm Other Unspecified Parts Mouth",
    },
    {
      value: "Malignant Neoplasm Ovary",
      label: "Malignant Neoplasm Ovary",
    },
    {
      value: "Malignant Neoplasm Palate Unspecified",
      label: "Malignant Neoplasm Palate Unspecified",
    },
    {
      value: "Malignant Neoplasm Pancreas",
      label: "Malignant Neoplasm Pancreas",
    },
    {
      value: "Malignant Neoplasm Pancreas Part Unspecified",
      label: "Malignant Neoplasm Pancreas Part Unspecified",
    },
    {
      value: "Malignant Neoplasm Pancreatic Duct",
      label: "Malignant Neoplasm Pancreatic Duct",
    },
    {
      value: "Malignant Neoplasm Parametrium",
      label: "Malignant Neoplasm Parametrium",
    },
    {
      value: "Malignant Neoplasm Parathyroid Gland",
      label: "Malignant Neoplasm Parathyroid Gland",
    },
    {
      value: "Malignant Neoplasm Paraurethral Glands",
      label: "Malignant Neoplasm Paraurethral Glands",
    },
    {
      value: "Malignant Neoplasm Parietal Lobe",
      label: "Malignant Neoplasm Parietal Lobe",
    },
    {
      value: "Malignant Neoplasm Parotid Gland",
      label: "Malignant Neoplasm Parotid Gland",
    },
    {
      value: "Malignant Neoplasm Pelvic Bones Sacrum Coccyx",
      label: "Malignant Neoplasm Pelvic Bones Sacrum Coccyx",
    },
    {
      value: "Malignant Neoplasm Penis",
      label: "Malignant Neoplasm Penis",
    },
    {
      value: "Malignant Neoplasm Penis Part Unspecified",
      label: "Malignant Neoplasm Penis Part Unspecified",
    },
    {
      value: "Malignant Neoplasm Peritoneum Unspecified",
      label: "Malignant Neoplasm Peritoneum Unspecified",
    },
    {
      value: "Malignant Neoplasm Pharynx",
      label: "Malignant Neoplasm Pharynx",
    },
    {
      value: "Malignant Neoplasm Pharynx Unspecified",
      label: "Malignant Neoplasm Pharynx Unspecified",
    },
    {
      value: "Malignant Neoplasm Pineal Gland",
      label: "Malignant Neoplasm Pineal Gland",
    },
    {
      value: "Malignant Neoplasm Pituitary Gland Craniopharyngeal Duct",
      label: "Malignant Neoplasm Pituitary Gland Craniopharyngeal Duct",
    },
    {
      value: "Malignant Neoplasm Postcricoid Region Hypopharynx",
      label: "Malignant Neoplasm Postcricoid Region Hypopharynx",
    },
    {
      value: "Malignant Neoplasm Posterior Hypopharyngeal Wall",
      label: "Malignant Neoplasm Posterior Hypopharyngeal Wall",
    },
    {
      value: "Malignant Neoplasm Posterior Mediastinum",
      label: "Malignant Neoplasm Posterior Mediastinum",
    },
    {
      value: "Malignant Neoplasm Posterior Wall Nasopharynx",
      label: "Malignant Neoplasm Posterior Wall Nasopharynx",
    },
    {
      value: "Malignant Neoplasm Posterior Wall Oropharynx",
      label: "Malignant Neoplasm Posterior Wall Oropharynx",
    },
    {
      value: "Malignant Neoplasm Posterior Wall Urinary Bladder",
      label: "Malignant Neoplasm Posterior Wall Urinary Bladder",
    },
    {
      value: "Malignant Neoplasm Prepuce",
      label: "Malignant Neoplasm Prepuce",
    },
    {
      value: "Malignant Neoplasm Prostate",
      label: "Malignant Neoplasm Prostate",
    },
    {
      value: "Malignant Neoplasm Pyloric Antrum",
      label: "Malignant Neoplasm Pyloric Antrum",
    },
    {
      value: "Malignant Neoplasm Pylorus",
      label: "Malignant Neoplasm Pylorus",
    },
    {
      value: "Malignant Neoplasm Pyriform Sinus",
      label: "Malignant Neoplasm Pyriform Sinus",
    },
    {
      value: "Malignant Neoplasm Rectosigmoid Junction",
      label: "Malignant Neoplasm Rectosigmoid Junction",
    },
    {
      value: "Malignant Neoplasm Rectum",
      label: "Malignant Neoplasm Rectum",
    },
    {
      value: "Malignant Neoplasm Rectum Rectosigmoid Junction Anus",
      label: "Malignant Neoplasm Rectum Rectosigmoid Junction Anus",
    },
    {
      value: "Malignant Neoplasm Renal Pelvis",
      label: "Malignant Neoplasm Renal Pelvis",
    },
    {
      value: "Malignant Neoplasm Retromolar Area",
      label: "Malignant Neoplasm Retromolar Area",
    },
    {
      value: "Malignant Neoplasm Retroperitoneum",
      label: "Malignant Neoplasm Retroperitoneum",
    },
    {
      value: "Malignant Neoplasm Retroperitoneum Peritoneum",
      label: "Malignant Neoplasm Retroperitoneum Peritoneum",
    },
    {
      value: "Malignant Neoplasm Ribs Sternum Clavicle",
      label: "Malignant Neoplasm Ribs Sternum Clavicle",
    },
    {
      value: "Malignant Neoplasm Round Ligament Uterus",
      label: "Malignant Neoplasm Round Ligament Uterus",
    },
    {
      value: "Malignant Neoplasm Salivary Gland",
      label: "Malignant Neoplasm Salivary Gland",
    },
    {
      value: "Malignant Neoplasm Salivary Gland Unspecified",
      label: "Malignant Neoplasm Salivary Gland Unspecified",
    },
    {
      value: "Malignant Neoplasm Scapula Long Bones Of Arm",
      label: "Malignant Neoplasm Scapula Long Bones Of Arm",
    },
    {
      value: "Malignant Neoplasm Scapula Long Bones Upper Limb",
      label: "Malignant Neoplasm Scapula Long Bones Upper Limb",
    },
    {
      value: "Malignant Neoplasm Scrotum",
      label: "Malignant Neoplasm Scrotum",
    },
    {
      value: "Malignant Neoplasm Short Bones Lower Limb",
      label: "Malignant Neoplasm Short Bones Lower Limb",
    },
    {
      value: "Malignant Neoplasm Short Bones Upper Limb",
      label: "Malignant Neoplasm Short Bones Upper Limb",
    },
    {
      value: "Malignant Neoplasm Sigmoid Colon",
      label: "Malignant Neoplasm Sigmoid Colon",
    },
    {
      value: "Malignant Neoplasm Skin",
      label: "Malignant Neoplasm Skin",
    },
    {
      value: "Malignant Neoplasm Small Intestine Including Duodenum",
      label: "Malignant Neoplasm Small Intestine Including Duodenum",
    },
    {
      value: "Malignant Neoplasm Soft Connective Tissue",
      label: "Malignant Neoplasm Soft Connective Tissue",
    },
    {
      value: "Malignant Neoplasm Soft Palate",
      label: "Malignant Neoplasm Soft Palate",
    },
    {
      value: "Malignant Neoplasm Specified Parts Peritoneum",
      label: "Malignant Neoplasm Specified Parts Peritoneum",
    },
    {
      value: "Malignant Neoplasm Spermatic Cord",
      label: "Malignant Neoplasm Spermatic Cord",
    },
    {
      value: "Malignant Neoplasm Sphenoidal Sinus",
      label: "Malignant Neoplasm Sphenoidal Sinus",
    },
    {
      value: "Malignant Neoplasm Spinal Cord",
      label: "Malignant Neoplasm Spinal Cord",
    },
    {
      value: "Malignant Neoplasm Spinal Meninges",
      label: "Malignant Neoplasm Spinal Meninges",
    },
    {
      value: "Malignant Neoplasm Spleen Not Elsewhere Classified",
      label: "Malignant Neoplasm Spleen Not Elsewhere Classified",
    },
    {
      value: "Malignant Neoplasm Splenic Flexure",
      label: "Malignant Neoplasm Splenic Flexure",
    },
    {
      value: "Malignant Neoplasm Stomach",
      label: "Malignant Neoplasm Stomach",
    },
    {
      value: "Malignant Neoplasm Stomach Unspecified",
      label: "Malignant Neoplasm Stomach Unspecified",
    },
    {
      value: "Malignant Neoplasm Subglottis",
      label: "Malignant Neoplasm Subglottis",
    },
    {
      value: "Malignant Neoplasm Sublingual Gland",
      label: "Malignant Neoplasm Sublingual Gland",
    },
    {
      value: "Malignant Neoplasm Submandibular Gland",
      label: "Malignant Neoplasm Submandibular Gland",
    },
    {
      value: "Malignant Neoplasm Superior Wall Nasopharynx",
      label: "Malignant Neoplasm Superior Wall Nasopharynx",
    },
    {
      value: "Malignant Neoplasm Supraglottis",
      label: "Malignant Neoplasm Supraglottis",
    },
    {
      value: "Malignant Neoplasm Tail Pancreas",
      label: "Malignant Neoplasm Tail Pancreas",
    },
    {
      value: "Malignant Neoplasm Temporal Lobe",
      label: "Malignant Neoplasm Temporal Lobe",
    },
    {
      value: "Malignant Neoplasm Testis",
      label: "Malignant Neoplasm Testis",
    },
    {
      value: "Malignant Neoplasm Thymus",
      label: "Malignant Neoplasm Thymus",
    },
    {
      value: "Malignant Neoplasm Tip Lateral Border Tongue",
      label: "Malignant Neoplasm Tip Lateral Border Tongue",
    },
    {
      value: "Malignant Neoplasm Tongue",
      label: "Malignant Neoplasm Tongue",
    },
    {
      value: "Malignant Neoplasm Tongue Unspecified",
      label: "Malignant Neoplasm Tongue Unspecified",
    },
    {
      value: "Malignant Neoplasm Tonsil",
      label: "Malignant Neoplasm Tonsil",
    },
    {
      value: "Malignant Neoplasm Tonsillar Fossa",
      label: "Malignant Neoplasm Tonsillar Fossa",
    },
    {
      value: "Malignant Neoplasm Tonsillar Pillars Anterior Posterior",
      label: "Malignant Neoplasm Tonsillar Pillars Anterior Posterior",
    },
    {
      value: "Malignant Neoplasm Trachea",
      label: "Malignant Neoplasm Trachea",
    },
    {
      value: "Malignant Neoplasm Trachea Bronchus Lung",
      label: "Malignant Neoplasm Trachea Bronchus Lung",
    },
    {
      value: "Malignant Neoplasm Transverse Colon",
      label: "Malignant Neoplasm Transverse Colon",
    },
    {
      value: "Malignant Neoplasm Trigone Urinary Bladder",
      label: "Malignant Neoplasm Trigone Urinary Bladder",
    },
    {
      value: "Malignant Neoplasm Undescended Testis",
      label: "Malignant Neoplasm Undescended Testis",
    },
    {
      value: "Malignant Neoplasm Upper Gum",
      label: "Malignant Neoplasm Upper Gum",
    },
    {
      value: "Malignant Neoplasm Upper Limb",
      label: "Malignant Neoplasm Upper Limb",
    },
    {
      value: "Malignant Neoplasm Upper Lip Inner Aspect",
      label: "Malignant Neoplasm Upper Lip Inner Aspect",
    },
    {
      value: "Malignant Neoplasm Upper Lip Vermilion Border",
      label: "Malignant Neoplasm Upper Lip Vermilion Border",
    },
    {
      value: "Malignant Neoplasm Upper Lobe Bronchus Lung",
      label: "Malignant Neoplasm Upper Lobe Bronchus Lung",
    },
    {
      value: "Malignant Neoplasm Upper Respiratory Tract Part Unspecified",
      label: "Malignant Neoplasm Upper Respiratory Tract Part Unspecified",
    },
    {
      value: "Malignant Neoplasm Upperinner Quadrant Female Breast",
      label: "Malignant Neoplasm Upperinner Quadrant Female Breast",
    },
    {
      value: "Malignant Neoplasm Upperouter Quadrant Female Breast",
      label: "Malignant Neoplasm Upperouter Quadrant Female Breast",
    },
    {
      value: "Malignant Neoplasm Urachus",
      label: "Malignant Neoplasm Urachus",
    },
    {
      value: "Malignant Neoplasm Ureter",
      label: "Malignant Neoplasm Ureter",
    },
    {
      value: "Malignant Neoplasm Ureteric Orifice",
      label: "Malignant Neoplasm Ureteric Orifice",
    },
    {
      value: "Malignant Neoplasm Urethra",
      label: "Malignant Neoplasm Urethra",
    },
    {
      value: "Malignant Neoplasm Urinary Organ Site Unspecified",
      label: "Malignant Neoplasm Urinary Organ Site Unspecified",
    },
    {
      value: "Malignant Neoplasm Uterine Adnexa",
      label: "Malignant Neoplasm Uterine Adnexa",
    },
    {
      value: "Malignant Neoplasm Uterine Adnexa Unspecified",
      label: "Malignant Neoplasm Uterine Adnexa Unspecified",
    },
    {
      value: "Malignant Neoplasm Uterus",
      label: "Malignant Neoplasm Uterus",
    },
    {
      value: "Malignant Neoplasm Uvula",
      label: "Malignant Neoplasm Uvula",
    },
    {
      value: "Malignant Neoplasm Vallecula Epiglottica",
      label: "Malignant Neoplasm Vallecula Epiglottica",
    },
    {
      value: "Malignant Neoplasm Ventral Surface Tongue",
      label: "Malignant Neoplasm Ventral Surface Tongue",
    },
    {
      value: "Malignant Neoplasm Ventricles",
      label: "Malignant Neoplasm Ventricles",
    },
    {
      value: "Malignant Neoplasm Vertebral Column",
      label: "Malignant Neoplasm Vertebral Column",
    },
    {
      value: "Malignant Neoplasm Vertebral Column Excluding Sacrum Coccyx",
      label: "Malignant Neoplasm Vertebral Column Excluding Sacrum Coccyx",
    },
    {
      value: "Malignant Neoplasm Vestibule Mouth",
      label: "Malignant Neoplasm Vestibule Mouth",
    },
    {
      value: "Malignant Neoplasm Vulva Unspecified",
      label: "Malignant Neoplasm Vulva Unspecified",
    },
    {
      value: "Malignant Neoplasm Waldeyers Ring",
      label: "Malignant Neoplasm Waldeyers Ring",
    },
    {
      value: "Malignant Neoplasm Without Specification Site",
      label: "Malignant Neoplasm Without Specification Site",
    },
    {
      value: "Malignant Plasma Cell Tumour",
      label: "Malignant Plasma Cell Tumour",
    },
    {
      value: "Malignant Tumor Of Acoustic Nerve",
      label: "Malignant Tumor Of Acoustic Nerve",
    },
    {
      value: "Malignant Tumor Of Brain",
      label: "Malignant Tumor Of Brain",
    },
    {
      value: "Malignant Tumor Of Eye",
      label: "Malignant Tumor Of Eye",
    },
    {
      value: "Malignant Tumor Of Nasal Cavity",
      label: "Malignant Tumor Of Nasal Cavity",
    },
    {
      value: "Malignant Tumor Of Pituitary Gland",
      label: "Malignant Tumor Of Pituitary Gland",
    },
    {
      value: "Malignant Tumor Of Salivary Glands",
      label: "Malignant Tumor Of Salivary Glands",
    },
    {
      value: "Malignant Tumor Of Temporal Lobe",
      label: "Malignant Tumor Of Temporal Lobe",
    },
    {
      value: "Malignant Tumor Of Ureteric Orifice",
      label: "Malignant Tumor Of Ureteric Orifice",
    },
    {
      value: "Malignant Tumour Of Bladder",
      label: "Malignant Tumour Of Bladder",
    },
    {
      value: "Malignant Tumour Of Ciliary Body",
      label: "Malignant Tumour Of Ciliary Body",
    },
    {
      value: "Malignant Tumour Of Colon",
      label: "Malignant Tumour Of Colon",
    },
    {
      value: "Malignant Tumour Of Common Bile Duct",
      label: "Malignant Tumour Of Common Bile Duct",
    },
    {
      value: "Malignant Tumour Of Endocrine Gland",
      label: "Malignant Tumour Of Endocrine Gland",
    },
    {
      value: "Malignant Tumour Of Hard Palate",
      label: "Malignant Tumour Of Hard Palate",
    },
    {
      value: "Malignant Tumour Of Heart",
      label: "Malignant Tumour Of Heart",
    },
    {
      value: "Malignant Tumour Of Intestine",
      label: "Malignant Tumour Of Intestine",
    },
    {
      value: "Malignant Tumour Of Larynx",
      label: "Malignant Tumour Of Larynx",
    },
    {
      value: "Malignant Tumour Of Middle Ear",
      label: "Malignant Tumour Of Middle Ear",
    },
    {
      value: "Malignant Tumour Of Neck",
      label: "Malignant Tumour Of Neck",
    },
    {
      value: "Malignant Tumour Of Oesophagus",
      label: "Malignant Tumour Of Oesophagus",
    },
    {
      value: "Malignant Tumour Of Ovary",
      label: "Malignant Tumour Of Ovary",
    },
    {
      value: "Malignant Tumour Of Pancreas",
      label: "Malignant Tumour Of Pancreas",
    },
    {
      value: "Malignant Tumour Of Pelvic Bones",
      label: "Malignant Tumour Of Pelvic Bones",
    },
    {
      value: "Malignant Tumour Of Pharynx",
      label: "Malignant Tumour Of Pharynx",
    },
    {
      value: "Malignant Tumour Of Rectum",
      label: "Malignant Tumour Of Rectum",
    },
    {
      value: "Malignant Tumour Of Soft Palate",
      label: "Malignant Tumour Of Soft Palate",
    },
    {
      value: "Malignant Tumour On Lung",
      label: "Malignant Tumour On Lung",
    },
    {
      value: "Mammary Cancer",
      label: "Mammary Cancer",
    },
    {
      value: "Mammary Neoplasm Malignant",
      label: "Mammary Neoplasm Malignant",
    },
    {
      value: "Mandible Cancer",
      label: "Mandible Cancer",
    },
    {
      value: "Mast Cell Leukaemia",
      label: "Mast Cell Leukaemia",
    },
    {
      value: "Mast Cell Sarcoma",
      label: "Mast Cell Sarcoma",
    },
    {
      value: "Meckels Diverticulum",
      label: "Meckels Diverticulum",
    },
    {
      value: "Mediastinal Cancer",
      label: "Mediastinal Cancer",
    },
    {
      value: "Mediastinum Cancer",
      label: "Mediastinum Cancer",
    },
    {
      value: "Melanoma Skin",
      label: "Melanoma Skin",
    },
    {
      value: "Melanoma Skin Site Unspecified",
      label: "Melanoma Skin Site Unspecified",
    },
    {
      value: "Meninges Cancer",
      label: "Meninges Cancer",
    },
    {
      value: "Meninges Neoplasm Malignant",
      label: "Meninges Neoplasm Malignant",
    },
    {
      value: "Meningioma",
      label: "Meningioma",
    },
    {
      value: "Mesothelioma",
      label: "Mesothelioma",
    },
    {
      value: "Monocytic Leukaemia",
      label: "Monocytic Leukaemia",
    },
    {
      value: "Mouth Cancer",
      label: "Mouth Cancer",
    },
    {
      value: "Mouth Malignant Neoplasm",
      label: "Mouth Malignant Neoplasm",
    },
    {
      value: "Mucinous Carcinoma",
      label: "Mucinous Carcinoma",
    },
    {
      value: "Multiple Myeloma",
      label: "Multiple Myeloma",
    },
    {
      value: "Muscle Cancer",
      label: "Muscle Cancer",
    },
    {
      value: "Muscle Neoplasm Malignant",
      label: "Muscle Neoplasm Malignant",
    },
    {
      value: "Mycosis Fungoides",
      label: "Mycosis Fungoides",
    },
    {
      value: "Myelodysplasia",
      label: "Myelodysplasia",
    },
    {
      value: "Myelodysplasic Syndrome",
      label: "Myelodysplasic Syndrome",
    },
    {
      value: "Myelofibrosis",
      label: "Myelofibrosis",
    },
    {
      value: "Myeloid Leukaemia",
      label: "Myeloid Leukaemia",
    },
    {
      value: "Myeloid Sarcoma",
      label: "Myeloid Sarcoma",
    },
    {
      value: "Myeloid Sarcomatosis",
      label: "Myeloid Sarcomatosis",
    },
    {
      value: "Myeloma",
      label: "Myeloma",
    },
    {
      value: "Myeloma Multiple",
      label: "Myeloma Multiple",
    },
    {
      value: "Myelomatosis",
      label: "Myelomatosis",
    },
    {
      value: "Myeloproliferative Disease",
      label: "Myeloproliferative Disease",
    },
    {
      value: "Nasal Cavity And Middle Ear Malignant Neoplasm",
      label: "Nasal Cavity And Middle Ear Malignant Neoplasm",
    },
    {
      value: "Nasalbone Cancer",
      label: "Nasalbone Cancer",
    },
    {
      value: "Nasalbone Neoplasm Malignant",
      label: "Nasalbone Neoplasm Malignant",
    },
    {
      value: "Nasopharyngeal Cancer",
      label: "Nasopharyngeal Cancer",
    },
    {
      value: "Nasopharynx Cancer",
      label: "Nasopharynx Cancer",
    },
    {
      value: "Nasopharynx Malignant Neoplasm",
      label: "Nasopharynx Malignant Neoplasm",
    },
    {
      value: "Neck Cancer",
      label: "Neck Cancer",
    },
    {
      value: "Neck Neoplasm Malignant",
      label: "Neck Neoplasm Malignant",
    },
    {
      value: "Neoplasm Unknown",
      label: "Neoplasm Unknown",
    },
    {
      value: "Neoplasm Unspecified Nature Bone Soft Tissue Skin",
      label: "Neoplasm Unspecified Nature Bone Soft Tissue Skin",
    },
    {
      value: "Neoplasm Unspecified Nature Digestive System",
      label: "Neoplasm Unspecified Nature Digestive System",
    },
    {
      value: "Neoplasm Unspecified Nature Other Genitourinary Organs",
      label: "Neoplasm Unspecified Nature Other Genitourinary Organs",
    },
    {
      value: "Neoplasm Unspecified Nature Respiratory System",
      label: "Neoplasm Unspecified Nature Respiratory System",
    },
    {
      value: "Nerve Cancer",
      label: "Nerve Cancer",
    },
    {
      value: "Nerve Neoplasm Malignant",
      label: "Nerve Neoplasm Malignant",
    },
    {
      value: "Neurionoma",
      label: "Neurionoma",
    },
    {
      value: "NHL",
      label: "NHL",
    },
    {
      value: "Nodular Lymphoma",
      label: "Nodular Lymphoma",
    },
    {
      value: "Nodular Sclerosis",
      label: "Nodular Sclerosis",
    },
    {
      value: "Non Hodgkins Lymphoma",
      label: "Non Hodgkins Lymphoma",
    },
    {
      value: "Non-Hodgkins Lymphoma",
      label: "Non-Hodgkins Lymphoma",
    },
    {
      value: "Nonhodgkins Lymphoma",
      label: "Nonhodgkins Lymphoma",
    },
    {
      value: "Nose Cancer",
      label: "Nose Cancer",
    },
    {
      value: "Nose Tumour",
      label: "Nose Tumour",
    },
    {
      value: "Occipital Lobe Cancer",
      label: "Occipital Lobe Cancer",
    },
    {
      value: "Oesophageal Cancer",
      label: "Oesophageal Cancer",
    },
    {
      value: "Oesophagus Cancer",
      label: "Oesophagus Cancer",
    },
    {
      value: "Osteo Carcinoma",
      label: "Osteo Carcinoma",
    },
    {
      value: "Osteoblastoma",
      label: "Osteoblastoma",
    },
    {
      value: "Osteogenic Sarcoma",
      label: "Osteogenic Sarcoma",
    },
    {
      value: "Osteoid Osteoma",
      label: "Osteoid Osteoma",
    },
    {
      value: "Other Hodgkins Disease",
      label: "Other Hodgkins Disease",
    },
    {
      value: "Other Malignant Neoplasm Scalp Skin Neck",
      label: "Other Malignant Neoplasm Scalp Skin Neck",
    },
    {
      value: "Other Malignant Neoplasm Skin",
      label: "Other Malignant Neoplasm Skin",
    },
    {
      value: "Other Malignant Neoplasms Lymphoid Histiocytic Tissue",
      label: "Other Malignant Neoplasms Lymphoid Histiocytic Tissue",
    },
    {
      value: "Other Malignant Neoplasms Of Skin",
      label: "Other Malignant Neoplasms Of Skin",
    },
    {
      value: "Ovarian Cancer",
      label: "Ovarian Cancer",
    },
    {
      value: "Ovary Cancer",
      label: "Ovary Cancer",
    },
    {
      value: "Ovary Malignant Neoplasm",
      label: "Ovary Malignant Neoplasm",
    },
    {
      value: "Ovary Neoplasm Malignant",
      label: "Ovary Neoplasm Malignant",
    },
    {
      value: "Pancreas Cancer",
      label: "Pancreas Cancer",
    },
    {
      value: "Pancreas Malignant Neoplasm",
      label: "Pancreas Malignant Neoplasm",
    },
    {
      value: "Pancreatic Cancer",
      label: "Pancreatic Cancer",
    },
    {
      value: "Papillary Breast Cancer",
      label: "Papillary Breast Cancer",
    },
    {
      value: "Papillary Cell Ca Thyroid",
      label: "Papillary Cell Ca Thyroid",
    },
    {
      value: "Papillary Cell Thyroid Cancer",
      label: "Papillary Cell Thyroid Cancer",
    },
    {
      value: "Paranasal Sinus Neoplasm Unknown",
      label: "Paranasal Sinus Neoplasm Unknown",
    },
    {
      value: "Parathyroid Gland Cancer",
      label: "Parathyroid Gland Cancer",
    },
    {
      value: "Parietal Lobe Cancer",
      label: "Parietal Lobe Cancer",
    },
    {
      value: "Parotid Gland Cancer",
      label: "Parotid Gland Cancer",
    },
    {
      value: "Pelvic Cancer",
      label: "Pelvic Cancer",
    },
    {
      value: "Pelvis Cancer",
      label: "Pelvis Cancer",
    },
    {
      value: "Pelvis Neoplasm Malignant",
      label: "Pelvis Neoplasm Malignant",
    },
    {
      value: "Pelvis Tumour",
      label: "Pelvis Tumour",
    },
    {
      value: "Penile Cancer",
      label: "Penile Cancer",
    },
    {
      value: "Penis Cancer",
      label: "Penis Cancer",
    },
    {
      value: "Penis Malignant Neoplasm",
      label: "Penis Malignant Neoplasm",
    },
    {
      value: "Perineum Cancer",
      label: "Perineum Cancer",
    },
    {
      value: "Perineum Neoplasm Malignant",
      label: "Perineum Neoplasm Malignant",
    },
    {
      value: "Peripheral T-Cell Lymphoma",
      label: "Peripheral T-Cell Lymphoma",
    },
    {
      value: "Peritoneal Cancer",
      label: "Peritoneal Cancer",
    },
    {
      value: "Peritoneum Cancer",
      label: "Peritoneum Cancer",
    },
    {
      value: "Pharyngeal Cancer",
      label: "Pharyngeal Cancer",
    },
    {
      value: "Pharynx Cancer",
      label: "Pharynx Cancer",
    },
    {
      value: "Placenta Cancer",
      label: "Placenta Cancer",
    },
    {
      value: "Placenta Carcinoma",
      label: "Placenta Carcinoma",
    },
    {
      value: "Placenta Malignant Neoplasm",
      label: "Placenta Malignant Neoplasm",
    },
    {
      value: "Placenta Neoplasm Malignant",
      label: "Placenta Neoplasm Malignant",
    },
    {
      value: "Plasma Cell Leukaemia",
      label: "Plasma Cell Leukaemia",
    },
    {
      value: "Plasmacytoma",
      label: "Plasmacytoma",
    },
    {
      value: "Pleura Carcinoma",
      label: "Pleura Carcinoma",
    },
    {
      value: "Polycythaemia Rubra Vera",
      label: "Polycythaemia Rubra Vera",
    },
    {
      value: "Polycythaemia Vera",
      label: "Polycythaemia Vera",
    },
    {
      value: "Polycythemia Rubra Vera",
      label: "Polycythemia Rubra Vera",
    },
    {
      value: "Polyposis Coli",
      label: "Polyposis Coli",
    },
    {
      value: "Primary Idiopathic Myelofibrosis",
      label: "Primary Idiopathic Myelofibrosis",
    },
    {
      value: "Prolymphocytic Leukaemia",
      label: "Prolymphocytic Leukaemia",
    },
    {
      value: "Prostate Cancer",
      label: "Prostate Cancer",
    },
    {
      value: "Prostate Neoplasm",
      label: "Prostate Neoplasm",
    },
    {
      value: "Prostate Neoplasm Malignant",
      label: "Prostate Neoplasm Malignant",
    },
    {
      value: "Prostate Neoplasm Unknown",
      label: "Prostate Neoplasm Unknown",
    },
    {
      value: "Prostatic Ca",
      label: "Prostatic Ca",
    },
    {
      value: "Prostatic Cancer",
      label: "Prostatic Cancer",
    },
    {
      value: "Pyloric Cancer",
      label: "Pyloric Cancer",
    },
    {
      value: "Pylorus Cancer",
      label: "Pylorus Cancer",
    },
    {
      value: "Rectal Cancer",
      label: "Rectal Cancer",
    },
    {
      value: "Rectosigmoid Junction Malignant Neoplasm",
      label: "Rectosigmoid Junction Malignant Neoplasm",
    },
    {
      value: "Refractory Anaemia",
      label: "Refractory Anaemia",
    },
    {
      value: "Refractory Anaemia With Excess Blasts",
      label: "Refractory Anaemia With Excess Blasts",
    },
    {
      value: "Refractory Anaemia With Ringed Sideroblasts",
      label: "Refractory Anaemia With Ringed Sideroblasts",
    },
    {
      value: "Renal Cancer",
      label: "Renal Cancer",
    },
    {
      value: "Renal Pelvis Cancer",
      label: "Renal Pelvis Cancer",
    },
    {
      value: "Respiratory Tract Cancer",
      label: "Respiratory Tract Cancer",
    },
    {
      value: "Respiratory Tract Neoplasm Malignant",
      label: "Respiratory Tract Neoplasm Malignant",
    },
    {
      value: "Reticulum Cell Sarcoma",
      label: "Reticulum Cell Sarcoma",
    },
    {
      value: "Retroperitoneal Cancer",
      label: "Retroperitoneal Cancer",
    },
    {
      value: "Retroperitoneum Cancer",
      label: "Retroperitoneum Cancer",
    },
    {
      value: "Rib Cancer",
      label: "Rib Cancer",
    },
    {
      value: "Rib Neoplasm Malignant",
      label: "Rib Neoplasm Malignant",
    },
    {
      value: "Sacrum Cancer",
      label: "Sacrum Cancer",
    },
    {
      value: "Sacrum Neoplasm Malignant",
      label: "Sacrum Neoplasm Malignant",
    },
    {
      value: "Salivary Gland Cancer",
      label: "Salivary Gland Cancer",
    },
    {
      value: "Sarcomatosis",
      label: "Sarcomatosis",
    },
    {
      value: "Scaphoid Bone Cancer",
      label: "Scaphoid Bone Cancer",
    },
    {
      value: "Scaphoid Bone Neoplasm Malignant",
      label: "Scaphoid Bone Neoplasm Malignant",
    },
    {
      value: "Scaphoidbone Neoplasm Malignant",
      label: "Scaphoidbone Neoplasm Malignant",
    },
    {
      value: "SCC Larynx",
      label: "SCC Larynx",
    },
    {
      value: "SCC Lung",
      label: "SCC Lung",
    },
    {
      value: "SCC Oral",
      label: "SCC Oral",
    },
    {
      value: "SCC Other",
      label: "SCC Other",
    },
    {
      value: "SCC Skin",
      label: "SCC Skin",
    },
    {
      value: "Scrotal Ca",
      label: "Scrotal Ca",
    },
    {
      value: "Scrotum Cancer",
      label: "Scrotum Cancer",
    },
    {
      value: "Secondary Bone Cancer",
      label: "Secondary Bone Cancer",
    },
    {
      value: "Secondary Cancer",
      label: "Secondary Cancer",
    },
    {
      value: "Secondary Cancer Of Bone",
      label: "Secondary Cancer Of Bone",
    },
    {
      value: "Secondary Cancer Of Intrathoracic Lymph Nodes",
      label: "Secondary Cancer Of Intrathoracic Lymph Nodes",
    },
    {
      value: "Secondary Cancer Of Liver",
      label: "Secondary Cancer Of Liver",
    },
    {
      value: "Secondary Cancer Of Lung",
      label: "Secondary Cancer Of Lung",
    },
    {
      value: "Secondary Cancer Of Lymph Nodes Of Neck",
      label: "Secondary Cancer Of Lymph Nodes Of Neck",
    },
    {
      value: "Secondary Liver Cancer",
      label: "Secondary Liver Cancer",
    },
    {
      value: "Secondary Lymph Node Cancer",
      label: "Secondary Lymph Node Cancer",
    },
    {
      value: "Secondary Malignant Neoplasm Adrenal Gland",
      label: "Secondary Malignant Neoplasm Adrenal Gland",
    },
    {
      value: "Secondary Malignant Neoplasm Brain Spinal Cord",
      label: "Secondary Malignant Neoplasm Brain Spinal Cord",
    },
    {
      value: "Secondary Malignant Neoplasm Intestine Rectum",
      label: "Secondary Malignant Neoplasm Intestine Rectum",
    },
    {
      value: "Secondary Malignant Neoplasm Kidney",
      label: "Secondary Malignant Neoplasm Kidney",
    },
    {
      value: "Secondary Malignant Neoplasm Lung",
      label: "Secondary Malignant Neoplasm Lung",
    },
    {
      value: "Secondary Malignant Neoplasm Mediastinum",
      label: "Secondary Malignant Neoplasm Mediastinum",
    },
    {
      value: "Secondary Malignant Neoplasm Of Bladder",
      label: "Secondary Malignant Neoplasm Of Bladder",
    },
    {
      value: "Secondary Malignant Neoplasm Of Lymph Nodes",
      label: "Secondary Malignant Neoplasm Of Lymph Nodes",
    },
    {
      value: "Secondary Malignant Neoplasm Other Digestive Organs Spleen",
      label: "Secondary Malignant Neoplasm Other Digestive Organs Spleen",
    },
    {
      value: "Secondary Malignant Neoplasm Other Parts Nervous System",
      label: "Secondary Malignant Neoplasm Other Parts Nervous System",
    },
    {
      value: "Secondary Malignant Neoplasm Other Respiratory Organs",
      label: "Secondary Malignant Neoplasm Other Respiratory Organs",
    },
    {
      value: "Secondary Malignant Neoplasm Other Specified Sites",
      label: "Secondary Malignant Neoplasm Other Specified Sites",
    },
    {
      value: "Secondary Malignant Neoplasm Other Urinary Organs",
      label: "Secondary Malignant Neoplasm Other Urinary Organs",
    },
    {
      value: "Secondary Malignant Neoplasm Ovary",
      label: "Secondary Malignant Neoplasm Ovary",
    },
    {
      value: "Secondary Malignant Neoplasm Respiratory Digestive Systems",
      label: "Secondary Malignant Neoplasm Respiratory Digestive Systems",
    },
    {
      value: "Secondary Malignant Neoplasm Retroperitoneum Peritoneum",
      label: "Secondary Malignant Neoplasm Retroperitoneum Peritoneum",
    },
    {
      value: "Secondary Malignant Neoplasm Skin",
      label: "Secondary Malignant Neoplasm Skin",
    },
    {
      value: "Secondary Malignant Neoplasm Small Intestine Including Duodenum",
      label: "Secondary Malignant Neoplasm Small Intestine Including Duodenum",
    },
    {
      value: "Secondary Malignant Tumor Of Mediastinum",
      label: "Secondary Malignant Tumor Of Mediastinum",
    },
    {
      value: "Secondary Skin Cancer",
      label: "Secondary Skin Cancer",
    },
    {
      value:
        "Secondary Unspecified Malignant Neoplasm Intraabdominal Lymph Nodes",
      label:
        "Secondary Unspecified Malignant Neoplasm Intraabdominal Lymph Nodes",
    },
    {
      value: "Secondary Unspecified Malignant Neoplasm Intrapelvic Lymph Nodes",
      label: "Secondary Unspecified Malignant Neoplasm Intrapelvic Lymph Nodes",
    },
    {
      value:
        "Secondary Unspecified Malignant Neoplasm Intrathoracic Lymph Nodes",
      label:
        "Secondary Unspecified Malignant Neoplasm Intrathoracic Lymph Nodes",
    },
    {
      value: "Secondary Unspecified Malignant Neoplasm Lymph Nodes",
      label: "Secondary Unspecified Malignant Neoplasm Lymph Nodes",
    },
    {
      value:
        "Secondary Unspecified Malignant Neoplasm Lymph Nodes Axilla Upper Limb",
      label:
        "Secondary Unspecified Malignant Neoplasm Lymph Nodes Axilla Upper Limb",
    },
    {
      value:
        "Secondary Unspecified Malignant Neoplasm Lymph Nodes Head Face Neck",
      label:
        "Secondary Unspecified Malignant Neoplasm Lymph Nodes Head Face Neck",
    },
    {
      value:
        "Secondary Unspecified Malignant Neoplasm Lymph Nodes Inguinal Region Lower Limb",
      label:
        "Secondary Unspecified Malignant Neoplasm Lymph Nodes Inguinal Region Lower Limb",
    },
    {
      value:
        "Secondary Unspecified Malignant Neoplasm Lymph Nodes Multiple Sites",
      label:
        "Secondary Unspecified Malignant Neoplasm Lymph Nodes Multiple Sites",
    },
    {
      value:
        "Secondary Unspecified Malignant Neoplasm Lymph Nodes Site Not Specified",
      label:
        "Secondary Unspecified Malignant Neoplasm Lymph Nodes Site Not Specified",
    },
    {
      value: "Seminoma",
      label: "Seminoma",
    },
    {
      value: "Sezarys Disease",
      label: "Sezarys Disease",
    },
    {
      value: "Shoulder Bone Neoplasm",
      label: "Shoulder Bone Neoplasm",
    },
    {
      value: "Shoulder Cancer",
      label: "Shoulder Cancer",
    },
    {
      value: "Shoulder Neoplasm Malignant",
      label: "Shoulder Neoplasm Malignant",
    },
    {
      value: "Sinus Cancer",
      label: "Sinus Cancer",
    },
    {
      value: "Sinus Tumour",
      label: "Sinus Tumour",
    },
    {
      value: "Skin Cancer",
      label: "Skin Cancer",
    },
    {
      value: "Skin Cancer Melanoma",
      label: "Skin Cancer Melanoma",
    },
    {
      value: "Skin Cancer Non Melanoma",
      label: "Skin Cancer Non Melanoma",
    },
    {
      value: "Skin Cancer Of Arm",
      label: "Skin Cancer Of Arm",
    },
    {
      value: "Skin Cancer Of Leg",
      label: "Skin Cancer Of Leg",
    },
    {
      value: "Skin Cancer Of Lower Limb",
      label: "Skin Cancer Of Lower Limb",
    },
    {
      value: "Skin Cancer Of Shoulder",
      label: "Skin Cancer Of Shoulder",
    },
    {
      value: "Skin Cancer Of Upper Limb Including Shoulder",
      label: "Skin Cancer Of Upper Limb Including Shoulder",
    },
    {
      value: "Skin Cancer On Ear",
      label: "Skin Cancer On Ear",
    },
    {
      value: "Skin Cancer On Eyelid",
      label: "Skin Cancer On Eyelid",
    },
    {
      value: "Skin Cancer On Face",
      label: "Skin Cancer On Face",
    },
    {
      value: "Skin Cancer On Lip",
      label: "Skin Cancer On Lip",
    },
    {
      value: "Skin Cancer On Scalp And Neck",
      label: "Skin Cancer On Scalp And Neck",
    },
    {
      value: "Skin Cancer On Trunk",
      label: "Skin Cancer On Trunk",
    },
    {
      value: "Skin Squamous Cell Neoplasm",
      label: "Skin Squamous Cell Neoplasm",
    },
    {
      value: "Skin Squamous Cell Tumour",
      label: "Skin Squamous Cell Tumour",
    },
    {
      value: "Skull Cancer",
      label: "Skull Cancer",
    },
    {
      value: "Skull Neoplasm Malignant",
      label: "Skull Neoplasm Malignant",
    },
    {
      value: "Small Intestine Malignant Neoplasm",
      label: "Small Intestine Malignant Neoplasm",
    },
    {
      value: "Solitary Myeloma",
      label: "Solitary Myeloma",
    },
    {
      value: "Solitary Plasmacytoma",
      label: "Solitary Plasmacytoma",
    },
    {
      value: "Sphenoid Bone Cancer",
      label: "Sphenoid Bone Cancer",
    },
    {
      value: "Sphenoid Bone Neoplasm Malignant",
      label: "Sphenoid Bone Neoplasm Malignant",
    },
    {
      value: "Sphenoidbone Neoplasm Malignant",
      label: "Sphenoidbone Neoplasm Malignant",
    },
    {
      value: "Spinal Column Cancer",
      label: "Spinal Column Cancer",
    },
    {
      value: "Spinal Cord Cancer",
      label: "Spinal Cord Cancer",
    },
    {
      value: "Spinal Cord Neoplasm Unknown",
      label: "Spinal Cord Neoplasm Unknown",
    },
    {
      value: "Spinalioma",
      label: "Spinalioma",
    },
    {
      value: "Spine Cancer",
      label: "Spine Cancer",
    },
    {
      value: "Squamous Cell Carcinoma",
      label: "Squamous Cell Carcinoma",
    },
    {
      value: "Squamous Cell Carcinoma Larynx",
      label: "Squamous Cell Carcinoma Larynx",
    },
    {
      value: "Squamous Cell Carcinoma Lung",
      label: "Squamous Cell Carcinoma Lung",
    },
    {
      value: "Squamous Cell Carcinoma Oral",
      label: "Squamous Cell Carcinoma Oral",
    },
    {
      value: "Squamous Cell Carcinoma Other",
      label: "Squamous Cell Carcinoma Other",
    },
    {
      value: "Squamous Cell Carcinoma Skin",
      label: "Squamous Cell Carcinoma Skin",
    },
    {
      value: "Squamous Cell Neoplasm",
      label: "Squamous Cell Neoplasm",
    },
    {
      value: "Stem Cell Leukaemia",
      label: "Stem Cell Leukaemia",
    },
    {
      value: "Sternum Cancer",
      label: "Sternum Cancer",
    },
    {
      value: "Sternum Neoplasm Malignant",
      label: "Sternum Neoplasm Malignant",
    },
    {
      value: "Stomach Cancer",
      label: "Stomach Cancer",
    },
    {
      value: "Stomach Malignant Neoplasm",
      label: "Stomach Malignant Neoplasm",
    },
    {
      value: "Subacute Lymphocytic Leukaemia",
      label: "Subacute Lymphocytic Leukaemia",
    },
    {
      value: "Subacute Monocytic Leukaemia",
      label: "Subacute Monocytic Leukaemia",
    },
    {
      value: "Subacute Myeloid Leukaemia",
      label: "Subacute Myeloid Leukaemia",
    },
    {
      value: "Synovial Bursa Cancer",
      label: "Synovial Bursa Cancer",
    },
    {
      value: "Synovial Bursa Neoplasm Malignant",
      label: "Synovial Bursa Neoplasm Malignant",
    },
    {
      value: "Synovialbursa Neoplasm Malignant",
      label: "Synovialbursa Neoplasm Malignant",
    },
    {
      value: "T-Zone Lymphoma",
      label: "T-Zone Lymphoma",
    },
    {
      value: "Temporal Lobe Cancer",
      label: "Temporal Lobe Cancer",
    },
    {
      value: "Tendon Cancer",
      label: "Tendon Cancer",
    },
    {
      value: "Tendon Neoplasm Malignant",
      label: "Tendon Neoplasm Malignant",
    },
    {
      value: "Teratoma",
      label: "Teratoma",
    },
    {
      value: "Testicle Cancer",
      label: "Testicle Cancer",
    },
    {
      value: "Testicular Cancer",
      label: "Testicular Cancer",
    },
    {
      value: "Testis Cancer",
      label: "Testis Cancer",
    },
    {
      value: "Testis Malignant Neoplasm",
      label: "Testis Malignant Neoplasm",
    },
    {
      value: "Testis Neoplasm Malignant",
      label: "Testis Neoplasm Malignant",
    },
    {
      value: "Thorax Cancer",
      label: "Thorax Cancer",
    },
    {
      value: "Thorax Neoplasm Malignant",
      label: "Thorax Neoplasm Malignant",
    },
    {
      value: "Throat Cancer",
      label: "Throat Cancer",
    },
    {
      value: "Thymus Cancer",
      label: "Thymus Cancer",
    },
    {
      value: "Thymus Neoplasm Unknown",
      label: "Thymus Neoplasm Unknown",
    },
    {
      value: "Thyroid Gland Cancer",
      label: "Thyroid Gland Cancer",
    },
    {
      value: "Thyroid Gland Tumour",
      label: "Thyroid Gland Tumour",
    },
    {
      value: "Thyroid Neoplasm Malignant",
      label: "Thyroid Neoplasm Malignant",
    },
    {
      value: "Thyroid Neoplasm Unknown",
      label: "Thyroid Neoplasm Unknown",
    },
    {
      value: "Tongue Cancer",
      label: "Tongue Cancer",
    },
    {
      value: "Tongue Malignant Neoplasm",
      label: "Tongue Malignant Neoplasm",
    },
    {
      value: "Tongue Tumour",
      label: "Tongue Tumour",
    },
    {
      value: "Tonsil Cancer",
      label: "Tonsil Cancer",
    },
    {
      value: "Tonsil Malignant Neoplasm",
      label: "Tonsil Malignant Neoplasm",
    },
    {
      value: "Tonsil Tumour",
      label: "Tonsil Tumour",
    },
    {
      value: "Trachea Malignant Neoplasm",
      label: "Trachea Malignant Neoplasm",
    },
    {
      value: "Tracheal Cancer",
      label: "Tracheal Cancer",
    },
    {
      value: "Transitional Cell Bladder Cancer",
      label: "Transitional Cell Bladder Cancer",
    },
    {
      value: "True Histiocytic Lymphoma",
      label: "True Histiocytic Lymphoma",
    },
    {
      value: "Trunk Cancer",
      label: "Trunk Cancer",
    },
    {
      value: "Trunk Neoplasm Malignant",
      label: "Trunk Neoplasm Malignant",
    },
    {
      value: "Tubular Carcinoma",
      label: "Tubular Carcinoma",
    },
    {
      value: "Tumour Malignant Unspecified",
      label: "Tumour Malignant Unspecified",
    },
    {
      value: "Ureter Cancer",
      label: "Ureter Cancer",
    },
    {
      value: "Ureter Tumour",
      label: "Ureter Tumour",
    },
    {
      value: "Urethra Cancer",
      label: "Urethra Cancer",
    },
    {
      value: "Urinary Cancer",
      label: "Urinary Cancer",
    },
    {
      value: "Uterus Cancer",
      label: "Uterus Cancer",
    },
    {
      value: "Uterus Neoplasm Malignant",
      label: "Uterus Neoplasm Malignant",
    },
    {
      value: "Uterus Neoplasm Unknown",
      label: "Uterus Neoplasm Unknown",
    },
    {
      value: "Vagina Cancer",
      label: "Vagina Cancer",
    },
    {
      value: "Vagina Malignant Neoplasm",
      label: "Vagina Malignant Neoplasm",
    },
    {
      value: "Vagina Neoplasm Malignant",
      label: "Vagina Neoplasm Malignant",
    },
    {
      value: "Vagina Tumour",
      label: "Vagina Tumour",
    },
    {
      value: "Vaginal Cancer",
      label: "Vaginal Cancer",
    },
    {
      value: "Vulva Cancer",
      label: "Vulva Cancer",
    },
    {
      value: "Vulva Malignant Neoplasm",
      label: "Vulva Malignant Neoplasm",
    },
    {
      value: "Vulva Neoplasm Malignant",
      label: "Vulva Neoplasm Malignant",
    },
    {
      value: "Vulva Tumour",
      label: "Vulva Tumour",
    },
    {
      value: "Waldenstroem Disease",
      label: "Waldenstroem Disease",
    },
    {
      value: "Waldenstroem Macroglobulinaemia",
      label: "Waldenstroem Macroglobulinaemia",
    },
    {
      value: "Waldenstroems Macroglobulinaemia",
      label: "Waldenstroems Macroglobulinaemia",
    },
    {
      value: "Waldenstroms Macroglobulinaemia",
      label: "Waldenstroms Macroglobulinaemia",
    },
    {
      value: "Waldeyers Ring Cancer",
      label: "Waldeyers Ring Cancer",
    },
    {
      value: "Womb Cancer",
      label: "Womb Cancer",
    },
    {
      value: "Wrist Cancer",
      label: "Wrist Cancer",
    },
    {
      value: "Zollinger Ellison Syndrome",
      label: "Zollinger Ellison Syndrome",
    },
  ],
  HeartConditions: [
    {
      value: "Abdomen Aneurysm",
      label: "Abdomen Aneurysm",
    },
    {
      value: "Abdomenaorta Aneurysm Rupture",
      label: "Abdomenaorta Aneurysm Rupture",
    },
    {
      value: "Abdomenaorta Embolism",
      label: "Abdomenaorta Embolism",
    },
    {
      value: "Abdominal Aortic Aneurysm And Dissection",
      label: "Abdominal Aortic Aneurysm And Dissection",
    },
    {
      value: "Acute And Subacute Infective Endocarditis",
      label: "Acute And Subacute Infective Endocarditis",
    },
    {
      value: "Acute Myocardial Infarction",
      label: "Acute Myocardial Infarction",
    },
    {
      value: "Acute Pericarditis",
      label: "Acute Pericarditis",
    },
    {
      value: "Adams Stokes Disease",
      label: "Adams Stokes Disease",
    },
    {
      value: "Alcoholic Cardiomyopathy",
      label: "Alcoholic Cardiomyopathy",
    },
    {
      value: "Aneurysm Abdominal",
      label: "Aneurysm Abdominal",
    },
    {
      value: "Aneurysm Aortic Rupture",
      label: "Aneurysm Aortic Rupture",
    },
    {
      value: "Aneurysm Cardiac",
      label: "Aneurysm Cardiac",
    },
    {
      value: "Aneurysm Of Heart",
      label: "Aneurysm Of Heart",
    },
    {
      value: "Aneurysm Thoracic",
      label: "Aneurysm Thoracic",
    },
    {
      value: "Aneurysm Thoracic Rupture",
      label: "Aneurysm Thoracic Rupture",
    },
    {
      value: "Angina Pectoris",
      label: "Angina Pectoris",
    },
    {
      value: "Aorta Abdominal Aneurysm",
      label: "Aorta Abdominal Aneurysm",
    },
    {
      value: "Aorta Aneurysm Rupture",
      label: "Aorta Aneurysm Rupture",
    },
    {
      value: "Aorta Coarctation",
      label: "Aorta Coarctation",
    },
    {
      value: "Aorta Dilatation",
      label: "Aorta Dilatation",
    },
    {
      value: "Aorta Dissecting Aneurysm",
      label: "Aorta Dissecting Aneurysm",
    },
    {
      value: "Aorta Embolism",
      label: "Aorta Embolism",
    },
    {
      value: "Aorta Thoracic Aneurysm",
      label: "Aorta Thoracic Aneurysm",
    },
    {
      value: "Aortic Heart Valve Leak",
      label: "Aortic Heart Valve Leak",
    },
    {
      value: "Aortic Incompetence",
      label: "Aortic Incompetence",
    },
    {
      value: "Aortic Insufficiency",
      label: "Aortic Insufficiency",
    },
    {
      value: "Aortic Regurgitation",
      label: "Aortic Regurgitation",
    },
    {
      value: "Aortic Sclerosis",
      label: "Aortic Sclerosis",
    },
    {
      value: "Aortic Stenosis",
      label: "Aortic Stenosis",
    },
    {
      value: "Aortic Valve Incompetence",
      label: "Aortic Valve Incompetence",
    },
    {
      value: "Aortic Valve Insufficiency",
      label: "Aortic Valve Insufficiency",
    },
    {
      value: "Aortic Valve Regurgitation",
      label: "Aortic Valve Regurgitation",
    },
    {
      value: "Aortic Valve Stenosis",
      label: "Aortic Valve Stenosis",
    },
    {
      value: "Aortic Valve Stenosis With Incompetence",
      label: "Aortic Valve Stenosis With Incompetence",
    },
    {
      value: "Aortic Valve Stenosis With Insufficiency",
      label: "Aortic Valve Stenosis With Insufficiency",
    },
    {
      value: "Aortic Valve Stenosis With Regurgitation",
      label: "Aortic Valve Stenosis With Regurgitation",
    },
    {
      value: "Arrhythmia",
      label: "Arrhythmia",
    },
    {
      value: "Arteriosclerotic Heart Disease",
      label: "Arteriosclerotic Heart Disease",
    },
    {
      value: "Atherosclerosis Of Aorta",
      label: "Atherosclerosis Of Aorta",
    },
    {
      value: "Atherosclerotic Heart Disease",
      label: "Atherosclerotic Heart Disease",
    },
    {
      value: "Atrial Flutter",
      label: "Atrial Flutter",
    },
    {
      value: "Atrial Septal Defect",
      label: "Atrial Septal Defect",
    },
    {
      value: "Atrioventricular Block",
      label: "Atrioventricular Block",
    },
    {
      value: "Atrioventricular Block And Left Bundle-Branch Block",
      label: "Atrioventricular Block And Left Bundle-Branch Block",
    },
    {
      value: "Atrioventricular Block First Degree",
      label: "Atrioventricular Block First Degree",
    },
    {
      value: "Atrioventricular Block Second Degree",
      label: "Atrioventricular Block Second Degree",
    },
    {
      value: "Atrioventricular Block, Complete",
      label: "Atrioventricular Block, Complete",
    },
    {
      value: "Atrioventricular Septal Defect",
      label: "Atrioventricular Septal Defect",
    },
    {
      value: "Barlows Syndrome",
      label: "Barlows Syndrome",
    },
    {
      value: "Benign Heart Murmurs",
      label: "Benign Heart Murmurs",
    },
    {
      value: "Bicuspid Aortic Valve",
      label: "Bicuspid Aortic Valve",
    },
    {
      value: "Bicuspid Valve",
      label: "Bicuspid Valve",
    },
    {
      value: "Biventricular Failure",
      label: "Biventricular Failure",
    },
    {
      value: "Blocked Coronary Artery",
      label: "Blocked Coronary Artery",
    },
    {
      value: "Bradycardia",
      label: "Bradycardia",
    },
    {
      value: "Cardiac Arrest",
      label: "Cardiac Arrest",
    },
    {
      value: "Cardiac Arrythmia",
      label: "Cardiac Arrythmia",
    },
    {
      value: "Cardiac Dysrhythmia",
      label: "Cardiac Dysrhythmia",
    },
    {
      value: "Cardiac Enlargement",
      label: "Cardiac Enlargement",
    },
    {
      value: "Cardiac Failure",
      label: "Cardiac Failure",
    },
    {
      value: "Cardiac Infarction",
      label: "Cardiac Infarction",
    },
    {
      value: "Cardiac Syndrome X",
      label: "Cardiac Syndrome X",
    },
    {
      value: "Cardiomegaly",
      label: "Cardiomegaly",
    },
    {
      value: "Cardiovascular Disease",
      label: "Cardiovascular Disease",
    },
    {
      value: "Carditis Rheumatic",
      label: "Carditis Rheumatic",
    },
    {
      value: "CCF",
      label: "CCF",
    },
    {
      value: "Chronic Endocarditis",
      label: "Chronic Endocarditis",
    },
    {
      value: "Chronic Ischaemic Heart Disease",
      label: "Chronic Ischaemic Heart Disease",
    },
    {
      value: "Chronic Myocarditis",
      label: "Chronic Myocarditis",
    },
    {
      value: "Chronic Rheumatic Pericarditis",
      label: "Chronic Rheumatic Pericarditis",
    },
    {
      value: "Coarctation Of Aorta",
      label: "Coarctation Of Aorta",
    },
    {
      value: "Common Arterial Trunk",
      label: "Common Arterial Trunk",
    },
    {
      value: "Complete Atrioventricular Block",
      label: "Complete Atrioventricular Block",
    },
    {
      value: "Complete Heart Block",
      label: "Complete Heart Block",
    },
    {
      value: "Complete Rbbb",
      label: "Complete Rbbb",
    },
    {
      value: "Concentric Heart Hypertrophy",
      label: "Concentric Heart Hypertrophy",
    },
    {
      value: "Conduction Disorder",
      label: "Conduction Disorder",
    },
    {
      value: "Congenital Aortic Valve Incompetence",
      label: "Congenital Aortic Valve Incompetence",
    },
    {
      value: "Congenital Aortic Valve Regurgitation",
      label: "Congenital Aortic Valve Regurgitation",
    },
    {
      value: "Congenital Heart Defect",
      label: "Congenital Heart Defect",
    },
    {
      value: "Congenital Insufficiency Of Aortic Valve",
      label: "Congenital Insufficiency Of Aortic Valve",
    },
    {
      value: "Congenital Malformations Of Aortic And Mitral Valves",
      label: "Congenital Malformations Of Aortic And Mitral Valves",
    },
    {
      value: "Congenital Malformations Of Cardiac Septa",
      label: "Congenital Malformations Of Cardiac Septa",
    },
    {
      value: "Congenital Malformations Of Pulmonary And Tricuspid Valves",
      label: "Congenital Malformations Of Pulmonary And Tricuspid Valves",
    },
    {
      value: "Congenital Mitral Valve Incompetence",
      label: "Congenital Mitral Valve Incompetence",
    },
    {
      value: "Congenital Mitral Valve Insufficiency",
      label: "Congenital Mitral Valve Insufficiency",
    },
    {
      value: "Congenital Mitral Valve Regurgitation",
      label: "Congenital Mitral Valve Regurgitation",
    },
    {
      value: "Congenital Mitral Valve Stenosis",
      label: "Congenital Mitral Valve Stenosis",
    },
    {
      value: "Congenital Pulmonary Stenosis",
      label: "Congenital Pulmonary Stenosis",
    },
    {
      value: "Congenital Pulmonary Valve Incompetence",
      label: "Congenital Pulmonary Valve Incompetence",
    },
    {
      value: "Congenital Pulmonary Valve Insufficiency",
      label: "Congenital Pulmonary Valve Insufficiency",
    },
    {
      value: "Congenital Pulmonary Valve Regurgitation",
      label: "Congenital Pulmonary Valve Regurgitation",
    },
    {
      value: "Congenital Pulmonary Valve Stenosis",
      label: "Congenital Pulmonary Valve Stenosis",
    },
    {
      value: "Congenital Stenosis Of Aortic Valve",
      label: "Congenital Stenosis Of Aortic Valve",
    },
    {
      value: "Congenital Tricuspid Valve Incompetence",
      label: "Congenital Tricuspid Valve Incompetence",
    },
    {
      value: "Congenital Tricuspid Valve Insufficency",
      label: "Congenital Tricuspid Valve Insufficency",
    },
    {
      value: "Congenital Tricuspid Valve Regurgitation",
      label: "Congenital Tricuspid Valve Regurgitation",
    },
    {
      value: "Congenital Tricuspid Valve Stenosis",
      label: "Congenital Tricuspid Valve Stenosis",
    },
    {
      value: "Congestive Cardiac Failure",
      label: "Congestive Cardiac Failure",
    },
    {
      value: "Congestive Heart Failure",
      label: "Congestive Heart Failure",
    },
    {
      value: "Cor Pulmonale",
      label: "Cor Pulmonale",
    },
    {
      value: "Coronary Arteriosclerosis",
      label: "Coronary Arteriosclerosis",
    },
    {
      value: "Coronary Artery Aneurysm",
      label: "Coronary Artery Aneurysm",
    },
    {
      value: "Coronary Artery Disease",
      label: "Coronary Artery Disease",
    },
    {
      value: "Coronary Atherosclerosis",
      label: "Coronary Atherosclerosis",
    },
    {
      value: "Coronary Embolism Not Resulting In Heart Attack",
      label: "Coronary Embolism Not Resulting In Heart Attack",
    },
    {
      value: "Coronary Heart Disease",
      label: "Coronary Heart Disease",
    },
    {
      value: "Coronary Insufficiency",
      label: "Coronary Insufficiency",
    },
    {
      value: "Coronary Insufficiency Acute",
      label: "Coronary Insufficiency Acute",
    },
    {
      value: "Coronary Insufficiency Chronic",
      label: "Coronary Insufficiency Chronic",
    },
    {
      value: "Coronary Sclerosis",
      label: "Coronary Sclerosis",
    },
    {
      value: "Coronary Thrombosis Not Resulting In Heart Attack",
      label: "Coronary Thrombosis Not Resulting In Heart Attack",
    },
    {
      value: "Coronary Thrombosis Not Resulting In Myocardial Infarction",
      label: "Coronary Thrombosis Not Resulting In Myocardial Infarction",
    },
    {
      value: "Dilated Cardiomyopathy",
      label: "Dilated Cardiomyopathy",
    },
    {
      value: "Dilated Heart",
      label: "Dilated Heart",
    },
    {
      value: "Dresslers Syndrome",
      label: "Dresslers Syndrome",
    },
    {
      value: "Drug Induced Cardiomyopathy",
      label: "Drug Induced Cardiomyopathy",
    },
    {
      value: "Ductus Arteriosus Patent",
      label: "Ductus Arteriosus Patent",
    },
    {
      value: "Ductus Arteriosus Persistent",
      label: "Ductus Arteriosus Persistent",
    },
    {
      value: "Ductus Botalli",
      label: "Ductus Botalli",
    },
    {
      value: "Ductus Botalli Patent",
      label: "Ductus Botalli Patent",
    },
    {
      value: "Ebstein Anomaly",
      label: "Ebstein Anomaly",
    },
    {
      value: "Ebsteins Anomaly",
      label: "Ebsteins Anomaly",
    },
    {
      value: "Eisenmenger Syndrome",
      label: "Eisenmenger Syndrome",
    },
    {
      value: "Embolism And Thrombosis Of Abdominal Aorta",
      label: "Embolism And Thrombosis Of Abdominal Aorta",
    },
    {
      value: "Endocarditis",
      label: "Endocarditis",
    },
    {
      value: "Endocarditis Acute",
      label: "Endocarditis Acute",
    },
    {
      value: "Endocarditis Rheumatic",
      label: "Endocarditis Rheumatic",
    },
    {
      value: "Enlarged Heart",
      label: "Enlarged Heart",
    },
    {
      value: "Fallot",
      label: "Fallot",
    },
    {
      value: "Fallots Tetralogy",
      label: "Fallots Tetralogy",
    },
    {
      value: "Floppy Mitral Valve",
      label: "Floppy Mitral Valve",
    },
    {
      value: "Haemopericardium, Not Elsewhere Classified",
      label: "Haemopericardium, Not Elsewhere Classified",
    },
    {
      value: "Heart Amyloid",
      label: "Heart Amyloid",
    },
    {
      value: "Heart Arrest",
      label: "Heart Arrest",
    },
    {
      value: "Heart Dilation",
      label: "Heart Dilation",
    },
    {
      value: "Heart Disease",
      label: "Heart Disease",
    },
    {
      value: "Heart Enlargement",
      label: "Heart Enlargement",
    },
    {
      value: "Heart Infarct",
      label: "Heart Infarct",
    },
    {
      value: "Heart Infarction",
      label: "Heart Infarction",
    },
    {
      value: "Heart Insufficiency",
      label: "Heart Insufficiency",
    },
    {
      value: "Heart Malformation",
      label: "Heart Malformation",
    },
    {
      value: "Heart Murmur",
      label: "Heart Murmur",
    },
    {
      value: "Heart Rhythm Disorder",
      label: "Heart Rhythm Disorder",
    },
    {
      value: "Heart Transplant",
      label: "Heart Transplant",
    },
    {
      value: "Heart Valve Defect",
      label: "Heart Valve Defect",
    },
    {
      value: "Heart Valve Disease",
      label: "Heart Valve Disease",
    },
    {
      value: "Heart Valvular Defect",
      label: "Heart Valvular Defect",
    },
    {
      value: "HOCM",
      label: "HOCM",
    },
    {
      value: "Hole In The Heart",
      label: "Hole In The Heart",
    },
    {
      value: "Hypertensive Heart Disease",
      label: "Hypertensive Heart Disease",
    },
    {
      value: "Hypertensive Heart Disease With Congestive Heart Failure",
      label: "Hypertensive Heart Disease With Congestive Heart Failure",
    },
    {
      value: "Hypertensive Heart Disease Without Congestive Heart Failure",
      label: "Hypertensive Heart Disease Without Congestive Heart Failure",
    },
    {
      value: "Hypertrophic Obstructive Cardiomyopathy",
      label: "Hypertrophic Obstructive Cardiomyopathy",
    },
    {
      value: "Hypertrophic Obstructive Cardiomyopathy - HOCM",
      label: "Hypertrophic Obstructive Cardiomyopathy - HOCM",
    },
    {
      value: "IHD",
      label: "IHD",
    },
    {
      value: "Imminent Heart Infarct",
      label: "Imminent Heart Infarct",
    },
    {
      value: "Incomplete RBBB",
      label: "Incomplete RBBB",
    },
    {
      value: "Incomplete Right Bundle Branch Block",
      label: "Incomplete Right Bundle Branch Block",
    },
    {
      value: "Infective Myocarditis",
      label: "Infective Myocarditis",
    },
    {
      value: "Infective Pericarditis",
      label: "Infective Pericarditis",
    },
    {
      value: "Interstitial Myocarditis",
      label: "Interstitial Myocarditis",
    },
    {
      value: "Irregular Heart Beat",
      label: "Irregular Heart Beat",
    },
    {
      value: "Ischaemic Cardiomyopathy",
      label: "Ischaemic Cardiomyopathy",
    },
    {
      value: "Ischaemic Chest Pain",
      label: "Ischaemic Chest Pain",
    },
    {
      value: "Ischaemic Heart Disease",
      label: "Ischaemic Heart Disease",
    },
    {
      value: "Left BBB",
      label: "Left BBB",
    },
    {
      value: "Left Bundle Branch Block",
      label: "Left Bundle Branch Block",
    },
    {
      value: "Left Heart Failure",
      label: "Left Heart Failure",
    },
    {
      value: "Left Ventricular Failure",
      label: "Left Ventricular Failure",
    },
    {
      value: "Left Ventricular Hypertrophy",
      label: "Left Ventricular Hypertrophy",
    },
    {
      value: "LVH",
      label: "LVH",
    },
    {
      value: "MI",
      label: "MI",
    },
    {
      value: "Mitral Heart Valve Leak",
      label: "Mitral Heart Valve Leak",
    },
    {
      value: "Mitral Incompetence",
      label: "Mitral Incompetence",
    },
    {
      value: "Mitral Insufficiency",
      label: "Mitral Insufficiency",
    },
    {
      value: "Mitral Prolapse",
      label: "Mitral Prolapse",
    },
    {
      value: "Mitral Regurgitation",
      label: "Mitral Regurgitation",
    },
    {
      value: "Mitral Stenosis",
      label: "Mitral Stenosis",
    },
    {
      value: "Mitral Valve Incompetence",
      label: "Mitral Valve Incompetence",
    },
    {
      value: "Mitral Valve Insufficiency",
      label: "Mitral Valve Insufficiency",
    },
    {
      value: "Mitral Valve Murmur",
      label: "Mitral Valve Murmur",
    },
    {
      value: "Mitral Valve Prolapse",
      label: "Mitral Valve Prolapse",
    },
    {
      value: "Mitral Valve Regurgitation",
      label: "Mitral Valve Regurgitation",
    },
    {
      value: "Myocardial Infarct",
      label: "Myocardial Infarct",
    },
    {
      value: "Myocarditis",
      label: "Myocarditis",
    },
    {
      value: "Myocarditis Acute",
      label: "Myocarditis Acute",
    },
    {
      value: "Myocarditis In Other Diseases Classified Elsewhere",
      label: "Myocarditis In Other Diseases Classified Elsewhere",
    },
    {
      value: "Myocarditis Rheumatic",
      label: "Myocarditis Rheumatic",
    },
    {
      value: "Non Obstructive Hypertrophic Cardiomyopathy",
      label: "Non Obstructive Hypertrophic Cardiomyopathy",
    },
    {
      value: "Non Rheumatic Mitral Valve Stenosis",
      label: "Non Rheumatic Mitral Valve Stenosis",
    },
    {
      value: "Nonobstructive Cardiomyopathy",
      label: "Nonobstructive Cardiomyopathy",
    },
    {
      value: "Nonrheumatic Tricuspid Valve Disorders",
      label: "Nonrheumatic Tricuspid Valve Disorders",
    },
    {
      value: "Nonrheumatic Tricuspid Valve Incompetence",
      label: "Nonrheumatic Tricuspid Valve Incompetence",
    },
    {
      value: "Nonrheumatic Tricuspid Valve Insufficiency",
      label: "Nonrheumatic Tricuspid Valve Insufficiency",
    },
    {
      value: "Nonrheumatic Tricuspid Valve Regurgitation",
      label: "Nonrheumatic Tricuspid Valve Regurgitation",
    },
    {
      value: "Nonrheumatic Tricuspid Valve Stenosis",
      label: "Nonrheumatic Tricuspid Valve Stenosis",
    },
    {
      value: "Nonrheumatic Tricuspid Valve Stenosis With Incompetence",
      label: "Nonrheumatic Tricuspid Valve Stenosis With Incompetence",
    },
    {
      value: "Nonrheumatic Tricuspid Valve Stenosis With Insufficiency",
      label: "Nonrheumatic Tricuspid Valve Stenosis With Insufficiency",
    },
    {
      value: "Nonrheumatic Tricuspid Valve Stenosis With Regurgitation",
      label: "Nonrheumatic Tricuspid Valve Stenosis With Regurgitation",
    },
    {
      value: "Obstructive Cardiomyopathy",
      label: "Obstructive Cardiomyopathy",
    },
    {
      value: "Obstructive Hypertrophic Cardiomyopathy",
      label: "Obstructive Hypertrophic Cardiomyopathy",
    },
    {
      value: "Old Heart Attack Diagnosed By ECG Or Other Special Investigation",
      label: "Old Heart Attack Diagnosed By ECG Or Other Special Investigation",
    },
    {
      value:
        "Old Myocardial Infarction Diagnosed By ECG Or Other Special Investigation",
      label:
        "Old Myocardial Infarction Diagnosed By ECG Or Other Special Investigation",
    },
    {
      value: "Other Congenital Malformations Of Pulmonary Valve",
      label: "Other Congenital Malformations Of Pulmonary Valve",
    },
    {
      value: "Other Rheumatic Heart Diseases",
      label: "Other Rheumatic Heart Diseases",
    },
    {
      value: "Palpitations",
      label: "Palpitations",
    },
    {
      value: "Paroxysmal Tachycardia",
      label: "Paroxysmal Tachycardia",
    },
    {
      value: "Patent Ductus Arteriosus",
      label: "Patent Ductus Arteriosus",
    },
    {
      value: "PDA",
      label: "PDA",
    },
    {
      value: "Pericarditis",
      label: "Pericarditis",
    },
    {
      value: "Pericarditis Acute",
      label: "Pericarditis Acute",
    },
    {
      value: "Pericarditis Chronic",
      label: "Pericarditis Chronic",
    },
    {
      value: "Pericarditis Constrictive",
      label: "Pericarditis Constrictive",
    },
    {
      value: "Pericarditis In Other Diseases Classified Elsewhere",
      label: "Pericarditis In Other Diseases Classified Elsewhere",
    },
    {
      value: "Pericarditis Rheumatic",
      label: "Pericarditis Rheumatic",
    },
    {
      value: "Pericarditis Tuberculous",
      label: "Pericarditis Tuberculous",
    },
    {
      value: "Pericardium Tuberculosis",
      label: "Pericardium Tuberculosis",
    },
    {
      value: "Pre-Excitation Syndrome",
      label: "Pre-Excitation Syndrome",
    },
    {
      value: "Preexcitation Syndrome",
      label: "Preexcitation Syndrome",
    },
    {
      value: "Pulmonary Artery Stenosis",
      label: "Pulmonary Artery Stenosis",
    },
    {
      value: "Pulmonary Heart Disease",
      label: "Pulmonary Heart Disease",
    },
    {
      value: "Pulmonary Heart Valve Leak",
      label: "Pulmonary Heart Valve Leak",
    },
    {
      value: "Pulmonary Stenosis",
      label: "Pulmonary Stenosis",
    },
    {
      value: "Pulmonary Valve Disorders",
      label: "Pulmonary Valve Disorders",
    },
    {
      value: "Pulmonary Valve Incompetence",
      label: "Pulmonary Valve Incompetence",
    },
    {
      value: "Pulmonary Valve Insufficiency",
      label: "Pulmonary Valve Insufficiency",
    },
    {
      value: "Pulmonary Valve Murmur",
      label: "Pulmonary Valve Murmur",
    },
    {
      value: "Pulmonary Valve Regurgitation",
      label: "Pulmonary Valve Regurgitation",
    },
    {
      value: "Pulmonary Valve Stenosis",
      label: "Pulmonary Valve Stenosis",
    },
    {
      value: "Rapid Heart Beat",
      label: "Rapid Heart Beat",
    },
    {
      value: "RBBB",
      label: "RBBB",
    },
    {
      value: "Restrictive Cardiomyopathy",
      label: "Restrictive Cardiomyopathy",
    },
    {
      value: "Rheumatic Aortic Valve Diseases",
      label: "Rheumatic Aortic Valve Diseases",
    },
    {
      value: "Rheumatic Fever With Heart Involvement",
      label: "Rheumatic Fever With Heart Involvement",
    },
    {
      value: "Rheumatic Heart Disease",
      label: "Rheumatic Heart Disease",
    },
    {
      value: "Rheumatic Mitral Valve Disease",
      label: "Rheumatic Mitral Valve Disease",
    },
    {
      value: "Rheumatic Myocarditis",
      label: "Rheumatic Myocarditis",
    },
    {
      value: "Rheumatic Tricuspid Valve Diseases",
      label: "Rheumatic Tricuspid Valve Diseases",
    },
    {
      value: "Rheumatism With Heartinvolvement",
      label: "Rheumatism With Heartinvolvement",
    },
    {
      value: "Right BBB",
      label: "Right BBB",
    },
    {
      value: "Right Bundle Branch Block",
      label: "Right Bundle Branch Block",
    },
    {
      value: "Right Bundlebranch Block",
      label: "Right Bundlebranch Block",
    },
    {
      value: "Right Heart Failure",
      label: "Right Heart Failure",
    },
    {
      value: "Right Ventricular Hypertrophy",
      label: "Right Ventricular Hypertrophy",
    },
    {
      value: "Ruptured Abdominal Aortic Aneurysm",
      label: "Ruptured Abdominal Aortic Aneurysm",
    },
    {
      value: "RVH",
      label: "RVH",
    },
    {
      value: "Second Degree Atrioventricular Block",
      label: "Second Degree Atrioventricular Block",
    },
    {
      value: "Septal Heart Defect",
      label: "Septal Heart Defect",
    },
    {
      value: "Septum Defect Cardiac",
      label: "Septum Defect Cardiac",
    },
    {
      value: "Sick Sinus Syndrome",
      label: "Sick Sinus Syndrome",
    },
    {
      value: "Slow Heart Beat",
      label: "Slow Heart Beat",
    },
    {
      value: "Stokes Adams Syndrome",
      label: "Stokes Adams Syndrome",
    },
    {
      value: "Subacute Bacterial Endocarditis",
      label: "Subacute Bacterial Endocarditis",
    },
    {
      value: "Super Ventricular Tachycardia",
      label: "Super Ventricular Tachycardia",
    },
    {
      value: "Supraventricular Paroxysmal Tachycardia",
      label: "Supraventricular Paroxysmal Tachycardia",
    },
    {
      value: "Supraventricular Tachycardia",
      label: "Supraventricular Tachycardia",
    },
    {
      value: "SVT",
      label: "SVT",
    },
    {
      value: "Systolic Murmur",
      label: "Systolic Murmur",
    },
    {
      value: "Tachycardia",
      label: "Tachycardia",
    },
    {
      value: "Tachycardia Paroxysmal Unspecified",
      label: "Tachycardia Paroxysmal Unspecified",
    },
    {
      value: "Third Degree Atrioventricular Block",
      label: "Third Degree Atrioventricular Block",
    },
    {
      value: "Tricuspid Heart Valve Leak",
      label: "Tricuspid Heart Valve Leak",
    },
    {
      value: "Tricuspid Incompetence",
      label: "Tricuspid Incompetence",
    },
    {
      value: "Tricuspid Insufficiency",
      label: "Tricuspid Insufficiency",
    },
    {
      value: "Tricuspid Regurgitation",
      label: "Tricuspid Regurgitation",
    },
    {
      value: "Tricuspid Stenosis",
      label: "Tricuspid Stenosis",
    },
    {
      value: "Tricuspid Valve Anomaly",
      label: "Tricuspid Valve Anomaly",
    },
    {
      value: "Tricuspid Valve Atresia",
      label: "Tricuspid Valve Atresia",
    },
    {
      value: "Tricuspid Valve Disease",
      label: "Tricuspid Valve Disease",
    },
    {
      value: "Tricuspid Valve Disorder",
      label: "Tricuspid Valve Disorder",
    },
    {
      value: "Tricuspid Valve Insufficiency",
      label: "Tricuspid Valve Insufficiency",
    },
    {
      value: "Tricuspid Valve Stenosis",
      label: "Tricuspid Valve Stenosis",
    },
    {
      value: "Triscuspid Valve Incompetence",
      label: "Triscuspid Valve Incompetence",
    },
    {
      value: "Triscuspid Valve Regurgitation",
      label: "Triscuspid Valve Regurgitation",
    },
    {
      value: "Valvular Heart Defect",
      label: "Valvular Heart Defect",
    },
    {
      value: "Valvular Heart Disease",
      label: "Valvular Heart Disease",
    },
    {
      value: "Ventricular Fibrillation And Flutter",
      label: "Ventricular Fibrillation And Flutter",
    },
    {
      value: "Ventricular Septal Defect",
      label: "Ventricular Septal Defect",
    },
    {
      value: "Ventricular Tachycardia",
      label: "Ventricular Tachycardia",
    },
    {
      value: "Vitium Cordis",
      label: "Vitium Cordis",
    },
    {
      value: "Wolff Parkinson White Syndrome",
      label: "Wolff Parkinson White Syndrome",
    },
    {
      value: "WPW",
      label: "WPW",
    },
  ],
  MultipleSclerosisConditions: [
    {
      value: "Multiple Sclerosis",
      label: "Multiple Sclerosis",
    },
  ],
  NeurologicalConditions: [
    {
      value: "ALS",
      label: "ALS",
    },
    {
      value: "Alzheimer Disease",
      label: "Alzheimer Disease",
    },
    {
      value: "Alzheimers Disease",
      label: "Alzheimers Disease",
    },
    {
      value: "Amyotrophic Lateral Sclerosis",
      label: "Amyotrophic Lateral Sclerosis",
    },
    {
      value: "Anoxic Brain Damage",
      label: "Anoxic Brain Damage",
    },
    {
      value: "Anoxic Cerebral",
      label: "Anoxic Cerebral",
    },
    {
      value: "Anterior Horn Syndrome",
      label: "Anterior Horn Syndrome",
    },
    {
      value: "Arachnoid Cyst",
      label: "Arachnoid Cyst",
    },
    {
      value: "Arteriosclerotic Dementia",
      label: "Arteriosclerotic Dementia",
    },
    {
      value: "Ataxia",
      label: "Ataxia",
    },
    {
      value: "Ataxia Cerebellar",
      label: "Ataxia Cerebellar",
    },
    {
      value: "Attack Epileptic",
      label: "Attack Epileptic",
    },
    {
      value: "Becker Dystrophy",
      label: "Becker Dystrophy",
    },
    {
      value: "Brain Compression",
      label: "Brain Compression",
    },
    {
      value: "Cauda Equina Syndrome",
      label: "Cauda Equina Syndrome",
    },
    {
      value: "Cerebral Anoxia",
      label: "Cerebral Anoxia",
    },
    {
      value: "Cerebral Cysts",
      label: "Cerebral Cysts",
    },
    {
      value: "Cerebral Dysfunction",
      label: "Cerebral Dysfunction",
    },
    {
      value: "Cerebral Pressure Rise",
      label: "Cerebral Pressure Rise",
    },
    {
      value: "Cerebral Swelling",
      label: "Cerebral Swelling",
    },
    {
      value: "Congenital Myopathies",
      label: "Congenital Myopathies",
    },
    {
      value: "Degeneration Cerebellar",
      label: "Degeneration Cerebellar",
    },
    {
      value: "Degeneration Cerebral",
      label: "Degeneration Cerebral",
    },
    {
      value: "Dementia",
      label: "Dementia",
    },
    {
      value: "Dementia Senile",
      label: "Dementia Senile",
    },
    {
      value: "Demyelinating Disease",
      label: "Demyelinating Disease",
    },
    {
      value: "Disease Spinocerebellar",
      label: "Disease Spinocerebellar",
    },
    {
      value: "Dystrophy Myotonic",
      label: "Dystrophy Myotonic",
    },
    {
      value: "Early-Onset Cerebellar Ataxia",
      label: "Early-Onset Cerebellar Ataxia",
    },
    {
      value: "Encephalitis",
      label: "Encephalitis",
    },
    {
      value: "Encephalopathy",
      label: "Encephalopathy",
    },
    {
      value: "Epilepsy",
      label: "Epilepsy",
    },
    {
      value: "Fit",
      label: "Fit",
    },
    {
      value: "Fits",
      label: "Fits",
    },
    {
      value: "Flaccid Hemiplegia",
      label: "Flaccid Hemiplegia",
    },
    {
      value: "Friedreichs Ataxia",
      label: "Friedreichs Ataxia",
    },
    {
      value: "Friedreichs Disease",
      label: "Friedreichs Disease",
    },
    {
      value: "Generalised Idiopathic Epilepsy And Epileptic Syndromes",
      label: "Generalised Idiopathic Epilepsy And Epileptic Syndromes",
    },
    {
      value: "GM Epilepsy",
      label: "GM Epilepsy",
    },
    {
      value: "Grand Mal Epilepsy",
      label: "Grand Mal Epilepsy",
    },
    {
      value: "Grand Mal Seizures",
      label: "Grand Mal Seizures",
    },
    {
      value: "Guillain Barre Syndrome",
      label: "Guillain Barre Syndrome",
    },
    {
      value: "Hemiplegia",
      label: "Hemiplegia",
    },
    {
      value: "Hereditary Ataxia",
      label: "Hereditary Ataxia",
    },
    {
      value: "Hereditary Motor And Sensory Neuropathy",
      label: "Hereditary Motor And Sensory Neuropathy",
    },
    {
      value: "Huntingtons Chorea",
      label: "Huntingtons Chorea",
    },
    {
      value: "Huntingtons Disease",
      label: "Huntingtons Disease",
    },
    {
      value: "Idiopathic Epilepsy",
      label: "Idiopathic Epilepsy",
    },
    {
      value: "Infantile Spinal Muscular Atrophy, Type I [Werdnig-Hoffman]",
      label: "Infantile Spinal Muscular Atrophy, Type I [Werdnig-Hoffman]",
    },
    {
      value: "Jacksonian Epilepsy",
      label: "Jacksonian Epilepsy",
    },
    {
      value: "Korsakoff Syndrome",
      label: "Korsakoff Syndrome",
    },
    {
      value: "Lateral Sclerosis",
      label: "Lateral Sclerosis",
    },
    {
      value: "M Gravis",
      label: "M Gravis",
    },
    {
      value: "Migraine",
      label: "Migraine",
    },
    {
      value: "Mononeuropathies Of Upper Limb",
      label: "Mononeuropathies Of Upper Limb",
    },
    {
      value: "Motor Neurone Disease",
      label: "Motor Neurone Disease",
    },
    {
      value: "Multi-Infarct Dementia",
      label: "Multi-Infarct Dementia",
    },
    {
      value: "Muscular Atrophy Spinal",
      label: "Muscular Atrophy Spinal",
    },
    {
      value: "Muscular Dystrophy",
      label: "Muscular Dystrophy",
    },
    {
      value: "Myasthenia Gravis",
      label: "Myasthenia Gravis",
    },
    {
      value: "Myelopathy",
      label: "Myelopathy",
    },
    {
      value: "Myopathy",
      label: "Myopathy",
    },
    {
      value: "Myotonia",
      label: "Myotonia",
    },
    {
      value: "Myotonia Congenital",
      label: "Myotonia Congenital",
    },
    {
      value: "Myotonic Disorder",
      label: "Myotonic Disorder",
    },
    {
      value: "Narcolepsy",
      label: "Narcolepsy",
    },
    {
      value: "Narcolepsy And Cataplexy",
      label: "Narcolepsy And Cataplexy",
    },
    {
      value: "Neuropathy",
      label: "Neuropathy",
    },
    {
      value: "Nocturnal Epilepsy",
      label: "Nocturnal Epilepsy",
    },
    {
      value: "Other Acute Disseminated Demyelination",
      label: "Other Acute Disseminated Demyelination",
    },
    {
      value: "Other Headache Syndromes",
      label: "Other Headache Syndromes",
    },
    {
      value: "Other Specified Degenerative Diseases Of Basal Ganglia",
      label: "Other Specified Degenerative Diseases Of Basal Ganglia",
    },
    {
      value: "Paralysis Agitans",
      label: "Paralysis Agitans",
    },
    {
      value: "Paramyotonia Congenita",
      label: "Paramyotonia Congenita",
    },
    {
      value: "Parkinsonism",
      label: "Parkinsonism",
    },
    {
      value: "Parkinsons",
      label: "Parkinsons",
    },
    {
      value: "Parkinsons Disease",
      label: "Parkinsons Disease",
    },
    {
      value: "PD",
      label: "PD",
    },
    {
      value: "PD - Parkinsons Disease",
      label: "PD - Parkinsons Disease",
    },
    {
      value: "Peripheral Neuropathy",
      label: "Peripheral Neuropathy",
    },
    {
      value: "Petit Mal Epilepsy",
      label: "Petit Mal Epilepsy",
    },
    {
      value: "Petit Mal Seizures",
      label: "Petit Mal Seizures",
    },
    {
      value: "Picks Disease",
      label: "Picks Disease",
    },
    {
      value: "PM Epilepsy",
      label: "PM Epilepsy",
    },
    {
      value: "Polyneuropathy",
      label: "Polyneuropathy",
    },
    {
      value: "Polyneuropathy Alcoholic",
      label: "Polyneuropathy Alcoholic",
    },
    {
      value: "Polyneuropathy Drug Induced",
      label: "Polyneuropathy Drug Induced",
    },
    {
      value: "Polyneuropathy Toxic",
      label: "Polyneuropathy Toxic",
    },
    {
      value: "Post Traumatic Epilepsy",
      label: "Post Traumatic Epilepsy",
    },
    {
      value: "Post Viral Fatigue Syndrome",
      label: "Post Viral Fatigue Syndrome",
    },
    {
      value: "Presenile Dementia",
      label: "Presenile Dementia",
    },
    {
      value: "Psychosis Senile",
      label: "Psychosis Senile",
    },
    {
      value: "Reyes Syndrome",
      label: "Reyes Syndrome",
    },
    {
      value: "Seizure Disorder",
      label: "Seizure Disorder",
    },
    {
      value: "Seizures",
      label: "Seizures",
    },
    {
      value: "Senile Brain Degeneration",
      label: "Senile Brain Degeneration",
    },
    {
      value: "Senile Dementia",
      label: "Senile Dementia",
    },
    {
      value: "Spastic Diplegia",
      label: "Spastic Diplegia",
    },
    {
      value: "Spastic Hemiplegia",
      label: "Spastic Hemiplegia",
    },
    {
      value: "Spinal Cord Compression",
      label: "Spinal Cord Compression",
    },
    {
      value: "Status Epilepticus",
      label: "Status Epilepticus",
    },
    {
      value: "Syringomyelia",
      label: "Syringomyelia",
    },
    {
      value: "Syringomyelia And Syringobulbia",
      label: "Syringomyelia And Syringobulbia",
    },
    {
      value: "Temporal Lobe Epilepsy",
      label: "Temporal Lobe Epilepsy",
    },
    {
      value: "Vascular Dementia",
      label: "Vascular Dementia",
    },
    {
      value: "Werdnig Hoffmann",
      label: "Werdnig Hoffmann",
    },
    {
      value: "Werdnig Hoffmann Disease",
      label: "Werdnig Hoffmann Disease",
    },
    {
      value: "Werdnig Hoffmann Syndrome",
      label: "Werdnig Hoffmann Syndrome",
    },
  ],
  OtherConditions: [
    {
      value: "Abdominal Pain",
      label: "Abdominal Pain",
    },
    {
      value: "Abnormality Of Red Blood Cells",
      label: "Abnormality Of Red Blood Cells",
    },
    {
      value: "Abscess",
      label: "Abscess",
    },
    {
      value: "Abscess Of Tonsil",
      label: "Abscess Of Tonsil",
    },
    {
      value: "Abscess On Liver",
      label: "Abscess On Liver",
    },
    {
      value: "Achalasia",
      label: "Achalasia",
    },
    {
      value: "Acquired Coagulation Deficiency",
      label: "Acquired Coagulation Deficiency",
    },
    {
      value: "Acquired Immune Deficiency Syndrome",
      label: "Acquired Immune Deficiency Syndrome",
    },
    {
      value: "Acromegaly",
      label: "Acromegaly",
    },
    {
      value: "Acromegaly And Pituitary Gigantism",
      label: "Acromegaly And Pituitary Gigantism",
    },
    {
      value: "Actinic Keratosis",
      label: "Actinic Keratosis",
    },
    {
      value: "Acute Cholangitis",
      label: "Acute Cholangitis",
    },
    {
      value: "Acute Delirium",
      label: "Acute Delirium",
    },
    {
      value: "Acute Gallbladder Inflammation",
      label: "Acute Gallbladder Inflammation",
    },
    {
      value: "Acute Glomerulonephritis",
      label: "Acute Glomerulonephritis",
    },
    {
      value: "Acute Miliary Tuberculosis, Unspecified",
      label: "Acute Miliary Tuberculosis, Unspecified",
    },
    {
      value: "Acute Pancreatitis",
      label: "Acute Pancreatitis",
    },
    {
      value: "Acute Prostatitis",
      label: "Acute Prostatitis",
    },
    {
      value: "Acute Renal Failure",
      label: "Acute Renal Failure",
    },
    {
      value: "Addison Disease",
      label: "Addison Disease",
    },
    {
      value: "Addison Tuberculosis",
      label: "Addison Tuberculosis",
    },
    {
      value: "Addison Tuberculous",
      label: "Addison Tuberculous",
    },
    {
      value: "Addisonian Crisis",
      label: "Addisonian Crisis",
    },
    {
      value: "Addisons Disease",
      label: "Addisons Disease",
    },
    {
      value: "Addisons Tuberculous",
      label: "Addisons Tuberculous",
    },
    {
      value: "Adnexitis Tuberculous",
      label: "Adnexitis Tuberculous",
    },
    {
      value: "Adrenal Cortex Atrophy",
      label: "Adrenal Cortex Atrophy",
    },
    {
      value: "Adrenal Cortex Calcification",
      label: "Adrenal Cortex Calcification",
    },
    {
      value: "Adrenal Disorder",
      label: "Adrenal Disorder",
    },
    {
      value: "Adrenal Gland TB",
      label: "Adrenal Gland TB",
    },
    {
      value: "Adrenal Gland Tuberculosis",
      label: "Adrenal Gland Tuberculosis",
    },
    {
      value: "Agammaglobulinaemia",
      label: "Agammaglobulinaemia",
    },
    {
      value: "Agranulocytosis",
      label: "Agranulocytosis",
    },
    {
      value: "Agyria",
      label: "Agyria",
    },
    {
      value: "AIDS",
      label: "AIDS",
    },
    {
      value: "AIDS Related Complex",
      label: "AIDS Related Complex",
    },
    {
      value: "Albuminuria",
      label: "Albuminuria",
    },
    {
      value: "Alcohol Abuse",
      label: "Alcohol Abuse",
    },
    {
      value: "Alcohol Dependence",
      label: "Alcohol Dependence",
    },
    {
      value: "Alcohol Misuse",
      label: "Alcohol Misuse",
    },
    {
      value: "Alcoholic",
      label: "Alcoholic",
    },
    {
      value: "Alcoholic Cirrhosis",
      label: "Alcoholic Cirrhosis",
    },
    {
      value: "Alcoholic Cirrhosis Of Liver",
      label: "Alcoholic Cirrhosis Of Liver",
    },
    {
      value: "Alcoholic Dementia",
      label: "Alcoholic Dementia",
    },
    {
      value: "Alcoholic Hallucinations",
      label: "Alcoholic Hallucinations",
    },
    {
      value: "Alcoholic Liver Damage",
      label: "Alcoholic Liver Damage",
    },
    {
      value: "Alcoholic Liver Disease",
      label: "Alcoholic Liver Disease",
    },
    {
      value: "Alcoholic Psychoses",
      label: "Alcoholic Psychoses",
    },
    {
      value: "Alcoholic Psychosis",
      label: "Alcoholic Psychosis",
    },
    {
      value: "Alcoholism",
      label: "Alcoholism",
    },
    {
      value: "Alopecia",
      label: "Alopecia",
    },
    {
      value: "Alpha 1 Antitrypsin Deficiency",
      label: "Alpha 1 Antitrypsin Deficiency",
    },
    {
      value: "Alpha1 Antitrypsin Deficiency",
      label: "Alpha1 Antitrypsin Deficiency",
    },
    {
      value: "Amputation Disease Related",
      label: "Amputation Disease Related",
    },
    {
      value: "Amyloid",
      label: "Amyloid",
    },
    {
      value: "Amyloid Kidney",
      label: "Amyloid Kidney",
    },
    {
      value: "Amyloidosis",
      label: "Amyloidosis",
    },
    {
      value: "Anaemia",
      label: "Anaemia",
    },
    {
      value: "Anaemia Aplastic",
      label: "Anaemia Aplastic",
    },
    {
      value: "Anaemia Haemolytic",
      label: "Anaemia Haemolytic",
    },
    {
      value: "Anaemia Haemolytic Hereditary",
      label: "Anaemia Haemolytic Hereditary",
    },
    {
      value: "Anal Abscess",
      label: "Anal Abscess",
    },
    {
      value: "Anal Fissure",
      label: "Anal Fissure",
    },
    {
      value: "Anal Polyp",
      label: "Anal Polyp",
    },
    {
      value: "Anal Prolapse",
      label: "Anal Prolapse",
    },
    {
      value: "Aneurysm Cerebral",
      label: "Aneurysm Cerebral",
    },
    {
      value: "Aneurysm Of Pulmonary Artery",
      label: "Aneurysm Of Pulmonary Artery",
    },
    {
      value: "Aneurysm Pulmonary Artery",
      label: "Aneurysm Pulmonary Artery",
    },
    {
      value: "Ankylosing Spondylitis",
      label: "Ankylosing Spondylitis",
    },
    {
      value: "Anomaly Digestive System",
      label: "Anomaly Digestive System",
    },
    {
      value: "Anti Stomach Ulcer",
      label: "Anti Stomach Ulcer",
    },
    {
      value: "Anuria",
      label: "Anuria",
    },
    {
      value: "Apallic Syndrome",
      label: "Apallic Syndrome",
    },
    {
      value: "Appendicectomy",
      label: "Appendicectomy",
    },
    {
      value: "Appendicitis",
      label: "Appendicitis",
    },
    {
      value: "Appendix Perforation",
      label: "Appendix Perforation",
    },
    {
      value: "Arm Arterial Embolism",
      label: "Arm Arterial Embolism",
    },
    {
      value: "Arm Monoplegia",
      label: "Arm Monoplegia",
    },
    {
      value: "Arm Paralysis",
      label: "Arm Paralysis",
    },
    {
      value: "Arm Paralysis Unilateral",
      label: "Arm Paralysis Unilateral",
    },
    {
      value: "Arms Paralysis",
      label: "Arms Paralysis",
    },
    {
      value: "Arnold Chiari Syndrome",
      label: "Arnold Chiari Syndrome",
    },
    {
      value: "Arnold-Chiari Syndrome",
      label: "Arnold-Chiari Syndrome",
    },
    {
      value: "Arterial Occlusive Disease",
      label: "Arterial Occlusive Disease",
    },
    {
      value: "Arteriosclerosis",
      label: "Arteriosclerosis",
    },
    {
      value: "Arteriosclerosis Cerebral",
      label: "Arteriosclerosis Cerebral",
    },
    {
      value: "Arteriosclerosis Obliterans",
      label: "Arteriosclerosis Obliterans",
    },
    {
      value: "Arteriovenous Fistula Of Pulmonary Vessels",
      label: "Arteriovenous Fistula Of Pulmonary Vessels",
    },
    {
      value: "Arteritis",
      label: "Arteritis",
    },
    {
      value: "Arteritis Cerebral",
      label: "Arteritis Cerebral",
    },
    {
      value: "Arteritis Nodosa",
      label: "Arteritis Nodosa",
    },
    {
      value: "Artery Cerebral Occlusion",
      label: "Artery Cerebral Occlusion",
    },
    {
      value: "Artery Cerebral Thrombosis",
      label: "Artery Cerebral Thrombosis",
    },
    {
      value: "Artery Embolism",
      label: "Artery Embolism",
    },
    {
      value: "Artery Occlusion",
      label: "Artery Occlusion",
    },
    {
      value: "Artery Spasm",
      label: "Artery Spasm",
    },
    {
      value: "Artery Stricture",
      label: "Artery Stricture",
    },
    {
      value: "Artery Thrombosis",
      label: "Artery Thrombosis",
    },
    {
      value: "Arthritis",
      label: "Arthritis",
    },
    {
      value: "Arthritis Rheumatic",
      label: "Arthritis Rheumatic",
    },
    {
      value: "Arthritis Rheumatoid",
      label: "Arthritis Rheumatoid",
    },
    {
      value: "Arthritis Tuberculous",
      label: "Arthritis Tuberculous",
    },
    {
      value: "Arthropathy Psoriasis",
      label: "Arthropathy Psoriasis",
    },
    {
      value: "Arthrosis",
      label: "Arthrosis",
    },
    {
      value: "Arthrosis Polyarticular",
      label: "Arthrosis Polyarticular",
    },
    {
      value: "Ascites",
      label: "Ascites",
    },
    {
      value: "Aspergers Syndrome",
      label: "Aspergers Syndrome",
    },
    {
      value: "Atheroma",
      label: "Atheroma",
    },
    {
      value: "Atheroma Cerebral Artery",
      label: "Atheroma Cerebral Artery",
    },
    {
      value: "Atherosclerosis",
      label: "Atherosclerosis",
    },
    {
      value: "Atherosclerosis Of Arteries Of Extremities",
      label: "Atherosclerosis Of Arteries Of Extremities",
    },
    {
      value: "Atherosclerosis Peripheral",
      label: "Atherosclerosis Peripheral",
    },
    {
      value: "Atopic Eczema",
      label: "Atopic Eczema",
    },
    {
      value: "Attack Cerebral Ischaemic",
      label: "Attack Cerebral Ischaemic",
    },
    {
      value: "Autism",
      label: "Autism",
    },
    {
      value: "Autism Infantile",
      label: "Autism Infantile",
    },
    {
      value: "Autoimmune Disease",
      label: "Autoimmune Disease",
    },
    {
      value: "Autoimmune Haemolytic Anaemia",
      label: "Autoimmune Haemolytic Anaemia",
    },
    {
      value: "B12 Deficiency Anaemia",
      label: "B12 Deficiency Anaemia",
    },
    {
      value: "Back Pain",
      label: "Back Pain",
    },
    {
      value: "Bakers Cyst",
      label: "Bakers Cyst",
    },
    {
      value: "Balanitis",
      label: "Balanitis",
    },
    {
      value: "Barrets Esophagus",
      label: "Barrets Esophagus",
    },
    {
      value: "Barrets Oesophagus",
      label: "Barrets Oesophagus",
    },
    {
      value: "Barretts Ulcer",
      label: "Barretts Ulcer",
    },
    {
      value: "Basilar Aneurysm",
      label: "Basilar Aneurysm",
    },
    {
      value: "Basilar Stenosis",
      label: "Basilar Stenosis",
    },
    {
      value: "Bells Palsy",
      label: "Bells Palsy",
    },
    {
      value: "Benign Intracranial Hypertension",
      label: "Benign Intracranial Hypertension",
    },
    {
      value: "Benign Prostatic Hypertrophy",
      label: "Benign Prostatic Hypertrophy",
    },
    {
      value: "Berry Aneurysm",
      label: "Berry Aneurysm",
    },
    {
      value: "Bile Duct Obstruction",
      label: "Bile Duct Obstruction",
    },
    {
      value: "Bileduct Inflammation Acute",
      label: "Bileduct Inflammation Acute",
    },
    {
      value: "Bileduct Inflammation Chronic",
      label: "Bileduct Inflammation Chronic",
    },
    {
      value: "Bileduct Inflammation Sclerosing",
      label: "Bileduct Inflammation Sclerosing",
    },
    {
      value: "Bileduct Inflammation Stenosing",
      label: "Bileduct Inflammation Stenosing",
    },
    {
      value: "Biliary Cirrhosis",
      label: "Biliary Cirrhosis",
    },
    {
      value: "Biliferous Duct Inflammation",
      label: "Biliferous Duct Inflammation",
    },
    {
      value: "Binge Drinker",
      label: "Binge Drinker",
    },
    {
      value: "Bipolar Disorder",
      label: "Bipolar Disorder",
    },
    {
      value: "Bird Flu",
      label: "Bird Flu",
    },
    {
      value: "Bladder Disorder",
      label: "Bladder Disorder",
    },
    {
      value: "Bladder Infection",
      label: "Bladder Infection",
    },
    {
      value: "Bladder Neck Obstruction",
      label: "Bladder Neck Obstruction",
    },
    {
      value: "Bladder Stone",
      label: "Bladder Stone",
    },
    {
      value: "Bladder TB",
      label: "Bladder TB",
    },
    {
      value: "Bladder Tuberculosis",
      label: "Bladder Tuberculosis",
    },
    {
      value: "Bladder Urination Abnormality",
      label: "Bladder Urination Abnormality",
    },
    {
      value: "Blepharitis",
      label: "Blepharitis",
    },
    {
      value: "Blind",
      label: "Blind",
    },
    {
      value: "Blindness",
      label: "Blindness",
    },
    {
      value: "Blocked Cerebral Artery",
      label: "Blocked Cerebral Artery",
    },
    {
      value: "Blocked Peripheral Artery",
      label: "Blocked Peripheral Artery",
    },
    {
      value: "Blod Clot In Leg",
      label: "Blod Clot In Leg",
    },
    {
      value: "Blood Clot Brain",
      label: "Blood Clot Brain",
    },
    {
      value: "Blood Disease",
      label: "Blood Disease",
    },
    {
      value: "Blood Disorder",
      label: "Blood Disorder",
    },
    {
      value: "Blood Dyscrasia",
      label: "Blood Dyscrasia",
    },
    {
      value: "Blood Effusion Intracerebral",
      label: "Blood Effusion Intracerebral",
    },
    {
      value: "Bone Cyst",
      label: "Bone Cyst",
    },
    {
      value: "Bone Spur",
      label: "Bone Spur",
    },
    {
      value: "Bone Tuberculosis",
      label: "Bone Tuberculosis",
    },
    {
      value: "Brain Abscess Tuberculous",
      label: "Brain Abscess Tuberculous",
    },
    {
      value: "Brain Haemorrhage",
      label: "Brain Haemorrhage",
    },
    {
      value: "Brain Infarct",
      label: "Brain Infarct",
    },
    {
      value: "Brain Tuberculosis",
      label: "Brain Tuberculosis",
    },
    {
      value: "Breast Cyst",
      label: "Breast Cyst",
    },
    {
      value: "Broken Neck",
      label: "Broken Neck",
    },
    {
      value: "Buergers Disease",
      label: "Buergers Disease",
    },
    {
      value: "Bursitis",
      label: "Bursitis",
    },
    {
      value: "Calcification Of Muscle",
      label: "Calcification Of Muscle",
    },
    {
      value: "Carbohydrate Malabsorption",
      label: "Carbohydrate Malabsorption",
    },
    {
      value: "Carotid Artery Stenosis",
      label: "Carotid Artery Stenosis",
    },
    {
      value: "Carotid Occlusion",
      label: "Carotid Occlusion",
    },
    {
      value: "Carotid Stenosis",
      label: "Carotid Stenosis",
    },
    {
      value: "Carpal Tunnel Syndrome",
      label: "Carpal Tunnel Syndrome",
    },
    {
      value: "Cartilage Damage",
      label: "Cartilage Damage",
    },
    {
      value: "Cataract",
      label: "Cataract",
    },
    {
      value: "Catatonic Schizophrenia",
      label: "Catatonic Schizophrenia",
    },
    {
      value: "Cell Giant Arteritis",
      label: "Cell Giant Arteritis",
    },
    {
      value: "Cellulitis",
      label: "Cellulitis",
    },
    {
      value: "Central Nervous System Tuberculosis",
      label: "Central Nervous System Tuberculosis",
    },
    {
      value: "Cerebral Arteries Thrombosis",
      label: "Cerebral Arteries Thrombosis",
    },
    {
      value: "Cerebral Arteriovenous Malformation",
      label: "Cerebral Arteriovenous Malformation",
    },
    {
      value: "Cerebral Artery Clot",
      label: "Cerebral Artery Clot",
    },
    {
      value: "Cerebral Atherosclerosis",
      label: "Cerebral Atherosclerosis",
    },
    {
      value: "Cerebral Embolism",
      label: "Cerebral Embolism",
    },
    {
      value: "Cerebral Haemorrhage",
      label: "Cerebral Haemorrhage",
    },
    {
      value: "Cerebral Haemorrhage/Bleed",
      label: "Cerebral Haemorrhage/Bleed",
    },
    {
      value: "Cerebral Infarct",
      label: "Cerebral Infarct",
    },
    {
      value: "Cerebral Ischaemia",
      label: "Cerebral Ischaemia",
    },
    {
      value: "Cerebral Oedema",
      label: "Cerebral Oedema",
    },
    {
      value: "Cerebral Thrombosis",
      label: "Cerebral Thrombosis",
    },
    {
      value: "Cerebrovascular Accident",
      label: "Cerebrovascular Accident",
    },
    {
      value: "Cerebrovascular Disease",
      label: "Cerebrovascular Disease",
    },
    {
      value: "Cerebrovascular Rupture",
      label: "Cerebrovascular Rupture",
    },
    {
      value: "Cervical Spondylosis",
      label: "Cervical Spondylosis",
    },
    {
      value: "Cervical Syndrome",
      label: "Cervical Syndrome",
    },
    {
      value: "Chest Pain",
      label: "Chest Pain",
    },
    {
      value: "Childhood Psychosis",
      label: "Childhood Psychosis",
    },
    {
      value: "Childhood Schizophrenia",
      label: "Childhood Schizophrenia",
    },
    {
      value: "Choanal Atresia",
      label: "Choanal Atresia",
    },
    {
      value: "Cholangitis",
      label: "Cholangitis",
    },
    {
      value: "Cholangitis Acute",
      label: "Cholangitis Acute",
    },
    {
      value: "Cholangitis Chronic",
      label: "Cholangitis Chronic",
    },
    {
      value: "Cholangitis Relapse",
      label: "Cholangitis Relapse",
    },
    {
      value: "Cholangitis Sclerosing",
      label: "Cholangitis Sclerosing",
    },
    {
      value: "Cholangitis Stenosing",
      label: "Cholangitis Stenosing",
    },
    {
      value: "Cholecystitis",
      label: "Cholecystitis",
    },
    {
      value: "Cholesteatoma",
      label: "Cholesteatoma",
    },
    {
      value: "Chondritis",
      label: "Chondritis",
    },
    {
      value: "Choroidal Detachment",
      label: "Choroidal Detachment",
    },
    {
      value: "Christmas Disease",
      label: "Christmas Disease",
    },
    {
      value: "Chronic Active Hepatitis",
      label: "Chronic Active Hepatitis",
    },
    {
      value: "Chronic Colitis",
      label: "Chronic Colitis",
    },
    {
      value: "Chronic Glomerulonephritis",
      label: "Chronic Glomerulonephritis",
    },
    {
      value: "Chronic Hepatitis",
      label: "Chronic Hepatitis",
    },
    {
      value: "Chronic Hepatitis B",
      label: "Chronic Hepatitis B",
    },
    {
      value: "Chronic Hepatitis C",
      label: "Chronic Hepatitis C",
    },
    {
      value: "Chronic Kidney Disease",
      label: "Chronic Kidney Disease",
    },
    {
      value: "Chronic Liver Disease",
      label: "Chronic Liver Disease",
    },
    {
      value: "Chronic Nephritis",
      label: "Chronic Nephritis",
    },
    {
      value: "Chronic Osteomyelitis",
      label: "Chronic Osteomyelitis",
    },
    {
      value: "Chronic Pancreatitis",
      label: "Chronic Pancreatitis",
    },
    {
      value: "Chronic Prostatitis",
      label: "Chronic Prostatitis",
    },
    {
      value: "Chronic Renal Failure",
      label: "Chronic Renal Failure",
    },
    {
      value: "Chronic Renal Insufficiency",
      label: "Chronic Renal Insufficiency",
    },
    {
      value: "Chronic Rheumatoid Arthritis",
      label: "Chronic Rheumatoid Arthritis",
    },
    {
      value: "Chronic Tubulo-Interstitial Nephritis, Unspecified",
      label: "Chronic Tubulo-Interstitial Nephritis, Unspecified",
    },
    {
      value: "Chronic Vegetative State",
      label: "Chronic Vegetative State",
    },
    {
      value: "Churg Strauss Syndrome",
      label: "Churg Strauss Syndrome",
    },
    {
      value: "Cirrhosis",
      label: "Cirrhosis",
    },
    {
      value: "Cirrhosis Biliary",
      label: "Cirrhosis Biliary",
    },
    {
      value: "Cirrhosis Of The Liver",
      label: "Cirrhosis Of The Liver",
    },
    {
      value: "Cirrhosis Portal",
      label: "Cirrhosis Portal",
    },
    {
      value: "Climacteric",
      label: "Climacteric",
    },
    {
      value: "CNS TB",
      label: "CNS TB",
    },
    {
      value: "Coagulation Defect",
      label: "Coagulation Defect",
    },
    {
      value: "Coagulopathy",
      label: "Coagulopathy",
    },
    {
      value: "Coats Retinopathy",
      label: "Coats Retinopathy",
    },
    {
      value: "Coeliac Disease",
      label: "Coeliac Disease",
    },
    {
      value: "Colectomy",
      label: "Colectomy",
    },
    {
      value: "Colic",
      label: "Colic",
    },
    {
      value: "Colitis",
      label: "Colitis",
    },
    {
      value: "Colitis Chronic",
      label: "Colitis Chronic",
    },
    {
      value: "Colitis Ulcerous",
      label: "Colitis Ulcerous",
    },
    {
      value: "Collagen Disease",
      label: "Collagen Disease",
    },
    {
      value: "Coma",
      label: "Coma",
    },
    {
      value: "Completely Paralysed",
      label: "Completely Paralysed",
    },
    {
      value: "Confusional State Acute",
      label: "Confusional State Acute",
    },
    {
      value: "Confusional State Reactive",
      label: "Confusional State Reactive",
    },
    {
      value: "Congenital Clotting Disorder",
      label: "Congenital Clotting Disorder",
    },
    {
      value: "Congenital Hipjoint Dislocation",
      label: "Congenital Hipjoint Dislocation",
    },
    {
      value: "Congenital Hypertrophic Pyloric Stenosis",
      label: "Congenital Hypertrophic Pyloric Stenosis",
    },
    {
      value: "Congenital Immune Deficiency Syndrome",
      label: "Congenital Immune Deficiency Syndrome",
    },
    {
      value: "Congenital Immunodeficiency",
      label: "Congenital Immunodeficiency",
    },
    {
      value: "Congenital Megacolon",
      label: "Congenital Megacolon",
    },
    {
      value: "Conjunctivitis",
      label: "Conjunctivitis",
    },
    {
      value: "Connective Tissue Disease",
      label: "Connective Tissue Disease",
    },
    {
      value: "Constipation",
      label: "Constipation",
    },
    {
      value: "Corticoadrenal Hyperfunction",
      label: "Corticoadrenal Hyperfunction",
    },
    {
      value: "Corticoadrenal Insufficiency",
      label: "Corticoadrenal Insufficiency",
    },
    {
      value: "Costochondritis",
      label: "Costochondritis",
    },
    {
      value: "Coxa Tuberculosis",
      label: "Coxa Tuberculosis",
    },
    {
      value: "Cramp",
      label: "Cramp",
    },
    {
      value: "Craniosynostosis",
      label: "Craniosynostosis",
    },
    {
      value: "CRF",
      label: "CRF",
    },
    {
      value: "Crohns Disease",
      label: "Crohns Disease",
    },
    {
      value: "Crohns Disease [Regional Enteritis]",
      label: "Crohns Disease [Regional Enteritis]",
    },
    {
      value: "Crohns Disease Of Large Intestine",
      label: "Crohns Disease Of Large Intestine",
    },
    {
      value: "Crystal Arthropathy",
      label: "Crystal Arthropathy",
    },
    {
      value: "Cushings Disease",
      label: "Cushings Disease",
    },
    {
      value: "Cushings Syndrome",
      label: "Cushings Syndrome",
    },
    {
      value: "Cyst On Liver",
      label: "Cyst On Liver",
    },
    {
      value: "Cystic Endometrial Hyperplasia",
      label: "Cystic Endometrial Hyperplasia",
    },
    {
      value: "Cystitis",
      label: "Cystitis",
    },
    {
      value: "Deafness",
      label: "Deafness",
    },
    {
      value: "Deep Vein Thrombosis",
      label: "Deep Vein Thrombosis",
    },
    {
      value: "Deficiency Anaemia",
      label: "Deficiency Anaemia",
    },
    {
      value: "Degenerative Disc Disease",
      label: "Degenerative Disc Disease",
    },
    {
      value: "Degenerative Spinal Column Disease",
      label: "Degenerative Spinal Column Disease",
    },
    {
      value: "Delirium, Unspecified",
      label: "Delirium, Unspecified",
    },
    {
      value: "Depression",
      label: "Depression",
    },
    {
      value: "Depression Acute",
      label: "Depression Acute",
    },
    {
      value: "Depression Endogenous",
      label: "Depression Endogenous",
    },
    {
      value: "Depression Psychosis",
      label: "Depression Psychosis",
    },
    {
      value: "Depression Single Episode",
      label: "Depression Single Episode",
    },
    {
      value: "Depressive Episode, Unspecified",
      label: "Depressive Episode, Unspecified",
    },
    {
      value: "Dermatitis",
      label: "Dermatitis",
    },
    {
      value: "Dermatitis Herpetiformis",
      label: "Dermatitis Herpetiformis",
    },
    {
      value: "Dermatomyositis",
      label: "Dermatomyositis",
    },
    {
      value: "Dermatosis",
      label: "Dermatosis",
    },
    {
      value: "Diabetes Insipidus",
      label: "Diabetes Insipidus",
    },
    {
      value: "Diabetic Glomerulosclerosis",
      label: "Diabetic Glomerulosclerosis",
    },
    {
      value: "Diplegia",
      label: "Diplegia",
    },
    {
      value: "Disc Prolapse",
      label: "Disc Prolapse",
    },
    {
      value: "Disorder Of Breast",
      label: "Disorder Of Breast",
    },
    {
      value: "Disorder Of Vestibular Function",
      label: "Disorder Of Vestibular Function",
    },
    {
      value: "Disorders Of Iron Metabolism",
      label: "Disorders Of Iron Metabolism",
    },
    {
      value: "Disorders Of Lipoprotein Metabolism And Other Lipidaemias",
      label: "Disorders Of Lipoprotein Metabolism And Other Lipidaemias",
    },
    {
      value: "Diverticulitis",
      label: "Diverticulitis",
    },
    {
      value: "DLE",
      label: "DLE",
    },
    {
      value: "Double Lung Transplant",
      label: "Double Lung Transplant",
    },
    {
      value: "Downs Syndrome",
      label: "Downs Syndrome",
    },
    {
      value: "Drug Dependence",
      label: "Drug Dependence",
    },
    {
      value: "Duodenal Ulcer",
      label: "Duodenal Ulcer",
    },
    {
      value: "Duodenal Ulcer With Perforation",
      label: "Duodenal Ulcer With Perforation",
    },
    {
      value: "Duodenitis",
      label: "Duodenitis",
    },
    {
      value: "Duodenum Anomaly",
      label: "Duodenum Anomaly",
    },
    {
      value: "Dupuytrens Contracture",
      label: "Dupuytrens Contracture",
    },
    {
      value: "DVT Deep Vein Thrombosis",
      label: "DVT Deep Vein Thrombosis",
    },
    {
      value: "Dwarfism",
      label: "Dwarfism",
    },
    {
      value: "Dyspepsia",
      label: "Dyspepsia",
    },
    {
      value: "Dysphagia",
      label: "Dysphagia",
    },
    {
      value: "Dystonia",
      label: "Dystonia",
    },
    {
      value: "Ear Tuberculosis",
      label: "Ear Tuberculosis",
    },
    {
      value: "Ectopic Kidney",
      label: "Ectopic Kidney",
    },
    {
      value: "Ectopic Pregnancy",
      label: "Ectopic Pregnancy",
    },
    {
      value: "Eczema",
      label: "Eczema",
    },
    {
      value: "Edwards Syndrome",
      label: "Edwards Syndrome",
    },
    {
      value: "Embolism Arterial Peripheral",
      label: "Embolism Arterial Peripheral",
    },
    {
      value: "Emotional State Reactive",
      label: "Emotional State Reactive",
    },
    {
      value: "Encephalocele",
      label: "Encephalocele",
    },
    {
      value: "End Stage Renal Failure",
      label: "End Stage Renal Failure",
    },
    {
      value: "Endocarditis Tuberculous",
      label: "Endocarditis Tuberculous",
    },
    {
      value: "Endocardium Tuberculosis",
      label: "Endocardium Tuberculosis",
    },
    {
      value: "Endocrine Disorder",
      label: "Endocrine Disorder",
    },
    {
      value: "Endometriosis",
      label: "Endometriosis",
    },
    {
      value: "Enlarged Liver",
      label: "Enlarged Liver",
    },
    {
      value: "Enlarged Prostate",
      label: "Enlarged Prostate",
    },
    {
      value: "Enlarged Spleen",
      label: "Enlarged Spleen",
    },
    {
      value: "Enlarged Thyroid",
      label: "Enlarged Thyroid",
    },
    {
      value: "Enteritis",
      label: "Enteritis",
    },
    {
      value: "Enteritis Chronic",
      label: "Enteritis Chronic",
    },
    {
      value: "Enteritis Tuberculous",
      label: "Enteritis Tuberculous",
    },
    {
      value: "Enteritis Ulcerous",
      label: "Enteritis Ulcerous",
    },
    {
      value: "Eosinophilia",
      label: "Eosinophilia",
    },
    {
      value: "Epidermal Thickening",
      label: "Epidermal Thickening",
    },
    {
      value: "Epididymal TB",
      label: "Epididymal TB",
    },
    {
      value: "Epididymis Tuberculosis",
      label: "Epididymis Tuberculosis",
    },
    {
      value: "Epistaxis",
      label: "Epistaxis",
    },
    {
      value: "Erosion And Ectropion Of Cervix Uteri",
      label: "Erosion And Ectropion Of Cervix Uteri",
    },
    {
      value: "Erythema",
      label: "Erythema",
    },
    {
      value: "Esophageal Ulcer",
      label: "Esophageal Ulcer",
    },
    {
      value: "Esophageal Varices",
      label: "Esophageal Varices",
    },
    {
      value: "Esophagus Anomaly",
      label: "Esophagus Anomaly",
    },
    {
      value: "Esophagus Rupture",
      label: "Esophagus Rupture",
    },
    {
      value: "Esrd",
      label: "Esrd",
    },
    {
      value: "Exudative Retinopathy",
      label: "Exudative Retinopathy",
    },
    {
      value: "Eye TB",
      label: "Eye TB",
    },
    {
      value: "Eye Tuberculous",
      label: "Eye Tuberculous",
    },
    {
      value: "Factor IX Deficiency",
      label: "Factor IX Deficiency",
    },
    {
      value: "Factor VIII Deficiency",
      label: "Factor VIII Deficiency",
    },
    {
      value: "Factor XI Deficiency",
      label: "Factor XI Deficiency",
    },
    {
      value: "Fanconis Anaemia",
      label: "Fanconis Anaemia",
    },
    {
      value: "Fasciitis",
      label: "Fasciitis",
    },
    {
      value: "Fatty Liver",
      label: "Fatty Liver",
    },
    {
      value: "Fatty Liver Alcoholic",
      label: "Fatty Liver Alcoholic",
    },
    {
      value: "Fatty Liver Degeneration",
      label: "Fatty Liver Degeneration",
    },
    {
      value: "Fibrocystic Breast Disease",
      label: "Fibrocystic Breast Disease",
    },
    {
      value: "Fibrocystic Disease",
      label: "Fibrocystic Disease",
    },
    {
      value: "Fibrocystic Kidney",
      label: "Fibrocystic Kidney",
    },
    {
      value: "Fibromyalgia",
      label: "Fibromyalgia",
    },
    {
      value: "Fibrositis",
      label: "Fibrositis",
    },
    {
      value: "Fistula",
      label: "Fistula",
    },
    {
      value: "Fluid Stomach",
      label: "Fluid Stomach",
    },
    {
      value: "Folate Deficiency Anaemia",
      label: "Folate Deficiency Anaemia",
    },
    {
      value: "Fracture Of Neck, Part Unspecified",
      label: "Fracture Of Neck, Part Unspecified",
    },
    {
      value: "Frozen Shoulder",
      label: "Frozen Shoulder",
    },
    {
      value: "Full Blown AIDS",
      label: "Full Blown AIDS",
    },
    {
      value: "Gallbladder Disorder",
      label: "Gallbladder Disorder",
    },
    {
      value: "Gallbladder Removal",
      label: "Gallbladder Removal",
    },
    {
      value: "Gallstone",
      label: "Gallstone",
    },
    {
      value: "Gangrene",
      label: "Gangrene",
    },
    {
      value: "Gastrectomy",
      label: "Gastrectomy",
    },
    {
      value: "Gastric Ulcer",
      label: "Gastric Ulcer",
    },
    {
      value: "Gastritis",
      label: "Gastritis",
    },
    {
      value: "Gastritis Due To Alcohol",
      label: "Gastritis Due To Alcohol",
    },
    {
      value: "Gastroenteritis",
      label: "Gastroenteritis",
    },
    {
      value: "Gastrojejunal Ulcer With Perforation",
      label: "Gastrojejunal Ulcer With Perforation",
    },
    {
      value: "Gauchers Disease",
      label: "Gauchers Disease",
    },
    {
      value: "Genital TB",
      label: "Genital TB",
    },
    {
      value: "Genitals Tuberculosis",
      label: "Genitals Tuberculosis",
    },
    {
      value: "Genitourinary Tuberculosis",
      label: "Genitourinary Tuberculosis",
    },
    {
      value: "Giant Cell Arteritis",
      label: "Giant Cell Arteritis",
    },
    {
      value: "Gilberts Disease",
      label: "Gilberts Disease",
    },
    {
      value: "Gland Inflammation Tuberculous",
      label: "Gland Inflammation Tuberculous",
    },
    {
      value: "Glaucoma",
      label: "Glaucoma",
    },
    {
      value: "Glomerulonephritis",
      label: "Glomerulonephritis",
    },
    {
      value: "Glomerulosclerosis",
      label: "Glomerulosclerosis",
    },
    {
      value: "Glomerulosclerosis Nondiabetic",
      label: "Glomerulosclerosis Nondiabetic",
    },
    {
      value: "Glomerulosclerosis Postpartum",
      label: "Glomerulosclerosis Postpartum",
    },
    {
      value: "Goitre",
      label: "Goitre",
    },
    {
      value: "Gout",
      label: "Gout",
    },
    {
      value: "Granuloma",
      label: "Granuloma",
    },
    {
      value: "Granulomatous Reticulosis",
      label: "Granulomatous Reticulosis",
    },
    {
      value: "Graves Disease",
      label: "Graves Disease",
    },
    {
      value: "Haematemesis",
      label: "Haematemesis",
    },
    {
      value: "Haematuria",
      label: "Haematuria",
    },
    {
      value: "Haemochromatosis",
      label: "Haemochromatosis",
    },
    {
      value: "Haemophilia",
      label: "Haemophilia",
    },
    {
      value: "Haemophilia A",
      label: "Haemophilia A",
    },
    {
      value: "Haemophilia B",
      label: "Haemophilia B",
    },
    {
      value: "Haemorrhage Intracerebral",
      label: "Haemorrhage Intracerebral",
    },
    {
      value: "Haemorrhage Meningeal",
      label: "Haemorrhage Meningeal",
    },
    {
      value: "Haemorrhagic Anticoagulant Disorder",
      label: "Haemorrhagic Anticoagulant Disorder",
    },
    {
      value: "Hand Schuller Christian Disease",
      label: "Hand Schuller Christian Disease",
    },
    {
      value: "Head Injury",
      label: "Head Injury",
    },
    {
      value: "Heart Amyloidosis",
      label: "Heart Amyloidosis",
    },
    {
      value: "Heart Transplant",
      label: "Heart Transplant",
    },
    {
      value: "Heartburn",
      label: "Heartburn",
    },
    {
      value: "Hemicolectomy",
      label: "Hemicolectomy",
    },
    {
      value: "Henoch Schonlein",
      label: "Henoch Schonlein",
    },
    {
      value: "Hep B",
      label: "Hep B",
    },
    {
      value: "Hep C",
      label: "Hep C",
    },
    {
      value: "Hepatic Cirrhosis",
      label: "Hepatic Cirrhosis",
    },
    {
      value: "Hepatitis",
      label: "Hepatitis",
    },
    {
      value: "Hepatitis A",
      label: "Hepatitis A",
    },
    {
      value: "Hepatitis Alcohol Induced",
      label: "Hepatitis Alcohol Induced",
    },
    {
      value: "Hepatitis B",
      label: "Hepatitis B",
    },
    {
      value: "Hepatitis C",
      label: "Hepatitis C",
    },
    {
      value: "Hepatitis Unspecified",
      label: "Hepatitis Unspecified",
    },
    {
      value: "Hepatomegaly",
      label: "Hepatomegaly",
    },
    {
      value: "Hepatosis",
      label: "Hepatosis",
    },
    {
      value: "Hereditary Clotting Disorder",
      label: "Hereditary Clotting Disorder",
    },
    {
      value: "Hereditary Elliptocytosis",
      label: "Hereditary Elliptocytosis",
    },
    {
      value: "Hereditary Factor Viii Deficiency",
      label: "Hereditary Factor Viii Deficiency",
    },
    {
      value: "Hereditary Spherocytosis",
      label: "Hereditary Spherocytosis",
    },
    {
      value: "Hernia",
      label: "Hernia",
    },
    {
      value: "Hiatus Hernia",
      label: "Hiatus Hernia",
    },
    {
      value: "High Triglycerides",
      label: "High Triglycerides",
    },
    {
      value: "Hip Joint Dysplasia",
      label: "Hip Joint Dysplasia",
    },
    {
      value: "Hip Movement Restriction",
      label: "Hip Movement Restriction",
    },
    {
      value: "Hip TB",
      label: "Hip TB",
    },
    {
      value: "Hirschsprungs Disease",
      label: "Hirschsprungs Disease",
    },
    {
      value: "HIV",
      label: "HIV",
    },
    {
      value: "HIV Positive",
      label: "HIV Positive",
    },
    {
      value: "Horseshoe Kidney",
      label: "Horseshoe Kidney",
    },
    {
      value: "Human Immunodeficiency Virus",
      label: "Human Immunodeficiency Virus",
    },
    {
      value: "Hydrocele",
      label: "Hydrocele",
    },
    {
      value: "Hydrocephalus",
      label: "Hydrocephalus",
    },
    {
      value: "Hydronephrosis",
      label: "Hydronephrosis",
    },
    {
      value: "Hyperaldosteronism",
      label: "Hyperaldosteronism",
    },
    {
      value: "Hyperglobulinaemia",
      label: "Hyperglobulinaemia",
    },
    {
      value: "Hyperlipidaemia",
      label: "Hyperlipidaemia",
    },
    {
      value: "Hyperparathyroidism",
      label: "Hyperparathyroidism",
    },
    {
      value: "Hyperpituitarism",
      label: "Hyperpituitarism",
    },
    {
      value: "Hyperplasia Of Prostate",
      label: "Hyperplasia Of Prostate",
    },
    {
      value: "Hyperprolactinaemia",
      label: "Hyperprolactinaemia",
    },
    {
      value: "Hypersplenism",
      label: "Hypersplenism",
    },
    {
      value: "Hypertension Portal",
      label: "Hypertension Portal",
    },
    {
      value: "Hypertensive Renal Disease",
      label: "Hypertensive Renal Disease",
    },
    {
      value: "Hypertensive Renal Disease Without Renal Failure",
      label: "Hypertensive Renal Disease Without Renal Failure",
    },
    {
      value: "Hyperthyroidism",
      label: "Hyperthyroidism",
    },
    {
      value: "Hypertonia Malignant",
      label: "Hypertonia Malignant",
    },
    {
      value: "Hypertriglyceridaemi",
      label: "Hypertriglyceridaemi",
    },
    {
      value: "Hypertriglyceridaemi Familial",
      label: "Hypertriglyceridaemi Familial",
    },
    {
      value: "Hypertriglyceridaemia",
      label: "Hypertriglyceridaemia",
    },
    {
      value: "Hypertriglyceridaemia Familial",
      label: "Hypertriglyceridaemia Familial",
    },
    {
      value: "Hyperuricaemia",
      label: "Hyperuricaemia",
    },
    {
      value: "Hypocalcemia",
      label: "Hypocalcemia",
    },
    {
      value: "Hypogonadism Male",
      label: "Hypogonadism Male",
    },
    {
      value: "Hypomania",
      label: "Hypomania",
    },
    {
      value: "Hypopituitarism",
      label: "Hypopituitarism",
    },
    {
      value: "Hypothyroidism",
      label: "Hypothyroidism",
    },
    {
      value: "Ichthyosis",
      label: "Ichthyosis",
    },
    {
      value: "Idiopathic Thrombocytopaenia Purpura",
      label: "Idiopathic Thrombocytopaenia Purpura",
    },
    {
      value: "Idiopathic Thrombocytopenic Purpura",
      label: "Idiopathic Thrombocytopenic Purpura",
    },
    {
      value: "Iga Nephropathy",
      label: "Iga Nephropathy",
    },
    {
      value: "Immune Deficiency Acquired",
      label: "Immune Deficiency Acquired",
    },
    {
      value: "Immunity Deficiency",
      label: "Immunity Deficiency",
    },
    {
      value: "Immunodeficiency",
      label: "Immunodeficiency",
    },
    {
      value: "Indigestion",
      label: "Indigestion",
    },
    {
      value: "Induced Delusional Disorder",
      label: "Induced Delusional Disorder",
    },
    {
      value: "Infected Bile Duct",
      label: "Infected Bile Duct",
    },
    {
      value: "Inflammatory Bowel Disease",
      label: "Inflammatory Bowel Disease",
    },
    {
      value: "Inflammatory Polyarthropathy",
      label: "Inflammatory Polyarthropathy",
    },
    {
      value: "Inflammatory Prostate Disease",
      label: "Inflammatory Prostate Disease",
    },
    {
      value: "Inflammed Bile Duct",
      label: "Inflammed Bile Duct",
    },
    {
      value: "Inguinal Hernia",
      label: "Inguinal Hernia",
    },
    {
      value: "Insufficiency Cerebrovascular",
      label: "Insufficiency Cerebrovascular",
    },
    {
      value: "Insulin Deficiency Postoperative",
      label: "Insulin Deficiency Postoperative",
    },
    {
      value: "Intermittent Claudication",
      label: "Intermittent Claudication",
    },
    {
      value: "Interstitial Cystitis",
      label: "Interstitial Cystitis",
    },
    {
      value: "Intestinal Abscess",
      label: "Intestinal Abscess",
    },
    {
      value: "Intestinal Adhesion",
      label: "Intestinal Adhesion",
    },
    {
      value: "Intestinal Adhesions",
      label: "Intestinal Adhesions",
    },
    {
      value: "Intestinal Malabsorption",
      label: "Intestinal Malabsorption",
    },
    {
      value: "Intestinal TB",
      label: "Intestinal TB",
    },
    {
      value: "Intestinal Tuberculosis",
      label: "Intestinal Tuberculosis",
    },
    {
      value: "Intestine Obstruction",
      label: "Intestine Obstruction",
    },
    {
      value: "Intestine Operation",
      label: "Intestine Operation",
    },
    {
      value: "Intestine Tuberculosis",
      label: "Intestine Tuberculosis",
    },
    {
      value: "Intestine Twisting",
      label: "Intestine Twisting",
    },
    {
      value: "Intracranial Bleed",
      label: "Intracranial Bleed",
    },
    {
      value: "Intracranial Haemorrhage",
      label: "Intracranial Haemorrhage",
    },
    {
      value: "Iridocyclitis",
      label: "Iridocyclitis",
    },
    {
      value: "Iritis",
      label: "Iritis",
    },
    {
      value: "Iron Deficiency Anaemia",
      label: "Iron Deficiency Anaemia",
    },
    {
      value: "Iron Metabolism Disorder",
      label: "Iron Metabolism Disorder",
    },
    {
      value: "Irritable Bladder",
      label: "Irritable Bladder",
    },
    {
      value: "Irritable Bowel Syndrome",
      label: "Irritable Bowel Syndrome",
    },
    {
      value: "Ischemia Cerebral",
      label: "Ischemia Cerebral",
    },
    {
      value: "ITP",
      label: "ITP",
    },
    {
      value: "Jaundice",
      label: "Jaundice",
    },
    {
      value: "Joint TB",
      label: "Joint TB",
    },
    {
      value: "Joint Tuberculosis",
      label: "Joint Tuberculosis",
    },
    {
      value: "Juvenile Polyarthritis",
      label: "Juvenile Polyarthritis",
    },
    {
      value: "Keratosis",
      label: "Keratosis",
    },
    {
      value: "Kidney Amyloid",
      label: "Kidney Amyloid",
    },
    {
      value: "Kidney Anomaly",
      label: "Kidney Anomaly",
    },
    {
      value: "Kidney Calculus Removal",
      label: "Kidney Calculus Removal",
    },
    {
      value: "Kidney Cyst",
      label: "Kidney Cyst",
    },
    {
      value: "Kidney Disease",
      label: "Kidney Disease",
    },
    {
      value: "Kidney Failure",
      label: "Kidney Failure",
    },
    {
      value: "Kidney Function Disorder",
      label: "Kidney Function Disorder",
    },
    {
      value: "Kidney Hypertension",
      label: "Kidney Hypertension",
    },
    {
      value: "Kidney Infarct",
      label: "Kidney Infarct",
    },
    {
      value: "Kidney Infection",
      label: "Kidney Infection",
    },
    {
      value: "Kidney Polycystic",
      label: "Kidney Polycystic",
    },
    {
      value: "Kidney Stone",
      label: "Kidney Stone",
    },
    {
      value: "Kidney Stone Removal",
      label: "Kidney Stone Removal",
    },
    {
      value: "Kidney Transplant",
      label: "Kidney Transplant",
    },
    {
      value: "Kidney Tuberculosis",
      label: "Kidney Tuberculosis",
    },
    {
      value: "Klinefelters Syndrome",
      label: "Klinefelters Syndrome",
    },
    {
      value: "Knee Joint Damage",
      label: "Knee Joint Damage",
    },
    {
      value: "Knee TB",
      label: "Knee TB",
    },
    {
      value: "Knee Tuberculosis",
      label: "Knee Tuberculosis",
    },
    {
      value: "Kyphoscoliosis",
      label: "Kyphoscoliosis",
    },
    {
      value: "Kyphosis",
      label: "Kyphosis",
    },
    {
      value: "Labyrinthitis",
      label: "Labyrinthitis",
    },
    {
      value: "Lactose Intolerance",
      label: "Lactose Intolerance",
    },
    {
      value: "Large Bowel Obstruction",
      label: "Large Bowel Obstruction",
    },
    {
      value: "Leg Monoplegia",
      label: "Leg Monoplegia",
    },
    {
      value: "Leg Paralysis",
      label: "Leg Paralysis",
    },
    {
      value: "Leg Paraplegia",
      label: "Leg Paraplegia",
    },
    {
      value: "Leg Ulcer",
      label: "Leg Ulcer",
    },
    {
      value: "Legs Paralysis",
      label: "Legs Paralysis",
    },
    {
      value: "Leprosy",
      label: "Leprosy",
    },
    {
      value: "Leucocytosis",
      label: "Leucocytosis",
    },
    {
      value: "Leucodystrophy",
      label: "Leucodystrophy",
    },
    {
      value: "Leukocytosis",
      label: "Leukocytosis",
    },
    {
      value: "Leukodystrophy",
      label: "Leukodystrophy",
    },
    {
      value: "Leukoplakia",
      label: "Leukoplakia",
    },
    {
      value: "Lichen",
      label: "Lichen",
    },
    {
      value: "Lichen Planus",
      label: "Lichen Planus",
    },
    {
      value: "Lipid Metabolism Disorder",
      label: "Lipid Metabolism Disorder",
    },
    {
      value: "Lipidosis Cerebral",
      label: "Lipidosis Cerebral",
    },
    {
      value: "Lipometabolism Disorder",
      label: "Lipometabolism Disorder",
    },
    {
      value: "Lipometabolism Disorder Familial",
      label: "Lipometabolism Disorder Familial",
    },
    {
      value: "Liver Cirrhosis",
      label: "Liver Cirrhosis",
    },
    {
      value: "Liver Cirrhosis Alcohol",
      label: "Liver Cirrhosis Alcohol",
    },
    {
      value: "Liver Disease",
      label: "Liver Disease",
    },
    {
      value: "Liver Transplant",
      label: "Liver Transplant",
    },
    {
      value: "Localised Scleroderma",
      label: "Localised Scleroderma",
    },
    {
      value: "Lumbago",
      label: "Lumbago",
    },
    {
      value: "Lung Transplant",
      label: "Lung Transplant",
    },
    {
      value: "Lupus Erythematosus Dissemination",
      label: "Lupus Erythematosus Dissemination",
    },
    {
      value: "Lymph Nodes Tuberculosis",
      label: "Lymph Nodes Tuberculosis",
    },
    {
      value: "Lymphocytopenia",
      label: "Lymphocytopenia",
    },
    {
      value: "Lymphopenia",
      label: "Lymphopenia",
    },
    {
      value: "Major Affective Disorder",
      label: "Major Affective Disorder",
    },
    {
      value: "Major Depression",
      label: "Major Depression",
    },
    {
      value: "Malabsorption",
      label: "Malabsorption",
    },
    {
      value: "Malabsorption Syndrome",
      label: "Malabsorption Syndrome",
    },
    {
      value: "Malignant Hypertension",
      label: "Malignant Hypertension",
    },
    {
      value: "Mania",
      label: "Mania",
    },
    {
      value: "Mania Chronic",
      label: "Mania Chronic",
    },
    {
      value: "Marfan Syndrome",
      label: "Marfan Syndrome",
    },
    {
      value: "Mastoiditis",
      label: "Mastoiditis",
    },
    {
      value: "Megaloblastic Anaemia",
      label: "Megaloblastic Anaemia",
    },
    {
      value: "Melaena",
      label: "Melaena",
    },
    {
      value: "Melancholia",
      label: "Melancholia",
    },
    {
      value: "Memory Loss",
      label: "Memory Loss",
    },
    {
      value: "Menieres Disease",
      label: "Menieres Disease",
    },
    {
      value: "Meningeal TB",
      label: "Meningeal TB",
    },
    {
      value: "Meninges Tuberculosis",
      label: "Meninges Tuberculosis",
    },
    {
      value: "Meningitis Tuberculous",
      label: "Meningitis Tuberculous",
    },
    {
      value: "Mental And Behavioural Disorders Due To Use Of Alcohol",
      label: "Mental And Behavioural Disorders Due To Use Of Alcohol",
    },
    {
      value: "Mental Deficiency",
      label: "Mental Deficiency",
    },
    {
      value: "Mental Retardation",
      label: "Mental Retardation",
    },
    {
      value: "Mental Retardation Distinct",
      label: "Mental Retardation Distinct",
    },
    {
      value: "Mental Retardation Mild",
      label: "Mental Retardation Mild",
    },
    {
      value: "Mental Retardation Moderate",
      label: "Mental Retardation Moderate",
    },
    {
      value: "Metabolic Syndrome",
      label: "Metabolic Syndrome",
    },
    {
      value: "Metatarsalgia",
      label: "Metatarsalgia",
    },
    {
      value: "Microcephalus",
      label: "Microcephalus",
    },
    {
      value: "Microgyria",
      label: "Microgyria",
    },
    {
      value: "Mild Mental Retardation",
      label: "Mild Mental Retardation",
    },
    {
      value: "Miliary TB",
      label: "Miliary TB",
    },
    {
      value: "Miliary Tuberculosis",
      label: "Miliary Tuberculosis",
    },
    {
      value: "Mineral Metabolism Disorder",
      label: "Mineral Metabolism Disorder",
    },
    {
      value: "Minor Depression",
      label: "Minor Depression",
    },
    {
      value: "Moderate Mental Retardation",
      label: "Moderate Mental Retardation",
    },
    {
      value: "Mongolism",
      label: "Mongolism",
    },
    {
      value: "Monoplegia",
      label: "Monoplegia",
    },
    {
      value: "Muscle Spasm",
      label: "Muscle Spasm",
    },
    {
      value: "Myalgia",
      label: "Myalgia",
    },
    {
      value: "Myelitis Tuberculous",
      label: "Myelitis Tuberculous",
    },
    {
      value: "Myocarditis Tuberculosis",
      label: "Myocarditis Tuberculosis",
    },
    {
      value: "Myocardium Tuberculosis",
      label: "Myocardium Tuberculosis",
    },
    {
      value: "Myositis",
      label: "Myositis",
    },
    {
      value: "Myxoedema",
      label: "Myxoedema",
    },
    {
      value: "Nasal Polyp",
      label: "Nasal Polyp",
    },
    {
      value: "Nasal Septum Tuberculosis",
      label: "Nasal Septum Tuberculosis",
    },
    {
      value: "Nasal Sinus Disorder",
      label: "Nasal Sinus Disorder",
    },
    {
      value: "Nasopharynx Tuberculosis",
      label: "Nasopharynx Tuberculosis",
    },
    {
      value: "Neck Pain",
      label: "Neck Pain",
    },
    {
      value: "Necrosis Of Pulp",
      label: "Necrosis Of Pulp",
    },
    {
      value: "Nephritis",
      label: "Nephritis",
    },
    {
      value: "Nephritis Acute",
      label: "Nephritis Acute",
    },
    {
      value: "Nephritis Chronic",
      label: "Nephritis Chronic",
    },
    {
      value: "Nephropathy",
      label: "Nephropathy",
    },
    {
      value: "Nephrosis",
      label: "Nephrosis",
    },
    {
      value: "Nephrotic Syndrome",
      label: "Nephrotic Syndrome",
    },
    {
      value: "Nerve Entrapment",
      label: "Nerve Entrapment",
    },
    {
      value: "Neuralgia",
      label: "Neuralgia",
    },
    {
      value: "Neuritis",
      label: "Neuritis",
    },
    {
      value: "Neurofibromatosis",
      label: "Neurofibromatosis",
    },
    {
      value: "Niemann Pick Disease",
      label: "Niemann Pick Disease",
    },
    {
      value: "Nocturia",
      label: "Nocturia",
    },
    {
      value: "Nodular Tendonous Disease",
      label: "Nodular Tendonous Disease",
    },
    {
      value: "Non Alcoholic Fatty Liver",
      label: "Non Alcoholic Fatty Liver",
    },
    {
      value: "Non-Ischaemic Chest Pain",
      label: "Non-Ischaemic Chest Pain",
    },
    {
      value: "Nonautoimmune Haemolytic Anaemia",
      label: "Nonautoimmune Haemolytic Anaemia",
    },
    {
      value: "Nonfamilial Hypogammaglobulinaemia",
      label: "Nonfamilial Hypogammaglobulinaemia",
    },
    {
      value: "Nose Tuberculosis",
      label: "Nose Tuberculosis",
    },
    {
      value: "Nosebleed",
      label: "Nosebleed",
    },
    {
      value: "Numbness",
      label: "Numbness",
    },
    {
      value: "Obnubilation",
      label: "Obnubilation",
    },
    {
      value: "Obstructed Bowel",
      label: "Obstructed Bowel",
    },
    {
      value: "Occlusive Arterial Disease",
      label: "Occlusive Arterial Disease",
    },
    {
      value: "Oesophageal Obstruction",
      label: "Oesophageal Obstruction",
    },
    {
      value: "Oesophagitis",
      label: "Oesophagitis",
    },
    {
      value: "Oesophagus Diverticulum",
      label: "Oesophagus Diverticulum",
    },
    {
      value: "Oesophagus Tuberculosis",
      label: "Oesophagus Tuberculosis",
    },
    {
      value: "Ongoing Liver Infection",
      label: "Ongoing Liver Infection",
    },
    {
      value: "Optic Neuritis",
      label: "Optic Neuritis",
    },
    {
      value: "Oral Tissue Leukoplakia",
      label: "Oral Tissue Leukoplakia",
    },
    {
      value: "Osteitis Deformans",
      label: "Osteitis Deformans",
    },
    {
      value: "Osteitis Tuberculous",
      label: "Osteitis Tuberculous",
    },
    {
      value: "Osteoarthritis",
      label: "Osteoarthritis",
    },
    {
      value: "Osteochondrosis",
      label: "Osteochondrosis",
    },
    {
      value: "Osteomyelitis",
      label: "Osteomyelitis",
    },
    {
      value: "Osteomyelitis Tuberculous",
      label: "Osteomyelitis Tuberculous",
    },
    {
      value: "Osteomyelosclerosis",
      label: "Osteomyelosclerosis",
    },
    {
      value: "Osteopenia",
      label: "Osteopenia",
    },
    {
      value: "Osteoporosis",
      label: "Osteoporosis",
    },
    {
      value: "Other Aplastic Anaemias",
      label: "Other Aplastic Anaemias",
    },
    {
      value: "Other Autoimmune Haemolytic Anaemias",
      label: "Other Autoimmune Haemolytic Anaemias",
    },
    {
      value: "Other Functional Intestinal Disorders",
      label: "Other Functional Intestinal Disorders",
    },
    {
      value: "Other Hereditary Haemolytic Anaemias",
      label: "Other Hereditary Haemolytic Anaemias",
    },
    {
      value: "Other Inflammatory Liver Diseases",
      label: "Other Inflammatory Liver Diseases",
    },
    {
      value: "Other Orthopoxvirus Infections",
      label: "Other Orthopoxvirus Infections",
    },
    {
      value: "Other Peripheral Vascular Diseases",
      label: "Other Peripheral Vascular Diseases",
    },
    {
      value: "Other Sideroblastic Anaemias",
      label: "Other Sideroblastic Anaemias",
    },
    {
      value: "Other Specified Disorders Of Kidney And Ureter",
      label: "Other Specified Disorders Of Kidney And Ureter",
    },
    {
      value: "Other Sphingolipidosis",
      label: "Other Sphingolipidosis",
    },
    {
      value: "Otitis Media",
      label: "Otitis Media",
    },
    {
      value: "Otitis Media Tuberculosis",
      label: "Otitis Media Tuberculosis",
    },
    {
      value: "Otosclerosis",
      label: "Otosclerosis",
    },
    {
      value: "Ovarian Cyst",
      label: "Ovarian Cyst",
    },
    {
      value: "Overactive Thyroid Gland",
      label: "Overactive Thyroid Gland",
    },
    {
      value: "Pagets Disease",
      label: "Pagets Disease",
    },
    {
      value: "Pagets Disease Of Bone",
      label: "Pagets Disease Of Bone",
    },
    {
      value: "Panarteritis Nodosa",
      label: "Panarteritis Nodosa",
    },
    {
      value: "Pancreas Abscess",
      label: "Pancreas Abscess",
    },
    {
      value: "Pancreas Anomalies",
      label: "Pancreas Anomalies",
    },
    {
      value: "Pancreas Atrophy",
      label: "Pancreas Atrophy",
    },
    {
      value: "Pancreas Cyst",
      label: "Pancreas Cyst",
    },
    {
      value: "Pancreas Disease",
      label: "Pancreas Disease",
    },
    {
      value: "Pancreas Fibrosis",
      label: "Pancreas Fibrosis",
    },
    {
      value: "Pancreas Fibrosis Cistic",
      label: "Pancreas Fibrosis Cistic",
    },
    {
      value: "Pancreas Fibrosis Cystic",
      label: "Pancreas Fibrosis Cystic",
    },
    {
      value: "Pancreas Inflammation",
      label: "Pancreas Inflammation",
    },
    {
      value: "Pancreas Inflammation Acute",
      label: "Pancreas Inflammation Acute",
    },
    {
      value: "Pancreas Inflammation Chronic",
      label: "Pancreas Inflammation Chronic",
    },
    {
      value: "Pancreas Inflammation Relapse",
      label: "Pancreas Inflammation Relapse",
    },
    {
      value: "Pancreas Necrosis",
      label: "Pancreas Necrosis",
    },
    {
      value: "Pancreas Pseudocyst",
      label: "Pancreas Pseudocyst",
    },
    {
      value: "Pancreas Transplant",
      label: "Pancreas Transplant",
    },
    {
      value: "Pancreatic Cyst",
      label: "Pancreatic Cyst",
    },
    {
      value: "Pancreatitis",
      label: "Pancreatitis",
    },
    {
      value: "Pancreatitis Acute",
      label: "Pancreatitis Acute",
    },
    {
      value: "Pancreatitis Relapse",
      label: "Pancreatitis Relapse",
    },
    {
      value: "Paraesthesia",
      label: "Paraesthesia",
    },
    {
      value: "Paralysis",
      label: "Paralysis",
    },
    {
      value: "Paranasal Sinus Tuberculosis",
      label: "Paranasal Sinus Tuberculosis",
    },
    {
      value: "Paranoid Schizophrenia",
      label: "Paranoid Schizophrenia",
    },
    {
      value: "Paranoid State",
      label: "Paranoid State",
    },
    {
      value: "Paraplegia",
      label: "Paraplegia",
    },
    {
      value: "Paraplegia And Tetraplegia",
      label: "Paraplegia And Tetraplegia",
    },
    {
      value: "Patau Syndrome",
      label: "Patau Syndrome",
    },
    {
      value: "Pataus Syndrome",
      label: "Pataus Syndrome",
    },
    {
      value: "PCKD",
      label: "PCKD",
    },
    {
      value: "Pemphigoid",
      label: "Pemphigoid",
    },
    {
      value: "Pemphigus",
      label: "Pemphigus",
    },
    {
      value: "Peptic Ulcer",
      label: "Peptic Ulcer",
    },
    {
      value: "Peripheral Arterial Occlusive Disease",
      label: "Peripheral Arterial Occlusive Disease",
    },
    {
      value: "Peripheral Vascular Disease",
      label: "Peripheral Vascular Disease",
    },
    {
      value: "Perisplenitis",
      label: "Perisplenitis",
    },
    {
      value: "Peritoneum Tuberculous",
      label: "Peritoneum Tuberculous",
    },
    {
      value: "Peritonitis",
      label: "Peritonitis",
    },
    {
      value: "Peritonsillar Abscess",
      label: "Peritonsillar Abscess",
    },
    {
      value: "Pernicious Anaemia",
      label: "Pernicious Anaemia",
    },
    {
      value: "Persistent Delusional Disorders",
      label: "Persistent Delusional Disorders",
    },
    {
      value: "Persistent Mania",
      label: "Persistent Mania",
    },
    {
      value: "Phlegmon",
      label: "Phlegmon",
    },
    {
      value: "Pickwickian Syndrome",
      label: "Pickwickian Syndrome",
    },
    {
      value: "Pityriasis",
      label: "Pityriasis",
    },
    {
      value: "PKD",
      label: "PKD",
    },
    {
      value: "Plasma Protein Metabolism Disorder",
      label: "Plasma Protein Metabolism Disorder",
    },
    {
      value: "Plasmacytosis",
      label: "Plasmacytosis",
    },
    {
      value: "Plasmaprotein Metabolism Disorder",
      label: "Plasmaprotein Metabolism Disorder",
    },
    {
      value: "Poliomyelitis",
      label: "Poliomyelitis",
    },
    {
      value: "Polyarteritis Nodosa And Related Conditions",
      label: "Polyarteritis Nodosa And Related Conditions",
    },
    {
      value: "Polyarthritis",
      label: "Polyarthritis",
    },
    {
      value: "Polyarthritis Chronic",
      label: "Polyarthritis Chronic",
    },
    {
      value: "Polyarthritis Primary Chronic",
      label: "Polyarthritis Primary Chronic",
    },
    {
      value: "Polyarthrosis",
      label: "Polyarthrosis",
    },
    {
      value: "Polyclonal Hypergammaglobulinaemia",
      label: "Polyclonal Hypergammaglobulinaemia",
    },
    {
      value: "Polycystic Kidney",
      label: "Polycystic Kidney",
    },
    {
      value: "Polycystic Kidney Disease",
      label: "Polycystic Kidney Disease",
    },
    {
      value: "Polycystic Kidneys",
      label: "Polycystic Kidneys",
    },
    {
      value: "Polycystic Liver",
      label: "Polycystic Liver",
    },
    {
      value: "Polymyalgia Rheumatica",
      label: "Polymyalgia Rheumatica",
    },
    {
      value: "Polymyositis",
      label: "Polymyositis",
    },
    {
      value: "Polyserositis Tuberculous",
      label: "Polyserositis Tuberculous",
    },
    {
      value: "Poor Circulation",
      label: "Poor Circulation",
    },
    {
      value: "Porphyria",
      label: "Porphyria",
    },
    {
      value: "Portal Hypertension",
      label: "Portal Hypertension",
    },
    {
      value: "Portal Vein Thrombosis",
      label: "Portal Vein Thrombosis",
    },
    {
      value: "Postcholecystectomy Syndrome",
      label: "Postcholecystectomy Syndrome",
    },
    {
      value: "Primary Aldosteronism",
      label: "Primary Aldosteronism",
    },
    {
      value: "Primary Biliary Cirrhosis",
      label: "Primary Biliary Cirrhosis",
    },
    {
      value: "Primary Immunodeficiency",
      label: "Primary Immunodeficiency",
    },
    {
      value: "Primary Thrombocytopenia",
      label: "Primary Thrombocytopenia",
    },
    {
      value: "Problem With Back",
      label: "Problem With Back",
    },
    {
      value: "Proctitis",
      label: "Proctitis",
    },
    {
      value: "Proctitis Chronic",
      label: "Proctitis Chronic",
    },
    {
      value: "Proctitis Ulcerous",
      label: "Proctitis Ulcerous",
    },
    {
      value: "Proctocolitis",
      label: "Proctocolitis",
    },
    {
      value: "Proctocolitis Chronic",
      label: "Proctocolitis Chronic",
    },
    {
      value: "Proctocolitis Ulcerous",
      label: "Proctocolitis Ulcerous",
    },
    {
      value: "Prolapsed Disc",
      label: "Prolapsed Disc",
    },
    {
      value: "Prolapsed Uterus",
      label: "Prolapsed Uterus",
    },
    {
      value: "Prostate Calculus",
      label: "Prostate Calculus",
    },
    {
      value: "Prostate Hypertrophy",
      label: "Prostate Hypertrophy",
    },
    {
      value: "Prostate Tuberculosis",
      label: "Prostate Tuberculosis",
    },
    {
      value: "Prostatitis",
      label: "Prostatitis",
    },
    {
      value: "Protein Deficiency Anaemia",
      label: "Protein Deficiency Anaemia",
    },
    {
      value: "Proteinuria",
      label: "Proteinuria",
    },
    {
      value: "Prurigo",
      label: "Prurigo",
    },
    {
      value: "Pruritis",
      label: "Pruritis",
    },
    {
      value: "Pseudohemophilia",
      label: "Pseudohemophilia",
    },
    {
      value: "Psoriasis",
      label: "Psoriasis",
    },
    {
      value: "Psoriatic Arthritis",
      label: "Psoriatic Arthritis",
    },
    {
      value: "Psychosis",
      label: "Psychosis",
    },
    {
      value: "Psychosis Affective",
      label: "Psychosis Affective",
    },
    {
      value: "Psychosis Affective Hypomanic",
      label: "Psychosis Affective Hypomanic",
    },
    {
      value: "Psychosis Affective Manic",
      label: "Psychosis Affective Manic",
    },
    {
      value: "Psychosis Child",
      label: "Psychosis Child",
    },
    {
      value: "Psychosis Depressive",
      label: "Psychosis Depressive",
    },
    {
      value: "Psychosis Disintegrative",
      label: "Psychosis Disintegrative",
    },
    {
      value: "Psychosis Hypomanic",
      label: "Psychosis Hypomanic",
    },
    {
      value: "Psychosis Hysterical",
      label: "Psychosis Hysterical",
    },
    {
      value: "Psychosis Induced",
      label: "Psychosis Induced",
    },
    {
      value: "Psychosis Infantile",
      label: "Psychosis Infantile",
    },
    {
      value: "Psychosis Manic",
      label: "Psychosis Manic",
    },
    {
      value: "Psychosis Nonorganic",
      label: "Psychosis Nonorganic",
    },
    {
      value: "Psychosis Organic Chronic",
      label: "Psychosis Organic Chronic",
    },
    {
      value: "Psychosis Organic Transient",
      label: "Psychosis Organic Transient",
    },
    {
      value: "Psychosis Paranoid",
      label: "Psychosis Paranoid",
    },
    {
      value: "Psychosis Psychogenic",
      label: "Psychosis Psychogenic",
    },
    {
      value: "Psychosis Psychogenic Paranoid",
      label: "Psychosis Psychogenic Paranoid",
    },
    {
      value: "Psychosis Reactive",
      label: "Psychosis Reactive",
    },
    {
      value: "Psychosis Reactive Depressive",
      label: "Psychosis Reactive Depressive",
    },
    {
      value: "Psychosis Schizophrenic",
      label: "Psychosis Schizophrenic",
    },
    {
      value: "Psychotic",
      label: "Psychotic",
    },
    {
      value: "Pure Hyperglyceridaemia",
      label: "Pure Hyperglyceridaemia",
    },
    {
      value: "Purpura",
      label: "Purpura",
    },
    {
      value: "Purpura Fulminant",
      label: "Purpura Fulminant",
    },
    {
      value: "PVD",
      label: "PVD",
    },
    {
      value: "Pyelonephritis",
      label: "Pyelonephritis",
    },
    {
      value: "Pyelonephritis Acute",
      label: "Pyelonephritis Acute",
    },
    {
      value: "Pyelonephritis Chronic",
      label: "Pyelonephritis Chronic",
    },
    {
      value: "Pyelonephritis Unknown",
      label: "Pyelonephritis Unknown",
    },
    {
      value: "Pyoderma",
      label: "Pyoderma",
    },
    {
      value: "Pyrophosphate Arthropathy",
      label: "Pyrophosphate Arthropathy",
    },
    {
      value: "Quadriplegia",
      label: "Quadriplegia",
    },
    {
      value: "RA",
      label: "RA",
    },
    {
      value: "Radiculitis",
      label: "Radiculitis",
    },
    {
      value: "Raised Bilirubin",
      label: "Raised Bilirubin",
    },
    {
      value: "Raised Blood Sugar",
      label: "Raised Blood Sugar",
    },
    {
      value: "Raised Creatinine",
      label: "Raised Creatinine",
    },
    {
      value: "Raised Liver Enzymes",
      label: "Raised Liver Enzymes",
    },
    {
      value: "Raynauds Disease",
      label: "Raynauds Disease",
    },
    {
      value: "Raynauds Phenomenon",
      label: "Raynauds Phenomenon",
    },
    {
      value: "Raynauds Syndrome",
      label: "Raynauds Syndrome",
    },
    {
      value: "Reaction Paranoid Acute",
      label: "Reaction Paranoid Acute",
    },
    {
      value: "Rectal Polyp",
      label: "Rectal Polyp",
    },
    {
      value: "Rectal Prolapse",
      label: "Rectal Prolapse",
    },
    {
      value: "Recurrent Cholangitis",
      label: "Recurrent Cholangitis",
    },
    {
      value: "Reflux Gastritis",
      label: "Reflux Gastritis",
    },
    {
      value: "Reflux Oesophagitis",
      label: "Reflux Oesophagitis",
    },
    {
      value: "Reiters Disease",
      label: "Reiters Disease",
    },
    {
      value: "Renal Agenesis, Unilateral",
      label: "Renal Agenesis, Unilateral",
    },
    {
      value: "Renal Colic",
      label: "Renal Colic",
    },
    {
      value: "Renal Disease",
      label: "Renal Disease",
    },
    {
      value: "Renal Failure",
      label: "Renal Failure",
    },
    {
      value: "Renal Failure Chronic",
      label: "Renal Failure Chronic",
    },
    {
      value: "Renal Function Disorder",
      label: "Renal Function Disorder",
    },
    {
      value: "Renal Hypertonia",
      label: "Renal Hypertonia",
    },
    {
      value: "Renal Osteodystrophy",
      label: "Renal Osteodystrophy",
    },
    {
      value: "Renal Transplant",
      label: "Renal Transplant",
    },
    {
      value: "Renal Tuberculosis",
      label: "Renal Tuberculosis",
    },
    {
      value: "Renal Vein Stenosis",
      label: "Renal Vein Stenosis",
    },
    {
      value: "Renal Vein Thrombosis",
      label: "Renal Vein Thrombosis",
    },
    {
      value: "Retinal Detachment",
      label: "Retinal Detachment",
    },
    {
      value: "Retinitis",
      label: "Retinitis",
    },
    {
      value: "Retinitis Pigmentosa",
      label: "Retinitis Pigmentosa",
    },
    {
      value: "Retinopathy",
      label: "Retinopathy",
    },
    {
      value: "Rheumatic Arthritis",
      label: "Rheumatic Arthritis",
    },
    {
      value: "Rheumatism",
      label: "Rheumatism",
    },
    {
      value: "Rheumatoid Arthritis",
      label: "Rheumatoid Arthritis",
    },
    {
      value: "Rosacea",
      label: "Rosacea",
    },
    {
      value: "Rotator Cuff Syndrome",
      label: "Rotator Cuff Syndrome",
    },
    {
      value: "Sacral Nerve Injury",
      label: "Sacral Nerve Injury",
    },
    {
      value: "Sarcoidosis",
      label: "Sarcoidosis",
    },
    {
      value: "Sarcoidosis Of Lymph Nodes",
      label: "Sarcoidosis Of Lymph Nodes",
    },
    {
      value: "Sarcoidosis Of Skin",
      label: "Sarcoidosis Of Skin",
    },
    {
      value: "Scheuermanns Disease",
      label: "Scheuermanns Disease",
    },
    {
      value: "Schizophrenia",
      label: "Schizophrenia",
    },
    {
      value: "Schizophrenia Disorganised Type",
      label: "Schizophrenia Disorganised Type",
    },
    {
      value: "Schizophrenia Infantile",
      label: "Schizophrenia Infantile",
    },
    {
      value: "Sciatica",
      label: "Sciatica",
    },
    {
      value: "Scleroderma",
      label: "Scleroderma",
    },
    {
      value: "Sclerosing Cholangitis",
      label: "Sclerosing Cholangitis",
    },
    {
      value: "Scoliosis",
      label: "Scoliosis",
    },
    {
      value: "Secondary Polycythaemia",
      label: "Secondary Polycythaemia",
    },
    {
      value: "Secondary Thrombocytopenia",
      label: "Secondary Thrombocytopenia",
    },
    {
      value: "Seminal Vesicle TB",
      label: "Seminal Vesicle TB",
    },
    {
      value: "Seminal Vesicle Tuberculosis",
      label: "Seminal Vesicle Tuberculosis",
    },
    {
      value: "Severe Depressive Episode With Psychotic Symptoms",
      label: "Severe Depressive Episode With Psychotic Symptoms",
    },
    {
      value: "Severe Mental Retardation",
      label: "Severe Mental Retardation",
    },
    {
      value: "Sickle Cell Anaemia",
      label: "Sickle Cell Anaemia",
    },
    {
      value: "Sickle-Cell Anaemia",
      label: "Sickle-Cell Anaemia",
    },
    {
      value: "Simple Schizophrenia",
      label: "Simple Schizophrenia",
    },
    {
      value: "Sinus Polyp",
      label: "Sinus Polyp",
    },
    {
      value: "Sinusitis",
      label: "Sinusitis",
    },
    {
      value: "Sjogrens Syndrome",
      label: "Sjogrens Syndrome",
    },
    {
      value: "Sjorgrens Disease",
      label: "Sjorgrens Disease",
    },
    {
      value: "Skin Rash",
      label: "Skin Rash",
    },
    {
      value: "Skin TB",
      label: "Skin TB",
    },
    {
      value: "Skin Tuberculosis",
      label: "Skin Tuberculosis",
    },
    {
      value: "Skin Ulcers",
      label: "Skin Ulcers",
    },
    {
      value: "SLE",
      label: "SLE",
    },
    {
      value: "Small Bowel Obstruction",
      label: "Small Bowel Obstruction",
    },
    {
      value: "Sphingolipidosis",
      label: "Sphingolipidosis",
    },
    {
      value: "Spina Bifida",
      label: "Spina Bifida",
    },
    {
      value: "Spina Bifida Aperta",
      label: "Spina Bifida Aperta",
    },
    {
      value: "Spina Bifida Cystic",
      label: "Spina Bifida Cystic",
    },
    {
      value: "Spina Bifida Occulta",
      label: "Spina Bifida Occulta",
    },
    {
      value: "Spinal Column Distorsion Tuberculous",
      label: "Spinal Column Distorsion Tuberculous",
    },
    {
      value: "Spinal Column Movement Restriction",
      label: "Spinal Column Movement Restriction",
    },
    {
      value: "Spinal Column Tuberculosis",
      label: "Spinal Column Tuberculosis",
    },
    {
      value: "Spinal Cord Injury",
      label: "Spinal Cord Injury",
    },
    {
      value: "Spinal Cord Paralysis",
      label: "Spinal Cord Paralysis",
    },
    {
      value: "Spinal Curvature",
      label: "Spinal Curvature",
    },
    {
      value: "Spinal Paralysis",
      label: "Spinal Paralysis",
    },
    {
      value: "Spinal Stenosis",
      label: "Spinal Stenosis",
    },
    {
      value: "Spleen Tuberculosis",
      label: "Spleen Tuberculosis",
    },
    {
      value: "Splenomegaly",
      label: "Splenomegaly",
    },
    {
      value: "Spondylitis",
      label: "Spondylitis",
    },
    {
      value: "Spondylitis Tuberculosis",
      label: "Spondylitis Tuberculosis",
    },
    {
      value: "Spondylitis Tuberculous",
      label: "Spondylitis Tuberculous",
    },
    {
      value: "Spondylolisthesis",
      label: "Spondylolisthesis",
    },
    {
      value: "Spondylolysis",
      label: "Spondylolysis",
    },
    {
      value: "Spondylosis",
      label: "Spondylosis",
    },
    {
      value: "Steatorrhea Pancreatic",
      label: "Steatorrhea Pancreatic",
    },
    {
      value: "Steatosis",
      label: "Steatosis",
    },
    {
      value: "Stein-Leventhal Syndrome",
      label: "Stein-Leventhal Syndrome",
    },
    {
      value: "Stomach Dysfunction",
      label: "Stomach Dysfunction",
    },
    {
      value: "Stomach Operation",
      label: "Stomach Operation",
    },
    {
      value: "Stomach Pain",
      label: "Stomach Pain",
    },
    {
      value: "Stomach Polyp",
      label: "Stomach Polyp",
    },
    {
      value: "Stomach Problem",
      label: "Stomach Problem",
    },
    {
      value: "Stomach Ulcer",
      label: "Stomach Ulcer",
    },
    {
      value: "Subarachnoid Bleed",
      label: "Subarachnoid Bleed",
    },
    {
      value: "Subarachnoid Haemorrhage",
      label: "Subarachnoid Haemorrhage",
    },
    {
      value: "Subarachnoid Haemorrhage (SAH)",
      label: "Subarachnoid Haemorrhage (SAH)",
    },
    {
      value: "Subdural Bleed",
      label: "Subdural Bleed",
    },
    {
      value: "Subdural Haemorrhage",
      label: "Subdural Haemorrhage",
    },
    {
      value: "Swollen Abdomen",
      label: "Swollen Abdomen",
    },
    {
      value: "Syndrome Hepatorenal",
      label: "Syndrome Hepatorenal",
    },
    {
      value: "Synovitis",
      label: "Synovitis",
    },
    {
      value: "Synovitis Tuberculous",
      label: "Synovitis Tuberculous",
    },
    {
      value: "Syphilis",
      label: "Syphilis",
    },
    {
      value: "Systemic Lupus Erythematosus",
      label: "Systemic Lupus Erythematosus",
    },
    {
      value: "Systemic Mastocytosis",
      label: "Systemic Mastocytosis",
    },
    {
      value: "Systemic Scleroderma",
      label: "Systemic Scleroderma",
    },
    {
      value: "Systemic Sclerosis",
      label: "Systemic Sclerosis",
    },
    {
      value: "Tay Sachs Disease",
      label: "Tay Sachs Disease",
    },
    {
      value: "TB Myocarditis",
      label: "TB Myocarditis",
    },
    {
      value: "Temporal (Giant Cell) Arteritis",
      label: "Temporal (Giant Cell) Arteritis",
    },
    {
      value: "Temporal Arteritis",
      label: "Temporal Arteritis",
    },
    {
      value: "Tendinitis",
      label: "Tendinitis",
    },
    {
      value: "Tennis Elbow",
      label: "Tennis Elbow",
    },
    {
      value: "Tenosynovitis",
      label: "Tenosynovitis",
    },
    {
      value: "Tenosynovitis Tuberculous",
      label: "Tenosynovitis Tuberculous",
    },
    {
      value: "Testicle Tuberculosis",
      label: "Testicle Tuberculosis",
    },
    {
      value: "Testis Tuberculosis",
      label: "Testis Tuberculosis",
    },
    {
      value: "Tetraplegia",
      label: "Tetraplegia",
    },
    {
      value: "Thalassaemia",
      label: "Thalassaemia",
    },
    {
      value: "Thalassaemia Major",
      label: "Thalassaemia Major",
    },
    {
      value: "Thalassaemia Minor",
      label: "Thalassaemia Minor",
    },
    {
      value: "Thalassaemias",
      label: "Thalassaemias",
    },
    {
      value: "Thalassemias",
      label: "Thalassemias",
    },
    {
      value: "Thoracic Spondylosis",
      label: "Thoracic Spondylosis",
    },
    {
      value: "Thrombocytosis",
      label: "Thrombocytosis",
    },
    {
      value: "Thrombopenia",
      label: "Thrombopenia",
    },
    {
      value: "Thymus Illness",
      label: "Thymus Illness",
    },
    {
      value: "Thyroid Gland Disease",
      label: "Thyroid Gland Disease",
    },
    {
      value: "Thyroid Gland Illness",
      label: "Thyroid Gland Illness",
    },
    {
      value: "Thyroid Gland Tuberculosis",
      label: "Thyroid Gland Tuberculosis",
    },
    {
      value: "Thyroid Illness",
      label: "Thyroid Illness",
    },
    {
      value: "Thyroiditis",
      label: "Thyroiditis",
    },
    {
      value: "Tietzes Syndrome",
      label: "Tietzes Syndrome",
    },
    {
      value: "Tinnitus",
      label: "Tinnitus",
    },
    {
      value: "Torsion Dystonia Fragments",
      label: "Torsion Dystonia Fragments",
    },
    {
      value: "Torsion Dystonia Idiopathic",
      label: "Torsion Dystonia Idiopathic",
    },
    {
      value: "Torsion Dystonia Symptomatic",
      label: "Torsion Dystonia Symptomatic",
    },
    {
      value: "Torticollis",
      label: "Torticollis",
    },
    {
      value: "Transient Organic Mental Disorder",
      label: "Transient Organic Mental Disorder",
    },
    {
      value: "Transient Organic Psychosis",
      label: "Transient Organic Psychosis",
    },
    {
      value: "Traumatic Subdural Haemorrhage",
      label: "Traumatic Subdural Haemorrhage",
    },
    {
      value: "Tremor",
      label: "Tremor",
    },
    {
      value: "Trigger Finger",
      label: "Trigger Finger",
    },
    {
      value: "Trisomy",
      label: "Trisomy",
    },
    {
      value: "Trisomy 13",
      label: "Trisomy 13",
    },
    {
      value: "Trisomy 18",
      label: "Trisomy 18",
    },
    {
      value: "Trisomy 21",
      label: "Trisomy 21",
    },
    {
      value: "Trisomy 22",
      label: "Trisomy 22",
    },
    {
      value: "Trisomy D",
      label: "Trisomy D",
    },
    {
      value: "Trisomy E",
      label: "Trisomy E",
    },
    {
      value: "Trisomy G",
      label: "Trisomy G",
    },
    {
      value: "Tuberculosis Hip",
      label: "Tuberculosis Hip",
    },
    {
      value: "Tuberculosis Intrathoracic Lymph Nodes",
      label: "Tuberculosis Intrathoracic Lymph Nodes",
    },
    {
      value: "Tuberculosis Of Bones And Joints",
      label: "Tuberculosis Of Bones And Joints",
    },
    {
      value: "Tuberculosis Of Central Nervous System (CNS)",
      label: "Tuberculosis Of Central Nervous System (CNS)",
    },
    {
      value: "Tuberculosis Of Genito-Urinary System",
      label: "Tuberculosis Of Genito-Urinary System",
    },
    {
      value: "Tuberculosis Of Genitourinary",
      label: "Tuberculosis Of Genitourinary",
    },
    {
      value: "Tuberculosis Of Hip",
      label: "Tuberculosis Of Hip",
    },
    {
      value: "Tuberculosis Of Intestines, Peritoneum And Mesenteric Glands",
      label: "Tuberculosis Of Intestines, Peritoneum And Mesenteric Glands",
    },
    {
      value: "Tuberculosis Of Kidney",
      label: "Tuberculosis Of Kidney",
    },
    {
      value: "Tuberculosis Of Skin And Subcutaneous Tissue",
      label: "Tuberculosis Of Skin And Subcutaneous Tissue",
    },
    {
      value: "Tuberculous Enteritis",
      label: "Tuberculous Enteritis",
    },
    {
      value: "Tuberculous Gland Inflammation",
      label: "Tuberculous Gland Inflammation",
    },
    {
      value: "Tuberculous Laryngitis",
      label: "Tuberculous Laryngitis",
    },
    {
      value: "Tuberculous Meningitis",
      label: "Tuberculous Meningitis",
    },
    {
      value: "Tuberculous Polyserositis",
      label: "Tuberculous Polyserositis",
    },
    {
      value: "Tuberculous Spinal Column",
      label: "Tuberculous Spinal Column",
    },
    {
      value: "UC",
      label: "UC",
    },
    {
      value: "Ulcer",
      label: "Ulcer",
    },
    {
      value: "Ulcerative Chronic Proctitis",
      label: "Ulcerative Chronic Proctitis",
    },
    {
      value: "Ulcerative Colitis",
      label: "Ulcerative Colitis",
    },
    {
      value: "Umbilical Hernia",
      label: "Umbilical Hernia",
    },
    {
      value: "Underactive Thyroid",
      label: "Underactive Thyroid",
    },
    {
      value: "Unspecified Blood Disease",
      label: "Unspecified Blood Disease",
    },
    {
      value: "Unspecified Blood Disorder",
      label: "Unspecified Blood Disorder",
    },
    {
      value: "Unspecified Human Immunodeficiency Virus [HIV] Disease",
      label: "Unspecified Human Immunodeficiency Virus [HIV] Disease",
    },
    {
      value: "Unspecified Spina Bifida With Hydrocephalus",
      label: "Unspecified Spina Bifida With Hydrocephalus",
    },
    {
      value: "Unspecified Viral Hepatitis",
      label: "Unspecified Viral Hepatitis",
    },
    {
      value: "Uraemia",
      label: "Uraemia",
    },
    {
      value: "Ureter Additional",
      label: "Ureter Additional",
    },
    {
      value: "Ureter Anomaly",
      label: "Ureter Anomaly",
    },
    {
      value: "Ureter Double",
      label: "Ureter Double",
    },
    {
      value: "Ureter Ectopic",
      label: "Ureter Ectopic",
    },
    {
      value: "Ureter Stricture",
      label: "Ureter Stricture",
    },
    {
      value: "Ureter Tuberculosis",
      label: "Ureter Tuberculosis",
    },
    {
      value: "Urethra Stricture",
      label: "Urethra Stricture",
    },
    {
      value: "Urethral Caruncle",
      label: "Urethral Caruncle",
    },
    {
      value: "Urinary Incontinence",
      label: "Urinary Incontinence",
    },
    {
      value: "Urinary Retention",
      label: "Urinary Retention",
    },
    {
      value: "Urinary Tract Calculus",
      label: "Urinary Tract Calculus",
    },
    {
      value: "Urinary Tract Infection",
      label: "Urinary Tract Infection",
    },
    {
      value: "Urinary Tract Tuberculosis",
      label: "Urinary Tract Tuberculosis",
    },
    {
      value: "Urine Infection",
      label: "Urine Infection",
    },
    {
      value: "Urticaria",
      label: "Urticaria",
    },
    {
      value: "Uterine Polyps",
      label: "Uterine Polyps",
    },
    {
      value: "Uterus Removal",
      label: "Uterus Removal",
    },
    {
      value: "Varicose Ulcer",
      label: "Varicose Ulcer",
    },
    {
      value: "Varicose Veins With Ulcer",
      label: "Varicose Veins With Ulcer",
    },
    {
      value: "Vascular Disease",
      label: "Vascular Disease",
    },
    {
      value: "Vascular Disease Peripheral",
      label: "Vascular Disease Peripheral",
    },
    {
      value: "Vein Embolism",
      label: "Vein Embolism",
    },
    {
      value: "Vein Thrombosis",
      label: "Vein Thrombosis",
    },
    {
      value: "Vena Cava Thrombosis",
      label: "Vena Cava Thrombosis",
    },
    {
      value: "Vertebral Stenosis",
      label: "Vertebral Stenosis",
    },
    {
      value: "Vertebrobasilar Disease",
      label: "Vertebrobasilar Disease",
    },
    {
      value: "Vestibular Vertigo",
      label: "Vestibular Vertigo",
    },
    {
      value: "Viral Hepatitis",
      label: "Viral Hepatitis",
    },
    {
      value: "Viral Hepatitis B",
      label: "Viral Hepatitis B",
    },
    {
      value: "Viral Hepatitis C",
      label: "Viral Hepatitis C",
    },
    {
      value: "Viral Hepatitis Unspecified",
      label: "Viral Hepatitis Unspecified",
    },
    {
      value: "Viral Meningitis",
      label: "Viral Meningitis",
    },
    {
      value: "Visual Field Defects",
      label: "Visual Field Defects",
    },
    {
      value: "Visual Loss Of One Eye",
      label: "Visual Loss Of One Eye",
    },
    {
      value: "Vitamin B12 Deficiency Anaemia",
      label: "Vitamin B12 Deficiency Anaemia",
    },
    {
      value:
        "Vitamin B12 Deficiency Anaemia Due To Intrinsic Factor Deficiency",
      label:
        "Vitamin B12 Deficiency Anaemia Due To Intrinsic Factor Deficiency",
    },
    {
      value: "Vitamin Deficiency",
      label: "Vitamin Deficiency",
    },
    {
      value: "Vitiligo",
      label: "Vitiligo",
    },
    {
      value: "Volvulus",
      label: "Volvulus",
    },
    {
      value: "Von Willebrands Disease",
      label: "Von Willebrands Disease",
    },
    {
      value: "Wegeners Granulomatosis",
      label: "Wegeners Granulomatosis",
    },
    {
      value: "Whipples Disease",
      label: "Whipples Disease",
    },
    {
      value: "Withdrawal State With Delirium",
      label: "Withdrawal State With Delirium",
    },
    {
      value: "Zollinger Ellison Syndrome",
      label: "Zollinger Ellison Syndrome",
    },
  ],
  RespiratoryConditions: [
    {
      value: "Acute Bronchiolitis",
      label: "Acute Bronchiolitis",
    },
    {
      value: "Acute Bronchitis",
      label: "Acute Bronchitis",
    },
    {
      value: "Acute Drug Induced Intersital Lung Disorder",
      label: "Acute Drug Induced Intersital Lung Disorder",
    },
    {
      value: "Acute Laryngitis And Epiglottitis",
      label: "Acute Laryngitis And Epiglottitis",
    },
    {
      value: "Acute Pulmonary Insufficiency Following Surgery",
      label: "Acute Pulmonary Insufficiency Following Surgery",
    },
    {
      value: "Acute Tracheitis",
      label: "Acute Tracheitis",
    },
    {
      value: "Acute Upper Respiratory Infection",
      label: "Acute Upper Respiratory Infection",
    },
    {
      value: "Adult Respiratory Distress Syndrome",
      label: "Adult Respiratory Distress Syndrome",
    },
    {
      value: "Airway Disease Due To Specific Organic Dust",
      label: "Airway Disease Due To Specific Organic Dust",
    },
    {
      value: "Airway Obstruction Chronic",
      label: "Airway Obstruction Chronic",
    },
    {
      value: "Alveolar Capillary Block",
      label: "Alveolar Capillary Block",
    },
    {
      value: "Aspergillosis",
      label: "Aspergillosis",
    },
    {
      value: "Bacterial Pneumonia",
      label: "Bacterial Pneumonia",
    },
    {
      value: "Bronchial TB",
      label: "Bronchial TB",
    },
    {
      value: "Bronchitis Unspecified",
      label: "Bronchitis Unspecified",
    },
    {
      value: "Bronchus Tuberculosis",
      label: "Bronchus Tuberculosis",
    },
    {
      value: "Chest Infection",
      label: "Chest Infection",
    },
    {
      value: "Chronic Bronchitis",
      label: "Chronic Bronchitis",
    },
    {
      value: "Chronic Drug Induced Intersital Lung Disorder",
      label: "Chronic Drug Induced Intersital Lung Disorder",
    },
    {
      value: "Chronic Laryngitis And Laryngotracheitis",
      label: "Chronic Laryngitis And Laryngotracheitis",
    },
    {
      value: "Chronic Nasopharyngitis",
      label: "Chronic Nasopharyngitis",
    },
    {
      value: "Chronic Obstructive Airways Disease",
      label: "Chronic Obstructive Airways Disease",
    },
    {
      value: "Chronic Obstructive Lung Disease",
      label: "Chronic Obstructive Lung Disease",
    },
    {
      value: "Chronic Pharyngitis",
      label: "Chronic Pharyngitis",
    },
    {
      value: "Chronic Pulmonary Insufficiency Following Surgery",
      label: "Chronic Pulmonary Insufficiency Following Surgery",
    },
    {
      value: "Chronic Rhinitis",
      label: "Chronic Rhinitis",
    },
    {
      value: "Chronic Sinusitis",
      label: "Chronic Sinusitis",
    },
    {
      value: "Chylous Effusion",
      label: "Chylous Effusion",
    },
    {
      value: "Coalworkers Pneumoconiosis",
      label: "Coalworkers Pneumoconiosis",
    },
    {
      value: "Collapsed Lung",
      label: "Collapsed Lung",
    },
    {
      value: "Compensatory Emphysema",
      label: "Compensatory Emphysema",
    },
    {
      value: "Cystic Fibrosis",
      label: "Cystic Fibrosis",
    },
    {
      value: "Diffuse Pulmonary Fibrosis",
      label: "Diffuse Pulmonary Fibrosis",
    },
    {
      value: "Diphtheria",
      label: "Diphtheria",
    },
    {
      value: "Double Lung Transplant",
      label: "Double Lung Transplant",
    },
    {
      value: "Drug Induced Intersital Lung Disorder",
      label: "Drug Induced Intersital Lung Disorder",
    },
    {
      value: "Dyspnoea",
      label: "Dyspnoea",
    },
    {
      value: "Emphysema Chronic",
      label: "Emphysema Chronic",
    },
    {
      value: "Extrinsic Asthma",
      label: "Extrinsic Asthma",
    },
    {
      value: "Fibrosis Cystic",
      label: "Fibrosis Cystic",
    },
    {
      value: "Fibrosis Of Lung Following Radiation",
      label: "Fibrosis Of Lung Following Radiation",
    },
    {
      value: "Fibrothorax",
      label: "Fibrothorax",
    },
    {
      value: "Flu With Pneumonia",
      label: "Flu With Pneumonia",
    },
    {
      value: "Ghon Focus",
      label: "Ghon Focus",
    },
    {
      value: "Haemoptysis",
      label: "Haemoptysis",
    },
    {
      value: "Haemothorax",
      label: "Haemothorax",
    },
    {
      value: "Hamman Rich Syndrome",
      label: "Hamman Rich Syndrome",
    },
    {
      value: "Hay Fever",
      label: "Hay Fever",
    },
    {
      value: "Heart Pulmonary Acute",
      label: "Heart Pulmonary Acute",
    },
    {
      value: "Hypersensitivity Pneumonitis Due To Organic Dust",
      label: "Hypersensitivity Pneumonitis Due To Organic Dust",
    },
    {
      value: "Hypertension Pulmonary",
      label: "Hypertension Pulmonary",
    },
    {
      value: "Idiopathic Fibrosing Alveolitis",
      label: "Idiopathic Fibrosing Alveolitis",
    },
    {
      value: "Idiopathic Interstitial Pulmonary Fibrosis",
      label: "Idiopathic Interstitial Pulmonary Fibrosis",
    },
    {
      value: "Influenza",
      label: "Influenza",
    },
    {
      value: "Influenza Due To Identified Influenza Virus",
      label: "Influenza Due To Identified Influenza Virus",
    },
    {
      value: "Influenza With Pneumonia",
      label: "Influenza With Pneumonia",
    },
    {
      value: "Intersital Pulmonary Disease",
      label: "Intersital Pulmonary Disease",
    },
    {
      value: "Intersitial Emphysema",
      label: "Intersitial Emphysema",
    },
    {
      value: "Intrinsic Asthma",
      label: "Intrinsic Asthma",
    },
    {
      value: "Larynx Tuberculosis",
      label: "Larynx Tuberculosis",
    },
    {
      value: "Lung Abscess",
      label: "Lung Abscess",
    },
    {
      value: "Lung Sarcoidosis",
      label: "Lung Sarcoidosis",
    },
    {
      value: "Lung Silicotic Fibrosis",
      label: "Lung Silicotic Fibrosis",
    },
    {
      value: "Lung Transplant",
      label: "Lung Transplant",
    },
    {
      value: "Lungs Tuberculosis",
      label: "Lungs Tuberculosis",
    },
    {
      value: "Mediastinal TB",
      label: "Mediastinal TB",
    },
    {
      value: "Mediastinum Tuberculosis",
      label: "Mediastinum Tuberculosis",
    },
    {
      value: "Mendelsons Syndrome",
      label: "Mendelsons Syndrome",
    },
    {
      value: "Mucoviscidosis",
      label: "Mucoviscidosis",
    },
    {
      value: "Obstructive Emphysema",
      label: "Obstructive Emphysema",
    },
    {
      value: "Obstructive Sleep Apnoea",
      label: "Obstructive Sleep Apnoea",
    },
    {
      value: "Other Interstitial Pulmonary Diseases",
      label: "Other Interstitial Pulmonary Diseases",
    },
    {
      value: "Pleura Tuberculosis",
      label: "Pleura Tuberculosis",
    },
    {
      value: "Pleural Effusion",
      label: "Pleural Effusion",
    },
    {
      value: "Pleurisy Tuberculous",
      label: "Pleurisy Tuberculous",
    },
    {
      value: "Pneumoconiosis Due To Asbestos And Other Mineral Fibres",
      label: "Pneumoconiosis Due To Asbestos And Other Mineral Fibres",
    },
    {
      value: "Pneumoconiosis Due To Dust Containing Silica",
      label: "Pneumoconiosis Due To Dust Containing Silica",
    },
    {
      value: "Pneumoconiosis Due To Other Inorganic Dusts",
      label: "Pneumoconiosis Due To Other Inorganic Dusts",
    },
    {
      value: "Pneumonia",
      label: "Pneumonia",
    },
    {
      value: "Pneumonia Tuberculous",
      label: "Pneumonia Tuberculous",
    },
    {
      value: "Pneumonitis",
      label: "Pneumonitis",
    },
    {
      value: "Pneumothorax",
      label: "Pneumothorax",
    },
    {
      value: "Pneumothorax Tuberculous",
      label: "Pneumothorax Tuberculous",
    },
    {
      value: "Postprocedural Respiratory Disorders",
      label: "Postprocedural Respiratory Disorders",
    },
    {
      value: "Predominantly Allergic Asthma",
      label: "Predominantly Allergic Asthma",
    },
    {
      value: "Primary Pulmonary Hypertension",
      label: "Primary Pulmonary Hypertension",
    },
    {
      value:
        "Primary Respiratory Tuberculosis Without Mention Of Bacteriological Or Histological Confirmation",
      label:
        "Primary Respiratory Tuberculosis Without Mention Of Bacteriological Or Histological Confirmation",
    },
    {
      value: "Primary Tuberculous Complex",
      label: "Primary Tuberculous Complex",
    },
    {
      value: "Pulmonary Collapse",
      label: "Pulmonary Collapse",
    },
    {
      value: "Pulmonary Edema",
      label: "Pulmonary Edema",
    },
    {
      value: "Pulmonary Embolism",
      label: "Pulmonary Embolism",
    },
    {
      value: "Pulmonary Embolus",
      label: "Pulmonary Embolus",
    },
    {
      value: "Pulmonary Eosinophilia",
      label: "Pulmonary Eosinophilia",
    },
    {
      value: "Pulmonary Fibrosis",
      label: "Pulmonary Fibrosis",
    },
    {
      value: "Pulmonary Incompetence",
      label: "Pulmonary Incompetence",
    },
    {
      value: "Pulmonary Infarction",
      label: "Pulmonary Infarction",
    },
    {
      value: "Pulmonary Insufficiency",
      label: "Pulmonary Insufficiency",
    },
    {
      value: "Pulmonary Oedema",
      label: "Pulmonary Oedema",
    },
    {
      value: "Pulmonary Regurgitation",
      label: "Pulmonary Regurgitation",
    },
    {
      value: "Pulmonary TB",
      label: "Pulmonary TB",
    },
    {
      value: "Pulmonary Thrombo Embolic Disease",
      label: "Pulmonary Thrombo Embolic Disease",
    },
    {
      value: "Pulmonary Thromboembolic Disease",
      label: "Pulmonary Thromboembolic Disease",
    },
    {
      value: "Pyothorax",
      label: "Pyothorax",
    },
    {
      value: "Radiation Pneumonitis",
      label: "Radiation Pneumonitis",
    },
    {
      value: "Resp TB",
      label: "Resp TB",
    },
    {
      value: "Respiratory Failure",
      label: "Respiratory Failure",
    },
    {
      value: "Respiratory TB",
      label: "Respiratory TB",
    },
    {
      value: "Respiratory Tract Infection",
      label: "Respiratory Tract Infection",
    },
    {
      value: "Respiratorytract Tuberculosis",
      label: "Respiratorytract Tuberculosis",
    },
    {
      value: "Rhinitis",
      label: "Rhinitis",
    },
    {
      value: "Secondary Pulmonary Hypertension",
      label: "Secondary Pulmonary Hypertension",
    },
    {
      value: "Shortness Breath",
      label: "Shortness Breath",
    },
    {
      value: "Silicosis",
      label: "Silicosis",
    },
    {
      value: "Silicotuberculosis",
      label: "Silicotuberculosis",
    },
    {
      value: "Simple And Mucopurulent Chronic Bronchitis",
      label: "Simple And Mucopurulent Chronic Bronchitis",
    },
    {
      value: "Sleep Apnoea",
      label: "Sleep Apnoea",
    },
    {
      value: "Smokers Cough",
      label: "Smokers Cough",
    },
    {
      value: "Status Asthmaticus",
      label: "Status Asthmaticus",
    },
    {
      value: "TB",
      label: "TB",
    },
    {
      value: "TB Lung",
      label: "TB Lung",
    },
    {
      value: "Tracheostomy Malfunction",
      label: "Tracheostomy Malfunction",
    },
    {
      value: "Tuberculosis",
      label: "Tuberculosis",
    },
    {
      value: "Tuberculosis Bronchial Isolated",
      label: "Tuberculosis Bronchial Isolated",
    },
    {
      value: "Tuberculosis General",
      label: "Tuberculosis General",
    },
    {
      value: "Tuberculosis Lungs Cavitation",
      label: "Tuberculosis Lungs Cavitation",
    },
    {
      value: "Tuberculosis Lungs Infiltrative",
      label: "Tuberculosis Lungs Infiltrative",
    },
    {
      value: "Tuberculosis Of Lung, Confirmed By Unspecified Means",
      label: "Tuberculosis Of Lung, Confirmed By Unspecified Means",
    },
    {
      value: "Tuberculosis Pleurisy",
      label: "Tuberculosis Pleurisy",
    },
    {
      value: "Tuberculosis Primary Progressive",
      label: "Tuberculosis Primary Progressive",
    },
    {
      value: "Tuberculosis Primary Specified",
      label: "Tuberculosis Primary Specified",
    },
    {
      value: "Tuberculosis Pulmonary",
      label: "Tuberculosis Pulmonary",
    },
    {
      value: "Tuberculosis Respiratory",
      label: "Tuberculosis Respiratory",
    },
    {
      value:
        "Tuberculous Pleurisy, Without Mention Of Bacteriological Or Histological Confirmation",
      label:
        "Tuberculous Pleurisy, Without Mention Of Bacteriological Or Histological Confirmation",
    },
    {
      value: "Upper Respiratory Tract Disease",
      label: "Upper Respiratory Tract Disease",
    },
    {
      value: "Vasomotor And Allergic Rhinitis",
      label: "Vasomotor And Allergic Rhinitis",
    },
    {
      value: "Viral Pneumonia",
      label: "Viral Pneumonia",
    },
  ],
  StrokeConditions: [
    {
      value: "TIA (Transient Ischaemic Attack - Mini-Stroke)",
      label: "TIA (Transient Ischaemic Attack - Mini-Stroke)",
    },
    {
      value: "Subarachnoid Haemorrhage (SAH)",
      label: "Subarachnoid Haemorrhage (SAH)",
    },
    {
      value: "Cerebral Haemorrhage/Bleed",
      label: "Cerebral Haemorrhage/Bleed",
    },
    {
      value: "CVA (Cerebrovascular Accident - Major Stroke)",
      label: "CVA (Cerebrovascular Accident - Major Stroke)",
    },
  ],
};

export const groupedOptions: IGroupedOption[] = [
  {
    label: "Heart",
    groupValue: "210",
    options: allConditions.HeartConditions,
  },
  {
    label: "Cancer",
    groupValue: "212",
    options: allConditions.CancerConditions,
  },
  {
    label: "Multiple Sclerosis",
    groupValue: "215",
    options: allConditions.MultipleSclerosisConditions,
  },
  {
    label: "Neurological",
    groupValue: "216",
    options: allConditions.NeurologicalConditions,
  },
  {
    label: "Other",
    groupValue: "208",
    options: allConditions.OtherConditions,
  },
  {
    label: "Respiratory",
    groupValue: "214",
    options: allConditions.RespiratoryConditions,
  },
  {
    label: "Stroke",
    groupValue: "213",
    options: allConditions.StrokeConditions,
  },
];

interface IData {
  Group: string;
  Value: string;
  ICD: string;
  ExchangeCode: number;
}

// const transformOrigoJSONData = (data: IData[]): Record<string, IOption[]> => {
//     const groupedOptions: Record<string, IOption[]> = {};

//     data.forEach((item) => {
//       const groupName = item.Group + 'Conditions';

//       if (!groupedOptions[groupName]) {
//         groupedOptions[groupName] = [];
//       }

//       groupedOptions[groupName].push({
//         value: item.Value,
//         label: item.Value,
//       });
//     });

//     return groupedOptions;
//   };

// const initialData =
// [        {
//     "Group": "Cancer",
//     "Value": "Abdomen Cancer",
//     "ICD": "C49",
//     "ExchangeCode": 2
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Abdominal Cancer",
//     "ICD": "C49",
//     "ExchangeCode": 5
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Accessory Sinuses Malignant Neoplasm",
//     "ICD": "C31",
//     "ExchangeCode": 876
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acoustic Neuroma",
//     "ICD": "D33",
//     "ExchangeCode": 6
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acusticus Neurinoma",
//     "ICD": "C72.4",
//     "ExchangeCode": 10
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Lymphoblastic Leukaemia",
//     "ICD": "C91.0",
//     "ExchangeCode": 2083
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Lymphocytic Leukaemia",
//     "ICD": "C91",
//     "ExchangeCode": 13
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Lymphoid Leukaemia",
//     "ICD": "C91",
//     "ExchangeCode": 14
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Megakaryoblastic Leukaemia",
//     "ICD": "C94.2",
//     "ExchangeCode": 2097
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Monocytic Leukaemia",
//     "ICD": "C93.0",
//     "ExchangeCode": 2094
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Myelocytic Leukaemia",
//     "ICD": "C92",
//     "ExchangeCode": 15
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Myelofibrosis",
//     "ICD": "C94.5",
//     "ExchangeCode": 2100
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Myeloid Leukaemia",
//     "ICD": "C92",
//     "ExchangeCode": 16
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Myelomonocytic Leukaemia",
//     "ICD": "C92.5",
//     "ExchangeCode": 2092
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Panmyelosis",
//     "ICD": "C94.4",
//     "ExchangeCode": 2099
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Acute Promyelocytic Leukaemia",
//     "ICD": "C92.4",
//     "ExchangeCode": 2091
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adeno CA Prostate",
//     "ICD": "C61",
//     "ExchangeCode": 24
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adeno Carcinoma Prostate",
//     "ICD": "C61",
//     "ExchangeCode": 25
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adenocarcinoma Prostate",
//     "ICD": "C61",
//     "ExchangeCode": 25
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adenoid Cancer",
//     "ICD": "C11.1",
//     "ExchangeCode": 26
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adenomatous Polyposis",
//     "ICD": "D12",
//     "ExchangeCode": 2057
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adenomatous Polyposis Coli",
//     "ICD": "D12",
//     "ExchangeCode": 2057
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adrenal Cancer",
//     "ICD": "C74",
//     "ExchangeCode": 34
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adrenal Gland Cancer",
//     "ICD": "C74",
//     "ExchangeCode": 34
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adrenal Gland Malignancy",
//     "ICD": "C74.9",
//     "ExchangeCode": 35
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adrenal Gland Neoplasm",
//     "ICD": "C74",
//     "ExchangeCode": 36
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adrenal Gland Tumour",
//     "ICD": "C74.9",
//     "ExchangeCode": 39
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Adult T-Cell Leukaemia",
//     "ICD": "C91.5",
//     "ExchangeCode": 2107
//    },
//    {
//     "Group": "Cancer",
//     "Value": "ALL",
//     "ICD": "C91.0",
//     "ExchangeCode": 58
//    },
//    {
//     "Group": "Cancer",
//     "Value": "AML",
//     "ICD": "C92.0",
//     "ExchangeCode": 63
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Anal Cancer",
//     "ICD": "C21",
//     "ExchangeCode": 71
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Anal Tumour",
//     "ICD": "C21",
//     "ExchangeCode": 71
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Anus And Anal Canal Malignant Neoplasm",
//     "ICD": "C21",
//     "ExchangeCode": 71
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Anus Neoplasm",
//     "ICD": "D37.7",
//     "ExchangeCode": 85
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Arm Cancer",
//     "ICD": "C40",
//     "ExchangeCode": 107
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Arm Carcinoma",
//     "ICD": "C40",
//     "ExchangeCode": 108
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Arm Neoplasm Malignant",
//     "ICD": "C40",
//     "ExchangeCode": 110
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Astrocytoma",
//     "ICD": "C71.9",
//     "ExchangeCode": 137
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Autonomic Nervous System Neoplasm",
//     "ICD": "C47",
//     "ExchangeCode": 1117
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Axilla Cancer",
//     "ICD": "C77.3",
//     "ExchangeCode": 154
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Axilla Neoplasm Malignant",
//     "ICD": "C49.3",
//     "ExchangeCode": 156
//    },
//    {
//     "Group": "Cancer",
//     "Value": "B-Cell Lymphoma",
//     "ICD": "C85.1",
//     "ExchangeCode": 704
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Back Cancer",
//     "ICD": "C49.6",
//     "ExchangeCode": 158
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Back Neoplasm Malignant",
//     "ICD": "C49.6",
//     "ExchangeCode": 159
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Basal Cell Carcinoma",
//     "ICD": "C44.9",
//     "ExchangeCode": 163
//    },
//    {
//     "Group": "Cancer",
//     "Value": "BCC",
//     "ICD": "C44",
//     "ExchangeCode": 166
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Bladder Tumour",
//     "ICD": "D30.3",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Brain Tumour",
//     "ICD": "D33.2",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Breast Lump",
//     "ICD": "D24",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Breast Tumour",
//     "ICD": "D24",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Colon Tumour",
//     "ICD": "D12.6",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Ear Neoplasm",
//     "ICD": "D14.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Ear Tumour",
//     "ICD": "D14.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Liver Tumour",
//     "ICD": "D13.4",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Lung Tumour",
//     "ICD": "D14.3",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm",
//     "ICD": "D36.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Bladder",
//     "ICD": "D30.3",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Bone",
//     "ICD": "D16.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Colon",
//     "ICD": "D12.6",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Larynx",
//     "ICD": "D14.1",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Lung",
//     "ICD": "D14.3",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Nose",
//     "ICD": "D36.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Bladder",
//     "ICD": "D30.3",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Brain",
//     "ICD": "D33.2",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Brain And Other Parts Of Central Nervous System",
//     "ICD": "D33",
//     "ExchangeCode": 6
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Breast",
//     "ICD": "D24",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Descending Colon",
//     "ICD": "D12.4",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Ear",
//     "ICD": "D14.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Endocrine Gland",
//     "ICD": "D35.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Joints",
//     "ICD": "D16.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Lung",
//     "ICD": "D14.3",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Lymph Nodes",
//     "ICD": "D36.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Mammary Gland",
//     "ICD": "D24",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Middle Ear",
//     "ICD": "D14.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Nasal Cavity",
//     "ICD": "D14.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Neck",
//     "ICD": "D36.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Nose",
//     "ICD": "D36.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Pancreas",
//     "ICD": "D13.6",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Parotid Gland",
//     "ICD": "D11.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Peripheral Nerves",
//     "ICD": "D36.1",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Pituitary Gland",
//     "ICD": "D35.2",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Pleura",
//     "ICD": "D15.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Prostate",
//     "ICD": "D29.1",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Rectum",
//     "ICD": "D12.8",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Shoulder",
//     "ICD": "D36.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Sigmoid Colon",
//     "ICD": "D12.5",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Skin",
//     "ICD": "D23.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Skin Of Arm",
//     "ICD": "D23.6",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Skin Of Upper Limb",
//     "ICD": "D23.6",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Stomach",
//     "ICD": "D13.1",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of The Bladder",
//     "ICD": "D30.3",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of The Pituitary Gland",
//     "ICD": "D35.2",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of The Stomach",
//     "ICD": "D13.1",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Thyroid Gland",
//     "ICD": "D34",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Of Uterus",
//     "ICD": "D26.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm On Back",
//     "ICD": "D36.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Parathyroid",
//     "ICD": "D351",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Shoulder",
//     "ICD": "D36.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Skin",
//     "ICD": "D23.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Neoplasm Stomach",
//     "ICD": "D13.1",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Pituitary Gland Tumour",
//     "ICD": "D35.2",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Prostate Tumour",
//     "ICD": "D29.1",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Scrotum Tumour",
//     "ICD": "D29.4",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Stomach Tumour",
//     "ICD": "D13.1",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumor Of Bladder",
//     "ICD": "D30.3",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumor Of Middle Ear",
//     "ICD": "D14.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumor Of Parotid Gland",
//     "ICD": "D11.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumor Of Pituitary Gland",
//     "ICD": "D35.2",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumor Of Prostate",
//     "ICD": "D29.1",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumor Of The Ovary",
//     "ICD": "D27",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour",
//     "ICD": "D36.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Eye",
//     "ICD": "D31.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour In Ear",
//     "ICD": "D14.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Liver",
//     "ICD": "D13.4",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Neck",
//     "ICD": "D36.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Adrenal Gland",
//     "ICD": "D35.0",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Back",
//     "ICD": "D36.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Bladder",
//     "ICD": "D30.3",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Bone",
//     "ICD": "D16.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Brain",
//     "ICD": "D33.2",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Colon",
//     "ICD": "D12.6",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Neck",
//     "ICD": "D36.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Ovarian",
//     "ICD": "D27",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Pancreas",
//     "ICD": "D13.6",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Pelvis",
//     "ICD": "D16.8",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Pituitary Gland",
//     "ICD": "D35.2",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Skull",
//     "ICD": "D36.7",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Small Intestine",
//     "ICD": "D13.3",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Spinal Cord",
//     "ICD": "D33.4",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Stomach",
//     "ICD": "D13.1",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of The Brain",
//     "ICD": "D33.2",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of The Colon",
//     "ICD": "D12.6",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Tumour Of Thyroid Gland",
//     "ICD": "D34",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Benign Uterus Neoplasm",
//     "ICD": "D26.9",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Biliary Tract Cancer",
//     "ICD": "C24",
//     "ExchangeCode": 748
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Biliary Tract Malignant Neoplasm",
//     "ICD": "C24",
//     "ExchangeCode": 748
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bladder Cancer",
//     "ICD": "C67",
//     "ExchangeCode": 177
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bladder Malignant Neoplasm",
//     "ICD": "C67",
//     "ExchangeCode": 178
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bladder Neoplasm Malignant",
//     "ICD": "C67",
//     "ExchangeCode": 178
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Blast Cell Leukaemia",
//     "ICD": "C95.0",
//     "ExchangeCode": 704
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Blood Vessel Cancer",
//     "ICD": "C49.0",
//     "ExchangeCode": 188
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bloodvessel Cancer",
//     "ICD": "C49.0",
//     "ExchangeCode": 189
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bloodvessel Neoplasm Malignant",
//     "ICD": "C80",
//     "ExchangeCode": 190
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bone Cancer",
//     "ICD": "C40",
//     "ExchangeCode": 191
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bone Neoplasm Malignant",
//     "ICD": "C40",
//     "ExchangeCode": 192
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bone Tumour Malignant",
//     "ICD": "C40",
//     "ExchangeCode": 194
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bones Of Skull And Face Malignant Neoplasm",
//     "ICD": "C41",
//     "ExchangeCode": 757
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bowel Cancer",
//     "ICD": "C17",
//     "ExchangeCode": 195
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bowel Tumour",
//     "ICD": "C17",
//     "ExchangeCode": 195
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Brain Cancer",
//     "ICD": "C71",
//     "ExchangeCode": 198
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Brain Malignant Neoplasm",
//     "ICD": "C71",
//     "ExchangeCode": 203
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Brain Neoplasm Malignant",
//     "ICD": "C71",
//     "ExchangeCode": 203
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Brain Stem Cancer",
//     "ICD": "C71.7",
//     "ExchangeCode": 204
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Brain Tumour",
//     "ICD": "C71",
//     "ExchangeCode": 206
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Breast Cancer",
//     "ICD": "C50.9",
//     "ExchangeCode": 207
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Breast Carcinoma",
//     "ICD": "C50.9",
//     "ExchangeCode": 207
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Breast Carcinoma In Situ",
//     "ICD": "D05.9",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Breast Malignant Neoplasm",
//     "ICD": "C50",
//     "ExchangeCode": 207
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Breast Neoplasm Benign",
//     "ICD": "D24",
//     "ExchangeCode": 1638
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Breast Neoplasm Malignant",
//     "ICD": "C50.9",
//     "ExchangeCode": 208
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Breast Tumour",
//     "ICD": "D48.6",
//     "ExchangeCode": 207
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bronchial Cancer",
//     "ICD": "C34",
//     "ExchangeCode": 211
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Bronchus And Lung Malignant Neoplasm",
//     "ICD": "C34",
//     "ExchangeCode": 698
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Buccal Cancer",
//     "ICD": "C06.9",
//     "ExchangeCode": 216
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Burkitts Lymphoma",
//     "ICD": "C83.7",
//     "ExchangeCode": 218
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Burkitts Tumour",
//     "ICD": "C83.7",
//     "ExchangeCode": 2076
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Buttocks Cancer",
//     "ICD": "C76.7",
//     "ExchangeCode": 219
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Buttocks Neoplasm Malignant",
//     "ICD": "C76.7",
//     "ExchangeCode": 220
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Ca Adrenal Gland",
//     "ICD": "C74.9",
//     "ExchangeCode": 222
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Caecum Cancer",
//     "ICD": "C18.0",
//     "ExchangeCode": 330
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Bladder",
//     "ICD": "C67.9",
//     "ExchangeCode": 750
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Cervix-Uteri",
//     "ICD": "C53.9",
//     "ExchangeCode": 283
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Colon",
//     "ICD": "C18.9",
//     "ExchangeCode": 227
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Kidney",
//     "ICD": "C64",
//     "ExchangeCode": 653
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Liver",
//     "ICD": "C22.9",
//     "ExchangeCode": 228
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Lymph Nodes",
//     "ICD": "C77.9",
//     "ExchangeCode": 704
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Mandible",
//     "ICD": "C41.1",
//     "ExchangeCode": 1014
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Bladder",
//     "ICD": "C67.9",
//     "ExchangeCode": 750
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Colon",
//     "ICD": "C18.9",
//     "ExchangeCode": 227
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Connective And Soft Tissue",
//     "ICD": "C49.9",
//     "ExchangeCode": 777
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Esophagus",
//     "ICD": "C15.9",
//     "ExchangeCode": 1140
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Face",
//     "ICD": "C76.0",
//     "ExchangeCode": 473
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Kidney",
//     "ICD": "C64",
//     "ExchangeCode": 653
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Kidneys",
//     "ICD": "C64",
//     "ExchangeCode": 653
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Lesser Stomach Curvature",
//     "ICD": "C16.5",
//     "ExchangeCode": 841
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Lung",
//     "ICD": "C34.9",
//     "ExchangeCode": 229
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Meninges",
//     "ICD": "C70",
//     "ExchangeCode": 1028
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Nasopharynx",
//     "ICD": "C14.0",
//     "ExchangeCode": 930
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Oesophagus",
//     "ICD": "C15.9",
//     "ExchangeCode": 1140
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Ovaries",
//     "ICD": "C56",
//     "ExchangeCode": 1165
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Ovary",
//     "ICD": "C56",
//     "ExchangeCode": 1165
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Pancreas",
//     "ICD": "C25.9",
//     "ExchangeCode": 1172
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Pharynx",
//     "ICD": "C14.0",
//     "ExchangeCode": 930
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Prostate",
//     "ICD": "C61",
//     "ExchangeCode": 1285
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Rectum",
//     "ICD": "C20",
//     "ExchangeCode": 232
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Scrotum",
//     "ICD": "C63.2",
//     "ExchangeCode": 1402
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Shoulder Skin",
//     "ICD": "C44.6",
//     "ExchangeCode": 1160
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Skin Of Ear",
//     "ICD": "C44.2",
//     "ExchangeCode": 1153
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Sternum",
//     "ICD": "C41.3",
//     "ExchangeCode": 1485
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of The Bladder",
//     "ICD": "C67.9",
//     "ExchangeCode": 750
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of The Colon",
//     "ICD": "C18.9",
//     "ExchangeCode": 227
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of The Eye",
//     "ICD": "C69.9",
//     "ExchangeCode": 470
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of The Larynx",
//     "ICD": "C32.9",
//     "ExchangeCode": 835
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of The Oesophagus",
//     "ICD": "C15.9",
//     "ExchangeCode": 1140
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of The Scrotum",
//     "ICD": "C63.2",
//     "ExchangeCode": 1402
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of The Uterus",
//     "ICD": "C55",
//     "ExchangeCode": 754
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of The Womb",
//     "ICD": "C55",
//     "ExchangeCode": 243
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Uterus",
//     "ICD": "C55",
//     "ExchangeCode": 754
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Of Womb",
//     "ICD": "C55",
//     "ExchangeCode": 243
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Scrotum",
//     "ICD": "C63.2",
//     "ExchangeCode": 1402
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Secondary",
//     "ICD": "C79.9",
//     "ExchangeCode": 241
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Splenic Flexure",
//     "ICD": "C18.5",
//     "ExchangeCode": 966
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Unspecified",
//     "ICD": "C80.9",
//     "ExchangeCode": 242
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cancer Womb",
//     "ICD": "C55",
//     "ExchangeCode": 243
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoid Syndrome",
//     "ICD": "E34.0",
//     "ExchangeCode": 245
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma Bladder",
//     "ICD": "C67.9",
//     "ExchangeCode": 750
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma Breast",
//     "ICD": "C50.9",
//     "ExchangeCode": 207
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Anus",
//     "ICD": "D01.3",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Bile Ducts",
//     "ICD": "D01.5",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Bladder",
//     "ICD": "D09.0",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Breast",
//     "ICD": "D05.9",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Cervix",
//     "ICD": "D06.9",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Cervix Uteri",
//     "ICD": "D06.9",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Colon",
//     "ICD": "D01.0",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Prostate",
//     "ICD": "D07.5",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Rectum",
//     "ICD": "D01.2",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Skin Of Lip",
//     "ICD": "D04.0",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma In Situ Of Thyroid Gland",
//     "ICD": "D09.3",
//     "ExchangeCode": 1637
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma Of Bladder",
//     "ICD": "C67.9",
//     "ExchangeCode": 750
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma Of Colon",
//     "ICD": "C18.9",
//     "ExchangeCode": 227
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma Of Prostate",
//     "ICD": "C61",
//     "ExchangeCode": 1287
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma Of Rectum",
//     "ICD": "C20",
//     "ExchangeCode": 232
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma Of Skin",
//     "ICD": "C44.9",
//     "ExchangeCode": 1157
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma Prostate",
//     "ICD": "C61",
//     "ExchangeCode": 1287
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinoma Rectum",
//     "ICD": "C20",
//     "ExchangeCode": 232
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Carcinosis",
//     "ICD": "C80",
//     "ExchangeCode": 246
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cerebellar Cancer",
//     "ICD": "C71.6",
//     "ExchangeCode": 262
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cerebral Tumour",
//     "ICD": "C71.6",
//     "ExchangeCode": 276
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cervical Cancer",
//     "ICD": "C53",
//     "ExchangeCode": 280
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cervix Cancer",
//     "ICD": "C53",
//     "ExchangeCode": 282
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cervix Uteri Cancer",
//     "ICD": "C53",
//     "ExchangeCode": 283
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cheek Cancer",
//     "ICD": "C43",
//     "ExchangeCode": 285
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Cheek Neoplasm Malignant",
//     "ICD": "C43",
//     "ExchangeCode": 286
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Chondrosarcoma",
//     "ICD": "C41",
//     "ExchangeCode": 2173
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Choroid Neoplasm",
//     "ICD": "C69.3",
//     "ExchangeCode": 295
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Chronic Lymphocytic Leukaemia",
//     "ICD": "C91.1",
//     "ExchangeCode": 301
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Chronic Lymphoid Leukaemia",
//     "ICD": "C91.1",
//     "ExchangeCode": 302
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Chronic Monocytic Leukaemia",
//     "ICD": "C93.1",
//     "ExchangeCode": 2095
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Chronic Myelocytic Leukaemia",
//     "ICD": "C92.1",
//     "ExchangeCode": 303
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Chronic Myelogenous",
//     "ICD": "C92.1",
//     "ExchangeCode": 304
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Chronic Myeloid Leukaemia",
//     "ICD": "C92.1",
//     "ExchangeCode": 305
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Chronic Myelomonocytic Leukaemia",
//     "ICD": "C92",
//     "ExchangeCode": 306
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Clavicle Cancer",
//     "ICD": "C41.3",
//     "ExchangeCode": 314
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Clavicle Neoplasm Malignant",
//     "ICD": "C41.3",
//     "ExchangeCode": 315
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Clitoris Cancer",
//     "ICD": "C51.2",
//     "ExchangeCode": 316
//    },
//    {
//     "Group": "Cancer",
//     "Value": "CLL",
//     "ICD": "C91.1",
//     "ExchangeCode": 317
//    },
//    {
//     "Group": "Cancer",
//     "Value": "CML",
//     "ICD": "C92.1",
//     "ExchangeCode": 318
//    },
//    {
//     "Group": "Cancer",
//     "Value": "CNS Cancer",
//     "ICD": "C72.9",
//     "ExchangeCode": 319
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Coccyx Cancer",
//     "ICD": "C41",
//     "ExchangeCode": 324
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Coccyx Neoplasm",
//     "ICD": "C41.4",
//     "ExchangeCode": 325
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Coccyx Neoplasm Malignant",
//     "ICD": "C41.4",
//     "ExchangeCode": 326
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Colon Cancer",
//     "ICD": "C18",
//     "ExchangeCode": 330
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Colon Malignant Neoplasm",
//     "ICD": "C18",
//     "ExchangeCode": 330
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Colorectal Cancer",
//     "ICD": "C19",
//     "ExchangeCode": 774
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Common Bile Duct Malignant Neoplasm",
//     "ICD": "C24",
//     "ExchangeCode": 748
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Connective Tissue Cancer",
//     "ICD": "C49.9",
//     "ExchangeCode": 347
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Connective Tissue Neoplasm Malignant",
//     "ICD": "C49.9",
//     "ExchangeCode": 349
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Corpus Uteri, Unspecified",
//     "ICD": "C54.9",
//     "ExchangeCode": 2082
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Coxa Cancer",
//     "ICD": "C76.5",
//     "ExchangeCode": 366
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Coxa Neoplasm Malignant",
//     "ICD": "C76.5",
//     "ExchangeCode": 367
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Di Guglielmos Disease",
//     "ICD": "C94.0",
//     "ExchangeCode": 1073
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Diaphragm Cancer",
//     "ICD": "C49.9",
//     "ExchangeCode": 407
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Diaphragm Neoplasm Malignant",
//     "ICD": "C49.9",
//     "ExchangeCode": 408
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Diffuse Non-Hodgkins Lymphoma",
//     "ICD": "C83",
//     "ExchangeCode": 2075
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Disseminated Malignant Neoplasm",
//     "ICD": "C80",
//     "ExchangeCode": 414
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Endocervical Cancer",
//     "ICD": "C53",
//     "ExchangeCode": 452
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Essential Thrombocythemia",
//     "ICD": "D47",
//     "ExchangeCode": 463
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Extramedullary Plasmacytoma",
//     "ICD": "C90.2",
//     "ExchangeCode": 1245
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Eye Cancer",
//     "ICD": "C69",
//     "ExchangeCode": 466
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Eye Neoplasm Malignant",
//     "ICD": "C69",
//     "ExchangeCode": 467
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Eyeball Cancer",
//     "ICD": "C69.4",
//     "ExchangeCode": 470
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Eyesocket Cancer",
//     "ICD": "C69",
//     "ExchangeCode": 471
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Eyesocket Neoplasm Malignant",
//     "ICD": "C69",
//     "ExchangeCode": 472
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Face Cancer",
//     "ICD": "C76",
//     "ExchangeCode": 473
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Face Neoplasm Malignant",
//     "ICD": "C76",
//     "ExchangeCode": 474
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Fallopian Tube Cancer",
//     "ICD": "C57",
//     "ExchangeCode": 798
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Familial Adenomatous Polyposis (FAP)",
//     "ICD": "D12",
//     "ExchangeCode": 2057
//    },
//    {
//     "Group": "Cancer",
//     "Value": "FAP",
//     "ICD": "D12",
//     "ExchangeCode": 2057
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Female Genital Cancer",
//     "ICD": "C57",
//     "ExchangeCode": 913
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Follicular Cell Ca Thyroid",
//     "ICD": "C73",
//     "ExchangeCode": 491
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Follicular Cell Thyroid Cancer",
//     "ICD": "C73",
//     "ExchangeCode": 491
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Follicular Non-Hodgkins Lymphoma",
//     "ICD": "C82.9",
//     "ExchangeCode": 1127
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Foot Cancer",
//     "ICD": "C76.5",
//     "ExchangeCode": 492
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Foot Neoplasm Malignant",
//     "ICD": "C76.5",
//     "ExchangeCode": 493
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Frontal Lobe Cancer",
//     "ICD": "C71.1",
//     "ExchangeCode": 496
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Gall Bladder Cancer",
//     "ICD": "C23",
//     "ExchangeCode": 498
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Gallbladder Cancer",
//     "ICD": "C23",
//     "ExchangeCode": 499
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Gallbladder Malignant Neoplasm",
//     "ICD": "C23",
//     "ExchangeCode": 805
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Genital Cancer Male",
//     "ICD": "C63",
//     "ExchangeCode": 860
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Gland Endocrine Cancer",
//     "ICD": "C75.9",
//     "ExchangeCode": 506
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Granulocytic Sarcoma",
//     "ICD": "C92.3",
//     "ExchangeCode": 305
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Gum Cancer",
//     "ICD": "C03",
//     "ExchangeCode": 810
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Gum Malignant Neoplasm",
//     "ICD": "C03",
//     "ExchangeCode": 810
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Hairy-Cell Leukaemia",
//     "ICD": "C91.4",
//     "ExchangeCode": 2106
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Hand Cancer",
//     "ICD": "C76.4",
//     "ExchangeCode": 522
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Hand Neoplasm Malignant",
//     "ICD": "C76.4",
//     "ExchangeCode": 523
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Head Cancer",
//     "ICD": "C76",
//     "ExchangeCode": 526
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Head Neoplasm Malignant",
//     "ICD": "C76",
//     "ExchangeCode": 527
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Heart Cancer",
//     "ICD": "C38",
//     "ExchangeCode": 815
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Hepatic Cancer",
//     "ICD": "C22.9",
//     "ExchangeCode": 549
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Hodgkins Disease",
//     "ICD": "C81",
//     "ExchangeCode": 564
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Hodgkins Lymphoma",
//     "ICD": "C81",
//     "ExchangeCode": 564
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Hypopharynx Cancer",
//     "ICD": "C13",
//     "ExchangeCode": 817
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Hypopharynx Malignant Neoplasm",
//     "ICD": "C13",
//     "ExchangeCode": 817
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Idiopathic Myelofibrosis",
//     "ICD": "D47.1",
//     "ExchangeCode": 600
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Infiltrating Ductal Adenocarcinoma",
//     "ICD": "C50.9",
//     "ExchangeCode": 612
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Intestinal Cancer",
//     "ICD": "C26",
//     "ExchangeCode": 624
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Intestine Cancer",
//     "ICD": "C26",
//     "ExchangeCode": 626
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Invasive Lobular Carcinoma",
//     "ICD": "C50.9",
//     "ExchangeCode": 632
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Jaw Cancer",
//     "ICD": "C41",
//     "ExchangeCode": 641
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Jaw Neoplasm Malignant",
//     "ICD": "C41",
//     "ExchangeCode": 642
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Joint Cancer",
//     "ICD": "C41.9",
//     "ExchangeCode": 643
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Joint Innerskin Cancer",
//     "ICD": "C49",
//     "ExchangeCode": 644
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Joint Neoplasm Malignant",
//     "ICD": "C41.9",
//     "ExchangeCode": 645
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Kahlers Disease",
//     "ICD": "C90.0",
//     "ExchangeCode": 1244
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Kaposi Sarcoma",
//     "ICD": "C46",
//     "ExchangeCode": 651
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Kaposis Sarcoma",
//     "ICD": "C46.9",
//     "ExchangeCode": 651
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Kidney Cancer",
//     "ICD": "C64",
//     "ExchangeCode": 653
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Kidney Neoplasm Malignant",
//     "ICD": "C64",
//     "ExchangeCode": 657
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Knee Cancer",
//     "ICD": "C41.9",
//     "ExchangeCode": 662
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Knee Neoplasm Malignant",
//     "ICD": "C41.9",
//     "ExchangeCode": 663
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Larynx Cancer",
//     "ICD": "C32",
//     "ExchangeCode": 666
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Larynx Malignant Neoplasm",
//     "ICD": "C32",
//     "ExchangeCode": 903
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Leg Cancer",
//     "ICD": "C76.5",
//     "ExchangeCode": 674
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Leg Neoplasm Malignant",
//     "ICD": "C76.5",
//     "ExchangeCode": 676
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lennerts Lymphoma",
//     "ICD": "C84.3",
//     "ExchangeCode": 1070
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Letterersiwe Disease",
//     "ICD": "C96.0",
//     "ExchangeCode": 680
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Leukaemia",
//     "ICD": "C95.9",
//     "ExchangeCode": 704
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Leukaemic Reticuloendotheliosis",
//     "ICD": "C91.4",
//     "ExchangeCode": 683
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Leukemic Reticuloendotheliosis",
//     "ICD": "C91.4",
//     "ExchangeCode": 683
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Ligament Cancer",
//     "ICD": "C49",
//     "ExchangeCode": 686
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lip Cancer",
//     "ICD": "C00",
//     "ExchangeCode": 687
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Liver And Intrahepatic Bile Ducts Malignant Neoplasm",
//     "ICD": "C22",
//     "ExchangeCode": 2070
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Liver Cancer",
//     "ICD": "C22",
//     "ExchangeCode": 692
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Loin Cancer",
//     "ICD": "C76",
//     "ExchangeCode": 696
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lung Cancer",
//     "ICD": "C34",
//     "ExchangeCode": 698
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lymph Nodes Cancer",
//     "ICD": "C77.9",
//     "ExchangeCode": 704
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lymph Nodes Neoplasm Malignant",
//     "ICD": "C77.9",
//     "ExchangeCode": 705
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lymphocytic Depletion",
//     "ICD": "C81.3",
//     "ExchangeCode": 2073
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lymphocytic Predominance",
//     "ICD": "C81.0",
//     "ExchangeCode": 2088
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lymphocytic-Histiocytic Predominance",
//     "ICD": "C81.0",
//     "ExchangeCode": 564
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lymphoepithelioid Lymphoma",
//     "ICD": "C84.3",
//     "ExchangeCode": 1070
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lymphoid Leukaemia",
//     "ICD": "C91",
//     "ExchangeCode": 2082
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lymphoma",
//     "ICD": "C85.9",
//     "ExchangeCode": 2097
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lymphosarcoma",
//     "ICD": "C85.0",
//     "ExchangeCode": 2079
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Lymphosarcoma Cell Leukaemia",
//     "ICD": "C94.7",
//     "ExchangeCode": 704
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Macroglobulinemia",
//     "ICD": "C88.0",
//     "ExchangeCode": 710
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Maligant Neoplasm Of Oropharynx",
//     "ICD": "C10.9",
//     "ExchangeCode": 875
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Maligant Neoplasm Of Spleen",
//     "ICD": "C26.1",
//     "ExchangeCode": 965
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Astrocytoma",
//     "ICD": "C71.9",
//     "ExchangeCode": 137
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Brain Tumour",
//     "ICD": "C71.9",
//     "ExchangeCode": 203
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Breast Neoplasm",
//     "ICD": "C50.9",
//     "ExchangeCode": 208
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Histiocytosis",
//     "ICD": "C96.8",
//     "ExchangeCode": 714
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Immunoproliferative Disease",
//     "ICD": "C88.9",
//     "ExchangeCode": 1627
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Lymphoma",
//     "ICD": "C85.9",
//     "ExchangeCode": 704
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Mast Cell Tumors",
//     "ICD": "C96.2",
//     "ExchangeCode": 716
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Mast Cell Tumour",
//     "ICD": "C96.2",
//     "ExchangeCode": 716
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Mastocytoma",
//     "ICD": "C96.2",
//     "ExchangeCode": 716
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Mastocytosis",
//     "ICD": "C96.2",
//     "ExchangeCode": 716
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma",
//     "ICD": "C43.9",
//     "ExchangeCode": 717
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Neck",
//     "ICD": "C43.4",
//     "ExchangeCode": 725
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Of Arm Or Shoulder",
//     "ICD": "C43",
//     "ExchangeCode": 727
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Of Eyelid",
//     "ICD": "C43.1",
//     "ExchangeCode": 1445
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Of Leg",
//     "ICD": "C43.7",
//     "ExchangeCode": 723
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Of Lip",
//     "ICD": "C43",
//     "ExchangeCode": 722
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Of Lower Limb",
//     "ICD": "C43",
//     "ExchangeCode": 723
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Of Nose",
//     "ICD": "C43.3",
//     "ExchangeCode": 1445
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Of Skin",
//     "ICD": "C43",
//     "ExchangeCode": 719
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Of Upper Limb Including Shoulder",
//     "ICD": "C43",
//     "ExchangeCode": 727
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma On Ear",
//     "ICD": "C43",
//     "ExchangeCode": 720
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma On Eyelid",
//     "ICD": "C43",
//     "ExchangeCode": 1445
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma On Face",
//     "ICD": "C43",
//     "ExchangeCode": 724
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma On Scalp And Face",
//     "ICD": "C43",
//     "ExchangeCode": 1445
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin",
//     "ICD": "C43.9",
//     "ExchangeCode": 719
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Ear External Auditory Canal",
//     "ICD": "C43.2",
//     "ExchangeCode": 720
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Eyelid Including Canthus",
//     "ICD": "C43.1",
//     "ExchangeCode": 721
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Leg Or Hip",
//     "ICD": "C43.7",
//     "ExchangeCode": 723
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Lip",
//     "ICD": "C43.0",
//     "ExchangeCode": 722
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Lower Limb Including Hip",
//     "ICD": "C43.7",
//     "ExchangeCode": 723
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Of Face",
//     "ICD": "C43.3",
//     "ExchangeCode": 724
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Other Unspecified Parts Face",
//     "ICD": "C43.3",
//     "ExchangeCode": 724
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Scalp Neck",
//     "ICD": "C43.4",
//     "ExchangeCode": 725
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Scalp Or Neck",
//     "ICD": "C43.4",
//     "ExchangeCode": 725
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Trunk Except Scrotum",
//     "ICD": "C43.5",
//     "ExchangeCode": 726
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Melanoma Skin Upper Limb Including Shoulder",
//     "ICD": "C43.6",
//     "ExchangeCode": 727
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Mole",
//     "ICD": "C43",
//     "ExchangeCode": 728
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neeoplasm Of Sacrum",
//     "ICD": "C41.4",
//     "ExchangeCode": 927
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplams Of Pleura",
//     "ICD": "C38",
//     "ExchangeCode": 815
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Abdomen",
//     "ICD": "C76.2",
//     "ExchangeCode": 729
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Accessory Sinus Unspecified",
//     "ICD": "C31.9",
//     "ExchangeCode": 730
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Adrenal Gland",
//     "ICD": "C74",
//     "ExchangeCode": 731
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Ampulla Vater",
//     "ICD": "C24.1",
//     "ExchangeCode": 732
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Anal Canal",
//     "ICD": "C21.1",
//     "ExchangeCode": 733
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Anterior Aspect Epiglottis",
//     "ICD": "C10.1",
//     "ExchangeCode": 734
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Anterior Mediastinum",
//     "ICD": "C38.1",
//     "ExchangeCode": 735
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Anterior Portion Floor Mout",
//     "ICD": "C04.0",
//     "ExchangeCode": 736
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Anterior Wall Nasopharynx",
//     "ICD": "C11.3",
//     "ExchangeCode": 738
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Anterior Wall Urinary Bladder",
//     "ICD": "C67.3",
//     "ExchangeCode": 739
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Anus",
//     "ICD": "C21.0",
//     "ExchangeCode": 740
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Anus Unspecified",
//     "ICD": "C21.0",
//     "ExchangeCode": 740
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Appendix Vermiformis",
//     "ICD": "C18.1",
//     "ExchangeCode": 742
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Ascending Colon",
//     "ICD": "C18.2",
//     "ExchangeCode": 744
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Auditory Tube Middle Ear Mastoid Air Cells",
//     "ICD": "C30.1",
//     "ExchangeCode": 745
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Axillary Tail Female Breast",
//     "ICD": "C50.6",
//     "ExchangeCode": 746
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Back",
//     "ICD": "C76.7",
//     "ExchangeCode": 158
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Base Tongue",
//     "ICD": "C01",
//     "ExchangeCode": 747
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Biliary Tract Part Unspecified",
//     "ICD": "C24.9",
//     "ExchangeCode": 748
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Bladder",
//     "ICD": "C67.9",
//     "ExchangeCode": 750
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Bladder Neck",
//     "ICD": "C67.5",
//     "ExchangeCode": 749
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Bladder Part Unspecified",
//     "ICD": "C67.9",
//     "ExchangeCode": 750
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Body Pancreas",
//     "ICD": "C25.1",
//     "ExchangeCode": 751
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Body Penis",
//     "ICD": "C60.2",
//     "ExchangeCode": 752
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Body Stomach",
//     "ICD": "C16.2",
//     "ExchangeCode": 753
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Body Uterus",
//     "ICD": "C55",
//     "ExchangeCode": 754
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Bone Articular Cartilage",
//     "ICD": "C40",
//     "ExchangeCode": 755
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Bone Articular Cartilage Site Unspecified",
//     "ICD": "C41.9",
//     "ExchangeCode": 756
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Bones Skull Face Except Mandible",
//     "ICD": "C41",
//     "ExchangeCode": 757
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Brain Stem",
//     "ICD": "C71",
//     "ExchangeCode": 758
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Brain Unspecified",
//     "ICD": "C71.9",
//     "ExchangeCode": 759
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Breast",
//     "ICD": "C50",
//     "ExchangeCode": 207
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Breast Female Unspecified",
//     "ICD": "C50.9",
//     "ExchangeCode": 760
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Broad Ligament Uterus",
//     "ICD": "C57.1",
//     "ExchangeCode": 761
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Bronchus Lung Unspecified",
//     "ICD": "C34.0",
//     "ExchangeCode": 762
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Cardia",
//     "ICD": "C16.0",
//     "ExchangeCode": 763
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Carotid Body",
//     "ICD": "C75.4",
//     "ExchangeCode": 764
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Cecum",
//     "ICD": "C18.0",
//     "ExchangeCode": 765
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Central Portion Female Breast",
//     "ICD": "C50.1",
//     "ExchangeCode": 766
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Cerebellum Nos",
//     "ICD": "C71.6",
//     "ExchangeCode": 767
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Cerebral Meninges",
//     "ICD": "C70.0",
//     "ExchangeCode": 768
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Cerebrum Except Lobes Ventricles",
//     "ICD": "C71.0",
//     "ExchangeCode": 769
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Cervix Uteri",
//     "ICD": "C53",
//     "ExchangeCode": 770
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Cervix Uteri Unspecified",
//     "ICD": "C53.9",
//     "ExchangeCode": 771
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Cheek Mucosa",
//     "ICD": "C06.0",
//     "ExchangeCode": 772
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Clitoris",
//     "ICD": "C51.2",
//     "ExchangeCode": 773
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Colon",
//     "ICD": "C18.9",
//     "ExchangeCode": 774
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Colon Unspecified",
//     "ICD": "C18.9",
//     "ExchangeCode": 775
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Commissure Lip",
//     "ICD": "C00.6",
//     "ExchangeCode": 776
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Commissure Of Lip",
//     "ICD": "C00.6",
//     "ExchangeCode": 776
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Common Bile Duct",
//     "ICD": "C24",
//     "ExchangeCode": 748
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Connective Other Soft Tissue",
//     "ICD": "C49",
//     "ExchangeCode": 777
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Corpus Uteri Except Isthmus",
//     "ICD": "C54",
//     "ExchangeCode": 786
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Cranial Nerves",
//     "ICD": "C72.5",
//     "ExchangeCode": 787
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Descending Colon",
//     "ICD": "C18.6",
//     "ExchangeCode": 788
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Dome Urinary Bladder",
//     "ICD": "C67.1",
//     "ExchangeCode": 789
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Dorsal Surface Tongue",
//     "ICD": "C02.0",
//     "ExchangeCode": 790
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Duodenum",
//     "ICD": "C17",
//     "ExchangeCode": 791
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Endocervix",
//     "ICD": "C53.0",
//     "ExchangeCode": 792
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Endocrine Gland",
//     "ICD": "C75.9",
//     "ExchangeCode": 793
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Endocrine Gland Site Unspecified",
//     "ICD": "C75.9",
//     "ExchangeCode": 793
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Epididymis",
//     "ICD": "C63.0",
//     "ExchangeCode": 794
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Ethmoidal Sinus",
//     "ICD": "C31.1",
//     "ExchangeCode": 795
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Exocervix",
//     "ICD": "C53.1",
//     "ExchangeCode": 796
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Extrahepatic Bile Ducts",
//     "ICD": "C24.0",
//     "ExchangeCode": 797
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Eye",
//     "ICD": "C69.9",
//     "ExchangeCode": 470
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Face",
//     "ICD": "C76.0",
//     "ExchangeCode": 473
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Fallopian Tube",
//     "ICD": "C57",
//     "ExchangeCode": 798
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Female Breast",
//     "ICD": "C50.9",
//     "ExchangeCode": 799
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Floor Mouth",
//     "ICD": "C04.9",
//     "ExchangeCode": 800
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Floor Mouth Part Unspecified",
//     "ICD": "C04.9",
//     "ExchangeCode": 801
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Frontal Lobe",
//     "ICD": "C71.1",
//     "ExchangeCode": 802
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Frontal Sinus",
//     "ICD": "C31.2",
//     "ExchangeCode": 803
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Fundus Stomach",
//     "ICD": "C16.1",
//     "ExchangeCode": 804
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Gallbladder",
//     "ICD": "C23",
//     "ExchangeCode": 805
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Gallbladder Extrahepatic Bile Ducts",
//     "ICD": "C24.0",
//     "ExchangeCode": 806
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Glans Penis",
//     "ICD": "C60.1",
//     "ExchangeCode": 807
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Glottis",
//     "ICD": "C32.0",
//     "ExchangeCode": 808
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Greater Curvature Stomach Unspecified",
//     "ICD": "C16.6",
//     "ExchangeCode": 809
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Gum",
//     "ICD": "C03",
//     "ExchangeCode": 810
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Gum Unspecified",
//     "ICD": "C03.9",
//     "ExchangeCode": 811
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Hard Palate",
//     "ICD": "C05.0",
//     "ExchangeCode": 812
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Head Face Neck",
//     "ICD": "C76",
//     "ExchangeCode": 813
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Head Pancreas",
//     "ICD": "C25.0",
//     "ExchangeCode": 814
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Heart",
//     "ICD": "C38.0",
//     "ExchangeCode": 815
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Hepatic Flexure",
//     "ICD": "C18.3",
//     "ExchangeCode": 816
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Hypopharynx",
//     "ICD": "C13",
//     "ExchangeCode": 817
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Hypopharynx Unspecified",
//     "ICD": "C13.9",
//     "ExchangeCode": 818
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Ileum",
//     "ICD": "C17.2",
//     "ExchangeCode": 819
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Illdefined Sites Within Digestive Organs Peritoneum",
//     "ICD": "C48.2",
//     "ExchangeCode": 820
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Illdefined Sites Within Lip Oral Cavity",
//     "ICD": "C14",
//     "ExchangeCode": 821
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Illdefined Sites Within Respiratory System",
//     "ICD": "C39.9",
//     "ExchangeCode": 822
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Intestinal Tract Part Unspecifie",
//     "ICD": "C26.0",
//     "ExchangeCode": 823
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Intestine",
//     "ICD": "C26.0",
//     "ExchangeCode": 823
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Intestine Unspecified",
//     "ICD": "C17.9",
//     "ExchangeCode": 824
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Intrahepatic Bile Ducts",
//     "ICD": "C22.1",
//     "ExchangeCode": 825
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Islets Langerhans",
//     "ICD": "C25.4",
//     "ExchangeCode": 826
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Jaw",
//     "ICD": "C76.0",
//     "ExchangeCode": 474
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Jejunum",
//     "ICD": "C17.1",
//     "ExchangeCode": 827
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Junctional Region Oropharynx",
//     "ICD": "C10.8",
//     "ExchangeCode": 828
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Junctional Zone Tongue",
//     "ICD": "C03.0",
//     "ExchangeCode": 829
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Labia Majora",
//     "ICD": "C51.0",
//     "ExchangeCode": 832
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Labia Minora",
//     "ICD": "C51.1",
//     "ExchangeCode": 833
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Laryngeal Cartilages",
//     "ICD": "C32.3",
//     "ExchangeCode": 834
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Larynx",
//     "ICD": "C32.9",
//     "ExchangeCode": 835
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Larynx Unspecified",
//     "ICD": "C32.9",
//     "ExchangeCode": 836
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lateral Portion Floor Mouth",
//     "ICD": "C04.1",
//     "ExchangeCode": 837
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lateral Wall Nasopharynx",
//     "ICD": "C11.2",
//     "ExchangeCode": 838
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lateral Wall Oropharynx",
//     "ICD": "C10.2",
//     "ExchangeCode": 839
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lateral Wall Urinary Bladder",
//     "ICD": "C67.2",
//     "ExchangeCode": 840
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Leg",
//     "ICD": "C76.5",
//     "ExchangeCode": 676
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lesser Curvature Stomach Unspecified",
//     "ICD": "C16.5",
//     "ExchangeCode": 841
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lingual Tonsil",
//     "ICD": "C02.4",
//     "ExchangeCode": 842
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lip",
//     "ICD": "C00",
//     "ExchangeCode": 843
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lip Unspecified Inner Aspect",
//     "ICD": "C00.5",
//     "ExchangeCode": 844
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lip Unspecified Vermilion Borde",
//     "ICD": "C00.5",
//     "ExchangeCode": 845
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Liver Intrahepatic Bile Ducts",
//     "ICD": "C22.1",
//     "ExchangeCode": 846
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Liver Not Specified As Primary Secondary",
//     "ICD": "C22.9",
//     "ExchangeCode": 847
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Liver Primary",
//     "ICD": "C22.9",
//     "ExchangeCode": 848
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Liver Specified As Secondary",
//     "ICD": "C78.7",
//     "ExchangeCode": 849
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Long Bones Leg",
//     "ICD": "C40.2",
//     "ExchangeCode": 850
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Long Bones Lower Limb",
//     "ICD": "C40.2",
//     "ExchangeCode": 850
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lowe Limb",
//     "ICD": "C76.5",
//     "ExchangeCode": 852
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lower Gum",
//     "ICD": "C03.1",
//     "ExchangeCode": 851
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lower Limb",
//     "ICD": "C76.5",
//     "ExchangeCode": 852
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lower Lip Inner Aspect",
//     "ICD": "C00.4",
//     "ExchangeCode": 853
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lower Lip Vermilion Border",
//     "ICD": "C00.1",
//     "ExchangeCode": 854
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lower Lobe Bronchus Lung",
//     "ICD": "C34.3",
//     "ExchangeCode": 855
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lowerinner Quadrant Female Breast",
//     "ICD": "C50.3",
//     "ExchangeCode": 856
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lowerouter Quadrant Female Breast",
//     "ICD": "C50.5",
//     "ExchangeCode": 857
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lung",
//     "ICD": "C34.9",
//     "ExchangeCode": 229
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Lymph Node",
//     "ICD": "C77.9",
//     "ExchangeCode": 705
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Main Bronchus",
//     "ICD": "C34.0",
//     "ExchangeCode": 858
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Major Salivary Glands",
//     "ICD": "C08.9",
//     "ExchangeCode": 859
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Male Genital Organ Site Unspecified",
//     "ICD": "C63.9",
//     "ExchangeCode": 860
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Mandible",
//     "ICD": "C41.1",
//     "ExchangeCode": 861
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Maxillary Sinus",
//     "ICD": "C31",
//     "ExchangeCode": 862
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Meckels Diverticulum",
//     "ICD": "C17.3",
//     "ExchangeCode": 863
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Mediastinum Part Unspecified",
//     "ICD": "C38.3",
//     "ExchangeCode": 864
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Meninges",
//     "ICD": "C70.9",
//     "ExchangeCode": 1028
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Middle Lobe Bronchus Lung",
//     "ICD": "C34.0",
//     "ExchangeCode": 865
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Mouth",
//     "ICD": "C06.9",
//     "ExchangeCode": 866
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Mouth Unspecified",
//     "ICD": "C06.9",
//     "ExchangeCode": 866
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Nasal Cavities",
//     "ICD": "C30.0",
//     "ExchangeCode": 867
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Nasal Cavities Middle Ear Accessory Sinuses",
//     "ICD": "C30.1",
//     "ExchangeCode": 868
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Nasopharynx",
//     "ICD": "C11",
//     "ExchangeCode": 869
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Nasopharynx Unspecified",
//     "ICD": "C11.9",
//     "ExchangeCode": 870
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Nervous System Part Unspecified",
//     "ICD": "C72.9",
//     "ExchangeCode": 871
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Nipple Areola Female Breast",
//     "ICD": "C50.0",
//     "ExchangeCode": 872
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Occipital Lobe",
//     "ICD": "C71.4",
//     "ExchangeCode": 873
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Oesophagus",
//     "ICD": "C15.9",
//     "ExchangeCode": 1140
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Accessory Sinus",
//     "ICD": "C31.9",
//     "ExchangeCode": 730
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Accessory Sinuses",
//     "ICD": "C31",
//     "ExchangeCode": 876
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Adrenal Gland",
//     "ICD": "C74",
//     "ExchangeCode": 36
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Anal Canal",
//     "ICD": "C21.1",
//     "ExchangeCode": 733
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Anus And Anal Canal",
//     "ICD": "C21",
//     "ExchangeCode": 71
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Back",
//     "ICD": "C76.7",
//     "ExchangeCode": 158
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Base Of Tongue",
//     "ICD": "C01",
//     "ExchangeCode": 747
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Biliary Tract",
//     "ICD": "C24",
//     "ExchangeCode": 748
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Bladder",
//     "ICD": "C67",
//     "ExchangeCode": 178
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Bone",
//     "ICD": "C41.9",
//     "ExchangeCode": 756
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Bone And Articular Cartilage Of Limbs",
//     "ICD": "C40",
//     "ExchangeCode": 755
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Bones Of Skull And Face",
//     "ICD": "C41",
//     "ExchangeCode": 757
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Brain",
//     "ICD": "C71.9",
//     "ExchangeCode": 203
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Breast",
//     "ICD": "C50.9",
//     "ExchangeCode": 208
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Bronchus And Lung",
//     "ICD": "C34",
//     "ExchangeCode": 698
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Caecum",
//     "ICD": "C18.0",
//     "ExchangeCode": 330
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Cauda Equina",
//     "ICD": "C72.1",
//     "ExchangeCode": 1467
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Central Nervous System",
//     "ICD": "C72.9",
//     "ExchangeCode": 319
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Cervical Part Of Oesophagus",
//     "ICD": "C15.0",
//     "ExchangeCode": 1140
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Cervix Uteri",
//     "ICD": "C53",
//     "ExchangeCode": 770
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Choroid",
//     "ICD": "C69.3",
//     "ExchangeCode": 295
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Coccyx",
//     "ICD": "C41.4",
//     "ExchangeCode": 927
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Colon",
//     "ICD": "C18.9",
//     "ExchangeCode": 775
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Connective And Soft Tissue",
//     "ICD": "C49.9",
//     "ExchangeCode": 782
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Connective Soft Tissue",
//     "ICD": "C49",
//     "ExchangeCode": 777
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Endocrine Gland",
//     "ICD": "C75.9",
//     "ExchangeCode": 793
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Endocrine Pancreas",
//     "ICD": "C25.4",
//     "ExchangeCode": 826
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Endometrium",
//     "ICD": "C54",
//     "ExchangeCode": 771
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Eye",
//     "ICD": "C69.9",
//     "ExchangeCode": 470
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Floor Of Mouth",
//     "ICD": "C04",
//     "ExchangeCode": 2066
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Gallbladder",
//     "ICD": "C23",
//     "ExchangeCode": 805
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Gum",
//     "ICD": "C03",
//     "ExchangeCode": 810
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Heart",
//     "ICD": "C38",
//     "ExchangeCode": 815
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Heart, Mediastinum And Pleura",
//     "ICD": "C38",
//     "ExchangeCode": 2076
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Hypopharynx",
//     "ICD": "C13",
//     "ExchangeCode": 817
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Intestine",
//     "ICD": "C26.0",
//     "ExchangeCode": 823
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Jaw",
//     "ICD": "C76.0",
//     "ExchangeCode": 474
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Kidney",
//     "ICD": "C64",
//     "ExchangeCode": 657
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Larynx",
//     "ICD": "C32.9",
//     "ExchangeCode": 835
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Leg",
//     "ICD": "C76.5",
//     "ExchangeCode": 676
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Lip",
//     "ICD": "C00.9",
//     "ExchangeCode": 776
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Liver And Intrahepatic Bile Ducts",
//     "ICD": "C22",
//     "ExchangeCode": 692
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Lower Limb",
//     "ICD": "C76.5",
//     "ExchangeCode": 852
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Lower Third Of Oesophagus",
//     "ICD": "C15.5",
//     "ExchangeCode": 1140
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Lung",
//     "ICD": "C34.9",
//     "ExchangeCode": 229
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Lymph Nodes",
//     "ICD": "C77.9",
//     "ExchangeCode": 705
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Male Genital Organ",
//     "ICD": "C63.9",
//     "ExchangeCode": 860
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Mediastinum",
//     "ICD": "C38",
//     "ExchangeCode": 815
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Meninges",
//     "ICD": "C70",
//     "ExchangeCode": 1028
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Mouth",
//     "ICD": "C06",
//     "ExchangeCode": 866
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Nasal Cavity",
//     "ICD": "C30.0",
//     "ExchangeCode": 867
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Nasal Cavity And Middle Ear",
//     "ICD": "C30",
//     "ExchangeCode": 2072
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Nasopharynx",
//     "ICD": "C11",
//     "ExchangeCode": 1095
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Neck",
//     "ICD": "C76.0",
//     "ExchangeCode": 1098
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Oesophagus",
//     "ICD": "C15.9",
//     "ExchangeCode": 1140
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Olfactory Nerve",
//     "ICD": "C72.2",
//     "ExchangeCode": 787
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Optic Nerve",
//     "ICD": "C72.3",
//     "ExchangeCode": 787
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Other And Ill-Defined Digestive Organs",
//     "ICD": "C26",
//     "ExchangeCode": 626
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Other And Ill-Defined Sites In The Respiratory System And Intrathoracic Organs",
//     "ICD": "C39",
//     "ExchangeCode": 881
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Other And Unspecified Parts Of Mouth",
//     "ICD": "C06",
//     "ExchangeCode": 866
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Other Connective And Soft Tissue",
//     "ICD": "C49",
//     "ExchangeCode": 782
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Other Endocrine Glands And Related Structures",
//     "ICD": "C75",
//     "ExchangeCode": 2071
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Ovary",
//     "ICD": "C56",
//     "ExchangeCode": 1166
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Palate",
//     "ICD": "C05.9",
//     "ExchangeCode": 918
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Pancreas",
//     "ICD": "C25",
//     "ExchangeCode": 919
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Pancreas Head",
//     "ICD": "C25.0",
//     "ExchangeCode": 814
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Parotid Gland",
//     "ICD": "C07",
//     "ExchangeCode": 926
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Pelvic Bones",
//     "ICD": "C41.4",
//     "ExchangeCode": 927
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Piriform Sinus",
//     "ICD": "C12",
//     "ExchangeCode": 942
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Placenta",
//     "ICD": "C58",
//     "ExchangeCode": 1243
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Prostate",
//     "ICD": "C61",
//     "ExchangeCode": 1287
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Rectosigmoid Junction",
//     "ICD": "C19",
//     "ExchangeCode": 943
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Rectum",
//     "ICD": "C20",
//     "ExchangeCode": 944
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Retroperitoneum And Peritoneum",
//     "ICD": "C48",
//     "ExchangeCode": 949
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Rib",
//     "ICD": "C41.3",
//     "ExchangeCode": 1375
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Salivary Gland",
//     "ICD": "C08.9",
//     "ExchangeCode": 1385
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Scrotum",
//     "ICD": "C63.2",
//     "ExchangeCode": 954
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Skin",
//     "ICD": "C44.9",
//     "ExchangeCode": 1157
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Skin Of Ear",
//     "ICD": "C44.2",
//     "ExchangeCode": 1153
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Skin Of Eyelid",
//     "ICD": "C44.1",
//     "ExchangeCode": 1154
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Small Intestine",
//     "ICD": "C17",
//     "ExchangeCode": 909
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Soft Connective Tissue",
//     "ICD": "C49.9",
//     "ExchangeCode": 782
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Sphenoidal Sinus",
//     "ICD": "C31.3",
//     "ExchangeCode": 962
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Spinal Column",
//     "ICD": "C41.2",
//     "ExchangeCode": 1007
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Spinal Cord, Cranial Nerves And Other Parts Of Central Nervous System",
//     "ICD": "C72",
//     "ExchangeCode": 2070
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Stomach",
//     "ICD": "C16.9",
//     "ExchangeCode": 841
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Testis",
//     "ICD": "C62",
//     "ExchangeCode": 1527
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of The Bladder",
//     "ICD": "C67.9",
//     "ExchangeCode": 750
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of The Colon",
//     "ICD": "C18.9",
//     "ExchangeCode": 775
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of The Larynx",
//     "ICD": "C32.9",
//     "ExchangeCode": 835
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of The Rectum",
//     "ICD": "C20",
//     "ExchangeCode": 944
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of The Scrotum",
//     "ICD": "C63.2",
//     "ExchangeCode": 954
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Thymus",
//     "ICD": "C37",
//     "ExchangeCode": 976
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Thyroid Gland",
//     "ICD": "C73",
//     "ExchangeCode": 1543
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Tonsil",
//     "ICD": "C09.9",
//     "ExchangeCode": 982
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Tonsils",
//     "ICD": "C09.9",
//     "ExchangeCode": 982
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Trachea",
//     "ICD": "C33",
//     "ExchangeCode": 984
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Ureter",
//     "ICD": "C66",
//     "ExchangeCode": 998
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Urinary Organ",
//     "ICD": "C68.9",
//     "ExchangeCode": 1001
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Uterus",
//     "ICD": "C55",
//     "ExchangeCode": 754
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Vagina",
//     "ICD": "C52",
//     "ExchangeCode": 1606
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Of Vulva",
//     "ICD": "C51",
//     "ExchangeCode": 1624
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Oropharynx",
//     "ICD": "C10",
//     "ExchangeCode": 874
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Oropharynx Unspecified",
//     "ICD": "C10.9",
//     "ExchangeCode": 875
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Accessory Sinuses",
//     "ICD": "C31",
//     "ExchangeCode": 876
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Illdefined Sites Within Digestive Organs Peritoneum",
//     "ICD": "C48",
//     "ExchangeCode": 879
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Illdefined Sites Within Respiratory System Intrathoracic Organs",
//     "ICD": "C39",
//     "ExchangeCode": 881
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Major Salivary Glands",
//     "ICD": "C08.9",
//     "ExchangeCode": 882
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Parts Brain",
//     "ICD": "C72.8",
//     "ExchangeCode": 883
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Parts Bronchus Lung",
//     "ICD": "C34.0",
//     "ExchangeCode": 884
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Sites Digestive System Intraabdominal Organs",
//     "ICD": "C26.8",
//     "ExchangeCode": 886
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Sites Floor Mouth",
//     "ICD": "C04.9",
//     "ExchangeCode": 887
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Sites Gum",
//     "ICD": "C03.9",
//     "ExchangeCode": 888
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Sites Lip",
//     "ICD": "C00.9",
//     "ExchangeCode": 889
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Sites Rectum Rectosigmoid Junction Anus",
//     "ICD": "C19",
//     "ExchangeCode": 890
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Sites Tongue",
//     "ICD": "C02.9",
//     "ExchangeCode": 891
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Sites Within Lip Oral Cavity",
//     "ICD": "C14",
//     "ExchangeCode": 892
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Sites Within Respirator System Intrathoracic Organs",
//     "ICD": "C39",
//     "ExchangeCode": 893
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Parts Mouth",
//     "ICD": "C06.9",
//     "ExchangeCode": 894
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Bladder",
//     "ICD": "C67.9",
//     "ExchangeCode": 896
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Body Uterus",
//     "ICD": "C55",
//     "ExchangeCode": 897
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Cervix",
//     "ICD": "C53",
//     "ExchangeCode": 898
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Female Breast",
//     "ICD": "C50.9",
//     "ExchangeCode": 899
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Gallbladder Extrahepatic Bile Ducts",
//     "ICD": "C24.0",
//     "ExchangeCode": 900
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Hypopharynx",
//     "ICD": "C13",
//     "ExchangeCode": 901
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Large Intestine",
//     "ICD": "C18.9",
//     "ExchangeCode": 902
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Larynx",
//     "ICD": "C32",
//     "ExchangeCode": 903
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Male Genital Organs",
//     "ICD": "C63.7",
//     "ExchangeCode": 904
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Nasopharynx",
//     "ICD": "C11",
//     "ExchangeCode": 905
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Oropharynx",
//     "ICD": "C10",
//     "ExchangeCode": 907
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Pancreas",
//     "ICD": "C25",
//     "ExchangeCode": 908
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Small Intestine",
//     "ICD": "C17",
//     "ExchangeCode": 909
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Stomach",
//     "ICD": "C16",
//     "ExchangeCode": 910
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Specified Sites Urinary Organs",
//     "ICD": "C46-C68",
//     "ExchangeCode": 911
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Unspecified Female Genital Organs",
//     "ICD": "C57",
//     "ExchangeCode": 913
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Other Unspecified Parts Mouth",
//     "ICD": "C06.9",
//     "ExchangeCode": 914
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Ovary",
//     "ICD": "C56",
//     "ExchangeCode": 1166
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Palate Unspecified",
//     "ICD": "C05.9",
//     "ExchangeCode": 918
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pancreas",
//     "ICD": "C25",
//     "ExchangeCode": 919
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pancreas Part Unspecified",
//     "ICD": "C25.9",
//     "ExchangeCode": 920
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pancreatic Duct",
//     "ICD": "C25.3",
//     "ExchangeCode": 921
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Parametrium",
//     "ICD": "C57.3",
//     "ExchangeCode": 922
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Parathyroid Gland",
//     "ICD": "C75.0",
//     "ExchangeCode": 923
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Paraurethral Glands",
//     "ICD": "C68.1",
//     "ExchangeCode": 924
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Parietal Lobe",
//     "ICD": "C71.3",
//     "ExchangeCode": 925
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Parotid Gland",
//     "ICD": "C07",
//     "ExchangeCode": 926
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pelvic Bones Sacrum Coccyx",
//     "ICD": "C41.4",
//     "ExchangeCode": 927
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Penis",
//     "ICD": "C60",
//     "ExchangeCode": 928
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Penis Part Unspecified",
//     "ICD": "C60.9",
//     "ExchangeCode": 928
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Peritoneum Unspecified",
//     "ICD": "C48.2",
//     "ExchangeCode": 929
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pharynx",
//     "ICD": "C14.0",
//     "ExchangeCode": 930
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pharynx Unspecified",
//     "ICD": "C14.0",
//     "ExchangeCode": 930
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pineal Gland",
//     "ICD": "C75.3",
//     "ExchangeCode": 931
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pituitary Gland Craniopharyngeal Duct",
//     "ICD": "C75.1",
//     "ExchangeCode": 932
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Postcricoid Region Hypopharynx",
//     "ICD": "C13.0",
//     "ExchangeCode": 933
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Posterior Hypopharyngeal Wall",
//     "ICD": "C13.9",
//     "ExchangeCode": 934
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Posterior Mediastinum",
//     "ICD": "C38.2",
//     "ExchangeCode": 935
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Posterior Wall Nasopharynx",
//     "ICD": "C11.1",
//     "ExchangeCode": 936
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Posterior Wall Oropharynx",
//     "ICD": "C10.3",
//     "ExchangeCode": 937
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Posterior Wall Urinary Bladder",
//     "ICD": "C67.4",
//     "ExchangeCode": 938
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Prepuce",
//     "ICD": "C60.0",
//     "ExchangeCode": 939
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Prostate",
//     "ICD": "C61",
//     "ExchangeCode": 1287
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pyloric Antrum",
//     "ICD": "C16.3",
//     "ExchangeCode": 940
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pylorus",
//     "ICD": "C16.4",
//     "ExchangeCode": 941
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Pyriform Sinus",
//     "ICD": "C12",
//     "ExchangeCode": 942
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Rectosigmoid Junction",
//     "ICD": "C19",
//     "ExchangeCode": 943
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Rectum",
//     "ICD": "C20",
//     "ExchangeCode": 944
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Rectum Rectosigmoid Junction Anus",
//     "ICD": "C19",
//     "ExchangeCode": 945
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Renal Pelvis",
//     "ICD": "C65",
//     "ExchangeCode": 946
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Retromolar Area",
//     "ICD": "C06.2",
//     "ExchangeCode": 947
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Retroperitoneum",
//     "ICD": "C48.0",
//     "ExchangeCode": 948
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Retroperitoneum Peritoneum",
//     "ICD": "C48.0",
//     "ExchangeCode": 949
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Ribs Sternum Clavicle",
//     "ICD": "C41.3",
//     "ExchangeCode": 950
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Round Ligament Uterus",
//     "ICD": "C57.2",
//     "ExchangeCode": 951
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Salivary Gland",
//     "ICD": "C08",
//     "ExchangeCode": 1385
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Salivary Gland Unspecified",
//     "ICD": "C08.9",
//     "ExchangeCode": 952
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Scapula Long Bones Of Arm",
//     "ICD": "C40.0",
//     "ExchangeCode": 953
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Scapula Long Bones Upper Limb",
//     "ICD": "C40.0",
//     "ExchangeCode": 953
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Scrotum",
//     "ICD": "C63.2",
//     "ExchangeCode": 954
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Short Bones Lower Limb",
//     "ICD": "C40.3",
//     "ExchangeCode": 955
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Short Bones Upper Limb",
//     "ICD": "C40.1",
//     "ExchangeCode": 956
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Sigmoid Colon",
//     "ICD": "C18.7",
//     "ExchangeCode": 957
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Skin",
//     "ICD": "C44.9",
//     "ExchangeCode": 1157
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Small Intestine Including Duodenum",
//     "ICD": "C17.0",
//     "ExchangeCode": 958
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Soft Connective Tissue",
//     "ICD": "C49",
//     "ExchangeCode": 782
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Soft Palate",
//     "ICD": "C05.1",
//     "ExchangeCode": 959
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Specified Parts Peritoneum",
//     "ICD": "C48.1",
//     "ExchangeCode": 960
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Spermatic Cord",
//     "ICD": "C63.1",
//     "ExchangeCode": 961
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Sphenoidal Sinus",
//     "ICD": "C31.3",
//     "ExchangeCode": 962
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Spinal Cord",
//     "ICD": "C72.0",
//     "ExchangeCode": 963
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Spinal Meninges",
//     "ICD": "C70.1",
//     "ExchangeCode": 964
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Spleen Not Elsewhere Classified",
//     "ICD": "C26.1",
//     "ExchangeCode": 965
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Splenic Flexure",
//     "ICD": "C18.5",
//     "ExchangeCode": 966
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Stomach",
//     "ICD": "C16.9",
//     "ExchangeCode": 967
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Stomach Unspecified",
//     "ICD": "C16.9",
//     "ExchangeCode": 968
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Subglottis",
//     "ICD": "C32.2",
//     "ExchangeCode": 969
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Sublingual Gland",
//     "ICD": "C08.1",
//     "ExchangeCode": 970
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Submandibular Gland",
//     "ICD": "C08.0",
//     "ExchangeCode": 971
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Superior Wall Nasopharynx",
//     "ICD": "C11.0",
//     "ExchangeCode": 972
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Supraglottis",
//     "ICD": "C32.1",
//     "ExchangeCode": 973
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Tail Pancreas",
//     "ICD": "C25.2",
//     "ExchangeCode": 974
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Temporal Lobe",
//     "ICD": "C71.2",
//     "ExchangeCode": 975
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Testis",
//     "ICD": "C62.9",
//     "ExchangeCode": 1527
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Thymus",
//     "ICD": "C37",
//     "ExchangeCode": 976
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Tip Lateral Border Tongue",
//     "ICD": "C02.1",
//     "ExchangeCode": 978
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Tongue",
//     "ICD": "C02.9",
//     "ExchangeCode": 979
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Tongue Unspecified",
//     "ICD": "C02.9",
//     "ExchangeCode": 980
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Tonsil",
//     "ICD": "C09",
//     "ExchangeCode": 981
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Tonsillar Fossa",
//     "ICD": "C09.0",
//     "ExchangeCode": 982
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Tonsillar Pillars Anterior Posterior",
//     "ICD": "C09.1",
//     "ExchangeCode": 983
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Trachea",
//     "ICD": "C33",
//     "ExchangeCode": 984
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Trachea Bronchus Lung",
//     "ICD": "C34.9",
//     "ExchangeCode": 985
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Transverse Colon",
//     "ICD": "C18.4",
//     "ExchangeCode": 986
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Trigone Urinary Bladder",
//     "ICD": "C67.0",
//     "ExchangeCode": 987
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Undescended Testis",
//     "ICD": "C62.0",
//     "ExchangeCode": 988
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Upper Gum",
//     "ICD": "C03.0",
//     "ExchangeCode": 989
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Upper Limb",
//     "ICD": "C76.4",
//     "ExchangeCode": 990
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Upper Lip Inner Aspect",
//     "ICD": "C00.3",
//     "ExchangeCode": 991
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Upper Lip Vermilion Border",
//     "ICD": "C00.0",
//     "ExchangeCode": 992
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Upper Lobe Bronchus Lung",
//     "ICD": "C34.0",
//     "ExchangeCode": 993
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Upper Respiratory Tract Part Unspecified",
//     "ICD": "C39.0",
//     "ExchangeCode": 994
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Upperinner Quadrant Female Breast",
//     "ICD": "C50.2",
//     "ExchangeCode": 995
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Upperouter Quadrant Female Breast",
//     "ICD": "C50.4",
//     "ExchangeCode": 996
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Urachus",
//     "ICD": "C67.7",
//     "ExchangeCode": 997
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Ureter",
//     "ICD": "C66",
//     "ExchangeCode": 998
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Ureteric Orifice",
//     "ICD": "C67.6",
//     "ExchangeCode": 999
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Urethra",
//     "ICD": "C68",
//     "ExchangeCode": 1000
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Urinary Organ Site Unspecified",
//     "ICD": "C68.9",
//     "ExchangeCode": 1001
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Uterine Adnexa",
//     "ICD": "C57.4",
//     "ExchangeCode": 1002
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Uterine Adnexa Unspecified",
//     "ICD": "C57.4",
//     "ExchangeCode": 1002
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Uterus",
//     "ICD": "C55",
//     "ExchangeCode": 754
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Uvula",
//     "ICD": "C05.2",
//     "ExchangeCode": 1003
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Vallecula Epiglottica",
//     "ICD": "C10",
//     "ExchangeCode": 1004
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Ventral Surface Tongue",
//     "ICD": "C02.2",
//     "ExchangeCode": 1005
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Ventricles",
//     "ICD": "C71.5",
//     "ExchangeCode": 1006
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Vertebral Column",
//     "ICD": "C41",
//     "ExchangeCode": 1007
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Vertebral Column Excluding Sacrum Coccyx",
//     "ICD": "C41.2",
//     "ExchangeCode": 1007
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Vestibule Mouth",
//     "ICD": "C06.1",
//     "ExchangeCode": 1008
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Vulva Unspecified",
//     "ICD": "C51.9",
//     "ExchangeCode": 1009
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Waldeyers Ring",
//     "ICD": "C14.2",
//     "ExchangeCode": 1010
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Neoplasm Without Specification Site",
//     "ICD": "C80",
//     "ExchangeCode": 1011
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Plasma Cell Tumour",
//     "ICD": "C90.2",
//     "ExchangeCode": 1245
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumor Of Acoustic Nerve",
//     "ICD": "C72.4",
//     "ExchangeCode": 787
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumor Of Brain",
//     "ICD": "C71.9",
//     "ExchangeCode": 203
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumor Of Eye",
//     "ICD": "C69.9",
//     "ExchangeCode": 470
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumor Of Nasal Cavity",
//     "ICD": "C30.0",
//     "ExchangeCode": 867
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumor Of Pituitary Gland",
//     "ICD": "C75.1",
//     "ExchangeCode": 932
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumor Of Salivary Glands",
//     "ICD": "C08.9",
//     "ExchangeCode": 1385
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumor Of Temporal Lobe",
//     "ICD": "C71.2",
//     "ExchangeCode": 975
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumor Of Ureteric Orifice",
//     "ICD": "C67.6",
//     "ExchangeCode": 999
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Bladder",
//     "ICD": "C67.9",
//     "ExchangeCode": 750
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Ciliary Body",
//     "ICD": "C69.4",
//     "ExchangeCode": 470
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Colon",
//     "ICD": "C18.9",
//     "ExchangeCode": 774
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Common Bile Duct",
//     "ICD": "C24",
//     "ExchangeCode": 748
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Endocrine Gland",
//     "ICD": "C75.9",
//     "ExchangeCode": 793
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Hard Palate",
//     "ICD": "C05.0",
//     "ExchangeCode": 812
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Heart",
//     "ICD": "C38.0",
//     "ExchangeCode": 815
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Intestine",
//     "ICD": "C26.0",
//     "ExchangeCode": 823
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Larynx",
//     "ICD": "C32.9",
//     "ExchangeCode": 835
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Middle Ear",
//     "ICD": "C30.1",
//     "ExchangeCode": 745
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Neck",
//     "ICD": "C76.0",
//     "ExchangeCode": 1098
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Oesophagus",
//     "ICD": "C15.9",
//     "ExchangeCode": 1140
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Ovary",
//     "ICD": "C56",
//     "ExchangeCode": 1166
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Pancreas",
//     "ICD": "C25.9",
//     "ExchangeCode": 920
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Pelvic Bones",
//     "ICD": "C41.4",
//     "ExchangeCode": 927
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Pharynx",
//     "ICD": "C14.0",
//     "ExchangeCode": 930
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Rectum",
//     "ICD": "C20",
//     "ExchangeCode": 944
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour Of Soft Palate",
//     "ICD": "C05.1",
//     "ExchangeCode": 959
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Malignant Tumour On Lung",
//     "ICD": "C34.9",
//     "ExchangeCode": 229
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mammary Cancer",
//     "ICD": "C50.9",
//     "ExchangeCode": 1012
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mammary Neoplasm Malignant",
//     "ICD": "C50.9",
//     "ExchangeCode": 1013
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mandible Cancer",
//     "ICD": "C41.1",
//     "ExchangeCode": 1014
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mast Cell Leukaemia",
//     "ICD": "C94.3",
//     "ExchangeCode": 2098
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mast Cell Sarcoma",
//     "ICD": "C96.2",
//     "ExchangeCode": 716
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Meckels Diverticulum",
//     "ICD": "C17.3",
//     "ExchangeCode": 863
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mediastinal Cancer",
//     "ICD": "C38.3",
//     "ExchangeCode": 1018
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mediastinum Cancer",
//     "ICD": "C38.3",
//     "ExchangeCode": 1020
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Melanoma Skin",
//     "ICD": "C43",
//     "ExchangeCode": 1024
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Melanoma Skin Site Unspecified",
//     "ICD": "C43.9",
//     "ExchangeCode": 1025
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Meninges Cancer",
//     "ICD": "C70.9",
//     "ExchangeCode": 1027
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Meninges Neoplasm Malignant",
//     "ICD": "C70",
//     "ExchangeCode": 1028
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Meningioma",
//     "ICD": "C70",
//     "ExchangeCode": 1030
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mesothelioma",
//     "ICD": "C45.9",
//     "ExchangeCode": 1037
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Monocytic Leukaemia",
//     "ICD": "C93",
//     "ExchangeCode": 2093
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mouth Cancer",
//     "ICD": "C06",
//     "ExchangeCode": 1058
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mouth Malignant Neoplasm",
//     "ICD": "C06",
//     "ExchangeCode": 866
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mucinous Carcinoma",
//     "ICD": "C56.9",
//     "ExchangeCode": 1060
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Multiple Myeloma",
//     "ICD": "C90",
//     "ExchangeCode": 1076
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Muscle Cancer",
//     "ICD": "C49.9",
//     "ExchangeCode": 1063
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Muscle Neoplasm Malignant",
//     "ICD": "C49.9",
//     "ExchangeCode": 1064
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Mycosis Fungoides",
//     "ICD": "C84",
//     "ExchangeCode": 1070
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Myelodysplasia",
//     "ICD": "D46",
//     "ExchangeCode": 1072
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Myelodysplasic Syndrome",
//     "ICD": "D46",
//     "ExchangeCode": 1072
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Myelofibrosis",
//     "ICD": "C94",
//     "ExchangeCode": 1073
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Myeloid Leukaemia",
//     "ICD": "C92",
//     "ExchangeCode": 2088
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Myeloid Sarcoma",
//     "ICD": "C92.3",
//     "ExchangeCode": 2174
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Myeloid Sarcomatosis",
//     "ICD": "C92",
//     "ExchangeCode": 1074
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Myeloma",
//     "ICD": "C90",
//     "ExchangeCode": 1075
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Myeloma Multiple",
//     "ICD": "C90.0",
//     "ExchangeCode": 1076
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Myelomatosis",
//     "ICD": "C90.0",
//     "ExchangeCode": 1244
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Myeloproliferative Disease",
//     "ICD": "D47.1",
//     "ExchangeCode": 1078
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nasal Cavity And Middle Ear Malignant Neoplasm",
//     "ICD": "C30",
//     "ExchangeCode": 2072
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nasalbone Cancer",
//     "ICD": "C41.0",
//     "ExchangeCode": 1092
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nasalbone Neoplasm Malignant",
//     "ICD": "C41.0",
//     "ExchangeCode": 1093
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nasopharyngeal Cancer",
//     "ICD": "C11",
//     "ExchangeCode": 1094
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nasopharynx Cancer",
//     "ICD": "C11",
//     "ExchangeCode": 1095
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nasopharynx Malignant Neoplasm",
//     "ICD": "C11",
//     "ExchangeCode": 1095
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Neck Cancer",
//     "ICD": "C76.0",
//     "ExchangeCode": 1097
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Neck Neoplasm Malignant",
//     "ICD": "C76.0",
//     "ExchangeCode": 1098
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Neoplasm Unknown",
//     "ICD": "D48.9",
//     "ExchangeCode": 1102
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Neoplasm Unspecified Nature Bone Soft Tissue Skin",
//     "ICD": "D48",
//     "ExchangeCode": 1104
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Neoplasm Unspecified Nature Digestive System",
//     "ICD": "D37",
//     "ExchangeCode": 1107
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Neoplasm Unspecified Nature Other Genitourinary Organs",
//     "ICD": "D39\/D40\/D41",
//     "ExchangeCode": 1109
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Neoplasm Unspecified Nature Respiratory System",
//     "ICD": "D38",
//     "ExchangeCode": 1111
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nerve Cancer",
//     "ICD": "C47",
//     "ExchangeCode": 1117
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nerve Neoplasm Malignant",
//     "ICD": "C72\/C47",
//     "ExchangeCode": 1118
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Neurionoma",
//     "ICD": "C47",
//     "ExchangeCode": 1117
//    },
//    {
//     "Group": "Cancer",
//     "Value": "NHL",
//     "ICD": "C85.9",
//     "ExchangeCode": 1122
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nodular Lymphoma",
//     "ICD": "C81.1",
//     "ExchangeCode": 1126
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nodular Sclerosis",
//     "ICD": "C81.1",
//     "ExchangeCode": 2072
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Non Hodgkins Lymphoma",
//     "ICD": "C85.9",
//     "ExchangeCode": 1127
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Non-Hodgkins Lymphoma",
//     "ICD": "C85.9",
//     "ExchangeCode": 1127
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nonhodgkins Lymphoma",
//     "ICD": "C85.9",
//     "ExchangeCode": 1130
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nose Cancer",
//     "ICD": "C30",
//     "ExchangeCode": 867
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Nose Tumour",
//     "ICD": "C76.0",
//     "ExchangeCode": 473
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Occipital Lobe Cancer",
//     "ICD": "C71.4",
//     "ExchangeCode": 1137
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Oesophageal Cancer",
//     "ICD": "C15",
//     "ExchangeCode": 1139
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Oesophagus Cancer",
//     "ICD": "C15",
//     "ExchangeCode": 1140
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Osteo Carcinoma",
//     "ICD": "C40",
//     "ExchangeCode": 755
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Osteoblastoma",
//     "ICD": "D16.9",
//     "ExchangeCode": 1146
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Osteogenic Sarcoma",
//     "ICD": "C41.9",
//     "ExchangeCode": 1147
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Osteoid Osteoma",
//     "ICD": "C41.9",
//     "ExchangeCode": 1148
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Other Hodgkins Disease",
//     "ICD": "C81.7",
//     "ExchangeCode": 2074
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Other Malignant Neoplasm Scalp Skin Neck",
//     "ICD": "C44.4",
//     "ExchangeCode": 1151
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Other Malignant Neoplasm Skin",
//     "ICD": "C44",
//     "ExchangeCode": 1152
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Other Malignant Neoplasms Lymphoid Histiocytic Tissue",
//     "ICD": "C96",
//     "ExchangeCode": 1162
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Other Malignant Neoplasms Of Skin",
//     "ICD": "C44",
//     "ExchangeCode": 1157
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Ovarian Cancer",
//     "ICD": "C56",
//     "ExchangeCode": 1164
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Ovary Cancer",
//     "ICD": "C56",
//     "ExchangeCode": 1165
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Ovary Malignant Neoplasm",
//     "ICD": "C56",
//     "ExchangeCode": 1166
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Ovary Neoplasm Malignant",
//     "ICD": "C56",
//     "ExchangeCode": 1166
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pancreas Cancer",
//     "ICD": "C25",
//     "ExchangeCode": 1172
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pancreas Malignant Neoplasm",
//     "ICD": "C25",
//     "ExchangeCode": 1172
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pancreatic Cancer",
//     "ICD": "C25.9",
//     "ExchangeCode": 1184
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Papillary Breast Cancer",
//     "ICD": "C50",
//     "ExchangeCode": 1189
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Papillary Cell Ca Thyroid",
//     "ICD": "C73",
//     "ExchangeCode": 1190
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Papillary Cell Thyroid Cancer",
//     "ICD": "C73",
//     "ExchangeCode": 1190
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Paranasal Sinus Neoplasm Unknown",
//     "ICD": "D38.5",
//     "ExchangeCode": 1194
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Parathyroid Gland Cancer",
//     "ICD": "C75.0",
//     "ExchangeCode": 1199
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Parietal Lobe Cancer",
//     "ICD": "C71.3",
//     "ExchangeCode": 1200
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Parotid Gland Cancer",
//     "ICD": "C07",
//     "ExchangeCode": 1203
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pelvic Cancer",
//     "ICD": "C41",
//     "ExchangeCode": 1210
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pelvis Cancer",
//     "ICD": "C76.3",
//     "ExchangeCode": 1211
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pelvis Neoplasm Malignant",
//     "ICD": "C41",
//     "ExchangeCode": 1212
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pelvis Tumour",
//     "ICD": "C41",
//     "ExchangeCode": 1210
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Penile Cancer",
//     "ICD": "C60",
//     "ExchangeCode": 1213
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Penis Cancer",
//     "ICD": "C60",
//     "ExchangeCode": 1214
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Penis Malignant Neoplasm",
//     "ICD": "C60",
//     "ExchangeCode": 928
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Perineum Cancer",
//     "ICD": "C49.5",
//     "ExchangeCode": 1221
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Perineum Neoplasm Malignant",
//     "ICD": "C49.5",
//     "ExchangeCode": 1222
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Peripheral T-Cell Lymphoma",
//     "ICD": "C84.4",
//     "ExchangeCode": 2078
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Peritoneal Cancer",
//     "ICD": "C48",
//     "ExchangeCode": 1225
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Peritoneum Cancer",
//     "ICD": "C48",
//     "ExchangeCode": 1227
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pharyngeal Cancer",
//     "ICD": "C10",
//     "ExchangeCode": 1233
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pharynx Cancer",
//     "ICD": "C14",
//     "ExchangeCode": 1234
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Placenta Cancer",
//     "ICD": "C58",
//     "ExchangeCode": 1241
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Placenta Carcinoma",
//     "ICD": "C58",
//     "ExchangeCode": 1242
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Placenta Malignant Neoplasm",
//     "ICD": "C58",
//     "ExchangeCode": 1243
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Placenta Neoplasm Malignant",
//     "ICD": "C58",
//     "ExchangeCode": 1243
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Plasma Cell Leukaemia",
//     "ICD": "C90.1",
//     "ExchangeCode": 1244
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Plasmacytoma",
//     "ICD": "C90.3",
//     "ExchangeCode": 1245
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pleura Carcinoma",
//     "ICD": "C38.4",
//     "ExchangeCode": 1248
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Polycythaemia Rubra Vera",
//     "ICD": "D45",
//     "ExchangeCode": 1260
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Polycythaemia Vera",
//     "ICD": "D45",
//     "ExchangeCode": 1261
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Polycythemia Rubra Vera",
//     "ICD": "D45",
//     "ExchangeCode": 1262
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Polyposis Coli",
//     "ICD": "D12",
//     "ExchangeCode": 2057
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Primary Idiopathic Myelofibrosis",
//     "ICD": "D47.1",
//     "ExchangeCode": 1277
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Prolymphocytic Leukaemia",
//     "ICD": "C91.3",
//     "ExchangeCode": 2086
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Prostate Cancer",
//     "ICD": "C61",
//     "ExchangeCode": 1285
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Prostate Neoplasm",
//     "ICD": "C61",
//     "ExchangeCode": 1286
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Prostate Neoplasm Malignant",
//     "ICD": "C61",
//     "ExchangeCode": 1287
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Prostate Neoplasm Unknown",
//     "ICD": "C61",
//     "ExchangeCode": 1288
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Prostatic Ca",
//     "ICD": "C61",
//     "ExchangeCode": 1290
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Prostatic Cancer",
//     "ICD": "C61",
//     "ExchangeCode": 1291
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pyloric Cancer",
//     "ICD": "C16.3",
//     "ExchangeCode": 1339
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Pylorus Cancer",
//     "ICD": "C16.4",
//     "ExchangeCode": 1340
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Rectal Cancer",
//     "ICD": "C20",
//     "ExchangeCode": 1352
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Rectosigmoid Junction Malignant Neoplasm",
//     "ICD": "C19",
//     "ExchangeCode": 945
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Refractory Anaemia",
//     "ICD": "D46",
//     "ExchangeCode": 1354
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Refractory Anaemia With Excess Blasts",
//     "ICD": "D46",
//     "ExchangeCode": 1355
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Refractory Anaemia With Ringed Sideroblasts",
//     "ICD": "D46",
//     "ExchangeCode": 1356
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Renal Cancer",
//     "ICD": "C64",
//     "ExchangeCode": 1358
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Renal Pelvis Cancer",
//     "ICD": "C65",
//     "ExchangeCode": 1362
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Respiratory Tract Cancer",
//     "ICD": "C39.9",
//     "ExchangeCode": 1367
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Respiratory Tract Neoplasm Malignant",
//     "ICD": "C39.9",
//     "ExchangeCode": 1368
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Reticulum Cell Sarcoma",
//     "ICD": "C83.3",
//     "ExchangeCode": 704
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Retroperitoneal Cancer",
//     "ICD": "C48.0",
//     "ExchangeCode": 1370
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Retroperitoneum Cancer",
//     "ICD": "C48",
//     "ExchangeCode": 1370
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Rib Cancer",
//     "ICD": "C41.3",
//     "ExchangeCode": 1375
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Rib Neoplasm Malignant",
//     "ICD": "C41.3",
//     "ExchangeCode": 1376
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sacrum Cancer",
//     "ICD": "C41.4",
//     "ExchangeCode": 1383
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sacrum Neoplasm Malignant",
//     "ICD": "C41.4",
//     "ExchangeCode": 1384
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Salivary Gland Cancer",
//     "ICD": "C08",
//     "ExchangeCode": 1385
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sarcomatosis",
//     "ICD": "C49.9",
//     "ExchangeCode": 1388
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Scaphoid Bone Cancer",
//     "ICD": "C40.9",
//     "ExchangeCode": 1389
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Scaphoid Bone Neoplasm Malignant",
//     "ICD": "C40.9",
//     "ExchangeCode": 1389
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Scaphoidbone Neoplasm Malignant",
//     "ICD": "C41.9",
//     "ExchangeCode": 1390
//    },
//    {
//     "Group": "Cancer",
//     "Value": "SCC Larynx",
//     "ICD": "C44",
//     "ExchangeCode": 1391
//    },
//    {
//     "Group": "Cancer",
//     "Value": "SCC Lung",
//     "ICD": "C44",
//     "ExchangeCode": 1392
//    },
//    {
//     "Group": "Cancer",
//     "Value": "SCC Oral",
//     "ICD": "C44",
//     "ExchangeCode": 1393
//    },
//    {
//     "Group": "Cancer",
//     "Value": "SCC Other",
//     "ICD": "C44",
//     "ExchangeCode": 1394
//    },
//    {
//     "Group": "Cancer",
//     "Value": "SCC Skin",
//     "ICD": "C44",
//     "ExchangeCode": 1395
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Scrotal Ca",
//     "ICD": "C63.2",
//     "ExchangeCode": 1401
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Scrotum Cancer",
//     "ICD": "C63.2",
//     "ExchangeCode": 1402
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Bone Cancer",
//     "ICD": "C79.5",
//     "ExchangeCode": 191
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Cancer",
//     "ICD": "C80",
//     "ExchangeCode": 241
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Cancer Of Bone",
//     "ICD": "C79.5",
//     "ExchangeCode": 191
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Cancer Of Intrathoracic Lymph Nodes",
//     "ICD": "C77.1",
//     "ExchangeCode": 1421
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Cancer Of Liver",
//     "ICD": "C78.7",
//     "ExchangeCode": 849
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Cancer Of Lung",
//     "ICD": "C78.0",
//     "ExchangeCode": 1407
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Cancer Of Lymph Nodes Of Neck",
//     "ICD": "C77.0",
//     "ExchangeCode": 1424
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Liver Cancer",
//     "ICD": "C78.7",
//     "ExchangeCode": 849
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Lymph Node Cancer",
//     "ICD": "C77",
//     "ExchangeCode": 1422
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Adrenal Gland",
//     "ICD": "C79.7",
//     "ExchangeCode": 1403
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Brain Spinal Cord",
//     "ICD": "C79.4",
//     "ExchangeCode": 1404
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Intestine Rectum",
//     "ICD": "C78.5",
//     "ExchangeCode": 1405
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Kidney",
//     "ICD": "C79.0",
//     "ExchangeCode": 1406
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Lung",
//     "ICD": "C78",
//     "ExchangeCode": 1407
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Mediastinum",
//     "ICD": "C78.1",
//     "ExchangeCode": 1408
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Of Bladder",
//     "ICD": "C79.1",
//     "ExchangeCode": 1413
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Of Lymph Nodes",
//     "ICD": "C77",
//     "ExchangeCode": 1422
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Other Digestive Organs Spleen",
//     "ICD": "C78.8",
//     "ExchangeCode": 1409
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Other Parts Nervous System",
//     "ICD": "C79.4",
//     "ExchangeCode": 1410
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Other Respiratory Organs",
//     "ICD": "C78.3",
//     "ExchangeCode": 1411
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Other Specified Sites",
//     "ICD": "C77-C79",
//     "ExchangeCode": 1412
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Other Urinary Organs",
//     "ICD": "C79.1",
//     "ExchangeCode": 1413
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Ovary",
//     "ICD": "C79.6",
//     "ExchangeCode": 1414
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Respiratory Digestive Systems",
//     "ICD": "C78",
//     "ExchangeCode": 1415
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Retroperitoneum Peritoneum",
//     "ICD": "C78.6",
//     "ExchangeCode": 1416
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Skin",
//     "ICD": "C79.2",
//     "ExchangeCode": 1417
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Neoplasm Small Intestine Including Duodenum",
//     "ICD": "C78.4",
//     "ExchangeCode": 1418
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Malignant Tumor Of Mediastinum",
//     "ICD": "C78.1",
//     "ExchangeCode": 1408
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Skin Cancer",
//     "ICD": "C79.2",
//     "ExchangeCode": 1417
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Unspecified Malignant Neoplasm Intraabdominal Lymph Nodes",
//     "ICD": "C77.2",
//     "ExchangeCode": 1419
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Unspecified Malignant Neoplasm Intrapelvic Lymph Nodes",
//     "ICD": "C77.5",
//     "ExchangeCode": 1420
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Unspecified Malignant Neoplasm Intrathoracic Lymph Nodes",
//     "ICD": "C77.1",
//     "ExchangeCode": 1421
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Unspecified Malignant Neoplasm Lymph Nodes",
//     "ICD": "C77",
//     "ExchangeCode": 1422
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Unspecified Malignant Neoplasm Lymph Nodes Axilla Upper Limb",
//     "ICD": "C77.3",
//     "ExchangeCode": 1423
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Unspecified Malignant Neoplasm Lymph Nodes Head Face Neck",
//     "ICD": "C77.0",
//     "ExchangeCode": 1424
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Unspecified Malignant Neoplasm Lymph Nodes Inguinal Region Lower Limb",
//     "ICD": "C77.4",
//     "ExchangeCode": 1425
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Unspecified Malignant Neoplasm Lymph Nodes Multiple Sites",
//     "ICD": "C77.8",
//     "ExchangeCode": 1426
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Secondary Unspecified Malignant Neoplasm Lymph Nodes Site Not Specified",
//     "ICD": "C77.9",
//     "ExchangeCode": 1427
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Seminoma",
//     "ICD": "C62.9",
//     "ExchangeCode": 1432
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sezarys Disease",
//     "ICD": "C84.1",
//     "ExchangeCode": 1437
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Shoulder Bone Neoplasm",
//     "ICD": "C40",
//     "ExchangeCode": 755
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Shoulder Cancer",
//     "ICD": "C76.4",
//     "ExchangeCode": 1439
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Shoulder Neoplasm Malignant",
//     "ICD": "C76.4",
//     "ExchangeCode": 1440
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sinus Cancer",
//     "ICD": "C12",
//     "ExchangeCode": 942
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sinus Tumour",
//     "ICD": "C12",
//     "ExchangeCode": 942
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer",
//     "ICD": "C43",
//     "ExchangeCode": 1024
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer Melanoma",
//     "ICD": "C43",
//     "ExchangeCode": 1445
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer Non Melanoma",
//     "ICD": "C44",
//     "ExchangeCode": 1446
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer Of Arm",
//     "ICD": "C43",
//     "ExchangeCode": 1024
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer Of Leg",
//     "ICD": "C43",
//     "ExchangeCode": 1024
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer Of Lower Limb",
//     "ICD": "C43",
//     "ExchangeCode": 1024
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer Of Shoulder",
//     "ICD": "C43",
//     "ExchangeCode": 727
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer Of Upper Limb Including Shoulder",
//     "ICD": "C43",
//     "ExchangeCode": 727
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer On Ear",
//     "ICD": "C43",
//     "ExchangeCode": 720
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer On Eyelid",
//     "ICD": "C43",
//     "ExchangeCode": 1024
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer On Face",
//     "ICD": "C43",
//     "ExchangeCode": 724
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer On Lip",
//     "ICD": "C43",
//     "ExchangeCode": 722
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer On Scalp And Neck",
//     "ICD": "C43",
//     "ExchangeCode": 725
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Cancer On Trunk",
//     "ICD": "C43",
//     "ExchangeCode": 726
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Squamous Cell Neoplasm",
//     "ICD": "M80.5",
//     "ExchangeCode": 1447
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skin Squamous Cell Tumour",
//     "ICD": "M80.5",
//     "ExchangeCode": 1448
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skull Cancer",
//     "ICD": "C41",
//     "ExchangeCode": 1451
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Skull Neoplasm Malignant",
//     "ICD": "C41.0",
//     "ExchangeCode": 1452
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Small Intestine Malignant Neoplasm",
//     "ICD": "C17",
//     "ExchangeCode": 909
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Solitary Myeloma",
//     "ICD": "C90.2",
//     "ExchangeCode": 1245
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Solitary Plasmacytoma",
//     "ICD": "C90.2",
//     "ExchangeCode": 1245
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sphenoid Bone Cancer",
//     "ICD": "C41",
//     "ExchangeCode": 1458
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sphenoid Bone Neoplasm Malignant",
//     "ICD": "C41",
//     "ExchangeCode": 1458
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sphenoidbone Neoplasm Malignant",
//     "ICD": "C31.3",
//     "ExchangeCode": 1459
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Spinal Column Cancer",
//     "ICD": "C41",
//     "ExchangeCode": 1007
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Spinal Cord Cancer",
//     "ICD": "C72.0",
//     "ExchangeCode": 1467
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Spinal Cord Neoplasm Unknown",
//     "ICD": "D43.4",
//     "ExchangeCode": 1470
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Spinalioma",
//     "ICD": "C44",
//     "ExchangeCode": 1473
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Spine Cancer",
//     "ICD": "C72.0",
//     "ExchangeCode": 1467
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Squamous Cell Carcinoma",
//     "ICD": "C44",
//     "ExchangeCode": 1481
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Squamous Cell Carcinoma Larynx",
//     "ICD": "C44",
//     "ExchangeCode": 1477
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Squamous Cell Carcinoma Lung",
//     "ICD": "C44",
//     "ExchangeCode": 1478
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Squamous Cell Carcinoma Oral",
//     "ICD": "C44",
//     "ExchangeCode": 1479
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Squamous Cell Carcinoma Other",
//     "ICD": "C44",
//     "ExchangeCode": 1480
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Squamous Cell Carcinoma Skin",
//     "ICD": "C44",
//     "ExchangeCode": 1481
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Squamous Cell Neoplasm",
//     "ICD": "C44",
//     "ExchangeCode": 1482
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Stem Cell Leukaemia",
//     "ICD": "C95.0",
//     "ExchangeCode": 704
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sternum Cancer",
//     "ICD": "C41.3",
//     "ExchangeCode": 1485
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Sternum Neoplasm Malignant",
//     "ICD": "C41.3",
//     "ExchangeCode": 1486
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Stomach Cancer",
//     "ICD": "C16",
//     "ExchangeCode": 1488
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Stomach Malignant Neoplasm",
//     "ICD": "C16",
//     "ExchangeCode": 1488
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Subacute Lymphocytic Leukaemia",
//     "ICD": "C91.2",
//     "ExchangeCode": 2085
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Subacute Monocytic Leukaemia",
//     "ICD": "C93.2",
//     "ExchangeCode": 2096
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Subacute Myeloid Leukaemia",
//     "ICD": "C92.2",
//     "ExchangeCode": 2090
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Synovial Bursa Cancer",
//     "ICD": "C49",
//     "ExchangeCode": 1502
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Synovial Bursa Neoplasm Malignant",
//     "ICD": "C49",
//     "ExchangeCode": 1503
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Synovialbursa Neoplasm Malignant",
//     "ICD": "C49",
//     "ExchangeCode": 1504
//    },
//    {
//     "Group": "Cancer",
//     "Value": "T-Zone Lymphoma",
//     "ICD": "C84.2",
//     "ExchangeCode": 2077
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Temporal Lobe Cancer",
//     "ICD": "C71.2",
//     "ExchangeCode": 1517
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tendon Cancer",
//     "ICD": "C49",
//     "ExchangeCode": 1519
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tendon Neoplasm Malignant",
//     "ICD": "C49",
//     "ExchangeCode": 1520
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Teratoma",
//     "ICD": "D48.9",
//     "ExchangeCode": 1522
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Testicle Cancer",
//     "ICD": "C62",
//     "ExchangeCode": 1523
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Testicular Cancer",
//     "ICD": "C62",
//     "ExchangeCode": 1525
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Testis Cancer",
//     "ICD": "C62",
//     "ExchangeCode": 1526
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Testis Malignant Neoplasm",
//     "ICD": "C62",
//     "ExchangeCode": 1527
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Testis Neoplasm Malignant",
//     "ICD": "C62",
//     "ExchangeCode": 1527
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Thorax Cancer",
//     "ICD": "C76.1",
//     "ExchangeCode": 1534
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Thorax Neoplasm Malignant",
//     "ICD": "C76.1",
//     "ExchangeCode": 1535
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Throat Cancer",
//     "ICD": "C10",
//     "ExchangeCode": 1536
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Thymus Cancer",
//     "ICD": "C37",
//     "ExchangeCode": 976
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Thymus Neoplasm Unknown",
//     "ICD": "D38.4",
//     "ExchangeCode": 1540
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Thyroid Gland Cancer",
//     "ICD": "C73",
//     "ExchangeCode": 1541
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Thyroid Gland Tumour",
//     "ICD": "C73",
//     "ExchangeCode": 1541
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Thyroid Neoplasm Malignant",
//     "ICD": "C73",
//     "ExchangeCode": 1543
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Thyroid Neoplasm Unknown",
//     "ICD": "C73",
//     "ExchangeCode": 1544
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tongue Cancer",
//     "ICD": "C02",
//     "ExchangeCode": 1547
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tongue Malignant Neoplasm",
//     "ICD": "C02",
//     "ExchangeCode": 1547
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tongue Tumour",
//     "ICD": "C03",
//     "ExchangeCode": 1547
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tonsil Cancer",
//     "ICD": "C09",
//     "ExchangeCode": 982
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tonsil Malignant Neoplasm",
//     "ICD": "C09",
//     "ExchangeCode": 982
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tonsil Tumour",
//     "ICD": "C09",
//     "ExchangeCode": 982
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Trachea Malignant Neoplasm",
//     "ICD": "C33",
//     "ExchangeCode": 984
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tracheal Cancer",
//     "ICD": "C33",
//     "ExchangeCode": 984
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Transitional Cell Bladder Cancer",
//     "ICD": "C67.9",
//     "ExchangeCode": 1553
//    },
//    {
//     "Group": "Cancer",
//     "Value": "True Histiocytic Lymphoma",
//     "ICD": "C96.3",
//     "ExchangeCode": 2102
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Trunk Cancer",
//     "ICD": "C76",
//     "ExchangeCode": 1569
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Trunk Neoplasm Malignant",
//     "ICD": "C76",
//     "ExchangeCode": 1570
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tubular Carcinoma",
//     "ICD": "C50",
//     "ExchangeCode": 1586
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Tumour Malignant Unspecified",
//     "ICD": "C80.9",
//     "ExchangeCode": 1587
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Ureter Cancer",
//     "ICD": "C66",
//     "ExchangeCode": 1596
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Ureter Tumour",
//     "ICD": "C66",
//     "ExchangeCode": 1596
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Urethra Cancer",
//     "ICD": "C68",
//     "ExchangeCode": 1600
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Urinary Cancer",
//     "ICD": "C68",
//     "ExchangeCode": 1001
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Uterus Cancer",
//     "ICD": "C55",
//     "ExchangeCode": 1602
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Uterus Neoplasm Malignant",
//     "ICD": "C55",
//     "ExchangeCode": 1603
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Uterus Neoplasm Unknown",
//     "ICD": "D39.0",
//     "ExchangeCode": 1604
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Vagina Cancer",
//     "ICD": "C52",
//     "ExchangeCode": 1605
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Vagina Malignant Neoplasm",
//     "ICD": "C52",
//     "ExchangeCode": 1606
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Vagina Neoplasm Malignant",
//     "ICD": "C52",
//     "ExchangeCode": 1606
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Vagina Tumour",
//     "ICD": "C52",
//     "ExchangeCode": 1606
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Vaginal Cancer",
//     "ICD": "C52",
//     "ExchangeCode": 1607
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Vulva Cancer",
//     "ICD": "C51",
//     "ExchangeCode": 1623
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Vulva Malignant Neoplasm",
//     "ICD": "C51",
//     "ExchangeCode": 1624
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Vulva Neoplasm Malignant",
//     "ICD": "C51",
//     "ExchangeCode": 1624
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Vulva Tumour",
//     "ICD": "C51",
//     "ExchangeCode": 1624
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Waldenstroem Disease",
//     "ICD": "C88.0",
//     "ExchangeCode": 1626
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Waldenstroem Macroglobulinaemia",
//     "ICD": "C88.0",
//     "ExchangeCode": 1627
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Waldenstroems Macroglobulinaemia",
//     "ICD": "C88.0",
//     "ExchangeCode": 1627
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Waldenstroms Macroglobulinaemia",
//     "ICD": "C88.0",
//     "ExchangeCode": 1627
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Waldeyers Ring Cancer",
//     "ICD": "C14.2",
//     "ExchangeCode": 1628
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Womb Cancer",
//     "ICD": "C55",
//     "ExchangeCode": 243
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Wrist Cancer",
//     "ICD": "C76.4",
//     "ExchangeCode": 1633
//    },
//    {
//     "Group": "Cancer",
//     "Value": "Zollinger Ellison Syndrome",
//     "ICD": "C76.4",
//     "ExchangeCode": 1636
//    },
//    {
//     "Group": "Heart",
//     "Value": "Abdomen Aneurysm",
//     "ICD": "I71",
//     "ExchangeCode": 1
//    },
//    {
//     "Group": "Heart",
//     "Value": "Abdomenaorta Aneurysm Rupture",
//     "ICD": "I71",
//     "ExchangeCode": 3
//    },
//    {
//     "Group": "Heart",
//     "Value": "Abdomenaorta Embolism",
//     "ICD": "I74",
//     "ExchangeCode": 4
//    },
//    {
//     "Group": "Heart",
//     "Value": "Abdominal Aortic Aneurysm And Dissection",
//     "ICD": "I71",
//     "ExchangeCode": 121
//    },
//    {
//     "Group": "Heart",
//     "Value": "Acute And Subacute Infective Endocarditis",
//     "ICD": "I33.0",
//     "ExchangeCode": 2181
//    },
//    {
//     "Group": "Heart",
//     "Value": "Acute Myocardial Infarction",
//     "ICD": "I21",
//     "ExchangeCode": 1080
//    },
//    {
//     "Group": "Heart",
//     "Value": "Acute Pericarditis",
//     "ICD": "I30",
//     "ExchangeCode": 1215
//    },
//    {
//     "Group": "Heart",
//     "Value": "Adams Stokes Disease",
//     "ICD": "I45",
//     "ExchangeCode": 17
//    },
//    {
//     "Group": "Heart",
//     "Value": "Alcoholic Cardiomyopathy",
//     "ICD": "I42.6",
//     "ExchangeCode": 251
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aneurysm Abdominal",
//     "ICD": "I71",
//     "ExchangeCode": 72
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aneurysm Aortic Rupture",
//     "ICD": "I71",
//     "ExchangeCode": 74
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aneurysm Cardiac",
//     "ICD": "I25.3",
//     "ExchangeCode": 75
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aneurysm Of Heart",
//     "ICD": "I25.3",
//     "ExchangeCode": 75
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aneurysm Thoracic",
//     "ICD": "I71",
//     "ExchangeCode": 78
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aneurysm Thoracic Rupture",
//     "ICD": "I71",
//     "ExchangeCode": 79
//    },
//    {
//     "Group": "Heart",
//     "Value": "Angina Pectoris",
//     "ICD": "I20",
//     "ExchangeCode": 2180
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aorta Abdominal Aneurysm",
//     "ICD": "I71",
//     "ExchangeCode": 86
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aorta Aneurysm Rupture",
//     "ICD": "I71",
//     "ExchangeCode": 87
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aorta Coarctation",
//     "ICD": "Q25",
//     "ExchangeCode": 89
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aorta Dilatation",
//     "ICD": "I71",
//     "ExchangeCode": 90
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aorta Dissecting Aneurysm",
//     "ICD": "I71",
//     "ExchangeCode": 91
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aorta Embolism",
//     "ICD": "I74",
//     "ExchangeCode": 92
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aorta Thoracic Aneurysm",
//     "ICD": "I71",
//     "ExchangeCode": 93
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Heart Valve Leak",
//     "ICD": "I35",
//     "ExchangeCode": 94
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Incompetence",
//     "ICD": "I35.1",
//     "ExchangeCode": 95
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Insufficiency",
//     "ICD": "I35",
//     "ExchangeCode": 96
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Regurgitation",
//     "ICD": "I35",
//     "ExchangeCode": 97
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Sclerosis",
//     "ICD": "I35",
//     "ExchangeCode": 98
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Stenosis",
//     "ICD": "I35",
//     "ExchangeCode": 99
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Valve Incompetence",
//     "ICD": "I35",
//     "ExchangeCode": 96
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Valve Insufficiency",
//     "ICD": "I06",
//     "ExchangeCode": 100
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Valve Regurgitation",
//     "ICD": "I35",
//     "ExchangeCode": 97
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Valve Stenosis",
//     "ICD": "I35",
//     "ExchangeCode": 101
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Valve Stenosis With Incompetence",
//     "ICD": "I35",
//     "ExchangeCode": 96
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Valve Stenosis With Insufficiency",
//     "ICD": "I35",
//     "ExchangeCode": 96
//    },
//    {
//     "Group": "Heart",
//     "Value": "Aortic Valve Stenosis With Regurgitation",
//     "ICD": "I35",
//     "ExchangeCode": 97
//    },
//    {
//     "Group": "Heart",
//     "Value": "Arrhythmia",
//     "ICD": "I49",
//     "ExchangeCode": 116
//    },
//    {
//     "Group": "Heart",
//     "Value": "Arteriosclerotic Heart Disease",
//     "ICD": "I70",
//     "ExchangeCode": 121
//    },
//    {
//     "Group": "Heart",
//     "Value": "Atherosclerosis Of Aorta",
//     "ICD": "I70",
//     "ExchangeCode": 121
//    },
//    {
//     "Group": "Heart",
//     "Value": "Atherosclerotic Heart Disease",
//     "ICD": "I25",
//     "ExchangeCode": 1670
//    },
//    {
//     "Group": "Heart",
//     "Value": "Atrial Flutter",
//     "ICD": "I48",
//     "ExchangeCode": 143
//    },
//    {
//     "Group": "Heart",
//     "Value": "Atrial Septal Defect",
//     "ICD": "Q21.1",
//     "ExchangeCode": 144
//    },
//    {
//     "Group": "Heart",
//     "Value": "Atrioventricular Block",
//     "ICD": "I44",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Atrioventricular Block And Left Bundle-Branch Block",
//     "ICD": "I44",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Atrioventricular Block First Degree",
//     "ICD": "I44",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Atrioventricular Block Second Degree",
//     "ICD": "I44",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Atrioventricular Block, Complete",
//     "ICD": "I44.2",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Atrioventricular Septal Defect",
//     "ICD": "Q21.2",
//     "ExchangeCode": 144
//    },
//    {
//     "Group": "Heart",
//     "Value": "Barlows Syndrome",
//     "ICD": "I34.1",
//     "ExchangeCode": 162
//    },
//    {
//     "Group": "Heart",
//     "Value": "Benign Heart Murmurs",
//     "ICD": "R01",
//     "ExchangeCode": 1330
//    },
//    {
//     "Group": "Heart",
//     "Value": "Bicuspid Aortic Valve",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Bicuspid Valve",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Biventricular Failure",
//     "ICD": "I50",
//     "ExchangeCode": 176
//    },
//    {
//     "Group": "Heart",
//     "Value": "Blocked Coronary Artery",
//     "ICD": "I24",
//     "ExchangeCode": 182
//    },
//    {
//     "Group": "Heart",
//     "Value": "Bradycardia",
//     "ICD": "R00",
//     "ExchangeCode": 2187
//    },
//    {
//     "Group": "Heart",
//     "Value": "Cardiac Arrest",
//     "ICD": "I46",
//     "ExchangeCode": 247
//    },
//    {
//     "Group": "Heart",
//     "Value": "Cardiac Arrythmia",
//     "ICD": "I49",
//     "ExchangeCode": 116
//    },
//    {
//     "Group": "Heart",
//     "Value": "Cardiac Dysrhythmia",
//     "ICD": "I49",
//     "ExchangeCode": 248
//    },
//    {
//     "Group": "Heart",
//     "Value": "Cardiac Enlargement",
//     "ICD": "I51",
//     "ExchangeCode": 453
//    },
//    {
//     "Group": "Heart",
//     "Value": "Cardiac Failure",
//     "ICD": "I50",
//     "ExchangeCode": 2185
//    },
//    {
//     "Group": "Heart",
//     "Value": "Cardiac Infarction",
//     "ICD": "I21",
//     "ExchangeCode": 249
//    },
//    {
//     "Group": "Heart",
//     "Value": "Cardiac Syndrome X",
//     "ICD": "I20",
//     "ExchangeCode": 537
//    },
//    {
//     "Group": "Heart",
//     "Value": "Cardiomegaly",
//     "ICD": "I51",
//     "ExchangeCode": 250
//    },
//    {
//     "Group": "Heart",
//     "Value": "Cardiovascular Disease",
//     "ICD": "I51",
//     "ExchangeCode": 252
//    },
//    {
//     "Group": "Heart",
//     "Value": "Carditis Rheumatic",
//     "ICD": "I09.9",
//     "ExchangeCode": 254
//    },
//    {
//     "Group": "Heart",
//     "Value": "CCF",
//     "ICD": "I50.9",
//     "ExchangeCode": 260
//    },
//    {
//     "Group": "Heart",
//     "Value": "Chronic Endocarditis",
//     "ICD": "I38",
//     "ExchangeCode": 447
//    },
//    {
//     "Group": "Heart",
//     "Value": "Chronic Ischaemic Heart Disease",
//     "ICD": "I25",
//     "ExchangeCode": 1671
//    },
//    {
//     "Group": "Heart",
//     "Value": "Chronic Myocarditis",
//     "ICD": "I51",
//     "ExchangeCode": 1081
//    },
//    {
//     "Group": "Heart",
//     "Value": "Chronic Rheumatic Pericarditis",
//     "ICD": "I09 .2",
//     "ExchangeCode": 1668
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coarctation Of Aorta",
//     "ICD": "Q25.1",
//     "ExchangeCode": 89
//    },
//    {
//     "Group": "Heart",
//     "Value": "Common Arterial Trunk",
//     "ICD": "Q20.0",
//     "ExchangeCode": 2165
//    },
//    {
//     "Group": "Heart",
//     "Value": "Complete Atrioventricular Block",
//     "ICD": "I44",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Complete Heart Block",
//     "ICD": "I44",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Complete Rbbb",
//     "ICD": "I45.1",
//     "ExchangeCode": 334
//    },
//    {
//     "Group": "Heart",
//     "Value": "Concentric Heart Hypertrophy",
//     "ICD": "I51.7",
//     "ExchangeCode": 336
//    },
//    {
//     "Group": "Heart",
//     "Value": "Conduction Disorder",
//     "ICD": "I45",
//     "ExchangeCode": 337
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Aortic Valve Incompetence",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Aortic Valve Regurgitation",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Heart Defect",
//     "ICD": "Q24",
//     "ExchangeCode": 284
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Insufficiency Of Aortic Valve",
//     "ICD": "Q23.1",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Malformations Of Aortic And Mitral Valves",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Malformations Of Cardiac Septa",
//     "ICD": "Q21",
//     "ExchangeCode": 144
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Malformations Of Pulmonary And Tricuspid Valves",
//     "ICD": "Q22",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Mitral Valve Incompetence",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Mitral Valve Insufficiency",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Mitral Valve Regurgitation",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Mitral Valve Stenosis",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Pulmonary Stenosis",
//     "ICD": "Q22.1",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Pulmonary Valve Incompetence",
//     "ICD": "Q22",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Pulmonary Valve Insufficiency",
//     "ICD": "Q22",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Pulmonary Valve Regurgitation",
//     "ICD": "Q22",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Pulmonary Valve Stenosis",
//     "ICD": "Q22",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Stenosis Of Aortic Valve",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Tricuspid Valve Incompetence",
//     "ICD": "Q22",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Tricuspid Valve Insufficency",
//     "ICD": "Q22",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Tricuspid Valve Regurgitation",
//     "ICD": "Q22",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congenital Tricuspid Valve Stenosis",
//     "ICD": "Q22",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congestive Cardiac Failure",
//     "ICD": "I50",
//     "ExchangeCode": 344
//    },
//    {
//     "Group": "Heart",
//     "Value": "Congestive Heart Failure",
//     "ICD": "I50",
//     "ExchangeCode": 345
//    },
//    {
//     "Group": "Heart",
//     "Value": "Cor Pulmonale",
//     "ICD": "I27.81",
//     "ExchangeCode": 351
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Arteriosclerosis",
//     "ICD": "I25",
//     "ExchangeCode": 352
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Artery Aneurysm",
//     "ICD": "I25",
//     "ExchangeCode": 1672
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Artery Disease",
//     "ICD": "I25",
//     "ExchangeCode": 355
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Atherosclerosis",
//     "ICD": "I25",
//     "ExchangeCode": 356
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Embolism Not Resulting In Heart Attack",
//     "ICD": "I24",
//     "ExchangeCode": 363
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Heart Disease",
//     "ICD": "I25",
//     "ExchangeCode": 358
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Insufficiency",
//     "ICD": "I24",
//     "ExchangeCode": 359
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Insufficiency Acute",
//     "ICD": "I24",
//     "ExchangeCode": 360
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Insufficiency Chronic",
//     "ICD": "I24",
//     "ExchangeCode": 361
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Sclerosis",
//     "ICD": "I25",
//     "ExchangeCode": 362
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Thrombosis Not Resulting In Heart Attack",
//     "ICD": "I24",
//     "ExchangeCode": 363
//    },
//    {
//     "Group": "Heart",
//     "Value": "Coronary Thrombosis Not Resulting In Myocardial Infarction",
//     "ICD": "I24",
//     "ExchangeCode": 363
//    },
//    {
//     "Group": "Heart",
//     "Value": "Dilated Cardiomyopathy",
//     "ICD": "I42",
//     "ExchangeCode": 251
//    },
//    {
//     "Group": "Heart",
//     "Value": "Dilated Heart",
//     "ICD": "I42.0",
//     "ExchangeCode": 410
//    },
//    {
//     "Group": "Heart",
//     "Value": "Dresslers Syndrome",
//     "ICD": "I24.1",
//     "ExchangeCode": 2131
//    },
//    {
//     "Group": "Heart",
//     "Value": "Drug Induced Cardiomyopathy",
//     "ICD": "I42",
//     "ExchangeCode": 251
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ductus Arteriosus Patent",
//     "ICD": "Q25.0",
//     "ExchangeCode": 425
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ductus Arteriosus Persistent",
//     "ICD": "Q25.0",
//     "ExchangeCode": 426
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ductus Botalli",
//     "ICD": "Q25.0",
//     "ExchangeCode": 427
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ductus Botalli Patent",
//     "ICD": "Q25.0",
//     "ExchangeCode": 428
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ebstein Anomaly",
//     "ICD": "Q22.5",
//     "ExchangeCode": 436
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ebsteins Anomaly",
//     "ICD": "Q22",
//     "ExchangeCode": 436
//    },
//    {
//     "Group": "Heart",
//     "Value": "Eisenmenger Syndrome",
//     "ICD": "Q21",
//     "ExchangeCode": 438
//    },
//    {
//     "Group": "Heart",
//     "Value": "Embolism And Thrombosis Of Abdominal Aorta",
//     "ICD": "I74.0",
//     "ExchangeCode": 2151
//    },
//    {
//     "Group": "Heart",
//     "Value": "Endocarditis",
//     "ICD": "I38",
//     "ExchangeCode": 447
//    },
//    {
//     "Group": "Heart",
//     "Value": "Endocarditis Acute",
//     "ICD": "I38",
//     "ExchangeCode": 448
//    },
//    {
//     "Group": "Heart",
//     "Value": "Endocarditis Rheumatic",
//     "ICD": "I09.1",
//     "ExchangeCode": 449
//    },
//    {
//     "Group": "Heart",
//     "Value": "Enlarged Heart",
//     "ICD": "I51",
//     "ExchangeCode": 453
//    },
//    {
//     "Group": "Heart",
//     "Value": "Fallot",
//     "ICD": "Q21",
//     "ExchangeCode": 478
//    },
//    {
//     "Group": "Heart",
//     "Value": "Fallots Tetralogy",
//     "ICD": "Q21",
//     "ExchangeCode": 479
//    },
//    {
//     "Group": "Heart",
//     "Value": "Floppy Mitral Valve",
//     "ICD": "I05",
//     "ExchangeCode": 1650
//    },
//    {
//     "Group": "Heart",
//     "Value": "Haemopericardium, Not Elsewhere Classified",
//     "ICD": "I31.2",
//     "ExchangeCode": 2135
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Amyloid",
//     "ICD": "I43.1",
//     "ExchangeCode": 528
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Arrest",
//     "ICD": "I46",
//     "ExchangeCode": 529
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Dilation",
//     "ICD": "I51",
//     "ExchangeCode": 532
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Disease",
//     "ICD": "I51",
//     "ExchangeCode": 533
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Enlargement",
//     "ICD": "I51.7",
//     "ExchangeCode": 534
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Infarct",
//     "ICD": "I21",
//     "ExchangeCode": 536
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Infarction",
//     "ICD": "I21",
//     "ExchangeCode": 537
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Insufficiency",
//     "ICD": "I51",
//     "ExchangeCode": 538
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Malformation",
//     "ICD": "Q24",
//     "ExchangeCode": 284
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Murmur",
//     "ICD": "R01",
//     "ExchangeCode": 1330
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Rhythm Disorder",
//     "ICD": "R00",
//     "ExchangeCode": 541
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Transplant",
//     "ICD": "Z94.1",
//     "ExchangeCode": 542
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Valve Defect",
//     "ICD": "I38",
//     "ExchangeCode": 448
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Valve Disease",
//     "ICD": "I51.9",
//     "ExchangeCode": 2186
//    },
//    {
//     "Group": "Heart",
//     "Value": "Heart Valvular Defect",
//     "ICD": "I38",
//     "ExchangeCode": 448
//    },
//    {
//     "Group": "Heart",
//     "Value": "HOCM",
//     "ICD": "I42.1",
//     "ExchangeCode": 563
//    },
//    {
//     "Group": "Heart",
//     "Value": "Hole In The Heart",
//     "ICD": "Q21",
//     "ExchangeCode": 1434
//    },
//    {
//     "Group": "Heart",
//     "Value": "Hypertensive Heart Disease",
//     "ICD": "I11",
//     "ExchangeCode": 583
//    },
//    {
//     "Group": "Heart",
//     "Value": "Hypertensive Heart Disease With Congestive Heart Failure",
//     "ICD": "I11",
//     "ExchangeCode": 583
//    },
//    {
//     "Group": "Heart",
//     "Value": "Hypertensive Heart Disease Without Congestive Heart Failure",
//     "ICD": "I11",
//     "ExchangeCode": 583
//    },
//    {
//     "Group": "Heart",
//     "Value": "Hypertrophic Obstructive Cardiomyopathy",
//     "ICD": "I42",
//     "ExchangeCode": 590
//    },
//    {
//     "Group": "Heart",
//     "Value": "Hypertrophic Obstructive Cardiomyopathy - HOCM",
//     "ICD": "I42",
//     "ExchangeCode": 2184
//    },
//    {
//     "Group": "Heart",
//     "Value": "IHD",
//     "ICD": "I25",
//     "ExchangeCode": 603
//    },
//    {
//     "Group": "Heart",
//     "Value": "Imminent Heart Infarct",
//     "ICD": "I20",
//     "ExchangeCode": 537
//    },
//    {
//     "Group": "Heart",
//     "Value": "Incomplete RBBB",
//     "ICD": "I45.10",
//     "ExchangeCode": 609
//    },
//    {
//     "Group": "Heart",
//     "Value": "Incomplete Right Bundle Branch Block",
//     "ICD": "I45",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Infective Myocarditis",
//     "ICD": "I40",
//     "ExchangeCode": 1082
//    },
//    {
//     "Group": "Heart",
//     "Value": "Infective Pericarditis",
//     "ICD": "I30",
//     "ExchangeCode": 1674
//    },
//    {
//     "Group": "Heart",
//     "Value": "Interstitial Myocarditis",
//     "ICD": "I51",
//     "ExchangeCode": 1081
//    },
//    {
//     "Group": "Heart",
//     "Value": "Irregular Heart Beat",
//     "ICD": "R00",
//     "ExchangeCode": 635
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ischaemic Cardiomyopathy",
//     "ICD": "I25",
//     "ExchangeCode": 636
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ischaemic Chest Pain",
//     "ICD": "I20",
//     "ExchangeCode": 537
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ischaemic Heart Disease",
//     "ICD": "I25",
//     "ExchangeCode": 637
//    },
//    {
//     "Group": "Heart",
//     "Value": "Left BBB",
//     "ICD": "I44.7",
//     "ExchangeCode": 671
//    },
//    {
//     "Group": "Heart",
//     "Value": "Left Bundle Branch Block",
//     "ICD": "I44",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Left Heart Failure",
//     "ICD": "I50",
//     "ExchangeCode": 672
//    },
//    {
//     "Group": "Heart",
//     "Value": "Left Ventricular Failure",
//     "ICD": "I50",
//     "ExchangeCode": 672
//    },
//    {
//     "Group": "Heart",
//     "Value": "Left Ventricular Hypertrophy",
//     "ICD": "I51",
//     "ExchangeCode": 673
//    },
//    {
//     "Group": "Heart",
//     "Value": "LVH",
//     "ICD": "I51.7",
//     "ExchangeCode": 703
//    },
//    {
//     "Group": "Heart",
//     "Value": "MI",
//     "ICD": "I21.3",
//     "ExchangeCode": 1038
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Heart Valve Leak",
//     "ICD": "I34",
//     "ExchangeCode": 1044
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Incompetence",
//     "ICD": "I34",
//     "ExchangeCode": 1045
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Insufficiency",
//     "ICD": "I34",
//     "ExchangeCode": 1046
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Prolapse",
//     "ICD": "I34",
//     "ExchangeCode": 1047
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Regurgitation",
//     "ICD": "I34",
//     "ExchangeCode": 1048
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Stenosis",
//     "ICD": "I05",
//     "ExchangeCode": 1650
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Valve Incompetence",
//     "ICD": "I34",
//     "ExchangeCode": 1045
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Valve Insufficiency",
//     "ICD": "I34",
//     "ExchangeCode": 1050
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Valve Murmur",
//     "ICD": "R01",
//     "ExchangeCode": 1051
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Valve Prolapse",
//     "ICD": "I34",
//     "ExchangeCode": 1050
//    },
//    {
//     "Group": "Heart",
//     "Value": "Mitral Valve Regurgitation",
//     "ICD": "I34",
//     "ExchangeCode": 1050
//    },
//    {
//     "Group": "Heart",
//     "Value": "Myocardial Infarct",
//     "ICD": "I21",
//     "ExchangeCode": 1079
//    },
//    {
//     "Group": "Heart",
//     "Value": "Myocarditis",
//     "ICD": "I51",
//     "ExchangeCode": 1081
//    },
//    {
//     "Group": "Heart",
//     "Value": "Myocarditis Acute",
//     "ICD": "I40",
//     "ExchangeCode": 1082
//    },
//    {
//     "Group": "Heart",
//     "Value": "Myocarditis In Other Diseases Classified Elsewhere",
//     "ICD": "I41.8",
//     "ExchangeCode": 2137
//    },
//    {
//     "Group": "Heart",
//     "Value": "Myocarditis Rheumatic",
//     "ICD": "I09.0",
//     "ExchangeCode": 1083
//    },
//    {
//     "Group": "Heart",
//     "Value": "Non Obstructive Hypertrophic Cardiomyopathy",
//     "ICD": "I42",
//     "ExchangeCode": 251
//    },
//    {
//     "Group": "Heart",
//     "Value": "Non Rheumatic Mitral Valve Stenosis",
//     "ICD": "I34",
//     "ExchangeCode": 1046
//    },
//    {
//     "Group": "Heart",
//     "Value": "Nonobstructive Cardiomyopathy",
//     "ICD": "I42",
//     "ExchangeCode": 1131
//    },
//    {
//     "Group": "Heart",
//     "Value": "Nonrheumatic Tricuspid Valve Disorders",
//     "ICD": "I36",
//     "ExchangeCode": 1676
//    },
//    {
//     "Group": "Heart",
//     "Value": "Nonrheumatic Tricuspid Valve Incompetence",
//     "ICD": "I36",
//     "ExchangeCode": 1676
//    },
//    {
//     "Group": "Heart",
//     "Value": "Nonrheumatic Tricuspid Valve Insufficiency",
//     "ICD": "I36",
//     "ExchangeCode": 1676
//    },
//    {
//     "Group": "Heart",
//     "Value": "Nonrheumatic Tricuspid Valve Regurgitation",
//     "ICD": "I36",
//     "ExchangeCode": 1676
//    },
//    {
//     "Group": "Heart",
//     "Value": "Nonrheumatic Tricuspid Valve Stenosis",
//     "ICD": "I36",
//     "ExchangeCode": 1676
//    },
//    {
//     "Group": "Heart",
//     "Value": "Nonrheumatic Tricuspid Valve Stenosis With Incompetence",
//     "ICD": "I36",
//     "ExchangeCode": 1676
//    },
//    {
//     "Group": "Heart",
//     "Value": "Nonrheumatic Tricuspid Valve Stenosis With Insufficiency",
//     "ICD": "I36",
//     "ExchangeCode": 1676
//    },
//    {
//     "Group": "Heart",
//     "Value": "Nonrheumatic Tricuspid Valve Stenosis With Regurgitation",
//     "ICD": "I36",
//     "ExchangeCode": 1676
//    },
//    {
//     "Group": "Heart",
//     "Value": "Obstructive Cardiomyopathy",
//     "ICD": "I42",
//     "ExchangeCode": 1134
//    },
//    {
//     "Group": "Heart",
//     "Value": "Obstructive Hypertrophic Cardiomyopathy",
//     "ICD": "I42",
//     "ExchangeCode": 1134
//    },
//    {
//     "Group": "Heart",
//     "Value": "Old Heart Attack Diagnosed By ECG Or Other Special Investigation",
//     "ICD": "I25",
//     "ExchangeCode": 530
//    },
//    {
//     "Group": "Heart",
//     "Value": "Old Myocardial Infarction Diagnosed By ECG Or Other Special Investigation",
//     "ICD": "I25",
//     "ExchangeCode": 530
//    },
//    {
//     "Group": "Heart",
//     "Value": "Other Congenital Malformations Of Pulmonary Valve",
//     "ICD": "Q22.3",
//     "ExchangeCode": 2166
//    },
//    {
//     "Group": "Heart",
//     "Value": "Other Rheumatic Heart Diseases",
//     "ICD": "I09",
//     "ExchangeCode": 1371
//    },
//    {
//     "Group": "Heart",
//     "Value": "Palpitations",
//     "ICD": "R00",
//     "ExchangeCode": 541
//    },
//    {
//     "Group": "Heart",
//     "Value": "Paroxysmal Tachycardia",
//     "ICD": "I47",
//     "ExchangeCode": 1499
//    },
//    {
//     "Group": "Heart",
//     "Value": "Patent Ductus Arteriosus",
//     "ICD": "Q25.0",
//     "ExchangeCode": 425
//    },
//    {
//     "Group": "Heart",
//     "Value": "PDA",
//     "ICD": "Q25",
//     "ExchangeCode": 1209
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pericarditis",
//     "ICD": "I31",
//     "ExchangeCode": 2133
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pericarditis Acute",
//     "ICD": "I30",
//     "ExchangeCode": 1215
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pericarditis Chronic",
//     "ICD": "I31.9",
//     "ExchangeCode": 1216
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pericarditis Constrictive",
//     "ICD": "I31.1",
//     "ExchangeCode": 2134
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pericarditis In Other Diseases Classified Elsewhere",
//     "ICD": "I32.8",
//     "ExchangeCode": 2136
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pericarditis Rheumatic",
//     "ICD": "I01",
//     "ExchangeCode": 1218
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pericarditis Tuberculous",
//     "ICD": "I32.0",
//     "ExchangeCode": 1219
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pericardium Tuberculosis",
//     "ICD": "I32.0",
//     "ExchangeCode": 1220
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pre-Excitation Syndrome",
//     "ICD": "I45",
//     "ExchangeCode": 1677
//    },
//    {
//     "Group": "Heart",
//     "Value": "Preexcitation Syndrome",
//     "ICD": "I45.6",
//     "ExchangeCode": 1273
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pulmonary Artery Stenosis",
//     "ICD": "Q25",
//     "ExchangeCode": 1318
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pulmonary Heart Disease",
//     "ICD": "I27",
//     "ExchangeCode": 582
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pulmonary Heart Valve Leak",
//     "ICD": "I37",
//     "ExchangeCode": 1321
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pulmonary Stenosis",
//     "ICD": "Q25.6",
//     "ExchangeCode": 1327
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pulmonary Valve Disorders",
//     "ICD": "I37",
//     "ExchangeCode": 1321
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pulmonary Valve Incompetence",
//     "ICD": "I37",
//     "ExchangeCode": 1321
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pulmonary Valve Insufficiency",
//     "ICD": "I37",
//     "ExchangeCode": 1321
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pulmonary Valve Murmur",
//     "ICD": "R01",
//     "ExchangeCode": 1330
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pulmonary Valve Regurgitation",
//     "ICD": "I37",
//     "ExchangeCode": 1321
//    },
//    {
//     "Group": "Heart",
//     "Value": "Pulmonary Valve Stenosis",
//     "ICD": "I37",
//     "ExchangeCode": 1331
//    },
//    {
//     "Group": "Heart",
//     "Value": "Rapid Heart Beat",
//     "ICD": "R00",
//     "ExchangeCode": 541
//    },
//    {
//     "Group": "Heart",
//     "Value": "RBBB",
//     "ICD": "I45.10",
//     "ExchangeCode": 1350
//    },
//    {
//     "Group": "Heart",
//     "Value": "Restrictive Cardiomyopathy",
//     "ICD": "I42",
//     "ExchangeCode": 251
//    },
//    {
//     "Group": "Heart",
//     "Value": "Rheumatic Aortic Valve Diseases",
//     "ICD": "I06",
//     "ExchangeCode": 1666
//    },
//    {
//     "Group": "Heart",
//     "Value": "Rheumatic Fever With Heart Involvement",
//     "ICD": "I01",
//     "ExchangeCode": 1373
//    },
//    {
//     "Group": "Heart",
//     "Value": "Rheumatic Heart Disease",
//     "ICD": "I09",
//     "ExchangeCode": 1371
//    },
//    {
//     "Group": "Heart",
//     "Value": "Rheumatic Mitral Valve Disease",
//     "ICD": "I05",
//     "ExchangeCode": 1655
//    },
//    {
//     "Group": "Heart",
//     "Value": "Rheumatic Myocarditis",
//     "ICD": "I09",
//     "ExchangeCode": 1083
//    },
//    {
//     "Group": "Heart",
//     "Value": "Rheumatic Tricuspid Valve Diseases",
//     "ICD": "I07",
//     "ExchangeCode": 1667
//    },
//    {
//     "Group": "Heart",
//     "Value": "Rheumatism With Heartinvolvement",
//     "ICD": "I01",
//     "ExchangeCode": 1373
//    },
//    {
//     "Group": "Heart",
//     "Value": "Right BBB",
//     "ICD": "I45.10",
//     "ExchangeCode": 1377
//    },
//    {
//     "Group": "Heart",
//     "Value": "Right Bundle Branch Block",
//     "ICD": "I45",
//     "ExchangeCode": 1378
//    },
//    {
//     "Group": "Heart",
//     "Value": "Right Bundlebranch Block",
//     "ICD": "I45",
//     "ExchangeCode": 1378
//    },
//    {
//     "Group": "Heart",
//     "Value": "Right Heart Failure",
//     "ICD": "I50",
//     "ExchangeCode": 1379
//    },
//    {
//     "Group": "Heart",
//     "Value": "Right Ventricular Hypertrophy",
//     "ICD": "I51",
//     "ExchangeCode": 1380
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ruptured Abdominal Aortic Aneurysm",
//     "ICD": "I71",
//     "ExchangeCode": 121
//    },
//    {
//     "Group": "Heart",
//     "Value": "RVH",
//     "ICD": "I51.7",
//     "ExchangeCode": 1381
//    },
//    {
//     "Group": "Heart",
//     "Value": "Second Degree Atrioventricular Block",
//     "ICD": "I44",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Septal Heart Defect",
//     "ICD": "Q21",
//     "ExchangeCode": 1434
//    },
//    {
//     "Group": "Heart",
//     "Value": "Septum Defect Cardiac",
//     "ICD": "Q21",
//     "ExchangeCode": 1434
//    },
//    {
//     "Group": "Heart",
//     "Value": "Sick Sinus Syndrome",
//     "ICD": "I49",
//     "ExchangeCode": 1678
//    },
//    {
//     "Group": "Heart",
//     "Value": "Slow Heart Beat",
//     "ICD": "R00",
//     "ExchangeCode": 541
//    },
//    {
//     "Group": "Heart",
//     "Value": "Stokes Adams Syndrome",
//     "ICD": "I45",
//     "ExchangeCode": 1487
//    },
//    {
//     "Group": "Heart",
//     "Value": "Subacute Bacterial Endocarditis",
//     "ICD": "I33.0",
//     "ExchangeCode": 1492
//    },
//    {
//     "Group": "Heart",
//     "Value": "Super Ventricular Tachycardia",
//     "ICD": "I47.1",
//     "ExchangeCode": 1497
//    },
//    {
//     "Group": "Heart",
//     "Value": "Supraventricular Paroxysmal Tachycardia",
//     "ICD": "I47.1",
//     "ExchangeCode": 1498
//    },
//    {
//     "Group": "Heart",
//     "Value": "Supraventricular Tachycardia",
//     "ICD": "I47",
//     "ExchangeCode": 1499
//    },
//    {
//     "Group": "Heart",
//     "Value": "SVT",
//     "ICD": "I47.1",
//     "ExchangeCode": 1500
//    },
//    {
//     "Group": "Heart",
//     "Value": "Systolic Murmur",
//     "ICD": "R01",
//     "ExchangeCode": 1330
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tachycardia",
//     "ICD": "R00",
//     "ExchangeCode": 541
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tachycardia Paroxysmal Unspecified",
//     "ICD": "I47.9",
//     "ExchangeCode": 1510
//    },
//    {
//     "Group": "Heart",
//     "Value": "Third Degree Atrioventricular Block",
//     "ICD": "I44",
//     "ExchangeCode": 145
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Heart Valve Leak",
//     "ICD": "I07",
//     "ExchangeCode": 1554
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Incompetence",
//     "ICD": "I07",
//     "ExchangeCode": 1555
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Insufficiency",
//     "ICD": "I07",
//     "ExchangeCode": 1556
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Regurgitation",
//     "ICD": "I07",
//     "ExchangeCode": 1557
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Stenosis",
//     "ICD": "I07",
//     "ExchangeCode": 1558
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Valve Anomaly",
//     "ICD": "Q22",
//     "ExchangeCode": 343
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Valve Atresia",
//     "ICD": "Q23",
//     "ExchangeCode": 1559
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Valve Disease",
//     "ICD": "I07",
//     "ExchangeCode": 1560
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Valve Disorder",
//     "ICD": "I07",
//     "ExchangeCode": 1560
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Valve Insufficiency",
//     "ICD": "I07",
//     "ExchangeCode": 1556
//    },
//    {
//     "Group": "Heart",
//     "Value": "Tricuspid Valve Stenosis",
//     "ICD": "I07",
//     "ExchangeCode": 1558
//    },
//    {
//     "Group": "Heart",
//     "Value": "Triscuspid Valve Incompetence",
//     "ICD": "I07",
//     "ExchangeCode": 1555
//    },
//    {
//     "Group": "Heart",
//     "Value": "Triscuspid Valve Regurgitation",
//     "ICD": "I07",
//     "ExchangeCode": 1557
//    },
//    {
//     "Group": "Heart",
//     "Value": "Valvular Heart Defect",
//     "ICD": "I34",
//     "ExchangeCode": 2182
//    },
//    {
//     "Group": "Heart",
//     "Value": "Valvular Heart Disease",
//     "ICD": "I34",
//     "ExchangeCode": 2183
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ventricular Fibrillation And Flutter",
//     "ICD": "I49",
//     "ExchangeCode": 1679
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ventricular Septal Defect",
//     "ICD": "Q21",
//     "ExchangeCode": 1612
//    },
//    {
//     "Group": "Heart",
//     "Value": "Ventricular Tachycardia",
//     "ICD": "I47",
//     "ExchangeCode": 1499
//    },
//    {
//     "Group": "Heart",
//     "Value": "Vitium Cordis",
//     "ICD": "I51.9",
//     "ExchangeCode": 1620
//    },
//    {
//     "Group": "Heart",
//     "Value": "Wolff Parkinson White Syndrome",
//     "ICD": "I45",
//     "ExchangeCode": 1631
//    },
//    {
//     "Group": "Heart",
//     "Value": "WPW",
//     "ICD": "I45.6",
//     "ExchangeCode": 1632
//    },
//    {
//     "Group": "MultipleSclerosis",
//     "Value": "Multiple Sclerosis",
//     "ICD": "G35",
//     "ExchangeCode": 1062
//    },
//    {
//     "Group": "Neurological",
//     "Value": "ALS",
//     "ICD": "G12",
//     "ExchangeCode": 60
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Alzheimer Disease",
//     "ICD": "G30",
//     "ExchangeCode": 1433
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Alzheimers Disease",
//     "ICD": "G30",
//     "ExchangeCode": 1433
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Amyotrophic Lateral Sclerosis",
//     "ICD": "G12",
//     "ExchangeCode": 67
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Anoxic Brain Damage",
//     "ICD": "G93",
//     "ExchangeCode": 82
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Anoxic Cerebral",
//     "ICD": "G93",
//     "ExchangeCode": 82
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Anterior Horn Syndrome",
//     "ICD": "G12",
//     "ExchangeCode": 83
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Arachnoid Cyst",
//     "ICD": "G93",
//     "ExchangeCode": 105
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Arteriosclerotic Dementia",
//     "ICD": "F01",
//     "ExchangeCode": 120
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Ataxia",
//     "ICD": "R27.0",
//     "ExchangeCode": 1986
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Ataxia Cerebellar",
//     "ICD": "G11",
//     "ExchangeCode": 138
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Attack Epileptic",
//     "ICD": "G40",
//     "ExchangeCode": 148
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Becker Dystrophy",
//     "ICD": "G71",
//     "ExchangeCode": 1643
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Brain Compression",
//     "ICD": "G93",
//     "ExchangeCode": 199
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Cauda Equina Syndrome",
//     "ICD": "G83.4",
//     "ExchangeCode": 259
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Cerebral Anoxia",
//     "ICD": "G93",
//     "ExchangeCode": 263
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Cerebral Cysts",
//     "ICD": "G93.0",
//     "ExchangeCode": 266
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Cerebral Dysfunction",
//     "ICD": "G93",
//     "ExchangeCode": 267
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Cerebral Pressure Rise",
//     "ICD": "G93",
//     "ExchangeCode": 273
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Cerebral Swelling",
//     "ICD": "G93",
//     "ExchangeCode": 274
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Congenital Myopathies",
//     "ICD": "G71.2",
//     "ExchangeCode": 2126
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Degeneration Cerebellar",
//     "ICD": "G11",
//     "ExchangeCode": 381
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Degeneration Cerebral",
//     "ICD": "G11",
//     "ExchangeCode": 382
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Dementia",
//     "ICD": "F03",
//     "ExchangeCode": 384
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Dementia Senile",
//     "ICD": "F03",
//     "ExchangeCode": 1316
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Demyelinating Disease",
//     "ICD": "G37",
//     "ExchangeCode": 385
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Disease Spinocerebellar",
//     "ICD": "G11",
//     "ExchangeCode": 412
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Dystrophy Myotonic",
//     "ICD": "G71",
//     "ExchangeCode": 434
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Early-Onset Cerebellar Ataxia",
//     "ICD": "G11.1",
//     "ExchangeCode": 2176
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Encephalitis",
//     "ICD": "G05",
//     "ExchangeCode": 445
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Encephalopathy",
//     "ICD": "G30",
//     "ExchangeCode": 445
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Epilepsy",
//     "ICD": "G40",
//     "ExchangeCode": 461
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Fit",
//     "ICD": "G40",
//     "ExchangeCode": 486
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Fits",
//     "ICD": "G40",
//     "ExchangeCode": 487
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Flaccid Hemiplegia",
//     "ICD": "G81",
//     "ExchangeCode": 488
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Friedreichs Ataxia",
//     "ICD": "G11",
//     "ExchangeCode": 494
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Friedreichs Disease",
//     "ICD": "G11",
//     "ExchangeCode": 495
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Generalised Idiopathic Epilepsy And Epileptic Syndromes",
//     "ICD": "G40.3",
//     "ExchangeCode": 2179
//    },
//    {
//     "Group": "Neurological",
//     "Value": "GM Epilepsy",
//     "ICD": "G40.3",
//     "ExchangeCode": 511
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Grand Mal Epilepsy",
//     "ICD": "G40",
//     "ExchangeCode": 512
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Grand Mal Seizures",
//     "ICD": "G40",
//     "ExchangeCode": 512
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Guillain Barre Syndrome",
//     "ICD": "G61",
//     "ExchangeCode": 514
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Hemiplegia",
//     "ICD": "G81",
//     "ExchangeCode": 546
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Hereditary Ataxia",
//     "ICD": "G11",
//     "ExchangeCode": 495
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Hereditary Motor And Sensory Neuropathy",
//     "ICD": "G60.0",
//     "ExchangeCode": 2124
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Huntingtons Chorea",
//     "ICD": "G24",
//     "ExchangeCode": 569
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Huntingtons Disease",
//     "ICD": "G24",
//     "ExchangeCode": 569
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Idiopathic Epilepsy",
//     "ICD": "G40",
//     "ExchangeCode": 597
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Infantile Spinal Muscular Atrophy, Type I [Werdnig-Hoffman]",
//     "ICD": "G12.0",
//     "ExchangeCode": 1630
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Jacksonian Epilepsy",
//     "ICD": "G40",
//     "ExchangeCode": 640
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Korsakoff Syndrome",
//     "ICD": "F03",
//     "ExchangeCode": 1316
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Lateral Sclerosis",
//     "ICD": "G12",
//     "ExchangeCode": 670
//    },
//    {
//     "Group": "Neurological",
//     "Value": "M Gravis",
//     "ICD": "G70.0",
//     "ExchangeCode": 709
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Migraine",
//     "ICD": "G43",
//     "ExchangeCode": 1639
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Mononeuropathies Of Upper Limb",
//     "ICD": "G56",
//     "ExchangeCode": 2123
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Motor Neurone Disease",
//     "ICD": "G12",
//     "ExchangeCode": 2177
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Multi-Infarct Dementia",
//     "ICD": "F01",
//     "ExchangeCode": 120
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Muscular Atrophy Spinal",
//     "ICD": "G12",
//     "ExchangeCode": 1065
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Muscular Dystrophy",
//     "ICD": "G71",
//     "ExchangeCode": 1066
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Myasthenia Gravis",
//     "ICD": "G70.0",
//     "ExchangeCode": 1069
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Myelopathy",
//     "ICD": "G95",
//     "ExchangeCode": 1077
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Myopathy",
//     "ICD": "G71",
//     "ExchangeCode": 1086
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Myotonia",
//     "ICD": "G71",
//     "ExchangeCode": 1087
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Myotonia Congenital",
//     "ICD": "G71",
//     "ExchangeCode": 1088
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Myotonic Disorder",
//     "ICD": "G71",
//     "ExchangeCode": 1089
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Narcolepsy",
//     "ICD": "G47",
//     "ExchangeCode": 1090
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Narcolepsy And Cataplexy",
//     "ICD": "G47.4",
//     "ExchangeCode": 2122
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Neuropathy",
//     "ICD": "G60",
//     "ExchangeCode": 1121
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Nocturnal Epilepsy",
//     "ICD": "G40",
//     "ExchangeCode": 1125
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Other Acute Disseminated Demyelination",
//     "ICD": "G36",
//     "ExchangeCode": 2118
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Other Headache Syndromes",
//     "ICD": "G44",
//     "ExchangeCode": 2119
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Other Specified Degenerative Diseases Of Basal Ganglia",
//     "ICD": "G23.8",
//     "ExchangeCode": 2117
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Paralysis Agitans",
//     "ICD": "G20",
//     "ExchangeCode": 1192
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Paramyotonia Congenita",
//     "ICD": "G71",
//     "ExchangeCode": 1193
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Parkinsonism",
//     "ICD": "G20",
//     "ExchangeCode": 1201
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Parkinsons",
//     "ICD": "G20",
//     "ExchangeCode": 1201
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Parkinsons Disease",
//     "ICD": "G20",
//     "ExchangeCode": 1201
//    },
//    {
//     "Group": "Neurological",
//     "Value": "PD",
//     "ICD": "G20",
//     "ExchangeCode": 1201
//    },
//    {
//     "Group": "Neurological",
//     "Value": "PD - Parkinsons Disease",
//     "ICD": "G20",
//     "ExchangeCode": 1201
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Peripheral Neuropathy",
//     "ICD": "G64",
//     "ExchangeCode": 1223
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Petit Mal Epilepsy",
//     "ICD": "G40",
//     "ExchangeCode": 1232
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Petit Mal Seizures",
//     "ICD": "G40",
//     "ExchangeCode": 1232
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Picks Disease",
//     "ICD": "G30",
//     "ExchangeCode": 1235
//    },
//    {
//     "Group": "Neurological",
//     "Value": "PM Epilepsy",
//     "ICD": "G40.7",
//     "ExchangeCode": 1251
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Polyneuropathy",
//     "ICD": "G61",
//     "ExchangeCode": 1264
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Polyneuropathy Alcoholic",
//     "ICD": "G62",
//     "ExchangeCode": 1266
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Polyneuropathy Drug Induced",
//     "ICD": "G62",
//     "ExchangeCode": 1267
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Polyneuropathy Toxic",
//     "ICD": "G62",
//     "ExchangeCode": 1268
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Post Traumatic Epilepsy",
//     "ICD": "G40",
//     "ExchangeCode": 1272
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Post Viral Fatigue Syndrome",
//     "ICD": "G93",
//     "ExchangeCode": 1646
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Presenile Dementia",
//     "ICD": "F03",
//     "ExchangeCode": 1274
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Psychosis Senile",
//     "ICD": "F03",
//     "ExchangeCode": 1316
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Reyes Syndrome",
//     "ICD": "G93",
//     "ExchangeCode": 1647
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Seizure Disorder",
//     "ICD": "G40",
//     "ExchangeCode": 1428
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Seizures",
//     "ICD": "G40",
//     "ExchangeCode": 1429
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Senile Brain Degeneration",
//     "ICD": "G30",
//     "ExchangeCode": 1433
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Senile Dementia",
//     "ICD": "F03",
//     "ExchangeCode": 1316
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Spastic Diplegia",
//     "ICD": "G80.1",
//     "ExchangeCode": 2127
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Spastic Hemiplegia",
//     "ICD": "G81",
//     "ExchangeCode": 1457
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Spinal Cord Compression",
//     "ICD": "G95",
//     "ExchangeCode": 1468
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Status Epilepticus",
//     "ICD": "G40",
//     "ExchangeCode": 1483
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Syringomyelia",
//     "ICD": "G95",
//     "ExchangeCode": 1506
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Syringomyelia And Syringobulbia",
//     "ICD": "G95.0",
//     "ExchangeCode": 2128
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Temporal Lobe Epilepsy",
//     "ICD": "G40",
//     "ExchangeCode": 1518
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Vascular Dementia",
//     "ICD": "F01",
//     "ExchangeCode": 120
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Werdnig Hoffmann",
//     "ICD": "G12.0",
//     "ExchangeCode": 1630
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Werdnig Hoffmann Disease",
//     "ICD": "G12",
//     "ExchangeCode": 1630
//    },
//    {
//     "Group": "Neurological",
//     "Value": "Werdnig Hoffmann Syndrome",
//     "ICD": "G12",
//     "ExchangeCode": 1630
//    },
//    {
//     "Group": "Other",
//     "Value": "Abdominal Pain",
//     "ICD": "R10.4",
//     "ExchangeCode": 1977
//    },
//    {
//     "Group": "Other",
//     "Value": "Abnormality Of Red Blood Cells",
//     "ICD": "R71",
//     "ExchangeCode": 2172
//    },
//    {
//     "Group": "Other",
//     "Value": "Abscess",
//     "ICD": "L02.9",
//     "ExchangeCode": 1854
//    },
//    {
//     "Group": "Other",
//     "Value": "Abscess Of Tonsil",
//     "ICD": "J36",
//     "ExchangeCode": 1706
//    },
//    {
//     "Group": "Other",
//     "Value": "Abscess On Liver",
//     "ICD": "K75.0",
//     "ExchangeCode": 1852
//    },
//    {
//     "Group": "Other",
//     "Value": "Achalasia",
//     "ICD": "K22.0",
//     "ExchangeCode": 1829
//    },
//    {
//     "Group": "Other",
//     "Value": "Acquired Coagulation Deficiency",
//     "ICD": "D68",
//     "ExchangeCode": 7
//    },
//    {
//     "Group": "Other",
//     "Value": "Acquired Immune Deficiency Syndrome",
//     "ICD": "D84",
//     "ExchangeCode": 8
//    },
//    {
//     "Group": "Other",
//     "Value": "Acromegaly",
//     "ICD": "E22.0",
//     "ExchangeCode": 9
//    },
//    {
//     "Group": "Other",
//     "Value": "Acromegaly And Pituitary Gigantism",
//     "ICD": "E22.0",
//     "ExchangeCode": 9
//    },
//    {
//     "Group": "Other",
//     "Value": "Actinic Keratosis",
//     "ICD": "L57.0",
//     "ExchangeCode": 1880
//    },
//    {
//     "Group": "Other",
//     "Value": "Acute Cholangitis",
//     "ICD": "K83.0",
//     "ExchangeCode": 290
//    },
//    {
//     "Group": "Other",
//     "Value": "Acute Delirium",
//     "ICD": "F05",
//     "ExchangeCode": 11
//    },
//    {
//     "Group": "Other",
//     "Value": "Acute Gallbladder Inflammation",
//     "ICD": "K81.1",
//     "ExchangeCode": 1852
//    },
//    {
//     "Group": "Other",
//     "Value": "Acute Glomerulonephritis",
//     "ICD": "N00",
//     "ExchangeCode": 12
//    },
//    {
//     "Group": "Other",
//     "Value": "Acute Miliary Tuberculosis, Unspecified",
//     "ICD": "A19.2",
//     "ExchangeCode": 2063
//    },
//    {
//     "Group": "Other",
//     "Value": "Acute Pancreatitis",
//     "ICD": "K85",
//     "ExchangeCode": 1187
//    },
//    {
//     "Group": "Other",
//     "Value": "Acute Prostatitis",
//     "ICD": "N41.0",
//     "ExchangeCode": 1947
//    },
//    {
//     "Group": "Other",
//     "Value": "Acute Renal Failure",
//     "ICD": "N17",
//     "ExchangeCode": 1934
//    },
//    {
//     "Group": "Other",
//     "Value": "Addison Disease",
//     "ICD": "E27",
//     "ExchangeCode": 18
//    },
//    {
//     "Group": "Other",
//     "Value": "Addison Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Addison Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Addisonian Crisis",
//     "ICD": "E27.2",
//     "ExchangeCode": 21
//    },
//    {
//     "Group": "Other",
//     "Value": "Addisons Disease",
//     "ICD": "E27.1",
//     "ExchangeCode": 22
//    },
//    {
//     "Group": "Other",
//     "Value": "Addisons Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Adnexitis Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Adrenal Cortex Atrophy",
//     "ICD": "E27",
//     "ExchangeCode": 28
//    },
//    {
//     "Group": "Other",
//     "Value": "Adrenal Cortex Calcification",
//     "ICD": "E27",
//     "ExchangeCode": 29
//    },
//    {
//     "Group": "Other",
//     "Value": "Adrenal Disorder",
//     "ICD": "E27",
//     "ExchangeCode": 33
//    },
//    {
//     "Group": "Other",
//     "Value": "Adrenal Gland TB",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Adrenal Gland Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Agammaglobulinaemia",
//     "ICD": "D80",
//     "ExchangeCode": 43
//    },
//    {
//     "Group": "Other",
//     "Value": "Agranulocytosis",
//     "ICD": "D70",
//     "ExchangeCode": 2104
//    },
//    {
//     "Group": "Other",
//     "Value": "Agyria",
//     "ICD": "Q04",
//     "ExchangeCode": 44
//    },
//    {
//     "Group": "Other",
//     "Value": "AIDS",
//     "ICD": "B24",
//     "ExchangeCode": 8
//    },
//    {
//     "Group": "Other",
//     "Value": "AIDS Related Complex",
//     "ICD": "B24",
//     "ExchangeCode": 8
//    },
//    {
//     "Group": "Other",
//     "Value": "Albuminuria",
//     "ICD": "R80",
//     "ExchangeCode": 49
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcohol Abuse",
//     "ICD": "F10",
//     "ExchangeCode": 50
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcohol Dependence",
//     "ICD": "F10.2",
//     "ExchangeCode": 57
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcohol Misuse",
//     "ICD": "F10",
//     "ExchangeCode": 51
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcoholic",
//     "ICD": "F10.1",
//     "ExchangeCode": 57
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcoholic Cirrhosis",
//     "ICD": "K70.3",
//     "ExchangeCode": 53
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcoholic Cirrhosis Of Liver",
//     "ICD": "K70.3",
//     "ExchangeCode": 53
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcoholic Dementia",
//     "ICD": "F10",
//     "ExchangeCode": 54
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcoholic Hallucinations",
//     "ICD": "F10",
//     "ExchangeCode": 55
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcoholic Liver Damage",
//     "ICD": "K70.4",
//     "ExchangeCode": 53
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcoholic Liver Disease",
//     "ICD": "K70",
//     "ExchangeCode": 694
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcoholic Psychoses",
//     "ICD": "F10",
//     "ExchangeCode": 56
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcoholic Psychosis",
//     "ICD": "F10",
//     "ExchangeCode": 56
//    },
//    {
//     "Group": "Other",
//     "Value": "Alcoholism",
//     "ICD": "F10.2",
//     "ExchangeCode": 57
//    },
//    {
//     "Group": "Other",
//     "Value": "Alopecia",
//     "ICD": "L65.9",
//     "ExchangeCode": 1881
//    },
//    {
//     "Group": "Other",
//     "Value": "Alpha 1 Antitrypsin Deficiency",
//     "ICD": "E88",
//     "ExchangeCode": 59
//    },
//    {
//     "Group": "Other",
//     "Value": "Alpha1 Antitrypsin Deficiency",
//     "ICD": "E88",
//     "ExchangeCode": 59
//    },
//    {
//     "Group": "Other",
//     "Value": "Amputation Disease Related",
//     "ICD": "Y83",
//     "ExchangeCode": 64
//    },
//    {
//     "Group": "Other",
//     "Value": "Amyloid",
//     "ICD": "E85",
//     "ExchangeCode": 65
//    },
//    {
//     "Group": "Other",
//     "Value": "Amyloid Kidney",
//     "ICD": "E85.4",
//     "ExchangeCode": 652
//    },
//    {
//     "Group": "Other",
//     "Value": "Amyloidosis",
//     "ICD": "E85",
//     "ExchangeCode": 66
//    },
//    {
//     "Group": "Other",
//     "Value": "Anaemia",
//     "ICD": "D64.9",
//     "ExchangeCode": 68
//    },
//    {
//     "Group": "Other",
//     "Value": "Anaemia Aplastic",
//     "ICD": "D64",
//     "ExchangeCode": 68
//    },
//    {
//     "Group": "Other",
//     "Value": "Anaemia Haemolytic",
//     "ICD": "D64",
//     "ExchangeCode": 69
//    },
//    {
//     "Group": "Other",
//     "Value": "Anaemia Haemolytic Hereditary",
//     "ICD": "D64",
//     "ExchangeCode": 70
//    },
//    {
//     "Group": "Other",
//     "Value": "Anal Abscess",
//     "ICD": "K61.0",
//     "ExchangeCode": 1847
//    },
//    {
//     "Group": "Other",
//     "Value": "Anal Fissure",
//     "ICD": "K60.2",
//     "ExchangeCode": 1846
//    },
//    {
//     "Group": "Other",
//     "Value": "Anal Polyp",
//     "ICD": "K62.0",
//     "ExchangeCode": 1280
//    },
//    {
//     "Group": "Other",
//     "Value": "Anal Prolapse",
//     "ICD": "K62.2",
//     "ExchangeCode": 1280
//    },
//    {
//     "Group": "Other",
//     "Value": "Aneurysm Cerebral",
//     "ICD": "I67",
//     "ExchangeCode": 2146
//    },
//    {
//     "Group": "Other",
//     "Value": "Aneurysm Of Pulmonary Artery",
//     "ICD": "I28.1",
//     "ExchangeCode": 77
//    },
//    {
//     "Group": "Other",
//     "Value": "Aneurysm Pulmonary Artery",
//     "ICD": "I28",
//     "ExchangeCode": 77
//    },
//    {
//     "Group": "Other",
//     "Value": "Ankylosing Spondylitis",
//     "ICD": "M45",
//     "ExchangeCode": 1903
//    },
//    {
//     "Group": "Other",
//     "Value": "Anomaly Digestive System",
//     "ICD": "Q45.9",
//     "ExchangeCode": 1966
//    },
//    {
//     "Group": "Other",
//     "Value": "Anti Stomach Ulcer",
//     "ICD": "K25.9",
//     "ExchangeCode": 1830
//    },
//    {
//     "Group": "Other",
//     "Value": "Anuria",
//     "ICD": "R34",
//     "ExchangeCode": 84
//    },
//    {
//     "Group": "Other",
//     "Value": "Apallic Syndrome",
//     "ICD": "F07",
//     "ExchangeCode": 2110
//    },
//    {
//     "Group": "Other",
//     "Value": "Appendicectomy",
//     "ICD": "K37",
//     "ExchangeCode": 1837
//    },
//    {
//     "Group": "Other",
//     "Value": "Appendicitis",
//     "ICD": "K37",
//     "ExchangeCode": 1837
//    },
//    {
//     "Group": "Other",
//     "Value": "Appendix Perforation",
//     "ICD": "K35.0",
//     "ExchangeCode": 1837
//    },
//    {
//     "Group": "Other",
//     "Value": "Arm Arterial Embolism",
//     "ICD": "I74.2",
//     "ExchangeCode": 106
//    },
//    {
//     "Group": "Other",
//     "Value": "Arm Monoplegia",
//     "ICD": "G83",
//     "ExchangeCode": 109
//    },
//    {
//     "Group": "Other",
//     "Value": "Arm Paralysis",
//     "ICD": "G83",
//     "ExchangeCode": 111
//    },
//    {
//     "Group": "Other",
//     "Value": "Arm Paralysis Unilateral",
//     "ICD": "G83",
//     "ExchangeCode": 112
//    },
//    {
//     "Group": "Other",
//     "Value": "Arms Paralysis",
//     "ICD": "G83",
//     "ExchangeCode": 113
//    },
//    {
//     "Group": "Other",
//     "Value": "Arnold Chiari Syndrome",
//     "ICD": "Q07",
//     "ExchangeCode": 114
//    },
//    {
//     "Group": "Other",
//     "Value": "Arnold-Chiari Syndrome",
//     "ICD": "Q07.0",
//     "ExchangeCode": 115
//    },
//    {
//     "Group": "Other",
//     "Value": "Arterial Occlusive Disease",
//     "ICD": "I74",
//     "ExchangeCode": 117
//    },
//    {
//     "Group": "Other",
//     "Value": "Arteriosclerosis",
//     "ICD": "I70",
//     "ExchangeCode": 118
//    },
//    {
//     "Group": "Other",
//     "Value": "Arteriosclerosis Cerebral",
//     "ICD": "I67.2",
//     "ExchangeCode": 2149
//    },
//    {
//     "Group": "Other",
//     "Value": "Arteriosclerosis Obliterans",
//     "ICD": "I73.9",
//     "ExchangeCode": 1611
//    },
//    {
//     "Group": "Other",
//     "Value": "Arteriovenous Fistula Of Pulmonary Vessels",
//     "ICD": "I28.0",
//     "ExchangeCode": 2132
//    },
//    {
//     "Group": "Other",
//     "Value": "Arteritis",
//     "ICD": "I77",
//     "ExchangeCode": 117
//    },
//    {
//     "Group": "Other",
//     "Value": "Arteritis Cerebral",
//     "ICD": "I77.6",
//     "ExchangeCode": 122
//    },
//    {
//     "Group": "Other",
//     "Value": "Arteritis Nodosa",
//     "ICD": "M30.0",
//     "ExchangeCode": 1897
//    },
//    {
//     "Group": "Other",
//     "Value": "Artery Cerebral Occlusion",
//     "ICD": "I66",
//     "ExchangeCode": 2145
//    },
//    {
//     "Group": "Other",
//     "Value": "Artery Cerebral Thrombosis",
//     "ICD": "I66",
//     "ExchangeCode": 2145
//    },
//    {
//     "Group": "Other",
//     "Value": "Artery Embolism",
//     "ICD": "I74",
//     "ExchangeCode": 125
//    },
//    {
//     "Group": "Other",
//     "Value": "Artery Occlusion",
//     "ICD": "I77",
//     "ExchangeCode": 126
//    },
//    {
//     "Group": "Other",
//     "Value": "Artery Spasm",
//     "ICD": "I73",
//     "ExchangeCode": 127
//    },
//    {
//     "Group": "Other",
//     "Value": "Artery Stricture",
//     "ICD": "I77",
//     "ExchangeCode": 126
//    },
//    {
//     "Group": "Other",
//     "Value": "Artery Thrombosis",
//     "ICD": "I74",
//     "ExchangeCode": 128
//    },
//    {
//     "Group": "Other",
//     "Value": "Arthritis",
//     "ICD": "M13.9",
//     "ExchangeCode": 1256
//    },
//    {
//     "Group": "Other",
//     "Value": "Arthritis Rheumatic",
//     "ICD": "M06",
//     "ExchangeCode": 129
//    },
//    {
//     "Group": "Other",
//     "Value": "Arthritis Rheumatoid",
//     "ICD": "M06.9",
//     "ExchangeCode": 1374
//    },
//    {
//     "Group": "Other",
//     "Value": "Arthritis Tuberculous",
//     "ICD": "M01",
//     "ExchangeCode": 130
//    },
//    {
//     "Group": "Other",
//     "Value": "Arthropathy Psoriasis",
//     "ICD": "L40.5",
//     "ExchangeCode": 1875
//    },
//    {
//     "Group": "Other",
//     "Value": "Arthrosis",
//     "ICD": "M19.9",
//     "ExchangeCode": 1891
//    },
//    {
//     "Group": "Other",
//     "Value": "Arthrosis Polyarticular",
//     "ICD": "M15",
//     "ExchangeCode": 131
//    },
//    {
//     "Group": "Other",
//     "Value": "Ascites",
//     "ICD": "R18",
//     "ExchangeCode": 134
//    },
//    {
//     "Group": "Other",
//     "Value": "Aspergers Syndrome",
//     "ICD": "F84",
//     "ExchangeCode": 149
//    },
//    {
//     "Group": "Other",
//     "Value": "Atheroma",
//     "ICD": "L72.1",
//     "ExchangeCode": 1883
//    },
//    {
//     "Group": "Other",
//     "Value": "Atheroma Cerebral Artery",
//     "ICD": "I66",
//     "ExchangeCode": 2145
//    },
//    {
//     "Group": "Other",
//     "Value": "Atherosclerosis",
//     "ICD": "I70",
//     "ExchangeCode": 121
//    },
//    {
//     "Group": "Other",
//     "Value": "Atherosclerosis Of Arteries Of Extremities",
//     "ICD": "I70",
//     "ExchangeCode": 121
//    },
//    {
//     "Group": "Other",
//     "Value": "Atherosclerosis Peripheral",
//     "ICD": "I70.2",
//     "ExchangeCode": 140
//    },
//    {
//     "Group": "Other",
//     "Value": "Atopic Eczema",
//     "ICD": "L20.9",
//     "ExchangeCode": 1860
//    },
//    {
//     "Group": "Other",
//     "Value": "Attack Cerebral Ischaemic",
//     "ICD": "I63.9",
//     "ExchangeCode": 2142
//    },
//    {
//     "Group": "Other",
//     "Value": "Autism",
//     "ICD": "F84",
//     "ExchangeCode": 149
//    },
//    {
//     "Group": "Other",
//     "Value": "Autism Infantile",
//     "ICD": "F84",
//     "ExchangeCode": 150
//    },
//    {
//     "Group": "Other",
//     "Value": "Autoimmune Disease",
//     "ICD": "M35.9",
//     "ExchangeCode": 151
//    },
//    {
//     "Group": "Other",
//     "Value": "Autoimmune Haemolytic Anaemia",
//     "ICD": "D59.1",
//     "ExchangeCode": 152
//    },
//    {
//     "Group": "Other",
//     "Value": "B12 Deficiency Anaemia",
//     "ICD": "D51",
//     "ExchangeCode": 157
//    },
//    {
//     "Group": "Other",
//     "Value": "Back Pain",
//     "ICD": "M54.9",
//     "ExchangeCode": 1907
//    },
//    {
//     "Group": "Other",
//     "Value": "Bakers Cyst",
//     "ICD": "M71.2",
//     "ExchangeCode": 1911
//    },
//    {
//     "Group": "Other",
//     "Value": "Balanitis",
//     "ICD": "N48.1",
//     "ExchangeCode": 1951
//    },
//    {
//     "Group": "Other",
//     "Value": "Barrets Esophagus",
//     "ICD": "K22.1",
//     "ExchangeCode": 1829
//    },
//    {
//     "Group": "Other",
//     "Value": "Barrets Oesophagus",
//     "ICD": "K22.7",
//     "ExchangeCode": 1829
//    },
//    {
//     "Group": "Other",
//     "Value": "Barretts Ulcer",
//     "ICD": "K22.1",
//     "ExchangeCode": 1829
//    },
//    {
//     "Group": "Other",
//     "Value": "Basilar Aneurysm",
//     "ICD": "I67",
//     "ExchangeCode": 2146
//    },
//    {
//     "Group": "Other",
//     "Value": "Basilar Stenosis",
//     "ICD": "I65.1",
//     "ExchangeCode": 2144
//    },
//    {
//     "Group": "Other",
//     "Value": "Bells Palsy",
//     "ICD": "G51",
//     "ExchangeCode": 1640
//    },
//    {
//     "Group": "Other",
//     "Value": "Benign Intracranial Hypertension",
//     "ICD": "G93.2",
//     "ExchangeCode": 1645
//    },
//    {
//     "Group": "Other",
//     "Value": "Benign Prostatic Hypertrophy",
//     "ICD": "N40",
//     "ExchangeCode": 1946
//    },
//    {
//     "Group": "Other",
//     "Value": "Berry Aneurysm",
//     "ICD": "I67.1",
//     "ExchangeCode": 2148
//    },
//    {
//     "Group": "Other",
//     "Value": "Bile Duct Obstruction",
//     "ICD": "K83.1",
//     "ExchangeCode": 289
//    },
//    {
//     "Group": "Other",
//     "Value": "Bileduct Inflammation Acute",
//     "ICD": "K80",
//     "ExchangeCode": 168
//    },
//    {
//     "Group": "Other",
//     "Value": "Bileduct Inflammation Chronic",
//     "ICD": "K80",
//     "ExchangeCode": 169
//    },
//    {
//     "Group": "Other",
//     "Value": "Bileduct Inflammation Sclerosing",
//     "ICD": "K80",
//     "ExchangeCode": 170
//    },
//    {
//     "Group": "Other",
//     "Value": "Bileduct Inflammation Stenosing",
//     "ICD": "K80",
//     "ExchangeCode": 171
//    },
//    {
//     "Group": "Other",
//     "Value": "Biliary Cirrhosis",
//     "ICD": "K74.5",
//     "ExchangeCode": 312
//    },
//    {
//     "Group": "Other",
//     "Value": "Biliferous Duct Inflammation",
//     "ICD": "K80",
//     "ExchangeCode": 172
//    },
//    {
//     "Group": "Other",
//     "Value": "Binge Drinker",
//     "ICD": "F10",
//     "ExchangeCode": 174
//    },
//    {
//     "Group": "Other",
//     "Value": "Bipolar Disorder",
//     "ICD": "F30",
//     "ExchangeCode": 175
//    },
//    {
//     "Group": "Other",
//     "Value": "Bird Flu",
//     "ICD": "J09",
//     "ExchangeCode": 1699
//    },
//    {
//     "Group": "Other",
//     "Value": "Bladder Disorder",
//     "ICD": "N32.9",
//     "ExchangeCode": 1942
//    },
//    {
//     "Group": "Other",
//     "Value": "Bladder Infection",
//     "ICD": "N30.9",
//     "ExchangeCode": 1941
//    },
//    {
//     "Group": "Other",
//     "Value": "Bladder Neck Obstruction",
//     "ICD": "N13.9",
//     "ExchangeCode": 1922
//    },
//    {
//     "Group": "Other",
//     "Value": "Bladder Stone",
//     "ICD": "N21.0",
//     "ExchangeCode": 1938
//    },
//    {
//     "Group": "Other",
//     "Value": "Bladder TB",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Bladder Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Bladder Urination Abnormality",
//     "ICD": "R39.1",
//     "ExchangeCode": 1593
//    },
//    {
//     "Group": "Other",
//     "Value": "Blepharitis",
//     "ICD": "H01.0",
//     "ExchangeCode": 1805
//    },
//    {
//     "Group": "Other",
//     "Value": "Blind",
//     "ICD": "H54.0",
//     "ExchangeCode": 1816
//    },
//    {
//     "Group": "Other",
//     "Value": "Blindness",
//     "ICD": "H54.0",
//     "ExchangeCode": 1816
//    },
//    {
//     "Group": "Other",
//     "Value": "Blocked Cerebral Artery",
//     "ICD": "I66",
//     "ExchangeCode": 2145
//    },
//    {
//     "Group": "Other",
//     "Value": "Blocked Peripheral Artery",
//     "ICD": "I74",
//     "ExchangeCode": 183
//    },
//    {
//     "Group": "Other",
//     "Value": "Blod Clot In Leg",
//     "ICD": "I80.2",
//     "ExchangeCode": 1680
//    },
//    {
//     "Group": "Other",
//     "Value": "Blood Clot Brain",
//     "ICD": "I62.9",
//     "ExchangeCode": 2140
//    },
//    {
//     "Group": "Other",
//     "Value": "Blood Disease",
//     "ICD": "D75.9",
//     "ExchangeCode": 1591
//    },
//    {
//     "Group": "Other",
//     "Value": "Blood Disorder",
//     "ICD": "D75",
//     "ExchangeCode": 1592
//    },
//    {
//     "Group": "Other",
//     "Value": "Blood Dyscrasia",
//     "ICD": "D75",
//     "ExchangeCode": 185
//    },
//    {
//     "Group": "Other",
//     "Value": "Blood Effusion Intracerebral",
//     "ICD": "I62.9",
//     "ExchangeCode": 2140
//    },
//    {
//     "Group": "Other",
//     "Value": "Bone Cyst",
//     "ICD": "M85.6",
//     "ExchangeCode": 1917
//    },
//    {
//     "Group": "Other",
//     "Value": "Bone Spur",
//     "ICD": "M77.3",
//     "ExchangeCode": 1914
//    },
//    {
//     "Group": "Other",
//     "Value": "Bone Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Brain Abscess Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Brain Haemorrhage",
//     "ICD": "I62.9",
//     "ExchangeCode": 2140
//    },
//    {
//     "Group": "Other",
//     "Value": "Brain Infarct",
//     "ICD": "I63",
//     "ExchangeCode": 2141
//    },
//    {
//     "Group": "Other",
//     "Value": "Brain Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Breast Cyst",
//     "ICD": "N60.0",
//     "ExchangeCode": 1952
//    },
//    {
//     "Group": "Other",
//     "Value": "Broken Neck",
//     "ICD": "S12.9",
//     "ExchangeCode": 209
//    },
//    {
//     "Group": "Other",
//     "Value": "Buergers Disease",
//     "ICD": "I73",
//     "ExchangeCode": 217
//    },
//    {
//     "Group": "Other",
//     "Value": "Bursitis",
//     "ICD": "M71.9",
//     "ExchangeCode": 1911
//    },
//    {
//     "Group": "Other",
//     "Value": "Calcification Of Muscle",
//     "ICD": "M61.4",
//     "ExchangeCode": 1909
//    },
//    {
//     "Group": "Other",
//     "Value": "Carbohydrate Malabsorption",
//     "ICD": "K90",
//     "ExchangeCode": 244
//    },
//    {
//     "Group": "Other",
//     "Value": "Carotid Artery Stenosis",
//     "ICD": "I65",
//     "ExchangeCode": 2143
//    },
//    {
//     "Group": "Other",
//     "Value": "Carotid Occlusion",
//     "ICD": "I65",
//     "ExchangeCode": 2143
//    },
//    {
//     "Group": "Other",
//     "Value": "Carotid Stenosis",
//     "ICD": "I65",
//     "ExchangeCode": 2143
//    },
//    {
//     "Group": "Other",
//     "Value": "Carpal Tunnel Syndrome",
//     "ICD": "G56.0",
//     "ExchangeCode": 1641
//    },
//    {
//     "Group": "Other",
//     "Value": "Cartilage Damage",
//     "ICD": "M24.1",
//     "ExchangeCode": 1895
//    },
//    {
//     "Group": "Other",
//     "Value": "Cataract",
//     "ICD": "H26.9",
//     "ExchangeCode": 1808
//    },
//    {
//     "Group": "Other",
//     "Value": "Catatonic Schizophrenia",
//     "ICD": "F20.2",
//     "ExchangeCode": 258
//    },
//    {
//     "Group": "Other",
//     "Value": "Cell Giant Arteritis",
//     "ICD": "M31.6",
//     "ExchangeCode": 1898
//    },
//    {
//     "Group": "Other",
//     "Value": "Cellulitis",
//     "ICD": "L03",
//     "ExchangeCode": 1855
//    },
//    {
//     "Group": "Other",
//     "Value": "Central Nervous System Tuberculosis",
//     "ICD": "A17",
//     "ExchangeCode": 1736
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Arteries Thrombosis",
//     "ICD": "I63",
//     "ExchangeCode": 2141
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Arteriovenous Malformation",
//     "ICD": "Q28.2",
//     "ExchangeCode": 2167
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Artery Clot",
//     "ICD": "I63",
//     "ExchangeCode": 2141
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Atherosclerosis",
//     "ICD": "I67.2",
//     "ExchangeCode": 2149
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Embolism",
//     "ICD": "I63",
//     "ExchangeCode": 2141
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Haemorrhage",
//     "ICD": "I63",
//     "ExchangeCode": 2141
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Haemorrhage\/Bleed",
//     "ICD": "I60",
//     "ExchangeCode": 269
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Infarct",
//     "ICD": "I63",
//     "ExchangeCode": 2141
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Ischaemia",
//     "ICD": "I63",
//     "ExchangeCode": 2141
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Oedema",
//     "ICD": "G93.6",
//     "ExchangeCode": 272
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebral Thrombosis",
//     "ICD": "I80",
//     "ExchangeCode": 275
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebrovascular Accident",
//     "ICD": "I63.9",
//     "ExchangeCode": 2142
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebrovascular Disease",
//     "ICD": "I67.9",
//     "ExchangeCode": 2150
//    },
//    {
//     "Group": "Other",
//     "Value": "Cerebrovascular Rupture",
//     "ICD": "I63.9",
//     "ExchangeCode": 2142
//    },
//    {
//     "Group": "Other",
//     "Value": "Cervical Spondylosis",
//     "ICD": "M47.8",
//     "ExchangeCode": 1905
//    },
//    {
//     "Group": "Other",
//     "Value": "Cervical Syndrome",
//     "ICD": "M54.2",
//     "ExchangeCode": 1907
//    },
//    {
//     "Group": "Other",
//     "Value": "Chest Pain",
//     "ICD": "R07",
//     "ExchangeCode": 1975
//    },
//    {
//     "Group": "Other",
//     "Value": "Childhood Psychosis",
//     "ICD": "F84",
//     "ExchangeCode": 287
//    },
//    {
//     "Group": "Other",
//     "Value": "Childhood Schizophrenia",
//     "ICD": "F20",
//     "ExchangeCode": 288
//    },
//    {
//     "Group": "Other",
//     "Value": "Choanal Atresia",
//     "ICD": "Q30.0",
//     "ExchangeCode": 2168
//    },
//    {
//     "Group": "Other",
//     "Value": "Cholangitis",
//     "ICD": "K83.0",
//     "ExchangeCode": 289
//    },
//    {
//     "Group": "Other",
//     "Value": "Cholangitis Acute",
//     "ICD": "K83",
//     "ExchangeCode": 290
//    },
//    {
//     "Group": "Other",
//     "Value": "Cholangitis Chronic",
//     "ICD": "K83",
//     "ExchangeCode": 291
//    },
//    {
//     "Group": "Other",
//     "Value": "Cholangitis Relapse",
//     "ICD": "K83",
//     "ExchangeCode": 292
//    },
//    {
//     "Group": "Other",
//     "Value": "Cholangitis Sclerosing",
//     "ICD": "K83",
//     "ExchangeCode": 293
//    },
//    {
//     "Group": "Other",
//     "Value": "Cholangitis Stenosing",
//     "ICD": "K83",
//     "ExchangeCode": 294
//    },
//    {
//     "Group": "Other",
//     "Value": "Cholecystitis",
//     "ICD": "K81",
//     "ExchangeCode": 1852
//    },
//    {
//     "Group": "Other",
//     "Value": "Cholesteatoma",
//     "ICD": "H71",
//     "ExchangeCode": 1820
//    },
//    {
//     "Group": "Other",
//     "Value": "Chondritis",
//     "ICD": "M94.8",
//     "ExchangeCode": 1920
//    },
//    {
//     "Group": "Other",
//     "Value": "Choroidal Detachment",
//     "ICD": "H31.4",
//     "ExchangeCode": 1810
//    },
//    {
//     "Group": "Other",
//     "Value": "Christmas Disease",
//     "ICD": "D67",
//     "ExchangeCode": 296
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Active Hepatitis",
//     "ICD": "K73.2",
//     "ExchangeCode": 1851
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Colitis",
//     "ICD": "K52.9",
//     "ExchangeCode": 328
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Glomerulonephritis",
//     "ICD": "N03",
//     "ExchangeCode": 298
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Hepatitis",
//     "ICD": "K73.9",
//     "ExchangeCode": 1851
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Hepatitis B",
//     "ICD": "B18",
//     "ExchangeCode": 1765
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Hepatitis C",
//     "ICD": "B18",
//     "ExchangeCode": 1765
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Kidney Disease",
//     "ICD": "N18",
//     "ExchangeCode": 1359
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Liver Disease",
//     "ICD": "K76.9",
//     "ExchangeCode": 1851
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Nephritis",
//     "ICD": "N03.9",
//     "ExchangeCode": 1114
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Osteomyelitis",
//     "ICD": "M86.6",
//     "ExchangeCode": 1918
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Pancreatitis",
//     "ICD": "K86.1",
//     "ExchangeCode": 309
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Prostatitis",
//     "ICD": "N41.1",
//     "ExchangeCode": 1947
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Renal Failure",
//     "ICD": "N18",
//     "ExchangeCode": 1359
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Renal Insufficiency",
//     "ICD": "N18.9",
//     "ExchangeCode": 1935
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Rheumatoid Arthritis",
//     "ICD": "M06.9",
//     "ExchangeCode": 1374
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Tubulo-Interstitial Nephritis, Unspecified",
//     "ICD": "N11.9",
//     "ExchangeCode": 2160
//    },
//    {
//     "Group": "Other",
//     "Value": "Chronic Vegetative State",
//     "ICD": "R40",
//     "ExchangeCode": 310
//    },
//    {
//     "Group": "Other",
//     "Value": "Churg Strauss Syndrome",
//     "ICD": "M30",
//     "ExchangeCode": 311
//    },
//    {
//     "Group": "Other",
//     "Value": "Cirrhosis",
//     "ICD": "K74.6",
//     "ExchangeCode": 693
//    },
//    {
//     "Group": "Other",
//     "Value": "Cirrhosis Biliary",
//     "ICD": "K74",
//     "ExchangeCode": 312
//    },
//    {
//     "Group": "Other",
//     "Value": "Cirrhosis Of The Liver",
//     "ICD": "K74.6",
//     "ExchangeCode": 693
//    },
//    {
//     "Group": "Other",
//     "Value": "Cirrhosis Portal",
//     "ICD": "K74.6",
//     "ExchangeCode": 313
//    },
//    {
//     "Group": "Other",
//     "Value": "Climacteric",
//     "ICD": "N95.1",
//     "ExchangeCode": 1960
//    },
//    {
//     "Group": "Other",
//     "Value": "CNS TB",
//     "ICD": "A17",
//     "ExchangeCode": 1736
//    },
//    {
//     "Group": "Other",
//     "Value": "Coagulation Defect",
//     "ICD": "D68",
//     "ExchangeCode": 322
//    },
//    {
//     "Group": "Other",
//     "Value": "Coagulopathy",
//     "ICD": "D68",
//     "ExchangeCode": 323
//    },
//    {
//     "Group": "Other",
//     "Value": "Coats Retinopathy",
//     "ICD": "H35.0",
//     "ExchangeCode": 1812
//    },
//    {
//     "Group": "Other",
//     "Value": "Coeliac Disease",
//     "ICD": "K90.0",
//     "ExchangeCode": 1484
//    },
//    {
//     "Group": "Other",
//     "Value": "Colectomy",
//     "ICD": "K63.9",
//     "ExchangeCode": 1848
//    },
//    {
//     "Group": "Other",
//     "Value": "Colic",
//     "ICD": "R10.4",
//     "ExchangeCode": 1977
//    },
//    {
//     "Group": "Other",
//     "Value": "Colitis",
//     "ICD": "A09",
//     "ExchangeCode": 1733
//    },
//    {
//     "Group": "Other",
//     "Value": "Colitis Chronic",
//     "ICD": "A09",
//     "ExchangeCode": 1733
//    },
//    {
//     "Group": "Other",
//     "Value": "Colitis Ulcerous",
//     "ICD": "K51",
//     "ExchangeCode": 328
//    },
//    {
//     "Group": "Other",
//     "Value": "Collagen Disease",
//     "ICD": "M35.9",
//     "ExchangeCode": 329
//    },
//    {
//     "Group": "Other",
//     "Value": "Coma",
//     "ICD": "R40.2",
//     "ExchangeCode": 331
//    },
//    {
//     "Group": "Other",
//     "Value": "Completely Paralysed",
//     "ICD": "G83",
//     "ExchangeCode": 335
//    },
//    {
//     "Group": "Other",
//     "Value": "Confusional State Acute",
//     "ICD": "F05",
//     "ExchangeCode": 338
//    },
//    {
//     "Group": "Other",
//     "Value": "Confusional State Reactive",
//     "ICD": "F05",
//     "ExchangeCode": 339
//    },
//    {
//     "Group": "Other",
//     "Value": "Congenital Clotting Disorder",
//     "ICD": "D68",
//     "ExchangeCode": 340
//    },
//    {
//     "Group": "Other",
//     "Value": "Congenital Hipjoint Dislocation",
//     "ICD": "Q65.2",
//     "ExchangeCode": 1968
//    },
//    {
//     "Group": "Other",
//     "Value": "Congenital Hypertrophic Pyloric Stenosis",
//     "ICD": "Q40.0",
//     "ExchangeCode": 1963
//    },
//    {
//     "Group": "Other",
//     "Value": "Congenital Immune Deficiency Syndrome",
//     "ICD": "D84",
//     "ExchangeCode": 341
//    },
//    {
//     "Group": "Other",
//     "Value": "Congenital Immunodeficiency",
//     "ICD": "D84",
//     "ExchangeCode": 342
//    },
//    {
//     "Group": "Other",
//     "Value": "Congenital Megacolon",
//     "ICD": "Q43.8",
//     "ExchangeCode": 1964
//    },
//    {
//     "Group": "Other",
//     "Value": "Conjunctivitis",
//     "ICD": "H10.9",
//     "ExchangeCode": 1806
//    },
//    {
//     "Group": "Other",
//     "Value": "Connective Tissue Disease",
//     "ICD": "M35.1",
//     "ExchangeCode": 348
//    },
//    {
//     "Group": "Other",
//     "Value": "Constipation",
//     "ICD": "K59.0",
//     "ExchangeCode": 1845
//    },
//    {
//     "Group": "Other",
//     "Value": "Corticoadrenal Hyperfunction",
//     "ICD": "E27",
//     "ExchangeCode": 364
//    },
//    {
//     "Group": "Other",
//     "Value": "Corticoadrenal Insufficiency",
//     "ICD": "E27",
//     "ExchangeCode": 365
//    },
//    {
//     "Group": "Other",
//     "Value": "Costochondritis",
//     "ICD": "M94.0",
//     "ExchangeCode": 1920
//    },
//    {
//     "Group": "Other",
//     "Value": "Coxa Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Cramp",
//     "ICD": "R25.2",
//     "ExchangeCode": 1985
//    },
//    {
//     "Group": "Other",
//     "Value": "Craniosynostosis",
//     "ICD": "Q75.0",
//     "ExchangeCode": 2170
//    },
//    {
//     "Group": "Other",
//     "Value": "CRF",
//     "ICD": "N18",
//     "ExchangeCode": 369
//    },
//    {
//     "Group": "Other",
//     "Value": "Crohns Disease",
//     "ICD": "K50.9",
//     "ExchangeCode": 370
//    },
//    {
//     "Group": "Other",
//     "Value": "Crohns Disease [Regional Enteritis]",
//     "ICD": "K50",
//     "ExchangeCode": 370
//    },
//    {
//     "Group": "Other",
//     "Value": "Crohns Disease Of Large Intestine",
//     "ICD": "K50.1",
//     "ExchangeCode": 370
//    },
//    {
//     "Group": "Other",
//     "Value": "Crystal Arthropathy",
//     "ICD": "M11",
//     "ExchangeCode": 371
//    },
//    {
//     "Group": "Other",
//     "Value": "Cushings Disease",
//     "ICD": "E24",
//     "ExchangeCode": 374
//    },
//    {
//     "Group": "Other",
//     "Value": "Cushings Syndrome",
//     "ICD": "E24",
//     "ExchangeCode": 375
//    },
//    {
//     "Group": "Other",
//     "Value": "Cyst On Liver",
//     "ICD": "K76.8",
//     "ExchangeCode": 556
//    },
//    {
//     "Group": "Other",
//     "Value": "Cystic Endometrial Hyperplasia",
//     "ICD": "N85.0",
//     "ExchangeCode": 1958
//    },
//    {
//     "Group": "Other",
//     "Value": "Cystitis",
//     "ICD": "N30.9",
//     "ExchangeCode": 1941
//    },
//    {
//     "Group": "Other",
//     "Value": "Deafness",
//     "ICD": "H91.9",
//     "ExchangeCode": 1823
//    },
//    {
//     "Group": "Other",
//     "Value": "Deep Vein Thrombosis",
//     "ICD": "I80.2",
//     "ExchangeCode": 1680
//    },
//    {
//     "Group": "Other",
//     "Value": "Deficiency Anaemia",
//     "ICD": "D50",
//     "ExchangeCode": 380
//    },
//    {
//     "Group": "Other",
//     "Value": "Degenerative Disc Disease",
//     "ICD": "M51.9",
//     "ExchangeCode": 1906
//    },
//    {
//     "Group": "Other",
//     "Value": "Degenerative Spinal Column Disease",
//     "ICD": "M47.9",
//     "ExchangeCode": 1905
//    },
//    {
//     "Group": "Other",
//     "Value": "Delirium, Unspecified",
//     "ICD": "F05.9",
//     "ExchangeCode": 2109
//    },
//    {
//     "Group": "Other",
//     "Value": "Depression",
//     "ICD": "F33",
//     "ExchangeCode": 2175
//    },
//    {
//     "Group": "Other",
//     "Value": "Depression Acute",
//     "ICD": "F33",
//     "ExchangeCode": 386
//    },
//    {
//     "Group": "Other",
//     "Value": "Depression Endogenous",
//     "ICD": "F33.2\/.3",
//     "ExchangeCode": 387
//    },
//    {
//     "Group": "Other",
//     "Value": "Depression Psychosis",
//     "ICD": "F33",
//     "ExchangeCode": 388
//    },
//    {
//     "Group": "Other",
//     "Value": "Depression Single Episode",
//     "ICD": "F30",
//     "ExchangeCode": 389
//    },
//    {
//     "Group": "Other",
//     "Value": "Depressive Episode, Unspecified",
//     "ICD": "F32.9",
//     "ExchangeCode": 2115
//    },
//    {
//     "Group": "Other",
//     "Value": "Dermatitis",
//     "ICD": "L30.9",
//     "ExchangeCode": 1874
//    },
//    {
//     "Group": "Other",
//     "Value": "Dermatitis Herpetiformis",
//     "ICD": "L13.0",
//     "ExchangeCode": 1859
//    },
//    {
//     "Group": "Other",
//     "Value": "Dermatomyositis",
//     "ICD": "M33.1",
//     "ExchangeCode": 390
//    },
//    {
//     "Group": "Other",
//     "Value": "Dermatosis",
//     "ICD": "L98.9",
//     "ExchangeCode": 1888
//    },
//    {
//     "Group": "Other",
//     "Value": "Diabetes Insipidus",
//     "ICD": "E23.2",
//     "ExchangeCode": 394
//    },
//    {
//     "Group": "Other",
//     "Value": "Diabetic Glomerulosclerosis",
//     "ICD": "E13.21",
//     "ExchangeCode": 405
//    },
//    {
//     "Group": "Other",
//     "Value": "Diplegia",
//     "ICD": "G83",
//     "ExchangeCode": 411
//    },
//    {
//     "Group": "Other",
//     "Value": "Disc Prolapse",
//     "ICD": "M51.2",
//     "ExchangeCode": 1906
//    },
//    {
//     "Group": "Other",
//     "Value": "Disorder Of Breast",
//     "ICD": "N64.9",
//     "ExchangeCode": 1953
//    },
//    {
//     "Group": "Other",
//     "Value": "Disorder Of Vestibular Function",
//     "ICD": "H81.9",
//     "ExchangeCode": 1822
//    },
//    {
//     "Group": "Other",
//     "Value": "Disorders Of Iron Metabolism",
//     "ICD": "E83.1",
//     "ExchangeCode": 634
//    },
//    {
//     "Group": "Other",
//     "Value": "Disorders Of Lipoprotein Metabolism And Other Lipidaemias",
//     "ICD": "E78",
//     "ExchangeCode": 2175
//    },
//    {
//     "Group": "Other",
//     "Value": "Diverticulitis",
//     "ICD": "K57.9",
//     "ExchangeCode": 1843
//    },
//    {
//     "Group": "Other",
//     "Value": "DLE",
//     "ICD": "L93",
//     "ExchangeCode": 416
//    },
//    {
//     "Group": "Other",
//     "Value": "Double Lung Transplant",
//     "ICD": "Z94",
//     "ExchangeCode": 420
//    },
//    {
//     "Group": "Other",
//     "Value": "Downs Syndrome",
//     "ICD": "Q90",
//     "ExchangeCode": 421
//    },
//    {
//     "Group": "Other",
//     "Value": "Drug Dependence",
//     "ICD": "F19.2",
//     "ExchangeCode": 1803
//    },
//    {
//     "Group": "Other",
//     "Value": "Duodenal Ulcer",
//     "ICD": "K26.9",
//     "ExchangeCode": 1831
//    },
//    {
//     "Group": "Other",
//     "Value": "Duodenal Ulcer With Perforation",
//     "ICD": "K26.5",
//     "ExchangeCode": 1831
//    },
//    {
//     "Group": "Other",
//     "Value": "Duodenitis",
//     "ICD": "K29.8",
//     "ExchangeCode": 500
//    },
//    {
//     "Group": "Other",
//     "Value": "Duodenum Anomaly",
//     "ICD": "Q43.9",
//     "ExchangeCode": 1964
//    },
//    {
//     "Group": "Other",
//     "Value": "Dupuytrens Contracture",
//     "ICD": "M72.0",
//     "ExchangeCode": 1912
//    },
//    {
//     "Group": "Other",
//     "Value": "DVT Deep Vein Thrombosis",
//     "ICD": "I80.2",
//     "ExchangeCode": 1680
//    },
//    {
//     "Group": "Other",
//     "Value": "Dwarfism",
//     "ICD": "Q77.4",
//     "ExchangeCode": 429
//    },
//    {
//     "Group": "Other",
//     "Value": "Dyspepsia",
//     "ICD": "K30",
//     "ExchangeCode": 1834
//    },
//    {
//     "Group": "Other",
//     "Value": "Dysphagia",
//     "ICD": "R13",
//     "ExchangeCode": 1979
//    },
//    {
//     "Group": "Other",
//     "Value": "Dystonia",
//     "ICD": "G24",
//     "ExchangeCode": 1550
//    },
//    {
//     "Group": "Other",
//     "Value": "Ear Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Ectopic Kidney",
//     "ICD": "Q63.2",
//     "ExchangeCode": 1967
//    },
//    {
//     "Group": "Other",
//     "Value": "Ectopic Pregnancy",
//     "ICD": "O00.9",
//     "ExchangeCode": 1961
//    },
//    {
//     "Group": "Other",
//     "Value": "Eczema",
//     "ICD": "L30.9",
//     "ExchangeCode": 1874
//    },
//    {
//     "Group": "Other",
//     "Value": "Edwards Syndrome",
//     "ICD": "Q91",
//     "ExchangeCode": 437
//    },
//    {
//     "Group": "Other",
//     "Value": "Embolism Arterial Peripheral",
//     "ICD": "I74",
//     "ExchangeCode": 440
//    },
//    {
//     "Group": "Other",
//     "Value": "Emotional State Reactive",
//     "ICD": "F30",
//     "ExchangeCode": 441
//    },
//    {
//     "Group": "Other",
//     "Value": "Encephalocele",
//     "ICD": "Q01",
//     "ExchangeCode": 444
//    },
//    {
//     "Group": "Other",
//     "Value": "End Stage Renal Failure",
//     "ICD": "N18",
//     "ExchangeCode": 446
//    },
//    {
//     "Group": "Other",
//     "Value": "Endocarditis Tuberculous",
//     "ICD": "A18.8",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Endocardium Tuberculosis",
//     "ICD": "A18.8",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Endocrine Disorder",
//     "ICD": "E34.9",
//     "ExchangeCode": 413
//    },
//    {
//     "Group": "Other",
//     "Value": "Endometriosis",
//     "ICD": "N80.9",
//     "ExchangeCode": 1954
//    },
//    {
//     "Group": "Other",
//     "Value": "Enlarged Liver",
//     "ICD": "R16.0",
//     "ExchangeCode": 1980
//    },
//    {
//     "Group": "Other",
//     "Value": "Enlarged Prostate",
//     "ICD": "N40",
//     "ExchangeCode": 1946
//    },
//    {
//     "Group": "Other",
//     "Value": "Enlarged Spleen",
//     "ICD": "R16.1",
//     "ExchangeCode": 454
//    },
//    {
//     "Group": "Other",
//     "Value": "Enlarged Thyroid",
//     "ICD": "E04.2",
//     "ExchangeCode": 1787
//    },
//    {
//     "Group": "Other",
//     "Value": "Enteritis",
//     "ICD": "K52.9",
//     "ExchangeCode": 455
//    },
//    {
//     "Group": "Other",
//     "Value": "Enteritis Chronic",
//     "ICD": "K52",
//     "ExchangeCode": 455
//    },
//    {
//     "Group": "Other",
//     "Value": "Enteritis Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Enteritis Ulcerous",
//     "ICD": "K52",
//     "ExchangeCode": 457
//    },
//    {
//     "Group": "Other",
//     "Value": "Eosinophilia",
//     "ICD": "D72.1",
//     "ExchangeCode": 458
//    },
//    {
//     "Group": "Other",
//     "Value": "Epidermal Thickening",
//     "ICD": "L85.9",
//     "ExchangeCode": 1885
//    },
//    {
//     "Group": "Other",
//     "Value": "Epididymal TB",
//     "ICD": "A18.1",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Epididymis Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Epistaxis",
//     "ICD": "R04.0",
//     "ExchangeCode": 1974
//    },
//    {
//     "Group": "Other",
//     "Value": "Erosion And Ectropion Of Cervix Uteri",
//     "ICD": "N86",
//     "ExchangeCode": 2163
//    },
//    {
//     "Group": "Other",
//     "Value": "Erythema",
//     "ICD": "L53.9",
//     "ExchangeCode": 1878
//    },
//    {
//     "Group": "Other",
//     "Value": "Esophageal Ulcer",
//     "ICD": "K22.1",
//     "ExchangeCode": 1829
//    },
//    {
//     "Group": "Other",
//     "Value": "Esophageal Varices",
//     "ICD": "I85.9",
//     "ExchangeCode": 1685
//    },
//    {
//     "Group": "Other",
//     "Value": "Esophagus Anomaly",
//     "ICD": "Q39.9",
//     "ExchangeCode": 1962
//    },
//    {
//     "Group": "Other",
//     "Value": "Esophagus Rupture",
//     "ICD": "K22.3",
//     "ExchangeCode": 1829
//    },
//    {
//     "Group": "Other",
//     "Value": "Esrd",
//     "ICD": "N18.5",
//     "ExchangeCode": 462
//    },
//    {
//     "Group": "Other",
//     "Value": "Exudative Retinopathy",
//     "ICD": "H35.0",
//     "ExchangeCode": 1812
//    },
//    {
//     "Group": "Other",
//     "Value": "Eye TB",
//     "ICD": "A18.5",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Eye Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Factor IX Deficiency",
//     "ICD": "D67",
//     "ExchangeCode": 475
//    },
//    {
//     "Group": "Other",
//     "Value": "Factor VIII Deficiency",
//     "ICD": "D66",
//     "ExchangeCode": 476
//    },
//    {
//     "Group": "Other",
//     "Value": "Factor XI Deficiency",
//     "ICD": "D68.1",
//     "ExchangeCode": 477
//    },
//    {
//     "Group": "Other",
//     "Value": "Fanconis Anaemia",
//     "ICD": "D61",
//     "ExchangeCode": 480
//    },
//    {
//     "Group": "Other",
//     "Value": "Fasciitis",
//     "ICD": "M72.5",
//     "ExchangeCode": 1912
//    },
//    {
//     "Group": "Other",
//     "Value": "Fatty Liver",
//     "ICD": "K76.0",
//     "ExchangeCode": 1850
//    },
//    {
//     "Group": "Other",
//     "Value": "Fatty Liver Alcoholic",
//     "ICD": "K76",
//     "ExchangeCode": 481
//    },
//    {
//     "Group": "Other",
//     "Value": "Fatty Liver Degeneration",
//     "ICD": "K76",
//     "ExchangeCode": 482
//    },
//    {
//     "Group": "Other",
//     "Value": "Fibrocystic Breast Disease",
//     "ICD": "N60",
//     "ExchangeCode": 1952
//    },
//    {
//     "Group": "Other",
//     "Value": "Fibrocystic Disease",
//     "ICD": "E84.9",
//     "ExchangeCode": 484
//    },
//    {
//     "Group": "Other",
//     "Value": "Fibrocystic Kidney",
//     "ICD": "Q61.8",
//     "ExchangeCode": 658
//    },
//    {
//     "Group": "Other",
//     "Value": "Fibromyalgia",
//     "ICD": "M79.0",
//     "ExchangeCode": 1915
//    },
//    {
//     "Group": "Other",
//     "Value": "Fibrositis",
//     "ICD": "M79.0",
//     "ExchangeCode": 1915
//    },
//    {
//     "Group": "Other",
//     "Value": "Fistula",
//     "ICD": "L98.8",
//     "ExchangeCode": 1888
//    },
//    {
//     "Group": "Other",
//     "Value": "Fluid Stomach",
//     "ICD": "R18",
//     "ExchangeCode": 134
//    },
//    {
//     "Group": "Other",
//     "Value": "Folate Deficiency Anaemia",
//     "ICD": "D52",
//     "ExchangeCode": 490
//    },
//    {
//     "Group": "Other",
//     "Value": "Fracture Of Neck, Part Unspecified",
//     "ICD": "S12.9",
//     "ExchangeCode": 209
//    },
//    {
//     "Group": "Other",
//     "Value": "Frozen Shoulder",
//     "ICD": "M75.0",
//     "ExchangeCode": 1913
//    },
//    {
//     "Group": "Other",
//     "Value": "Full Blown AIDS",
//     "ICD": "B24",
//     "ExchangeCode": 8
//    },
//    {
//     "Group": "Other",
//     "Value": "Gallbladder Disorder",
//     "ICD": "K82.8",
//     "ExchangeCode": 1852
//    },
//    {
//     "Group": "Other",
//     "Value": "Gallbladder Removal",
//     "ICD": "K82.9",
//     "ExchangeCode": 1852
//    },
//    {
//     "Group": "Other",
//     "Value": "Gallstone",
//     "ICD": "K80.2",
//     "ExchangeCode": 1852
//    },
//    {
//     "Group": "Other",
//     "Value": "Gangrene",
//     "ICD": "R02",
//     "ExchangeCode": 1973
//    },
//    {
//     "Group": "Other",
//     "Value": "Gastrectomy",
//     "ICD": "K31.9Op",
//     "ExchangeCode": 1836
//    },
//    {
//     "Group": "Other",
//     "Value": "Gastric Ulcer",
//     "ICD": "K25.9",
//     "ExchangeCode": 1830
//    },
//    {
//     "Group": "Other",
//     "Value": "Gastritis",
//     "ICD": "K29.7",
//     "ExchangeCode": 500
//    },
//    {
//     "Group": "Other",
//     "Value": "Gastritis Due To Alcohol",
//     "ICD": "K29",
//     "ExchangeCode": 500
//    },
//    {
//     "Group": "Other",
//     "Value": "Gastroenteritis",
//     "ICD": "K52.9",
//     "ExchangeCode": 455
//    },
//    {
//     "Group": "Other",
//     "Value": "Gastrojejunal Ulcer With Perforation",
//     "ICD": "K28.5",
//     "ExchangeCode": 1833
//    },
//    {
//     "Group": "Other",
//     "Value": "Gauchers Disease",
//     "ICD": "E75",
//     "ExchangeCode": 501
//    },
//    {
//     "Group": "Other",
//     "Value": "Genital TB",
//     "ICD": "A18.1",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Genitals Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Genitourinary Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Giant Cell Arteritis",
//     "ICD": "M31.6",
//     "ExchangeCode": 1898
//    },
//    {
//     "Group": "Other",
//     "Value": "Gilberts Disease",
//     "ICD": "E80.4",
//     "ExchangeCode": 1799
//    },
//    {
//     "Group": "Other",
//     "Value": "Gland Inflammation Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Glaucoma",
//     "ICD": "H40.9",
//     "ExchangeCode": 1813
//    },
//    {
//     "Group": "Other",
//     "Value": "Glomerulonephritis",
//     "ICD": "N05.9",
//     "ExchangeCode": 12
//    },
//    {
//     "Group": "Other",
//     "Value": "Glomerulosclerosis",
//     "ICD": "N8",
//     "ExchangeCode": 508
//    },
//    {
//     "Group": "Other",
//     "Value": "Glomerulosclerosis Nondiabetic",
//     "ICD": "N8",
//     "ExchangeCode": 509
//    },
//    {
//     "Group": "Other",
//     "Value": "Glomerulosclerosis Postpartum",
//     "ICD": "N8",
//     "ExchangeCode": 510
//    },
//    {
//     "Group": "Other",
//     "Value": "Goitre",
//     "ICD": "E04.9",
//     "ExchangeCode": 1787
//    },
//    {
//     "Group": "Other",
//     "Value": "Gout",
//     "ICD": "M10.9",
//     "ExchangeCode": 1890
//    },
//    {
//     "Group": "Other",
//     "Value": "Granuloma",
//     "ICD": "L92.9",
//     "ExchangeCode": 1886
//    },
//    {
//     "Group": "Other",
//     "Value": "Granulomatous Reticulosis",
//     "ICD": "D71",
//     "ExchangeCode": 513
//    },
//    {
//     "Group": "Other",
//     "Value": "Graves Disease",
//     "ICD": "E05.0",
//     "ExchangeCode": 1788
//    },
//    {
//     "Group": "Other",
//     "Value": "Haematemesis",
//     "ICD": "K92.0",
//     "ExchangeCode": 1853
//    },
//    {
//     "Group": "Other",
//     "Value": "Haematuria",
//     "ICD": "R31",
//     "ExchangeCode": 1988
//    },
//    {
//     "Group": "Other",
//     "Value": "Haemochromatosis",
//     "ICD": "E83.1",
//     "ExchangeCode": 515
//    },
//    {
//     "Group": "Other",
//     "Value": "Haemophilia",
//     "ICD": "D66",
//     "ExchangeCode": 517
//    },
//    {
//     "Group": "Other",
//     "Value": "Haemophilia A",
//     "ICD": "D66",
//     "ExchangeCode": 517
//    },
//    {
//     "Group": "Other",
//     "Value": "Haemophilia B",
//     "ICD": "D66",
//     "ExchangeCode": 517
//    },
//    {
//     "Group": "Other",
//     "Value": "Haemorrhage Intracerebral",
//     "ICD": "I61",
//     "ExchangeCode": 2139
//    },
//    {
//     "Group": "Other",
//     "Value": "Haemorrhage Meningeal",
//     "ICD": "I60",
//     "ExchangeCode": 1494
//    },
//    {
//     "Group": "Other",
//     "Value": "Haemorrhagic Anticoagulant Disorder",
//     "ICD": "D68",
//     "ExchangeCode": 520
//    },
//    {
//     "Group": "Other",
//     "Value": "Hand Schuller Christian Disease",
//     "ICD": "D76",
//     "ExchangeCode": 524
//    },
//    {
//     "Group": "Other",
//     "Value": "Head Injury",
//     "ICD": "S09.9",
//     "ExchangeCode": 2011
//    },
//    {
//     "Group": "Other",
//     "Value": "Heart Amyloidosis",
//     "ICD": "E85",
//     "ExchangeCode": 65
//    },
//    {
//     "Group": "Other",
//     "Value": "Heart Transplant",
//     "ICD": "Z94.1",
//     "ExchangeCode": 542
//    },
//    {
//     "Group": "Other",
//     "Value": "Heartburn",
//     "ICD": "R12",
//     "ExchangeCode": 1978
//    },
//    {
//     "Group": "Other",
//     "Value": "Hemicolectomy",
//     "ICD": "K63.9",
//     "ExchangeCode": 1848
//    },
//    {
//     "Group": "Other",
//     "Value": "Henoch Schonlein",
//     "ICD": "D69.0",
//     "ExchangeCode": 1333
//    },
//    {
//     "Group": "Other",
//     "Value": "Hep B",
//     "ICD": "B16",
//     "ExchangeCode": 1763
//    },
//    {
//     "Group": "Other",
//     "Value": "Hep C",
//     "ICD": "B17",
//     "ExchangeCode": 1764
//    },
//    {
//     "Group": "Other",
//     "Value": "Hepatic Cirrhosis",
//     "ICD": "B16",
//     "ExchangeCode": 1763
//    },
//    {
//     "Group": "Other",
//     "Value": "Hepatitis",
//     "ICD": "B15.9",
//     "ExchangeCode": 1762
//    },
//    {
//     "Group": "Other",
//     "Value": "Hepatitis A",
//     "ICD": "B15.9",
//     "ExchangeCode": 1762
//    },
//    {
//     "Group": "Other",
//     "Value": "Hepatitis Alcohol Induced",
//     "ICD": "K70",
//     "ExchangeCode": 551
//    },
//    {
//     "Group": "Other",
//     "Value": "Hepatitis B",
//     "ICD": "B16",
//     "ExchangeCode": 1763
//    },
//    {
//     "Group": "Other",
//     "Value": "Hepatitis C",
//     "ICD": "B17",
//     "ExchangeCode": 1764
//    },
//    {
//     "Group": "Other",
//     "Value": "Hepatitis Unspecified",
//     "ICD": "B15.9",
//     "ExchangeCode": 1762
//    },
//    {
//     "Group": "Other",
//     "Value": "Hepatomegaly",
//     "ICD": "R16",
//     "ExchangeCode": 555
//    },
//    {
//     "Group": "Other",
//     "Value": "Hepatosis",
//     "ICD": "K76.8",
//     "ExchangeCode": 556
//    },
//    {
//     "Group": "Other",
//     "Value": "Hereditary Clotting Disorder",
//     "ICD": "D66",
//     "ExchangeCode": 517
//    },
//    {
//     "Group": "Other",
//     "Value": "Hereditary Elliptocytosis",
//     "ICD": "D58",
//     "ExchangeCode": 558
//    },
//    {
//     "Group": "Other",
//     "Value": "Hereditary Factor Viii Deficiency",
//     "ICD": "D66",
//     "ExchangeCode": 517
//    },
//    {
//     "Group": "Other",
//     "Value": "Hereditary Spherocytosis",
//     "ICD": "D58",
//     "ExchangeCode": 559
//    },
//    {
//     "Group": "Other",
//     "Value": "Hernia",
//     "ICD": "K46.9",
//     "ExchangeCode": 1841
//    },
//    {
//     "Group": "Other",
//     "Value": "Hiatus Hernia",
//     "ICD": "K44.9",
//     "ExchangeCode": 1840
//    },
//    {
//     "Group": "Other",
//     "Value": "High Triglycerides",
//     "ICD": "E78",
//     "ExchangeCode": 1797
//    },
//    {
//     "Group": "Other",
//     "Value": "Hip Joint Dysplasia",
//     "ICD": "Q65.8",
//     "ExchangeCode": 1968
//    },
//    {
//     "Group": "Other",
//     "Value": "Hip Movement Restriction",
//     "ICD": "R29.8",
//     "ExchangeCode": 1987
//    },
//    {
//     "Group": "Other",
//     "Value": "Hip TB",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Hirschsprungs Disease",
//     "ICD": "Q43.1",
//     "ExchangeCode": 1964
//    },
//    {
//     "Group": "Other",
//     "Value": "HIV",
//     "ICD": "Z21",
//     "ExchangeCode": 562
//    },
//    {
//     "Group": "Other",
//     "Value": "HIV Positive",
//     "ICD": "Z21",
//     "ExchangeCode": 562
//    },
//    {
//     "Group": "Other",
//     "Value": "Horseshoe Kidney",
//     "ICD": "Q63.1",
//     "ExchangeCode": 1967
//    },
//    {
//     "Group": "Other",
//     "Value": "Human Immunodeficiency Virus",
//     "ICD": "Z21",
//     "ExchangeCode": 568
//    },
//    {
//     "Group": "Other",
//     "Value": "Hydrocele",
//     "ICD": "N43.3",
//     "ExchangeCode": 1949
//    },
//    {
//     "Group": "Other",
//     "Value": "Hydrocephalus",
//     "ICD": "G91",
//     "ExchangeCode": 570
//    },
//    {
//     "Group": "Other",
//     "Value": "Hydronephrosis",
//     "ICD": "N13.3",
//     "ExchangeCode": 571
//    },
//    {
//     "Group": "Other",
//     "Value": "Hyperaldosteronism",
//     "ICD": "E34",
//     "ExchangeCode": 572
//    },
//    {
//     "Group": "Other",
//     "Value": "Hyperglobulinaemia",
//     "ICD": "R77",
//     "ExchangeCode": 573
//    },
//    {
//     "Group": "Other",
//     "Value": "Hyperlipidaemia",
//     "ICD": "E78",
//     "ExchangeCode": 1797
//    },
//    {
//     "Group": "Other",
//     "Value": "Hyperparathyroidism",
//     "ICD": "E21.3",
//     "ExchangeCode": 1791
//    },
//    {
//     "Group": "Other",
//     "Value": "Hyperpituitarism",
//     "ICD": "E22",
//     "ExchangeCode": 578
//    },
//    {
//     "Group": "Other",
//     "Value": "Hyperplasia Of Prostate",
//     "ICD": "N40",
//     "ExchangeCode": 1946
//    },
//    {
//     "Group": "Other",
//     "Value": "Hyperprolactinaemia",
//     "ICD": "E22",
//     "ExchangeCode": 579
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypersplenism",
//     "ICD": "D73",
//     "ExchangeCode": 580
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypertension Portal",
//     "ICD": "K76.6",
//     "ExchangeCode": 581
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypertensive Renal Disease",
//     "ICD": "I12",
//     "ExchangeCode": 584
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypertensive Renal Disease Without Renal Failure",
//     "ICD": "I12.9",
//     "ExchangeCode": 2130
//    },
//    {
//     "Group": "Other",
//     "Value": "Hyperthyroidism",
//     "ICD": "E05.9",
//     "ExchangeCode": 1788
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypertonia Malignant",
//     "ICD": "P94.1",
//     "ExchangeCode": 585
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypertriglyceridaemi",
//     "ICD": "E78",
//     "ExchangeCode": 1797
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypertriglyceridaemi Familial",
//     "ICD": "E78",
//     "ExchangeCode": 1797
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypertriglyceridaemia",
//     "ICD": "E78.1",
//     "ExchangeCode": 1797
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypertriglyceridaemia Familial",
//     "ICD": "E78",
//     "ExchangeCode": 1797
//    },
//    {
//     "Group": "Other",
//     "Value": "Hyperuricaemia",
//     "ICD": "E79.0",
//     "ExchangeCode": 1798
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypocalcemia",
//     "ICD": "E83.5",
//     "ExchangeCode": 1800
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypogonadism Male",
//     "ICD": "E29.1",
//     "ExchangeCode": 1793
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypomania",
//     "ICD": "F30.0",
//     "ExchangeCode": 593
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypopituitarism",
//     "ICD": "E23.0",
//     "ExchangeCode": 594
//    },
//    {
//     "Group": "Other",
//     "Value": "Hypothyroidism",
//     "ICD": "E03.9",
//     "ExchangeCode": 1786
//    },
//    {
//     "Group": "Other",
//     "Value": "Ichthyosis",
//     "ICD": "Q80.9",
//     "ExchangeCode": 1971
//    },
//    {
//     "Group": "Other",
//     "Value": "Idiopathic Thrombocytopaenia Purpura",
//     "ICD": "D69",
//     "ExchangeCode": 601
//    },
//    {
//     "Group": "Other",
//     "Value": "Idiopathic Thrombocytopenic Purpura",
//     "ICD": "D69.3",
//     "ExchangeCode": 1333
//    },
//    {
//     "Group": "Other",
//     "Value": "Iga Nephropathy",
//     "ICD": "N05",
//     "ExchangeCode": 602
//    },
//    {
//     "Group": "Other",
//     "Value": "Immune Deficiency Acquired",
//     "ICD": "D84",
//     "ExchangeCode": 606
//    },
//    {
//     "Group": "Other",
//     "Value": "Immunity Deficiency",
//     "ICD": "D84",
//     "ExchangeCode": 607
//    },
//    {
//     "Group": "Other",
//     "Value": "Immunodeficiency",
//     "ICD": "D84.9",
//     "ExchangeCode": 607
//    },
//    {
//     "Group": "Other",
//     "Value": "Indigestion",
//     "ICD": "K30",
//     "ExchangeCode": 1834
//    },
//    {
//     "Group": "Other",
//     "Value": "Induced Delusional Disorder",
//     "ICD": "F24",
//     "ExchangeCode": 1304
//    },
//    {
//     "Group": "Other",
//     "Value": "Infected Bile Duct",
//     "ICD": "K83",
//     "ExchangeCode": 611
//    },
//    {
//     "Group": "Other",
//     "Value": "Inflammatory Bowel Disease",
//     "ICD": "A08.4",
//     "ExchangeCode": 1722
//    },
//    {
//     "Group": "Other",
//     "Value": "Inflammatory Polyarthropathy",
//     "ICD": "M06.4",
//     "ExchangeCode": 614
//    },
//    {
//     "Group": "Other",
//     "Value": "Inflammatory Prostate Disease",
//     "ICD": "N41.9",
//     "ExchangeCode": 1947
//    },
//    {
//     "Group": "Other",
//     "Value": "Inflammed Bile Duct",
//     "ICD": "K83",
//     "ExchangeCode": 615
//    },
//    {
//     "Group": "Other",
//     "Value": "Inguinal Hernia",
//     "ICD": "K40.9",
//     "ExchangeCode": 1838
//    },
//    {
//     "Group": "Other",
//     "Value": "Insufficiency Cerebrovascular",
//     "ICD": "I67",
//     "ExchangeCode": 2147
//    },
//    {
//     "Group": "Other",
//     "Value": "Insulin Deficiency Postoperative",
//     "ICD": "E89.1",
//     "ExchangeCode": 619
//    },
//    {
//     "Group": "Other",
//     "Value": "Intermittent Claudication",
//     "ICD": "I73",
//     "ExchangeCode": 622
//    },
//    {
//     "Group": "Other",
//     "Value": "Interstitial Cystitis",
//     "ICD": "N30.1",
//     "ExchangeCode": 1941
//    },
//    {
//     "Group": "Other",
//     "Value": "Intestinal Abscess",
//     "ICD": "K63.0",
//     "ExchangeCode": 1848
//    },
//    {
//     "Group": "Other",
//     "Value": "Intestinal Adhesion",
//     "ICD": "K66.0",
//     "ExchangeCode": 1849
//    },
//    {
//     "Group": "Other",
//     "Value": "Intestinal Adhesions",
//     "ICD": "K66.0",
//     "ExchangeCode": 1849
//    },
//    {
//     "Group": "Other",
//     "Value": "Intestinal Malabsorption",
//     "ICD": "K90.9",
//     "ExchangeCode": 1484
//    },
//    {
//     "Group": "Other",
//     "Value": "Intestinal TB",
//     "ICD": "A18.3",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Intestinal Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Intestine Obstruction",
//     "ICD": "K56.7",
//     "ExchangeCode": 1842
//    },
//    {
//     "Group": "Other",
//     "Value": "Intestine Operation",
//     "ICD": "K63.9Op",
//     "ExchangeCode": 1848
//    },
//    {
//     "Group": "Other",
//     "Value": "Intestine Tuberculosis",
//     "ICD": "A18.3",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Intestine Twisting",
//     "ICD": "K56.2",
//     "ExchangeCode": 1842
//    },
//    {
//     "Group": "Other",
//     "Value": "Intracranial Bleed",
//     "ICD": "I60",
//     "ExchangeCode": 1494
//    },
//    {
//     "Group": "Other",
//     "Value": "Intracranial Haemorrhage",
//     "ICD": "I60",
//     "ExchangeCode": 1494
//    },
//    {
//     "Group": "Other",
//     "Value": "Iridocyclitis",
//     "ICD": "H20.9",
//     "ExchangeCode": 1807
//    },
//    {
//     "Group": "Other",
//     "Value": "Iritis",
//     "ICD": "H20.0",
//     "ExchangeCode": 1807
//    },
//    {
//     "Group": "Other",
//     "Value": "Iron Deficiency Anaemia",
//     "ICD": "D50.9",
//     "ExchangeCode": 633
//    },
//    {
//     "Group": "Other",
//     "Value": "Iron Metabolism Disorder",
//     "ICD": "E83.1",
//     "ExchangeCode": 634
//    },
//    {
//     "Group": "Other",
//     "Value": "Irritable Bladder",
//     "ICD": "N32.9",
//     "ExchangeCode": 1942
//    },
//    {
//     "Group": "Other",
//     "Value": "Irritable Bowel Syndrome",
//     "ICD": "K58.9",
//     "ExchangeCode": 1844
//    },
//    {
//     "Group": "Other",
//     "Value": "Ischemia Cerebral",
//     "ICD": "I67",
//     "ExchangeCode": 2146
//    },
//    {
//     "Group": "Other",
//     "Value": "ITP",
//     "ICD": "D69",
//     "ExchangeCode": 639
//    },
//    {
//     "Group": "Other",
//     "Value": "Jaundice",
//     "ICD": "R17",
//     "ExchangeCode": 1981
//    },
//    {
//     "Group": "Other",
//     "Value": "Joint TB",
//     "ICD": "A18.0",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Joint Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Juvenile Polyarthritis",
//     "ICD": "M08",
//     "ExchangeCode": 650
//    },
//    {
//     "Group": "Other",
//     "Value": "Keratosis",
//     "ICD": "L57.0",
//     "ExchangeCode": 1880
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Amyloid",
//     "ICD": "E85",
//     "ExchangeCode": 652
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Anomaly",
//     "ICD": "Q63.9",
//     "ExchangeCode": 1967
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Calculus Removal",
//     "ICD": "N20.0",
//     "ExchangeCode": 1937
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Cyst",
//     "ICD": "Q61.9",
//     "ExchangeCode": 658
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Disease",
//     "ICD": "N28.9",
//     "ExchangeCode": 1940
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Failure",
//     "ICD": "N19",
//     "ExchangeCode": 654
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Function Disorder",
//     "ICD": "N28.9",
//     "ExchangeCode": 655
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Hypertension",
//     "ICD": "I12",
//     "ExchangeCode": 656
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Infarct",
//     "ICD": "N28.0",
//     "ExchangeCode": 1940
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Infection",
//     "ICD": "N15.9",
//     "ExchangeCode": 1933
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Polycystic",
//     "ICD": "Q61.3",
//     "ExchangeCode": 658
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Stone",
//     "ICD": "N20.0",
//     "ExchangeCode": 1937
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Stone Removal",
//     "ICD": "N20.0",
//     "ExchangeCode": 1937
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Transplant",
//     "ICD": "Z94.0",
//     "ExchangeCode": 660
//    },
//    {
//     "Group": "Other",
//     "Value": "Kidney Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Klinefelters Syndrome",
//     "ICD": "Q98.4",
//     "ExchangeCode": 1972
//    },
//    {
//     "Group": "Other",
//     "Value": "Knee Joint Damage",
//     "ICD": "M23.8",
//     "ExchangeCode": 1894
//    },
//    {
//     "Group": "Other",
//     "Value": "Knee TB",
//     "ICD": "A18.0",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Knee Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Kyphoscoliosis",
//     "ICD": "M41.9",
//     "ExchangeCode": 1400
//    },
//    {
//     "Group": "Other",
//     "Value": "Kyphosis",
//     "ICD": "M40.2",
//     "ExchangeCode": 1900
//    },
//    {
//     "Group": "Other",
//     "Value": "Labyrinthitis",
//     "ICD": "H83.0",
//     "ExchangeCode": 1822
//    },
//    {
//     "Group": "Other",
//     "Value": "Lactose Intolerance",
//     "ICD": "E73.9",
//     "ExchangeCode": 1796
//    },
//    {
//     "Group": "Other",
//     "Value": "Large Bowel Obstruction",
//     "ICD": "K56.6",
//     "ExchangeCode": 1842
//    },
//    {
//     "Group": "Other",
//     "Value": "Leg Monoplegia",
//     "ICD": "G83",
//     "ExchangeCode": 675
//    },
//    {
//     "Group": "Other",
//     "Value": "Leg Paralysis",
//     "ICD": "G83",
//     "ExchangeCode": 677
//    },
//    {
//     "Group": "Other",
//     "Value": "Leg Paraplegia",
//     "ICD": "G83",
//     "ExchangeCode": 678
//    },
//    {
//     "Group": "Other",
//     "Value": "Leg Ulcer",
//     "ICD": "L97",
//     "ExchangeCode": 1887
//    },
//    {
//     "Group": "Other",
//     "Value": "Legs Paralysis",
//     "ICD": "G83",
//     "ExchangeCode": 679
//    },
//    {
//     "Group": "Other",
//     "Value": "Leprosy",
//     "ICD": "A30.9",
//     "ExchangeCode": 1741
//    },
//    {
//     "Group": "Other",
//     "Value": "Leucocytosis",
//     "ICD": "D72.8",
//     "ExchangeCode": 681
//    },
//    {
//     "Group": "Other",
//     "Value": "Leucodystrophy",
//     "ICD": "E75",
//     "ExchangeCode": 682
//    },
//    {
//     "Group": "Other",
//     "Value": "Leukocytosis",
//     "ICD": "D72.8",
//     "ExchangeCode": 684
//    },
//    {
//     "Group": "Other",
//     "Value": "Leukodystrophy",
//     "ICD": "E75",
//     "ExchangeCode": 685
//    },
//    {
//     "Group": "Other",
//     "Value": "Leukoplakia",
//     "ICD": "L98.9",
//     "ExchangeCode": 1888
//    },
//    {
//     "Group": "Other",
//     "Value": "Lichen",
//     "ICD": "L28.0",
//     "ExchangeCode": 1872
//    },
//    {
//     "Group": "Other",
//     "Value": "Lichen Planus",
//     "ICD": "L43.9",
//     "ExchangeCode": 1876
//    },
//    {
//     "Group": "Other",
//     "Value": "Lipid Metabolism Disorder",
//     "ICD": "E78.9",
//     "ExchangeCode": 1797
//    },
//    {
//     "Group": "Other",
//     "Value": "Lipidosis Cerebral",
//     "ICD": "E75",
//     "ExchangeCode": 689
//    },
//    {
//     "Group": "Other",
//     "Value": "Lipometabolism Disorder",
//     "ICD": "E78.9",
//     "ExchangeCode": 1797
//    },
//    {
//     "Group": "Other",
//     "Value": "Lipometabolism Disorder Familial",
//     "ICD": "E78",
//     "ExchangeCode": 1797
//    },
//    {
//     "Group": "Other",
//     "Value": "Liver Cirrhosis",
//     "ICD": "K74.6",
//     "ExchangeCode": 693
//    },
//    {
//     "Group": "Other",
//     "Value": "Liver Cirrhosis Alcohol",
//     "ICD": "K70",
//     "ExchangeCode": 694
//    },
//    {
//     "Group": "Other",
//     "Value": "Liver Disease",
//     "ICD": "K76.9",
//     "ExchangeCode": 1851
//    },
//    {
//     "Group": "Other",
//     "Value": "Liver Transplant",
//     "ICD": "Z94.4",
//     "ExchangeCode": 2056
//    },
//    {
//     "Group": "Other",
//     "Value": "Localised Scleroderma",
//     "ICD": "L94",
//     "ExchangeCode": 695
//    },
//    {
//     "Group": "Other",
//     "Value": "Lumbago",
//     "ICD": "M54.5",
//     "ExchangeCode": 1907
//    },
//    {
//     "Group": "Other",
//     "Value": "Lung Transplant",
//     "ICD": "Z94",
//     "ExchangeCode": 420
//    },
//    {
//     "Group": "Other",
//     "Value": "Lupus Erythematosus Dissemination",
//     "ICD": "M32.9",
//     "ExchangeCode": 702
//    },
//    {
//     "Group": "Other",
//     "Value": "Lymph Nodes Tuberculosis",
//     "ICD": "A18.2",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Lymphocytopenia",
//     "ICD": "D61",
//     "ExchangeCode": 707
//    },
//    {
//     "Group": "Other",
//     "Value": "Lymphopenia",
//     "ICD": "D61",
//     "ExchangeCode": 708
//    },
//    {
//     "Group": "Other",
//     "Value": "Major Affective Disorder",
//     "ICD": "F30-F39",
//     "ExchangeCode": 711
//    },
//    {
//     "Group": "Other",
//     "Value": "Major Depression",
//     "ICD": "F32",
//     "ExchangeCode": 712
//    },
//    {
//     "Group": "Other",
//     "Value": "Malabsorption",
//     "ICD": "K90",
//     "ExchangeCode": 713
//    },
//    {
//     "Group": "Other",
//     "Value": "Malabsorption Syndrome",
//     "ICD": "K90.9",
//     "ExchangeCode": 1484
//    },
//    {
//     "Group": "Other",
//     "Value": "Malignant Hypertension",
//     "ICD": "I10",
//     "ExchangeCode": 715
//    },
//    {
//     "Group": "Other",
//     "Value": "Mania",
//     "ICD": "F30",
//     "ExchangeCode": 1015
//    },
//    {
//     "Group": "Other",
//     "Value": "Mania Chronic",
//     "ICD": "F30",
//     "ExchangeCode": 1016
//    },
//    {
//     "Group": "Other",
//     "Value": "Marfan Syndrome",
//     "ICD": "Q87.4.",
//     "ExchangeCode": 2171
//    },
//    {
//     "Group": "Other",
//     "Value": "Mastoiditis",
//     "ICD": "H70.9",
//     "ExchangeCode": 1819
//    },
//    {
//     "Group": "Other",
//     "Value": "Megaloblastic Anaemia",
//     "ICD": "D53",
//     "ExchangeCode": 1022
//    },
//    {
//     "Group": "Other",
//     "Value": "Melaena",
//     "ICD": "K92.1",
//     "ExchangeCode": 1853
//    },
//    {
//     "Group": "Other",
//     "Value": "Melancholia",
//     "ICD": "F33",
//     "ExchangeCode": 1023
//    },
//    {
//     "Group": "Other",
//     "Value": "Memory Loss",
//     "ICD": "R41.3",
//     "ExchangeCode": 1992
//    },
//    {
//     "Group": "Other",
//     "Value": "Menieres Disease",
//     "ICD": "H81.0",
//     "ExchangeCode": 2129
//    },
//    {
//     "Group": "Other",
//     "Value": "Meningeal TB",
//     "ICD": "A17.1",
//     "ExchangeCode": 1736
//    },
//    {
//     "Group": "Other",
//     "Value": "Meninges Tuberculosis",
//     "ICD": "A17",
//     "ExchangeCode": 1736
//    },
//    {
//     "Group": "Other",
//     "Value": "Meningitis Tuberculous",
//     "ICD": "A17",
//     "ExchangeCode": 1736
//    },
//    {
//     "Group": "Other",
//     "Value": "Mental And Behavioural Disorders Due To Use Of Alcohol",
//     "ICD": "F10",
//     "ExchangeCode": 174
//    },
//    {
//     "Group": "Other",
//     "Value": "Mental Deficiency",
//     "ICD": "F79",
//     "ExchangeCode": 1032
//    },
//    {
//     "Group": "Other",
//     "Value": "Mental Retardation",
//     "ICD": "F79",
//     "ExchangeCode": 1033
//    },
//    {
//     "Group": "Other",
//     "Value": "Mental Retardation Distinct",
//     "ICD": "F79",
//     "ExchangeCode": 1034
//    },
//    {
//     "Group": "Other",
//     "Value": "Mental Retardation Mild",
//     "ICD": "F70",
//     "ExchangeCode": 1035
//    },
//    {
//     "Group": "Other",
//     "Value": "Mental Retardation Moderate",
//     "ICD": "F71",
//     "ExchangeCode": 1036
//    },
//    {
//     "Group": "Other",
//     "Value": "Metabolic Syndrome",
//     "ICD": "E88.9",
//     "ExchangeCode": 1802
//    },
//    {
//     "Group": "Other",
//     "Value": "Metatarsalgia",
//     "ICD": "M77.4",
//     "ExchangeCode": 1914
//    },
//    {
//     "Group": "Other",
//     "Value": "Microcephalus",
//     "ICD": "Q02",
//     "ExchangeCode": 1039
//    },
//    {
//     "Group": "Other",
//     "Value": "Microgyria",
//     "ICD": "Q04",
//     "ExchangeCode": 1040
//    },
//    {
//     "Group": "Other",
//     "Value": "Mild Mental Retardation",
//     "ICD": "F70",
//     "ExchangeCode": 1035
//    },
//    {
//     "Group": "Other",
//     "Value": "Miliary TB",
//     "ICD": "A19",
//     "ExchangeCode": 1738
//    },
//    {
//     "Group": "Other",
//     "Value": "Miliary Tuberculosis",
//     "ICD": "A19.9",
//     "ExchangeCode": 1738
//    },
//    {
//     "Group": "Other",
//     "Value": "Mineral Metabolism Disorder",
//     "ICD": "E83.9",
//     "ExchangeCode": 1800
//    },
//    {
//     "Group": "Other",
//     "Value": "Minor Depression",
//     "ICD": "F48",
//     "ExchangeCode": 1043
//    },
//    {
//     "Group": "Other",
//     "Value": "Moderate Mental Retardation",
//     "ICD": "F71",
//     "ExchangeCode": 1036
//    },
//    {
//     "Group": "Other",
//     "Value": "Mongolism",
//     "ICD": "Q90",
//     "ExchangeCode": 1055
//    },
//    {
//     "Group": "Other",
//     "Value": "Monoplegia",
//     "ICD": "G83",
//     "ExchangeCode": 1056
//    },
//    {
//     "Group": "Other",
//     "Value": "Muscle Spasm",
//     "ICD": "R25.2",
//     "ExchangeCode": 1985
//    },
//    {
//     "Group": "Other",
//     "Value": "Myalgia",
//     "ICD": "M79.1",
//     "ExchangeCode": 1372
//    },
//    {
//     "Group": "Other",
//     "Value": "Myelitis Tuberculous",
//     "ICD": "A17",
//     "ExchangeCode": 1736
//    },
//    {
//     "Group": "Other",
//     "Value": "Myocarditis Tuberculosis",
//     "ICD": "A18.8",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Myocardium Tuberculosis",
//     "ICD": "A18.8",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Myositis",
//     "ICD": "M60.9",
//     "ExchangeCode": 1908
//    },
//    {
//     "Group": "Other",
//     "Value": "Myxoedema",
//     "ICD": "E03.9",
//     "ExchangeCode": 1786
//    },
//    {
//     "Group": "Other",
//     "Value": "Nasal Polyp",
//     "ICD": "J33",
//     "ExchangeCode": 1704
//    },
//    {
//     "Group": "Other",
//     "Value": "Nasal Septum Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Nasal Sinus Disorder",
//     "ICD": "J34",
//     "ExchangeCode": 1705
//    },
//    {
//     "Group": "Other",
//     "Value": "Nasopharynx Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Neck Pain",
//     "ICD": "R07.0",
//     "ExchangeCode": 1976
//    },
//    {
//     "Group": "Other",
//     "Value": "Necrosis Of Pulp",
//     "ICD": "K04.1",
//     "ExchangeCode": 1825
//    },
//    {
//     "Group": "Other",
//     "Value": "Nephritis",
//     "ICD": "N05.9",
//     "ExchangeCode": 1113
//    },
//    {
//     "Group": "Other",
//     "Value": "Nephritis Acute",
//     "ICD": "N05",
//     "ExchangeCode": 1113
//    },
//    {
//     "Group": "Other",
//     "Value": "Nephritis Chronic",
//     "ICD": "N05",
//     "ExchangeCode": 1114
//    },
//    {
//     "Group": "Other",
//     "Value": "Nephropathy",
//     "ICD": "N28.9",
//     "ExchangeCode": 1940
//    },
//    {
//     "Group": "Other",
//     "Value": "Nephrosis",
//     "ICD": "N05",
//     "ExchangeCode": 1115
//    },
//    {
//     "Group": "Other",
//     "Value": "Nephrotic Syndrome",
//     "ICD": "N04.9",
//     "ExchangeCode": 1116
//    },
//    {
//     "Group": "Other",
//     "Value": "Nerve Entrapment",
//     "ICD": "G58.9",
//     "ExchangeCode": 1642
//    },
//    {
//     "Group": "Other",
//     "Value": "Neuralgia",
//     "ICD": "M79.2",
//     "ExchangeCode": 1372
//    },
//    {
//     "Group": "Other",
//     "Value": "Neuritis",
//     "ICD": "M79.2",
//     "ExchangeCode": 1372
//    },
//    {
//     "Group": "Other",
//     "Value": "Neurofibromatosis",
//     "ICD": "Q85.0",
//     "ExchangeCode": 1120
//    },
//    {
//     "Group": "Other",
//     "Value": "Niemann Pick Disease",
//     "ICD": "E75.2",
//     "ExchangeCode": 1124
//    },
//    {
//     "Group": "Other",
//     "Value": "Nocturia",
//     "ICD": "R35",
//     "ExchangeCode": 1991
//    },
//    {
//     "Group": "Other",
//     "Value": "Nodular Tendonous Disease",
//     "ICD": "M65.3",
//     "ExchangeCode": 1910
//    },
//    {
//     "Group": "Other",
//     "Value": "Non Alcoholic Fatty Liver",
//     "ICD": "K70.0",
//     "ExchangeCode": 1850
//    },
//    {
//     "Group": "Other",
//     "Value": "Non-Ischaemic Chest Pain",
//     "ICD": "R07",
//     "ExchangeCode": 1975
//    },
//    {
//     "Group": "Other",
//     "Value": "Nonautoimmune Haemolytic Anaemia",
//     "ICD": "D59.2-D59.4",
//     "ExchangeCode": 1129
//    },
//    {
//     "Group": "Other",
//     "Value": "Nonfamilial Hypogammaglobulinaemia",
//     "ICD": "D80.1",
//     "ExchangeCode": 2106
//    },
//    {
//     "Group": "Other",
//     "Value": "Nose Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Nosebleed",
//     "ICD": "R04.0",
//     "ExchangeCode": 1974
//    },
//    {
//     "Group": "Other",
//     "Value": "Numbness",
//     "ICD": "R20.8",
//     "ExchangeCode": 1983
//    },
//    {
//     "Group": "Other",
//     "Value": "Obnubilation",
//     "ICD": "F05",
//     "ExchangeCode": 1133
//    },
//    {
//     "Group": "Other",
//     "Value": "Obstructed Bowel",
//     "ICD": "K56.6",
//     "ExchangeCode": 1842
//    },
//    {
//     "Group": "Other",
//     "Value": "Occlusive Arterial Disease",
//     "ICD": "I77.1",
//     "ExchangeCode": 1138
//    },
//    {
//     "Group": "Other",
//     "Value": "Oesophageal Obstruction",
//     "ICD": "K22.2",
//     "ExchangeCode": 1829
//    },
//    {
//     "Group": "Other",
//     "Value": "Oesophagitis",
//     "ICD": "K20",
//     "ExchangeCode": 1827
//    },
//    {
//     "Group": "Other",
//     "Value": "Oesophagus Diverticulum",
//     "ICD": "Q39.6",
//     "ExchangeCode": 1962
//    },
//    {
//     "Group": "Other",
//     "Value": "Oesophagus Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Ongoing Liver Infection",
//     "ICD": "K75.9",
//     "ExchangeCode": 1852
//    },
//    {
//     "Group": "Other",
//     "Value": "Optic Neuritis",
//     "ICD": "H46",
//     "ExchangeCode": 1814
//    },
//    {
//     "Group": "Other",
//     "Value": "Oral Tissue Leukoplakia",
//     "ICD": "K13.2",
//     "ExchangeCode": 1826
//    },
//    {
//     "Group": "Other",
//     "Value": "Osteitis Deformans",
//     "ICD": "M88",
//     "ExchangeCode": 1144
//    },
//    {
//     "Group": "Other",
//     "Value": "Osteitis Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Osteoarthritis",
//     "ICD": "M19.9",
//     "ExchangeCode": 1891
//    },
//    {
//     "Group": "Other",
//     "Value": "Osteochondrosis",
//     "ICD": "M93.9",
//     "ExchangeCode": 1919
//    },
//    {
//     "Group": "Other",
//     "Value": "Osteomyelitis",
//     "ICD": "M86.9",
//     "ExchangeCode": 1918
//    },
//    {
//     "Group": "Other",
//     "Value": "Osteomyelitis Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Osteomyelosclerosis",
//     "ICD": "D75",
//     "ExchangeCode": 1150
//    },
//    {
//     "Group": "Other",
//     "Value": "Osteopenia",
//     "ICD": "M81.9",
//     "ExchangeCode": 1916
//    },
//    {
//     "Group": "Other",
//     "Value": "Osteoporosis",
//     "ICD": "M81.9",
//     "ExchangeCode": 1916
//    },
//    {
//     "Group": "Other",
//     "Value": "Other Aplastic Anaemias",
//     "ICD": "D61",
//     "ExchangeCode": 708
//    },
//    {
//     "Group": "Other",
//     "Value": "Other Autoimmune Haemolytic Anaemias",
//     "ICD": "D59.1",
//     "ExchangeCode": 152
//    },
//    {
//     "Group": "Other",
//     "Value": "Other Functional Intestinal Disorders",
//     "ICD": "K59",
//     "ExchangeCode": 2156
//    },
//    {
//     "Group": "Other",
//     "Value": "Other Hereditary Haemolytic Anaemias",
//     "ICD": "D58",
//     "ExchangeCode": 559
//    },
//    {
//     "Group": "Other",
//     "Value": "Other Inflammatory Liver Diseases",
//     "ICD": "K75",
//     "ExchangeCode": 2157
//    },
//    {
//     "Group": "Other",
//     "Value": "Other Orthopoxvirus Infections",
//     "ICD": "B08.0",
//     "ExchangeCode": 2064
//    },
//    {
//     "Group": "Other",
//     "Value": "Other Peripheral Vascular Diseases",
//     "ICD": "I73",
//     "ExchangeCode": 1611
//    },
//    {
//     "Group": "Other",
//     "Value": "Other Sideroblastic Anaemias",
//     "ICD": "D64.3",
//     "ExchangeCode": 2103
//    },
//    {
//     "Group": "Other",
//     "Value": "Other Specified Disorders Of Kidney And Ureter",
//     "ICD": "N28.8",
//     "ExchangeCode": 2162
//    },
//    {
//     "Group": "Other",
//     "Value": "Other Sphingolipidosis",
//     "ICD": "E75.2",
//     "ExchangeCode": 1124
//    },
//    {
//     "Group": "Other",
//     "Value": "Otitis Media",
//     "ICD": "H66.9",
//     "ExchangeCode": 1818
//    },
//    {
//     "Group": "Other",
//     "Value": "Otitis Media Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Otosclerosis",
//     "ICD": "H80.9",
//     "ExchangeCode": 1821
//    },
//    {
//     "Group": "Other",
//     "Value": "Ovarian Cyst",
//     "ICD": "N83.2",
//     "ExchangeCode": 1956
//    },
//    {
//     "Group": "Other",
//     "Value": "Overactive Thyroid Gland",
//     "ICD": "E05.9",
//     "ExchangeCode": 1788
//    },
//    {
//     "Group": "Other",
//     "Value": "Pagets Disease",
//     "ICD": "M88",
//     "ExchangeCode": 1168
//    },
//    {
//     "Group": "Other",
//     "Value": "Pagets Disease Of Bone",
//     "ICD": "M88.9",
//     "ExchangeCode": 1168
//    },
//    {
//     "Group": "Other",
//     "Value": "Panarteritis Nodosa",
//     "ICD": "M30.0",
//     "ExchangeCode": 1897
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Abscess",
//     "ICD": "K85",
//     "ExchangeCode": 1169
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Anomalies",
//     "ICD": "K86",
//     "ExchangeCode": 1170
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Atrophy",
//     "ICD": "K86",
//     "ExchangeCode": 1171
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Cyst",
//     "ICD": "K86.2",
//     "ExchangeCode": 1173
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Disease",
//     "ICD": "K86",
//     "ExchangeCode": 1174
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Fibrosis",
//     "ICD": "K86",
//     "ExchangeCode": 1175
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Fibrosis Cistic",
//     "ICD": "E84.9",
//     "ExchangeCode": 1176
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Fibrosis Cystic",
//     "ICD": "E84.9",
//     "ExchangeCode": 1177
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Inflammation",
//     "ICD": "K86",
//     "ExchangeCode": 1178
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Inflammation Acute",
//     "ICD": "K86",
//     "ExchangeCode": 1179
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Inflammation Chronic",
//     "ICD": "K86",
//     "ExchangeCode": 1180
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Inflammation Relapse",
//     "ICD": "K86",
//     "ExchangeCode": 1181
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Necrosis",
//     "ICD": "K85",
//     "ExchangeCode": 1182
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Pseudocyst",
//     "ICD": "K86.3",
//     "ExchangeCode": 1185
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreas Transplant",
//     "ICD": "Z94",
//     "ExchangeCode": 1183
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreatic Cyst",
//     "ICD": "K86.2",
//     "ExchangeCode": 1185
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreatitis",
//     "ICD": "K85",
//     "ExchangeCode": 1186
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreatitis Acute",
//     "ICD": "K85",
//     "ExchangeCode": 1187
//    },
//    {
//     "Group": "Other",
//     "Value": "Pancreatitis Relapse",
//     "ICD": "K85",
//     "ExchangeCode": 1188
//    },
//    {
//     "Group": "Other",
//     "Value": "Paraesthesia",
//     "ICD": "R20.2",
//     "ExchangeCode": 1983
//    },
//    {
//     "Group": "Other",
//     "Value": "Paralysis",
//     "ICD": "G83",
//     "ExchangeCode": 1191
//    },
//    {
//     "Group": "Other",
//     "Value": "Paranasal Sinus Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Paranoid Schizophrenia",
//     "ICD": "F20",
//     "ExchangeCode": 1196
//    },
//    {
//     "Group": "Other",
//     "Value": "Paranoid State",
//     "ICD": "F23",
//     "ExchangeCode": 1197
//    },
//    {
//     "Group": "Other",
//     "Value": "Paraplegia",
//     "ICD": "G82",
//     "ExchangeCode": 1198
//    },
//    {
//     "Group": "Other",
//     "Value": "Paraplegia And Tetraplegia",
//     "ICD": "G82",
//     "ExchangeCode": 1529
//    },
//    {
//     "Group": "Other",
//     "Value": "Patau Syndrome",
//     "ICD": "Q91",
//     "ExchangeCode": 1204
//    },
//    {
//     "Group": "Other",
//     "Value": "Pataus Syndrome",
//     "ICD": "Q91.7",
//     "ExchangeCode": 1204
//    },
//    {
//     "Group": "Other",
//     "Value": "PCKD",
//     "ICD": "Q60.0",
//     "ExchangeCode": 1259
//    },
//    {
//     "Group": "Other",
//     "Value": "Pemphigoid",
//     "ICD": "L12.9",
//     "ExchangeCode": 1858
//    },
//    {
//     "Group": "Other",
//     "Value": "Pemphigus",
//     "ICD": "L10.9",
//     "ExchangeCode": 1857
//    },
//    {
//     "Group": "Other",
//     "Value": "Peptic Ulcer",
//     "ICD": "K27.9",
//     "ExchangeCode": 1832
//    },
//    {
//     "Group": "Other",
//     "Value": "Peripheral Arterial Occlusive Disease",
//     "ICD": "I74",
//     "ExchangeCode": 440
//    },
//    {
//     "Group": "Other",
//     "Value": "Peripheral Vascular Disease",
//     "ICD": "I73",
//     "ExchangeCode": 1270
//    },
//    {
//     "Group": "Other",
//     "Value": "Perisplenitis",
//     "ICD": "D73",
//     "ExchangeCode": 1224
//    },
//    {
//     "Group": "Other",
//     "Value": "Peritoneum Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Peritonitis",
//     "ICD": "K65.9",
//     "ExchangeCode": 1849
//    },
//    {
//     "Group": "Other",
//     "Value": "Peritonsillar Abscess",
//     "ICD": "J36",
//     "ExchangeCode": 1706
//    },
//    {
//     "Group": "Other",
//     "Value": "Pernicious Anaemia",
//     "ICD": "D51.0",
//     "ExchangeCode": 1229
//    },
//    {
//     "Group": "Other",
//     "Value": "Persistent Delusional Disorders",
//     "ICD": "F22",
//     "ExchangeCode": 2113
//    },
//    {
//     "Group": "Other",
//     "Value": "Persistent Mania",
//     "ICD": "F30",
//     "ExchangeCode": 1230
//    },
//    {
//     "Group": "Other",
//     "Value": "Phlegmon",
//     "ICD": "L03.9",
//     "ExchangeCode": 1855
//    },
//    {
//     "Group": "Other",
//     "Value": "Pickwickian Syndrome",
//     "ICD": "E66",
//     "ExchangeCode": 1236
//    },
//    {
//     "Group": "Other",
//     "Value": "Pityriasis",
//     "ICD": "L21.0",
//     "ExchangeCode": 1861
//    },
//    {
//     "Group": "Other",
//     "Value": "PKD",
//     "ICD": "Q60.0",
//     "ExchangeCode": 1259
//    },
//    {
//     "Group": "Other",
//     "Value": "Plasma Protein Metabolism Disorder",
//     "ICD": "E88",
//     "ExchangeCode": 1247
//    },
//    {
//     "Group": "Other",
//     "Value": "Plasmacytosis",
//     "ICD": "D72",
//     "ExchangeCode": 1246
//    },
//    {
//     "Group": "Other",
//     "Value": "Plasmaprotein Metabolism Disorder",
//     "ICD": "E88.0",
//     "ExchangeCode": 1247
//    },
//    {
//     "Group": "Other",
//     "Value": "Poliomyelitis",
//     "ICD": "A80.9",
//     "ExchangeCode": 1754
//    },
//    {
//     "Group": "Other",
//     "Value": "Polyarteritis Nodosa And Related Conditions",
//     "ICD": "M30",
//     "ExchangeCode": 311
//    },
//    {
//     "Group": "Other",
//     "Value": "Polyarthritis",
//     "ICD": "M13.0",
//     "ExchangeCode": 1256
//    },
//    {
//     "Group": "Other",
//     "Value": "Polyarthritis Chronic",
//     "ICD": "M08",
//     "ExchangeCode": 1256
//    },
//    {
//     "Group": "Other",
//     "Value": "Polyarthritis Primary Chronic",
//     "ICD": "M08",
//     "ExchangeCode": 1257
//    },
//    {
//     "Group": "Other",
//     "Value": "Polyarthrosis",
//     "ICD": "M15",
//     "ExchangeCode": 1258
//    },
//    {
//     "Group": "Other",
//     "Value": "Polyclonal Hypergammaglobulinaemia",
//     "ICD": "D89.0",
//     "ExchangeCode": 2107
//    },
//    {
//     "Group": "Other",
//     "Value": "Polycystic Kidney",
//     "ICD": "Q61.3",
//     "ExchangeCode": 658
//    },
//    {
//     "Group": "Other",
//     "Value": "Polycystic Kidney Disease",
//     "ICD": "Q61",
//     "ExchangeCode": 1259
//    },
//    {
//     "Group": "Other",
//     "Value": "Polycystic Kidneys",
//     "ICD": "Q61.3",
//     "ExchangeCode": 658
//    },
//    {
//     "Group": "Other",
//     "Value": "Polycystic Liver",
//     "ICD": "Q44.6",
//     "ExchangeCode": 1965
//    },
//    {
//     "Group": "Other",
//     "Value": "Polymyalgia Rheumatica",
//     "ICD": "M35.3",
//     "ExchangeCode": 1899
//    },
//    {
//     "Group": "Other",
//     "Value": "Polymyositis",
//     "ICD": "M33.2",
//     "ExchangeCode": 1263
//    },
//    {
//     "Group": "Other",
//     "Value": "Polyserositis Tuberculous",
//     "ICD": "A19",
//     "ExchangeCode": 1738
//    },
//    {
//     "Group": "Other",
//     "Value": "Poor Circulation",
//     "ICD": "I73",
//     "ExchangeCode": 1270
//    },
//    {
//     "Group": "Other",
//     "Value": "Porphyria",
//     "ICD": "E80.2",
//     "ExchangeCode": 1799
//    },
//    {
//     "Group": "Other",
//     "Value": "Portal Hypertension",
//     "ICD": "K76.6",
//     "ExchangeCode": 581
//    },
//    {
//     "Group": "Other",
//     "Value": "Portal Vein Thrombosis",
//     "ICD": "I81",
//     "ExchangeCode": 1681
//    },
//    {
//     "Group": "Other",
//     "Value": "Postcholecystectomy Syndrome",
//     "ICD": "K91.5",
//     "ExchangeCode": 2158
//    },
//    {
//     "Group": "Other",
//     "Value": "Primary Aldosteronism",
//     "ICD": "E26.0",
//     "ExchangeCode": 1275
//    },
//    {
//     "Group": "Other",
//     "Value": "Primary Biliary Cirrhosis",
//     "ICD": "K74.3",
//     "ExchangeCode": 1276
//    },
//    {
//     "Group": "Other",
//     "Value": "Primary Immunodeficiency",
//     "ICD": "D80-D89",
//     "ExchangeCode": 1278
//    },
//    {
//     "Group": "Other",
//     "Value": "Primary Thrombocytopenia",
//     "ICD": "D69.4",
//     "ExchangeCode": 1333
//    },
//    {
//     "Group": "Other",
//     "Value": "Problem With Back",
//     "ICD": "R29.8",
//     "ExchangeCode": 1987
//    },
//    {
//     "Group": "Other",
//     "Value": "Proctitis",
//     "ICD": "K62.8",
//     "ExchangeCode": 1280
//    },
//    {
//     "Group": "Other",
//     "Value": "Proctitis Chronic",
//     "ICD": "K62",
//     "ExchangeCode": 1280
//    },
//    {
//     "Group": "Other",
//     "Value": "Proctitis Ulcerous",
//     "ICD": "K62",
//     "ExchangeCode": 1281
//    },
//    {
//     "Group": "Other",
//     "Value": "Proctocolitis",
//     "ICD": "K51",
//     "ExchangeCode": 1282
//    },
//    {
//     "Group": "Other",
//     "Value": "Proctocolitis Chronic",
//     "ICD": "K51",
//     "ExchangeCode": 1283
//    },
//    {
//     "Group": "Other",
//     "Value": "Proctocolitis Ulcerous",
//     "ICD": "K51",
//     "ExchangeCode": 1284
//    },
//    {
//     "Group": "Other",
//     "Value": "Prolapsed Disc",
//     "ICD": "M51.2",
//     "ExchangeCode": 1906
//    },
//    {
//     "Group": "Other",
//     "Value": "Prolapsed Uterus",
//     "ICD": "N81.4",
//     "ExchangeCode": 1955
//    },
//    {
//     "Group": "Other",
//     "Value": "Prostate Calculus",
//     "ICD": "N42.0",
//     "ExchangeCode": 1948
//    },
//    {
//     "Group": "Other",
//     "Value": "Prostate Hypertrophy",
//     "ICD": "N40",
//     "ExchangeCode": 1946
//    },
//    {
//     "Group": "Other",
//     "Value": "Prostate Tuberculosis",
//     "ICD": "A18.1",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Prostatitis",
//     "ICD": "N41.9",
//     "ExchangeCode": 1947
//    },
//    {
//     "Group": "Other",
//     "Value": "Protein Deficiency Anaemia",
//     "ICD": "D53.0",
//     "ExchangeCode": 1292
//    },
//    {
//     "Group": "Other",
//     "Value": "Proteinuria",
//     "ICD": "R80",
//     "ExchangeCode": 1293
//    },
//    {
//     "Group": "Other",
//     "Value": "Prurigo",
//     "ICD": "L28.2",
//     "ExchangeCode": 1872
//    },
//    {
//     "Group": "Other",
//     "Value": "Pruritis",
//     "ICD": "L29",
//     "ExchangeCode": 1873
//    },
//    {
//     "Group": "Other",
//     "Value": "Pseudohemophilia",
//     "ICD": "D68",
//     "ExchangeCode": 1294
//    },
//    {
//     "Group": "Other",
//     "Value": "Psoriasis",
//     "ICD": "L40.9",
//     "ExchangeCode": 1875
//    },
//    {
//     "Group": "Other",
//     "Value": "Psoriatic Arthritis",
//     "ICD": "M07",
//     "ExchangeCode": 2159
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis",
//     "ICD": "F44",
//     "ExchangeCode": 1295
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Affective",
//     "ICD": "F39",
//     "ExchangeCode": 1296
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Affective Hypomanic",
//     "ICD": "F39",
//     "ExchangeCode": 1297
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Affective Manic",
//     "ICD": "F39",
//     "ExchangeCode": 1298
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Child",
//     "ICD": "F84.0",
//     "ExchangeCode": 1299
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Depressive",
//     "ICD": "F33",
//     "ExchangeCode": 1300
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Disintegrative",
//     "ICD": "F84",
//     "ExchangeCode": 1301
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Hypomanic",
//     "ICD": "F30",
//     "ExchangeCode": 1302
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Hysterical",
//     "ICD": "F44",
//     "ExchangeCode": 1303
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Induced",
//     "ICD": "F24",
//     "ExchangeCode": 1304
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Infantile",
//     "ICD": "F84.0",
//     "ExchangeCode": 1305
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Manic",
//     "ICD": "F30",
//     "ExchangeCode": 1306
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Nonorganic",
//     "ICD": "F28",
//     "ExchangeCode": 1307
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Organic Chronic",
//     "ICD": "F09",
//     "ExchangeCode": 1308
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Organic Transient",
//     "ICD": "F23",
//     "ExchangeCode": 1309
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Paranoid",
//     "ICD": "F29",
//     "ExchangeCode": 1310
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Psychogenic",
//     "ICD": "F23",
//     "ExchangeCode": 1311
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Psychogenic Paranoid",
//     "ICD": "F23",
//     "ExchangeCode": 1312
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Reactive",
//     "ICD": "F33",
//     "ExchangeCode": 1313
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Reactive Depressive",
//     "ICD": "F33",
//     "ExchangeCode": 1314
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychosis Schizophrenic",
//     "ICD": "F20",
//     "ExchangeCode": 1315
//    },
//    {
//     "Group": "Other",
//     "Value": "Psychotic",
//     "ICD": "F09",
//     "ExchangeCode": 1317
//    },
//    {
//     "Group": "Other",
//     "Value": "Pure Hyperglyceridaemia",
//     "ICD": "E78.1",
//     "ExchangeCode": 2108
//    },
//    {
//     "Group": "Other",
//     "Value": "Purpura",
//     "ICD": "D69.2",
//     "ExchangeCode": 1332
//    },
//    {
//     "Group": "Other",
//     "Value": "Purpura Fulminant",
//     "ICD": "D69",
//     "ExchangeCode": 1333
//    },
//    {
//     "Group": "Other",
//     "Value": "PVD",
//     "ICD": "I73.9",
//     "ExchangeCode": 1335
//    },
//    {
//     "Group": "Other",
//     "Value": "Pyelonephritis",
//     "ICD": "N12",
//     "ExchangeCode": 1338
//    },
//    {
//     "Group": "Other",
//     "Value": "Pyelonephritis Acute",
//     "ICD": "N12",
//     "ExchangeCode": 1336
//    },
//    {
//     "Group": "Other",
//     "Value": "Pyelonephritis Chronic",
//     "ICD": "N12",
//     "ExchangeCode": 1337
//    },
//    {
//     "Group": "Other",
//     "Value": "Pyelonephritis Unknown",
//     "ICD": "N12",
//     "ExchangeCode": 1338
//    },
//    {
//     "Group": "Other",
//     "Value": "Pyoderma",
//     "ICD": "L08.0",
//     "ExchangeCode": 1856
//    },
//    {
//     "Group": "Other",
//     "Value": "Pyrophosphate Arthropathy",
//     "ICD": "M11",
//     "ExchangeCode": 1341
//    },
//    {
//     "Group": "Other",
//     "Value": "Quadriplegia",
//     "ICD": "G82",
//     "ExchangeCode": 1342
//    },
//    {
//     "Group": "Other",
//     "Value": "RA",
//     "ICD": "M06.9",
//     "ExchangeCode": 1343
//    },
//    {
//     "Group": "Other",
//     "Value": "Radiculitis",
//     "ICD": "M54.1",
//     "ExchangeCode": 1907
//    },
//    {
//     "Group": "Other",
//     "Value": "Raised Bilirubin",
//     "ICD": "E80.6",
//     "ExchangeCode": 1799
//    },
//    {
//     "Group": "Other",
//     "Value": "Raised Blood Sugar",
//     "ICD": "R73.9",
//     "ExchangeCode": 574
//    },
//    {
//     "Group": "Other",
//     "Value": "Raised Creatinine",
//     "ICD": "N18.9",
//     "ExchangeCode": 1935
//    },
//    {
//     "Group": "Other",
//     "Value": "Raised Liver Enzymes",
//     "ICD": "R74.0",
//     "ExchangeCode": 2005
//    },
//    {
//     "Group": "Other",
//     "Value": "Raynauds Disease",
//     "ICD": "I73",
//     "ExchangeCode": 1348
//    },
//    {
//     "Group": "Other",
//     "Value": "Raynauds Phenomenon",
//     "ICD": "I73",
//     "ExchangeCode": 1349
//    },
//    {
//     "Group": "Other",
//     "Value": "Raynauds Syndrome",
//     "ICD": "I73",
//     "ExchangeCode": 1349
//    },
//    {
//     "Group": "Other",
//     "Value": "Reaction Paranoid Acute",
//     "ICD": "F23",
//     "ExchangeCode": 1351
//    },
//    {
//     "Group": "Other",
//     "Value": "Rectal Polyp",
//     "ICD": "K62.1",
//     "ExchangeCode": 1280
//    },
//    {
//     "Group": "Other",
//     "Value": "Rectal Prolapse",
//     "ICD": "K62.3",
//     "ExchangeCode": 1280
//    },
//    {
//     "Group": "Other",
//     "Value": "Recurrent Cholangitis",
//     "ICD": "K83",
//     "ExchangeCode": 1353
//    },
//    {
//     "Group": "Other",
//     "Value": "Reflux Gastritis",
//     "ICD": "K29.6",
//     "ExchangeCode": 500
//    },
//    {
//     "Group": "Other",
//     "Value": "Reflux Oesophagitis",
//     "ICD": "K21.0",
//     "ExchangeCode": 1828
//    },
//    {
//     "Group": "Other",
//     "Value": "Reiters Disease",
//     "ICD": "M02.3",
//     "ExchangeCode": 1889
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Agenesis, Unilateral",
//     "ICD": "Q60.0",
//     "ExchangeCode": 2169
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Colic",
//     "ICD": "N23",
//     "ExchangeCode": 1939
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Disease",
//     "ICD": "N28.9",
//     "ExchangeCode": 1940
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Failure",
//     "ICD": "N19",
//     "ExchangeCode": 1936
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Failure Chronic",
//     "ICD": "N18.9",
//     "ExchangeCode": 1359
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Function Disorder",
//     "ICD": "N25",
//     "ExchangeCode": 1360
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Hypertonia",
//     "ICD": "N25",
//     "ExchangeCode": 1361
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Osteodystrophy",
//     "ICD": "N25.0",
//     "ExchangeCode": 2161
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Transplant",
//     "ICD": "Z94.0",
//     "ExchangeCode": 1363
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Vein Stenosis",
//     "ICD": "I82.3",
//     "ExchangeCode": 1682
//    },
//    {
//     "Group": "Other",
//     "Value": "Renal Vein Thrombosis",
//     "ICD": "I82.3",
//     "ExchangeCode": 1682
//    },
//    {
//     "Group": "Other",
//     "Value": "Retinal Detachment",
//     "ICD": "H33.2",
//     "ExchangeCode": 1811
//    },
//    {
//     "Group": "Other",
//     "Value": "Retinitis",
//     "ICD": "H30.9",
//     "ExchangeCode": 1809
//    },
//    {
//     "Group": "Other",
//     "Value": "Retinitis Pigmentosa",
//     "ICD": "H35.5",
//     "ExchangeCode": 1812
//    },
//    {
//     "Group": "Other",
//     "Value": "Retinopathy",
//     "ICD": "H35.0",
//     "ExchangeCode": 1812
//    },
//    {
//     "Group": "Other",
//     "Value": "Rheumatic Arthritis",
//     "ICD": "M06.9",
//     "ExchangeCode": 129
//    },
//    {
//     "Group": "Other",
//     "Value": "Rheumatism",
//     "ICD": "M79.0",
//     "ExchangeCode": 1372
//    },
//    {
//     "Group": "Other",
//     "Value": "Rheumatoid Arthritis",
//     "ICD": "M06.9",
//     "ExchangeCode": 1374
//    },
//    {
//     "Group": "Other",
//     "Value": "Rosacea",
//     "ICD": "L71.9",
//     "ExchangeCode": 1882
//    },
//    {
//     "Group": "Other",
//     "Value": "Rotator Cuff Syndrome",
//     "ICD": "M75.1",
//     "ExchangeCode": 1913
//    },
//    {
//     "Group": "Other",
//     "Value": "Sacral Nerve Injury",
//     "ICD": "S34",
//     "ExchangeCode": 1382
//    },
//    {
//     "Group": "Other",
//     "Value": "Sarcoidosis",
//     "ICD": "D86.9",
//     "ExchangeCode": 1387
//    },
//    {
//     "Group": "Other",
//     "Value": "Sarcoidosis Of Lymph Nodes",
//     "ICD": "D86.1",
//     "ExchangeCode": 1387
//    },
//    {
//     "Group": "Other",
//     "Value": "Sarcoidosis Of Skin",
//     "ICD": "D86.3",
//     "ExchangeCode": 1387
//    },
//    {
//     "Group": "Other",
//     "Value": "Scheuermanns Disease",
//     "ICD": "M42.0",
//     "ExchangeCode": 1901
//    },
//    {
//     "Group": "Other",
//     "Value": "Schizophrenia",
//     "ICD": "F20",
//     "ExchangeCode": 1396
//    },
//    {
//     "Group": "Other",
//     "Value": "Schizophrenia Disorganised Type",
//     "ICD": "F20",
//     "ExchangeCode": 1397
//    },
//    {
//     "Group": "Other",
//     "Value": "Schizophrenia Infantile",
//     "ICD": "F20",
//     "ExchangeCode": 1398
//    },
//    {
//     "Group": "Other",
//     "Value": "Sciatica",
//     "ICD": "M54.3",
//     "ExchangeCode": 1907
//    },
//    {
//     "Group": "Other",
//     "Value": "Scleroderma",
//     "ICD": "M34.9",
//     "ExchangeCode": 1399
//    },
//    {
//     "Group": "Other",
//     "Value": "Sclerosing Cholangitis",
//     "ICD": "K83.0",
//     "ExchangeCode": 289
//    },
//    {
//     "Group": "Other",
//     "Value": "Scoliosis",
//     "ICD": "M41.9",
//     "ExchangeCode": 1400
//    },
//    {
//     "Group": "Other",
//     "Value": "Secondary Polycythaemia",
//     "ICD": "D75.1",
//     "ExchangeCode": 2105
//    },
//    {
//     "Group": "Other",
//     "Value": "Secondary Thrombocytopenia",
//     "ICD": "D69.5",
//     "ExchangeCode": 1333
//    },
//    {
//     "Group": "Other",
//     "Value": "Seminal Vesicle TB",
//     "ICD": "A18.1",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Seminal Vesicle Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Severe Depressive Episode With Psychotic Symptoms",
//     "ICD": "F32.3",
//     "ExchangeCode": 2114
//    },
//    {
//     "Group": "Other",
//     "Value": "Severe Mental Retardation",
//     "ICD": "F72",
//     "ExchangeCode": 1436
//    },
//    {
//     "Group": "Other",
//     "Value": "Sickle Cell Anaemia",
//     "ICD": "D57.1",
//     "ExchangeCode": 1441
//    },
//    {
//     "Group": "Other",
//     "Value": "Sickle-Cell Anaemia",
//     "ICD": "D57.1",
//     "ExchangeCode": 1441
//    },
//    {
//     "Group": "Other",
//     "Value": "Simple Schizophrenia",
//     "ICD": "F20.6",
//     "ExchangeCode": 2112
//    },
//    {
//     "Group": "Other",
//     "Value": "Sinus Polyp",
//     "ICD": "J33",
//     "ExchangeCode": 1704
//    },
//    {
//     "Group": "Other",
//     "Value": "Sinusitis",
//     "ICD": "J01",
//     "ExchangeCode": 1686
//    },
//    {
//     "Group": "Other",
//     "Value": "Sjogrens Syndrome",
//     "ICD": "M35.0",
//     "ExchangeCode": 1444
//    },
//    {
//     "Group": "Other",
//     "Value": "Sjorgrens Disease",
//     "ICD": "M35",
//     "ExchangeCode": 1444
//    },
//    {
//     "Group": "Other",
//     "Value": "Skin Rash",
//     "ICD": "R21",
//     "ExchangeCode": 1984
//    },
//    {
//     "Group": "Other",
//     "Value": "Skin TB",
//     "ICD": "A18.4",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Skin Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Skin Ulcers",
//     "ICD": "L98.4",
//     "ExchangeCode": 1888
//    },
//    {
//     "Group": "Other",
//     "Value": "SLE",
//     "ICD": "M32.9",
//     "ExchangeCode": 1453
//    },
//    {
//     "Group": "Other",
//     "Value": "Small Bowel Obstruction",
//     "ICD": "K56.6",
//     "ExchangeCode": 1842
//    },
//    {
//     "Group": "Other",
//     "Value": "Sphingolipidosis",
//     "ICD": "E75",
//     "ExchangeCode": 689
//    },
//    {
//     "Group": "Other",
//     "Value": "Spina Bifida",
//     "ICD": "Q05",
//     "ExchangeCode": 1460
//    },
//    {
//     "Group": "Other",
//     "Value": "Spina Bifida Aperta",
//     "ICD": "Q05",
//     "ExchangeCode": 1461
//    },
//    {
//     "Group": "Other",
//     "Value": "Spina Bifida Cystic",
//     "ICD": "Q05",
//     "ExchangeCode": 1462
//    },
//    {
//     "Group": "Other",
//     "Value": "Spina Bifida Occulta",
//     "ICD": "Q76",
//     "ExchangeCode": 1463
//    },
//    {
//     "Group": "Other",
//     "Value": "Spinal Column Distorsion Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Spinal Column Movement Restriction",
//     "ICD": "R29.8",
//     "ExchangeCode": 1987
//    },
//    {
//     "Group": "Other",
//     "Value": "Spinal Column Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Spinal Cord Injury",
//     "ICD": "S34",
//     "ExchangeCode": 1469
//    },
//    {
//     "Group": "Other",
//     "Value": "Spinal Cord Paralysis",
//     "ICD": "G83",
//     "ExchangeCode": 1471
//    },
//    {
//     "Group": "Other",
//     "Value": "Spinal Curvature",
//     "ICD": "M40.2",
//     "ExchangeCode": 1900
//    },
//    {
//     "Group": "Other",
//     "Value": "Spinal Paralysis",
//     "ICD": "G83",
//     "ExchangeCode": 1472
//    },
//    {
//     "Group": "Other",
//     "Value": "Spinal Stenosis",
//     "ICD": "M48.0",
//     "ExchangeCode": 1614
//    },
//    {
//     "Group": "Other",
//     "Value": "Spleen Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Splenomegaly",
//     "ICD": "Q89",
//     "ExchangeCode": 1475
//    },
//    {
//     "Group": "Other",
//     "Value": "Spondylitis",
//     "ICD": "M46.9",
//     "ExchangeCode": 1904
//    },
//    {
//     "Group": "Other",
//     "Value": "Spondylitis Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Spondylitis Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Spondylolisthesis",
//     "ICD": "M43.1",
//     "ExchangeCode": 1902
//    },
//    {
//     "Group": "Other",
//     "Value": "Spondylolysis",
//     "ICD": "M43.0",
//     "ExchangeCode": 1902
//    },
//    {
//     "Group": "Other",
//     "Value": "Spondylosis",
//     "ICD": "M47.9",
//     "ExchangeCode": 1905
//    },
//    {
//     "Group": "Other",
//     "Value": "Steatorrhea Pancreatic",
//     "ICD": "K90",
//     "ExchangeCode": 1484
//    },
//    {
//     "Group": "Other",
//     "Value": "Steatosis",
//     "ICD": "K76.0",
//     "ExchangeCode": 1850
//    },
//    {
//     "Group": "Other",
//     "Value": "Stein-Leventhal Syndrome",
//     "ICD": "E28.2",
//     "ExchangeCode": 1792
//    },
//    {
//     "Group": "Other",
//     "Value": "Stomach Dysfunction",
//     "ICD": "K31.9",
//     "ExchangeCode": 1835
//    },
//    {
//     "Group": "Other",
//     "Value": "Stomach Operation",
//     "ICD": "K31.9Op",
//     "ExchangeCode": 1836
//    },
//    {
//     "Group": "Other",
//     "Value": "Stomach Pain",
//     "ICD": "R10.1",
//     "ExchangeCode": 1977
//    },
//    {
//     "Group": "Other",
//     "Value": "Stomach Polyp",
//     "ICD": "K31.7",
//     "ExchangeCode": 1835
//    },
//    {
//     "Group": "Other",
//     "Value": "Stomach Problem",
//     "ICD": "K31.9",
//     "ExchangeCode": 1835
//    },
//    {
//     "Group": "Other",
//     "Value": "Stomach Ulcer",
//     "ICD": "K25.9",
//     "ExchangeCode": 1830
//    },
//    {
//     "Group": "Other",
//     "Value": "Subarachnoid Bleed",
//     "ICD": "I60",
//     "ExchangeCode": 1494
//    },
//    {
//     "Group": "Other",
//     "Value": "Subarachnoid Haemorrhage",
//     "ICD": "I60",
//     "ExchangeCode": 1494
//    },
//    {
//     "Group": "Other",
//     "Value": "Subarachnoid Haemorrhage (SAH)",
//     "ICD": "I60",
//     "ExchangeCode": 1494
//    },
//    {
//     "Group": "Other",
//     "Value": "Subdural Bleed",
//     "ICD": "I60",
//     "ExchangeCode": 1494
//    },
//    {
//     "Group": "Other",
//     "Value": "Subdural Haemorrhage",
//     "ICD": "I60",
//     "ExchangeCode": 1494
//    },
//    {
//     "Group": "Other",
//     "Value": "Swollen Abdomen",
//     "ICD": "R19.0",
//     "ExchangeCode": 1982
//    },
//    {
//     "Group": "Other",
//     "Value": "Syndrome Hepatorenal",
//     "ICD": "K76",
//     "ExchangeCode": 1501
//    },
//    {
//     "Group": "Other",
//     "Value": "Synovitis",
//     "ICD": "M65.9",
//     "ExchangeCode": 1910
//    },
//    {
//     "Group": "Other",
//     "Value": "Synovitis Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Syphilis",
//     "ICD": "A50",
//     "ExchangeCode": 1748
//    },
//    {
//     "Group": "Other",
//     "Value": "Systemic Lupus Erythematosus",
//     "ICD": "M32.9",
//     "ExchangeCode": 1507
//    },
//    {
//     "Group": "Other",
//     "Value": "Systemic Mastocytosis",
//     "ICD": "Q82",
//     "ExchangeCode": 1508
//    },
//    {
//     "Group": "Other",
//     "Value": "Systemic Scleroderma",
//     "ICD": "M34.9",
//     "ExchangeCode": 1509
//    },
//    {
//     "Group": "Other",
//     "Value": "Systemic Sclerosis",
//     "ICD": "M34.9",
//     "ExchangeCode": 1509
//    },
//    {
//     "Group": "Other",
//     "Value": "Tay Sachs Disease",
//     "ICD": "E75",
//     "ExchangeCode": 1511
//    },
//    {
//     "Group": "Other",
//     "Value": "TB Myocarditis",
//     "ICD": "A18.8",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Temporal (Giant Cell) Arteritis",
//     "ICD": "M31.6",
//     "ExchangeCode": 1898
//    },
//    {
//     "Group": "Other",
//     "Value": "Temporal Arteritis",
//     "ICD": "M31.6",
//     "ExchangeCode": 1898
//    },
//    {
//     "Group": "Other",
//     "Value": "Tendinitis",
//     "ICD": "M77.9",
//     "ExchangeCode": 1914
//    },
//    {
//     "Group": "Other",
//     "Value": "Tennis Elbow",
//     "ICD": "M77.1",
//     "ExchangeCode": 1914
//    },
//    {
//     "Group": "Other",
//     "Value": "Tenosynovitis",
//     "ICD": "M65.9",
//     "ExchangeCode": 1910
//    },
//    {
//     "Group": "Other",
//     "Value": "Tenosynovitis Tuberculous",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Testicle Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Testis Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tetraplegia",
//     "ICD": "G82",
//     "ExchangeCode": 1529
//    },
//    {
//     "Group": "Other",
//     "Value": "Thalassaemia",
//     "ICD": "D56.9",
//     "ExchangeCode": 1533
//    },
//    {
//     "Group": "Other",
//     "Value": "Thalassaemia Major",
//     "ICD": "D56",
//     "ExchangeCode": 1530
//    },
//    {
//     "Group": "Other",
//     "Value": "Thalassaemia Minor",
//     "ICD": "D56",
//     "ExchangeCode": 1531
//    },
//    {
//     "Group": "Other",
//     "Value": "Thalassaemias",
//     "ICD": "D56",
//     "ExchangeCode": 1532
//    },
//    {
//     "Group": "Other",
//     "Value": "Thalassemias",
//     "ICD": "D56",
//     "ExchangeCode": 1533
//    },
//    {
//     "Group": "Other",
//     "Value": "Thoracic Spondylosis",
//     "ICD": "M47.8",
//     "ExchangeCode": 1905
//    },
//    {
//     "Group": "Other",
//     "Value": "Thrombocytosis",
//     "ICD": "D75.2",
//     "ExchangeCode": 1537
//    },
//    {
//     "Group": "Other",
//     "Value": "Thrombopenia",
//     "ICD": "D69.6",
//     "ExchangeCode": 1538
//    },
//    {
//     "Group": "Other",
//     "Value": "Thymus Illness",
//     "ICD": "E32",
//     "ExchangeCode": 1539
//    },
//    {
//     "Group": "Other",
//     "Value": "Thyroid Gland Disease",
//     "ICD": "E07.9",
//     "ExchangeCode": 1790
//    },
//    {
//     "Group": "Other",
//     "Value": "Thyroid Gland Illness",
//     "ICD": "E07.9",
//     "ExchangeCode": 1790
//    },
//    {
//     "Group": "Other",
//     "Value": "Thyroid Gland Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Thyroid Illness",
//     "ICD": "E35.0",
//     "ExchangeCode": 1794
//    },
//    {
//     "Group": "Other",
//     "Value": "Thyroiditis",
//     "ICD": "E06.9",
//     "ExchangeCode": 1789
//    },
//    {
//     "Group": "Other",
//     "Value": "Tietzes Syndrome",
//     "ICD": "M94.0",
//     "ExchangeCode": 1920
//    },
//    {
//     "Group": "Other",
//     "Value": "Tinnitus",
//     "ICD": "H93.1",
//     "ExchangeCode": 1824
//    },
//    {
//     "Group": "Other",
//     "Value": "Torsion Dystonia Fragments",
//     "ICD": "G24",
//     "ExchangeCode": 1548
//    },
//    {
//     "Group": "Other",
//     "Value": "Torsion Dystonia Idiopathic",
//     "ICD": "G24",
//     "ExchangeCode": 1549
//    },
//    {
//     "Group": "Other",
//     "Value": "Torsion Dystonia Symptomatic",
//     "ICD": "G24",
//     "ExchangeCode": 1550
//    },
//    {
//     "Group": "Other",
//     "Value": "Torticollis",
//     "ICD": "M43.6",
//     "ExchangeCode": 1902
//    },
//    {
//     "Group": "Other",
//     "Value": "Transient Organic Mental Disorder",
//     "ICD": "F05.9",
//     "ExchangeCode": 2109
//    },
//    {
//     "Group": "Other",
//     "Value": "Transient Organic Psychosis",
//     "ICD": "F05",
//     "ExchangeCode": 11
//    },
//    {
//     "Group": "Other",
//     "Value": "Traumatic Subdural Haemorrhage",
//     "ICD": "S06.5",
//     "ExchangeCode": 2010
//    },
//    {
//     "Group": "Other",
//     "Value": "Tremor",
//     "ICD": "R25.1",
//     "ExchangeCode": 1985
//    },
//    {
//     "Group": "Other",
//     "Value": "Trigger Finger",
//     "ICD": "M65.3",
//     "ExchangeCode": 1910
//    },
//    {
//     "Group": "Other",
//     "Value": "Trisomy",
//     "ICD": "Q92",
//     "ExchangeCode": 1561
//    },
//    {
//     "Group": "Other",
//     "Value": "Trisomy 13",
//     "ICD": "Q91",
//     "ExchangeCode": 1562
//    },
//    {
//     "Group": "Other",
//     "Value": "Trisomy 18",
//     "ICD": "Q91",
//     "ExchangeCode": 1563
//    },
//    {
//     "Group": "Other",
//     "Value": "Trisomy 21",
//     "ICD": "Q90",
//     "ExchangeCode": 1564
//    },
//    {
//     "Group": "Other",
//     "Value": "Trisomy 22",
//     "ICD": "Q92",
//     "ExchangeCode": 1565
//    },
//    {
//     "Group": "Other",
//     "Value": "Trisomy D",
//     "ICD": "Q91",
//     "ExchangeCode": 1566
//    },
//    {
//     "Group": "Other",
//     "Value": "Trisomy E",
//     "ICD": "A91",
//     "ExchangeCode": 1758
//    },
//    {
//     "Group": "Other",
//     "Value": "Trisomy G",
//     "ICD": "Q90",
//     "ExchangeCode": 1568
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculosis Hip",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculosis Intrathoracic Lymph Nodes",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculosis Of Bones And Joints",
//     "ICD": "A18.0",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculosis Of Central Nervous System (CNS)",
//     "ICD": "A17.9",
//     "ExchangeCode": 2062
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculosis Of Genito-Urinary System",
//     "ICD": "A18.1",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculosis Of Genitourinary",
//     "ICD": "A18.1",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculosis Of Hip",
//     "ICD": "A18.0",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculosis Of Intestines, Peritoneum And Mesenteric Glands",
//     "ICD": "A18.3",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculosis Of Kidney",
//     "ICD": "A18.1",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculosis Of Skin And Subcutaneous Tissue",
//     "ICD": "A18.4",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculous Enteritis",
//     "ICD": "A18.3",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculous Gland Inflammation",
//     "ICD": "A18.8",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculous Laryngitis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculous Meningitis",
//     "ICD": "A17.0",
//     "ExchangeCode": 2061
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculous Polyserositis",
//     "ICD": "A19.9",
//     "ExchangeCode": 1738
//    },
//    {
//     "Group": "Other",
//     "Value": "Tuberculous Spinal Column",
//     "ICD": "A18.0",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "UC",
//     "ICD": "K51.90",
//     "ExchangeCode": 1588
//    },
//    {
//     "Group": "Other",
//     "Value": "Ulcer",
//     "ICD": "L98.4",
//     "ExchangeCode": 1888
//    },
//    {
//     "Group": "Other",
//     "Value": "Ulcerative Chronic Proctitis",
//     "ICD": "K51.2",
//     "ExchangeCode": 1280
//    },
//    {
//     "Group": "Other",
//     "Value": "Ulcerative Colitis",
//     "ICD": "K51.9",
//     "ExchangeCode": 1589
//    },
//    {
//     "Group": "Other",
//     "Value": "Umbilical Hernia",
//     "ICD": "K42.9",
//     "ExchangeCode": 1839
//    },
//    {
//     "Group": "Other",
//     "Value": "Underactive Thyroid",
//     "ICD": "E03.9",
//     "ExchangeCode": 1786
//    },
//    {
//     "Group": "Other",
//     "Value": "Unspecified Blood Disease",
//     "ICD": "D75.9",
//     "ExchangeCode": 1591
//    },
//    {
//     "Group": "Other",
//     "Value": "Unspecified Blood Disorder",
//     "ICD": "D75.9",
//     "ExchangeCode": 1592
//    },
//    {
//     "Group": "Other",
//     "Value": "Unspecified Human Immunodeficiency Virus [HIV] Disease",
//     "ICD": "B24",
//     "ExchangeCode": 8
//    },
//    {
//     "Group": "Other",
//     "Value": "Unspecified Spina Bifida With Hydrocephalus",
//     "ICD": "Q05.4",
//     "ExchangeCode": 2164
//    },
//    {
//     "Group": "Other",
//     "Value": "Unspecified Viral Hepatitis",
//     "ICD": "B19",
//     "ExchangeCode": 2065
//    },
//    {
//     "Group": "Other",
//     "Value": "Uraemia",
//     "ICD": "R39",
//     "ExchangeCode": 1593
//    },
//    {
//     "Group": "Other",
//     "Value": "Ureter Additional",
//     "ICD": "Q62.5",
//     "ExchangeCode": 1594
//    },
//    {
//     "Group": "Other",
//     "Value": "Ureter Anomaly",
//     "ICD": "Q62.8",
//     "ExchangeCode": 1595
//    },
//    {
//     "Group": "Other",
//     "Value": "Ureter Double",
//     "ICD": "Q62.5",
//     "ExchangeCode": 1597
//    },
//    {
//     "Group": "Other",
//     "Value": "Ureter Ectopic",
//     "ICD": "Q62",
//     "ExchangeCode": 1598
//    },
//    {
//     "Group": "Other",
//     "Value": "Ureter Stricture",
//     "ICD": "N13.5",
//     "ExchangeCode": 1922
//    },
//    {
//     "Group": "Other",
//     "Value": "Ureter Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Urethra Stricture",
//     "ICD": "N35.9",
//     "ExchangeCode": 1943
//    },
//    {
//     "Group": "Other",
//     "Value": "Urethral Caruncle",
//     "ICD": "N36.2",
//     "ExchangeCode": 1944
//    },
//    {
//     "Group": "Other",
//     "Value": "Urinary Incontinence",
//     "ICD": "R32",
//     "ExchangeCode": 1989
//    },
//    {
//     "Group": "Other",
//     "Value": "Urinary Retention",
//     "ICD": "R33",
//     "ExchangeCode": 1990
//    },
//    {
//     "Group": "Other",
//     "Value": "Urinary Tract Calculus",
//     "ICD": "N21.9",
//     "ExchangeCode": 1939
//    },
//    {
//     "Group": "Other",
//     "Value": "Urinary Tract Infection",
//     "ICD": "N39.0",
//     "ExchangeCode": 1945
//    },
//    {
//     "Group": "Other",
//     "Value": "Urinary Tract Tuberculosis",
//     "ICD": "A18",
//     "ExchangeCode": 1737
//    },
//    {
//     "Group": "Other",
//     "Value": "Urine Infection",
//     "ICD": "N39.0",
//     "ExchangeCode": 1945
//    },
//    {
//     "Group": "Other",
//     "Value": "Urticaria",
//     "ICD": "L50.9",
//     "ExchangeCode": 1877
//    },
//    {
//     "Group": "Other",
//     "Value": "Uterine Polyps",
//     "ICD": "N84.0",
//     "ExchangeCode": 1957
//    },
//    {
//     "Group": "Other",
//     "Value": "Uterus Removal",
//     "ICD": "N85.9",
//     "ExchangeCode": 1959
//    },
//    {
//     "Group": "Other",
//     "Value": "Varicose Ulcer",
//     "ICD": "I83.0",
//     "ExchangeCode": 1684
//    },
//    {
//     "Group": "Other",
//     "Value": "Varicose Veins With Ulcer",
//     "ICD": "I83.0",
//     "ExchangeCode": 1684
//    },
//    {
//     "Group": "Other",
//     "Value": "Vascular Disease",
//     "ICD": "I99",
//     "ExchangeCode": 1610
//    },
//    {
//     "Group": "Other",
//     "Value": "Vascular Disease Peripheral",
//     "ICD": "I73.9",
//     "ExchangeCode": 1611
//    },
//    {
//     "Group": "Other",
//     "Value": "Vein Embolism",
//     "ICD": "I82.9",
//     "ExchangeCode": 1683
//    },
//    {
//     "Group": "Other",
//     "Value": "Vein Thrombosis",
//     "ICD": "I82.9",
//     "ExchangeCode": 1683
//    },
//    {
//     "Group": "Other",
//     "Value": "Vena Cava Thrombosis",
//     "ICD": "I82.2",
//     "ExchangeCode": 1682
//    },
//    {
//     "Group": "Other",
//     "Value": "Vertebral Stenosis",
//     "ICD": "M48",
//     "ExchangeCode": 1614
//    },
//    {
//     "Group": "Other",
//     "Value": "Vertebrobasilar Disease",
//     "ICD": "G45.8",
//     "ExchangeCode": 2120
//    },
//    {
//     "Group": "Other",
//     "Value": "Vestibular Vertigo",
//     "ICD": "H81.9",
//     "ExchangeCode": 1822
//    },
//    {
//     "Group": "Other",
//     "Value": "Viral Hepatitis",
//     "ICD": "B18",
//     "ExchangeCode": 1765
//    },
//    {
//     "Group": "Other",
//     "Value": "Viral Hepatitis B",
//     "ICD": "B18",
//     "ExchangeCode": 1765
//    },
//    {
//     "Group": "Other",
//     "Value": "Viral Hepatitis C",
//     "ICD": "B18",
//     "ExchangeCode": 1765
//    },
//    {
//     "Group": "Other",
//     "Value": "Viral Hepatitis Unspecified",
//     "ICD": "B18",
//     "ExchangeCode": 1765
//    },
//    {
//     "Group": "Other",
//     "Value": "Viral Meningitis",
//     "ICD": "A87.9",
//     "ExchangeCode": 1756
//    },
//    {
//     "Group": "Other",
//     "Value": "Visual Field Defects",
//     "ICD": "H53.4",
//     "ExchangeCode": 1815
//    },
//    {
//     "Group": "Other",
//     "Value": "Visual Loss Of One Eye",
//     "ICD": "H54.6",
//     "ExchangeCode": 1817
//    },
//    {
//     "Group": "Other",
//     "Value": "Vitamin B12 Deficiency Anaemia",
//     "ICD": "D51.9",
//     "ExchangeCode": 157
//    },
//    {
//     "Group": "Other",
//     "Value": "Vitamin B12 Deficiency Anaemia Due To Intrinsic Factor Deficiency",
//     "ICD": "D51.0",
//     "ExchangeCode": 157
//    },
//    {
//     "Group": "Other",
//     "Value": "Vitamin Deficiency",
//     "ICD": "E56.9",
//     "ExchangeCode": 1795
//    },
//    {
//     "Group": "Other",
//     "Value": "Vitiligo",
//     "ICD": "L80",
//     "ExchangeCode": 1884
//    },
//    {
//     "Group": "Other",
//     "Value": "Volvulus",
//     "ICD": "K56.2",
//     "ExchangeCode": 1842
//    },
//    {
//     "Group": "Other",
//     "Value": "Von Willebrands Disease",
//     "ICD": "D68.0",
//     "ExchangeCode": 1621
//    },
//    {
//     "Group": "Other",
//     "Value": "Wegeners Granulomatosis",
//     "ICD": "M31.3",
//     "ExchangeCode": 1629
//    },
//    {
//     "Group": "Other",
//     "Value": "Whipples Disease",
//     "ICD": "K90.8",
//     "ExchangeCode": 1484
//    },
//    {
//     "Group": "Other",
//     "Value": "Withdrawal State With Delirium",
//     "ICD": "F10.4^",
//     "ExchangeCode": 2111
//    },
//    {
//     "Group": "Other",
//     "Value": "Zollinger Ellison Syndrome",
//     "ICD": "E16",
//     "ExchangeCode": 1636
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Acute Bronchiolitis",
//     "ICD": "J21",
//     "ExchangeCode": 214
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Acute Bronchitis",
//     "ICD": "J20",
//     "ExchangeCode": 214
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Acute Drug Induced Intersital Lung Disorder",
//     "ICD": "J70",
//     "ExchangeCode": 1708
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Acute Laryngitis And Epiglottitis",
//     "ICD": "J05",
//     "ExchangeCode": 1688
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Acute Pulmonary Insufficiency Following Surgery",
//     "ICD": "J95",
//     "ExchangeCode": 1717
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Acute Tracheitis",
//     "ICD": "J04.1",
//     "ExchangeCode": 1687
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Acute Upper Respiratory Infection",
//     "ICD": "J06",
//     "ExchangeCode": 1688
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Adult Respiratory Distress Syndrome",
//     "ICD": "J80",
//     "ExchangeCode": 1709
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Airway Disease Due To Specific Organic Dust",
//     "ICD": "J66",
//     "ExchangeCode": 1442
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Airway Obstruction Chronic",
//     "ICD": "J44",
//     "ExchangeCode": 48
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Alveolar Capillary Block",
//     "ICD": "I78",
//     "ExchangeCode": 61
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Aspergillosis",
//     "ICD": "B44",
//     "ExchangeCode": 1780
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Bacterial Pneumonia",
//     "ICD": "J15",
//     "ExchangeCode": 160
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Bronchial TB",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Bronchitis Unspecified",
//     "ICD": "J40",
//     "ExchangeCode": 214
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Bronchus Tuberculosis",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chest Infection",
//     "ICD": "J06",
//     "ExchangeCode": 1688
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chronic Bronchitis",
//     "ICD": "J40",
//     "ExchangeCode": 297
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chronic Drug Induced Intersital Lung Disorder",
//     "ICD": "J70",
//     "ExchangeCode": 1708
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chronic Laryngitis And Laryngotracheitis",
//     "ICD": "J37",
//     "ExchangeCode": 1688
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chronic Nasopharyngitis",
//     "ICD": "J31",
//     "ExchangeCode": 1703
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chronic Obstructive Airways Disease",
//     "ICD": "J44",
//     "ExchangeCode": 321
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chronic Obstructive Lung Disease",
//     "ICD": "J44",
//     "ExchangeCode": 307
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chronic Pharyngitis",
//     "ICD": "J31.2",
//     "ExchangeCode": 1703
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chronic Pulmonary Insufficiency Following Surgery",
//     "ICD": "J95.3",
//     "ExchangeCode": 1717
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chronic Rhinitis",
//     "ICD": "J31",
//     "ExchangeCode": 1703
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chronic Sinusitis",
//     "ICD": "J32",
//     "ExchangeCode": 1703
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Chylous Effusion",
//     "ICD": "J94.0",
//     "ExchangeCode": 1716
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Coalworkers Pneumoconiosis",
//     "ICD": "J60",
//     "ExchangeCode": 1252
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Collapsed Lung",
//     "ICD": "J93",
//     "ExchangeCode": 1715
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Compensatory Emphysema",
//     "ICD": "J98.3",
//     "ExchangeCode": 442
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Cystic Fibrosis",
//     "ICD": "E84",
//     "ExchangeCode": 485
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Diffuse Pulmonary Fibrosis",
//     "ICD": "J84",
//     "ExchangeCode": 409
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Diphtheria",
//     "ICD": "A36.9",
//     "ExchangeCode": 1742
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Double Lung Transplant",
//     "ICD": "Z94",
//     "ExchangeCode": 420
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Drug Induced Intersital Lung Disorder",
//     "ICD": "J70",
//     "ExchangeCode": 1708
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Dyspnoea",
//     "ICD": "R06",
//     "ExchangeCode": 432
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Emphysema Chronic",
//     "ICD": "J43",
//     "ExchangeCode": 2153
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Extrinsic Asthma",
//     "ICD": "J45.0",
//     "ExchangeCode": 465
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Fibrosis Cystic",
//     "ICD": "E84",
//     "ExchangeCode": 485
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Fibrosis Of Lung Following Radiation",
//     "ICD": "J70",
//     "ExchangeCode": 1708
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Fibrothorax",
//     "ICD": "J94",
//     "ExchangeCode": 1716
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Flu With Pneumonia",
//     "ICD": "J10\/J11",
//     "ExchangeCode": 489
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Ghon Focus",
//     "ICD": "A16.7",
//     "ExchangeCode": 2060
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Haemoptysis",
//     "ICD": "R04.2",
//     "ExchangeCode": 1974
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Haemothorax",
//     "ICD": "J94",
//     "ExchangeCode": 1716
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Hamman Rich Syndrome",
//     "ICD": "J84",
//     "ExchangeCode": 521
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Hay Fever",
//     "ICD": "J30",
//     "ExchangeCode": 1702
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Heart Pulmonary Acute",
//     "ICD": "I26.0",
//     "ExchangeCode": 540
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Hypersensitivity Pneumonitis Due To Organic Dust",
//     "ICD": "J67",
//     "ExchangeCode": 1442
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Hypertension Pulmonary",
//     "ICD": "I27",
//     "ExchangeCode": 582
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Idiopathic Fibrosing Alveolitis",
//     "ICD": "J84",
//     "ExchangeCode": 598
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Idiopathic Interstitial Pulmonary Fibrosis",
//     "ICD": "J84",
//     "ExchangeCode": 599
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Influenza",
//     "ICD": "J11",
//     "ExchangeCode": 1700
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Influenza Due To Identified Influenza Virus",
//     "ICD": "J10",
//     "ExchangeCode": 2152
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Influenza With Pneumonia",
//     "ICD": "J18",
//     "ExchangeCode": 616
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Intersital Pulmonary Disease",
//     "ICD": "J84",
//     "ExchangeCode": 599
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Intersitial Emphysema",
//     "ICD": "J98",
//     "ExchangeCode": 442
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Intrinsic Asthma",
//     "ICD": "J45.1",
//     "ExchangeCode": 631
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Larynx Tuberculosis",
//     "ICD": "A16.5",
//     "ExchangeCode": 2059
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Lung Abscess",
//     "ICD": "J85",
//     "ExchangeCode": 1711
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Lung Sarcoidosis",
//     "ICD": "D86",
//     "ExchangeCode": 1387
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Lung Silicotic Fibrosis",
//     "ICD": "J62",
//     "ExchangeCode": 699
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Lung Transplant",
//     "ICD": "Z94",
//     "ExchangeCode": 420
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Lungs Tuberculosis",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Mediastinal TB",
//     "ICD": "A16.5",
//     "ExchangeCode": 2059
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Mediastinum Tuberculosis",
//     "ICD": "A16.5",
//     "ExchangeCode": 2059
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Mendelsons Syndrome",
//     "ICD": "J95",
//     "ExchangeCode": 1717
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Mucoviscidosis",
//     "ICD": "E84",
//     "ExchangeCode": 1061
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Obstructive Emphysema",
//     "ICD": "J43",
//     "ExchangeCode": 2153
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Obstructive Sleep Apnoea",
//     "ICD": "G47",
//     "ExchangeCode": 1136
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Other Interstitial Pulmonary Diseases",
//     "ICD": "J84",
//     "ExchangeCode": 599
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pleura Tuberculosis",
//     "ICD": "A16.5",
//     "ExchangeCode": 2059
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pleural Effusion",
//     "ICD": "J90",
//     "ExchangeCode": 1713
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pleurisy Tuberculous",
//     "ICD": "A16.5",
//     "ExchangeCode": 2059
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pneumoconiosis Due To Asbestos And Other Mineral Fibres",
//     "ICD": "J61",
//     "ExchangeCode": 133
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pneumoconiosis Due To Dust Containing Silica",
//     "ICD": "J62",
//     "ExchangeCode": 1442
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pneumoconiosis Due To Other Inorganic Dusts",
//     "ICD": "J63",
//     "ExchangeCode": 1442
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pneumonia",
//     "ICD": "J18",
//     "ExchangeCode": 1253
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pneumonia Tuberculous",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pneumonitis",
//     "ICD": "J69",
//     "ExchangeCode": 1707
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pneumothorax",
//     "ICD": "J93",
//     "ExchangeCode": 1715
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pneumothorax Tuberculous",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Postprocedural Respiratory Disorders",
//     "ICD": "J95",
//     "ExchangeCode": 1717
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Predominantly Allergic Asthma",
//     "ICD": "J45",
//     "ExchangeCode": 465
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Primary Pulmonary Hypertension",
//     "ICD": "I27",
//     "ExchangeCode": 582
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Primary Respiratory Tuberculosis Without Mention Of Bacteriological Or Histological Confirmation",
//     "ICD": "A16.7",
//     "ExchangeCode": 2060
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Primary Tuberculous Complex",
//     "ICD": "A16.7",
//     "ExchangeCode": 2060
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Collapse",
//     "ICD": "J98",
//     "ExchangeCode": 1715
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Edema",
//     "ICD": "J81",
//     "ExchangeCode": 1325
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Embolism",
//     "ICD": "I26",
//     "ExchangeCode": 1319
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Embolus",
//     "ICD": "I26",
//     "ExchangeCode": 1320
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Eosinophilia",
//     "ICD": "J82",
//     "ExchangeCode": 1710
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Fibrosis",
//     "ICD": "J84",
//     "ExchangeCode": 599
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Incompetence",
//     "ICD": "I26",
//     "ExchangeCode": 1322
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Infarction",
//     "ICD": "I26",
//     "ExchangeCode": 1323
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Insufficiency",
//     "ICD": "I26",
//     "ExchangeCode": 1324
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Oedema",
//     "ICD": "J81",
//     "ExchangeCode": 1325
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Regurgitation",
//     "ICD": "I26",
//     "ExchangeCode": 1326
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary TB",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Thrombo Embolic Disease",
//     "ICD": "I26",
//     "ExchangeCode": 1329
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pulmonary Thromboembolic Disease",
//     "ICD": "I26",
//     "ExchangeCode": 1329
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Pyothorax",
//     "ICD": "J86",
//     "ExchangeCode": 1712
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Radiation Pneumonitis",
//     "ICD": "J70",
//     "ExchangeCode": 1708
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Resp TB",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Respiratory Failure",
//     "ICD": "J96",
//     "ExchangeCode": 1718
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Respiratory TB",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Respiratory Tract Infection",
//     "ICD": "J22",
//     "ExchangeCode": 1701
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Respiratorytract Tuberculosis",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Rhinitis",
//     "ICD": "J30",
//     "ExchangeCode": 1702
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Secondary Pulmonary Hypertension",
//     "ICD": "I27",
//     "ExchangeCode": 1319
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Shortness Breath",
//     "ICD": "R06.0",
//     "ExchangeCode": 1438
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Silicosis",
//     "ICD": "J62",
//     "ExchangeCode": 1442
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Silicotuberculosis",
//     "ICD": "J65",
//     "ExchangeCode": 1443
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Simple And Mucopurulent Chronic Bronchitis",
//     "ICD": "J41",
//     "ExchangeCode": 214
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Sleep Apnoea",
//     "ICD": "G47",
//     "ExchangeCode": 1136
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Smokers Cough",
//     "ICD": "J41",
//     "ExchangeCode": 1455
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Status Asthmaticus",
//     "ICD": "J46",
//     "ExchangeCode": 136
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "TB",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "TB Lung",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tracheostomy Malfunction",
//     "ICD": "J95",
//     "ExchangeCode": 1717
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis Bronchial Isolated",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis General",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis Lungs Cavitation",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis Lungs Infiltrative",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis Of Lung, Confirmed By Unspecified Means",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis Pleurisy",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis Primary Progressive",
//     "ICD": "A16.7",
//     "ExchangeCode": 2060
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis Primary Specified",
//     "ICD": "A16.7",
//     "ExchangeCode": 2060
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis Pulmonary",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculosis Respiratory",
//     "ICD": "A15.3",
//     "ExchangeCode": 2058
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Tuberculous Pleurisy, Without Mention Of Bacteriological Or Histological Confirmation",
//     "ICD": "A16.5",
//     "ExchangeCode": 2059
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Upper Respiratory Tract Disease",
//     "ICD": "J39",
//     "ExchangeCode": 1701
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Vasomotor And Allergic Rhinitis",
//     "ICD": "J30",
//     "ExchangeCode": 1702
//    },
//    {
//     "Group": "Respiratory",
//     "Value": "Viral Pneumonia",
//     "ICD": "J12",
//     "ExchangeCode": 1619
//    },
//    {
//     "Group": "Stroke",
//     "Value": "TIA (Transient Ischaemic Attack - Mini-Stroke)",
//     "ICD": "G45",
//     "ExchangeCode": 1649
//    },
//    {
//     "Group": "Stroke",
//     "Value": "Subarachnoid Haemorrhage (SAH)",
//     "ICD": "I60",
//     "ExchangeCode": 1494
//    },
//    {
//     "Group": "Stroke",
//     "Value": "Cerebral Haemorrhage\/Bleed",
//     "ICD": "I63",
//     "ExchangeCode": 269
//    },
//    {
//     "Group": "Stroke",
//     "Value": "CVA (Cerebrovascular Accident - Major Stroke)",
//     "ICD": "I64",
//     "ExchangeCode": 376
//    }
//   ];

// export const formattedData = transformOrigoJSONData(initialData);
