import * as Yup from "yup";
export const medicalConditionSchema = Yup.object().shape({
  conditionName: Yup.string()
    .nullable()
    .label("Condition Name")
    .when("conditionType", {
      is: (val: string) => ["208", "212", "210", "214"].includes(val),
      then: Yup.string().required(),
    })
    .when(["conditionType", "neurologicalType"], {
      is: (conditionType: string, neurologicalType: string) =>
        conditionType === "216" && neurologicalType === "6019",
      then: Yup.string()
        .required()
        .notOneOf(["0", ""], "Please select an option"),
    }),
  conditionType: Yup.string().nullable().label("Condition Type").required(),
  dateDiagnosed: Yup.string().nullable().label("Date Diagnosed").required(),
  lastSystolicReading: Yup.string()
    .nullable()
    .label("Last Systolic Reading")
    .when("conditionType", {
      is: (val: string) => val == "218",
      then: Yup.string()
        .required()
        .notOneOf(["0", ""], "Please select an option"),
      otherwise: Yup.string().optional(),
    }),
  lastDiastolicReading: Yup.string()
    .nullable()
    .label("Last Diastolic Reading")
    .when("conditionType", {
      is: (val: string) => val == "218",
      then: Yup.string()
        .required()
        .notOneOf(["0", ""], "Please select an option"),
      otherwise: Yup.string().optional(),
    }),
  lastReadingDate: Yup.string()
    .nullable()
    .label("Last Reading Date")
    .when("conditionType", {
      is: (val: string) => val == "218",
      then: Yup.string()
        .required()
        .notOneOf(["0", ""], "Please select an option"),
      otherwise: Yup.string().optional(),
    }),
  heartConditionType: Yup.string().nullable().label("Heart Condition Type"),
  lastSufferedSymptoms: Yup.string()
    .nullable()
    .label("Last Suffered Symptoms")
    .when("conditionType", {
      is: (val: string) => val == "208",
      then: Yup.string()
        .required()
        .notOneOf(["0", ""], "Please select an option"),
      otherwise: Yup.string().optional(),
    }),
  diabetesType: Yup.string().nullable().label("Diabetes Type"),
  howIsItControlled: Yup.string().nullable().label("How Is It Controlled"),
  tumourlocation: Yup.string().nullable().label("Tumour Location"),
  tumourType: Yup.string().nullable().label("Tumour Type"),
  generalGrade: Yup.string().nullable().label("General Grade"),
  classificationType: Yup.string().nullable().label("Classification Type"),
  natureOfTumour: Yup.string().nullable().label("Nature Of Tumour"),
  strokeType: Yup.string().nullable().label("Stroke Type"),
  partOfBodyAffected: Yup.string().nullable().label("Part Of Body Affected"),
  respiratoryType: Yup.string().nullable().label("Respiratory Type"),
  numberOfHospitalAdmissions: Yup.string()
    .nullable()
    .label("Number Of Hospital Admissions"),
  subType: Yup.string().nullable().label("Sub Type"),
  neurologicalType: Yup.string()
    .nullable()
    .label("Neurological Type")
    .when("conditionType", {
      is: (val: string) => val == "216",
      then: Yup.string()
        .required()
        .notOneOf(["0", ""], "Please select an option"),
      otherwise: Yup.string().optional(),
    }),
  mmseScoreKnown: Yup.string().nullable().label("MMSE Score Known"),
  mmseScore: Yup.string().nullable().label("MMSE Score"),
  falls: Yup.string().nullable().label("Falls"),
  pressureSores: Yup.string().nullable().label("Pressure Sores"),
  seizures: Yup.string().nullable().label("Seizures"),
  tremors: Yup.string().nullable().label("Tremors"),
});
