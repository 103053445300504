import * as Yup from "yup";
import {
  Form,
  Formik,
  FormikValues,
  useFormik,
  useFormikContext,
} from "formik";
import { Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { InputBox } from "../../forms/form_fields/InputBox";
import { ObjectShape } from "yup/lib/object";
import { GetFormFields } from "../../forms/Helpers";
import { KTSVG } from "../../../_metronic/helpers";
import { Notice } from "../cards/Notice";
import { ScrollToError } from "../other/ScrollToError";

export const EditDataModal = (props: ModalProps) => {
  const initialValues = props.data || {};
  const formFields = GetFormFields(props.schema);

  const submitBtnRef = useRef<HTMLButtonElement | null>(null);

  // Wrap the existing submit function
  async function submit(values: any, actions: FormikValues) {
    //submit
    submitBtnRef.current?.setAttribute("data-kt-indicator", "on");
    submitBtnRef.current?.setAttribute("disabled", "true");
    await props.handleSubmit(values);
    props.handleClose();
  }

  const formikRef = useRef<FormikValues>(null);

  const close = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
    props.handleClose();
  };

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <Modal
      id="editDataModal"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName={
        props.fullscreen === true
          ? "modal-dialog modal-dialog-centered modal-fullscreen"
          : "modal-dialog modal-dialog-centered mw-900px"
      }
      show={props.show}
      onHide={close}
      backdrop={true}
    >
      <div className="modal-header">
        <h2>{props.modalHeader}</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={close}
        >
          <KTSVG
            className="svg-icon-1"
            path="/media/icons/duotune/arrows/arr061.svg"
          />
        </div>
        {/* end::Close */}
      </div>
      {initialValues && (
        <Formik
          validationSchema={props.schema}
          initialValues={initialValues}
          onSubmit={submit}
          forwardRef={formikRef}
          validateOnBlur={true}
          validateOnChange={true}
          validateOnMount={false}
        >
          {(formik) => {
            const { isValid, submitForm } = formik;
            return (
              <>
                <div className="modal-body py-lg-10 px-lg-10">
                  <div className="flex-row-fluid py-lg-5 px-lg-15">
                    <Form
                      className={
                        props.fullscreen === true
                          ? "py-5 w-100 px-5 container-xl"
                          : "py-5 w-100 w-xl-700px px-5"
                      }
                      id="ModalForm"
                    >
                      {formFields}
                    </Form>
                  </div>
                </div>
                <div className="modal-footer">
                  {!isValid && (
                    <div className="text-danger mt-2 d-flex justify-content-end">
                      Please correct the above errors.
                    </div>
                  )}
                  {!props.recordLocked ? (
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary me-3"
                      ref={submitBtnRef}
                      onClick={async () => {
                        // Add an onClick handler
                        submitForm();
                        await formik.validateForm(); // Trigger validation
                        if (!isValid) {
                          ScrollToError();
                        }
                      }}
                    >
                      <span className="indicator-label">Save</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  ) : (
                    <Notice
                      noticeClasses={
                        "mb-10 bg-light-warning border border-warning"
                      }
                      icon={
                        <KTSVG
                          path="/media/icons/duotune/general/gen047.svg"
                          className="svg-icon svg-icon-3x svg-icon-dark"
                        />
                      }
                      iconClasses={""}
                      header={"Record locked"}
                      content={
                        <>
                          <div className="mt-5">
                            If you need to make any changes to your details,
                            please contact us on{" "}
                            <span className="text-dark text-bolder">
                              {" "}
                              0808 281 2558.
                            </span>
                          </div>
                        </>
                      }
                      button={null}
                    />
                  )}
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  handleClose: () => void;
  show: boolean;
  schema: YupSchema;
  data?: Record<string, any>;
  modalHeader: string;
  handleSubmit: (data: any) => void;
  fullscreen?: boolean;
  recordLocked?: boolean;
}

interface YupSchema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}
