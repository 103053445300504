import { hotjar } from 'react-hotjar';
import React from "react";
import ReactDOM from "react-dom/client";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";
import { AppRoutes } from "./routing/AppRoutes";

// Initialize Hotjar
hotjar.initialize(3536266, 6);

const container = document.getElementById("root");
if (container) {
  ReactDOM.createRoot(container).render(
    // <React.StrictMode>
    <AppRoutes />
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
