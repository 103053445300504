import {
  ErrorMessage,
  Field,
  FieldProps,
  useField,
  useFormikContext,
} from "formik";
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from "react";
import Select, { createFilter } from "react-select";
import { IGroupedOption, IOption } from "../../../models/LookupWithGroupsData";

interface IProps {
  groupFilterField?: string;
  label: string;
  description: string;
  name: string;
  required: boolean;
  placeholder: string;
  tooltip?: ITooltip;
  prefix: ReactNode;
  values: IGroupedOption[];
  className?: string;
  addSeparatorBelow?: boolean;
  addSeparatorAbove?: boolean;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}

export const LookupWithSearchAndGroups = (props: IProps) => {
  const [initialValue, setInitialValue] = useState<IOption>({
    label: "",
    value: "Please Select",
  });
  const [field, meta, helpers] = useField(props.name);

  useEffect(() => {
    if (field.value) {
      // Find the option that corresponds to the field value and set it as the initial value
      const foundOption = props.values
        .flatMap((group) => group.options)
        .find((option) => option.value === field.value);

      if (foundOption) {
        setInitialValue(foundOption);
      }
    }
  }, [field.value, props.values]);

  const minInput = 3;
  const filterOption = (item: any, input: any) => {
    return (
      // Min input length
      input.length >= minInput &&
      // Use Select's default filtering for string matching by creating filter
      createFilter({})(item, input)
    );
  };
  const noOptionsMessage = (input: any) =>
    input.length >= minInput
      ? "No options"
      : "Please start typing (minimum of 3 characters)...";

  //below is for filtering search groups
  const { values } = useFormikContext<any>();
  const groupFilter = returnGroupFilterFieldValue();

  function returnGroupFilterFieldValue() {
    if (props.groupFilterField) {
      const getNestedValue = (obj: any, path: string) => {
        const pathParts = path.split(".");
        return pathParts.reduce((accumulator, current) => {
          return accumulator && accumulator[current];
        }, obj);
      };
      const fieldValue = getNestedValue(values, props.groupFilterField);
      return fieldValue;
    } else {
      return undefined;
    }
  }

  const filterGroupOptions = (
    groupedOptions: IGroupedOption[],
    filterGroup?: string
  ) => {
    if (!filterGroup) return groupedOptions;
    return groupedOptions
      .filter(
        (group) => group.groupValue.toLowerCase() === filterGroup.toLowerCase()
      )
      .map((group) => ({
        ...group,
        options: group.options,
      }));
  };

  // Memoize the filtered options
  const memoizedFilteredOptions = useMemo(() => {
    return filterGroupOptions(props.values, groupFilter);
  }, [props.values, groupFilter]);

  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <div className="d-flex flex-column fv-row">
        <div className="mb-2">
          <label className="d-flex align-items-center fs-3 fw-bold form-label">
            <span className={props.required ? "required" : ""}>
              {props.label}
            </span>
            {props.tooltip?.button && props.tooltip.button}
          </label>
          {props.tooltip?.notice && props.tooltip.notice}
          {props.description != "" && (
            <div className="form-text">{props.description}</div>
          )}
        </div>

        <div className="input-group">
          {props.prefix && (
            <div className="input-group-prepend">
              <div className="input-group-text border-0 me-2 bg-transparent bolder">
                {props.prefix}
              </div>
            </div>
          )}

          <Select<IOption, false, IGroupedOption>
            name={props.name}
            options={memoizedFilteredOptions}
            filterOption={filterOption}
            noOptionsMessage={noOptionsMessage}
            //className="form-control form-control-lg form-control-solid"
            className="input-group fs-5"
            value={initialValue}
            onChange={(option: IOption | null) => {
              helpers.setValue(option ? option.value : "");
            }}
          />
        </div>

        <div className="text-danger mt-2">
          <ErrorMessage name={props.name} />
        </div>
      </div>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};
