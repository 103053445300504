import React, { useEffect, useRef } from "react";

interface IProps {
  url: string;
  prefill?: IPrefill;
}

interface IPrefill {
  name?: string;
  email?: string;
}

const CalendlyWidget = (props: IProps) => {
  const hasLoaded = useRef(false);

  useEffect(() => {
    if (!hasLoaded.current && (window as any).Calendly) {
      hasLoaded.current = true;
      (window as any).Calendly.initInlineWidget({
        url: props.url,
        parentElement: document.querySelector(".calendly-inline-widget"),
        prefill: props.prefill,
        utm: {},
      });
    }
  });

  return (
    <div
      className="calendly-inline-widget"
      style={{ width: "100%", height: "100%", border: "none" }}
      data-auto-load="false"
    />
  );
};

export default CalendlyWidget;
