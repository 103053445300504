import { useState } from "react";
import { Link } from "react-router-dom";
import { RadioImageSelect } from "../modules/common/other/RadioImageSelect";

export const GetStarted = () => {
  const [selectedOption, setSelectedOption] = useState(0);
  function handleSelect(option: number) {
    setSelectedOption(option);
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="mb-10 fv-row text-center">
        <div className="fs-1 fw-bolder mb-2">I would like help with…</div>
        <div className="text-gray-800 fw-semibold fs-6 mb-10">
          Tell us some basic details to get an idea of your retirement options.
        </div>
        {
          <RadioImageSelect
            item={[
              {
                imageSrc: "media/illustrations/bw/png/moneybox.png",
                label: "Pensions & Annuities",
              },
              {
                imageSrc: "media/illustrations/bw/png/real-estate.png",
                label: "Equity Release",
              },
              {
                imageSrc:
                  "media/illustrations/bw/png/there-is-nothing-here.png",
                label: "I don't know",
              },
            ]}
            onSelect={handleSelect}
            radioName="GetStartedRadio"
          />
        }
      </div>
      <Link
        to={
          selectedOption === 0
            ? "/quickquote"
            : selectedOption === 1
            ? "/404"
            : "/"
        }
        className="btn btn-primary btn-lg"
      >
        Great, let's go
      </Link>
    </div>
  );
};
