import { IGroupedOption } from "./LookupWithGroupsData";

interface IMedication {
  medicationName?: string;
  numberTaken?: string;
  frequency?: string;
  dosageKnown?: string;
  dosage?: string;
  dosageUnit?: string;
  forConditionType?: string;
  startDate?: string;
  stillTakeMedication?: string;
  endDate?: string;
}

export type { IMedication };

const allMedications = [
  { value: "100004", label: "AAA Spray" },
  { value: "198", label: "Abacavir" },
  { value: "100005", label: "Abatacept" },
  { value: "100006", label: "Abciximab" },
  { value: "100007", label: "Abelcet" },
  { value: "100008", label: "Abilify" },
  { value: "199", label: "Abiraterone" },
  { value: "100009", label: "Abraxane" },
  { value: "100010", label: "Abstral" },
  { value: "200", label: "Acamprosate" },
  { value: "201", label: "Acarbose" },
  { value: "100011", label: "Accolate" },
  { value: "202", label: "Accupril" },
  { value: "100012", label: "Accupro" },
  { value: "100013", label: "Accuretic" },
  { value: "100014", label: "ACE inhibitor - Other" },
  { value: "100015", label: "Acea Gel" },
  { value: "203", label: "Acebutolol" },
  { value: "100016", label: "Aceclofenac" },
  { value: "204", label: "Acemetacin" },
  { value: "205", label: "Acenocoumarol" },
  { value: "100017", label: "Acetazolamide" },
  { value: "100018", label: "Acetic acid" },
  { value: "100019", label: "Acetylcholine" },
  { value: "206", label: "Acetylcysteine" },
  { value: "100020", label: "Acetylsalicylic Acid" },
  { value: "207", label: "Acfol" },
  { value: "208", label: "Aciclovir" },
  { value: "100021", label: "Acidex" },
  { value: "209", label: "Acipimox" },
  { value: "210", label: "Acitretin" },
  { value: "211", label: "Aclasta" },
  { value: "100022", label: "Acnecide" },
  { value: "100023", label: "Acnisal" },
  { value: "212", label: "Acomplia" },
  { value: "213", label: "Acovil" },
  { value: "214", label: "Actavis" },
  { value: "100024", label: "Actilyse" },
  { value: "100025", label: "Actinomycin D" },
  { value: "100026", label: "Actiq" },
  { value: "100027", label: "Activated" },
  { value: "100028", label: "Active II Endocrine" },
  { value: "215", label: "Actonel" },
  { value: "100029", label: "Actonel Combi" },
  { value: "100030", label: "Actonel Once a Week" },
  { value: "216", label: "Actos" },
  { value: "217", label: "Actrapid" },
  { value: "100031", label: "Acular" },
  { value: "100032", label: "Acupan" },
  { value: "218", label: "Adalat" },
  { value: "100033", label: "Adalat Capsules" },
  { value: "100034", label: "Adalat LA" },
  { value: "219", label: "Adalimumab" },
  { value: "100035", label: "Adapalene" },
  { value: "100036", label: "Adartrel" },
  { value: "100037", label: "Adcal" },
  { value: "220", label: "Adcal D3" },
  { value: "221", label: "Adcirca" },
  { value: "100038", label: "Adcortyl" },
  { value: "100039", label: "Adefovir" },
  { value: "100040", label: "Adenocor" },
  { value: "222", label: "Adenosine" },
  { value: "100041", label: "Adenuric" },
  { value: "223", label: "Adipine" },
  { value: "100042", label: "Adipine XL" },
  { value: "224", label: "Adiro" },
  { value: "225", label: "Adizem" },
  { value: "100043", label: "Adizem- XL" },
  { value: "100044", label: "Adizem-SR" },
  { value: "100045", label: "Adizem-XL" },
  { value: "100046", label: "Adrenaline" },
  { value: "100047", label: "Advagraf Prolonged Release" },
  { value: "100048", label: "Advate" },
  { value: "226", label: "Advicor" },
  { value: "227", label: "Aerosonit" },
  { value: "228", label: "Afinitor" },
  { value: "100049", label: "Aggrastat" },
  { value: "100050", label: "Agomelatine" },
  { value: "229", label: "Airomir" },
  { value: "100051", label: "Aknemin" },
  { value: "100052", label: "Aknemycin Plus" },
  { value: "230", label: "Alateris" },
  { value: "100053", label: "Alclometasone" },
  { value: "231", label: "Aldactazine" },
  { value: "100054", label: "Aldactide 50" },
  { value: "232", label: "Aldactone" },
  { value: "233", label: "Aldara" },
  { value: "234", label: "Aldesleukin" },
  { value: "100055", label: "Aldomet" },
  { value: "235", label: "Alemtuzumab" },
  { value: "236", label: "Alendronate" },
  { value: "237", label: "Alendronic acid" },
  { value: "238", label: "Alfacalcidol" },
  { value: "100056", label: "Alfentanil" },
  { value: "239", label: "Alfuzosin" },
  { value: "100057", label: "Algesal" },
  { value: "240", label: "Alginates" },
  { value: "100058", label: "Alginic Acid" },
  { value: "241", label: "Alimemazine" },
  { value: "100059", label: "Alimta" },
  { value: "242", label: "Aliskiren" },
  { value: "100060", label: "Alitretinoin" },
  { value: "243", label: "Alkeran" },
  { value: "100061", label: "Allantoin" },
  { value: "100062", label: "Allegron" },
  { value: "244", label: "Allopurinol" },
  { value: "245", label: "Almax" },
  { value: "100063", label: "Almogran" },
  { value: "100064", label: "Almotriptan" },
  { value: "100065", label: "Alogliptin" },
  { value: "100066", label: "Alomide" },
  { value: "100067", label: "Aloxi" },
  { value: "100068", label: "Alpha blocker - Other" },
  { value: "100069", label: "Alphaderm" },
  { value: "100070", label: "Alphagan" },
  { value: "100071", label: "Alphosyl" },
  { value: "100072", label: "Alphosyl HC" },
  { value: "246", label: "Alprazolam" },
  { value: "247", label: "Alprostadil" },
  { value: "100073", label: "Altacite Plus" },
  { value: "100074", label: "Altargo" },
  { value: "248", label: "Alteis" },
  { value: "100075", label: "Alteplase" },
  { value: "249", label: "Alu-Cap" },
  { value: "100076", label: "Aluminium Acetate" },
  { value: "100077", label: "Aluminium chloride hexahydrate" },
  { value: "100078", label: "Aluminium Dihydroxyallantoinate" },
  { value: "250", label: "Aluminium Hydroxide" },
  { value: "100079", label: "Aluminium Hydroxide Gel" },
  { value: "100080", label: "Aluminium oxide" },
  { value: "251", label: "Alupent" },
  { value: "100081", label: "Alvedon" },
  { value: "252", label: "Alverine" },
  { value: "100082", label: "Alverine citrate" },
  { value: "253", label: "Alvesco" },
  { value: "254", label: "Amantadine" },
  { value: "100083", label: "Amantadine hydrochloride" },
  { value: "255", label: "Amaryl" },
  { value: "100084", label: "Ambirix" },
  { value: "100085", label: "Ambisome" },
  { value: "256", label: "Ambrisentan" },
  { value: "257", label: "Amdip" },
  { value: "100086", label: "Ametop" },
  { value: "258", label: "Amias" },
  { value: "100087", label: "Amidox" },
  { value: "100088", label: "Amielle Comfort" },
  { value: "100089", label: "Amifampridine" },
  { value: "100090", label: "Amikacin" },
  { value: "100091", label: "Amikin" },
  { value: "259", label: "Amiloride" },
  { value: "100092", label: "Aminobenzoic acid" },
  { value: "260", label: "Aminophylline" },
  { value: "261", label: "Amiodarone" },
  { value: "262", label: "Amisulpride" },
  { value: "263", label: "Amitriptyline" },
  { value: "264", label: "Amlobesilat-Sandoz" },
  { value: "265", label: "Amlodipine" },
  { value: "100093", label: "Amlodipine; Atorvastatin" },
  { value: "100094", label: "Amlodipine; Valsartan" },
  { value: "266", label: "Amlor" },
  { value: "267", label: "Amlostin" },
  { value: "268", label: "Amlovasc" },
  { value: "100095", label: "Ammonaps" },
  { value: "100096", label: "Amobarbital" },
  { value: "269", label: "Amorolfine" },
  { value: "270", label: "Amoxetine" },
  { value: "271", label: "Amoxicillin" },
  { value: "100097", label: "Amoxil" },
  { value: "100098", label: "Amphocil" },
  { value: "272", label: "Amphotericin" },
  { value: "100099", label: "Ampicillin" },
  { value: "100100", label: "Amsacrine" },
  { value: "100101", label: "Amsidine" },
  { value: "100102", label: "Amyben" },
  { value: "100103", label: "Anabact" },
  { value: "100104", label: "Anacal" },
  { value: "273", label: "Anafranil" },
  { value: "274", label: "Anagrelide" },
  { value: "100105", label: "Anakinra" },
  { value: "100106", label: "Anapen" },
  { value: "275", label: "Anastrozole" },
  { value: "100107", label: "Ancotil" },
  { value: "100108", label: "Androcur" },
  { value: "100109", label: "Andropatch" },
  { value: "100110", label: "Anectine" },
  { value: "100111", label: "Anexate" },
  { value: "100112", label: "Angeliq" },
  { value: "276", label: "Angettes" },
  { value: "100113", label: "Angettes 75" },
  { value: "100114", label: "Angiotensin II Receptor Blocker - Other" },
  { value: "100115", label: "Angiox" },
  { value: "100116", label: "Angitak" },
  { value: "277", label: "Angitil SR" },
  { value: "278", label: "Angitil XL" },
  { value: "100117", label: "Anhydrol Forte" },
  { value: "100118", label: "Anidulafungin" },
  { value: "100119", label: "Anquil" },
  { value: "100120", label: "Antabuse" },
  { value: "100121", label: "Antazoline" },
  { value: "100122", label: "Antepsin" },
  { value: "279", label: "Anthenol" },
  { value: "100123", label: "Anti-arrhythmic - Other" },
  { value: "100124", label: "Anti-D immunoglobulin" },
  { value: "100125", label: "Anti-hypertensive - Other" },
  { value: "100127", label: "Antiplatelet - Other" },
  { value: "100128", label: "Antithrombin alfa" },
  { value: "100126", label: "Anti-thymocyte immunoglobulin" },
  { value: "100129", label: "Anugesic-HC" },
  { value: "100130", label: "Anusol" },
  { value: "100131", label: "Anusol HC" },
  { value: "280", label: "Anxicalm" },
  { value: "281", label: "Apagrel" },
  { value: "282", label: "Apidra" },
  { value: "283", label: "Apocard" },
  { value: "100132", label: "APO-go" },
  { value: "284", label: "Apomorphine" },
  { value: "100133", label: "Apraclonidine" },
  { value: "100134", label: "Aprepitant" },
  { value: "100135", label: "Apresoline" },
  { value: "100136", label: "Aprinox" },
  { value: "285", label: "Aprovel" },
  { value: "100137", label: "Aptivus" },
  { value: "100138", label: "Aqsia" },
  { value: "100139", label: "Aquadrate" },
  { value: "100140", label: "Aquagel Genito-urinary System" },
  { value: "100141", label: "Aqueous Cream" },
  { value: "100142", label: "Aqueous cream Skin" },
  { value: "100143", label: "Arachis Oil" },
  { value: "286", label: "Aranesp" },
  { value: "287", label: "Arava" },
  { value: "100144", label: "ARB - Other" },
  { value: "288", label: "Arcoxia" },
  { value: "100145", label: "Aredia" },
  { value: "100146", label: "Argipressin" },
  { value: "289", label: "Aricept" },
  { value: "290", label: "Arimidex" },
  { value: "291", label: "Aripaprazole" },
  { value: "100147", label: "Aripiprazole" },
  { value: "100148", label: "Arixtra" },
  { value: "100149", label: "Arjun Ear Drops" },
  { value: "100150", label: "Arlevert" },
  { value: "292", label: "Aromasin" },
  { value: "293", label: "Aromatase inhibitors" },
  { value: "100151", label: "Arpicolin" },
  { value: "100152", label: "ARRT List" },
  { value: "100153", label: "Artelac" },
  { value: "100154", label: "Artemether" },
  { value: "294", label: "Arthrotec" },
  { value: "100155", label: "Arthrotec 50" },
  { value: "100156", label: "Artificial saliva" },
  { value: "295", label: "Arythmol" },
  { value: "100157", label: "Arzerra" },
  { value: "100158", label: "AS Saliva Orthana" },
  { value: "296", label: "Asacol" },
  { value: "100159", label: "Asacol MR" },
  { value: "297", label: "Asasantin" },
  { value: "100160", label: "Asasantin Retard" },
  { value: "298", label: "Ascensia Microfil" },
  { value: "100161", label: "Aserbine" },
  { value: "299", label: "Asilone" },
  { value: "100162", label: "Asilone Suspension" },
  { value: "100163", label: "Asmabec Clickhaler" },
  { value: "100164", label: "Asmanex Twisthaler" },
  { value: "100165", label: "Asmasal Clickhaler" },
  { value: "300", label: "Aspegic" },
  { value: "301", label: "Aspirin" },
  { value: "302", label: "Aspro Clear" },
  { value: "303", label: "Atacand" },
  { value: "304", label: "Atarax" },
  { value: "100166", label: "Atazanavir" },
  { value: "305", label: "Atenolol" },
  { value: "100167", label: "Atimos Modulite" },
  { value: "100168", label: "Ativan" },
  { value: "306", label: "Atomoxetine" },
  { value: "100169", label: "Atopiclair" },
  { value: "307", label: "Atoris" },
  { value: "308", label: "Atorvastatin" },
  { value: "100170", label: "Atosiban" },
  { value: "100171", label: "Atovaquone" },
  { value: "100172", label: "Atracurium" },
  { value: "100173", label: "Atriance" },
  { value: "309", label: "Atripla" },
  { value: "310", label: "Atropine" },
  { value: "311", label: "Atrovent" },
  { value: "100174", label: "ATryn" },
  { value: "312", label: "Augmentin" },
  { value: "100175", label: "Aureocort" },
  { value: "100176", label: "Automatic ICD" },
  { value: "313", label: "Avalide" },
  { value: "100177", label: "Avamys" },
  { value: "314", label: "Avandamet" },
  { value: "315", label: "Avandia" },
  { value: "316", label: "Avapro" },
  { value: "317", label: "Avastin" },
  { value: "100178", label: "Avaxim" },
  { value: "100179", label: "Aveeno" },
  { value: "100180", label: "Avelox" },
  { value: "100181", label: "Avloclor" },
  { value: "100182", label: "Avoca" },
  { value: "318", label: "Avodart" },
  { value: "100183", label: "Avomine" },
  { value: "319", label: "Avonex" },
  { value: "320", label: "Axagon" },
  { value: "100184", label: "Axid" },
  { value: "100185", label: "Axorid" },
  { value: "100186", label: "Axsain" },
  { value: "321", label: "Azacitidine" },
  { value: "100187", label: "Azactam" },
  { value: "100188", label: "Azarga" },
  { value: "322", label: "Azathioprine" },
  { value: "100189", label: "Azelaic acid" },
  { value: "100190", label: "Azelastine" },
  { value: "323", label: "Azelastine Hydrochloride" },
  { value: "324", label: "Azilect" },
  { value: "325", label: "Azithromycin" },
  { value: "326", label: "Azopt" },
  { value: "100191", label: "Aztreonam" },
  { value: "100192", label: "Bacitracin" },
  { value: "327", label: "Baclofen" },
  { value: "100193", label: "Bactroban" },
  { value: "100194", label: "Bactroban Nasal" },
  { value: "100195", label: "Balance Activ RX" },
  { value: "100196", label: "Balmosa" },
  { value: "328", label: "Balneum" },
  { value: "329", label: "Balsalazide" },
  { value: "100197", label: "Bambec" },
  { value: "100198", label: "Bambuterol" },
  { value: "100199", label: "Baraclude" },
  { value: "100200", label: "Baratol" },
  { value: "100201", label: "Barkat" },
  { value: "330", label: "Barnidipine" },
  { value: "100202", label: "Basiliximab" },
  { value: "100203", label: "Baxan" },
  { value: "100204", label: "Becaplermin" },
  { value: "331", label: "Beclazone" },
  { value: "332", label: "Becloforte" },
  { value: "333", label: "Beclometasone" },
  { value: "334", label: "Beclotaide" },
  { value: "335", label: "Becodisks" },
  { value: "336", label: "Beconase" },
  { value: "337", label: "Becotide" },
  { value: "100205", label: "Bedol" },
  { value: "100206", label: "Bemiparin" },
  { value: "338", label: "Benazepril" },
  { value: "339", label: "Bendroflumethiazide" },
  { value: "100207", label: "Benefix" },
  { value: "100208", label: "Benperidol" },
  { value: "100209", label: "Benserazide" },
  { value: "100210", label: "Benzalkonium chloride" },
  { value: "100211", label: "Benzatropine" },
  { value: "340", label: "Benzhexol" },
  { value: "100212", label: "Benzocaine" },
  { value: "100213", label: "Benzoic Acid" },
  { value: "100214", label: "Benzoin" },
  { value: "341", label: "Benzoyl Peroxide" },
  { value: "100215", label: "Benzthiazide" },
  { value: "342", label: "Benzydamine" },
  { value: "100216", label: "Benzyl Benzoate" },
  { value: "100217", label: "Benzylpenicillin sodium" },
  { value: "100218", label: "Beractant" },
  { value: "100219", label: "Berinert" },
  { value: "100220", label: "Beriplex P/N" },
  { value: "343", label: "Besavar XL" },
  { value: "344", label: "Besitran" },
  { value: "100221", label: "Beta-adalat" },
  { value: "100222", label: "Beta-blocker - Other" },
  { value: "100223", label: "Betacap" },
  { value: "345", label: "Beta-Cardone" },
  { value: "100224", label: "Betadine Ointment" },
  { value: "100225", label: "Betadine Spray" },
  { value: "346", label: "Betaferon" },
  { value: "100226", label: "Betagan" },
  { value: "347", label: "Betahistine" },
  { value: "100227", label: "Betaine anhydrous" },
  { value: "348", label: "Betaloc" },
  { value: "100228", label: "Betaloc - SA" },
  { value: "100229", label: "Betaloc I.V. Injection" },
  { value: "349", label: "Betamethasone" },
  { value: "350", label: "Beta-Prograne" },
  { value: "100230", label: "Betaxolol" },
  { value: "100231", label: "Betesil" },
  { value: "100232", label: "Bethanechol" },
  { value: "100233", label: "Betim" },
  { value: "100234", label: "Betnelan" },
  { value: "351", label: "Betnesol" },
  { value: "100235", label: "Betnesol Tablets" },
  { value: "352", label: "Betnovate" },
  { value: "100236", label: "Betnovate RD" },
  { value: "100237", label: "Betnovate-C" },
  { value: "353", label: "Betoptic" },
  { value: "100238", label: "Bettamousse" },
  { value: "354", label: "Bevacizumab" },
  { value: "100239", label: "Bexarotene" },
  { value: "355", label: "Bezafibrate" },
  { value: "100240", label: "Bezalip" },
  { value: "100241", label: "Bezalip Mono" },
  { value: "356", label: "Bezalip-Mono" },
  { value: "100242", label: "Bi-Aglut" },
  { value: "100243", label: "Bi-Alimenta" },
  { value: "357", label: "Bicalutamide" },
  { value: "358", label: "Bimatoprost" },
  { value: "100244", label: "Binocrit" },
  { value: "100245", label: "Biorphen" },
  { value: "100246", label: "Biotene Oralbalance" },
  { value: "100247", label: "BioXtra" },
  { value: "100248", label: "Biphasic Insulin Aspart" },
  { value: "100249", label: "Biphasic Insulin Lispro" },
  { value: "100250", label: "Biphasic Isophane Insulin" },
  { value: "359", label: "Biphosphonates" },
  { value: "360", label: "Bipreterax" },
  { value: "361", label: "Bisacodyl" },
  { value: "100251", label: "Bismuth Oxide" },
  { value: "100252", label: "Bismuth Subgallate" },
  { value: "362", label: "Bisoprolol" },
  { value: "100253", label: "Bivalirudin" },
  { value: "363", label: "Bleomycin" },
  { value: "100254", label: "Blood pressure treatment - Other" },
  { value: "364", label: "Bolamyn SR" },
  { value: "365", label: "Bondronat" },
  { value: "366", label: "Bonefos" },
  { value: "100255", label: "Bonjela" },
  { value: "367", label: "Bonviva" },
  { value: "368", label: "Bortezomib" },
  { value: "369", label: "Bosentan" },
  { value: "100256", label: "Botox" },
  { value: "370", label: "Botulinum toxin" },
  { value: "100257", label: "BP treatment - Other" },
  { value: "100258", label: "Bradosol" },
  { value: "100259", label: "Bramitob" },
  { value: "100260", label: "Brasivol" },
  { value: "371", label: "Brazepam" },
  { value: "100261", label: "Brevibloc" },
  { value: "100262", label: "Brevoxyl" },
  { value: "100263", label: "Brexidol" },
  { value: "372", label: "Bricanyl" },
  { value: "100264", label: "Bricanyl Turbohaler" },
  { value: "100265", label: "Bridion" },
  { value: "100266", label: "Brimonidine" },
  { value: "373", label: "Brinzolamide" },
  { value: "100267", label: "BritLofex" },
  { value: "100268", label: "Brochlor" },
  { value: "100269", label: "Broflex" },
  { value: "100270", label: "Brolene" },
  { value: "374", label: "Bromazepam" },
  { value: "375", label: "Bromelain" },
  { value: "376", label: "Bromocriptine" },
  { value: "377", label: "Brufen Retard" },
  { value: "378", label: "Buccastem" },
  { value: "100271", label: "Buclizine" },
  { value: "100272", label: "Budelin Novolizer" },
  { value: "100273", label: "Budenofalk" },
  { value: "379", label: "Budesonide" },
  { value: "380", label: "Bumetanide" },
  { value: "100274", label: "Bupivacaine" },
  { value: "381", label: "Buprenorphine" },
  { value: "382", label: "Bupropion" },
  { value: "383", label: "Burinex" },
  { value: "384", label: "Burinex A" },
  { value: "385", label: "Burinex K" },
  { value: "386", label: "Buscopan" },
  { value: "100275", label: "Buserelin" },
  { value: "100276", label: "Busilvex" },
  { value: "387", label: "Buspar" },
  { value: "388", label: "Buspirone" },
  { value: "100277", label: "Busulfan" },
  { value: "389", label: "Butamide" },
  { value: "100278", label: "Butobarbital" },
  { value: "390", label: "BuTrans" },
  { value: "391", label: "Byetta" },
  { value: "100279", label: "C1-esterase inhibitor" },
  { value: "100280", label: "Cabaser" },
  { value: "392", label: "Cabergoline" },
  { value: "393", label: "Cabren" },
  { value: "394", label: "Cacit" },
  { value: "100281", label: "Cacit Tablets" },
  { value: "100282", label: "Cade Oil" },
  { value: "100283", label: "Cadicor" },
  { value: "395", label: "Caduet" },
  { value: "100284", label: "Caelyx" },
  { value: "100285", label: "Cafergot" },
  { value: "100286", label: "Caffeine" },
  { value: "100287", label: "Calamine" },
  { value: "100288", label: "Calceos" },
  { value: "100289", label: "Calchan MR" },
  { value: "100290", label: "Calcicard CR" },
  { value: "396", label: "Calcichew" },
  { value: "100291", label: "Calcijex" },
  { value: "397", label: "Calcipotriol" },
  { value: "398", label: "Calcitonin" },
  { value: "399", label: "Calcitriol" },
  { value: "100292", label: "Calcium" },
  { value: "100293", label: "Calcium acetate" },
  { value: "100294", label: "Calcium and Ergocalciferol" },
  { value: "100295", label: "Calcium carbonate" },
  { value: "100296", label: "Calcium channel blocker - Other" },
  { value: "100297", label: "Calcium gluconate" },
  { value: "100298", label: "Calcium lactate" },
  { value: "100299", label: "Calcium levofolinate" },
  { value: "100300", label: "Calcium Phosphate" },
  { value: "100301", label: "Calcium Resonium" },
  { value: "100302", label: "Calcium-Sandoz" },
  { value: "100303", label: "Calcort" },
  { value: "100304", label: "Calfovit D3" },
  { value: "100305", label: "Calgel" },
  { value: "100306", label: "Calmurid" },
  { value: "100307", label: "Calmurid HC" },
  { value: "100308", label: "Calogen" },
  { value: "100309", label: "Caloreen" },
  { value: "100310", label: "Calpol Paediatric" },
  { value: "100311", label: "Calshake" },
  { value: "400", label: "Calsynar" },
  { value: "100312", label: "CAM" },
  { value: "100313", label: "Camcolit" },
  { value: "401", label: "Campath" },
  { value: "100314", label: "Camphor" },
  { value: "100315", label: "Campral EC" },
  { value: "100316", label: "Campto" },
  { value: "100317", label: "Canagliflozin" },
  { value: "100318", label: "Cancidas" },
  { value: "402", label: "Candazole" },
  { value: "100319", label: "Candesartan" },
  { value: "403", label: "Candesartan Ciletexil" },
  { value: "100320", label: "Candesartan Cilexetil" },
  { value: "100321", label: "Canesten Cream" },
  { value: "100322", label: "Canesten Pessary" },
  { value: "100323", label: "Canesten Solution" },
  { value: "100324", label: "Canesten-HC" },
  { value: "100325", label: "Cannabidiol" },
  { value: "100326", label: "Canusal" },
  { value: "100327", label: "Capasal" },
  { value: "100328", label: "Capastat" },
  { value: "404", label: "Capecitabine" },
  { value: "100329", label: "Caphosol" },
  { value: "405", label: "Capoten" },
  { value: "406", label: "Capozide" },
  { value: "100330", label: "Capreomycin" },
  { value: "100331", label: "Caprilon" },
  { value: "100332", label: "Caprin" },
  { value: "407", label: "Capsaicin" },
  { value: "100333", label: "Capsicum Oleoresin" },
  { value: "408", label: "Captopril" },
  { value: "409", label: "Carace" },
  { value: "100334", label: "Carace 10 Plus" },
  { value: "100335", label: "Carace Plus" },
  { value: "100336", label: "Caralpha" },
  { value: "100337", label: "Carbaglu" },
  { value: "410", label: "Carbamazepine" },
  { value: "100338", label: "Carbetocin" },
  { value: "411", label: "Carbidopa" },
  { value: "412", label: "Carbimazole" },
  { value: "413", label: "Carbocisteine" },
  { value: "100339", label: "Carbo-Dome" },
  { value: "414", label: "Carbomer" },
  { value: "415", label: "Carboplatin" },
  { value: "100340", label: "Carboprost" },
  { value: "416", label: "Cardene" },
  { value: "100341", label: "Cardene SR" },
  { value: "417", label: "Cardensiel" },
  { value: "418", label: "Cardicor" },
  { value: "419", label: "Cardioplen" },
  { value: "100342", label: "Cardioplen XL" },
  { value: "100343", label: "Cardioxane" },
  { value: "420", label: "Cardipril" },
  { value: "421", label: "Cardoxin" },
  { value: "422", label: "Cardozin XL" },
  { value: "423", label: "Cardura" },
  { value: "100344", label: "Cardura XL" },
  { value: "424", label: "Carduran" },
  { value: "425", label: "Cardyl" },
  { value: "100345", label: "Care Olive Oil Ear Drops" },
  { value: "100346", label: "Care Virasoothe" },
  { value: "100347", label: "Carglumic acid" },
  { value: "426", label: "Carloc" },
  { value: "100348", label: "Carmellose sodium" },
  { value: "427", label: "Carteolol" },
  { value: "428", label: "Carvedilol" },
  { value: "429", label: "Casodex" },
  { value: "100349", label: "Caspofungin" },
  { value: "100350", label: "Castor Oil" },
  { value: "100351", label: "Catacrom" },
  { value: "430", label: "Catapres" },
  { value: "100352", label: "Caverject Dual Chamber" },
  { value: "100353", label: "Cayston" },
  { value: "100354", label: "Ceanel Conc." },
  { value: "100355", label: "Cedocard retard" },
  { value: "100356", label: "Cefaclor" },
  { value: "431", label: "Cefadroxil" },
  { value: "432", label: "Cefalexin" },
  { value: "100357", label: "Cefixime" },
  { value: "100358", label: "Cefotaxime" },
  { value: "100359", label: "Cefpodoxime" },
  { value: "433", label: "Cefradine" },
  { value: "100360", label: "Ceftazidime" },
  { value: "100361", label: "Ceftriaxone" },
  { value: "100362", label: "Cefuroxime" },
  { value: "100363", label: "Celance" },
  { value: "434", label: "Celebrex" },
  { value: "435", label: "Celecoxib" },
  { value: "436", label: "Celectol" },
  { value: "100364", label: "Celevac" },
  { value: "437", label: "Celiprolol" },
  { value: "438", label: "Cellcept" },
  { value: "100365", label: "Celluvisc" },
  { value: "100366", label: "Celsentri" },
  { value: "100367", label: "Celvapan" },
  { value: "439", label: "Centyl K" },
  { value: "100368", label: "Ceplene" },
  { value: "100369", label: "Ceporex" },
  { value: "100370", label: "Ceprotin" },
  { value: "100371", label: "Cepton Medicated Skin Wash" },
  { value: "440", label: "Certican" },
  { value: "441", label: "Certolizumab pegol" },
  { value: "100372", label: "Cerumol" },
  { value: "100373", label: "Cervarix" },
  { value: "100374", label: "Cetalkonium Chloride" },
  { value: "100375", label: "Cetavlex" },
  { value: "442", label: "Cetirizine" },
  { value: "100376", label: "Cetraben" },
  { value: "100377", label: "Cetrimide" },
  { value: "100378", label: "Cetrorelix" },
  { value: "100379", label: "Cetrotide" },
  { value: "100380", label: "Cetylpyridinium Chloride" },
  { value: "443", label: "Champix" },
  { value: "100381", label: "Charcoal" },
  { value: "100382", label: "Charcodote" },
  { value: "444", label: "Chemydur" },
  { value: "100383", label: "Chemydur 60XL" },
  { value: "100384", label: "Chloral hydrate" },
  { value: "445", label: "Chlorambucil" },
  { value: "446", label: "Chloramphenicol" },
  { value: "100385", label: "Chloraprep" },
  { value: "447", label: "Chlordiazepoxide" },
  { value: "448", label: "Chlorhexidine" },
  { value: "100386", label: "Chlorhexidine gluconate" },
  { value: "449", label: "Chloripramine" },
  { value: "100387", label: "Chlorobutanol" },
  { value: "100388", label: "Chloromycetin" },
  { value: "450", label: "Chloroquine" },
  { value: "100389", label: "Chloroxylenol" },
  { value: "451", label: "Chlorphenamine" },
  { value: "452", label: "Chlorpromazine" },
  { value: "100390", label: "Chlorpropamide" },
  { value: "453", label: "Chlortalidone" },
  { value: "454", label: "Cholestagel" },
  { value: "100391", label: "Cholesterol treatment - Other" },
  { value: "455", label: "Cholestin" },
  { value: "100392", label: "Choline Salicylate" },
  { value: "100393", label: "Choragon" },
  { value: "100394", label: "Choriogonadotropin alfa" },
  { value: "100395", label: "Chorionic gonadotrophin" },
  { value: "456", label: "Cialis" },
  { value: "457", label: "Ciclesonide" },
  { value: "458", label: "Ciclofalina" },
  { value: "459", label: "Ciclosporin" },
  { value: "460", label: "Cidine" },
  { value: "100396", label: "Cidofovir" },
  { value: "100397", label: "Cidomycin Paediatric" },
  { value: "100398", label: "Cilastatin" },
  { value: "461", label: "Cilazapril" },
  { value: "462", label: "Cilift" },
  { value: "463", label: "Cilostazol" },
  { value: "100399", label: "Ciloxan" },
  { value: "464", label: "Cimetidine" },
  { value: "465", label: "Cimzia" },
  { value: "100400", label: "Cinacalcet" },
  { value: "466", label: "Cinchocaine" },
  { value: "467", label: "Cinitapride" },
  { value: "100401", label: "Cinnamic Acid" },
  { value: "468", label: "Cinnarizine" },
  { value: "469", label: "Cipralex" },
  { value: "100402", label: "Cipramil" },
  { value: "470", label: "Ciprofibrate" },
  { value: "471", label: "Ciprofloxacin" },
  { value: "472", label: "Ciproxin" },
  { value: "100403", label: "Circadin" },
  { value: "100404", label: "Cisatracurium" },
  { value: "473", label: "Cisplatin" },
  { value: "474", label: "Citalopram" },
  { value: "100405", label: "Citanest" },
  { value: "100406", label: "Citramag" },
  { value: "100407", label: "Citric Acid" },
  { value: "100408", label: "Cladribine" },
  { value: "100409", label: "Clairette" },
  { value: "100410", label: "Clarelux" },
  { value: "475", label: "Clarithromycin" },
  { value: "100411", label: "Clavulanic Acid" },
  { value: "100412", label: "Clemastine" },
  { value: "476", label: "Clenil Modulite" },
  { value: "477", label: "Cleocin" },
  { value: "478", label: "Clexane" },
  { value: "100413", label: "Climagest" },
  { value: "100414", label: "Climanor" },
  { value: "479", label: "Climaval" },
  { value: "480", label: "Climesse" },
  { value: "481", label: "Clindamycin" },
  { value: "100415", label: "Clinitas" },
  { value: "100416", label: "Clinorette" },
  { value: "482", label: "Clioquinol" },
  { value: "100417", label: "Clipper" },
  { value: "483", label: "Clobazam" },
  { value: "484", label: "Clobetasol" },
  { value: "100418", label: "Clobetasol Propionate" },
  { value: "485", label: "Clobetasone" },
  { value: "100419", label: "Clobetasone butyrate" },
  { value: "486", label: "Clodronate" },
  { value: "100420", label: "Clofarabine" },
  { value: "100421", label: "Clomethiazole" },
  { value: "100422", label: "Clomid" },
  { value: "100423", label: "Clomifene" },
  { value: "487", label: "Clomipramine" },
  { value: "488", label: "Clonazepam" },
  { value: "489", label: "Clonidine" },
  { value: "100424", label: "Clonidine hydrochloride" },
  { value: "100425", label: "Clopamide" },
  { value: "490", label: "Clopidogrel" },
  { value: "100426", label: "Clopixol" },
  { value: "491", label: "Cloral Betaine" },
  { value: "100427", label: "Clotam Rapid" },
  { value: "492", label: "Clotrimazole" },
  { value: "493", label: "Clozapine" },
  { value: "100434", label: "CoaguChek PT" },
  { value: "100435", label: "Coal tar" },
  { value: "494", label: "Co-amilofruse" },
  { value: "495", label: "Co-amilozide" },
  { value: "496", label: "Co-Amoxiclav" },
  { value: "497", label: "CoAprovel" },
  { value: "498", label: "Co-beneldopa" },
  { value: "100428", label: "Co-Betaloc" },
  { value: "499", label: "Co-careldopa" },
  { value: "500", label: "Co-Codamol" },
  { value: "100429", label: "Co-Codaprin" },
  { value: "100436", label: "Cocois" },
  { value: "100437", label: "Cod Liver Oil" },
  { value: "501", label: "Co-danthramer" },
  { value: "502", label: "Co-danthrusate" },
  { value: "503", label: "Codeine" },
  { value: "100438", label: "Codeine Phosphate" },
  { value: "504", label: "Codeisan" },
  { value: "505", label: "Co-diovan" },
  { value: "506", label: "Codipar" },
  { value: "507", label: "Co-dydramol" },
  { value: "100430", label: "Co-Fluampicil" },
  { value: "100431", label: "Co-flumactone" },
  { value: "508", label: "Colazide" },
  { value: "509", label: "Colchicine" },
  { value: "510", label: "Colecalciferol" },
  { value: "511", label: "Colesevelam" },
  { value: "512", label: "Colestid" },
  { value: "513", label: "Colestipol" },
  { value: "514", label: "Colestyramine" },
  { value: "100439", label: "Colifoam" },
  { value: "100440", label: "Colistimethate sodium" },
  { value: "515", label: "Colistin" },
  { value: "100441", label: "Collagen" },
  { value: "516", label: "Colofac" },
  { value: "100442", label: "Colofac MR" },
  { value: "517", label: "Colomycin" },
  { value: "518", label: "Colpermin" },
  { value: "519", label: "Co-magaldrox" },
  { value: "100443", label: "Combigan" },
  { value: "520", label: "Combivent" },
  { value: "100444", label: "Combivent UDV" },
  { value: "521", label: "Combivir" },
  { value: "100445", label: "Combodart" },
  { value: "522", label: "Competact" },
  { value: "100446", label: "Compound macrogol oral powder" },
  { value: "523", label: "Comtess" },
  { value: "100447", label: "Concerta XL" },
  { value: "100448", label: "Condyline" },
  { value: "100449", label: "Confidence Rings" },
  { value: "524", label: "Congescor" },
  { value: "100450", label: "Conotrane" },
  { value: "100451", label: "Contiflo XL" },
  { value: "100452", label: "Contigen" },
  { value: "100453", label: "Convulex" },
  { value: "525", label: "Copaxone" },
  { value: "100454", label: "Copegus" },
  { value: "526", label: "Co-phenotrope" },
  { value: "100432", label: "Co-Prenozide" },
  { value: "527", label: "Co-proxamol" },
  { value: "528", label: "Coracten" },
  { value: "100455", label: "Coracten SR" },
  { value: "100456", label: "Coracten XL" },
  { value: "529", label: "Cordarone X" },
  { value: "530", label: "Coreg" },
  { value: "531", label: "Corgard" },
  { value: "100457", label: "Corifollitropin alfa" },
  { value: "100458", label: "Corlan" },
  { value: "532", label: "Corodil Comp" },
  { value: "100459", label: "Coro-Nitro" },
  { value: "100460", label: "Coro-Nitro Pump Spray" },
  { value: "533", label: "Coropres" },
  { value: "100461", label: "Corsodyl" },
  { value: "100462", label: "Cortisone" },
  { value: "534", label: "Co-simalcite" },
  { value: "100463", label: "Cosmegen Lyovac" },
  { value: "100464", label: "CosmoFer" },
  { value: "100465", label: "Cosopt" },
  { value: "535", label: "Cotareg" },
  { value: "536", label: "Co-tenidone" },
  { value: "537", label: "Co-triamterzide" },
  { value: "538", label: "Co-trimoxazole" },
  { value: "539", label: "Coumadin" },
  { value: "100466", label: "Covermark" },
  { value: "540", label: "Coversyl" },
  { value: "100467", label: "Coversyl Arginine" },
  { value: "100468", label: "Coversyl Arginine Plus" },
  { value: "100469", label: "Coversyl Plus" },
  { value: "541", label: "Cozaar" },
  { value: "542", label: "Cozaar-Comp" },
  { value: "100470", label: "Cozaar-Comp 50/12.5" },
  { value: "100433", label: "Co-Zidocapt" },
  { value: "543", label: "Creon" },
  { value: "100471", label: "Creon 10000" },
  { value: "544", label: "Crestor" },
  { value: "100472", label: "Crinone" },
  { value: "100473", label: "Crixivan" },
  { value: "100474", label: "Crotamiton" },
  { value: "100475", label: "Cryogesic" },
  { value: "100476", label: "Crystacide" },
  { value: "100477", label: "Crystapen" },
  { value: "545", label: "CTD" },
  { value: "100478", label: "Cubicin" },
  { value: "100479", label: "Cuplex" },
  { value: "100480", label: "Curatoderm" },
  { value: "100481", label: "Curosurf" },
  { value: "100482", label: "Cutivate" },
  { value: "100483", label: "CX Powder" },
  { value: "546", label: "Cyanocobalamin" },
  { value: "100484", label: "Cyclimorph" },
  { value: "547", label: "Cyclizine" },
  { value: "100486", label: "Cyclogest" },
  { value: "548", label: "Cyclopenthiazide" },
  { value: "100487", label: "Cyclopentolate" },
  { value: "549", label: "Cyclophosphamide" },
  { value: "100485", label: "Cyclo-Progynova 2mg" },
  { value: "550", label: "Cyclosporin" },
  { value: "100488", label: "Cyklokapron Injection" },
  { value: "100489", label: "Cyklokapron Tablets" },
  { value: "551", label: "Cymbalta" },
  { value: "100490", label: "Cymevene" },
  { value: "100491", label: "Cyproheptadine" },
  { value: "100492", label: "Cyprostat" },
  { value: "100493", label: "Cyproterone" },
  { value: "552", label: "Cyproterone Acetate" },
  { value: "100494", label: "Cystadane" },
  { value: "100495", label: "Cystagon" },
  { value: "100496", label: "Cystine AA Supplement" },
  { value: "100497", label: "Cystrin" },
  { value: "100498", label: "Cytarabine" },
  { value: "100499", label: "Cytotec" },
  { value: "100501", label: "Dabigatran" },
  { value: "100502", label: "Dabigatran etexilate" },
  { value: "553", label: "Dacadis" },
  { value: "554", label: "Dacarbazine" },
  { value: "555", label: "Dacortin" },
  { value: "556", label: "Dafiro" },
  { value: "557", label: "Daktacort" },
  { value: "100503", label: "Daktarin" },
  { value: "100504", label: "Daktarin Oral Gel" },
  { value: "100505", label: "Dalacin C" },
  { value: "100506", label: "Dalacin Cream" },
  { value: "100507", label: "Dalacin T" },
  { value: "558", label: "Dalteparin" },
  { value: "100508", label: "Dalteparin Sodium" },
  { value: "100509", label: "Danaparoid sodium" },
  { value: "559", label: "Danazol" },
  { value: "100510", label: "Danol" },
  { value: "100511", label: "Dantrium" },
  { value: "560", label: "Dantrolene" },
  { value: "100512", label: "Dantrolene sodium" },
  { value: "100513", label: "Dantron" },
  { value: "561", label: "Daonil" },
  { value: "100514", label: "Dapagliflozin" },
  { value: "562", label: "Dapsone" },
  { value: "100515", label: "Daptomycin" },
  { value: "100516", label: "Daraprim" },
  { value: "563", label: "Darbepoetin Alfa" },
  { value: "100517", label: "Darifenacin" },
  { value: "100518", label: "Darunavir" },
  { value: "564", label: "Dasatinib" },
  { value: "100519", label: "Daunorubicin" },
  { value: "100520", label: "Daunoxome" },
  { value: "100521", label: "DDAVP" },
  { value: "100522", label: "DDAVP Nasal Solution" },
  { value: "100524", label: "Deca-Durabolin" },
  { value: "100525", label: "Decapeptyl SR" },
  { value: "100526", label: "Decubal Clinic" },
  { value: "100527", label: "Defanac SR" },
  { value: "100528", label: "Deferasirox" },
  { value: "100529", label: "Deferiprone" },
  { value: "100530", label: "Defibrillator - Implanted" },
  { value: "565", label: "Deflazacort" },
  { value: "100531", label: "Degarelix" },
  { value: "100532", label: "Delph SPF30" },
  { value: "100533", label: "Deltacortril" },
  { value: "566", label: "Deltacortril Enteric" },
  { value: "100534", label: "Deltastab" },
  { value: "100535", label: "Demeclocycline" },
  { value: "567", label: "Demerol" },
  { value: "100523", label: "De-Noltab" },
  { value: "100536", label: "Denosumab" },
  { value: "100537", label: "Denzapine" },
  { value: "568", label: "Depakote" },
  { value: "569", label: "Depamine" },
  { value: "570", label: "Depixol" },
  { value: "100539", label: "DepoCyte" },
  { value: "100538", label: "Depo-Medrone" },
  { value: "100540", label: "Deponit" },
  { value: "100541", label: "Dequaspray" },
  { value: "100542", label: "Dermacolor" },
  { value: "100543", label: "Dermalo" },
  { value: "100544", label: "Dermamist" },
  { value: "100545", label: "Dermatonics Heel Balm" },
  { value: "100546", label: "Dermax Therapeutic Shampoo" },
  { value: "100547", label: "Dermol" },
  { value: "100548", label: "Dermovate" },
  { value: "100549", label: "Dermovate-NN" },
  { value: "100550", label: "Deseril" },
  { value: "100551", label: "Desferal" },
  { value: "100552", label: "Desferrioxamine" },
  { value: "571", label: "Desloratadine" },
  { value: "100553", label: "DesmoMelt" },
  { value: "572", label: "Desmopressin" },
  { value: "573", label: "Desmospray" },
  { value: "100554", label: "Desmotabs" },
  { value: "100555", label: "Destolit" },
  { value: "574", label: "Detemir" },
  { value: "575", label: "Detensiel" },
  { value: "576", label: "Detrol LA" },
  { value: "577", label: "Detrunorm" },
  { value: "578", label: "Detrusitol" },
  { value: "579", label: "Dexamethasone" },
  { value: "580", label: "Dexamfetamine" },
  { value: "100556", label: "Dexedrine" },
  { value: "100557", label: "Dexeryl" },
  { value: "100558", label: "Dexibuprofen" },
  { value: "100559", label: "Deximune" },
  { value: "100560", label: "Dexketoprofen" },
  { value: "100561", label: "Dexrazoxane" },
  { value: "100562", label: "Dexsol" },
  { value: "100563", label: "Dextran" },
  { value: "100564", label: "Dextrogel" },
  { value: "100565", label: "Dextrose" },
  { value: "100566", label: "DF 118 Forte" },
  { value: "100500", label: "D-GAM" },
  { value: "100567", label: "DHC Continus" },
  { value: "100568", label: "diabact UBT" },
  { value: "100569", label: "Diabetes mellitus - Other Tablet" },
  { value: "581", label: "Diabex" },
  { value: "100570", label: "Diafalk" },
  { value: "582", label: "Diaformin" },
  { value: "583", label: "Diafusor" },
  { value: "100571", label: "Dialamine" },
  { value: "584", label: "Dialyvit" },
  { value: "585", label: "Diamicron" },
  { value: "100572", label: "Diamicron MR" },
  { value: "100573", label: "Diamorphine" },
  { value: "100574", label: "Diamox" },
  { value: "586", label: "Dianben" },
  { value: "100575", label: "Dianette" },
  { value: "587", label: "Diavan" },
  { value: "100576", label: "Diazemuls" },
  { value: "588", label: "Diazepam" },
  { value: "100577", label: "Diazepam Rectubes" },
  { value: "100578", label: "Diazoxide" },
  { value: "100579", label: "Dibenyline" },
  { value: "589", label: "Dichlotride" },
  { value: "590", label: "Diclofenac" },
  { value: "591", label: "Dicloflex" },
  { value: "592", label: "Diclomax Retard" },
  { value: "100580", label: "Diconal" },
  { value: "593", label: "Dicycloverine" },
  { value: "100581", label: "Dicynene" },
  { value: "100582", label: "Didanosine" },
  { value: "594", label: "Didronel" },
  { value: "100583", label: "Didronel PMO" },
  { value: "100584", label: "Dietary Specials" },
  { value: "595", label: "Diethylamine Salicylate" },
  { value: "596", label: "Diethylstilbestrol" },
  { value: "100585", label: "Differin" },
  { value: "100586", label: "Difflam" },
  { value: "100587", label: "Difflam Oral Rinse" },
  { value: "597", label: "Diflucan" },
  { value: "100588", label: "Diflucortolone valerate" },
  { value: "100589", label: "Digibind" },
  { value: "598", label: "Digitoxin" },
  { value: "599", label: "Digoxin" },
  { value: "100590", label: "Digoxin antibody" },
  { value: "600", label: "Dihydrocodeine" },
  { value: "601", label: "Diltiazem" },
  { value: "602", label: "Dilzem" },
  { value: "100591", label: "Dilzem SR" },
  { value: "100592", label: "Dilzem XL" },
  { value: "100593", label: "Dimenhydrinate" },
  { value: "100594", label: "Dimethyl Sulfoxide" },
  { value: "100595", label: "Dimeticone" },
  { value: "100596", label: "Dinoprostone" },
  { value: "100597", label: "Dioctyl" },
  { value: "100598", label: "Dioderm" },
  { value: "100599", label: "Dioralyte" },
  { value: "603", label: "Diovan" },
  { value: "100600", label: "Dipentum" },
  { value: "604", label: "Diphenhydramine" },
  { value: "100601", label: "Diphtheria Toxoid" },
  { value: "100602", label: "Dipipanone" },
  { value: "100603", label: "Diprivan" },
  { value: "100604", label: "Diprobase" },
  { value: "100605", label: "Diprobath" },
  { value: "605", label: "Diprosalic" },
  { value: "100606", label: "Diprosone" },
  { value: "606", label: "Dipyridamole" },
  { value: "100607", label: "Disipal" },
  { value: "100608", label: "Disodium Edetate" },
  { value: "607", label: "Disopyramide" },
  { value: "100609", label: "Distaclor MR" },
  { value: "100610", label: "Distamine" },
  { value: "100611", label: "Distigmine" },
  { value: "100612", label: "Disulfiram" },
  { value: "100613", label: "Dithranol" },
  { value: "100614", label: "Dithrocream" },
  { value: "100615", label: "Ditropan" },
  { value: "100616", label: "Diumide-K Continus" },
  { value: "100617", label: "Diuretic - Other" },
  { value: "608", label: "Diurexan" },
  { value: "609", label: "Dixarit" },
  { value: "610", label: "Docetaxel" },
  { value: "100618", label: "DocOmega" },
  { value: "611", label: "Docosahexaenoic acid" },
  { value: "612", label: "Docusate" },
  { value: "100619", label: "Docusate Sodium" },
  { value: "100620", label: "Docusol" },
  { value: "100621", label: "Dolenio" },
  { value: "100622", label: "Dolmatil" },
  { value: "613", label: "Dolodol" },
  { value: "614", label: "Domperidone" },
  { value: "615", label: "Donepezil" },
  { value: "100623", label: "Dopacard" },
  { value: "616", label: "Dopamine" },
  { value: "100624", label: "Dopexamine" },
  { value: "100625", label: "Dopram" },
  { value: "617", label: "Doralese" },
  { value: "100626", label: "Doribax" },
  { value: "100627", label: "Doripenem" },
  { value: "100628", label: "Dornase alfa" },
  { value: "618", label: "Dorzolamide" },
  { value: "619", label: "Dostinex" },
  { value: "620", label: "Dosulepin" },
  { value: "621", label: "Dothiepin" },
  { value: "100629", label: "Doublebase" },
  { value: "100630", label: "Dovobet" },
  { value: "100631", label: "Dovonex" },
  { value: "622", label: "Doxadura" },
  { value: "100632", label: "Doxapram" },
  { value: "100633", label: "Doxazisin" },
  { value: "623", label: "Doxazosin" },
  { value: "624", label: "Doxepin" },
  { value: "625", label: "Doxium" },
  { value: "626", label: "Doxorubicin" },
  { value: "100634", label: "Doxorubin" },
  { value: "627", label: "Doxycycline" },
  { value: "628", label: "Doxzogen" },
  { value: "100635", label: "Dozic" },
  { value: "100636", label: "Drapolene" },
  { value: "100637", label: "Driclor" },
  { value: "629", label: "Dronedarone" },
  { value: "100638", label: "Drospirenone" },
  { value: "100639", label: "Drotrecogin alfa" },
  { value: "100641", label: "Dtap-Ipv Vaccine" },
  { value: "100642", label: "Dtap-Ipv-Hib Vaccine" },
  { value: "100640", label: "Dt-Ipv Vaccine" },
  { value: "100643", label: "Duac" },
  { value: "100644", label: "Dukoral" },
  { value: "100645", label: "Dulcobalance" },
  { value: "100646", label: "Dulcolax Pico Perles" },
  { value: "630", label: "Duloxetine" },
  { value: "100647", label: "Duobar" },
  { value: "100648", label: "Duocal Super Soluble" },
  { value: "631", label: "Duodopa" },
  { value: "100649", label: "Duofilm" },
  { value: "632", label: "DuoTrav" },
  { value: "100650", label: "Duovent UDV" },
  { value: "633", label: "Durogesic" },
  { value: "100651", label: "Durogesic DTrans" },
  { value: "100652", label: "Durolane" },
  { value: "634", label: "Dutasteride" },
  { value: "635", label: "Dyazide" },
  { value: "100653", label: "Dydrogesterone" },
  { value: "100654", label: "Dyloject" },
  { value: "100655", label: "Dynastat" },
  { value: "100656", label: "Dyspamet" },
  { value: "100657", label: "Dysport" },
  { value: "100658", label: "Dytac" },
  { value: "636", label: "Dytide" },
  { value: "100659", label: "E.I.D." },
  { value: "100660", label: "E45" },
  { value: "100661", label: "EAA Supplement" },
  { value: "100662", label: "EarCalm" },
  { value: "100663", label: "Easiphen" },
  { value: "100664", label: "Easyhaler Beclometasone" },
  { value: "100665", label: "Easyhaler Budesonide" },
  { value: "100666", label: "Easyhaler Formoterol" },
  { value: "100667", label: "Easyhaler Salbutamol" },
  { value: "637", label: "Ebixa" },
  { value: "100668", label: "Ecalta" },
  { value: "638", label: "Eccoxolac" },
  { value: "100669", label: "Econazole" },
  { value: "100670", label: "Econazole nitrate" },
  { value: "100671", label: "Ecostatin" },
  { value: "639", label: "Ecotrin" },
  { value: "100672", label: "Eculizumab" },
  { value: "640", label: "ECX" },
  { value: "100673", label: "Eczmol" },
  { value: "641", label: "Edronax" },
  { value: "642", label: "Efavirenz" },
  { value: "100674", label: "Efcortesol" },
  { value: "643", label: "Efexor" },
  { value: "100675", label: "Efexor XL" },
  { value: "100676", label: "Effentora" },
  { value: "100677", label: "Effercitrate" },
  { value: "644", label: "Efient" },
  { value: "100678", label: "Eflornithine" },
  { value: "100679", label: "Efracea" },
  { value: "645", label: "Efudix" },
  { value: "646", label: "Eicosapentaenoic acid" },
  { value: "647", label: "Elantan" },
  { value: "100680", label: "Elantan LA" },
  { value: "100681", label: "Elaprase" },
  { value: "648", label: "Elavil" },
  { value: "100682", label: "Eldepryl" },
  { value: "100683", label: "Eldisine" },
  { value: "100684", label: "Electrolade" },
  { value: "100685", label: "Electrolytes" },
  { value: "100686", label: "Eletriptan" },
  { value: "100687", label: "Elidel" },
  { value: "649", label: "Elisor" },
  { value: "100688", label: "Elite" },
  { value: "650", label: "Elleste Duet" },
  { value: "100689", label: "Elleste Duet 1mg" },
  { value: "100690", label: "Elleste Duet Conti" },
  { value: "100691", label: "Elleste Solo" },
  { value: "100692", label: "Elleste Solo MX 40" },
  { value: "651", label: "Elocon" },
  { value: "100693", label: "Elonva" },
  { value: "100694", label: "Eloxatin" },
  { value: "100695", label: "Eltrombopag" },
  { value: "652", label: "Eltroxin" },
  { value: "100696", label: "Emadine" },
  { value: "653", label: "Emconcor" },
  { value: "654", label: "Emcor" },
  { value: "655", label: "Emcoretic" },
  { value: "100697", label: "Emedastine" },
  { value: "100698", label: "Emend" },
  { value: "100699", label: "Emeside Syrup" },
  { value: "100700", label: "Emflex" },
  { value: "100701", label: "Emla" },
  { value: "100702", label: "Emollin" },
  { value: "100703", label: "Empagliflozin" },
  { value: "100704", label: "Emselex" },
  { value: "100705", label: "Emsogen" },
  { value: "656", label: "Emtricitabine" },
  { value: "100706", label: "Emtriva" },
  { value: "100707", label: "Emulsiderm" },
  { value: "100708", label: "Emulsifying Ointment" },
  { value: "657", label: "Enalapril" },
  { value: "658", label: "Enbrel" },
  { value: "100709", label: "Endekay Fluotabs" },
  { value: "100710", label: "Energivit" },
  { value: "100711", label: "Enfuvirtide" },
  { value: "100712", label: "Engerix B" },
  { value: "659", label: "Enoxaparin" },
  { value: "100713", label: "Enshake" },
  { value: "660", label: "Ensure" },
  { value: "661", label: "Entacapone" },
  { value: "100714", label: "Entecavir" },
  { value: "100715", label: "Entocort CR" },
  { value: "100716", label: "Enzira" },
  { value: "100717", label: "Epaderm Cream" },
  { value: "662", label: "Epanutin" },
  { value: "100718", label: "Epanutin Parenteral" },
  { value: "663", label: "Epavirenz" },
  { value: "100719", label: "Epaxal" },
  { value: "664", label: "Ephedrine" },
  { value: "100720", label: "Epiduo" },
  { value: "665", label: "Epilim" },
  { value: "100721", label: "Epinastine" },
  { value: "666", label: "EpiPen" },
  { value: "667", label: "Epirubicin" },
  { value: "100722", label: "Episenta" },
  { value: "668", label: "Epistatus" },
  { value: "100723", label: "Epivir" },
  { value: "669", label: "Eplerenone" },
  { value: "670", label: "EPO" },
  { value: "671", label: "Epoetin" },
  { value: "100724", label: "Epoetin alfa" },
  { value: "100725", label: "Epoetin beta" },
  { value: "100726", label: "Epoetin theta" },
  { value: "100727", label: "Epoetin zeta" },
  { value: "100728", label: "Epoprostenol" },
  { value: "100729", label: "Eporatio" },
  { value: "100730", label: "Eposin" },
  { value: "672", label: "Eprex" },
  { value: "673", label: "Eprey" },
  { value: "674", label: "Eprosartan" },
  { value: "100731", label: "Eptacog alfa" },
  { value: "100732", label: "Eptadone" },
  { value: "100733", label: "Eptifibatide" },
  { value: "675", label: "Equanil" },
  { value: "100734", label: "Equasym XL" },
  { value: "100735", label: "Erbitux" },
  { value: "100736", label: "Erdosteine" },
  { value: "100737", label: "Erdotin" },
  { value: "100738", label: "ErectAssist" },
  { value: "100739", label: "ErectEase" },
  { value: "100740", label: "Ergocalciferol" },
  { value: "100741", label: "Ergometrine Maleate" },
  { value: "100742", label: "Ergotamine Tartrate" },
  { value: "676", label: "Erlotinib" },
  { value: "100743", label: "Ertapenem" },
  { value: "100744", label: "Eryacne" },
  { value: "100745", label: "Erymax" },
  { value: "100746", label: "Erythrocin" },
  { value: "677", label: "Erythromycin" },
  { value: "100747", label: "Erythroped A" },
  { value: "678", label: "Erythropoietin" },
  { value: "679", label: "Escitalopram" },
  { value: "100748", label: "Eslicarbazepine" },
  { value: "100749", label: "Esmeron" },
  { value: "100750", label: "Esmolol" },
  { value: "680", label: "Esomeprazole" },
  { value: "100751", label: "Estracyt" },
  { value: "681", label: "Estraderm" },
  { value: "100752", label: "Estraderm MX" },
  { value: "682", label: "Estradiol" },
  { value: "100753", label: "Estradiol Implant" },
  { value: "100754", label: "Estradot" },
  { value: "100755", label: "Estramustine" },
  { value: "100756", label: "Estring" },
  { value: "100757", label: "Estriol" },
  { value: "100758", label: "Estrone" },
  { value: "683", label: "Etalpha" },
  { value: "100759", label: "Etamsylate" },
  { value: "684", label: "Etanercept" },
  { value: "685", label: "Ethambutol" },
  { value: "100760", label: "Ethanolamine oleate" },
  { value: "100761", label: "Ethinylestradiol" },
  { value: "100762", label: "Ethosuximide" },
  { value: "100763", label: "Ethyl chloride" },
  { value: "100764", label: "Ethyl Nicotinate" },
  { value: "686", label: "Etidronate" },
  { value: "687", label: "Etodolac" },
  { value: "100765", label: "Etomidate" },
  { value: "100766", label: "Etopan XL" },
  { value: "100767", label: "Etopophos" },
  { value: "100768", label: "Etoposide" },
  { value: "688", label: "Etoricoxib" },
  { value: "689", label: "Etravirine" },
  { value: "100769", label: "Etrivex" },
  { value: "690", label: "Eucardic" },
  { value: "100770", label: "Eucerin" },
  { value: "691", label: "Eucreas" },
  { value: "100771", label: "Eudemine" },
  { value: "100772", label: "Euflexxa" },
  { value: "100773", label: "Euglucon" },
  { value: "100774", label: "Eumobase" },
  { value: "100775", label: "Eumovate" },
  { value: "692", label: "Eupantol" },
  { value: "100776", label: "Eurax" },
  { value: "100777", label: "Eurax-hydrocortisone" },
  { value: "693", label: "Eutirox" },
  { value: "100778", label: "Evening primrose oil" },
  { value: "694", label: "Everolimus" },
  { value: "695", label: "Evista" },
  { value: "100779", label: "Evoltra" },
  { value: "696", label: "Evorel" },
  { value: "100780", label: "Evorel Conti" },
  { value: "100781", label: "Evorel Sequi" },
  { value: "697", label: "Exanatide" },
  { value: "698", label: "Exelon" },
  { value: "699", label: "Exemestane" },
  { value: "100782", label: "Exenatide" },
  { value: "700", label: "Exforge" },
  { value: "100783", label: "Exjade" },
  { value: "100784", label: "Exocin" },
  { value: "100785", label: "Exorex" },
  { value: "100786", label: "Extavia" },
  { value: "100787", label: "Exterol" },
  { value: "100788", label: "Exubera" },
  { value: "701", label: "Ezetimibe" },
  { value: "702", label: "Ezetrol" },
  { value: "100789", label: "Factor Ii" },
  { value: "100790", label: "Factor Ix" },
  { value: "100791", label: "Factor Vii" },
  { value: "100792", label: "Factor VIII" },
  { value: "100793", label: "Factor X" },
  { value: "100794", label: "Factor XIII" },
  { value: "703", label: "Famciclovir" },
  { value: "704", label: "Famotidine" },
  { value: "705", label: "Famvir" },
  { value: "100795", label: "Fareston" },
  { value: "100796", label: "Fasigyn" },
  { value: "706", label: "Faslodex" },
  { value: "100797", label: "Fasturtec" },
  { value: "707", label: "Faverin" },
  { value: "708", label: "FCR" },
  { value: "100798", label: "Febuxostat" },
  { value: "100799", label: "Feiba" },
  { value: "100800", label: "Felbinac" },
  { value: "709", label: "Feldene" },
  { value: "100801", label: "Feldene Gel" },
  { value: "710", label: "Felendil" },
  { value: "711", label: "Felodipine" },
  { value: "712", label: "Felotens XL" },
  { value: "100802", label: "Femapak 40" },
  { value: "713", label: "Femara" },
  { value: "100803", label: "Fematrix 40" },
  { value: "100804", label: "Femmax" },
  { value: "100805", label: "Femoston 1/10" },
  { value: "100806", label: "Femoston-Conti" },
  { value: "100807", label: "Femseven" },
  { value: "100808", label: "FemSeven Conti" },
  { value: "100809", label: "FemSeven Sequi" },
  { value: "714", label: "Fenactol" },
  { value: "100810", label: "Fenbid Gel" },
  { value: "100811", label: "Fenbid Spansule" },
  { value: "715", label: "Fenbufen" },
  { value: "100812", label: "Fendrix" },
  { value: "716", label: "Fenofenadine" },
  { value: "717", label: "Fenofibrate" },
  { value: "100813", label: "Fenoprofen" },
  { value: "100814", label: "Fenopron" },
  { value: "100815", label: "Fenoterol" },
  { value: "100816", label: "Fentalis" },
  { value: "718", label: "Fentanyl" },
  { value: "100817", label: "Fentazin" },
  { value: "719", label: "Feospan" },
  { value: "100818", label: "Ferinject" },
  { value: "100819", label: "Ferric carboxymaltose" },
  { value: "100820", label: "Ferriprox" },
  { value: "100821", label: "Ferrograd" },
  { value: "100822", label: "Ferrograd Folic" },
  { value: "100823", label: "Ferrous fumarate" },
  { value: "720", label: "Ferrous Gluconate" },
  { value: "721", label: "Ferrous Sulphate" },
  { value: "722", label: "Fersaday" },
  { value: "723", label: "Fesoterodine" },
  { value: "724", label: "Fexofenadine" },
  { value: "725", label: "Fibrazate" },
  { value: "100825", label: "Fibrogammin P" },
  { value: "100824", label: "Fibro-Vein" },
  { value: "726", label: "Filair" },
  { value: "727", label: "Filgrastim" },
  { value: "100826", label: "Finacea" },
  { value: "728", label: "Finasteride" },
  { value: "100827", label: "Firazyr" },
  { value: "100828", label: "Firdapse" },
  { value: "100829", label: "Firmagon" },
  { value: "729", label: "Flagyl" },
  { value: "100830", label: "Flamasacard" },
  { value: "100831", label: "Flamazine" },
  { value: "100832", label: "FlavourPac" },
  { value: "100833", label: "Flavoxate" },
  { value: "100834", label: "Flebogammadif" },
  { value: "730", label: "Flecaine" },
  { value: "731", label: "Flecainide" },
  { value: "100835", label: "Fleet Phospho-Soda" },
  { value: "100836", label: "Fleet Ready-to-Use Enema" },
  { value: "100837", label: "Flexitol Heel Balm" },
  { value: "732", label: "Flixonase" },
  { value: "733", label: "Flixotide" },
  { value: "100838", label: "Flixotide Diskhaler" },
  { value: "100839", label: "Flolan" },
  { value: "734", label: "Flomax" },
  { value: "735", label: "Flomaxtra" },
  { value: "100840", label: "Flomaxtra XL" },
  { value: "100841", label: "Florinef" },
  { value: "100842", label: "Flotros" },
  { value: "736", label: "Flovent" },
  { value: "100843", label: "Flowfusor" },
  { value: "100844", label: "Floxapen" },
  { value: "737", label: "Fluanxol" },
  { value: "100845", label: "Fluarix" },
  { value: "738", label: "Flucloxacillin" },
  { value: "100846", label: "Flucloxacillin Sodium" },
  { value: "739", label: "Fluconazole" },
  { value: "100847", label: "Flucytosine" },
  { value: "100848", label: "Fludara" },
  { value: "740", label: "Fludarabine" },
  { value: "741", label: "Fludex" },
  { value: "742", label: "Fludrocortisone" },
  { value: "100849", label: "Fludroxycortide" },
  { value: "743", label: "Fluindione" },
  { value: "100850", label: "Flumazenil" },
  { value: "100851", label: "Flumetasone" },
  { value: "744", label: "Flunisolide" },
  { value: "745", label: "Fluocinolone" },
  { value: "100852", label: "Fluocinonide" },
  { value: "100853", label: "Fluocortolone" },
  { value: "100854", label: "Fluor-a-day" },
  { value: "100855", label: "Fluorescein" },
  { value: "100856", label: "Fluorigard" },
  { value: "100857", label: "Fluorometholone" },
  { value: "746", label: "Fluorouracil" },
  { value: "747", label: "Fluoxetine" },
  { value: "748", label: "Flupentixol" },
  { value: "100858", label: "Fluphenazine" },
  { value: "749", label: "Flurbiprofen" },
  { value: "750", label: "Flutamide" },
  { value: "751", label: "Fluticasone" },
  { value: "100859", label: "Fluticasone furoate" },
  { value: "100860", label: "Fluticasone propionate" },
  { value: "752", label: "Fluvastatin" },
  { value: "753", label: "Fluvestrant" },
  { value: "100861", label: "Fluvirin" },
  { value: "754", label: "Fluvoxamine" },
  { value: "100862", label: "FML" },
  { value: "755", label: "Folic acid" },
  { value: "100863", label: "Folinic acid" },
  { value: "100864", label: "Follitropin alfa" },
  { value: "100865", label: "Follitropin beta" },
  { value: "756", label: "Folpik" },
  { value: "100866", label: "Fondaparinux sodium" },
  { value: "757", label: "Foradil" },
  { value: "758", label: "Forceval" },
  { value: "759", label: "Forlax" },
  { value: "100867", label: "Formaldehyde" },
  { value: "760", label: "Formoterol" },
  { value: "100868", label: "Forsteo" },
  { value: "100869", label: "Forticare" },
  { value: "100870", label: "Fortini" },
  { value: "100871", label: "Fortipine LA" },
  { value: "100872", label: "Fortisip Bottle" },
  { value: "100873", label: "Fortum" },
  { value: "761", label: "Fortzaar" },
  { value: "762", label: "Fosamax" },
  { value: "100874", label: "Fosamax Once Weekly" },
  { value: "100875", label: "Fosamprenavir" },
  { value: "100876", label: "Fosaprepitant" },
  { value: "763", label: "Fosavance" },
  { value: "100877", label: "Foscan" },
  { value: "100878", label: "Foscarnet" },
  { value: "100879", label: "Foscavir" },
  { value: "764", label: "Fosinopril" },
  { value: "765", label: "Fosphenytoin" },
  { value: "766", label: "Fosrenol" },
  { value: "767", label: "Fostair" },
  { value: "100880", label: "Fostimon" },
  { value: "768", label: "Fozitec" },
  { value: "100881", label: "Frador" },
  { value: "769", label: "Fragmin" },
  { value: "100882", label: "Framycetin Sulphate" },
  { value: "100883", label: "Frisium" },
  { value: "100884", label: "Froben SR" },
  { value: "100885", label: "Frovatriptan" },
  { value: "100886", label: "Fru-Co" },
  { value: "770", label: "Frumil" },
  { value: "100887", label: "Frusemide" },
  { value: "100888", label: "Frusene" },
  { value: "100889", label: "Frusol" },
  { value: "771", label: "FuciBET" },
  { value: "100890", label: "Fucidin" },
  { value: "100891", label: "Fucidin H Cream" },
  { value: "772", label: "Fucithalmic" },
  { value: "100892", label: "Fulsovin" },
  { value: "100893", label: "Fulvestrant" },
  { value: "100894", label: "Fungizone" },
  { value: "100895", label: "Furadantin" },
  { value: "773", label: "Furosemide" },
  { value: "100896", label: "Fusidic acid" },
  { value: "774", label: "Fuzatal" },
  { value: "100897", label: "Fuzeon" },
  { value: "775", label: "Fybogel" },
  { value: "100898", label: "Fybogel Mebeverine" },
  { value: "100899", label: "GA Gel" },
  { value: "776", label: "Gabapentin" },
  { value: "777", label: "Galantamine" },
  { value: "100900", label: "Galenphol Paed Linctus" },
  { value: "100901", label: "Galfer" },
  { value: "100902", label: "Galpseud" },
  { value: "100903", label: "Galpseud Plus" },
  { value: "100904", label: "Galsud" },
  { value: "100905", label: "Galvus" },
  { value: "778", label: "Gambaran" },
  { value: "100906", label: "Gammagard S/D" },
  { value: "100907", label: "Gammaplex" },
  { value: "100908", label: "Ganciclovir" },
  { value: "100909", label: "Ganfort" },
  { value: "100910", label: "Ganirelix" },
  { value: "100911", label: "Gardasil" },
  { value: "100912", label: "Gastrocote" },
  { value: "100913", label: "Gaviscon Advance" },
  { value: "100914", label: "Gefitinib" },
  { value: "100915", label: "Gelatin" },
  { value: "100916", label: "Gelclair" },
  { value: "100917", label: "GelTears" },
  { value: "779", label: "GemCis" },
  { value: "780", label: "Gemcisplat" },
  { value: "781", label: "Gemcitabine" },
  { value: "782", label: "Gemfibrozil" },
  { value: "783", label: "Gemzar" },
  { value: "100918", label: "Generaid" },
  { value: "100919", label: "Generaid Plus" },
  { value: "100920", label: "Generpharm" },
  { value: "100921", label: "Genesis Rings" },
  { value: "100922", label: "Gengigel" },
  { value: "784", label: "Genotropin" },
  { value: "785", label: "Gentamicin" },
  { value: "100923", label: "Gentamicin Sulphate" },
  { value: "100924", label: "Genticin" },
  { value: "100925", label: "Genticin Injection" },
  { value: "100926", label: "Gentisone HC" },
  { value: "100927", label: "Gestone" },
  { value: "100928", label: "Glandosane" },
  { value: "786", label: "Glatiramer" },
  { value: "100929", label: "Gliadel" },
  { value: "787", label: "Glibenclamide" },
  { value: "100930", label: "Glibenese" },
  { value: "788", label: "Gliclazide" },
  { value: "789", label: "Glimepiride" },
  { value: "790", label: "Glipizide" },
  { value: "791", label: "Gliquidone" },
  { value: "792", label: "Glivec" },
  { value: "100931", label: "GlucaGen" },
  { value: "100932", label: "Glucagon" },
  { value: "793", label: "Glucobay" },
  { value: "100933", label: "GlucoGel" },
  { value: "794", label: "Gluconorm" },
  { value: "795", label: "Glucophage" },
  { value: "100934", label: "Glucophage SR" },
  { value: "796", label: "Glucor" },
  { value: "100935", label: "Glucosamine" },
  { value: "797", label: "Glucosamine Hydrochloride" },
  { value: "798", label: "Glucosamine Sulphate" },
  { value: "100936", label: "Glucose" },
  { value: "799", label: "Glucovance" },
  { value: "800", label: "Glucozene" },
  { value: "801", label: "Glurenorm" },
  { value: "100937", label: "Glusartel" },
  { value: "100938", label: "Glutafin" },
  { value: "100939", label: "Glutaraldehyde" },
  { value: "100940", label: "Glutarol" },
  { value: "802", label: "Glyburide" },
  { value: "100941", label: "Glycerdex" },
  { value: "100942", label: "Glycerin" },
  { value: "100943", label: "Glycerol" },
  { value: "803", label: "Glyceryl Trinitrate" },
  { value: "100944", label: "Glycogen" },
  { value: "100945", label: "Glycopyrronium bromide" },
  { value: "100946", label: "Glycosade" },
  { value: "804", label: "Glypressin" },
  { value: "805", label: "Glytrin" },
  { value: "100947", label: "Glytrin Spray" },
  { value: "100948", label: "Golden Eye" },
  { value: "100949", label: "Gonal-F" },
  { value: "100950", label: "Gonapeptyl" },
  { value: "806", label: "Gopten" },
  { value: "807", label: "Goserelin" },
  { value: "100951", label: "Gramicidin" },
  { value: "100952", label: "Granisetron" },
  { value: "100953", label: "Granocyte" },
  { value: "100954", label: "Grazax" },
  { value: "808", label: "Grepid" },
  { value: "100955", label: "Griseofulvin" },
  { value: "100956", label: "Grisol AF" },
  { value: "809", label: "GTN" },
  { value: "100957", label: "Guanethidine" },
  { value: "810", label: "Gutron" },
  { value: "100958", label: "Gynest Cream" },
  { value: "100959", label: "Gyno-Daktarin 1" },
  { value: "100960", label: "Gyno-Pevaryl 1" },
  { value: "100961", label: "Haelan" },
  { value: "100962", label: "Haemate P" },
  { value: "100963", label: "Haemophilus Influenzae Type B" },
  { value: "100964", label: "Haldol" },
  { value: "811", label: "Half Beta Prograne" },
  { value: "100965", label: "Half-Inderal LA" },
  { value: "812", label: "Haloperidol" },
  { value: "100966", label: "Harifen" },
  { value: "100967", label: "Havrix Monodose" },
  { value: "100968", label: "Hay-Crom" },
  { value: "100969", label: "Haymine" },
  { value: "100970", label: "HBvaxPRO" },
  { value: "813", label: "HCTZ" },
  { value: "100971", label: "HCU Express" },
  { value: "100972", label: "HCU-LV" },
  { value: "814", label: "Helicidine" },
  { value: "100973", label: "Helicobacter Test Infai" },
  { value: "100974", label: "Helixate NexGen" },
  { value: "100975", label: "Hemabate" },
  { value: "815", label: "Hemigoxine" },
  { value: "100976", label: "Hemin" },
  { value: "100977", label: "Heminevrin" },
  { value: "100978", label: "Hemocane" },
  { value: "816", label: "Heparin" },
  { value: "100979", label: "Heparinoid" },
  { value: "100980", label: "Heparon Junior" },
  { value: "100981", label: "Hepatitis A" },
  { value: "100982", label: "Hepatitis B" },
  { value: "100983", label: "Hepatyrix" },
  { value: "100984", label: "Hepsal" },
  { value: "100985", label: "Hepsera" },
  { value: "817", label: "Herceptin" },
  { value: "100986", label: "Herpid" },
  { value: "100987", label: "Hexetidine" },
  { value: "100988", label: "Hexopal" },
  { value: "100989", label: "Hexyl Nicotinate" },
  { value: "100990", label: "Hib Vaccine" },
  { value: "100991", label: "Hiberix" },
  { value: "100992", label: "HiBi Liquid Hand Rub+" },
  { value: "100993", label: "Hibi Tane Plus" },
  { value: "100994", label: "Hibiscrub" },
  { value: "818", label: "Hidrosaluretil" },
  { value: "819", label: "Hidroxil" },
  { value: "100995", label: "Hiprex" },
  { value: "100996", label: "Hirudoid" },
  { value: "100997", label: "Histamine" },
  { value: "100998", label: "Histrelin" },
  { value: "820", label: "Hjerdyl" },
  { value: "100999", label: "Homatropine" },
  { value: "101000", label: "Hormonin" },
  { value: "821", label: "Humalog" },
  { value: "101001", label: "Humalog Mix25" },
  { value: "822", label: "Human Insulatard" },
  { value: "823", label: "Human Mixtard" },
  { value: "101002", label: "Human papillomavirus vaccine" },
  { value: "101003", label: "Human protein C" },
  { value: "101004", label: "Humatrope" },
  { value: "824", label: "Humira" },
  { value: "825", label: "Humulin" },
  { value: "101005", label: "Humulin I" },
  { value: "101006", label: "Humulin M3" },
  { value: "101007", label: "Humulin S" },
  { value: "101008", label: "Hyabak" },
  { value: "101009", label: "Hyalase" },
  { value: "101010", label: "Hyalgan" },
  { value: "101011", label: "Hyaluronic acid" },
  { value: "101012", label: "Hyaluronidase" },
  { value: "101013", label: "Hycamtin" },
  { value: "826", label: "Hydralazine" },
  { value: "827", label: "Hydrea" },
  { value: "101014", label: "Hydrex" },
  { value: "828", label: "Hydrochlorothiazide" },
  { value: "829", label: "Hydrocortisone" },
  { value: "101015", label: "Hydrocortisone butyrate" },
  { value: "101016", label: "Hydrocortistab" },
  { value: "101017", label: "Hydroflumethiazide" },
  { value: "101018", label: "Hydrogen peroxide" },
  { value: "101019", label: "Hydromol" },
  { value: "101020", label: "Hydromoor" },
  { value: "101021", label: "Hydromorphone" },
  { value: "830", label: "Hydroxocobalamin" },
  { value: "831", label: "Hydroxycarbamide" },
  { value: "832", label: "Hydroxychloroquine" },
  { value: "101022", label: "Hydroxyethylcellulose" },
  { value: "101023", label: "Hydroxypropyl Guar" },
  { value: "833", label: "Hydroxyurea" },
  { value: "834", label: "Hydroxyzine" },
  { value: "835", label: "Hygroton" },
  { value: "101024", label: "Hylan" },
  { value: "101025", label: "Hylo-Tear" },
  { value: "836", label: "Hyoscine" },
  { value: "837", label: "Hyoscine butylbromide" },
  { value: "838", label: "Hyperium" },
  { value: "101026", label: "Hypnomidate" },
  { value: "101027", label: "Hypnovel" },
  { value: "101028", label: "Hypo-Fit" },
  { value: "839", label: "Hypovase" },
  { value: "840", label: "Hypromellose" },
  { value: "841", label: "Hypurin" },
  { value: "101029", label: "Hypurin Bovine Isophane" },
  { value: "101030", label: "Hypurin Bovine Lente" },
  { value: "101031", label: "Hypurin Bovine Neutral" },
  { value: "101032", label: "Hypurin Bovine PZI" },
  { value: "101033", label: "Hypurin Porcine 30/70" },
  { value: "101034", label: "Hypurin Porcine Isophane" },
  { value: "101035", label: "Hypurin Porcine Neutral" },
  { value: "842", label: "Hytacand" },
  { value: "843", label: "Hytrin" },
  { value: "844", label: "Hyzaar" },
  { value: "101036", label: "Ibandronate" },
  { value: "845", label: "Ibandronic Acid" },
  { value: "101037", label: "Ibugel" },
  { value: "846", label: "Ibuprofen" },
  { value: "101038", label: "Icatibant acetate" },
  { value: "101039", label: "ICD" },
  { value: "101040", label: "Idarubicin" },
  { value: "101041", label: "Idoxuridine" },
  { value: "101042", label: "Idursulfase" },
  { value: "847", label: "Ikorel" },
  { value: "848", label: "Iloprost" },
  { value: "849", label: "Imanitib" },
  { value: "101043", label: "Imatinib" },
  { value: "850", label: "Imdur" },
  { value: "851", label: "Imidapril" },
  { value: "852", label: "Imigran" },
  { value: "101044", label: "Imipenem" },
  { value: "853", label: "Imipramine" },
  { value: "854", label: "Imiquimod" },
  { value: "101045", label: "Immunoglobulin" },
  { value: "855", label: "Imovane" },
  { value: "101046", label: "Implanted cardioverter defibrillator" },
  { value: "101047", label: "Implanted Defibrillator" },
  { value: "856", label: "Imuran" },
  { value: "857", label: "Imurel" },
  { value: "858", label: "Inacid" },
  { value: "101048", label: "Indacaterol" },
  { value: "859", label: "Indapamide" },
  { value: "860", label: "Inderal" },
  { value: "101049", label: "Inderal LA" },
  { value: "101050", label: "Indinavir" },
  { value: "861", label: "Indivina" },
  { value: "862", label: "Indometacin" },
  { value: "863", label: "Indoramin" },
  { value: "864", label: "Inegy" },
  { value: "865", label: "Inexium" },
  { value: "866", label: "Infliximab" },
  { value: "101051", label: "Influenza vaccine" },
  { value: "101052", label: "Influvac" },
  { value: "101053", label: "Inhaler" },
  { value: "101054", label: "Inhalers" },
  { value: "101055", label: "Innohep" },
  { value: "867", label: "Innovace" },
  { value: "868", label: "Innozide" },
  { value: "101056", label: "Inositol nicotinate" },
  { value: "101057", label: "Inovelon" },
  { value: "869", label: "Inspra" },
  { value: "101058", label: "Instanyl" },
  { value: "101059", label: "Instillagel" },
  { value: "870", label: "Insulatard" },
  { value: "101060", label: "Insulin" },
  { value: "101061", label: "Insulin Aspart" },
  { value: "101062", label: "Insulin Detemir" },
  { value: "101063", label: "Insulin Glargine" },
  { value: "101064", label: "Insulin Glulisine" },
  { value: "101065", label: "Insulin Injection" },
  { value: "101066", label: "Insulin Lispro" },
  { value: "101067", label: "Insulin Zinc Suspension" },
  { value: "101068", label: "Insuman" },
  { value: "101069", label: "Insuman Basal" },
  { value: "101070", label: "Insuman Comb" },
  { value: "101071", label: "Insuman Rapid" },
  { value: "101072", label: "Intal Inhaler" },
  { value: "101073", label: "Integrilin" },
  { value: "871", label: "Intelence" },
  { value: "101074", label: "Interferon alfa" },
  { value: "872", label: "Interferon Alpha" },
  { value: "873", label: "Interferon Beta" },
  { value: "874", label: "Interferon Gamma" },
  { value: "875", label: "Interleukin 2" },
  { value: "101075", label: "Intrinsa" },
  { value: "101076", label: "IntronA" },
  { value: "101077", label: "Invanz" },
  { value: "101078", label: "Invega" },
  { value: "101079", label: "Invirase" },
  { value: "101080", label: "Iopidine" },
  { value: "101081", label: "Ipocol" },
  { value: "876", label: "Ipratropium Bromide" },
  { value: "101082", label: "Ipratropium Steri-Neb" },
  { value: "877", label: "Irbesartan" },
  { value: "101083", label: "Iressa" },
  { value: "101084", label: "Irinotecan" },
  { value: "101085", label: "Iron-hydroxide dextran complex" },
  { value: "101086", label: "Iron-hydroxide sucrose complex" },
  { value: "101087", label: "Irriclens" },
  { value: "101088", label: "Irripod" },
  { value: "878", label: "Iscover" },
  { value: "101089", label: "Isentress" },
  { value: "879", label: "Isib" },
  { value: "101090", label: "Isib 60 XL" },
  { value: "101091", label: "Ismelin" },
  { value: "880", label: "ISMN" },
  { value: "881", label: "Ismo" },
  { value: "101092", label: "Ismo Retard" },
  { value: "101093", label: "Isocarboxazid" },
  { value: "101094", label: "Isodur XL" },
  { value: "882", label: "Isogel" },
  { value: "101095", label: "Isoket" },
  { value: "101096", label: "Isoket Infusion" },
  { value: "101097", label: "Isoket retard" },
  { value: "101098", label: "Isoleucine" },
  { value: "101099", label: "Isoleucine AA Supplement" },
  { value: "101100", label: "Isometheptene" },
  { value: "883", label: "Isoniazid" },
  { value: "101101", label: "Isophane Insulin" },
  { value: "884", label: "Isopropyl Myristate" },
  { value: "101102", label: "Isopto Alkaline" },
  { value: "101103", label: "Isopto Plain" },
  { value: "885", label: "Isosorbide Dinitrate" },
  { value: "886", label: "Isosorbide Mononitrate" },
  { value: "887", label: "Isotard" },
  { value: "101104", label: "Isotard XL" },
  { value: "888", label: "Isotretinoin" },
  { value: "101105", label: "Isotrex" },
  { value: "101106", label: "Isotrexin" },
  { value: "101107", label: "Isovorin" },
  { value: "101108", label: "Ispagel" },
  { value: "889", label: "Ispaghula" },
  { value: "890", label: "Isradipine" },
  { value: "891", label: "Istin" },
  { value: "892", label: "Itraconazole" },
  { value: "893", label: "Ivabradine" },
  { value: "101109", label: "Ivemend" },
  { value: "894", label: "Ixia" },
  { value: "101110", label: "Ixiaro" },
  { value: "101111", label: "Janumet" },
  { value: "895", label: "Januvia" },
  { value: "101112", label: "Japanese encephalitis" },
  { value: "101113", label: "Javlor" },
  { value: "101114", label: "Jet Nebuliser" },
  { value: "101115", label: "Juvela Gluten-Free" },
  { value: "101116", label: "Juvela Low Protein" },
  { value: "896", label: "Kaletra" },
  { value: "897", label: "Kalms" },
  { value: "898", label: "Kalpress Plus" },
  { value: "101117", label: "Kalspare" },
  { value: "899", label: "Kalten" },
  { value: "101118", label: "Kamillosan" },
  { value: "101119", label: "Kapake" },
  { value: "900", label: "Kardegic" },
  { value: "101120", label: "Kefadim" },
  { value: "901", label: "Keflex" },
  { value: "101121", label: "Kemadrin" },
  { value: "101122", label: "Kemicetine Succinate" },
  { value: "902", label: "Kenalog" },
  { value: "101123", label: "Kentera" },
  { value: "101124", label: "Kepivance" },
  { value: "903", label: "Keppra" },
  { value: "101125", label: "Keral" },
  { value: "904", label: "Kerlone" },
  { value: "101126", label: "Keromask" },
  { value: "101127", label: "Ketalar" },
  { value: "101128", label: "Ketamine" },
  { value: "101129", label: "Ketek" },
  { value: "101130", label: "Ketocal" },
  { value: "101131", label: "Ketocid" },
  { value: "905", label: "Ketoconazole" },
  { value: "906", label: "Ketoprofen" },
  { value: "101132", label: "Ketorolac" },
  { value: "101133", label: "Ketotifen" },
  { value: "907", label: "Ketovite" },
  { value: "101134", label: "KeyOmega" },
  { value: "101135", label: "Kineret" },
  { value: "101136", label: "Kinidin Durules" },
  { value: "101137", label: "Kiovig" },
  { value: "908", label: "Kivexa" },
  { value: "101138", label: "Klaricid XL" },
  { value: "101139", label: "Klean-Prep" },
  { value: "909", label: "Kliofem" },
  { value: "910", label: "Kliovance" },
  { value: "911", label: "Klipal" },
  { value: "101140", label: "Kogenate Bayer" },
  { value: "101141", label: "Kolanticon" },
  { value: "101142", label: "Konakion MM" },
  { value: "101143", label: "Kytril" },
  { value: "912", label: "Labetalol" },
  { value: "913", label: "Lacerol" },
  { value: "101147", label: "Lacidipine" },
  { value: "914", label: "Lacidpine" },
  { value: "915", label: "Lacosamide" },
  { value: "101148", label: "Lacri-Lube" },
  { value: "101149", label: "Lactase" },
  { value: "101150", label: "Lactic Acid" },
  { value: "101151", label: "Lactugal" },
  { value: "916", label: "Lactulose" },
  { value: "917", label: "Lamictal" },
  { value: "101152", label: "Lamisil" },
  { value: "101153", label: "Lamisil Cream" },
  { value: "918", label: "Lamivudine" },
  { value: "919", label: "Lamotrigine" },
  { value: "101154", label: "Lanolin" },
  { value: "920", label: "Lanoxin" },
  { value: "921", label: "Lanreotide" },
  { value: "922", label: "Lansoprazole" },
  { value: "923", label: "Lanthanum" },
  { value: "924", label: "Lantus" },
  { value: "101155", label: "Lanvis" },
  { value: "925", label: "Lanzopral" },
  { value: "926", label: "Lapatinib" },
  { value: "101156", label: "Largactil Injection" },
  { value: "101157", label: "Lariam" },
  { value: "101158", label: "Laropiprant" },
  { value: "101159", label: "LarvE" },
  { value: "101160", label: "Lasikal" },
  { value: "101161", label: "Lasilactone" },
  { value: "927", label: "Lasilix" },
  { value: "928", label: "Lasix" },
  { value: "101162", label: "Lasix Injection" },
  { value: "929", label: "Latanoprost" },
  { value: "101163", label: "Lauromacrogol" },
  { value: "101144", label: "L-Cystine" },
  { value: "101164", label: "Ledclair" },
  { value: "930", label: "Lederfen" },
  { value: "931", label: "Leflunomide" },
  { value: "932", label: "Lenalidomide" },
  { value: "101165", label: "Lenograstim" },
  { value: "101166", label: "Lepirudin" },
  { value: "933", label: "Lercan" },
  { value: "934", label: "Lercanidipine" },
  { value: "935", label: "Lerka" },
  { value: "936", label: "Lescol" },
  { value: "101167", label: "Lescol XL" },
  { value: "937", label: "Letairis" },
  { value: "938", label: "Letrozole" },
  { value: "101168", label: "Leucine" },
  { value: "101169", label: "Leucine AA Supplement" },
  { value: "101170", label: "Leukeran" },
  { value: "939", label: "Leuprolide" },
  { value: "940", label: "Leuprorelin" },
  { value: "101171", label: "Leustat" },
  { value: "941", label: "Levemir" },
  { value: "942", label: "Levetiracetam" },
  { value: "101172", label: "Levitra" },
  { value: "943", label: "Levobunolol" },
  { value: "101173", label: "Levobupivacaine" },
  { value: "101174", label: "Levocarnitine" },
  { value: "944", label: "Levocetirizine" },
  { value: "945", label: "Levodopa" },
  { value: "946", label: "Levofloxacin" },
  { value: "947", label: "Levomepromazine" },
  { value: "101175", label: "Levonorgestrel" },
  { value: "948", label: "Levothyroxine" },
  { value: "949", label: "Lexatin" },
  { value: "101176", label: "Lexpec" },
  { value: "950", label: "Libradin" },
  { value: "951", label: "Librium" },
  { value: "952", label: "Lidocaine" },
  { value: "953", label: "Lignocaine" },
  { value: "101177", label: "Li-Liquid" },
  { value: "101178", label: "linagliptin" },
  { value: "954", label: "Lioresal" },
  { value: "955", label: "Liothyronine" },
  { value: "956", label: "Lipantil" },
  { value: "101179", label: "Lipantil Micro" },
  { value: "957", label: "Lipex" },
  { value: "958", label: "Lipicard" },
  { value: "101180", label: "Lipid regulation - Other" },
  { value: "959", label: "Lipidol" },
  { value: "960", label: "Lipitor" },
  { value: "961", label: "Lipitrol" },
  { value: "962", label: "Liplat" },
  { value: "101181", label: "Lipobase" },
  { value: "101182", label: "Liposic" },
  { value: "963", label: "Lipostat" },
  { value: "101183", label: "Liqui-Char" },
  { value: "101184", label: "Liquid paraffin" },
  { value: "101185", label: "Liquid Paraffin/White Soft Paraffin" },
  { value: "101186", label: "Liquifilm Tears" },
  { value: "101187", label: "Liquigen" },
  { value: "964", label: "Liraglutide" },
  { value: "965", label: "Lisinopril" },
  { value: "101188", label: "Liskonum" },
  { value: "966", label: "Lisoretic" },
  { value: "101189", label: "Lispro" },
  { value: "967", label: "Lithium" },
  { value: "968", label: "Livial" },
  { value: "101190", label: "Livwell" },
  { value: "101191", label: "LMX4" },
  { value: "969", label: "Lobivon" },
  { value: "970", label: "Loceryl" },
  { value: "101192", label: "Locoid" },
  { value: "101193", label: "Locorten-Vioform" },
  { value: "971", label: "Lodine" },
  { value: "101194", label: "Lodine SR" },
  { value: "101195", label: "Lodoxamide" },
  { value: "972", label: "Lodoz" },
  { value: "973", label: "Lofepramine" },
  { value: "101196", label: "Lofexidine" },
  { value: "101197", label: "Lomont" },
  { value: "974", label: "Lomotil" },
  { value: "101198", label: "Loniten" },
  { value: "101199", label: "Loop diuretic - Other" },
  { value: "975", label: "Lopace" },
  { value: "976", label: "Loperamide" },
  { value: "101200", label: "Lophlex" },
  { value: "101201", label: "Lopid" },
  { value: "977", label: "Lopinavir" },
  { value: "978", label: "Loprazolam" },
  { value: "101202", label: "Lopresor" },
  { value: "101203", label: "Lopresor SR" },
  { value: "101204", label: "Loprofin" },
  { value: "101205", label: "Loramyc" },
  { value: "979", label: "Loratadine" },
  { value: "980", label: "Loratine" },
  { value: "981", label: "Lorazepam" },
  { value: "101206", label: "Lormetazepam" },
  { value: "982", label: "Loron" },
  { value: "983", label: "Losartan" },
  { value: "984", label: "Losec" },
  { value: "101207", label: "Lotemax" },
  { value: "101208", label: "Loteprednol" },
  { value: "985", label: "Lotrel" },
  { value: "101209", label: "Lotriderm" },
  { value: "986", label: "Lovastatin" },
  { value: "987", label: "Lovaza" },
  { value: "101145", label: "L-Tyrosine" },
  { value: "101146", label: "L-Tyrosine AA Supplement" },
  { value: "101210", label: "Lucentis" },
  { value: "101211", label: "Lumefantrine" },
  { value: "988", label: "Lumigan" },
  { value: "989", label: "Lupron" },
  { value: "990", label: "Lustral" },
  { value: "101212", label: "Lutropin Alfa" },
  { value: "101213", label: "Luveris" },
  { value: "991", label: "Luvox" },
  { value: "101214", label: "Lyflex" },
  { value: "992", label: "Lymecycline" },
  { value: "993", label: "Lyrica" },
  { value: "994", label: "Lyrinel XL" },
  { value: "101215", label: "Lysovir" },
  { value: "101217", label: "Maalox" },
  { value: "101218", label: "MabCampath" },
  { value: "101219", label: "MabThera" },
  { value: "101220", label: "Macrobid" },
  { value: "995", label: "Macrodantin" },
  { value: "996", label: "Macrogol" },
  { value: "101221", label: "Macrogol 4 Lauryl Ether" },
  { value: "101222", label: "Macugen" },
  { value: "997", label: "Madopar" },
  { value: "998", label: "Magnapen" },
  { value: "101223", label: "Magnesium Carbonate" },
  { value: "101224", label: "Magnesium citrate" },
  { value: "999", label: "Magnesium Hydroxide" },
  { value: "101225", label: "Magnesium Oxide" },
  { value: "101226", label: "Magnesium sulphate" },
  { value: "101227", label: "Magnesium Trisilicate" },
  { value: "101228", label: "Malic Acid" },
  { value: "1000", label: "Manevac" },
  { value: "1001", label: "Manidon" },
  { value: "101229", label: "Mannitol" },
  { value: "1002", label: "Maprosyn" },
  { value: "101230", label: "Maraviroc" },
  { value: "101231", label: "Marcain" },
  { value: "1003", label: "Marevan" },
  { value: "101232", label: "Matolazone" },
  { value: "1004", label: "Maxalt" },
  { value: "101233", label: "Maxepa" },
  { value: "1005", label: "Maxidex" },
  { value: "101234", label: "Maxijul Super Soluble" },
  { value: "1006", label: "Maxilase" },
  { value: "101235", label: "Maxitram SR" },
  { value: "101236", label: "Maxitrol" },
  { value: "101237", label: "Maxolon" },
  { value: "1007", label: "Maxtrex" },
  { value: "101238", label: "MCT Pepdite" },
  { value: "101239", label: "Measles Virus" },
  { value: "101240", label: "Mebendazole" },
  { value: "1008", label: "Mebeverine" },
  { value: "1009", label: "Mecardis" },
  { value: "1010", label: "Mecysteine" },
  { value: "101241", label: "Medikinet" },
  { value: "101242", label: "Medinol Paediatric" },
  { value: "101243", label: "Medrone" },
  { value: "1011", label: "Medroxyprogesterone" },
  { value: "101244", label: "Mefenamic acid" },
  { value: "101245", label: "Mefloquine" },
  { value: "101246", label: "Megace" },
  { value: "1012", label: "Megestrol" },
  { value: "101247", label: "Melatonin" },
  { value: "1013", label: "Meloclopramide" },
  { value: "1014", label: "Meloxicam" },
  { value: "1015", label: "Melphalan" },
  { value: "101248", label: "Memantine" },
  { value: "101249", label: "Meningitec" },
  { value: "101250", label: "Meningitis And Hib Vaccine" },
  { value: "101251", label: "Meningitis Vaccine" },
  { value: "101252", label: "Meningococcal Oligosaccharides" },
  { value: "101253", label: "Menitorix" },
  { value: "101254", label: "Menopur" },
  { value: "101255", label: "Menotrophin" },
  { value: "101256", label: "Menthol in Aqueous Cream" },
  { value: "101257", label: "Menveo" },
  { value: "1016", label: "Mepacrine" },
  { value: "101258", label: "Mepact" },
  { value: "1017", label: "Mepradec" },
  { value: "1018", label: "Meprobamate" },
  { value: "1019", label: "Meptazinol" },
  { value: "101259", label: "Meptid" },
  { value: "1020", label: "Merbentyl" },
  { value: "101260", label: "Mercaptamine" },
  { value: "1021", label: "Mercaptopurine" },
  { value: "101261", label: "Merocaine" },
  { value: "101262", label: "Merocets" },
  { value: "101263", label: "Meronem" },
  { value: "101264", label: "Meropenem" },
  { value: "1022", label: "Mesalazine" },
  { value: "101265", label: "Mesna" },
  { value: "1023", label: "Mesren MR" },
  { value: "101266", label: "Mesterolone" },
  { value: "1024", label: "Mestinon" },
  { value: "101267", label: "Metabolic Mineral Mixture" },
  { value: "101268", label: "Metalyse" },
  { value: "101269", label: "Meted" },
  { value: "101270", label: "Metenix" },
  { value: "101271", label: "Metenix 5" },
  { value: "1025", label: "Metforal" },
  { value: "1026", label: "Metformin" },
  { value: "1027", label: "Methadone" },
  { value: "101272", label: "Methenamine" },
  { value: "101273", label: "Methionine" },
  { value: "1028", label: "Methocarbamol" },
  { value: "1029", label: "Methotrexate" },
  { value: "1030", label: "Methotrimeprazine" },
  { value: "101274", label: "Methoxypolyethylene glycol-epoetin beta" },
  { value: "101275", label: "Methyl aminolaevulinate" },
  { value: "101276", label: "Methyl Hydroxybenzoate" },
  { value: "101277", label: "Methyl Salicylate" },
  { value: "1031", label: "Methylcellulose" },
  { value: "1032", label: "Methyldopa" },
  { value: "101278", label: "Methylnaltrexone bromide" },
  { value: "101279", label: "Methylphenidate" },
  { value: "101280", label: "Methylprednisolone" },
  { value: "101281", label: "Methysergide" },
  { value: "1033", label: "Metoclopramide" },
  { value: "1034", label: "Metolazone" },
  { value: "1035", label: "Metopirone" },
  { value: "1036", label: "Metoprolol" },
  { value: "101282", label: "Metrogel" },
  { value: "1037", label: "Metronidazole" },
  { value: "101283", label: "Metrosa" },
  { value: "101284", label: "Metvix" },
  { value: "1038", label: "Metyrapone" },
  { value: "101285", label: "Mexiletine" },
  { value: "101286", label: "Mexitil" },
  { value: "1039", label: "Mezavant" },
  { value: "101287", label: "Mezavant XL" },
  { value: "101288", label: "Mezolar Matrix" },
  { value: "101289", label: "Miacalcic Nasal Spray" },
  { value: "101290", label: "Mianserin" },
  { value: "1040", label: "MIBG treatment" },
  { value: "101291", label: "Micafungin" },
  { value: "101292", label: "Micanol" },
  { value: "1041", label: "Micardis" },
  { value: "101293", label: "Micardis plus" },
  { value: "101294", label: "Micolette" },
  { value: "101295", label: "Miconazole" },
  { value: "101296", label: "Micralax" },
  { value: "1042", label: "Micropirin" },
  { value: "1043", label: "Midazolam" },
  { value: "1044", label: "Midodrine" },
  { value: "101297", label: "Midrid" },
  { value: "101298", label: "Mifamurtide" },
  { value: "101299", label: "Mifegyne" },
  { value: "101300", label: "Mifepristone" },
  { value: "1045", label: "Miflasone" },
  { value: "101301", label: "Migard" },
  { value: "101302", label: "Miglustat" },
  { value: "1046", label: "Migraleve" },
  { value: "101303", label: "MigraMax" },
  { value: "101304", label: "Migril" },
  { value: "101305", label: "Mildison Lipocream" },
  { value: "101306", label: "Milrinone" },
  { value: "101307", label: "Milupa lpd" },
  { value: "101308", label: "MIMMS" },
  { value: "101309", label: "Mimpara" },
  { value: "1047", label: "Minax" },
  { value: "1048", label: "Minidab" },
  { value: "101311", label: "Minijet Lignocaine" },
  { value: "101312", label: "Minims Artificial Tears" },
  { value: "101313", label: "Minims Atropine" },
  { value: "101314", label: "Minims Chloramphenicol" },
  { value: "101315", label: "Minims Cyclopentolate" },
  { value: "101316", label: "Minims Dexamethasone" },
  { value: "101317", label: "Minims Fluorescein" },
  { value: "101318", label: "Minims Lidocaine and Fluorescein" },
  { value: "101319", label: "Minims Metipranolol" },
  { value: "101320", label: "Minims Oxybuprocaine" },
  { value: "101321", label: "Minims Phenylephrine" },
  { value: "101322", label: "Minims Pilocarpine" },
  { value: "101323", label: "Minims Prednisolone" },
  { value: "101324", label: "Minims Proxymetacaine" },
  { value: "101325", label: "Minims Proxymetacaine and Fluorescein" },
  { value: "101326", label: "Minims Sodium Chloride" },
  { value: "101327", label: "Minims Tetracaine" },
  { value: "101328", label: "Minims Tropicamide" },
  { value: "1049", label: "Minitran" },
  { value: "101329", label: "Miniversol" },
  { value: "101310", label: "Mini-Wright" },
  { value: "101330", label: "Minocin MR" },
  { value: "1050", label: "Minocycline" },
  { value: "101331", label: "Minodiab" },
  { value: "1051", label: "Minoxidil" },
  { value: "101332", label: "Mintec" },
  { value: "101333", label: "Miochol-E" },
  { value: "1052", label: "Mirapexin" },
  { value: "101334", label: "Mircera" },
  { value: "101335", label: "Mirena" },
  { value: "1053", label: "Mirtazapine" },
  { value: "1054", label: "Misoprostol" },
  { value: "101336", label: "Mitomycin-C" },
  { value: "1055", label: "Mitoxantrone" },
  { value: "101337", label: "Mivacron" },
  { value: "101338", label: "Mivacurium" },
  { value: "1056", label: "Mixtard" },
  { value: "101339", label: "Mixtard 30" },
  { value: "1057", label: "Mizolastine" },
  { value: "101340", label: "Mizollen" },
  { value: "1058", label: "MMF" },
  { value: "101341", label: "Mmr Vaccine" },
  { value: "101216", label: "M-M-RvaxPro" },
  { value: "101342", label: "Mobic" },
  { value: "101343", label: "Mobiflex" },
  { value: "101344", label: "Mobigel" },
  { value: "101345", label: "Moclobemide" },
  { value: "1059", label: "Modafinil" },
  { value: "1060", label: "Modalim" },
  { value: "1061", label: "Modamide" },
  { value: "101346", label: "Modecate" },
  { value: "101347", label: "Modigraf" },
  { value: "101348", label: "Modjul Flavour" },
  { value: "101349", label: "Modrasone" },
  { value: "101350", label: "Moducren" },
  { value: "101351", label: "Modulen IBD" },
  { value: "101352", label: "Moduret 25" },
  { value: "1062", label: "Moduretic" },
  { value: "101353", label: "Moexipril" },
  { value: "101354", label: "Molaxole" },
  { value: "1063", label: "Molipaxin" },
  { value: "1064", label: "Molsidain" },
  { value: "1065", label: "Molsidomine" },
  { value: "1066", label: "Mometasone" },
  { value: "1067", label: "Monicor" },
  { value: "1068", label: "Monit SR" },
  { value: "101355", label: "Monogen" },
  { value: "1069", label: "Monolitum" },
  { value: "1070", label: "Monomax" },
  { value: "1071", label: "Monomil" },
  { value: "101356", label: "Monomil XL" },
  { value: "101357", label: "Mononine" },
  { value: "101358", label: "Monoparin" },
  { value: "1072", label: "Mono-Tildiem" },
  { value: "1073", label: "Monoxidine" },
  { value: "1074", label: "Montelukast" },
  { value: "1075", label: "Mopral" },
  { value: "101359", label: "Morhulin" },
  { value: "101360", label: "Moroctocog Alfa" },
  { value: "1076", label: "Morphgesic SR" },
  { value: "1077", label: "Morphine" },
  { value: "1078", label: "Motens" },
  { value: "101361", label: "Motifene" },
  { value: "1079", label: "Motilium" },
  { value: "1080", label: "Movelat" },
  { value: "1081", label: "Movicol" },
  { value: "101362", label: "Moviprep" },
  { value: "1082", label: "Moxifloxacin" },
  { value: "101363", label: "Moxisylyte" },
  { value: "1083", label: "Moxonidine" },
  { value: "101364", label: "Mozobil" },
  { value: "1084", label: "MST Continus" },
  { value: "101365", label: "MSUD Aid III" },
  { value: "101366", label: "MSUD Anamix Junior" },
  { value: "101367", label: "MSUD Express" },
  { value: "101368", label: "Mucin" },
  { value: "101369", label: "MucoClear" },
  { value: "1085", label: "Mucodyne" },
  { value: "101370", label: "Mucogel" },
  { value: "101371", label: "Mucopolysaccharide Polysulphate" },
  { value: "101372", label: "MuGard" },
  { value: "101373", label: "Multaq" },
  { value: "101374", label: "Multiparin" },
  { value: "101375", label: "Multivitamins" },
  { value: "101376", label: "Mumps Virus" },
  { value: "101377", label: "Mupirocin" },
  { value: "101378", label: "MUSE" },
  { value: "101379", label: "MXL" },
  { value: "101380", label: "Mycamine" },
  { value: "101381", label: "Mycobutin" },
  { value: "101382", label: "Mycophenolate" },
  { value: "1086", label: "Mycophenolate Mofetil" },
  { value: "101383", label: "Mydriacyl" },
  { value: "101384", label: "Mydrilate" },
  { value: "101385", label: "Myfortic" },
  { value: "101386", label: "Myleran" },
  { value: "101387", label: "Myocet" },
  { value: "1087", label: "Myocrisin" },
  { value: "101388", label: "Myotonine" },
  { value: "1088", label: "Mysoline" },
  { value: "101389", label: "Nabilone" },
  { value: "1089", label: "Nabumetone" },
  { value: "1090", label: "Nadolol" },
  { value: "101390", label: "Nafarelin" },
  { value: "1091", label: "Naftidrofuryl" },
  { value: "101391", label: "Nalcrom" },
  { value: "101392", label: "Nalidixic acid" },
  { value: "101393", label: "Nalorex" },
  { value: "101394", label: "Naloxone" },
  { value: "1092", label: "Naltrexone" },
  { value: "101395", label: "Nandrolone decanoate" },
  { value: "1093", label: "Napratec" },
  { value: "1094", label: "Naprosyn" },
  { value: "101396", label: "Naprosyn EC" },
  { value: "1095", label: "Naproxen" },
  { value: "101397", label: "Naramig" },
  { value: "1096", label: "Naratriptan" },
  { value: "101398", label: "Nardil" },
  { value: "101399", label: "Naropin" },
  { value: "1097", label: "Nasacort" },
  { value: "101400", label: "Naseptin" },
  { value: "1098", label: "Nasobec" },
  { value: "101401", label: "Nasofan Aqueous" },
  { value: "1099", label: "Nasonex" },
  { value: "101402", label: "Nasonex Alcohol Free" },
  { value: "101403", label: "Natalizumab" },
  { value: "1100", label: "Natecal D3" },
  { value: "1101", label: "Nateglinide" },
  { value: "1102", label: "Natrilix" },
  { value: "101404", label: "Natrilix SR" },
  { value: "1103", label: "Nattokinase" },
  { value: "101405", label: "Navelbine" },
  { value: "1104", label: "Navidrex" },
  { value: "1105", label: "Navispare" },
  { value: "1106", label: "Nazdol MR" },
  { value: "101406", label: "Nebido" },
  { value: "1107", label: "Nebilet" },
  { value: "1108", label: "Nebivolol" },
  { value: "101407", label: "NebuChamber" },
  { value: "101408", label: "Nebuliser" },
  { value: "101409", label: "Nebusal 7%" },
  { value: "1109", label: "Nedocromil" },
  { value: "1110", label: "Nefopam" },
  { value: "101410", label: "Nefopam hydrochloride" },
  { value: "101411", label: "Neisseria Meningitidis Group C" },
  { value: "101412", label: "NeisVac-C" },
  { value: "101413", label: "Nelarabine" },
  { value: "101414", label: "Nelfinavir" },
  { value: "101416", label: "Neocate LCP" },
  { value: "101417", label: "NeoClarityn" },
  { value: "1111", label: "Neofel XL" },
  { value: "101418", label: "Neokay" },
  { value: "101419", label: "Neomercazole" },
  { value: "1112", label: "Neo-Mercazole" },
  { value: "101420", label: "Neomycin" },
  { value: "101415", label: "Neo-Naclex-K" },
  { value: "1113", label: "Neoral" },
  { value: "1114", label: "NeoRecormon" },
  { value: "101421", label: "Neosporin" },
  { value: "1115", label: "Neotigason" },
  { value: "1116", label: "Neozipine" },
  { value: "101422", label: "Nepafenac" },
  { value: "101423", label: "Nepro" },
  { value: "101424", label: "Nerisone" },
  { value: "101425", label: "Nerisone Forte" },
  { value: "101426", label: "Nesina" },
  { value: "101427", label: "Neulasta" },
  { value: "101428", label: "Neupogen" },
  { value: "1117", label: "Neupro" },
  { value: "101429", label: "NeuroBloc" },
  { value: "101430", label: "Neurontin" },
  { value: "101431", label: "Neutral Insulin" },
  { value: "101432", label: "Neutrogena T Gel" },
  { value: "101433", label: "Nevanac" },
  { value: "1118", label: "Nevirapine" },
  { value: "101434", label: "New to Add to ARRT" },
  { value: "101435", label: "Nexavar" },
  { value: "1119", label: "Nexium" },
  { value: "1120", label: "Niaspan" },
  { value: "101436", label: "Nicam" },
  { value: "1121", label: "Nicardipine" },
  { value: "1122", label: "Nicorandil" },
  { value: "101437", label: "Nicorette" },
  { value: "101438", label: "Nicotinamide" },
  { value: "101439", label: "Nicotine" },
  { value: "101440", label: "Nicotinell" },
  { value: "1123", label: "Nicotinic Acid" },
  { value: "1124", label: "Nifedipine" },
  { value: "1125", label: "Nifedipress MR" },
  { value: "101441", label: "Nilotinib" },
  { value: "101442", label: "Nimbex" },
  { value: "1126", label: "Nimodipine" },
  { value: "1127", label: "Nimotop" },
  { value: "101443", label: "Nipent" },
  { value: "101444", label: "Niquitin CQ" },
  { value: "101445", label: "Nisoldipine" },
  { value: "101446", label: "Nitisinone" },
  { value: "101447", label: "Nitrate - Other" },
  { value: "1128", label: "Nitrazepam" },
  { value: "101449", label: "Nitrocine" },
  { value: "101448", label: "Nitro-Dur" },
  { value: "1129", label: "Nitrofurantoin" },
  { value: "1130", label: "Nitrolingual" },
  { value: "101450", label: "Nitrolingual Pumpspray" },
  { value: "101451", label: "Nitromin" },
  { value: "101452", label: "Nitronal" },
  { value: "1131", label: "Nivaquine" },
  { value: "101453", label: "Nivaquine Syrup" },
  { value: "101454", label: "Nivemycin" },
  { value: "101455", label: "Nivestim" },
  { value: "1132", label: "Nizatidine" },
  { value: "101456", label: "Nizoral" },
  { value: "101457", label: "Nizoral Cream" },
  { value: "101458", label: "Nizoral Shampoo" },
  { value: "1133", label: "Noliprel" },
  { value: "101459", label: "Nonacog Alfa" },
  { value: "1134", label: "Nootropil" },
  { value: "101460", label: "Norcuron" },
  { value: "101461", label: "Norditropin SimpleXx" },
  { value: "101462", label: "Norethisterone" },
  { value: "101463", label: "Norfloxacin" },
  { value: "101464", label: "Norgalax" },
  { value: "101465", label: "Norgestrel" },
  { value: "101466", label: "Norimode" },
  { value: "101467", label: "Normacol" },
  { value: "1135", label: "Normacol Plus" },
  { value: "101468", label: "Normasol" },
  { value: "101469", label: "Normax" },
  { value: "101470", label: "Normosang" },
  { value: "101471", label: "Norprolac" },
  { value: "1136", label: "Nortriptyline" },
  { value: "1137", label: "Norvasc" },
  { value: "1138", label: "Norvir" },
  { value: "1636", label: "Not listed" },
  { value: "101472", label: "Novasource GI Control" },
  { value: "101473", label: "Novofem" },
  { value: "1139", label: "Novomix" },
  { value: "101474", label: "NovoMix 30" },
  { value: "1140", label: "NovoNorm" },
  { value: "1141", label: "Novorapid" },
  { value: "101475", label: "NovoSeven" },
  { value: "101476", label: "Noxafil" },
  { value: "101477", label: "Noxyflex S" },
  { value: "101478", label: "Noxythiolin" },
  { value: "101479", label: "Nozinan" },
  { value: "101480", label: "Nplate" },
  { value: "1142", label: "Nuelin SA" },
  { value: "101482", label: "Nurofen for Children" },
  { value: "1143", label: "Nu-Seals" },
  { value: "101481", label: "Nu-Seals Aspirin" },
  { value: "101483", label: "Nutilis" },
  { value: "101484", label: "Nutraplus" },
  { value: "101485", label: "Nutrineal PD4" },
  { value: "101486", label: "Nutrini" },
  { value: "101487", label: "Nutriprem 2" },
  { value: "101488", label: "Nutrison" },
  { value: "101489", label: "Nutrizym 10" },
  { value: "1144", label: "Nutrizym GR" },
  { value: "101490", label: "NutropinAq" },
  { value: "101491", label: "Nuvelle Continuous" },
  { value: "101492", label: "Nyogel" },
  { value: "101493", label: "Nystaform" },
  { value: "101494", label: "Nystaform-HC" },
  { value: "101495", label: "Nystan Oral Suspension" },
  { value: "1145", label: "Nystatin" },
  { value: "101496", label: "Oatmeal" },
  { value: "101497", label: "Occlusal" },
  { value: "101498", label: "Octagam" },
  { value: "101499", label: "Octim" },
  { value: "101500", label: "Octocog Alfa" },
  { value: "1146", label: "Octreotide" },
  { value: "101501", label: "Ocufen" },
  { value: "101502", label: "Oculotect" },
  { value: "101503", label: "Ocusan" },
  { value: "101504", label: "Oestrogel" },
  { value: "101505", label: "Oestrogen" },
  { value: "101506", label: "Ofatumumab" },
  { value: "101507", label: "Ofloxacin" },
  { value: "101508", label: "Oftaquix" },
  { value: "101509", label: "Oilatum Cream" },
  { value: "1147", label: "Olanzapine" },
  { value: "101510", label: "Olbetam" },
  { value: "101511", label: "Oldaram" },
  { value: "101512", label: "Olive oil" },
  { value: "101513", label: "Olmesartan" },
  { value: "1148", label: "Olmesartan medoxomil" },
  { value: "1149", label: "Olmetec" },
  { value: "101514", label: "Olmetec Plus" },
  { value: "101515", label: "Olopatadine" },
  { value: "1150", label: "Olsalazine" },
  { value: "1151", label: "Olsar Plus" },
  { value: "1152", label: "Omacor" },
  { value: "101516", label: "Omalizumab" },
  { value: "1153", label: "Omeprazole" },
  { value: "1154", label: "Omnic OCAS" },
  { value: "101517", label: "Omnitrope" },
  { value: "101518", label: "Onbrez Breezhaler" },
  { value: "101519", label: "OncoTICE" },
  { value: "101520", label: "Oncovin" },
  { value: "1155", label: "Ondansetron" },
  { value: "1156", label: "One-Alpha" },
  { value: "101521", label: "Onglyza" },
  { value: "101522", label: "Onkotrone" },
  { value: "101523", label: "Onsenal" },
  { value: "101524", label: "Opatanol" },
  { value: "101525", label: "Opilon" },
  { value: "101526", label: "Opizone" },
  { value: "101527", label: "Optichamber Advantage" },
  { value: "101528", label: "Opticrom" },
  { value: "101529", label: "Optiflo G" },
  { value: "101530", label: "Optilast" },
  { value: "101531", label: "Optimax" },
  { value: "101532", label: "Optivate" },
  { value: "101533", label: "Optive" },
  { value: "101534", label: "Orabase" },
  { value: "101535", label: "Oral Impact" },
  { value: "101536", label: "Oraldene" },
  { value: "1157", label: "Oramorph" },
  { value: "101537", label: "Orap" },
  { value: "101538", label: "Orelox" },
  { value: "101539", label: "Orencia" },
  { value: "101540", label: "Orfadin" },
  { value: "101541", label: "Orgalutran" },
  { value: "101542", label: "Orgaran" },
  { value: "101543", label: "Orgran" },
  { value: "1158", label: "Orlistat" },
  { value: "1159", label: "Orphenadrine" },
  { value: "101544", label: "Ortho-Gynest" },
  { value: "101545", label: "Orthovisc" },
  { value: "101546", label: "Orudis" },
  { value: "1160", label: "Oruvail" },
  { value: "101547", label: "Oruvail Gel" },
  { value: "101548", label: "Osbon ErecAid" },
  { value: "1161", label: "Oseltamivir" },
  { value: "101549", label: "Osmanil" },
  { value: "101550", label: "Osmolite" },
  { value: "101551", label: "Ostenil" },
  { value: "101552", label: "Osvaren" },
  { value: "1162", label: "Otomize" },
  { value: "101553", label: "Otosporin" },
  { value: "101554", label: "Otovent" },
  { value: "101555", label: "Otrivine-Antistin" },
  { value: "101556", label: "Ovestin Cream" },
  { value: "101557", label: "Ovitrelle" },
  { value: "1163", label: "Oxactin" },
  { value: "1164", label: "Oxaliplatin" },
  { value: "1165", label: "Oxazepam" },
  { value: "101558", label: "Oxcarbazepine" },
  { value: "101559", label: "Oxerutin" },
  { value: "1166", label: "Oxis" },
  { value: "101560", label: "Oxis Turbohaler" },
  { value: "1167", label: "Oxivent" },
  { value: "1168", label: "Oxprenolol" },
  { value: "101561", label: "Oxyal" },
  { value: "101562", label: "Oxybuprocaine" },
  { value: "1169", label: "Oxybutynin" },
  { value: "1170", label: "Oxycodone" },
  { value: "101563", label: "Oxycodone Hydrochloride" },
  { value: "1171", label: "OxyContin" },
  { value: "101564", label: "Oxygen Services" },
  { value: "1172", label: "OxyNorm" },
  { value: "101565", label: "Oxyprenolol" },
  { value: "1173", label: "Oxytetracycline" },
  { value: "101566", label: "Oxytocin" },
  { value: "101567", label: "Pabal" },
  { value: "1174", label: "Paclitaxel" },
  { value: "101568", label: "Palifermin" },
  { value: "101569", label: "Paliperidone" },
  { value: "101570", label: "Palivizumab" },
  { value: "101571", label: "Palladone" },
  { value: "101572", label: "Palonosetron" },
  { value: "101573", label: "Paludrine" },
  { value: "101574", label: "Pamergan P100" },
  { value: "1175", label: "Pamidronate" },
  { value: "1176", label: "Pamsvax" },
  { value: "101575", label: "Panadol OA" },
  { value: "101576", label: "Pancrease HL" },
  { value: "101577", label: "Pancreatin" },
  { value: "1177", label: "Pancrex" },
  { value: "101578", label: "Pancrex V Forte Tablets" },
  { value: "101579", label: "Pandemrix" },
  { value: "101580", label: "Panitumumab" },
  { value: "101581", label: "Panoxyl" },
  { value: "1178", label: "Pantoprazole" },
  { value: "101582", label: "Paracetamol" },
  { value: "101583", label: "Paradote" },
  { value: "101584", label: "Paramax" },
  { value: "101585", label: "Parathyroid hormone" },
  { value: "101586", label: "Parecoxib" },
  { value: "101587", label: "Paricalcitol" },
  { value: "1179", label: "Pariet" },
  { value: "101588", label: "Parlodel" },
  { value: "101589", label: "Paroven" },
  { value: "1180", label: "Paroxetine" },
  { value: "101590", label: "Partobulin" },
  { value: "101591", label: "Parvolex" },
  { value: "1181", label: "Patupilone" },
  { value: "101592", label: "Pavacol-D" },
  { value: "101593", label: "Pazopanib" },
  { value: "101594", label: "Peak flow meter" },
  { value: "101595", label: "Pedea" },
  { value: "101596", label: "Pediacel" },
  { value: "101597", label: "Peezy" },
  { value: "101598", label: "Pegaptanib" },
  { value: "101599", label: "Pegasys" },
  { value: "101600", label: "Pegfilgrastim" },
  { value: "101601", label: "Pegvisomant" },
  { value: "101602", label: "Pemetrexed" },
  { value: "1182", label: "Pen V" },
  { value: "101603", label: "Penbritin" },
  { value: "101604", label: "Penciclovir" },
  { value: "101605", label: "Penicillamine" },
  { value: "101606", label: "Penicillin V" },
  { value: "101607", label: "Pennsaid" },
  { value: "101608", label: "Pentacarinat" },
  { value: "101609", label: "Pentamidine" },
  { value: "1183", label: "Pentasa" },
  { value: "101610", label: "Pentazocine" },
  { value: "101611", label: "Pentostatin" },
  { value: "1184", label: "Pentoxifylline" },
  { value: "101612", label: "Pentrax" },
  { value: "101613", label: "Pepcid" },
  { value: "101614", label: "Pepdite" },
  { value: "101615", label: "Peppermint oil" },
  { value: "1185", label: "Peptac" },
  { value: "101616", label: "Percutol" },
  { value: "101617", label: "Perdix" },
  { value: "101618", label: "Perfalgan" },
  { value: "1186", label: "Pergolide" },
  { value: "101619", label: "Pergoveris" },
  { value: "101620", label: "Periactin" },
  { value: "101621", label: "Periciazine" },
  { value: "1187", label: "Pericyazine" },
  { value: "101622", label: "Perinal" },
  { value: "1188", label: "Perindopril" },
  { value: "101623", label: "Periostat" },
  { value: "1189", label: "Peritone" },
  { value: "101624", label: "Permitabs" },
  { value: "1190", label: "Permixon" },
  { value: "101625", label: "Perphenazine" },
  { value: "1191", label: "Persantin" },
  { value: "101626", label: "Persantin Retard" },
  { value: "101627", label: "Personal Best" },
  { value: "101628", label: "Pertussis" },
  { value: "101629", label: "Peru Balsam" },
  { value: "1192", label: "Pethidine" },
  { value: "101630", label: "Pevaryl" },
  { value: "101631", label: "Pharmorubicin" },
  { value: "101632", label: "Phenelzine" },
  { value: "101633", label: "Phenergan" },
  { value: "1193", label: "Phenindione" },
  { value: "1194", label: "Phenobarbital" },
  { value: "101634", label: "Phenoxybenzamine" },
  { value: "1195", label: "Phenoxymethylpenicillin" },
  { value: "101635", label: "Phentolamine" },
  { value: "101636", label: "Phentolamine Mesilate" },
  { value: "101637", label: "Phenylalanine AA Supplement" },
  { value: "101638", label: "Phenylephrine" },
  { value: "101639", label: "Phenylethyl Alcohol" },
  { value: "1196", label: "Phenytoin" },
  { value: "101640", label: "Phlexy-10 Drink Mix" },
  { value: "1197", label: "Phloretin" },
  { value: "1198", label: "Pholcodine" },
  { value: "1199", label: "Phosex" },
  { value: "101641", label: "PhosLo" },
  { value: "1200", label: "Phyllocontin Continus" },
  { value: "101642", label: "Physeptone" },
  { value: "101643", label: "Physeptone Mixture" },
  { value: "1201", label: "Physiotens" },
  { value: "101644", label: "Phytomenadione" },
  { value: "101645", label: "Picolax" },
  { value: "101646", label: "Piko-1" },
  { value: "101647", label: "Pilocarpine" },
  { value: "101648", label: "Pilogel" },
  { value: "101649", label: "Pimecrolimus" },
  { value: "1202", label: "Pimozide" },
  { value: "1203", label: "Pindolol" },
  { value: "101650", label: "Pinetarsol Bath Oil" },
  { value: "1204", label: "Pioglitazone" },
  { value: "101651", label: "Piperacillin" },
  { value: "101652", label: "Piperazine" },
  { value: "101653", label: "Piportil Depot" },
  { value: "1205", label: "Pipotiazine" },
  { value: "1206", label: "Piracetam" },
  { value: "1207", label: "Piriton" },
  { value: "1208", label: "Piroxicam" },
  { value: "101654", label: "Pitressin" },
  { value: "101655", label: "Pivmecillinam" },
  { value: "1209", label: "Pizotifen" },
  { value: "1210", label: "Plaquenil" },
  { value: "1211", label: "Plavix" },
  { value: "1212", label: "Plendil" },
  { value: "101656", label: "Plerixafor" },
  { value: "1213", label: "Pletal" },
  { value: "101657", label: "Pneumococcal vaccine" },
  { value: "101658", label: "Pneumovax II" },
  { value: "101659", label: "Pocket Chamber" },
  { value: "101660", label: "Pocket Peak" },
  { value: "101661", label: "Podophyllotoxin" },
  { value: "101662", label: "Pollens" },
  { value: "101663", label: "Pollinex" },
  { value: "101664", label: "Polycal" },
  { value: "1214", label: "Polycal Powder" },
  { value: "101665", label: "Polyethylene Glycol" },
  { value: "101666", label: "Polyfax" },
  { value: "101667", label: "Polyglucose polymer" },
  { value: "101668", label: "Polymyxin" },
  { value: "101669", label: "Polymyxin B" },
  { value: "101670", label: "Polysorbate" },
  { value: "101671", label: "Polystyrene sulphonate" },
  { value: "101672", label: "Polytar Liquid" },
  { value: "101673", label: "Polyvinyl alcohol" },
  { value: "101674", label: "Polyvinylpyrrolidone" },
  { value: "101675", label: "Ponstan" },
  { value: "101676", label: "Poractant" },
  { value: "1215", label: "Portiron" },
  { value: "1216", label: "Posaconazole" },
  { value: "101677", label: "Pos-T-Vac" },
  { value: "101678", label: "Potaba" },
  { value: "101679", label: "Potassium Bicarbonate" },
  { value: "101680", label: "Potassium Chloride" },
  { value: "1217", label: "Potassium Citrate" },
  { value: "101681", label: "Potassium Hydroxy-Quinoline Sulphate" },
  { value: "101682", label: "Potassium permanganate" },
  { value: "101683", label: "Povidone" },
  { value: "101684", label: "Povidone-iodine" },
  { value: "101685", label: "Powerbreathe" },
  { value: "101686", label: "Powergel" },
  { value: "101687", label: "Pradaxa" },
  { value: "1218", label: "Pramipexole" },
  { value: "101688", label: "Pramocaine" },
  { value: "1219", label: "Prandin" },
  { value: "1220", label: "Prareduct" },
  { value: "1221", label: "Prasugrel" },
  { value: "1222", label: "Pravaselect" },
  { value: "1223", label: "Pravastatin" },
  { value: "1224", label: "Praxilene" },
  { value: "1225", label: "Prazosin" },
  { value: "101689", label: "Pred Forte" },
  { value: "1226", label: "Predenema" },
  { value: "1227", label: "Predfoam" },
  { value: "1228", label: "Prednisolone" },
  { value: "101690", label: "Predsol" },
  { value: "101691", label: "Predsol Enema" },
  { value: "1229", label: "Pregabalin" },
  { value: "1230", label: "Pregaday" },
  { value: "101692", label: "Pregestimil Lipil" },
  { value: "101693", label: "Pregnyl" },
  { value: "1231", label: "Premarin" },
  { value: "101694", label: "Premique" },
  { value: "101695", label: "Premique Cycle" },
  { value: "1232", label: "Prempak-C" },
  { value: "101696", label: "Preotact" },
  { value: "101697", label: "Prescal" },
  { value: "101698", label: "Preservex" },
  { value: "1233", label: "Prestarium" },
  { value: "101699", label: "Prestim" },
  { value: "1234", label: "Pretanix" },
  { value: "1235", label: "Preterax" },
  { value: "1236", label: "Prevacid" },
  { value: "101700", label: "Prevenar 13" },
  { value: "1237", label: "Prevencor" },
  { value: "1238", label: "Previscan" },
  { value: "101701", label: "Prezista" },
  { value: "1239", label: "Priadel" },
  { value: "101702", label: "Prialt" },
  { value: "101703", label: "Prilocaine" },
  { value: "101704", label: "Primacor" },
  { value: "101705", label: "Primaxin IV" },
  { value: "1240", label: "Primidone" },
  { value: "101706", label: "Primolut N" },
  { value: "1241", label: "Prinivil" },
  { value: "101707", label: "Priorix" },
  { value: "101708", label: "Pripsen" },
  { value: "101709", label: "Pripsen Mebendazole" },
  { value: "1242", label: "Pritor" },
  { value: "101710", label: "Privigen" },
  { value: "1243", label: "Pro-Banthine" },
  { value: "101714", label: "Procainamide" },
  { value: "101711", label: "Pro-Cal Powder" },
  { value: "1244", label: "Prochlorperazine" },
  { value: "1245", label: "Procoralan" },
  { value: "101715", label: "Proctofoam HC" },
  { value: "1246", label: "Proctosedyl" },
  { value: "1247", label: "Procyclidine" },
  { value: "101712", label: "Pro-Epanutin" },
  { value: "101716", label: "Progesterone" },
  { value: "1248", label: "Prograf" },
  { value: "101717", label: "Proguanil" },
  { value: "1249", label: "Progynova" },
  { value: "101718", label: "Progynova TS" },
  { value: "101719", label: "Proleukin S.C." },
  { value: "101720", label: "Prolia" },
  { value: "1250", label: "Promazine" },
  { value: "1251", label: "Promethazine" },
  { value: "101721", label: "Promin" },
  { value: "101722", label: "Promixin" },
  { value: "101723", label: "Pronestyl" },
  { value: "1252", label: "Propafenone" },
  { value: "101724", label: "Propamidine" },
  { value: "1253", label: "Propantheline" },
  { value: "101725", label: "Propess" },
  { value: "1254", label: "Propiverine" },
  { value: "101726", label: "Propofol" },
  { value: "1255", label: "Propranolol" },
  { value: "101727", label: "Propyl Hydroxybenzoate" },
  { value: "1256", label: "Propylthiouracil" },
  { value: "1257", label: "Proscar" },
  { value: "101728", label: "ProSource" },
  { value: "1258", label: "Prostap SR" },
  { value: "101729", label: "Prostin E2" },
  { value: "101730", label: "Prostin VR" },
  { value: "101731", label: "Prosulf" },
  { value: "101732", label: "ProSure" },
  { value: "1259", label: "Prosurin" },
  { value: "101733", label: "Protamine" },
  { value: "101734", label: "Protamine Zinc Insulin" },
  { value: "101735", label: "Protein C" },
  { value: "1260", label: "Protelos" },
  { value: "1261", label: "Prothiaden" },
  { value: "101736", label: "Protifar" },
  { value: "1262", label: "Protium" },
  { value: "101737", label: "Protium I.V." },
  { value: "101738", label: "Protopic" },
  { value: "1263", label: "Provera" },
  { value: "101739", label: "ProvideXTRA" },
  { value: "1264", label: "Provigil" },
  { value: "101713", label: "Pro-Viron" },
  { value: "101740", label: "Proxymetacaine" },
  { value: "1265", label: "Prozac" },
  { value: "101741", label: "ProZero" },
  { value: "101742", label: "Prucalopride" },
  { value: "101743", label: "Pseudoephedrine" },
  { value: "101744", label: "Psoriderm" },
  { value: "101745", label: "Psorin" },
  { value: "1266", label: "Pulmicort" },
  { value: "101746", label: "Pulmicort Inhaler CFC-free" },
  { value: "101747", label: "Pulmozyme" },
  { value: "101748", label: "Pulvinal Beclometasone" },
  { value: "101749", label: "Pulvinal Salbutamol" },
  { value: "101750", label: "Puregon" },
  { value: "1267", label: "Purinethol" },
  { value: "101751", label: "Puri-Nethol" },
  { value: "101752", label: "PVA 1.4% Tubilux" },
  { value: "101753", label: "Pylobactell" },
  { value: "101754", label: "Pyralvex" },
  { value: "1268", label: "Pyrazinamide" },
  { value: "1269", label: "Pyridostigmine" },
  { value: "101755", label: "Pyridoxine" },
  { value: "1270", label: "Pyridoxine Hydrochloride" },
  { value: "1271", label: "Pyrimethamine" },
  { value: "1272", label: "Questran" },
  { value: "101756", label: "Questran Light" },
  { value: "1273", label: "Quetiapine" },
  { value: "101757", label: "QuickCal" },
  { value: "1274", label: "Quinagolide" },
  { value: "1275", label: "Quinapril" },
  { value: "101758", label: "Quinidine" },
  { value: "1276", label: "Quinil" },
  { value: "1277", label: "Quinine" },
  { value: "1278", label: "Quinine Bisulphate" },
  { value: "101759", label: "Quinine sulphate" },
  { value: "101760", label: "Quinoderm Cream" },
  { value: "101761", label: "Qutenza" },
  { value: "101762", label: "QV Cream" },
  { value: "1279", label: "Qvar" },
  { value: "1280", label: "Rabeprazole" },
  { value: "101763", label: "Rabies vaccine" },
  { value: "101764", label: "Rabies Vaccine BP" },
  { value: "101765", label: "Rabipur" },
  { value: "1281", label: "Raloxifene" },
  { value: "1282", label: "Raltegravir" },
  { value: "1283", label: "Raltitrexed" },
  { value: "1284", label: "Ramipril" },
  { value: "1285", label: "Ranbaxy" },
  { value: "1286", label: "Ranexa" },
  { value: "101766", label: "Ranibizumab" },
  { value: "1287", label: "Ranitidine" },
  { value: "1288", label: "Ranolazine" },
  { value: "1289", label: "Ranopril" },
  { value: "1290", label: "Rantidina" },
  { value: "101767", label: "Rapamune" },
  { value: "101768", label: "Rapifen" },
  { value: "101769", label: "Rapilysin" },
  { value: "101770", label: "Rapitil" },
  { value: "101771", label: "Rapport" },
  { value: "101772", label: "Rapydan" },
  { value: "1291", label: "Rasagiline" },
  { value: "101773", label: "Rasburicase" },
  { value: "1292", label: "Rasilez" },
  { value: "101774", label: "Rebetol" },
  { value: "1293", label: "Rebif" },
  { value: "1294", label: "Reboxetine" },
  { value: "1295", label: "Recentin" },
  { value: "101775", label: "Rectogesic" },
  { value: "1296", label: "Reductil" },
  { value: "101776", label: "ReFacto AF" },
  { value: "101777", label: "Refludan" },
  { value: "101778", label: "Refolinon" },
  { value: "101779", label: "Regranex" },
  { value: "101780", label: "Regulan" },
  { value: "101781", label: "Regurin" },
  { value: "101782", label: "Relactagel" },
  { value: "1297", label: "Relafen" },
  { value: "101783", label: "Relaxit" },
  { value: "101784", label: "Relenza" },
  { value: "101785", label: "Relestat" },
  { value: "1298", label: "Relifex" },
  { value: "101786", label: "Relistor" },
  { value: "101787", label: "Relpax" },
  { value: "1299", label: "Remedeine" },
  { value: "101788", label: "Remicade" },
  { value: "101789", label: "Remifentanil" },
  { value: "101790", label: "Reminyl" },
  { value: "1300", label: "Remniyl" },
  { value: "1301", label: "Renagel" },
  { value: "101791", label: "Renamil" },
  { value: "1302", label: "Renapro" },
  { value: "101792", label: "Renastart" },
  { value: "101793", label: "Renilon 7.5" },
  { value: "101794", label: "Reopro" },
  { value: "101795", label: "Repadina" },
  { value: "1303", label: "Repaglinide" },
  { value: "101796", label: "Repevax" },
  { value: "101797", label: "Replenine-VF" },
  { value: "101798", label: "Replens MD" },
  { value: "1304", label: "Requip" },
  { value: "101799", label: "Resolor" },
  { value: "101800", label: "Resonium-A" },
  { value: "101801", label: "Resource Energy" },
  { value: "101802", label: "Resource Optifibre" },
  { value: "101803", label: "Resource Thickenup" },
  { value: "101804", label: "Respifor" },
  { value: "101805", label: "Respontin" },
  { value: "1305", label: "Restandol" },
  { value: "101806", label: "Restandol Testocaps" },
  { value: "101807", label: "Retacrit" },
  { value: "1306", label: "Retalzem" },
  { value: "101808", label: "Retapamulin" },
  { value: "101809", label: "Reteplase" },
  { value: "101810", label: "Retin-A" },
  { value: "1307", label: "Retrovir" },
  { value: "101811", label: "Revatio" },
  { value: "101812", label: "Revaxis" },
  { value: "1308", label: "Revlimid" },
  { value: "101813", label: "Revolade" },
  { value: "1309", label: "Reyataz" },
  { value: "1310", label: "Rheumatac retard" },
  { value: "101814", label: "Rhinocort Aqua" },
  { value: "101815", label: "Rhinolast" },
  { value: "101816", label: "Rhophylac" },
  { value: "101817", label: "Rhubarb Extract" },
  { value: "1311", label: "Rhumalgan" },
  { value: "101818", label: "Riamet" },
  { value: "101819", label: "Ribavirin" },
  { value: "101820", label: "Rifabutin" },
  { value: "101821", label: "Rifadin" },
  { value: "1312", label: "Rifampicin" },
  { value: "1313", label: "Rifater" },
  { value: "1314", label: "Rifinah" },
  { value: "1315", label: "Rilmenidine" },
  { value: "1316", label: "Rilutek" },
  { value: "1317", label: "Riluzole" },
  { value: "101822", label: "Rimactane" },
  { value: "101823", label: "Rimexolone" },
  { value: "1318", label: "Rimonabant" },
  { value: "101824", label: "Rinatec" },
  { value: "1319", label: "Rinialer" },
  { value: "1320", label: "Risedronate" },
  { value: "1321", label: "Risperdal" },
  { value: "1322", label: "Risperidone" },
  { value: "101825", label: "Ritalin" },
  { value: "101826", label: "Ritodrine" },
  { value: "1323", label: "Ritonavir" },
  { value: "1324", label: "Rituximab" },
  { value: "101827", label: "Rivaroxaban" },
  { value: "1325", label: "Rivastigmine" },
  { value: "1326", label: "Rivotril" },
  { value: "1327", label: "Rizatriptan" },
  { value: "101828", label: "Rizopia" },
  { value: "1328", label: "Roaccutane" },
  { value: "101829", label: "RoActemra" },
  { value: "101830", label: "Robaxin" },
  { value: "101831", label: "Robinul" },
  { value: "1329", label: "Rocaltrol" },
  { value: "101832", label: "Rocephin" },
  { value: "101833", label: "Rocuronium" },
  { value: "1330", label: "Rodomel XL" },
  { value: "1331", label: "Rofecoxib" },
  { value: "101834", label: "Roferon-A" },
  { value: "101835", label: "Rogitine" },
  { value: "101836", label: "Romiplostim" },
  { value: "1332", label: "Ropinirole" },
  { value: "101837", label: "Ropivacaine" },
  { value: "101838", label: "Rosiced" },
  { value: "1333", label: "Rosiglitazone" },
  { value: "1334", label: "Rosuvastatin" },
  { value: "101839", label: "Rotarix" },
  { value: "101840", label: "Rotavirus vaccine" },
  { value: "1335", label: "Rotigotine" },
  { value: "101841", label: "Rozex" },
  { value: "101842", label: "Rubella Virus" },
  { value: "101843", label: "Rufinamide" },
  { value: "101844", label: "Rupafin" },
  { value: "1336", label: "Rupatadine" },
  { value: "1337", label: "Rynacrom" },
  { value: "101845", label: "Rynacrom Spray" },
  { value: "1338", label: "Rythmodan" },
  { value: "101846", label: "Rythmodan retard" },
  { value: "1339", label: "Rythmol" },
  { value: "101847", label: "S.O.S. 10" },
  { value: "101848", label: "Sabril" },
  { value: "101849", label: "Saflutan" },
  { value: "101850", label: "Saizen" },
  { value: "101851", label: "Salactol" },
  { value: "101852", label: "Salagen" },
  { value: "1340", label: "Salamol" },
  { value: "101853", label: "Salamol Easi-Breathe" },
  { value: "101854", label: "Salatac" },
  { value: "1341", label: "Salazopyrin" },
  { value: "101855", label: "Salazopyrin EN-Tabs" },
  { value: "101856", label: "Salbulin Novolizer" },
  { value: "1342", label: "Salbutamol" },
  { value: "1343", label: "Salicylic Acid" },
  { value: "101857", label: "Saline Steri-Neb" },
  { value: "101858", label: "Saline Steripoules" },
  { value: "101859", label: "Salinum" },
  { value: "101860", label: "Saliveze" },
  { value: "1344", label: "Salivix" },
  { value: "1345", label: "Salmeterol" },
  { value: "1346", label: "Salofalk" },
  { value: "101861", label: "Samsca" },
  { value: "1347", label: "Sanctura XR" },
  { value: "101862", label: "Sandimmun" },
  { value: "1348", label: "Sandocal" },
  { value: "101863", label: "Sandocal 400" },
  { value: "1349", label: "Sando-K" },
  { value: "1350", label: "Sandostatin" },
  { value: "1351", label: "Sandrena" },
  { value: "101864", label: "Sanomigran" },
  { value: "1352", label: "Saquinavir" },
  { value: "1353", label: "Sativex" },
  { value: "1354", label: "Saw Palmetto" },
  { value: "1355", label: "Saxagliptin" },
  { value: "101865", label: "Scheriproct" },
  { value: "101866", label: "Scopoderm" },
  { value: "1356", label: "Seatone" },
  { value: "101867", label: "Sebco" },
  { value: "101868", label: "Sebivo" },
  { value: "1357", label: "Secalip" },
  { value: "101869", label: "Secobarbital Sodium" },
  { value: "1358", label: "Sectral" },
  { value: "101870", label: "Securon" },
  { value: "101871", label: "Securon I.V." },
  { value: "1359", label: "Securon SR" },
  { value: "1360", label: "Seguril" },
  { value: "101872", label: "Select" },
  { value: "1361", label: "Selectol" },
  { value: "1362", label: "Selegiline" },
  { value: "1363", label: "Selenium" },
  { value: "101873", label: "Selenium sulphide" },
  { value: "101874", label: "Selexid" },
  { value: "1364", label: "Seloken XL" },
  { value: "1365", label: "Selo-Zok" },
  { value: "101875", label: "Selsun" },
  { value: "101876", label: "Semi-Daonil" },
  { value: "1366", label: "Senna" },
  { value: "101877", label: "Senokot Syrup" },
  { value: "101878", label: "Sensi-Care" },
  { value: "1367", label: "Septrin" },
  { value: "1368", label: "Sequacor" },
  { value: "101879", label: "Seractil" },
  { value: "101880", label: "Seravit Paediatric" },
  { value: "1369", label: "Serc" },
  { value: "1370", label: "Serenace" },
  { value: "1371", label: "Seretide" },
  { value: "101881", label: "Seretide Accuhaler" },
  { value: "1372", label: "Serevent" },
  { value: "101882", label: "Serevent Evohaler" },
  { value: "1373", label: "Seroplex" },
  { value: "101883", label: "Seroquel" },
  { value: "1374", label: "Seroquel XL" },
  { value: "1375", label: "Seroxat" },
  { value: "1376", label: "Serpraline" },
  { value: "1377", label: "Sertraline" },
  { value: "1378", label: "Sevelamer" },
  { value: "101884", label: "Sevikar" },
  { value: "1379", label: "Sevredol" },
  { value: "1380", label: "Sibutramine" },
  { value: "101885", label: "Siklos" },
  { value: "1381", label: "Sildenafil" },
  { value: "101886", label: "Silkis" },
  { value: "101887", label: "Silver nitrate" },
  { value: "101888", label: "Silver sulfadiazine" },
  { value: "101889", label: "Similac High Energy" },
  { value: "101890", label: "Simple Eye Ointment" },
  { value: "101891", label: "Simple linctus" },
  { value: "101892", label: "Simulect" },
  { value: "1382", label: "Simvador" },
  { value: "1383", label: "Simvastatin" },
  { value: "1384", label: "Sinemet" },
  { value: "101893", label: "Sinepin" },
  { value: "1385", label: "Singulair" },
  { value: "1386", label: "Sinthrome" },
  { value: "1387", label: "Sintrom" },
  { value: "101894", label: "Siopel" },
  { value: "1388", label: "Sirolimus" },
  { value: "1389", label: "Sitagliptin" },
  { value: "101895", label: "Sitagliptin, Metformin combination" },
  { value: "101896", label: "Sitaxentan" },
  { value: "101897", label: "Skelid" },
  { value: "101898", label: "Skinoren" },
  { value: "101899", label: "Slo Drinks" },
  { value: "1390", label: "Slocinx XL" },
  { value: "1391", label: "Slo-Phyllin" },
  { value: "1392", label: "Slow-FE" },
  { value: "1393", label: "Slow-K" },
  { value: "101900", label: "Slow-Trasicor" },
  { value: "1394", label: "Slozem" },
  { value: "101901", label: "SMA Gold Prem 2" },
  { value: "101902", label: "SMA High Energy" },
  { value: "101903", label: "SMA LF" },
  { value: "101904", label: "SMA Staydown" },
  { value: "101905", label: "Sno Tears" },
  { value: "101906", label: "Sno-Pro Drink" },
  { value: "101907", label: "Sodium Acid Citrate" },
  { value: "101908", label: "Sodium Acid Phosphate" },
  { value: "101909", label: "Sodium Alginate" },
  { value: "101910", label: "Sodium Amytal" },
  { value: "1395", label: "Sodium Aurothiomalate" },
  { value: "1396", label: "Sodium Bicarbonate" },
  { value: "101911", label: "Sodium calcium edetate" },
  { value: "101912", label: "Sodium Chloride" },
  { value: "101913", label: "Sodium citrate" },
  { value: "1397", label: "Sodium Clodronate" },
  { value: "1398", label: "Sodium Cromoglicate" },
  { value: "101914", label: "Sodium feredetate" },
  { value: "101915", label: "Sodium fluoride" },
  { value: "101916", label: "Sodium Fusidate" },
  { value: "101917", label: "Sodium Hyaluronate" },
  { value: "101918", label: "Sodium Nitroprusside" },
  { value: "101919", label: "Sodium oxybate" },
  { value: "101920", label: "Sodium phenylbutyrate" },
  { value: "101921", label: "Sodium Phosphate" },
  { value: "1399", label: "Sodium Picosulfate" },
  { value: "101922", label: "Sodium Sulphate" },
  { value: "101923", label: "Sodium tetradecyl sulphate" },
  { value: "1400", label: "Sodium Valproate" },
  { value: "101924", label: "Sofradex" },
  { value: "101925", label: "Solaraze" },
  { value: "101926", label: "Solian" },
  { value: "1401", label: "Solifenacin" },
  { value: "101927", label: "Soliris" },
  { value: "1402", label: "Solostar" },
  { value: "1403", label: "Solpadol" },
  { value: "101928", label: "Soltamox" },
  { value: "101931", label: "Soluble Insulin" },
  { value: "101929", label: "Solu-Cortef" },
  { value: "101930", label: "Solu-Medrone" },
  { value: "101932", label: "Solvazinc" },
  { value: "1404", label: "Somac" },
  { value: "101933", label: "SomaCorrect" },
  { value: "101934", label: "SomaErect" },
  { value: "1405", label: "Somatropin" },
  { value: "101935", label: "Somatuline Autogel" },
  { value: "101936", label: "Somavert" },
  { value: "101937", label: "Sonata" },
  { value: "101938", label: "Soneryl" },
  { value: "101939", label: "Sorafenib" },
  { value: "1406", label: "Sortis" },
  { value: "101940", label: "Sotacor" },
  { value: "1407", label: "Sotalex" },
  { value: "1408", label: "Sotalol" },
  { value: "101941", label: "Soya Oil" },
  { value: "1409", label: "Spasmonal" },
  { value: "101942", label: "Spasmonal Forte" },
  { value: "101943", label: "Spectraban Ultra" },
  { value: "1410", label: "Spiriva" },
  { value: "1411", label: "Spironolactone" },
  { value: "1412", label: "Sporanox" },
  { value: "101944", label: "Sporanox Pulse" },
  { value: "101945", label: "Sprilon" },
  { value: "1413", label: "Sprycel" },
  { value: "101946", label: "SST" },
  { value: "1414", label: "Stablon" },
  { value: "1415", label: "Stagid" },
  { value: "1416", label: "Stalevo" },
  { value: "101947", label: "Stamaril" },
  { value: "1417", label: "Staril" },
  { value: "101948", label: "Starlix" },
  { value: "101949", label: "Stavudine" },
  { value: "101950", label: "Stelara" },
  { value: "1418", label: "Stelazine" },
  { value: "1419", label: "Stemetil" },
  { value: "101952", label: "Sterculia" },
  { value: "101953", label: "Sterets Aquasol" },
  { value: "101954", label: "Stericlens" },
  { value: "101955", label: "Sterimar" },
  { value: "101956", label: "Steripod" },
  { value: "101951", label: "Ster-Zac Bath Conc." },
  { value: "101957", label: "Stesolid" },
  { value: "101958", label: "Stiemycin" },
  { value: "1420", label: "Stilboestrol" },
  { value: "101959", label: "Stilnoct" },
  { value: "1421", label: "Stilnox" },
  { value: "1422", label: "Strattera" },
  { value: "101960", label: "Strefen" },
  { value: "101961", label: "Streptase" },
  { value: "101962", label: "Streptokinase" },
  { value: "1423", label: "Stronazon MR" },
  { value: "1424", label: "Strontium Ranelate" },
  { value: "1425", label: "Stugeron" },
  { value: "101963", label: "Styrax" },
  { value: "101964", label: "Subcuvia" },
  { value: "101965", label: "Subgam" },
  { value: "101966", label: "Sublimaze" },
  { value: "101967", label: "Suboxone" },
  { value: "101968", label: "Subutex" },
  { value: "1426", label: "Sucralfate" },
  { value: "101969", label: "Sudafed" },
  { value: "101970", label: "Sudocrem" },
  { value: "101971", label: "Sugammadex" },
  { value: "101972", label: "Sulfamethoxazole" },
  { value: "1427", label: "Sulfasalazine" },
  { value: "101973", label: "Sulfinpyrazone" },
  { value: "1428", label: "Sulindac" },
  { value: "101974", label: "Sulphur" },
  { value: "1429", label: "Sulpiride" },
  { value: "1430", label: "Sulpor" },
  { value: "1431", label: "Sumatriptan" },
  { value: "1432", label: "Sunitanib" },
  { value: "101975", label: "Sunitinib" },
  { value: "101976", label: "Sunnyvale" },
  { value: "101977", label: "Sunsense Ultra SPF 50+" },
  { value: "101978", label: "Suplasyn" },
  { value: "101979", label: "Suplena" },
  { value: "101980", label: "Supportan Drink" },
  { value: "1433", label: "Supralip" },
  { value: "101981", label: "Suprax" },
  { value: "101982", label: "Suprecur" },
  { value: "101983", label: "Suprefact" },
  { value: "101984", label: "SureEase" },
  { value: "101985", label: "Surgam" },
  { value: "1434", label: "Surmontil" },
  { value: "101986", label: "Survanta" },
  { value: "101987", label: "Survimed OPD" },
  { value: "101988", label: "Suscard" },
  { value: "101989", label: "Suscard Buccal" },
  { value: "101990", label: "Sustac" },
  { value: "1435", label: "Sustanon" },
  { value: "101991", label: "Sustanon 100" },
  { value: "1436", label: "Sustiva" },
  { value: "1437", label: "Sutent" },
  { value: "101992", label: "Sutherland Jelly" },
  { value: "101993", label: "Suxamethonium" },
  { value: "101994", label: "Sylk" },
  { value: "1438", label: "Symbicort" },
  { value: "101995", label: "Symbicort 100/6 Turbohaler" },
  { value: "1439", label: "Symmetrel" },
  { value: "101996", label: "Synacthen Depot" },
  { value: "101997", label: "Synagis" },
  { value: "101998", label: "Synalar" },
  { value: "101999", label: "Synalar 1 in 4" },
  { value: "102000", label: "Synalar Cream 1 in 10" },
  { value: "102001", label: "Synarel" },
  { value: "102002", label: "Syner-KINASE" },
  { value: "102003", label: "Synflex" },
  { value: "102004", label: "Synflorix" },
  { value: "102005", label: "Synocrom" },
  { value: "102006", label: "Synopsis" },
  { value: "1440", label: "Syntaris" },
  { value: "1441", label: "Synthyroid" },
  { value: "102007", label: "Syntocinon" },
  { value: "102008", label: "Syntometrine" },
  { value: "102009", label: "Synvisc" },
  { value: "102010", label: "Syprol" },
  { value: "102011", label: "Syscor MR" },
  { value: "102012", label: "Systane" },
  { value: "102013", label: "Sytron" },
  { value: "1442", label: "Tabphyn MR" },
  { value: "102014", label: "Tacalcitol" },
  { value: "1443", label: "Tacrolimus" },
  { value: "1444", label: "Tadalafil" },
  { value: "102015", label: "Tafluprost" },
  { value: "1445", label: "Tagamet" },
  { value: "1446", label: "Tahor" },
  { value: "1447", label: "Tambocor" },
  { value: "1448", label: "Tamiflu" },
  { value: "1449", label: "Tamoxifen" },
  { value: "1450", label: "Tamsulosin" },
  { value: "102016", label: "Tamsulosin Hydrochloride" },
  { value: "1451", label: "Tamurex" },
  { value: "1452", label: "Tanatril" },
  { value: "102017", label: "Tantril" },
  { value: "102018", label: "Tar" },
  { value: "102019", label: "Taranis" },
  { value: "1453", label: "Tarceva" },
  { value: "1454", label: "Tareg" },
  { value: "102020", label: "Targinact" },
  { value: "102021", label: "Targocid" },
  { value: "102022", label: "Targretin" },
  { value: "102023", label: "Tarivid" },
  { value: "102024", label: "Tarka" },
  { value: "102025", label: "Tasigna" },
  { value: "102026", label: "Tasmar" },
  { value: "102027", label: "Tavanic" },
  { value: "102028", label: "Tavegil" },
  { value: "1455", label: "Taxol" },
  { value: "1456", label: "Taxotere" },
  { value: "102029", label: "Tazarotene" },
  { value: "102030", label: "Tazobactam" },
  { value: "102031", label: "Tazocin" },
  { value: "102032", label: "Tears Naturale" },
  { value: "1457", label: "Tebetane" },
  { value: "102033", label: "Tegafur" },
  { value: "1458", label: "Tegretol" },
  { value: "102034", label: "Teicoplanin" },
  { value: "102035", label: "Telbivudine" },
  { value: "1459", label: "Telfast" },
  { value: "102036", label: "Telithromycin" },
  { value: "1460", label: "Telmisartan" },
  { value: "102037", label: "Telzir" },
  { value: "1461", label: "Temazepam" },
  { value: "1462", label: "Temerit" },
  { value: "1463", label: "Temgesic" },
  { value: "1464", label: "Temodal" },
  { value: "102038", label: "Temoporfin" },
  { value: "1465", label: "Temozolomide" },
  { value: "102039", label: "Temsirolimus" },
  { value: "102040", label: "Tenecteplase" },
  { value: "1466", label: "Tenif" },
  { value: "1467", label: "Tenofovir" },
  { value: "1468", label: "Tenoret" },
  { value: "102041", label: "Tenoret 50" },
  { value: "102042", label: "Tenoretic" },
  { value: "1469", label: "Tenormin" },
  { value: "102043", label: "Tenoxicam" },
  { value: "1470", label: "Tensaid" },
  { value: "1471", label: "Tensionorme" },
  { value: "102044", label: "Tensipine MR" },
  { value: "102045", label: "Tentrini" },
  { value: "102046", label: "Teoptic" },
  { value: "1472", label: "Terazosin" },
  { value: "1473", label: "Terbinafine" },
  { value: "1474", label: "Terbutaline" },
  { value: "102047", label: "Teriparatide" },
  { value: "1475", label: "Terlipressin" },
  { value: "1476", label: "Ternozolamide" },
  { value: "1477", label: "Tertroxin" },
  { value: "1478", label: "Terutroban" },
  { value: "1479", label: "Tesavel" },
  { value: "102048", label: "Testim" },
  { value: "1480", label: "Testogel" },
  { value: "102049", label: "Testosterone" },
  { value: "102050", label: "Tetrabenazine" },
  { value: "102051", label: "Tetracaine" },
  { value: "102052", label: "Tetracosactide" },
  { value: "1481", label: "Tetracycline" },
  { value: "102053", label: "Tetrahydrofurfuryl Salicylate" },
  { value: "102054", label: "Tetralysal" },
  { value: "102055", label: "Tevagrastim" },
  { value: "1482", label: "Teveten" },
  { value: "1483", label: "Thalidomide" },
  { value: "102056", label: "Thalidomide Celgene" },
  { value: "102057", label: "Thelin" },
  { value: "1484", label: "Theophylline" },
  { value: "1485", label: "Thiamine Hydrochloride" },
  { value: "102058", label: "Thick And Easy" },
  { value: "102059", label: "Thixo-D Original" },
  { value: "102060", label: "Thymoglobuline" },
  { value: "1486", label: "Thyroxine" },
  { value: "102061", label: "Tiagabine" },
  { value: "1487", label: "Tianeptine" },
  { value: "102062", label: "Tiaprofenic acid" },
  { value: "1488", label: "Tiazac" },
  { value: "1489", label: "Tibolone" },
  { value: "102063", label: "Ticarcillin" },
  { value: "102064", label: "Tick-borne encephalitis vaccine" },
  { value: "1490", label: "Ticlopidine" },
  { value: "102065", label: "TicoVac" },
  { value: "102066", label: "Tigecycline" },
  { value: "1491", label: "Tilade" },
  { value: "1492", label: "Tildiem" },
  { value: "102067", label: "Tildiem LA" },
  { value: "102068", label: "Tildiem Retard" },
  { value: "102069", label: "Tiloryth" },
  { value: "102070", label: "Tiludronate" },
  { value: "102071", label: "Timentin" },
  { value: "102072", label: "Timodine" },
  { value: "1493", label: "Timolol" },
  { value: "102073", label: "Timolol Maleate" },
  { value: "1494", label: "Timoptol" },
  { value: "102074", label: "Timoptol-LA" },
  { value: "102075", label: "Tinidazole" },
  { value: "1495", label: "Tinzaparin" },
  { value: "102076", label: "Tioconazole" },
  { value: "102077", label: "Tioguanine" },
  { value: "1496", label: "Tiotropium" },
  { value: "102078", label: "Tipranavir" },
  { value: "102079", label: "Tirofiban" },
  { value: "102080", label: "Tisept" },
  { value: "1497", label: "Tizanidine" },
  { value: "102081", label: "TOBI" },
  { value: "102082", label: "Tobia Teff Flour" },
  { value: "102083", label: "Tobradex" },
  { value: "102084", label: "Tobramycin" },
  { value: "102085", label: "Tocilizumab" },
  { value: "102086", label: "Toctino" },
  { value: "1498", label: "Tolbutamide" },
  { value: "102087", label: "Tolcapone" },
  { value: "102088", label: "Tolfenamic acid" },
  { value: "1499", label: "Tolterodine" },
  { value: "102089", label: "Tolvaptan" },
  { value: "102090", label: "Tomudex" },
  { value: "102091", label: "Topal" },
  { value: "1500", label: "Topamax" },
  { value: "1501", label: "Topiramate" },
  { value: "1502", label: "Topotecan" },
  { value: "1503", label: "Toprol XL" },
  { value: "102092", label: "Toradol" },
  { value: "1504", label: "Torasemide" },
  { value: "1505", label: "Torcetrapib" },
  { value: "102093", label: "Torem" },
  { value: "102094", label: "Toremifene" },
  { value: "102095", label: "Torisel" },
  { value: "1506", label: "Torvastin" },
  { value: "102096", label: "Tostran" },
  { value: "1507", label: "Toviaz" },
  { value: "102097", label: "Trabectedin" },
  { value: "102098", label: "Tracleer" },
  { value: "102099", label: "Tracrium" },
  { value: "102100", label: "Tractocile" },
  { value: "102101", label: "Tradorec XL" },
  { value: "1508", label: "Tramacet" },
  { value: "1509", label: "Tramadol" },
  { value: "1510", label: "Trandolapril" },
  { value: "1511", label: "Tranexamic acid" },
  { value: "102102", label: "Transiderm-Nitro" },
  { value: "1512", label: "Transtec" },
  { value: "102103", label: "Transvasin" },
  { value: "1513", label: "Tranylcypromine" },
  { value: "1514", label: "Trasicor" },
  { value: "102104", label: "Trasidrex" },
  { value: "1515", label: "Trastuzumab" },
  { value: "102105", label: "Travasept 100" },
  { value: "1516", label: "Travatan" },
  { value: "1517", label: "Travoprost" },
  { value: "102106", label: "Traxam" },
  { value: "1518", label: "Trazodone" },
  { value: "102107", label: "TREATMENT" },
  { value: "102108", label: "Tredaptive" },
  { value: "102109", label: "Trental" },
  { value: "102110", label: "Tretinoin" },
  { value: "1519", label: "Triamcinolone" },
  { value: "1520", label: "Triamterene" },
  { value: "1521", label: "Triatec" },
  { value: "102112", label: "Triclosan" },
  { value: "1522", label: "Tridestra" },
  { value: "1523", label: "Trifluoperazine" },
  { value: "1524", label: "Triflusal" },
  { value: "1525", label: "Trihexyphenidyl" },
  { value: "102113", label: "Trileptal" },
  { value: "102114", label: "Trilostane" },
  { value: "1526", label: "Trimetazidine" },
  { value: "1527", label: "Trimethoprim" },
  { value: "1528", label: "Trimipramine" },
  { value: "102115", label: "Trimopan" },
  { value: "102116", label: "Trimovate" },
  { value: "102117", label: "Trintek" },
  { value: "102111", label: "Tri-potassium di-citrato bismuthate" },
  { value: "1529", label: "Triptafen" },
  { value: "1530", label: "Triptorelin" },
  { value: "1531", label: "Trisequens" },
  { value: "1532", label: "Tritace" },
  { value: "102118", label: "Tritamyl" },
  { value: "1533", label: "Trizivir" },
  { value: "1534", label: "Tromalyt" },
  { value: "1535", label: "Trombyl" },
  { value: "102119", label: "Tropicamide" },
  { value: "1536", label: "Trospium" },
  { value: "102120", label: "Trospium chloride" },
  { value: "102121", label: "Trosyl" },
  { value: "102122", label: "Trusopt" },
  { value: "1537", label: "Truvada" },
  { value: "1538", label: "Tryptizol" },
  { value: "102123", label: "Tryptophan" },
  { value: "102124", label: "Tuinal" },
  { value: "102125", label: "Twinrix Adult" },
  { value: "102126", label: "Tygacil" },
  { value: "1539", label: "Tylex" },
  { value: "102127", label: "Typherix" },
  { value: "102128", label: "Typhim Vi" },
  { value: "102129", label: "Typhoid" },
  { value: "102130", label: "TYR Anamix Junior" },
  { value: "102131", label: "TYR Express" },
  { value: "102132", label: "Tyrosine" },
  { value: "102133", label: "Tyrosine AA Supplement" },
  { value: "102134", label: "Tysabri" },
  { value: "1540", label: "Tyverb" },
  { value: "102135", label: "Ubretid" },
  { value: "1541", label: "Ucerax" },
  { value: "102136", label: "Uftoral" },
  { value: "102137", label: "Ultimate" },
  { value: "102138", label: "Ultiva" },
  { value: "102139", label: "Ultra" },
  { value: "102140", label: "Ultra GF" },
  { value: "102141", label: "Ultra PKU" },
  { value: "102142", label: "Ultrabase" },
  { value: "102143", label: "Ultralanum" },
  { value: "102144", label: "Ultraproct" },
  { value: "102145", label: "Undecenoic Acid" },
  { value: "102146", label: "Unguentum M" },
  { value: "1542", label: "Uniphyllin Continus" },
  { value: "102147", label: "Uniroid HC" },
  { value: "102148", label: "Unisept" },
  { value: "1543", label: "Univer" },
  { value: "102149", label: "Uracil" },
  { value: "102150", label: "Urdox" },
  { value: "102151", label: "Urea" },
  { value: "102152", label: "Urea Hydrogen Peroxide" },
  { value: "102153", label: "Uriben" },
  { value: "102154", label: "Uriflex G" },
  { value: "102155", label: "Urispas" },
  { value: "102156", label: "Urofollitropin" },
  { value: "102157", label: "Urokinase" },
  { value: "1544", label: "Ursodeoxycholic Acid" },
  { value: "1545", label: "Ursofalk" },
  { value: "102158", label: "Ursogal" },
  { value: "102159", label: "Ustekinumab" },
  { value: "102160", label: "Utinor" },
  { value: "102161", label: "Utovlan" },
  { value: "102162", label: "Utrogestan" },
  { value: "102163", label: "Uvistat SPF30" },
  { value: "1546", label: "Vagifem" },
  { value: "1547", label: "Valaciclovir" },
  { value: "102164", label: "Valclair" },
  { value: "102165", label: "Valcyte" },
  { value: "102166", label: "Valdoxan" },
  { value: "102167", label: "Valganciclovir" },
  { value: "102168", label: "Valine" },
  { value: "102169", label: "Valine AA Supplement" },
  { value: "1548", label: "Valium" },
  { value: "102170", label: "Valni XL" },
  { value: "102171", label: "Valoid" },
  { value: "1549", label: "Valproate" },
  { value: "1550", label: "Valproic Acid" },
  { value: "1551", label: "Valsartan" },
  { value: "1552", label: "Valtrex" },
  { value: "102172", label: "Vancocin" },
  { value: "102173", label: "Vancomycin" },
  { value: "102174", label: "Vaniqa" },
  { value: "102175", label: "Vantas" },
  { value: "102176", label: "Vaqta Paediatric" },
  { value: "1553", label: "Varbim XL" },
  { value: "1554", label: "Vardenafil" },
  { value: "1555", label: "Varenicline" },
  { value: "102177", label: "Varicella zoster vaccine" },
  { value: "102178", label: "Varilrix" },
  { value: "102179", label: "Varivax" },
  { value: "102180", label: "Vascace" },
  { value: "1556", label: "Vascalpha" },
  { value: "102181", label: "Vasodilator - Other" },
  { value: "102182", label: "Vasogen" },
  { value: "1557", label: "Vastarel" },
  { value: "102183", label: "Vectavir" },
  { value: "102184", label: "Vectibix" },
  { value: "102185", label: "Vecuronium" },
  { value: "102186", label: "Vegenat-Med High Protein" },
  { value: "102187", label: "Veil" },
  { value: "102188", label: "Velbe" },
  { value: "1558", label: "Velcade" },
  { value: "1559", label: "Velosef" },
  { value: "102189", label: "Velosulin" },
  { value: "1560", label: "Venlafaxine" },
  { value: "1561", label: "Venlalic XL" },
  { value: "1562", label: "Venofer" },
  { value: "102190", label: "Ventavis" },
  { value: "102191", label: "Ventmax SR" },
  { value: "1563", label: "Ventolin" },
  { value: "102192", label: "Ventolin Evohaler" },
  { value: "102193", label: "Ventolin Infusion" },
  { value: "102194", label: "Ventricular anti-arrhythmic - Other" },
  { value: "102195", label: "Vepesid" },
  { value: "102196", label: "Veracur" },
  { value: "1564", label: "Verapamil" },
  { value: "102197", label: "Vermox" },
  { value: "102198", label: "Verrugon" },
  { value: "1565", label: "Versatis" },
  { value: "102199", label: "Versol" },
  { value: "1566", label: "Vertab SR" },
  { value: "102200", label: "Verteporfin" },
  { value: "102201", label: "Vesanoid" },
  { value: "1567", label: "Vesicare" },
  { value: "102202", label: "Vexol" },
  { value: "102203", label: "Vfend" },
  { value: "1568", label: "Viagra" },
  { value: "102204", label: "ViATIM" },
  { value: "1569", label: "Viazem XL" },
  { value: "102205", label: "Vibramycin-D" },
  { value: "102206", label: "Victanyl" },
  { value: "1570", label: "Victoza" },
  { value: "1571", label: "Vidaza" },
  { value: "102207", label: "Videne" },
  { value: "102208", label: "Videx" },
  { value: "102209", label: "Vigabatrin" },
  { value: "102210", label: "Vigam" },
  { value: "1572", label: "Vildagliptin" },
  { value: "102211", label: "Vimpat" },
  { value: "1573", label: "Vinblastine" },
  { value: "1574", label: "Vincristine" },
  { value: "1575", label: "Vindesine" },
  { value: "102212", label: "Vinflunine" },
  { value: "1576", label: "Vinorelbine" },
  { value: "1577", label: "Vioform-Hydrocortisone" },
  { value: "1578", label: "Vioxx" },
  { value: "102213", label: "Vipidia" },
  { value: "102214", label: "Viracept" },
  { value: "102215", label: "ViraferonPeg" },
  { value: "102216", label: "Viramune" },
  { value: "102217", label: "Viread" },
  { value: "102218", label: "Virgan" },
  { value: "102219", label: "Viridal Duo" },
  { value: "102220", label: "Viroflu" },
  { value: "102221", label: "Virormone" },
  { value: "1579", label: "Visclair" },
  { value: "1580", label: "Viscotears" },
  { value: "102222", label: "Viskaldix" },
  { value: "102223", label: "Visken" },
  { value: "102224", label: "Vismed" },
  { value: "102225", label: "Vistide" },
  { value: "102226", label: "Visudyne" },
  { value: "102227", label: "Vitalograph" },
  { value: "102228", label: "Vitamin D" },
  { value: "102229", label: "Vitamin D3" },
  { value: "102230", label: "Vivaglobin" },
  { value: "1581", label: "Vivaxa" },
  { value: "102231", label: "Vividrin Drops" },
  { value: "102232", label: "Vividrin Spray" },
  { value: "102233", label: "Vivotif" },
  { value: "102234", label: "Volibris" },
  { value: "1582", label: "Volsaid Retard" },
  { value: "1583", label: "Voltarene SR" },
  { value: "1584", label: "Voltarol" },
  { value: "102235", label: "Voltarol Emulgel" },
  { value: "102236", label: "Voltarol Ophtha" },
  { value: "102237", label: "Volumatic" },
  { value: "102238", label: "Voriconazole" },
  { value: "102239", label: "Vortex" },
  { value: "102240", label: "Votrient" },
  { value: "102241", label: "VSL#3" },
  { value: "1585", label: "Warfarin" },
  { value: "102242", label: "Warticon" },
  { value: "102243", label: "Waxsol" },
  { value: "102244", label: "Welldorm" },
  { value: "102245", label: "Wellvone" },
  { value: "1586", label: "Wobenzyme" },
  { value: "102246", label: "Wool Fat" },
  { value: "102247", label: "Wysoy" },
  { value: "102248", label: "Xagrid" },
  { value: "1587", label: "Xalacom" },
  { value: "1588", label: "Xalatan" },
  { value: "102249", label: "Xamiol" },
  { value: "1589", label: "Xanax" },
  { value: "102250", label: "Xarelto" },
  { value: "1590", label: "Xatral XL" },
  { value: "102251", label: "Xclair" },
  { value: "1591", label: "Xeloda" },
  { value: "102252", label: "Xenazine 25" },
  { value: "1592", label: "Xenical" },
  { value: "102253", label: "Xeomin" },
  { value: "1593", label: "Xepin" },
  { value: "102254", label: "Xerotin" },
  { value: "102255", label: "Xigris" },
  { value: "1594", label: "Xipamide" },
  { value: "1595", label: "Xismox XL" },
  { value: "102256", label: "Xolair" },
  { value: "102257", label: "Xylitol" },
  { value: "102258", label: "Xylocaine" },
  { value: "102259", label: "Xylometazoline" },
  { value: "102260", label: "Xyloproct" },
  { value: "102261", label: "Xyrem" },
  { value: "1596", label: "Xyzal" },
  { value: "102262", label: "Yellow fever vaccine" },
  { value: "102263", label: "Yellow Soft Paraffin" },
  { value: "102264", label: "Yentreve" },
  { value: "102265", label: "Yondelis" },
  { value: "102266", label: "Yutopar" },
  { value: "1597", label: "Zacin" },
  { value: "102267", label: "Zaditen" },
  { value: "102268", label: "Zaditen Drops" },
  { value: "1598", label: "Zafirlukast" },
  { value: "1599", label: "Zaldiar" },
  { value: "102269", label: "Zaleplon" },
  { value: "1600", label: "Zamadol" },
  { value: "102270", label: "Zamadol SR" },
  { value: "1601", label: "Zamene" },
  { value: "1602", label: "Zanaflex" },
  { value: "102271", label: "Zanamivir" },
  { value: "1603", label: "Zanidip" },
  { value: "1604", label: "Zantac" },
  { value: "1605", label: "Zapain" },
  { value: "1606", label: "Zarator" },
  { value: "102272", label: "Zarontin" },
  { value: "102273", label: "Zarzio" },
  { value: "102274", label: "Zavedos" },
  { value: "102275", label: "Zavesca" },
  { value: "102276", label: "Zeasorb" },
  { value: "102277", label: "Zebinix" },
  { value: "102278", label: "Zeffix" },
  { value: "102279", label: "Zelapar" },
  { value: "1607", label: "Zemon" },
  { value: "102280", label: "Zemplar" },
  { value: "1608", label: "Zemtard" },
  { value: "102281", label: "Zemtard XL" },
  { value: "102282", label: "Zerit" },
  { value: "102283", label: "Zerobase" },
  { value: "102284", label: "ZeroCream" },
  { value: "102285", label: "Zeroguent" },
  { value: "102286", label: "Zerolatum" },
  { value: "102287", label: "Zeroneum" },
  { value: "102288", label: "Zerozole" },
  { value: "1609", label: "Zestoretic" },
  { value: "102289", label: "Zestoretic 20" },
  { value: "1610", label: "Zestril" },
  { value: "1611", label: "Zetia" },
  { value: "102290", label: "Ziagen" },
  { value: "102291", label: "Zibor" },
  { value: "102292", label: "Ziconotide" },
  { value: "102293", label: "Zidoval" },
  { value: "1612", label: "Zidovudine" },
  { value: "1613", label: "Zimbacol XL" },
  { value: "102294", label: "Zimovane" },
  { value: "1614", label: "Zimstat" },
  { value: "102295", label: "Zinacef" },
  { value: "102296", label: "Zinc" },
  { value: "102297", label: "Zinc Acetate" },
  { value: "102298", label: "Zinc Oxide" },
  { value: "102299", label: "Zinc sulphate" },
  { value: "102300", label: "Zindaclin" },
  { value: "102301", label: "Zineryt" },
  { value: "102302", label: "Zirtek Allergy" },
  { value: "102303", label: "Zispin Soltab" },
  { value: "1615", label: "Zithromax" },
  { value: "1616", label: "Zocor" },
  { value: "102304", label: "Zofran" },
  { value: "1617", label: "Zoladex" },
  { value: "102305", label: "Zoledronate" },
  { value: "1618", label: "Zoledronic Acid" },
  { value: "102306", label: "Zoleptil" },
  { value: "1619", label: "Zolmitriptan" },
  { value: "1620", label: "Zoloft" },
  { value: "1621", label: "Zolpidem" },
  { value: "102307", label: "Zolvera" },
  { value: "102308", label: "Zomacton" },
  { value: "1622", label: "Zometa" },
  { value: "102309", label: "Zomig" },
  { value: "1623", label: "Zomorph" },
  { value: "1624", label: "Zonegran" },
  { value: "1625", label: "Zonisamide" },
  { value: "1626", label: "Zopiclone" },
  { value: "102310", label: "Zorac" },
  { value: "1627", label: "Zortress" },
  { value: "102311", label: "Zotepine" },
  { value: "1628", label: "Zoton" },
  { value: "102312", label: "Zoton FasTab" },
  { value: "102313", label: "Zovirax" },
  { value: "102314", label: "Zovirax Eye Ointment" },
  { value: "1629", label: "Zoxan" },
  { value: "1630", label: "Zuclopenthixol" },
  { value: "1631", label: "Zumenon" },
  { value: "1632", label: "Zyban" },
  { value: "1633", label: "Zydol" },
  { value: "102315", label: "Zydol SR" },
  { value: "1634", label: "Zyloric" },
  { value: "102316", label: "Zyomet" },
  { value: "102317", label: "Zypadhera" },
  { value: "1635", label: "Zyprexa" },
  { value: "102318", label: "Zyvox Tablets" },
];

export const groupedOptions: IGroupedOption[] = [
  {
    label: "Medications",
    groupValue: "medicaitons",
    options: allMedications,
  },
];
