import * as Yup from "yup";

const header = "Personal details";

const description = "Complete your personal details.";

const schema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .required()
    .label("Title")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Title",
          description: "",
          name: "title",
          tooltip: "",
          required: true,
          items: [
            { label: "Mr", value: "Mr" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "Ms", value: "Ms" },
            { label: "Dr", value: "dr" },
          ],
        },
      },
    }),
  firstName: Yup.string()
    .nullable()
    .required()
    .label("First Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "First Name",
          description: "",
          name: "firstName",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          validationType: "name",
          minValue: 2,
          maxValue: 25
        },
      },
    }),
  middleName: Yup.string()
    .nullable()
    .label("Middle Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Middle Name",
          description: "",
          name: "middleName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          maxValue: 25
        },
      },
    }),
  lastName: Yup.string()
    .nullable()
    .required()
    .label("Last Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Last Name",
          description: "",
          name: "lastName",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          validationType: "name",
          minValue: 2,
          maxValue: 25
        },
      },
    }),
  hasPreviousNamesYesNo: Yup.string()
    .nullable()
    .required()
    .label("Do you have any previous names?")
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: "Do you have any previous names?",
          description:
            "We need to know this to ensure we have accurate historical data and can correctly match any records.",
          name: "hasPreviousNamesYesNo",
          tooltip: "",
          required: true,
        },
      },
    }),

  previousNames: Yup.string()
    .nullable()
    .when("hasPreviousNamesYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable(),
    })
    .label("Previous Names")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Previous Names",
          description: "",
          name: "previousNames",
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          validationType: "name",
          maxValue: 25
        },
        dependsOnFields: [
          { fieldName: "hasPreviousNamesYesNo", fieldValue: "true" },
        ],
      },
    }),
  previousNamesDateChanged: Yup.string()
    .nullable()
    .when("hasPreviousNamesYesNo", {
      is: (val: string) => val == "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable(),
    })
    .label("Date changed")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "previousNamesDateChanged",
          label: "Date changed",
          required: true,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear(),
        },
        dependsOnFields: [
          { fieldName: "hasPreviousNamesYesNo", fieldValue: "true" },
        ],
      },
    }),

  sex: Yup.string()
    .nullable()
    .required()
    .label("Sex")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          required: true,
          label: "Sex",
          description: "",
          name: "sex",
          tooltip: "",
          items: [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ],
        },
      },
    }),

  dateOfBirth: Yup.string()
    .nullable()
    .required()
    .label("Date of Birth")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "dateOfBirth",
          label: "Your Date of Birth",
          required: true,
          description: "You must be over 55 to get quotes.",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear() - 4,
        },
      },
    }),

  maritalStatus: Yup.string()
    .nullable()
    .required()
    .label("Marital Status")
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          required: true,
          label: "Marital Status",
          description:
            "Your marital status can affect your pension benefits, especially if you wish to add protection to your product which will provide income to your spouse or partner in the event of your death.",
          name: "maritalStatus",
          tooltip: "",
          values: [
            "",
            "Single",
            "Married",
            "Divorced",
            "Widowed",
            "Separated",
            "Co-habiting",
            "Civil Partnership",
            "Intend To Marry",
            "Intend To Form Civil Partnership",
            "Dissolved Civil Partnership",
          ],
        },
      },
    }),

  niNumber: Yup.string()
    .nullable()
    .required()
    .label("NI Number")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "NI Number",
          description:
            "This is needed for identification purposes and to access any relevant pension data",
          name: "niNumber",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          validationType: "niNumber"
        },
      },
    }),

  postcode: Yup.string()
    .nullable()
    .label("Postcode")
    .meta({
      control: {
        componentType: "Postcode",
        props: {
          label: "Your Postcode",
          description: "",
          name: "postcode",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          addressLine1FieldName: "address",
          cityFieldName: "city",
          includeFindAddress: true,
          validationType: "postcode"
        },
      },
    }),

  address: Yup.string()
    .nullable()
    .required()
    .label("address")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Your Address",
          description: "",
          name: "address",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  city: Yup.string()
    .nullable()
    .required()
    .label("city")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Your City",
          description: "",
          name: "city",
          required: true,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  addSpouseOrPartnerYesNo: Yup.string()
    .nullable()
    .required()
    .label("Would you like to add a spouse or partner?")
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: "Would you like to add a spouse or partner?",
          description:
            "You can leave your pension to a spouse or partner upon your death. Select Yes if you would like to do so.",
          name: "addSpouseOrPartnerYesNo",
          tooltip: "",
          required: true,
        },
      },
    }),

  person2Title: Yup.string()
    .nullable()
    .when("addSpouseOrPartnerYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("What is their Title")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Their Title",
          description: "",
          name: "person2Title",
          tooltip: "",
          items: [
            { label: "Mr", value: "Mr" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "Ms", value: "Ms" },
            { label: "Dr", value: "dr" },
          ],
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),
  person2FirstName: Yup.string()
    .nullable()
    .when("addSpouseOrPartnerYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their First Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their First Name",
          description: "",
          name: "person2FirstName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          validationType: "name",
          maxValue: 25
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),
  person2MiddleName: Yup.string()
    .nullable()
    .label("Their Middle Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Middle Name",
          description: "",
          name: "person2MiddleName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          validationType: "name",
          maxValue: 25
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),
  person2LastName: Yup.string()
    .nullable()
    .when("addSpouseOrPartnerYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their Last Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Last Name",
          description: "",
          name: "person2LastName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          validationType: "name",
          maxValue: 25
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2HasPreviousNamesYesNo: Yup.string()
    .nullable()
    .when("addSpouseOrPartnerYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Do they have any previous names?")
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: "Do they have any previous names?",
          description: "",
          name: "person2HasPreviousNamesYesNo",
          tooltip: "",
          required: true,
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2previousNames: Yup.string()
    .nullable()
    .when("person2HasPreviousNamesYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their Previous Names")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Previous Names",
          description: "",
          name: "person2previousNames",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          validationType: "name",
          maxValue: 25
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
          { fieldName: "person2HasPreviousNamesYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2previousNamesDateChanged: Yup.string()
    .nullable()
    .when("person2HasPreviousNamesYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Date changed")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "person2previousNamesDateChanged",
          label: "Date changed",
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear(),
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
          { fieldName: "person2HasPreviousNamesYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2Sex: Yup.string()
    .nullable()
    .when("addSpouseOrPartnerYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their Sex")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Their Sex",
          description: "",
          name: "person2Sex",
          tooltip: "",
          items: [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ],
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2DateOfBirth: Yup.string()
    .nullable()
    .when("addSpouseOrPartnerYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their Date of Birth")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "person2DateOfBirth",
          label: "Their Date of Birth",
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear() - 4,
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2NINumber: Yup.string()
    .nullable()
    .when("addSpouseOrPartnerYesNo", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their NI Number")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their NI Number",
          description: "",
          name: "person2NINumber",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          validationType: "niNumber"
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2PostCode: Yup.string()
    .nullable()
    .label("Their Postcode")
    .meta({
      control: {
        componentType: "Postcode",
        props: {
          label: "Their Postcode",
          description: "",
          name: "person2Postcode",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          addressLine1FieldName: "person2Address",
          cityFieldName: "person2City",
          includeFindAddress: true,
          validationType: "postcode"
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2Address: Yup.string()
    .nullable()

    .label("Their Address")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Address",
          description: "",
          name: "person2Address",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),

  person2City: Yup.string()
    .nullable()
    .label("Their City")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their City",
          description: "",
          name: "person2City",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartnerYesNo", fieldValue: "true" },
        ],
      },
    }),
});

export { header, description, schema };
