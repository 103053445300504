import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { Notice } from "./Notice";

export const QuoteCollection = (props: IProps) => {
  return (
    <>
      <div className="card mb-5 mb-xl-10">
        <div className="text-center">
          <div className="card-title m-0 d-flex justify-content-center mt-10">
            <div className="fw-bold fs-2">{props.title}</div>
            {props.titleLink && (
              <Link
                className={props.titleLink.className || "small"}
                to={props.titleLink.to}
              >
                {props.titleLink.label}
              </Link>
            )}
          </div>
          {props.titleSmallText && (
            <div className="small">{props.titleSmallText}</div>
          )}
        </div>
        <div className="card-body p-9">
          {props.topParagraph && <p className="mb-10">{props.topParagraph}</p>}
          <div>
            {props.quotes.map((item, index) => {
              return (
                <div className="mb-10" key={index}>
                  {item.outerTitle && (
                    <h2 className="mb-5 mt-5">{item.outerTitle}</h2>
                  )}
                  <div
                    key={index}
                    className="card quote-card mb-5 border border-gray-400"
                  >
                    <div className="card-body">
                      <div className="d-md-flex flex-md-row flex-wrap flex-sm-nowrap align-items-center justify-content-center">
                        {/* logo */}
                        <div className="me-md-15 d-md-flex justify-content-sm-start justify-content-center">
                          <div className="flex-shrink-md-0 me-md-4 text-center">
                            {item.logoLocation && (
                              <img
                                src={toAbsoluteUrl(item.logoLocation)}
                                alt="logo"
                                style={{ height: "auto", width: "75px" }}
                              />
                            )}
                          </div>
                        </div>
                        {/* end of logo */}

                        <div className="flex-grow-1 container flex-column">
                          {/*quote title */}
                          {item.title && (
                            <div className="pb-5 pt-5 fs-3 fw-bold">
                              {item.title}
                            </div>
                          )}
                          {/*end of quote title */}

                          {/* start of quote values */}
                          <div className="container">
                            <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                              {item.quoteValues.map((qVItem, qVIndex) => {
                                return (
                                  <div
                                    key={qVIndex}
                                    className="d-flex flex-column align-items-center my-3 mb-sm-0"
                                  >
                                    <div className="fs-5 fw-bold text-center">
                                      {qVItem.title}
                                    </div>
                                    <div className="fs-3 text-primary text-center">
                                      {qVItem.value}
                                    </div>
                                  </div>
                                );
                              })}
                              {item.button && (
                                <Link
                                  id={item.button.id}
                                  className={
                                    item.button.className
                                      ? item.button.className +
                                        " d-flex align-items-center justify-content-center"
                                      : "btn btn-primary d-flex align-items-center justify-content-center"
                                  }
                                  to={item.button.to}
                                >
                                  {item.button.label}
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* end of quote values */}
                      </div>
                    </div>
                    {item.footer && (
                      <div className="card-footer d-flex justify-content-center fw-bold pt-5 pb-5">
                        {item.footer}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            {props.notice && (
              <Notice
                noticeClasses={props.notice.noticeClasses}
                icon={props.notice.icon}
                iconClasses={props.notice.iconClasses}
                header={props.notice.header}
                content={props.notice.content}
                button={props.notice.button}
              />
            )}
          </div>
          {props.footer && <div className="card-footer">{props.footer}</div>}
          {props.bottomButton && (
            <div className="d-flex align-items-center justify-content-center py-10">
              <Link
                className={
                  props.bottomButton.className ||
                  "btn btn-primary d-flex align-items-center"
                }
                to={props.bottomButton.to}
              >
                {props.bottomButton.label}
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

interface IProps {
  title: string | null;
  titleLink: IButton | null;
  titleSmallText?: string;
  topParagraph: string | null;
  bottomParagraph: string | null;
  notice: INotice | null;
  footer: string | null;
  quotes: IQuote[];
  bottomButton?: IButton | null;
}

interface IButton {
  label: string;
  to: string;
  className: string | null;
  id?: string;
}

interface IQuote {
  outerTitle?: string;
  logoLocation: string;
  title?: string | null;
  quoteValues: IQuoteValue[];
  button: IButton | null;
  footer?: string | null;
}

interface IQuoteValue {
  title: string | null;
  value: string;
}

interface INotice {
  noticeClasses: string | null;
  icon: ReactNode | null;
  iconClasses: string | null;
  header: string | null;
  content: string | null;
  button: IButton | null;
}
