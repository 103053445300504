import * as Yup from "yup";
import { pensionSchema } from "../Shared/PensionSchema";
import { medicalConditionSchema } from "../Shared/MedicalConditionSchema";
import { propertySchema } from "../Shared/PropertySchema";
import { groupedOptions as groupedPensionProviders } from "../../../../models/PensionProviders";
import { groupedOptions as groupedMedicalConditions } from "../../../../models/MedicalConditionsData";
import { groupedOptions as groupedMedications } from "../../../../models/MedicationData";
import { medicationSchema } from "./MedicationSchema";
import { employmentSchema } from "./EmploymentSchema";
import { groupedOptions as groupedOccupations } from "../../../../models/EmploymentData";
import { Notice } from "../../../common/cards/Notice";

const AllData = Yup.object().shape({
  pensionContributionFrequency: Yup.string()
    .nullable()
    .label("Pension Contribution Frequency")
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          name: "pensionContributionFrequency",
          label: "Pension Contribution Frequency",
          required: false,
          description: "",
          placeholder: "Please Select",
          tooltip: "",
          prefix: undefined,
          values: ["", "Weekly", "Monthly", "Fortnightly", "Yearly"],
        },
      },
    }),

  pensionContributionAmount: Yup.number()
    .nullable()
    .label("Pension Contribution Amount")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Pension Contribution Amount",
          description: "",
          name: "pensionContributionAmount",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "number",
          prefix: <div className="h3">£</div>,
        },
      },
    }),

  title: Yup.string()
    .nullable()

    .label("Title")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Title",
          description: "",
          name: "title",
          tooltip: "",
          items: [
            { label: "Mr", value: "Mr" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "Ms", value: "Ms" },
            { label: "Dr", value: "dr" },
          ],
        },
      },
    }),
  firstName: Yup.string()
    .nullable()

    .label("First Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "First Name",
          description: "",
          name: "firstName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  middleName: Yup.string()
    .nullable()

    .label("Middle Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Middle Name",
          description: "",
          name: "middleName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  lastName: Yup.string()
    .nullable()

    .label("Last Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Last Name",
          description: "",
          name: "lastName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  hasPreviousNamesYesNo: Yup.string()
    .nullable()
    .required()
    .label("Do you have any previous names?")
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: "Do you have any previous names?",
          description: "",
          name: "hasPreviousNamesYesNo",
          tooltip: "",
          required: true,
        },
      },
    }),
  previousNames: Yup.string()
    .nullable()
    .when("hasPreviousNamesYesNo", {
      is: (val: string) => val == "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable(),
    })
    .label("Previous Names")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Previous Names",
          description: "",
          name: "previousNames",
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [
          { fieldName: "hasPreviousNamesYesNo", fieldValue: "true" },
        ],
      },
    }),
  previousNamesDateChanged: Yup.string()
    .nullable()
    .when("hasPreviousNamesYesNo", {
      is: (val: string) => val == "true",
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable(),
    })
    .label("Date changed")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "previousNamesDateChanged",
          label: "Date changed",
          required: true,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear(),
        },
        dependsOnFields: [
          { fieldName: "hasPreviousNamesYesNo", fieldValue: "true" },
        ],
      },
    }),

  sex: Yup.string()
    .nullable()

    .label("Sex")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Sex",
          description: "",
          name: "sex",
          tooltip: "",
          items: [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ],
        },
      },
    }),
  maritalStatus: Yup.string()
    .nullable()

    .label("Marital Status")
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          label: "Marital Status",
          description: "",
          name: "maritalStatus",
          tooltip: "",
          values: [
            "",
            "Single",
            "Married",
            "Divorced",
            "Widowed",
            "Separated",
            "Co-habiting",
            "Civil Partnership",
            "Intend To Marry",
            "Intend To Form Civil Partnership",
            "Dissolved Civil Partnership",
          ],
        },
      },
    }),

  residentialStatus: Yup.string()
    .nullable()

    .label("Residential Status")
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          label: "Residential Status",
          description: "",
          name: "residentialStatus",
          tooltip: "",
          values: [
            "",
            "In a care home",
            "In a residential home",
            "Living with friends",
            "Living with parents",
            "Other",
            "Owner",
            "Owner - living alone",
            "Owner - living with someone",
            "Tenant (private)",
            "Tenant (private) - living alone",
            "Tenant (private) - living with someone",
            "Tenant (public)",
            "Tenant (public) - living alone",
            "Tenant (public) - living with someone",
          ],
        },
      },
    }),

  employmentStatus: Yup.string()
    .nullable()

    .label("Employment Status")
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          label: "Employment Status",
          description: "",
          name: "employmentStatus",
          tooltip: "",
          values: [
            "",
            "Contract",
            "Director",
            "Full Time Employee",
            "House Person",
            "Other",
            "Part Time Employee",
            "Retired",
            "Self Employed",
            "Student",
            "Temporary Employment",
            "Unemployed",
          ],
        },
      },
    }),

  postcode: Yup.string()
    .nullable()
    .matches(
      /^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) [0-9][ABD-HJLNP-UW-Z]{2})$/i,
      "Invalid UK Postcode"
    )
    .label("Postcode")
    .meta({
      control: {
        componentType: "Postcode",
        props: {
          label: "Your Postcode",
          description: "",
          name: "postcode",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          addressLine1FieldName: "address",
          cityFieldName: "city",
          includeFindAddress: true,
        },
      },
    }),

  address: Yup.string()
    .nullable()
    .label("address")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Your Address",
          description: "",
          name: "address",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  city: Yup.string()
    .nullable()

    .label("Your City")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Your City",
          description: "",
          name: "city",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  email: Yup.string()
    .nullable()

    .label("email")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Your email",
          description: "",
          name: "email",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  primaryPhone: Yup.string()
    .nullable()

    .label("Your phone number")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Your phone number",
          description: "",
          name: "primaryPhone",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  dateOfBirth: Yup.string()
    .nullable()
    .label("Date of Birth")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "dateOfBirth",
          label: "Your Date of Birth",
          required: false,
          description: "You must be over 55 to get quotes.",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1930,
          endYear: new Date().getFullYear() - 50,
        },
      },
    }),

  niNumber: Yup.string()
    .nullable()

    .label("NI Number")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "NI Number",
          description: "",
          name: "NINumber",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),
  objectives: Yup.string()
    .nullable()

    .label("Your Objectives")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Your Objectives",
          description: "",
          name: "objectives",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  addSpouseOrPartner: Yup.string()
    .nullable()

    .label("Would you like to add a spouse or partner?")
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: "Would you like to add a spouse or partner?",
          description:
            "You can leave your pension to a spouse or partner upon your death. Select Yes if you would like to do so.",
          name: "addSpouseOrPartner",
          tooltip: "",
        },
      },
    }),

  person2Title: Yup.string()
    .nullable()
    .when("addSpouseOrPartner", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("What is their Title")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Their Title",
          description: "",
          name: "person2Title",
          tooltip: "",
          items: [
            { label: "Mr", value: "Mr" },
            { label: "Mrs", value: "Mrs" },
            { label: "Miss", value: "Miss" },
            { label: "Ms", value: "Ms" },
            { label: "Dr", value: "dr" },
          ],
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartner", fieldValue: "true" },
        ],
      },
    }),
  person2FirstName: Yup.string()
    .nullable()
    .when("addSpouseOrPartner", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their First Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their First Name",
          description: "",
          name: "person2FirstName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartner", fieldValue: "true" },
        ],
      },
    }),
  person2MiddleName: Yup.string()
    .nullable()
    .when("addSpouseOrPartner", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their Middle Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Middle Name",
          description: "",
          name: "person2MiddleName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartner", fieldValue: "true" },
        ],
      },
    }),
  person2LastName: Yup.string()
    .nullable()
    .when("addSpouseOrPartner", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their Last Name")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Last Name",
          description: "",
          name: "Person2LastName",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartner", fieldValue: "true" },
        ],
      },
    }),

  person2HasPreviousNames: Yup.string()
    .nullable()

    .label("Do they have any previous names?")
    .meta({
      control: {
        componentType: "YesNoBoxes",
        props: {
          label: "Do they have any previous names?",
          description: "",
          name: "Person2HasPreviousNames",
          tooltip: "",
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartner", fieldValue: "true" },
        ],
      },
    }),

  person2PreviousNames: Yup.string()
    .nullable()
    .when("Person2HasPreviousNames", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their Previous Names")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Previous Names",
          description: "",
          name: "Person2PreviousNames",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartner", fieldValue: "true" },
          { fieldName: "Person2HasPreviousNames", fieldValue: "true" },
        ],
      },
    }),

  person2PreviousNameDateChanged: Yup.string()
    .nullable()
    .when("hasPreviousNames", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Date changed")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "person2PreviousNameDateChanged",
          label: "Date changed",
          required: false,
          description: "",
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear(),
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartner", fieldValue: "true" },
          { fieldName: "Person2HasPreviousNames", fieldValue: "true" },
        ],
      },
    }),

  person2DateOfBirth: Yup.string()
    .nullable()
    .when("addSpouseOrPartner", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their Date of Birth")
    .meta({
      control: {
        componentType: "DateDDMMYYYY",
        props: {
          name: "person2DateOfBirth",
          label: "Their Date of Birth",
          required: false,
          placeholder: "",
          tooltip: "",
          prefix: undefined,
          startYear: 1920,
          endYear: new Date().getFullYear() - 4,
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartner", fieldValue: "true" },
        ],
      },
    }),
  person2Sex: Yup.string()
    .nullable()
    .when("addSpouseOrPartner", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their Sex")
    .meta({
      control: {
        componentType: "RadioBoxes",
        props: {
          label: "Their Sex",
          description: "",
          name: "person2Sex",
          tooltip: "",
          items: [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ],
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartner", fieldValue: "true" },
        ],
      },
    }),
  person2NINumber: Yup.string()
    .nullable()
    .when("addSpouseOrPartner", {
      is: (val: string) => val === "true",
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().optional(),
    })
    .label("Their NI Number")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their NI Number",
          description: "",
          name: "person2NINumber",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
        dependsOnFields: [
          { fieldName: "addSpouseOrPartner", fieldValue: "true" },
        ],
      },
    }),

  person2MaritalStatus: Yup.string()
    .nullable()

    .label("Their Marital Status")
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          label: "Their Marital Status",
          description: "",
          name: "person2MaritalStatus",
          tooltip: "",
          values: [
            "",
            "Single",
            "Married",
            "Divorced",
            "Widowed",
            "Separated",
            "Co-habiting",
            "Civil Partnership",
            "Intend To Marry",
            "Intend To Form Civil Partnership",
            "Dissolved Civil Partnership",
          ],
        },
      },
    }),

  person2EmploymentStatus: Yup.string()
    .nullable()

    .label("Their Employment Status")
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          label: "Their Employment Status",
          description: "",
          name: "person2EmploymentStatus",
          tooltip: "",
          values: [
            "",
            "Contract",
            "Director",
            "Full Time Employee",
            "House Person",
            "Other",
            "Part Time Employee",
            "Retired",
            "Self Employed",
            "Student",
            "Temporary Employment",
            "Unemployed",
          ],
        },
      },
    }),

  person2ResidentialStatus: Yup.string()
    .nullable()

    .label("Their Residential Status")
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          label: "Their Residential Status",
          description: "",
          name: "person2ResidentialStatus",
          tooltip: "",
          values: [
            "",
            "In a care home",
            "In a residential home",
            "Living with friends",
            "Living with parents",
            "Other",
            "Owner",
            "Owner - living alone",
            "Owner - living with someone",
            "Tenant (private)",
            "Tenant (private) - living alone",
            "Tenant (private) - living with someone",
            "Tenant (public)",
            "Tenant (public) - living alone",
            "Tenant (public) - living with someone",
          ],
        },
      },
    }),

  person2PostCode: Yup.string()
    .nullable()
    .matches(
      /^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) [0-9][ABD-HJLNP-UW-Z]{2})$/i,
      "Invalid UK Postcode"
    )
    .label("Their Postcode")
    .meta({
      control: {
        componentType: "Postcode",
        props: {
          label: "Their Postcode",
          description: "",
          name: "person2Postcode",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
          addressLine1FieldName: "person2Address",
          cityFieldName: "person2City",
          includeFindAddress: true,
        },
      },
    }),

  person2Address: Yup.string()
    .nullable()

    .label("Their Address")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their Address",
          description: "",
          name: "person2Address",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  person2City: Yup.string()
    .nullable()

    .label("Their City")
    .meta({
      control: {
        componentType: "InputBox",
        props: {
          label: "Their City",
          description: "",
          name: "person2City",
          required: false,
          placeholder: "",
          tooltip: "",
          inputType: "text",
          prefix: undefined,
        },
      },
    }),

  person2RelationshipToClient: Yup.string()
    .nullable()

    .label("What is their relationship to you")
    .meta({
      control: {
        componentType: "Lookup",
        props: {
          label: "What is their relationship to you",
          description: "",
          name: "person2RelationshipToClient",
          tooltip: "",
          values: [
            "",
            "Brother",
            "Civil Partner",
            "Cohabitant",
            "Daughter",
            "Dependent",
            "Father",
            "Fiancee",
            "Granddaughter",
            "Grandson",
            "Husband",
            "Mother",
            "Other",
            "Sister",
            "Solicitor",
            "Son",
            "Stepdaughter",
            "Stepson",
            "Wife",
          ],
        },
      },
    }),

  pensions: Yup.array()
    .of(pensionSchema)
    .label("pensions")
    .meta({
      fieldArrayName: "pensions",
      fieldArrayDescription:
        "Please add details for any pensions you have below",
      addSeparatorAbove: false,
      control: [
        {
          componentType: "Lookup",
          props: {
            label: "Pension type (if known)",
            description: "",
            name: "pensionType",
            tooltip: "",
            values: [
              { label: "", value: "0" },
              { label: "Stakeholder Pension", value: "4093" },
              { label: "SIPPS", value: "4094" },
              { label: "Occupational Pension", value: "4095" },
              { label: "Annuity", value: "4098" },
              { label: "SERPS", value: "4102" },
              { label: "AVCs", value: "4103" },
              { label: "FSAVCs", value: "4104" },
              { label: "Personal Pension Plan", value: "100287" },
              { label: "Section 32", value: "100288" },
              { label: "RAC", value: "100289" },
              { label: "Group Personal Pension", value: "100290" },
              { label: "IPP", value: "100291" },
              { label: "S226", value: "100292" },
              { label: "SHP", value: "100293" },
              { label: "GPP", value: "100294" },
              { label: "GSHP", value: "100295" },
              { label: "GSIPP", value: "100296" },
              { label: "ID (USP)", value: "100297" },
              { label: "SSAS", value: "100298" },
              { label: "CIMP", value: "100299" },
              { label: "COMP", value: "100300" },
              { label: "EPP", value: "100301" },
              { label: "GAVC", value: "100302" },
              { label: "DAC/A2L", value: "100303" },
              { label: "Workplace Pension", value: "100304" },
              { label: "Final Salary", value: "100305" },
              { label: "FTA (Maturing)", value: "100694" },
            ],
          },
        },
        {
          componentType: "LookupWithSearchAndGroups",
          props: {
            name: "cedingScheme",
            label: "Who is the pension provider?",
            required: false,
            description: "Search and select a provider",
            placeholder: "Please Select",
            tooltip: "",
            prefix: undefined,
            className: "col-md-9",
            values: groupedPensionProviders,
          },
        },
        {
          componentType: "InputBox",
          props: {
            label: "Valuation",
            description: "How much is in the pension.",
            name: "valuation",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="h3">£</div>,
            addSeparatorBelow: true,
          },
        },
        {
          componentType: "DateDDMMYYYY",
          props: {
            name: "valuationDate",
            label: "Valuation date",
            required: false,
            description: "",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: new Date().getFullYear(),
            endYear: new Date().getFullYear() + 10,
          },
        },
      ],
    }),

  medicalInformation: Yup.object().shape({
    heightFeet: Yup.number()
      .nullable()
      .label("Your Height")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Enter Your Height",
            description: "",
            name: "medicalInformation.heightFeet",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="small">Feet:</div>,
            className: "col-md-6 col-sm-12",
          },
        },
      }),

    heightInches: Yup.number()
      .nullable()
      .label("Your Height")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "",
            description: "",
            name: "medicalInformation.heightInches",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="small">Inches:</div>,
            className: "col-md-6 col-sm-12",
          },
        },
      }),

    weightStone: Yup.number()
      .nullable()
      .label("Your Weight")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Enter Your Weight",
            description: "",
            name: "medicalInformation.weightStone",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="small">Stone:</div>,
            className: "col-md-6 col-sm-12",
            addSeparatorBelow: false,
          },
        },
      }),

    weightPounds: Yup.number()
      .nullable()
      .label("Your Weight")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "",
            description: "",
            name: "medicalInformation.weightPounds",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="small">Pounds:</div>,
            className: "col-md-6 col-sm-12",
            addSeparatorBelow: false,
          },
        },
      }),

    medicalConditionsYesNo: Yup.string()
      .label("Do you, or have you ever had any medical conditions?")
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label: "Do you, or have you ever had any medical conditions?",
            description:
              "Medical conditions can dramatically increase the amount of money you could receive.",
            name: "medicalInformation.medicalConditionsYesNo",
            tooltip: "",
            addSeparatorAbove: true,
          },
        },
      }),

    medicalConditionList: Yup.array()
      .of(medicalConditionSchema)
      .when("medicalInformation.medicalConditionsYesNo", {
        is: (val: string) => val === "true",
        then: Yup.array().required(),
        otherwise: Yup.array().optional(),
      })
      .label("Medical Conditions")
      .meta({
        fieldArrayName: "medicalInformation.medicalConditionList",
        fieldArrayDescription:
          "If you have any medical conditions, these can potentially increase the amount you recieve. Use the below to add them.",
        addSeparatorAbove: false,
        control: [
          {
            componentType: "Lookup",
            props: {
              name: "conditionType",
              label: "Condition Type",
              required: true,
              description: "",
              placeholder: "Please Select",
              tooltip: "",
              prefix: undefined,
              values: [
                { label: "", value: "0" },
                { label: "High Blood Pressure", value: "218" },
                { label: "High Cholesterol", value: "219" },
                { label: "Diabetes", value: "211" },
                { label: "Cancer", value: "212" },
                { label: "Other", value: "208" },
                { label: "Heart", value: "210" },
                { label: "Respiratory", value: "214" },
                { label: "Neurological", value: "216" },
                { label: "Stroke", value: "213" },
                { label: "Multiple Sclerosis", value: "215" },
              ],
            },
          },

          //Respiratory
          {
            componentType: "Lookup",
            props: {
              label: "What sort of respiratory condition is it?",
              description: "",
              name: "respiratoryType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Chronic Obstructive Airways", value: "6020" },
                { label: "Pulmonary Disease", value: "6021" },
                { label: "Emphysema", value: "6022" },
                { label: "Bronchiectasis", value: "6023" },
                { label: "Pneumoconiosis", value: "6024" },
                { label: "Asbestosis", value: "6025" },
                { label: "Asthma", value: "6026" },
                { label: "Pleural Plaques", value: "6027" },
                { label: "Other", value: "6029" },
              ],
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["214"],
              },
            ],
          },

          // Heart
          {
            componentType: "Lookup",
            props: {
              name: "heartConditionType",
              label: "What type of heart condition do you have?",
              required: false,
              description: "",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Heart Attack", value: "5997" },
                { label: "Angina", value: "5998" },
                { label: "Heart Failure", value: "5999" },
                { label: "Aortic Aneurysm", value: "6000" },
                { label: "Cardiomyopathy", value: "6001" },
                { label: "Heart Valve Disorders", value: "6002" },
                { label: "Atrial Fibrillation (AF)", value: "6003" },
                { label: "Other", value: "6004" },
              ],
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["210"],
              },
            ],
          },
          {
            componentType: "LookupWithSearchAndGroups",
            props: {
              name: "conditionName",
              label: "Condition Name",
              required: false,
              description: "Search and select a condition",
              placeholder: "Please Select",
              tooltip: "",
              prefix: undefined,
              values: groupedMedicalConditions,
              groupFilterField: "conditionType",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["210", "208", "212", "214", "216", "213"],
              },
            ],
          },
          //shared
          {
            componentType: "DateMMYYYY",
            props: {
              name: "dateDiagnosed",
              label: "When were you diagnosed?",
              required: true,
              description: "A rough estimate will be fine.",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: new Date().getFullYear() - 100,
              endYear: new Date().getFullYear(),
              addSeparatorBelow: false,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: [
                  "210",
                  "208",
                  "212",
                  "214",
                  "216",
                  "213",
                  "218",
                  "219",
                  "211",
                  "215",
                ],
              },
            ],
          },

          // High Blood Pressure
          {
            componentType: "InputBox",
            props: {
              name: "lastSystolicReading",
              label: "What was your last Systolic Reading?",
              required: true,
              description: "This is the higher reading",
              placeholder: "",
              inputType: "number",
              tooltip: "",
              prefix: null,
              addSeparatorBelow: false,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["218"],
              },
            ],
          },
          {
            componentType: "InputBox",
            props: {
              name: "lastDiastolicReading",
              label: "What was your last Diastolic Reading?",
              required: true,
              description: "This is the lower reading",
              placeholder: "",
              inputType: "number",
              tooltip: "",
              prefix: null,
              addSeparatorBelow: false,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["218"],
              },
            ],
          },
          {
            componentType: "DateMMYYYY",
            props: {
              name: "lastReadingDate",
              label: "When were these readings taken?",
              required: true,
              description: "A rough estimate will be fine.",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: new Date().getFullYear() - 100,
              endYear: new Date().getFullYear(),
              addSeparatorBelow: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["218"],
              },
            ],
          },

          // Other
          {
            componentType: "DateMMYYYY",
            props: {
              name: "lastSufferedSymptoms",
              label: "When did you last suffer symptoms?",
              required: true,
              description: "A rough estimate will be fine.",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: new Date().getFullYear() - 100,
              endYear: new Date().getFullYear(),
              addSeparatorBelow: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["208"],
              },
            ],
          },

          // Diabetus
          {
            componentType: "Lookup",
            props: {
              label: "What type of diabetes do you have?",
              description: "",
              name: "diabetesType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Type 1", value: "5820" },
                { label: "Type 2", value: "5821" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["211"],
              },
            ],
          },
          {
            componentType: "Lookup",
            props: {
              label: "How do you control your diabetes?",
              description: "",
              name: "diabetesControlMethod",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Diet", value: "5822" },
                { label: "Insulin", value: "5823" },
                { label: "Tablet", value: "5824" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["211"],
              },
            ],
          },

          // Cancer
          {
            componentType: "Lookup",
            props: {
              label: "What type of tumour did/do you have?",
              description: "",
              name: "tumourType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Benign", value: "5860" },
                { label: "Pre-Cancerous", value: "5861" },
                { label: "Malignant", value: "5862" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["212"],
              },
            ],
          },
          {
            componentType: "Lookup",
            props: {
              label: "Where did/do you have the tumour?",
              description: "",
              name: "tumourLocation",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Bone", value: "5939" },
                { label: "Brain and Central Nervous System", value: "5940" },
                { label: "Breast", value: "5941" },
                { label: "Circulatory system", value: "5942" },
                { label: "Connective and other soft tissue", value: "5943" },
                { label: "Digestive System", value: "5944" },
                { label: "Liver", value: "5945" },
                { label: "Lymph Nodes", value: "5946" },
                { label: "Oral", value: "5947" },
                { label: "Reproductive system", value: "5948" },
                { label: "Respiratory system", value: "5949" },
                { label: "Skin", value: "5950" },
                { label: "Urinary system", value: "5951" },
                { label: "Other", value: "5952" },
                { label: "Not specified", value: "5953" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["212"],
              },
            ],
          },
          {
            componentType: "Lookup",
            props: {
              label: "What grade of cancer did/do you have?",
              description: "",
              name: "generalGrade",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Grade 1 (Low Grade)", value: "5954" },
                { label: "Grade 2 (Intermediate Grade)", value: "5955" },
                { label: "Grade 3 (High Grade)", value: "5956" },
                { label: "Unknown", value: "5966" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["212"],
              },
            ],
          },
          // classification, multiple classifcation types AnnArborClassification MAC FIGO Dukes
          {
            componentType: "Lookup",
            props: {
              label: "What grade of cancer did/do you have?",
              description: "",
              name: "classificationType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Grade 1 (Low Grade)", value: "5954" },
                { label: "Grade 2 (Intermediate Grade)", value: "5955" },
                { label: "Grade 3 (High Grade)", value: "5956" },
                { label: "Unknown", value: "5966" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["212"],
              },
            ],
          },

          // Stroke
          {
            componentType: "Lookup",
            props: {
              label: "What type of stroke did you have?",
              description: "",
              name: "strokeType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                {
                  label: "CVA (Cerebrovascular Accident - Major Stroke)",
                  value: "6043",
                },
                { label: "Subarachnoid Haemorrhage (SAH)", value: "6044" },
                { label: "Cerebral Haemorrhage/Bleed", value: "6045" },
                {
                  label: "TIA (Transient Ischaemic Attack - Mini-Stroke)",
                  value: "6046",
                },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["213"],
              },
            ],
          },
          {
            componentType: "Lookup",
            props: {
              label: "Which area has been the most affected by the stroke?",
              description: "",
              name: "partOfBodyAffected",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Speech", value: "6038" },
                { label: "Vision", value: "6039" },
                { label: "Arm", value: "6040" },
                { label: "Leg", value: "6041" },
                { label: "Memory Loss", value: "6042" },
              ],
              className: "col-md-6 col-sm-12",
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["213"],
              },
            ],
          },

          // Neurological
          {
            componentType: "Lookup",
            props: {
              label: "What is the neurological condition type?",
              description: "",
              name: "neurologicalType",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { label: "Alzheimer's Disease", value: "6016" },
                { label: "Parkinson's Disease", value: "6017" },
                { label: "Other", value: "6019" },
                { label: "Senile Dementia", value: "100995" },
                { label: "Vascular Dementia", value: "100996" },
                { label: "Motor Neurone Disease", value: "100997" },
              ],
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "YesNoBoxes",
            props: {
              label: "Do you know your MMSE score?",
              description: "",
              name: "mmseScoreKnown",
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "InputBox",
            props: {
              name: "mmseScore",
              label: "What is your MMSE score?",
              required: true,
              description: "It will be a number no higher than 30",
              placeholder: "",
              inputType: "number",
              tooltip: "",
              prefix: null,
              addSeparatorBelow: false,
            },
            dependsOnFields: [
              {
                fieldName: "mmseScoreKnown",
                fieldValue: "true",
              },
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "YesNoBoxes",
            props: {
              label: "Have you had any falls?",
              description: "",
              name: "falls",
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: "mmseScoreKnown",
                fieldValue: "true",
              },
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "YesNoBoxes",
            props: {
              label: "Have you had any pressure sores?",
              description: "",
              name: "pressureSores",
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "YesNoBoxes",
            props: {
              label: "Have you had any seizures?",
              description: "",
              name: "seizures",
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
          {
            componentType: "YesNoBoxes",
            props: {
              label: "Have you had any tremors?",
              description: "",
              name: "tremors",
              tooltip: "",
              addSeparatorAbove: true,
            },
            dependsOnFields: [
              {
                fieldName: "conditionType",
                fieldValue: ["216"],
              },
            ],
          },
        ],

        dependsOnFields: [
          {
            fieldName: "medicalInformation.medicalConditionsYesNo",
            fieldValue: "true",
          },
        ],
      }),

    medicationsYesNo: Yup.string()
      .label("Do you take any medications?")
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label: "Do you take any medications?",
            description:
              "Medications you take can dramatically increase the amount of money you could receive.",
            name: "medicalInformation.medicationsYesNo",
            tooltip: "",
            addSeparatorAbove: true,
          },
        },
      }),

    medicationList: Yup.array()
      .of(Yup.object().shape({ medicationSchema }))
      .label("Medications")
      .meta({
        fieldArrayName: "medicalInformation.medicationList",
        fieldArrayDescription:
          "If you take any medications, these can potentially increase the amount you recieve. Use the below to add them",
        addSeparatorAbove: false,
        control: [
          {
            componentType: "LookupWithSearchAndGroups",
            props: {
              name: "medicationName",
              label: "Medication Name",
              required: false,
              description: "Search and select a medication",
              placeholder: "Please Select",
              tooltip: "",
              prefix: undefined,
              className: "",
              values: groupedMedications,
            },
          },
          {
            componentType: "Lookup",
            props: {
              name: "forConditionType",
              label: "What sort of condition is this medication for?",
              required: true,
              description: "",
              placeholder: "Please Select",
              tooltip: "",
              prefix: undefined,
              values: [
                { label: "", value: "0" },
                { label: "Cancer/Tumour", value: "4161" },
                { label: "Other", value: "4163" },
                { label: "Heart", value: "4165" },
                { label: "Diabetes", value: "4167" },
                { label: "Respiratory", value: "4169" },
                { label: "Neurological", value: "4170" },
                { label: "Stroke", value: "4172" },
                { label: "High Blood Pressure", value: "5873" },
                { label: "High Cholesterol", value: "5874" },
                { label: "Multiple Sclerosis", value: "5983" },
              ],
            },
          },
          {
            componentType: "InputBox",
            props: {
              label: "What dosage do you take?",
              description: "",
              name: "dosage",
              required: false,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: null,
              className: "col-md-6 col-sm-12",
              addSeparatorBelow: false,
            },
          },
          {
            componentType: "Lookup",
            props: {
              label: "Unit",
              description: "",
              name: "dosageUnit",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { value: "5992", label: "Gram" },
                { value: "5993", label: "Milligram" },
                { value: "5994", label: "Microgram" },
                { value: "5995", label: "Millilitre" },
                { value: "5996", label: "Unit Of Insulin" },
              ],
              className: "col-md-6 col-sm-12",
            },
          },
          {
            componentType: "InputBox",
            props: {
              label: "How many do you take?",
              description: "",
              name: "numberTaken",
              required: false,
              placeholder: "",
              tooltip: "",
              inputType: "number",
              prefix: null,
              className: "col-md-6 col-sm-12",
              addSeparatorBelow: false,
            },
          },
          {
            componentType: "Lookup",
            props: {
              label: "How often do you take them?",
              description: "",
              name: "frequency",
              tooltip: "",
              values: [
                { label: "", value: "0" },
                { value: "5833", label: "As Required" },
                { value: "5984", label: "Day" },
                { value: "5985", label: "Week" },
                { value: "5986", label: "Fortnight" },
                { value: "5987", label: "Four Weeks" },
                { value: "5988", label: "Month" },
                { value: "5989", label: "Quarter" },
                { value: "5990", label: "Half Year" },
                { value: "5991", label: "Year" },
              ],
              className: "col-md-6 col-sm-12",
            },
          },
          {
            componentType: "DateDDMMYYYY",
            props: {
              name: "startDate",
              label: "When did you start taking the medication?",
              required: false,
              description: "",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: 1920,
              endYear: new Date().getFullYear(),
            },
          },
          {
            componentType: "DateDDMMYYYY",
            props: {
              name: "endDate",
              label: "When did you stop taking the medication?",
              required: false,
              description: "",
              placeholder: "",
              tooltip: "",
              prefix: undefined,
              startYear: 1920,
              endYear: new Date().getFullYear(),
            },
          },
        ],
        dependsOnFields: [
          {
            fieldName: "medicalInformation.medicationsYesNo",
            fieldValue: "true",
          },
        ],
      }),

    alcoholUnitsPerWeek: Yup.string()
      .label("Do you drink alcohol?")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "How many units of alcohol do you drink per week?",
            description:
              "1 pint of beer is 3 units, a single shot of spirit is 1 unit and a glass of wine is 2 units.",
            name: "medicalInformation.alcoholUnitsPerWeek",
            inputType: "number",
            addSeparatorAbove: true,
          },
        },
      }),

    previousOrCurrentSmoker: Yup.string()
      .label("Have you ever been a smoker?")
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label: "Have you ever been a smoker?",
            description:
              "If you are a smoker or have been previously, this can increase the amount you receive.",
            name: "medicalInformation.previousOrCurrentSmoker",
            tooltip: "",
            addSeparatorAbove: true,
          },
        },
      }),

    previousSmoker: Yup.string()
      .label("Have you now stopped smoking?")
      .meta({
        control: {
          componentType: "YesNoBoxes",
          props: {
            label: "Have you now stopped smoking?",
            description: "",
            name: "medicalInformation.previousSmoker",
            tooltip: "",
            addSeparatorAbove: false,
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmoker",
              fieldValue: "true",
            },
          ],
        },
      }),

    yearMonthStartedSmoking: Yup.string()
      .nullable()
      .label("When did you start smoking?")
      .meta({
        control: {
          componentType: "DateMMYYYY",
          props: {
            name: "medicalInformation.yearMonthStartedStoppedSmoking",
            label: "When did you start smoking?",
            required: false,
            description: "A rough estimate will be fine.",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: new Date().getFullYear() - 100,
            endYear: new Date().getFullYear(),
            addSeparatorBelow: false,
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmoker",
              fieldValue: "true",
            },
          ],
        },
      }),

    yearMonthStoppedSmoking: Yup.string()
      .nullable()
      .label("When did you stop smoking?")
      .meta({
        control: {
          componentType: "DateMMYYYY",
          props: {
            name: "medicalInformation.yearMonthStoppedSmoking",
            label: "When did you stop smoking?",
            required: false,
            description: "A rough estimate will be fine.",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: new Date().getFullYear() - 100,
            endYear: new Date().getFullYear(),
            addSeparatorBelow: false,
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmoker",
              fieldValue: "true",
            },
            {
              fieldName: "medicalInformation.previousSmoker",
              fieldValue: "true",
            },
          ],
        },
      }),

    cigarettesPerDay: Yup.string()
      .label("Cigarettes Per Day")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Cigarettes Per Day",
            description: "",
            name: "medicalInformation.cigarettesPerDay",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: undefined,
            className: "col-md-6 col-sm-12",
            addSeparatorBelow: false,
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmoker",
              fieldValue: "true",
            },
          ],
        },
      }),

    cigarsPerDay: Yup.string()
      .label("Cigars Per Day")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Cigars Per Day",
            description: "",
            name: "medicalInformation.cigarsPerDay",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: undefined,
            className: "col-md-6 col-sm-12",
            addSeparatorBelow: false,
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmoker",
              fieldValue: "true",
            },
          ],
        },
      }),

    gramsOfTobaccoPerDay: Yup.string()
      .label("Grams Of Tobacco Per Day")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Grams Of Tobacco Per Day",
            description: "",
            name: "medicalInformation.gramsOfTobaccoPerDay",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: undefined,
            className: "col-md-6 col-sm-12",
            addSeparatorBelow: false,
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmoker",
              fieldValue: "true",
            },
          ],
        },
      }),

    gramsOfPipeTobaccoPerDay: Yup.string()
      .label("Grams Of Tobacco Per Day")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Grams Of Pipe Tobacco Per Day",
            description: "",
            name: "medicalInformation.gramsOfPipeTobaccoPerDay",
            required: false,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: undefined,
            className: "col-md-6 col-sm-12",
            addSeparatorBelow: false,
          },
          dependsOnFields: [
            {
              fieldName: "medicalInformation.previousOrCurrentSmoker",
              fieldValue: "true",
            },
          ],
        },
      }),
  }),

  annuity: Yup.object().shape({
    pensionFund: Yup.number()
      .required()
      .label("Pension Fund")
      .meta({
        control: {
          componentType: "InputBox",
          props: {
            label: "Pension Fund",
            description:
              "If you have multiple pensions, please combine them and enter that amount",
            name: "annuity.pensionFund",
            required: true,
            placeholder: "",
            tooltip: "",
            inputType: "number",
            prefix: <div className="h3">£</div>,
            addSeparatorBelow: true,
          },
        },
      }),
    plannedRetirementDate: Yup.string()
      .required()
      .label("Planned Retirement Date")
      .meta({
        control: {
          componentType: "DateDDMMYYYY",
          props: {
            name: "annuity.plannedRetirementDate",
            label: "Planned retirement date",
            required: true,
            description: "",
            placeholder: "",
            tooltip: {
              button: (
                <a
                  className="ms-10 btn btn-small btn-outline form-text"
                  data-bs-toggle="collapse"
                  data-bs-target="#plannedRetirementDate"
                  aria-expanded="false"
                  aria-controls="plannedRetirementDate"
                >
                  Why?
                </a>
              ),
              notice: (
                <div className="collapse pb-5 pt-3" id="plannedRetirementDate">
                  <Notice
                    noticeClasses={"bg-light-warning"}
                    icon={undefined}
                    iconClasses={null}
                    header={undefined}
                    content={
                      "Letting us know when you plan on retiring can help us tailor your journey to what you need"
                    }
                    button={null}
                  />
                </div>
              ),
            },
            prefix: undefined,
            startYear: new Date().getFullYear(),
            endYear: new Date().getFullYear() + 10,
          },
        },
      }),
  }),
  propertyDetails: Yup.array()
    .of(propertySchema)
    .label("Properties")
    .meta({
      fieldArrayName: "properties",
      fieldArrayDescription:
        "Please add your property details. Use the below form to add them.",
      addSeparatorAbove: false,
      control: [
        {
          componentType: "InputBox",
          props: {
            name: "address",
            label: "What is your address?",
            required: true,
            description: "Please enter your house name/number and street",
            placeholder: "",
            inputType: "string",
            tooltip: "",
            prefix: null,
            addSeparatorBelow: false,
          },
        },
        {
          componentType: "InputBox",
          props: {
            name: "city",
            label: "Which town/city do you live in?",
            required: true,
            description: "Please enter the city",
            placeholder: "",
            inputType: "string",
            tooltip: "",
            prefix: null,
            addSeparatorBelow: false,
          },
        },
        {
          componentType: "InputBox",
          props: {
            name: "postcode",
            label: "What is your postcode",
            required: true,
            description: "Please enter the postcode",
            placeholder: "",
            inputType: "string",
            tooltip: "",
            prefix: null,
            addSeparatorBelow: false,
          },
        },
        {
          componentType: "InputBox",
          props: {
            name: "estimatedValue",
            label: "What is the estimated value of your property?",
            required: true,
            description: "Please enter the estimated value of your property",
            placeholder: "",
            inputType: "string",
            tooltip: "",
            prefix: null,
            addSeparatorBelow: false,
          },
        },
      ],
    }),

  employments: Yup.array()
    .of(employmentSchema)
    .label("")
    .meta({
      fieldArrayName: "employments",
      fieldArrayDescription: "",
      addSeparatorAbove: false,
      control: [
        {
          componentType: "Lookup",
          props: {
            label: "Employment Status",
            description: "",
            name: "employmentStatus",
            tooltip: "",
            values: [
              { label: "", value: "0" },
              { label: "Full Time Employee", value: "1742" },
              { label: "Part Time Employee", value: "1743" },
              { label: "Self Employed", value: "1744" },
              { label: "Contract", value: "1745" },
              { label: "Retired", value: "1746" },
              { label: "Student", value: "1747" },
              { label: "Unemployed", value: "1748" },
              { label: "House Person", value: "1749" },
              { label: "Temporary Employment", value: "1750" },
              { label: "Director", value: "1751" },
              { label: "Other", value: "1752" },
            ],
          },
        },
        {
          componentType: "LookupWithSearchAndGroups",
          props: {
            name: "occupation",
            label: "Select your occupation",
            required: false,
            description: "Select your occupation",
            placeholder: "Please Select",
            tooltip: "",
            prefix: undefined,
            className: "",
            values: groupedOccupations,
          },
        },
        {
          componentType: "Lookup",
          props: {
            name: "employmentType",
            label: "What type of employment was this?",
            required: false,
            description: "",
            placeholder: "Please Select",
            tooltip: "",
            prefix: undefined,
            className: "",
            values: [
              { label: "", value: "0" },
              { label: "Current primary employment", value: "3663" },
              { label: "Current Secondary employment", value: "3664" },
              { label: "Previous employment", value: "3665" },
            ],
          },
        },
        {
          componentType: "DateMMYYYY",
          props: {
            name: "dateStarted",
            label: "Date Started",
            required: true,
            description: "",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: 1920,
            endYear: new Date().getFullYear(),
          },
        },
        {
          componentType: "DateMMYYYY",
          props: {
            name: "dateEnded",
            label: "Date ended",
            description: "",
            placeholder: "",
            tooltip: "",
            prefix: undefined,
            startYear: 1920,
            endYear: new Date().getFullYear(),
          },
        },
      ],
    }),

  // // person2MedicalInformation: Yup.object().shape({
  // //   heightFeet: Yup.number()
  // //     .nullable()
  // //     .label("Your Height")
  // //     .meta({
  // //       control: {
  // //         componentType: "InputBox",
  // //         props: {
  // //           label: "Enter Your Height",
  // //           description: "",
  // //           name: "person2MedicalInformation.heightFeet",
  // //           required: false,
  // //           placeholder: "",
  // //           tooltip: "",
  // //           inputType: "number",
  // //           prefix: <div className="small">Feet:</div>,
  // //           className: "col-md-6 col-sm-12",
  // //         },
  // //       },
  // //     }),

  // //   heightInches: Yup.number()
  // //     .nullable()
  // //     .label("Your Height")
  // //     .meta({
  // //       control: {
  // //         componentType: "InputBox",
  // //         props: {
  // //           label: "",
  // //           description: "",
  // //           name: "person2MedicalInformation.heightInches",
  // //           required: false,
  // //           placeholder: "",
  // //           tooltip: "",
  // //           inputType: "number",
  // //           prefix: <div className="small">Inches:</div>,
  // //           className: "col-md-6 col-sm-12",
  // //         },
  // //       },
  // //     }),

  // //   weightStone: Yup.number()
  // //     .nullable()
  // //     .label("Your Weight")
  // //     .meta({
  // //       control: {
  // //         componentType: "InputBox",
  // //         props: {
  // //           label: "Enter Your Weight",
  // //           description: "",
  // //           name: "person2MedicalInformation.weightStone",
  // //           required: false,
  // //           placeholder: "",
  // //           tooltip: "",
  // //           inputType: "number",
  // //           prefix: <div className="small">Stone:</div>,
  // //           className: "col-md-6 col-sm-12",
  // //           addSeparatorBelow: false,
  // //         },
  // //       },
  // //     }),

  // //   weightPounds: Yup.number()
  // //     .nullable()
  // //     .label("Your Weight")
  // //     .meta({
  // //       control: {
  // //         componentType: "InputBox",
  // //         props: {
  // //           label: "",
  // //           description: "",
  // //           name: "person2MedicalInformation.weightPounds",
  // //           required: false,
  // //           placeholder: "",
  // //           tooltip: "",
  // //           inputType: "number",
  // //           prefix: <div className="small">Pounds:</div>,
  // //           className: "col-md-6 col-sm-12",
  // //           addSeparatorBelow: false,
  // //         },
  // //       },
  // //     }),

  // //   medicalConditionsYesNo: Yup.string()
  // //     .label("Do you, or have you ever had any medical conditions?")
  // //     .meta({
  // //       control: {
  // //         componentType: "YesNoBoxes",
  // //         props: {
  // //           label: "Do you, or have you ever had any medical conditions?",
  // //           description:
  // //             "Medical conditions can dramatically increase the amount of money you could receive.",
  // //           name: "person2MedicalInformation.medicalConditionsYesNo",
  // //           tooltip: "",
  // //           addSeparatorAbove: true,
  // //         },
  // //       },
  // //     }),

  // //   medicalConditionList: Yup.array()
  // //     .of(medicalConditionSchema)
  // //     .label("Medical Conditions")
  // //     .meta({
  // //       fieldArrayName: "person2MedicalInformation.medicalConditionList",
  // //       fieldArrayDescription:
  // //         "If you have any medical conditions, these can potentially increase the amount you recieve. Use the below to add them.",
  // //       addSeparatorAbove: false,
  // //       control: [
  // //         {
  // //           componentType: "Lookup",
  // //           props: {
  // //             name: "conditionType",
  // //             label: "Condition Type",
  // //             required: false,
  // //             description: "",
  // //             placeholder: "Please Select",
  // //             tooltip: "",
  // //             prefix: undefined,
  // //             values: [
  // //               "",
  // //               "Heart",
  // //               "Respiratory",
  // //               "Cancer",
  // //               "Multiple Sclerosis",
  // //               "Stroke",
  // //               "Neurological",
  // //               "Other",
  // //             ],
  // //           },
  // //         },
  // //         {
  // //           componentType: "LookupWithSearchAndGroups",
  // //           props: {
  // //             name: "conditionName",
  // //             label: "Condition Name",
  // //             required: false,
  // //             description: "Search and select a condition",
  // //             placeholder: "Please Select",
  // //             tooltip: "",
  // //             prefix: undefined,
  // //             className: "col-md-9",
  // //             values: groupedMedicalConditions,
  // //             groupFilterField: "conditionType",
  // //           },
  // //           dependsOnFields: [
  // //             {
  // //               fieldName: "conditionType",
  // //               fieldValue: [
  // //                 "Heart",
  // //                 "Respiratory",
  // //                 "Cancer",
  // //                 "Stroke",
  // //                 "Neurological",
  // //                 "Other",
  // //               ],
  // //             },
  // //           ],
  // //         },
  // //       ],
  // //       dependsOnFields: [
  // //         {
  // //           fieldName: "person2MedicalInformation.medicalConditionsYesNo",
  // //           fieldValue: "true",
  // //         },
  // //       ],
  // //     }),

  // //   medicationsYesNo: Yup.string()
  // //     .label("Do you take any medications?")
  // //     .meta({
  // //       control: {
  // //         componentType: "YesNoBoxes",
  // //         props: {
  // //           label: "Do you take any medications?",
  // //           description:
  // //             "Medications you take can dramatically increase the amount of money you could receive.",
  // //           name: "person2MedicalInformation.medicationsYesNo",
  // //           tooltip: "",
  // //           addSeparatorAbove: true,
  // //         },
  // //       },
  // //     }),

  // //   medicationList: Yup.array()
  // //     .of(
  // //       Yup.object().shape({
  // //         medicationName: Yup.string().label("Medication Name"),
  // //         numberTaken: Yup.string().label("Number Taken"),
  // //         Frequency: Yup.string().label("Frequency Taken"),
  // //         dosage: Yup.string().label("Dosage"),
  // //         dosageUnit: Yup.string().label("Dosage Unit"),
  // //       })
  // //     )
  // //     .label("Medications")
  // //     .meta({
  // //       fieldArrayName: "person2MedicalInformation.medicationList",
  // //       fieldArrayDescription:
  // //         "If you take any medications, these can potentially increase the amount you recieve. Use the below to add them",
  // //       addSeparatorAbove: false,
  // //       control: [
  // //         {
  // //           componentType: "LookupWithSearchAndGroups",
  // //           props: {
  // //             name: "medicationName",
  // //             label: "Medication Name",
  // //             required: false,
  // //             description: "Search and select a medication",
  // //             placeholder: "Please Select",
  // //             tooltip: "",
  // //             prefix: undefined,
  // //             className: "",
  // //             values: groupedMedications,
  // //           },
  // //         },
  // //         {
  // //           componentType: "InputBox",
  // //           props: {
  // //             label: "What dosage do you take?",
  // //             description: "",
  // //             name: "person2MedicalInformation.dosage",
  // //             required: false,
  // //             placeholder: "",
  // //             tooltip: "",
  // //             inputType: "number",
  // //             prefix: null,
  // //             className: "col-md-6 col-sm-12",
  // //             addSeparatorBelow: false,
  // //           },
  // //         },
  // //         {
  // //           componentType: "Lookup",
  // //           props: {
  // //             label: "Unit",
  // //             description: "",
  // //             name: "person2MedicalInformation.dosageUnit",
  // //             tooltip: "",
  // //             values: [
  // //               "Gram",
  // //               "Microgram",
  // //               "Milligram",
  // //               "Millilitre",
  // //               "Unit of Insulin",
  // //             ],
  // //             className: "col-md-6 col-sm-12",
  // //           },
  // //         },
  // //         {
  // //           componentType: "InputBox",
  // //           props: {
  // //             label: "How many do you take?",
  // //             description: "",
  // //             name: "person2MedicalInformation.numberTaken",
  // //             required: false,
  // //             placeholder: "",
  // //             tooltip: "",
  // //             inputType: "number",
  // //             prefix: null,
  // //             className: "col-md-6 col-sm-12",
  // //             addSeparatorBelow: false,
  // //           },
  // //         },
  // //         {
  // //           componentType: "Lookup",
  // //           props: {
  // //             label: "How often do you take them?",
  // //             description: "",
  // //             name: "person2MedicalInformation.FrequencyTaken",
  // //             tooltip: "",
  // //             values: [
  // //               "As Required",
  // //               "Day",
  // //               "Fortnight",
  // //               "Four Weeks",
  // //               "Half Year",
  // //               "Month",
  // //               "Quarter",
  // //               "Week",
  // //               "Year",
  // //             ],
  // //             className: "col-md-6 col-sm-12",
  // //           },
  // //         },
  // //       ],
  // //       dependsOnFields: [
  // //         {
  // //           fieldName: "person2MedicalInformation.medicationsYesNo",
  // //           fieldValue: "true",
  // //         },
  // //       ],
  // //     }),

  // //   alcoholUnitsPerWeek: Yup.string()
  // //     .label("Do you drink alcohol?")
  // //     .meta({
  // //       control: {
  // //         componentType: "InputBox",
  // //         props: {
  // //           label: "How many units of alcohol do you drink per week?",
  // //           description:
  // //             "1 pint of beer is 3 units, a single shot of spirit is 1 unit and a glass of wine is 2 units.",
  // //           name: "person2MedicalInformation.alcoholUnitsPerWeek",
  // //           tooltip: "",
  // //           inputType: "number",
  // //           addSeparatorAbove: true,
  // //         },
  // //       },
  // //     }),

  // //   previousOrCurrentSmoker: Yup.string()
  // //     .label("Have you ever been a smoker?")
  // //     .meta({
  // //       control: {
  // //         componentType: "YesNoBoxes",
  // //         props: {
  // //           label: "Have you ever been a smoker?",
  // //           description:
  // //             "If you are a smoker or have been previously, this can increase the amount you receive.",
  // //           name: "person2MedicalInformation.previousOrCurrentSmoker",
  // //           tooltip: "",
  // //           addSeparatorAbove: true,
  // //         },
  // //       },
  // //     }),

  // //   previousSmoker: Yup.string()
  // //     .label("Have you now stopped smoking?")
  // //     .meta({
  // //       control: {
  // //         componentType: "YesNoBoxes",
  // //         props: {
  // //           label: "Have you now stopped smoking?",
  // //           description: "",
  // //           name: "person2MedicalInformation.previousSmoker",
  // //           tooltip: "",
  // //           addSeparatorAbove: false,
  // //         },
  // //         dependsOnFields: [
  // //           {
  // //             fieldName: "person2MedicalInformation.previousOrCurrentSmoker",
  // //             fieldValue: "true",
  // //           },
  // //         ],
  // //       },
  // //     }),

  // //   yearMonthStartedSmoking: Yup.string()
  // //     .label("When did you start smoking?")
  // //     .meta({
  // //       control: {
  // //         componentType: "DateMMYYYY",
  // //         props: {
  // //           name: "person2MedicalInformation.yearMonthStartedStoppedSmoking",
  // //           label: "When did you start smoking?",
  // //           required: false,
  // //           description: "A rough estimate will be fine.",
  // //           placeholder: "",
  // //           tooltip: "",
  // //           prefix: undefined,
  // //           startYear: new Date().getFullYear() - 100,
  // //           endYear: new Date().getFullYear(),
  // //           addSeparatorBelow: false,
  // //         },
  // //         dependsOnFields: [
  // //           {
  // //             fieldName: "person2MedicalInformation.previousOrCurrentSmoker",
  // //             fieldValue: "true",
  // //           },
  // //         ],
  // //       },
  // //     }),

  // //   yearMonthStoppedSmoking: Yup.string()
  // //     .label("When did you stop smoking?")
  // //     .meta({
  // //       control: {
  // //         componentType: "DateMMYYYY",
  // //         props: {
  // //           name: "person2MedicalInformation.yearMonthStoppedSmoking",
  // //           label: "When did you stop smoking?",
  // //           required: false,
  // //           description: "A rough estimate will be fine.",
  // //           placeholder: "",
  // //           tooltip: "",
  // //           prefix: undefined,
  // //           startYear: new Date().getFullYear() - 100,
  // //           endYear: new Date().getFullYear(),
  // //           addSeparatorBelow: false,
  // //         },
  // //         dependsOnFields: [
  // //           {
  // //             fieldName: "person2MedicalInformation.previousOrCurrentSmoker",
  // //             fieldValue: "true",
  // //           },
  // //           {
  // //             fieldName: "person2MedicalInformation.previousSmoker",
  // //             fieldValue: "true",
  // //           },
  // //         ],
  // //       },
  // //     }),

  // //   cigarettesPerDay: Yup.string()
  // //     .label("Cigarettes Per Day")
  // //     .meta({
  // //       control: {
  // //         componentType: "InputBox",
  // //         props: {
  // //           label: "Cigarettes Per Day",
  // //           description: "",
  // //           name: "person2MedicalInformation.cigarettesPerDay",
  // //           required: false,
  // //           placeholder: "",
  // //           tooltip: "",
  // //           inputType: "number",
  // //           prefix: undefined,
  // //           className: "col-md-6 col-sm-12",
  // //           addSeparatorBelow: false,
  // //         },
  // //         dependsOnFields: [
  // //           {
  // //             fieldName: "person2MedicalInformation.previousOrCurrentSmoker",
  // //             fieldValue: "true",
  // //           },
  // //         ],
  // //       },
  // //     }),

  // //   cigarsPerDay: Yup.string()
  // //     .label("Cigars Per Day")
  // //     .meta({
  // //       control: {
  // //         componentType: "InputBox",
  // //         props: {
  // //           label: "Cigars Per Day",
  // //           description: "",
  // //           name: "person2MedicalInformation.cigarsPerDay",
  // //           required: false,
  // //           placeholder: "",
  // //           tooltip: "",
  // //           inputType: "number",
  // //           prefix: undefined,
  // //           className: "col-md-6 col-sm-12",
  // //           addSeparatorBelow: false,
  // //         },
  // //         dependsOnFields: [
  // //           {
  // //             fieldName: "person2MedicalInformation.previousOrCurrentSmoker",
  // //             fieldValue: "true",
  // //           },
  // //         ],
  // //       },
  // //     }),

  // //   gramsOfTobaccoPerDay: Yup.string()
  // //     .label("Grams Of Tobacco Per Day")
  // //     .meta({
  // //       control: {
  // //         componentType: "InputBox",
  // //         props: {
  // //           label: "Grams Of Tobacco Per Day",
  // //           description: "",
  // //           name: "person2MedicalInformation.gramsOfTobaccoPerDay",
  // //           required: false,
  // //           placeholder: "",
  // //           tooltip: "",
  // //           inputType: "number",
  // //           prefix: undefined,
  // //           className: "col-md-6 col-sm-12",
  // //           addSeparatorBelow: false,
  // //         },
  // //         dependsOnFields: [
  // //           {
  // //             fieldName: "person2MedicalInformation.previousOrCurrentSmoker",
  // //             fieldValue: "true",
  // //           },
  // //         ],
  // //       },
  // //     }),

  // //   gramsOfPipeTobaccoPerDay: Yup.string()
  // //     .label("Grams Of Tobacco Per Day")
  // //     .meta({
  // //       control: {
  // //         componentType: "InputBox",
  // //         props: {
  // //           label: "Grams Of Pipe Tobacco Per Day",
  // //           description: "",
  // //           name: "person2MedicalInformation.gramsOfPipeTobaccoPerDay",
  // //           required: false,
  // //           placeholder: "",
  // //           tooltip: "",
  // //           inputType: "number",
  // //           prefix: undefined,
  // //           className: "col-md-6 col-sm-12",
  // //           addSeparatorBelow: false,
  // //         },
  // //         dependsOnFields: [
  // //           {
  // //             fieldName: "person2MedicalInformation.previousOrCurrentSmoker",
  // //             fieldValue: "true",
  // //           },
  // //         ],
  // //       },
  // //     }),
  // // }),
});

export { AllData };
