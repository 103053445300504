import { FieldArray, useField } from "formik";
import React from "react";
import { ObjectShape } from "yup/lib/object";
import { CheckboxBoxes } from "./form_fields/CheckboxBoxes";
import { DateDDMMYYYY } from "./form_fields/DateDDMMYYYY";
import { DateMMYYYY } from "./form_fields/DateMMYYYY";
import { FieldArrayComponent } from "./form_fields/FieldArrayComponent";
import { InputBox } from "./form_fields/InputBox";
import { Postcode } from "./form_fields/Postcode";
import { Lookup } from "./form_fields/Lookup";
import { LookupWithSearchAndGroups } from "./form_fields/LookupWithSearchAndGroups";
import { RadioBoxes } from "./form_fields/RadioBoxes";
import { YesNoBoxes } from "./form_fields/YesNoBoxes";
import WithVisibility from "./WithVisibility";

interface YupSchema<T extends ObjectShape = any> {
  fields: {
    [key: string]: T;
  };
}

function GetFormFieldsWithHeaderAndFields(
  schema: YupSchema,
  header: string,
  description: string
) {
  const formFields = GetFormFields(schema);
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-15">
        <h2 className="fw-bolder d-flex align-items-center text-dark">
          {header}
        </h2>

        <div className="text-gray-400 fw-bold fs-6">{description}</div>
      </div>
      <div className="fv-row">
        <div className="row align-items-end">{formFields}</div>
      </div>
    </div>
  );
}

function ReturnElement(control: any) {
  const label = control.props.label && control.props.label;
  const description = control.props.description && control.props.description;
  const name = control.props.name && control.props.name;
  const initialValue = control.props.initialValue && control.props.initialValue;
  const required = control.props.required && control.props.required;
  const placeholder = control.props.placeholder && control.props.placeholder;
  const tooltip = control.props.tooltip && control.props.tooltip;
  const inputType = control.props.inputType && control.props.inputType;
  const prefix = control.props.prefix && control.props.prefix;
  const items = control.props.items && control.props.items;
  const startYear = control.props.startYear && control.props.startYear;
  const endYear = control.props.endYear && control.props.endYear;
  const values = control.props.values && control.props.values;
  const className = control.props.className && control.props.className;
  const addSeparatorAbove =
    control.props.addSeparatorAbove && control.props.addSeparatorAbove;
  const addSeparatorBelow =
    control.props.addSeparatorBelow && control.props.addSeparatorBelow;
  const dependsOnFields = control.dependsOnFields && control.dependsOnFields;
  const groupFilterField =
    control.props.groupFilterField && control.props.groupFilterField;
  const addressLine1FieldName =
    control.props.addressLine1FieldName && control.props.addressLine1FieldName;
  const CityFieldName =
    control.props.cityFieldName && control.props.cityFieldName;
  const IncludeFindAddress =
    control.props.includeFindAddress && control.props.includeFindAddress;
  const ValuesFunction =
    control.props.valuesFunction && control.props.valuesFunction;
  const validationType = control.props.validationType && control.props.validationType;
  const minValue = control.props.minValue && control.props.minValue;
  const maxValue = control.props.maxValue && control.props.maxValue;

  const InputBoxWithVisibility = WithVisibility(InputBox);
  const PostcodeWithVisibility = WithVisibility(Postcode);
  const CheckboxBoxesWithVisibility = WithVisibility(CheckboxBoxes);
  const DateDDMMYYYYWithVisibility = WithVisibility(DateDDMMYYYY);
  const DateMMYYYYWithVisibility = WithVisibility(DateMMYYYY);
  const LookupWithVisibility = WithVisibility(Lookup);
  const RadioBoxesWithVisibility = WithVisibility(RadioBoxes);
  const YesNoBoxesWithVisibility = WithVisibility(YesNoBoxes);
  const LookupWithSearchAndGroupsWithVisibility = WithVisibility(
    LookupWithSearchAndGroups
  );

  return (
    <>
      {control.componentType === "InputBox" && (
        <InputBoxWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          placeholder={placeholder}
          tooltip={tooltip}
          inputType={inputType}
          prefix={prefix}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          initialValue={initialValue}
          validationType={validationType}
          minValue={minValue}
          maxValue={maxValue}
        />
      )}
      {control.componentType === "Postcode" && (
        <PostcodeWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          placeholder={placeholder}
          tooltip={tooltip}
          inputType={inputType}
          prefix={prefix}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          initialValue={initialValue}
          cityFieldName={CityFieldName}
          addressLine1FieldName={addressLine1FieldName}
          includeFindAddress={IncludeFindAddress}
        />
      )}
      {control.componentType === "CheckboxBoxes" && (
        <CheckboxBoxesWithVisibility
          label={label}
          description={description}
          tooltip={tooltip}
          items={items}
          name={name}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          required={required}
        />
      )}
      {control.componentType === "DateDDMMYYYY" && (
        <DateDDMMYYYYWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          tooltip={tooltip}
          prefix={prefix}
          startYear={startYear}
          endYear={endYear}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
        />
      )}
      {control.componentType === "DateMMYYYY" && (
        <DateMMYYYYWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          tooltip={tooltip}
          prefix={prefix}
          startYear={startYear}
          endYear={endYear}
          placeholder={placeholder}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
        />
      )}
      {control.componentType === "Lookup" && (
        <LookupWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          tooltip={tooltip}
          prefix={prefix}
          placeholder={placeholder}
          values={values}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          valuesFunction={ValuesFunction}
        />
      )}
      {control.componentType === "RadioBoxes" && (
        <RadioBoxesWithVisibility
          label={label}
          description={description}
          tooltip={tooltip}
          items={items}
          name={name}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          required={required}
        />
      )}
      {control.componentType === "YesNoBoxes" && (
        <YesNoBoxesWithVisibility
          label={label}
          description={description}
          tooltip={tooltip}
          name={name}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          required={required}
        />
      )}
      {control.componentType === "LookupWithSearchAndGroups" && (
        <LookupWithSearchAndGroupsWithVisibility
          label={label}
          description={description}
          name={name}
          required={required}
          tooltip={tooltip}
          prefix={prefix}
          placeholder={placeholder}
          values={values}
          className={className}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          dependsOnFields={dependsOnFields}
          groupFilterField={groupFilterField}
        />
      )}
    </>
  );
}

function IterateThroughFieldsInSchema(
  yupSchema: YupSchema,
  onDependencyChange?: (fieldName: string) => void
) {
  return Object.entries(yupSchema.fields).map(([key, value]) => {
    //if it's a schema within a schema (annuity wthin client) then get the fields from the sub schema
    if (value.fields) {
      return (
        <React.Fragment key={key}>
          {IterateThroughFieldsInSchema(value, onDependencyChange)}
        </React.Fragment>
      );
    }

    //changes for depends on
    const dependsOnFields =
      value.spec.meta.dependsOnFields && value.spec.meta.dependsOnFields;
    const control = value.spec.meta.control;
    //if it's not an array, just map each field normally
    if (!Array.isArray(control)) {
      return (
        <React.Fragment key={key}>{ReturnElement(control)}</React.Fragment>
      );
    }
    // if it's an array of controls, then add as a fieldArray
    else {
      const fieldArrayName = value.spec.meta.fieldArrayName;
      const fieldArrayLabel = value.spec.label;
      const fieldArrayDescription = value.spec.meta.fieldArrayDescription;
      const className = value.spec.meta.className;
      const addSeparatorBelow = value.spec.meta.addSeparatorBelow;
      const addSeparatorAbove = value.spec.meta.addSeparatorAbove;
      const FieldArrayComponentWithVisibility =
        WithVisibility(FieldArrayComponent);

      return (
        <FieldArrayComponentWithVisibility
          key={fieldArrayName}
          fieldArrayName={fieldArrayName}
          fieldArrayLabel={fieldArrayLabel}
          fieldArrayDescription={fieldArrayDescription}
          controls={control}
          addSeparatorAbove={addSeparatorAbove}
          addSeparatorBelow={addSeparatorBelow}
          className={className}
          dependsOnFields={dependsOnFields}
        />
      );
    }
  });
}

const GetFormFields = (
  yupSchema: YupSchema,
  onDependencyChange?: (fieldName: string) => void
) => {
  return IterateThroughFieldsInSchema(yupSchema, onDependencyChange);
};

export { GetFormFields, GetFormFieldsWithHeaderAndFields, ReturnElement };
