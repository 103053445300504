import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import { usePortal } from "../../context/PortalContext";
import { DataCard1 } from "../../modules/common/cards/DataCard1";
import { NavCard } from "../../modules/common/cards/NavCard";
import { EditDataModal } from "../../modules/common/modals/EditDataModal";
import * as Yup from "yup";
import { YourProfile } from "../../modules/forms/form_schemas/AnnuityDashboard/YourProfile";

import { Form, Formik } from "formik";
import { CarouselModal } from "../../modules/common/modals/CarouselModal";
import { CTA1 } from "../../modules/common/cards/CTA1";
import { KTSVG } from "../../_metronic/helpers";
import { FAQ } from "../../modules/common/other/FAQ";
import CalendlyWidget from "../../modules/common/other/CalendlyWidget";
import { Link } from "react-router-dom";
import { NextSteps } from "../NextSteps";

export const Overview = () => {
  const { returnClientData, setClientData, clientData }: any = usePortal();

  return (
    <>
      <div>
        {clientData ? (
          <>
            <div className="row">
              <div className="col-12 mb-10">
                <NextSteps />
              </div>
              <div className="col-12">
                <CTA1
                  title={"Feeling Overwhelmed?"}
                  content={
                    <div className="fs-3 mb-0 text-dark">
                      Call us on 01302 496 300 or click below <br /> to book an
                      appointment
                    </div>
                  }
                  imageSrc="media/illustrations/bw/png/question.png"
                  button={[
                    {
                      id: "booking",
                      to: "#",
                      label: "BOOK AN APPOINTMENT",
                      className: "btn btn-primary fw-semibold px-6 py-3",
                      function: undefined,
                    },
                  ]}
                />
              </div>

              <div className="col-12">
                <div className="card mb-5 mb-xl-10">
                  <div className="fs-2 fw-bold m-0 d-flex justify-content-center py-10">
                    My Retirement Checklist
                  </div>
                  <div className="card-body py-9 px-20">
                    <div className="row mb-15">
                      <div className="col-12 col-md-9 d-flex align-items-center">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={
                              clientData.annuity?.fullQuoteCompleted === "true"
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="ms-2 ms-md-10 me-md-10">
                          <span className="fw-bold fs-1 text-dark">
                            Get a tailored quote
                            <br />
                          </span>
                          <span className=" fs-6">
                            Add your medical and lifestyle details to get a
                            quote tailored to you. Including these additional
                            details can significantly increase the quote you
                            might receive, making it a worthwhile step.
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 mt-5 mt-md-0 col-sm-12 d-flex align-items-center justify-content-center">
                        <Link
                          to="/fullquote"
                          className="btn btn-primary w-75 h-100 my-5 d-flex align-items-center justify-content-center"
                        >
                          Quote Tool
                        </Link>
                      </div>
                    </div>
                    <div className="row mb-15">
                      <div className="col-12 col-md-9 d-flex align-items-center">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={
                              clientData.annuity?.factfindCompleted === "true"
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="ms-2 ms-md-10 me-md-10">
                          <span className="fw-bold fs-1 text-dark">
                            Fact Find <br />
                          </span>
                          <span className=" fs-6">
                            Your next step is to complete a Fact Find. This can
                            be done either by speaking with one of our
                            specialists or by yourself, online and in your own
                            time. Once the Fact Find is completed, you'll be all
                            set to book your appointment with an Independent
                            Financial Adviser.
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 mt-5 mt-md-0 col-sm-12 d-flex align-items-center justify-content-center">
                        <Link
                          to="/factfind"
                          className="btn btn-primary w-75 h-100 my-5 d-flex align-items-center justify-content-center"
                        >
                          Complete Fact Find
                        </Link>
                      </div>
                    </div>
                    <div className="row mb-15">
                      <div className="col-12 col-md-9 d-flex align-items-center">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={
                              clientData.annuity?.bookIFAAppointment === "true"
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="ms-2 ms-md-10 me-md-10">
                          <span className="fw-bold fs-1 text-dark">
                            Book an IFA appointment <br />
                          </span>
                          <span className=" fs-6">
                            Book to speak with one of our Independent Financial
                            Advisers. This can be either a phone call or if you
                            prefer, a video call through this portal.
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 mt-5 mt-md-0 col-sm-12 d-flex align-items-center justify-content-center">
                        <a
                          id="booking"
                          className="btn btn-primary w-75 h-100 my-5 d-flex align-items-center justify-content-center"
                        >
                          Book IFA Call
                        </a>
                      </div>
                    </div>
                    <div className="row mb-15">
                      <div className="col-12 col-md-9 d-flex align-items-center">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={
                              clientData.annuity?.status !== "Portal" &&
                              clientData.annuity?.status !== "" &&
                              clientData.annuity?.status
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="ms-2 ms-md-10 me-md-10">
                          <span className="fw-bold fs-1 text-dark">
                            Proceed to Application <br />
                          </span>
                          <span className=" fs-6">
                            Upon receiving your advice and recommendation, we'll
                            send out an application form for you to sign and
                            return. We'll handle all the heavy lifting,
                            facilitating the transfer of your pension funds into
                            your new retirement product."
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 mt-5 mt-md-0 col-sm-12 d-flex align-items-center justify-content-center">
                        <Link
                          to="/myapplication"
                          className="btn btn-primary w-75 h-100 my-5 d-flex align-items-center justify-content-center"
                        >
                          View application
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-12">
                <FAQ
                  section={[
                    {
                      title: "General",
                      questions: [
                        {
                          to: "/",
                          question:
                            "Who are Pense and how do I know I'm safe with you?",
                        },
                        {
                          to: "/",
                          question: "What are your fees?",
                        },
                        {
                          to: "/",
                          question: "How much should I pay into my pension?",
                        },
                        {
                          to: "/",
                          question: "How much money do I need for retirement?",
                        },
                      ],
                    },
                    {
                      title: "Annuities & Retirement",
                      questions: [
                        {
                          to: "/",
                          question: "What is an Annuity?",
                        },
                        {
                          to: "/",
                          question:
                            "What options are available if I decide to tane an Annuity?",
                        },
                        {
                          to: "/",
                          question: "What are the alternatives to Annuities?",
                        },
                        {
                          to: "/",
                          question:
                            "Do I have to accept my pension company's offer?",
                        },
                      ],
                    },
                    {
                      title: "Equity Release",
                      questions: [
                        {
                          to: "/",
                          question: "What is Equity Release?",
                        },
                        {
                          to: "/",
                          question: "What is a lifetime mortgage?",
                        },
                        {
                          to: "/",
                          question:
                            "Why are some property types not eligible for some providers?",
                        },
                        {
                          to: "/",
                          question:
                            "How do I know if Equity Release is right for me?",
                        },
                      ],
                    },
                  ]}
                />
              </div> */}
            </div>
          </>
        ) : (
          <p>Loading your details...</p>
        )}
      </div>
    </>
  );
};
