import { FieldArray, useField } from "formik";
import React, { ReactNode, useRef } from "react";
import { ReturnElement } from "../Helpers";

export const FieldArrayComponent = (props: IProps) => {
  const counter = useRef(0);
  const fieldName = props.fieldArrayName;
  const controls = props.controls;
  const [field, meta, helpers] = useField(fieldName);

  const createNewRowObject = () => {
    const newRowObject: any = {
      id: counter.current++,
    };

    controls.forEach((element: any) => {
      newRowObject[element.props.name] = "";
    });

    return newRowObject;
  };

  return (
    <div className={props.className ? props.className : "mb-9"}>
      {props.addSeparatorAbove && <hr className="py-5"></hr>}
      <label className="d-flex align-items-center fs-3 fw-bold form-label">
        <span className={props.required ? "required" : ""}>
          {props.fieldArrayLabel}
        </span>
        {props.tooltip?.button && props.tooltip.button}
      </label>
      {props.tooltip?.notice && props.tooltip.notice}
      <div className="form-text mb-5">{props.fieldArrayDescription}</div>
      <FieldArray name={fieldName}>
        {({ insert, remove, push }) => (
          <div>
            {/* create a row of controls for each value */}
            {field.value?.length > 0 &&
              field.value.map((row: any, index: any) => (
                <div
                  className="form-group align-items-center mb-7"
                  key={`${index}_${row.id}`}
                >
                  {/* <div className="border rounded border-solid border-dark border-rounded border-curved border-2 p-10 my-5"> */}
                  <div className="rounded shadow p-5 pt-10 my-5 row">
                    <div className="col-12 d-flex justify-content-end pb-5">
                      <a
                        type="button"
                        className="btn btn-sm font-weight-bolder btn-light-danger"
                        onClick={() => {
                          const indexToRemove = index;
                          remove(indexToRemove);
                        }}
                      >
                        <i className="la la-trash-o"></i>Remove Item
                      </a>
                    </div>
                    {/* foreach control in yup schema controls array, create a control */}
                    {controls.map((element: any, elementMapKey: any) => {
                      // debugger;
                      const newName = `${fieldName}.${index}.${element.props.name}`;

                      let updatedElementProps = {
                        ...element.props,
                        name: newName,
                      };

                      //if it's a lookupWithSearchAndGroups we may have a filter based on another field - needs to have the index on
                      if (element.props.groupFilterField) {
                        updatedElementProps = {
                          ...element.props,
                          name: newName,
                          groupFilterField: `${fieldName}.${index}.${element.props.groupFilterField}`,
                        };
                      }

                      //update for dependsOnFields within field arrays - needs to have the index on
                      if (element.dependsOnFields) {
                        let newDependsOnfields: any[] = [];
                        // debugger;
                        element.dependsOnFields.map((field: any) => {
                          newDependsOnfields.push({
                            fieldName: `${fieldName}.${index}.${field.fieldName}`,
                            fieldValue: field.fieldValue,
                          });
                        });

                        const updatedElement = {
                          ...element,
                          props: updatedElementProps,
                          dependsOnFields: newDependsOnfields,
                        };

                        return (
                          <React.Fragment
                            key={`${index}_${element.props.name}`}
                          >
                            {ReturnElement(updatedElement)}
                          </React.Fragment>
                        );
                      } else {
                        const updatedElement = {
                          ...element,
                          props: updatedElementProps,
                        };

                        return (
                          <React.Fragment
                            key={`${index}_${element.props.name}`}
                          >
                            {ReturnElement(updatedElement)}
                          </React.Fragment>
                        );
                      }
                    })}
                  </div>
                </div>
              ))}
            {/* push button to add a row based on above with schema values set */}
            <button
              type="button"
              className="btn btn-sm font-weight-bolder btn-light-primary mb-5 mt-5"
              onClick={() => push(createNewRowObject())}
            >
              <i className="la la-plus"></i>Add New Item
            </button>
          </div>
        )}
      </FieldArray>
      {props.addSeparatorBelow && <hr className="py-5"></hr>}
    </div>
  );
};

interface IProps {
  fieldArrayName: string;
  fieldArrayLabel: string;
  fieldArrayDescription: string;
  controls: any;
  className?: string;
  addSeparatorBelow?: boolean;
  addSeparatorAbove?: boolean;
  required?: boolean;
  tooltip?: ITooltip;
}

interface ITooltip {
  button: ReactNode;
  notice: ReactNode;
}
